import React from "react"
import Layout from "../../layouts/ja"
import CommonDoc from "../../components/CommonDoc"

const IndexPage = props => (
  <Layout location={props.location} title={`About VIRTUAL ART BOOK FAIR`}>
    <CommonDoc>
      <section className={`container`}>
        <div className={`content`}>
          <div className={`content__head`}>
            <h1 className={`haeding__lev1`}>About VIRTUAL ART BOOK FAIR</h1>
            <h2 className={`haeding__lev2`}>Nov 16-23, 2020</h2>
          </div>
          <div className={`editor__content`}>
             <p>世界が一変した2020年。コロナ禍においてもTOKYO ART BOOK FAIR（TABF）の役割は、アート出版におけるプラットフォームのひとつとして、これまでの経験と繫がりをいかし、バーチャルという新たな領域でアートブックとの出会いや人との交流が生まれる場を創出することだと考えました。いまを“実験の時代”と捉え、DIY精神とユーモアを忘れず、皆さんにアートブックの魅力を多角的に楽しんでいただける様々な体験が詰まったオンラインのフェア、VIRTUAL ART BOOK FAIR（VABF）を開催いたします。</p>
             <p>東京都現代美術館をインスピレーション源としたバーチャル空間に、来場者とオンライン上で交流しながら印刷物の魅力を伝える約230組の出展者ブースが並び、レクチャーやトークショー、サイン会なども行われます。また、今年のゲストカントリーであるオランダの出版文化を紐解く企画として、毎年同国の優れたデザインの本が選出されるアワード「Best Dutch Book Design」の2019年の受賞作品を紹介する展示や、イルマ・ボーム、カレル・マルテンス、ルース・ファン・ビークなど、オランダのアートブックシーンを牽引するデザイナーやアーティストらのインタビューとともに、彼らが影響を受けた本と手がけた印刷物を展示する「Dutch Artists’ Books: Then and Now」などを開催いたします。</p>
             <p>これまでリアルな空間で行われてきたTOKYO ART BOOK FAIRの良さを活かしながら、今を“実験の時代”ととらえ、DIY精神とユーモアを忘れずに新たな可能性を探りつつ、アートブックを多角的に楽しんでいただけるフェアを目指します。</p>
             <section>
              <h2>CONTACT</h2>
              <p>TOKYO ART BOOK FAIR 運営事務局<br /><a href="mailto:info@tokyoartbookfair.com">info@tokyoartbookfair.com</a></p>
             </section>
          </div>
          {/* /.editor__content */}
        </div>
        {/* /.content */}
      </section>
      {/* /.container */}
    </CommonDoc>
  </Layout>
)

export default IndexPage
