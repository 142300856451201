import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.en.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Haarlem-based poetry laboratory De Zingende Zaag (The Singing Saw) came up with some colourful bunting to celebrate its thirtieth anniversary. Literally: as this 12-metre long combination of flatbook and leporello (the longest in the world according to the makers) can be hung up just as it is. An ingenious system of pages glued nose-to-tail results in a perfectly hinged string of zigzag bunting that folds neatly into a book block presenting the 42 colourful publications puzzled together by the poetry laboratory over the past thirty years. With highlighted poems, reviews and texts written for the occasion in bold retro colours reminiscent of the cover art of ’80s Dutch pop sensations Doe Maar and festive, chaotic design based around the motif of the saw tooth. Book design as clowning. Impossible to improve.`}</p>
    <hr></hr>
    <p>{`Publisher: De Zingende Zaag Producties, Haarlem`}<br parentName="p"></br>{`
`}{`Concept and design: Studio Hendriksen, Amsterdam; George Moormann, Haarlem`}</p>
    <p>{`Category: Prose, poetry or graphic novels`}<br parentName="p"></br>{`
`}{`Editor: George Moormann`}<br parentName="p"></br>{`
`}{`Language: Dutch`}<br parentName="p"></br>{`
`}{`Photographer/ fotograaf: Ton van der Heide`}<br parentName="p"></br>{`
`}{`Image editor: George Moormann`}<br parentName="p"></br>{`
`}{`Designer: Studio Hendriksen, Amsterdam`}<br parentName="p"></br>{`
`}{`Lithographer: Zwaan Lenoir, Wormerveer`}<br parentName="p"></br>{`
`}{`Printer: Zwaan Lenoir, Wormerveer`}<br parentName="p"></br>{`
`}{`Binder: J. Voetelink & Zoon, Haarlem`}<br parentName="p"></br>{`
`}{`Font: Maison Neue (Milieu Grotesque)`}<br parentName="p"></br>{`
`}{`Size in mm: 170 x 225 x 40 (leporello: 12.24 meter)`}<br parentName="p"></br>{`
`}{`Number of pages: 144`}<br parentName="p"></br>{`
`}{`Print run: 333`}<br parentName="p"></br>{`
`}{`Price: € 125`}<br parentName="p"></br>{`
`}{`ISBN: 979 90 74183 31 4`}<br parentName="p"></br>{`
`}{`Paper for interior: 250gsm Edixion Offset (Antalis)`}<br parentName="p"></br>{`
`}{`Binding style: Combination of flatbook and leporello`}<br parentName="p"></br>{`
`}{`Special features: The longest literary leporello in the world. J. Voetelink & Zoon is the only company in Europe with a machine that can make such a book.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      