import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.en.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`No book in this selection caused such divergence of opinion within the jury as this 484-page investigation of the archetypical American cowboy in film and (advertising) media through the rephotography and collage art of Richard Prince. ‘A showy book’ was one jury member’s verdict; a ‘great adventure book’ according to another. The fact that Prince started out in the cutting-room at media conglomerate Time Inc. clearly influenced both his artistic work and this book. Collage, design imitating the lay-out of magazines, the visual language of (Marlboro) advertisements. At the back of the book there is a section on glossy paper containing Prince’s own ‘Cowboy works’. Once again we have a cover without typography on front or back. Instead, the title is placed running around the spine and the three trimmed sides of the book block, blocked out of the image which is also ‘pulled’ around the sides. Unnecessary, some jury members thought; a sound decision in the opinion of others. Finally, the fans won the day.`}</p>
    <hr></hr>
    <p>{`Publisher: Fulton Ryder, New York & Prestel Publishing, New York (USA)`}<br parentName="p"></br>{`
`}{`Concept and design: COMA Amsterdam | New York (Cornelia Blatter, Marcel Hermans)`}</p>
    <p>{`Category: Art, photo and architecture books`}<br parentName="p"></br>{`
`}{`Artist: Richard Prince`}<br parentName="p"></br>{`
`}{`Language: English`}<br parentName="p"></br>{`
`}{`Photographer: Richard Prince`}<br parentName="p"></br>{`
`}{`Image editor: Robert M. Rubin`}<br parentName="p"></br>{`
`}{`Editor-in-chief: Jana Martin`}<br parentName="p"></br>{`
`}{`Lay-out: COMA Amsterdam | New York`}<br parentName="p"></br>{`
`}{`Paper advice: De Monsterkamer`}<br parentName="p"></br>{`
`}{`Producer: ORO Graphic Project Management, Koekange`}<br parentName="p"></br>{`
`}{`Lithographer: Echelon Color, Los Angeles (USA)`}<br parentName="p"></br>{`
`}{`Printer: Kösel GmbH & Co. KG, Altusried-Krugzell (DE)`}<br parentName="p"></br>{`
`}{`Binder: Kösel GmbH & Co. KG, Altusried-Krugzell (DE)`}<br parentName="p"></br>{`
`}{`Font: New Fournier BP (Swiss Typefaces), Gill Sans (Monotype)`}<br parentName="p"></br>{`
`}{`Size in mm: 230 x 305 x 28`}<br parentName="p"></br>{`
`}{`Number of pages: 484`}<br parentName="p"></br>{`
`}{`Print run: 5,000`}<br parentName="p"></br>{`
`}{`Price: € 69`}<br parentName="p"></br>{`
`}{`ISBN: 978 3 7913 5968 7`}<br parentName="p"></br>{`
`}{`Paper for interior: 115gsm Garda Gloss (Lecta), 80 and 100gsm Munken Polar Rough (Arctic Paper)`}<br parentName="p"></br>{`
`}{`Cover: 300gsm Munken Polar Rough (Arctic Paper)`}<br parentName="p"></br>{`
`}{`Binding style: Sewn softcover`}<br parentName="p"></br>{`
`}{`Special features: Printing on the edges: the cover design is three-dimensional thanks to the flawlessly overflowing printing of the edges of the book block from the front and the back of the cover. The title is printed around the edges by Kösel GmbH & Co. KG, Altusried-Krugzell (DE).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      