import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.en.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This book presents architect Wiel Arets’ kaleidoscopic vision of the future of the modern metropolis and the often unconscious, collective human choices that colour, drive and transform life in this environment. The page numbers are replaced by ‘time stamps’, which show that each chapter ‘lasts’ exactly 72 minutes – based on the idea that a journey can be experienced comfortably if it lasts no more than 72 minutes. The Japanese-style folded paper and the neobond® material used for the cover – around a ‘normal’ cover board – feel extraordinarily supple. The chapters are separated by pictorial sections consisting of photos of urban life in Tokyo. Here too, the book plays with translucent paper: the title is printed on the inside of the title page folded in the Japanese style; the title of the first chapter shows through the unprinted page following the title page. The first photo in each of the pictorial sections is also printed on the inside of the page, before appearing again on the outside of the next page. This allows the words and images to ‘loom up’ and gives the typography of the text pages – with its strongly contrasting fonts of different sizes distinguishing between voices and quotations in the book – lively images before and after, without this being at all detrimental to a pleasant reading experience. Another smart feature: the extremely strong, supple neobond® provides a spine that will not break and which opens superbly, in spite of the hot melt gluing, contributing even more to the readability of this intelligent book; understated yet ‘designed through and through’. Bravo!`}</p>
    <hr></hr>
    <p>{`Publisher: Actar, New York City (USA)`}<br parentName="p"></br>{`
`}{`Designer: Mainstudio, Amsterdam`}</p>
    <p>{`Category: Art, photo and architecture books`}<br parentName="p"></br>{`
`}{`Editor: John Bezold`}<br parentName="p"></br>{`
`}{`Language: English`}<br parentName="p"></br>{`
`}{`Photographer: Wiel Arets`}<br parentName="p"></br>{`
`}{`Editor-in-chief: John Bezold`}<br parentName="p"></br>{`
`}{`Lithographer: Zwaan Lenoir, Wormerveer`}<br parentName="p"></br>{`
`}{`Printer: Zwaan Lenoir, Wormerveer`}<br parentName="p"></br>{`
`}{`Binder: Boekbinderij Patist, Den Dolder`}<br parentName="p"></br>{`
`}{`Font: Stanley (Optimo) Monospace 821 (Bitstream)`}<br parentName="p"></br>{`
`}{`Size in mm: 165 x 235 x 21`}<br parentName="p"></br>{`
`}{`Number of pages: 278 (556)`}<br parentName="p"></br>{`
`}{`Print run: 2,000`}<br parentName="p"></br>{`
`}{`Price: € 32`}<br parentName="p"></br>{`
`}{`ISBN: 978 1 945150 65 4`}<br parentName="p"></br>{`
`}{`Paper for interior: 60gsm IBO One (Igepa)`}<br parentName="p"></br>{`
`}{`Cover: 300gsm Munken Print White 15 (Antalis)`}<br parentName="p"></br>{`
`}{`Dust jacket: 200gsm neobond® green (Papyrus)`}<br parentName="p"></br>{`
`}{`Binding style: Unsewn softcover, Chinese/Japanese binding, dust jacket is glued to the spine`}<br parentName="p"></br>{`
`}{`Special features: The dust jacket is printed twice in black offset, white screen printing on the spine and cover by Seanscreen.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      