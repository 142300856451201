import React from "react"
import Layout from "../../../layouts/ja"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import css from "./index.module.scss"

const IndexPage = props => {
  let posts = []
  for (let i = 0; i < props.data.allMdx.edges.length; i += 1) {
    let thePost = props.data.allMdx.edges[i].node

    posts.push(
      <li key={i} className={css.dab__items__item}>
        <Link to={`/${thePost.slug}`} className={css.dab__items__item__link}>
          <Img
            className={css.dab__items__item__img}
            fluid={thePost.frontmatter.thumbnail.childImageSharp.fluid}
          />
          <h2 className={css.dab__items__item__title}>
            {thePost.frontmatter.author}
          </h2>
          <p className={css.dab__items__item__description}>
            {thePost.frontmatter.description}
          </p>
        </Link>
      </li>
    )
  }

  return (
    <Layout location={props.location} title={`Dutch Artists Book Exhibition`}>
      <section className={`container`}>
        <div className={css.dab__content + ` content`}>
          <div className={css.dab__head + ` content__head`}>
            <h1 className={`haeding__lev1`}>
              DUTCH ARTISTS’ BOOK: <br className={`only-sp`} />
              THEN AND NOW
            </h1>
          </div>

          <div className={css.dab__read}>
            <p>
              2019年に作り手たちの言葉によって日本におけるアートブックの進化を紐解いた「Japanese
              Artists’ Book Then and
              Now」のオランダ版。今回は現代のオランダ出版文化を牽引するキーパーソンたちのアートブックとの出会い、またその原体験がどのように現在の制作へと繋がっているかを尋ねたインタビュー動画とともに、彼らが影響を受けた本と手がけた多様な印刷物をオンライン上でご紹介します。インタビューでは、どのようにオランダのアート出版が豊かな発展を遂げたのか、アーティスト、デザイナー、書店の関係性、アイデアを本というプラットフォームに落とし込むユニークなアイデア、コロナ禍におけるクリエイティビティの役割などがパーソナルな経験とともに語られています。
            </p>
          </div>

          <ul className={css.dab__items}>{posts}</ul>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage

export const posts = graphql`
  query {
    allMdx(
      filter: { slug: { regex: "/^exhibitions.+dutch-artist-book/" } }
      sort: { fields: slug }
    ) {
      edges {
        node {
          id
          slug
          frontmatter {
            title
            author
            description
            thumbnail {
              id
              childImageSharp {
                fluid(maxWidth: 1280) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            genre
          }
          excerpt
        }
      }
    }
  }
`
