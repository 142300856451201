import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/DefaultPage.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`一般社団法人東京アートブックフェア（以下「当法人」といいます。）は、当法人が提供するウェブサイトその他一切のサービス（以下「本サービス」といいます。）における個人情報の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」といいます。）を定めます。`}</p>
    <h2>{`第 1 条　個人情報保護に関する基本方針`}</h2>
    <ol>
      <li parentName="ol">{`当法人は、ユーザーの個人情報を保護するため、当法人が行うユーザーの個人情報の取得および利用に関して本ポリシーを定め、管理体制の整備を図るとともに、本ポリシーを役員および従業員に対して周知するよう努めます。`}</li>
      <li parentName="ol">{`当法人は、日本国の個人情報保護法を遵守すると共に、EU 一般データ保護規則（General Data Protection Regulation。以下「GDPR」といいます。）や、カリフォルニア州消費者プライバシー法（California Consumer Privacy Act of 2018。以下「CCPA」といいます。）など各地域における個別の法令を尊重し、本ポリシーが当該各法令に抵触することのないよう配慮すると共に、当該各法令に基づく権利行使がなされた場合には適切に対処するよう努めます。`}</li>
    </ol>
    <h2>{`第 2 条　定義`}</h2>
    <ol>
      <li parentName="ol">{`個人に関する情報のうち「個人情報」とは、日本国の個人情報保護法が規定する「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先その他の記述などにより特定の個人を識別できる情報を指します。`}</li>
      <li parentName="ol">{`個人に関する情報のうち「履歴情報および特性情報」とは、前項の「個人情報」以外のものをいい、ご利用いただいたサービス、ご覧になったホームページや広告の履歴、ユーザーが検索したキーワード、ご利用日時、ご利用方法、ご利用環境、郵便番号や性別、職業、年齢、IP アドレス、クッキー情報、位置情報、端末の個体識別情報などを指します。`}</li>
    </ol>
    <h2>{`第 3 条　取得する情報`}</h2>
    <ol>
      <li parentName="ol">{`当法人は、当サイトにおいて以下の情報を取得することがあります。当法人は、当該各情報の取得、利用、管理を適正に行い、収集した各情報を利用目的の範囲内で適切に取り扱います。`}
        <ol parentName="li">
          <li parentName="ol">{`ユーザーが登録した情報`}<br parentName="li"></br>
            {`出展などの申込みや問い合わせがあった時などに氏名、住所、電話番号、メールアドレスなどを取得する場合があります。`}</li>
          <li parentName="ol">{`クッキー（Cookie）`}<br parentName="li"></br>
            {`当法人は、下記の目的のためにクッキーを利用します。ユーザーはクッキーを使用するか否かを選択することができますが、クッキーの保存を拒む場合には本サービスの一部をご利用いただけない場合があります。`}
            <ul parentName="li">
              <li parentName="ul">{`ユーザーの設定内容の保存などユーザーの利便性向上のため`}</li>
              <li parentName="ul">{`セッションの維持および保護などセキュリティのため`}</li>
              <li parentName="ul">{`本サービスの訪問回数や利用形態などを把握し、より良いサービスを提供するため`}</li>
            </ul>
          </li>
          <li parentName="ol">{`アナリティクス`}<br parentName="li"></br>
            {`本サービスは、Google によるアクセス解析ツール「Google アナリティクス」を利用しています。この Google アナリティクスはトラフィックデータの収集のためにクッキーを使用します。このトラフィックデータは匿名で収集されており、個人を特定するものではありません。クッキーを無効にすることで収集を拒否することができますので、お使いのブラウザの設定をご確認ください。この点について、詳しく知りたい場合は「Google アナリティクス利用規約」をご参照ください。`}</li>
          <li parentName="ol">{`ログ`}<br parentName="li"></br>
            {`ユーザーが本サービスを利用すると、ログ情報が自動的に生成、保存されます。本サービスは、これらの情報から利用者の環境を分析し、より良いサービスを提供するため、また正常なサービス提供を妨害する不正行為防止のために利用します。`}</li>
          <li parentName="ol">{`機器情報`}<br parentName="li"></br>
            {`本サービスは、ユーザーが利用する端末機器情報（端末の個体識別情報など）、OS 情報などの IP 情報を取得する場合があります。これらの情報はより良いサービスを提供するため、また本人確認や正常なサービス提供を妨害する不正行為を防止するために利用します。なお、ユーザーが使用する OS によって、当法人が取得する機器情報の詳細は異なります。当法人が取得できる情報の詳細については、デバイスの製造元またはソフトウェア・プロバイダーのポリシーをご確認ください。`}</li>
        </ol>
      </li>
      <li parentName="ol">{`EEA（European Economic Area。欧州経済領域）域内の方の個人情報の取得および利用は、原則として同意があった場合に限っています。ただし、ユーザーの同意がなくとも、① ユーザーの要望に対応する場合、② 正当な利益を保護する必要がある場合、③ 法令に基づく場合、④ 当法人のサービスを改善する必要がある場合などは、個人情報の取得および利用を行うことがあります。`}</li>
      <li parentName="ol">{`ユーザーは、当法人の所定の窓口に連絡することにより、いつでも前項の同意を撤回することができます。ただし、同意の撤回がなされた場合であっても、撤回前の同意に基づく個人情報の取得・利用の適法性に影響はありません。`}</li>
    </ol>
    <h2>{`第 4 条　情報の利用目的`}</h2>
    <p>{`当法人は取得した個人情報を以下の目的で利用します。`}</p>
    <ol>
      <li parentName="ol">{`ユーザーの本人確認のため`}</li>
      <li parentName="ol">{`不正利用防止のため`}</li>
      <li parentName="ol">{`ユーザーが本サービスを円滑に利用できるようにするため`}</li>
      <li parentName="ol">{`本サービスの提供、維持、改善のため`}</li>
      <li parentName="ol">{`本サービス利用に関する統計データを作成するため`}</li>
      <li parentName="ol">{`現在提供している本サービスまたは今後提供を検討している本サービスに関するアンケートを実施するため`}</li>
      <li parentName="ol">{`ユーザーからの問い合わせに対応するため`}</li>
      <li parentName="ol">{`本サービスに関する情報や当法人以外の事業者が広告主となる広告情報などを告知するため`}</li>
      <li parentName="ol">{`今後の本サービスに関する新企画立案を行い、提供するため`}</li>
      <li parentName="ol">{`その他本サービスに関する重要なお知らせなど、必要に応じた連絡を行うため`}</li>
    </ol>
    <h2>{`第 5 条 情報の提供`}</h2>
    <ol>
      <li parentName="ol">{`当法人は、ユーザーが事前に同意した場合のほか、次に掲げる場合を除き、原則として第三者に個人情報を提供することはありません。`}
        <ol parentName="li">
          <li parentName="ol">{`法令に基づく場合`}</li>
          <li parentName="ol">{`人の生命、身体または財産の保護のために必要がある場合であって、同意を得ることが困難であるとき`}</li>
          <li parentName="ol">{`公衆衛生の向上または児童の健全な育成の推進のため特に必要がある場合であって、同意を得ることが困難であるとき`}</li>
          <li parentName="ol">{`国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき`}</li>
          <li parentName="ol">{`当法人が利用目的の達成に必要な範囲内において個人情報の全部または一部について委託を行った場合`}</li>
          <li parentName="ol">{`合併、法人分割、営業譲渡その他の事由による事業の承継に伴って個人情報が提供された場合`}</li>
          <li parentName="ol">{`個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用する個人情報の項目、共同して利用する者の範囲、利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について、あらかじめユーザーに通知し、またはユーザーが容易に知り得る状態に置いているとき`}</li>
        </ol>
      </li>
      <li parentName="ol">{`当法人は、過去に個人情報を商業目的で提供または販売したことはありません。`}</li>
    </ol>
    <h2>{`第 6 条 情報の預託`}</h2>
    <p>{`当法人は利用目的の達成に必要な範囲内においてユーザーから取得した個人情報の全部または一部を業務委託先に預託することがあります。その際、業務委託先としての適格性を十分審査するとともに、情報が適正に管理されるよう努めます。`}</p>
    <h2>{`第 7 条 情報の共同利用`}</h2>
    <p>{`本サービスを提供するにあたり、当法人と共同して業務を行うビジネスパートナーが必要な場合には、原則としてユーザーの事前の同意をいただいた上で、個人情報をそのビジネスパートナーと共同利用することがあります。この場合、当法人は、利用目的、ビジネスパートナーの名称、情報の種類、管理者の名称についてユーザーに公表した上で共同利用を行います。`}</p>
    <h2>{`第 8 条　個人情報の確認、訂正など`}</h2>
    <ol>
      <li parentName="ol">{`ユーザーは、GDPR などに基づき、当法人に対して、① 個人情報が取得・利用されているか否かを確認し、② 不正確な個人情報の訂正を請求し、③ 一定の場合に個人情報の消去を請求し、④ 一定の場合に個人情報の利用を制限することを請求することができます。`}</li>
      <li parentName="ol">{`ユーザーが前項の請求などを行う場合には、当法人が定めた手続きに従う必要があります。また、当法人が別途定めた開示手数料をいただく場合があります。`}</li>
      <li parentName="ol">{`第 1 項の規定にかかわらず、以下の場合には請求などを行うことはできません。`}
        <ol parentName="li">
          <li parentName="ol">{`開示などを行うことでユーザー本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合`}</li>
          <li parentName="ol">{`開示などを行うことで当法人の業務の適正な実施に著しい支障を及ぼすおそれがある場合`}</li>
          <li parentName="ol">{`開示などを行うことが法令に違反する場合`}</li>
          <li parentName="ol">{`本人からの請求などであることが確認できない場合`}</li>
          <li parentName="ol">{`技術的に不可能な場合または過大な作業量が必要となる場合`}</li>
        </ol>
      </li>
      <li parentName="ol">{`CCPA の適用がある場合、ユーザーは同法に基づき以下の権利を有しています。ただし、ユーザーがかかる権利を行使するにあたっては、当法人が定める方法で本人確認を行います。`}
        <ol parentName="li">
          <li parentName="ol">{`過去 12 か月以内に当法人が収集した個人情報を年 2 回まで提供を求める権利`}</li>
          <li parentName="ol">{`法が定める一定の場合に個人情報を消去する権利`}</li>
          <li parentName="ol">{`差別されない権利`}</li>
          <li parentName="ol">{`個人情報の販売の停止を求め、将来的に個人情報の販売をしないよう求める権利`}</li>
        </ol>
      </li>
    </ol>
    <h2>{`第 9 条　個人情報の利用停止など`}</h2>
    <p>{`当法人は、ユーザー本人から、個人情報が利用目的の範囲を超えて取り扱われているという理由、または不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下「利用停止など」といいます。）を求められた場合には、遅滞なく必要な調査を行い、その結果に基づき、個人情報の利用停止などを行い、その旨をユーザー本人に通知します。ただし、個人情報の利用停止などに多額の費用を有する場合や、その他利用停止などを行うことが困難な場合であって、ユーザー本人の権利利益を保護するために必要なこれに代わるべき措置をとることができる場合は、この代替策を講じます。`}</p>
    <h2>{`第 10 条 本プライバシーポリシーの改訂`}</h2>
    <ol>
      <li parentName="ol">{`本ポリシーの内容は、ユーザーに通知することなく変更することができます。CCPA の適用がある場合には、少なくとも 12 か月に一度、本ポリシーの改訂をいたします。`}</li>
      <li parentName="ol">{`当法人が別途定める場合を除き、変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効力を生じるものとします。`}</li>
      <li parentName="ol">{`本ポリシーが日本語以外の言語で提供された場合であって、日本語の本ポリシーの意図するところと外国語の本ポリシーの意味内容が異なった場合には、日本語で記載された本ポリシーが優先します。`}</li>
    </ol>
    <h2>{`お問い合わせ`}</h2>
    <p>{`本ポリシーに関してご不明な点がある場合は下記までご連絡ください。`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "mailto:info@tokyoartbookfair.com"
      }}>{`info@tokyoartbookfair.com`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      