import { Input } from "./Input"
import { EventEmitter } from "events"

import * as THREE from "three"
import CameraControls from "./vendor/camera-controls"
CameraControls.install({ THREE })

import type { World } from "./worlds/World"
import { BoothWorld } from "./worlds/BoothWorld"
import { FoyerWorld } from "./worlds/FoyerWorld"
import { Clock, WebGLRenderer } from "three"
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass"
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass"
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader"
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer"
import { TransitionShader } from "./TransitionShader"

import TWEEN from "@tweenjs/tween.js"
import { AudioPlayer } from "./AudioPlayer"
import { TextMesh } from "./TextMesh"
import { MouseInfo, MouseWatcher } from "./MouseWatcher"
import { Area, getAreaFromUrl } from "../commonTypes"
import isMobile from "is-mobile"
import { Loader } from "./Loader"

export type MoveDir = "left" | "right" | "up" | "down"

const isPC = !isMobile()

export class Game {
  private input = new Input(this)
  private world: World
  private composer: EffectComposer
  private transitionPass = new ShaderPass(TransitionShader)
  private clock = new Clock()
  private isMoving: Record<MoveDir, boolean> = {
    left: false,
    right: false,
    up: false,
    down: false,
  }
  private audioPlayer: AudioPlayer
  private mouseWatcher: MouseWatcher
  private enabled = false
  private currentArea: Area = "entrance"

  private constructor(
    private events: EventEmitter,
    private canvas: HTMLCanvasElement,
    private renderer: WebGLRenderer,
    private worlds: World[]
  ) {
    const area = getAreaFromUrl()
    this.currentArea = area
    this.world = isPC ? worlds[area === "booth" ? 1 : 0] : worlds[0]
    this.world.setEnabled(true)

    this.composer = this.initComposer()

    window.addEventListener("resize", this.resize)
    window.addEventListener("keydown", this.onKeyDown)
    window.addEventListener("keyup", this.onKeyUp)
    document.addEventListener("visibilitychange", this.onVisibilityChange)

    this.mouseWatcher = new MouseWatcher(this.canvas)
    this.mouseWatcher.on("click", this.onClick)
    this.mouseWatcher.on("mousedown", this.onMouseDown)
    this.mouseWatcher.on("mousemove", this.onMouseMove)

    // 現在のページによって描画on/offを切り替える
    area !== "other" ? this.enable() : this.disable()

    this.resize()
    this.start()

    this.audioPlayer = new AudioPlayer(events)

    // HTML側からのイベントハンドラーを設定
    this.events.on("changeWorld", this.changeWorld)
    this.events.on("changeArea", this.changeArea)
    this.events.on("areaChanged", this.areaChanged)
    this.events.on("moveToVendor", this.moveToVendor)
    this.events.on("openVendor", this.disable)
    this.events.on("leaveVendorDetail", this.enable)

    this.events.emit("loaded", true)
  }

  static async init(
    events: EventEmitter,
    canvas: HTMLCanvasElement
  ): Promise<Game> {
    Loader.init()

    const renderer = new WebGLRenderer({ canvas })
    renderer.autoClear = false
    renderer.shadowMap.enabled = true
    renderer.setSize(canvas.offsetWidth, canvas.offsetHeight)
    // renderer.setPixelRatio(window.devicePixelRatio)

    TextMesh.setIsWebGL2(renderer.capabilities.isWebGL2)
    TextMesh.preload()

    const area = getAreaFromUrl()

    const worlds = isPC
      ? await Promise.all([
          FoyerWorld.init(0, events, canvas),
          BoothWorld.init(1, events, canvas),
        ])
      : [
          area === "booth"
            ? await BoothWorld.init(1, events, canvas)
            : await FoyerWorld.init(0, events, canvas),
        ]

    return new Game(events, canvas, renderer, worlds)
  }

  initComposer() {
    const composer = new EffectComposer(this.renderer)
    composer.renderer.autoClear = false

    this.transitionPass.uniforms["tex1"].value = this.world.renderTarget.texture
    this.transitionPass.uniforms["tex2"].value = this.world.renderTarget.texture
    composer.addPass(this.transitionPass)

    // const ssaoPass = new SSAOPass(scene, this.camera, canvas.offsetWidth, canvas.offsetHeight);
    // ssaoPass.kernelRadius = 10;
    // composer.addPass(ssaoPass);

    const fxaaPass = new ShaderPass(FXAAShader)
    const pixelRatio = this.renderer.getPixelRatio()
    fxaaPass.material.uniforms["resolution"].value.x =
      1 / (this.canvas.offsetWidth * pixelRatio)
    fxaaPass.material.uniforms["resolution"].value.y =
      1 / (this.canvas.offsetHeight * pixelRatio)
    composer.addPass(fxaaPass)
    composer.setSize(this.canvas.offsetWidth, this.canvas.offsetHeight)

    return composer
  }

  private resize = () => {
    const width = window.innerWidth
    const height = window.innerHeight
    this.canvas.width = width
    this.canvas.height = height
    this.worlds.forEach(w => w.resize(width, height))
    this.renderer.setSize(width, height)
    this.composer.setSize(width, height)
  }

  private onKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      this.world.escape()
    }

    // 隠しコマンド キャプチャ用にUIを隠す
    if (e.ctrlKey && e.shiftKey && (e.key === "?" || e.key === "/")) {
      const hidden = this.canvas.style.zIndex === "-1"
      this.canvas.style.zIndex = hidden ? "9999999999" : "-1"
    }

    if (e.key === "ArrowLeft" || e.key === "a") {
      this.world.setMoving("left", true)
    } else if (e.key === "ArrowRight" || e.key === "d") {
      this.world.setMoving("right", true)
    } else if (e.key === "ArrowUp" || e.key === "w") {
      this.world.setMoving("up", true)
    } else if (e.key === "ArrowDown" || e.key === "s") {
      this.world.setMoving("down", true)
    }
  }

  private onKeyUp = (e: KeyboardEvent) => {
    if (e.key === "ArrowLeft" || e.key === "a") {
      this.world.setMoving("left", false)
    } else if (e.key === "ArrowRight" || e.key === "d") {
      this.world.setMoving("right", false)
    } else if (e.key === "ArrowUp" || e.key === "w") {
      this.world.setMoving("up", false)
    } else if (e.key === "ArrowDown" || e.key === "s") {
      this.world.setMoving("down", false)
    }
  }

  private onVisibilityChange = () => {
    if (document.visibilityState !== "visible") {
      this.world.resetMoving()
    }
  }

  private onMouseDown = ({ isRight }: MouseInfo) => {
    if (isRight) {
      this.world.resetCameraCenter()
    }
  }

  private onMouseMove = ({ x, y, w, h }: MouseInfo) => {
    // -1〜+1の範囲で現在のマウス座標を登録する
    this.world.onMouseMove((x / w) * 2 - 1, -(y / h) * 2 + 1)
  }

  private onClick = ({ x, y, w, h, isRight }: MouseInfo) => {
    // -1〜+1の範囲で現在のマウス座標を登録する
    this.world.onClick((x / w) * 2 - 1, -(y / h) * 2 + 1)
  }

  dispose() {
    this.worlds.forEach(w => w.dispose())

    window.removeEventListener("resize", this.resize)
    window.removeEventListener("keydown", this.onKeyDown)
    window.removeEventListener("keyup", this.onKeyUp)
    document.removeEventListener("visibilitychange", this.onVisibilityChange)
    this.mouseWatcher.dispose()

    this.events.off("changeWorld", this.changeWorld)
    this.events.off("changeArea", this.changeArea)
    this.events.off("areaChanged", this.areaChanged)
    this.events.off("moveToVendor", this.moveToVendor)
    this.events.off("openVendor", this.disable)
    this.events.off("leaveVendorDetail", this.enable)

    this.input.dispose()
  }

  start(): void {
    this.loop()
    this.world.start()
  }

  loop = () => {
    requestAnimationFrame(this.loop)
    if (!this.enabled) {
      return
    }

    TWEEN.update()

    // Move camera
    const deltaTime = this.clock.getDelta()
    this.world.move(deltaTime)

    this.renderer.clear()
    this.composer.renderer.clear()

    this.worlds.forEach(w => {
      w.loop()
      this.renderer.setRenderTarget(w.renderTarget)
      this.renderer.clear()
      w.render(this.renderer)
    })

    this.composer.render()
  }

  changeWorld = async (worldNum: number) => {
    if (isPC) {
      const prevWorld = this.world
      const nextWorld = this.worlds[worldNum]
      nextWorld.reset()

      if (prevWorld === nextWorld) {
        return
      }

      MouseWatcher.isEnabled = false
      nextWorld.setEnabled(true)

      prevWorld.leaveWorld(nextWorld.id)
      nextWorld.enterWorld(prevWorld.id)

      this.transitionPass.uniforms["tex1"].value =
        prevWorld.renderTarget.texture
      this.transitionPass.uniforms["tex2"].value =
        nextWorld.renderTarget.texture
      this.transitionPass.uniforms["fade"].value = 0

      await new Promise(resolve => {
        new TWEEN.Tween({ t: 0 })
          .to({ t: 1 }, 500)
          .easing(TWEEN.Easing.Cubic.InOut)
          .delay(1000)
          .onUpdate(({ t }) => {
            this.transitionPass.uniforms["fade"].value = t
          })
          .onComplete(resolve)
          .start()
      })

      prevWorld.setEnabled(false)
      MouseWatcher.isEnabled = true
      this.world = nextWorld
    } else {
      const prevWorldId = this.world.id

      // ローディング画面を表示しつつ、現在のworldを抜ける
      MouseWatcher.isEnabled = false
      const showingMask = new Promise(resolve =>
        setTimeout(() => {
          this.events.emit("loaded", false)
          resolve()
        }, 500)
      )
      await this.world.leaveWorld(worldNum)
      this.world.setEnabled(false)

      // 新しいワールドを作成
      this.world = await (worldNum === 0
        ? FoyerWorld.init(0, this.events, this.canvas)
        : BoothWorld.init(1, this.events, this.canvas))
      this.worlds = [this.world]

      this.transitionPass.uniforms[
        "tex1"
      ].value = this.world.renderTarget.texture
      this.transitionPass.uniforms[
        "tex2"
      ].value = this.world.renderTarget.texture

      // ローディング画面を隠しつつ、新しいワールドを表示
      showingMask.then(() => this.events.emit("loaded", true))
      this.world.setEnabled(true)
      await this.world.enterWorld(prevWorldId)
      MouseWatcher.isEnabled = true
    }

    this.events.emit("worldChanged", worldNum)
  }

  /**
   * エリア変更時、必要ならワールドを変更する。
   * ワールド内のエリア移動は各Worldクラスで行う。
   */
  changeArea = (area: Area): void => {
    if (area === "entrance" && this.world.id !== 0) {
      this.changeWorld(0)
    } else if (area === "rafael" && this.world.id !== 0) {
      this.changeWorld(0)
    } else if (area === "booth" && this.world.id !== 1) {
      this.changeWorld(1)
    }
  }

  areaChanged = (area: Area): void => {
    this.currentArea = area
  }

  setIsMoving(dir: MoveDir, isMoving: boolean): void {
    this.world.setMoving(dir, isMoving)
  }

  moveToVendor = async (id: string): Promise<void> => {
    if (this.world.id !== 1) {
      await this.changeWorld(1)
    }
    ;(this.world as BoothWorld).moveToVendor(id)
  }

  enable = (): void => {
    this.enabled = true
    this.canvas.style.display = "block"
    this.events.emit("playSE", `se/6_${Math.ceil(Math.random() * 8)}`)
  }

  disable = (): void => {
    this.enabled = false
    this.canvas.style.display = "none"
    this.events.emit("playSE", `se/5_${Math.ceil(Math.random() * 10)}`)
  }
}
