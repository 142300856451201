import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`ライナウト・アウトスホールンの作品はシンプルで抑制が効いた形と、金属、半透明ガラスと木材という限られた素材によって構成され、瞑想的な静けさを持っている。作品の多くは遠近法的な錯視を起こさせる。作品によって空間が形成され、置かれたその空間もその作品の一部となる。本書では、その作品と空間のバランスがページ上のイメージの配置へと注意深く変換され、余白が作品表象の一部となっている。作品制作における素材の極端な制限は、本書全体を通して厳しく抑制された色使いにも表れている。例えばエッセイが印刷されたページはそれぞれ微かに違う色付けがされ、文字はシルバーで印字されているが可読性は損なわれていない。そのような方向性のため、写真やリトグラフ、そして印刷も最新の注意と正確さを持って扱われている。主要パートに使われたコート紙とドローイングのパートに使用された非コート紙の対比は、ささやかながらも必然的かつ自然だ。シルバー・灰色の綴じ糸は、最初と最後に置かれた詳細な目次とクレジットの黒いページに使われているシルバーのインクと補完関係をなしているが、やややり過ぎの感があり、コンセプトに干渉していると感じる選考委員もいた。しかしそれでも、シルバーと薄い黒を用い、空押しされたカバーと共に、本書はアウトスホールンの作品が持つコンセプト、形状、そして姿勢を正当に表す模範的な作品集となっている。`}</p>
    <hr></hr>
    <p>{`出版社： Reinoud Oudshoorn, Amsterdam`}<br parentName="p"></br>{`
`}{`デザイン： DRAWSWORDS Studio (Rob van den Nieuwenhuizen), Amsterdam`}</p>
    <p>{`カテゴリー： アート、写真、建築`}<br parentName="p"></br>{`
`}{`アーティスト：Reinoud Oudshoorn`}<br parentName="p"></br>{`
`}{`言語：英語`}<br parentName="p"></br>{`
`}{`翻訳：Jessie Gordon`}<br parentName="p"></br>{`
`}{`写真：Peter Cox, Tom Elst, Tom Haartsen, Thomas Lenden, Rob van Uchelen`}<br parentName="p"></br>{`
`}{`イメージ編集：Reinoud Oudshoorn, Rob van den Nieuwenhuizen`}<br parentName="p"></br>{`
`}{`編集：Jessie Gordon, Rob van den Nieuwenhuizen`}<br parentName="p"></br>{`
`}{`用紙アドバイス：De Monsterkamer`}<br parentName="p"></br>{`
`}{`製作：Jos Morree Fine Books, Weesp`}<br parentName="p"></br>{`
`}{`リトグラフ：Marco Kokkelkoren, Amsterdam`}<br parentName="p"></br>{`
`}{`印刷：Zwaan Lenoir, Wormerveer; Grafiplaza, Mijdrecht`}<br parentName="p"></br>{`
`}{`製本：Boekbinderij Van Waarden, Zaandam`}<br parentName="p"></br>{`
`}{`フォント：Favorit Std Mono (Dinamo), Untitled Serif (Klim Type Foundry)`}<br parentName="p"></br>{`
`}{`サイズ（ミリ）：230 x 310 x 10`}<br parentName="p"></br>{`
`}{`ページ数：128`}<br parentName="p"></br>{`
`}{`発行部数：1,000`}<br parentName="p"></br>{`
`}{`価格：€ 45`}<br parentName="p"></br>{`
`}{`ISBN：978 90 9031672 7`}<br parentName="p"></br>{`
`}{`本文用紙：120gsm Keaykolour in the colours Basalt, Biscuit, Pastel Green (Antalis), 115gsm Munken Print White 15 (Antalis), 115gsm Arctic Volume Highwhite (Antalis), 120gsm PERGRAPHICA® Classic Smooth (Igepa)`}<br parentName="p"></br>{`
`}{`見返し：120gsm Keaykolour Basalt (Antalis)`}<br parentName="p"></br>{`
`}{`装丁：120gsm Gmund Cover Matt 71/Color 043 (Gmund) over 1.5 mm greyboard`}<br parentName="p"></br>{`
`}{`製本方法：糸かがりソフトカバーフラップ付き、薄い厚紙に最小限のチリ、銀糸綴じ`}<br parentName="p"></br>{`
`}{`特記事項：カバーに Boekbinderij Van Waarden による空押し加工と高精細スブリマスクリーンで印刷。中身の画像セクションは CMYK とスケルトンブラック、「Process」セクションは黒とスケルトンブラック、テキストセクションとタイトルページは Pantone877 で印刷、カバーは Pantone877 とスケルトン（Pantone877 と黒を混ぜた色）で印刷。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      