import React, { useEffect } from "react"
import styles from "./LoadingMask.module.scss"
import langSwitchCss from "./LanguageSwitcher.module.scss"
import { VabfLogo } from "./VabfLogo"
import { FormattedMessage } from "react-intl"

type LoadingMaskProps = Readonly<{
  isLoading: boolean
  version: string
  lang: string
}>

export const LoadingMask: React.FC<LoadingMaskProps> = ({
  isLoading,
  version,
  lang,
}) => {
  return (
    <div className={styles.wrapper} data-loading={isLoading}>
      <div className={styles.logo}>
        <VabfLogo />
        <p>NOV 16—23, 2020</p>
      </div>

      <div className={styles.version}>Ver. {version}</div>
      {lang === "ja" ? (
        <div>
          <div className={langSwitchCss.base}>
            <a href="/en/">EN</a>
          </div>
          <div className={styles.simpleLink}>
            <a href="/exhibitors/list">
              <FormattedMessage id={`simpleHtmlVersion`} />
            </a>
          </div>
        </div>
      ) : (
        <div>
          <div className={langSwitchCss.base}>
            <a href="/">JA</a>
          </div>
          <div className={styles.simpleLink}>
            <a href="/en/exhibitors/list">
              <FormattedMessage id={`simpleHtmlVersion`} />
            </a>
          </div>
        </div>
      )}
    </div>
  )
}
