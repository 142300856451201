import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.en.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Reinoud Oudshoorn’s work consists of simple, controlled shapes in a limited range of materials – predominately metal, matt glass and wood – that evoke a sense of contemplation. Many of the works play with the illusion of perspective and the suggestion of space this can create, whereby the space in and around the sculptures becomes part of the work. In the book, this balance between work and space is translated into painstaking placement of the images on the page, with the white space becoming part of the representations of the works. The extreme restraint in the materials used to create the works is reflected in the tightly controlled, minimal use of colour throughout the book – for example on the different, but always lightly tinted, pages on which the essays are printed in silver ink, but always remaining legible. Naturally given the approach taken, the photography, lithography and printing have been executed with great care and precision. The difference between the coated paper used for the pictorial sections and the uncoated paper for the section with the drawings feels logical, natural and subtle. The silver-grey binding thread complements the silver ink, also used on the black pages at the front and back of the book for the extensive index and credits – some jury members considered this slightly too much of a good thing, which here and there interferes with the concept. With its cover, however – printed in a mix of silver and half-scale black and reverse blind stamped – this is an exemplary book that does justice to Oudshoorn’s work in terms of content, form and attitude.`}</p>
    <hr></hr>
    <p>{`Category: Art, photo and architecture books`}<br parentName="p"></br>{`
`}{`Artist: Reinoud Oudshoorn`}<br parentName="p"></br>{`
`}{`Language: English`}<br parentName="p"></br>{`
`}{`Translator: Jessie Gordon`}<br parentName="p"></br>{`
`}{`Photographer: Peter Cox, Tom Elst, Tom Haartsen, Thomas Lenden, Rob van Uchelen`}<br parentName="p"></br>{`
`}{`Image editor: Reinoud Oudshoorn, Rob van den Nieuwenhuizen`}<br parentName="p"></br>{`
`}{`Editor-in-chief: Jessie Gordon, Rob van den Nieuwenhuizen`}<br parentName="p"></br>{`
`}{`Paper advice: De Monsterkamer`}<br parentName="p"></br>{`
`}{`Producer: Jos Morree Fine Books, Weesp`}<br parentName="p"></br>{`
`}{`Lithographer: Marco Kokkelkoren, Amsterdam`}<br parentName="p"></br>{`
`}{`Printer: Zwaan Lenoir, Wormerveer; Grafiplaza, Mijdrecht`}<br parentName="p"></br>{`
`}{`Binder: Boekbinderij Van Waarden, Zaandam`}<br parentName="p"></br>{`
`}{`Font: Favorit Std Mono (Dinamo), Untitled Serif (Klim Type Foundry)`}<br parentName="p"></br>{`
`}{`Size in mm: 230 x 310 x 10`}<br parentName="p"></br>{`
`}{`Number of pages: 128`}<br parentName="p"></br>{`
`}{`Print run: 1,000`}<br parentName="p"></br>{`
`}{`Price: € 45`}<br parentName="p"></br>{`
`}{`ISBN: 978 90 9031672 7`}<br parentName="p"></br>{`
`}{`Paper for interior: 120gsm Keaykolour in the colours Basalt, Biscuit, Pastel Green (Antalis), 115gsm Munken Print White 15 (Antalis), 115gsm Arctic Volume Highwhite (Antalis), 120gsm PERGRAPHICA® Classic Smooth (Igepa)`}<br parentName="p"></br>{`
`}{`Endpapers: 120gsm Keaykolour Basalt (Antalis)`}<br parentName="p"></br>{`
`}{`Binding: 120gsm Gmund Cover Matt 71/Color 043 (Gmund) over 1.5 mm greyboard`}<br parentName="p"></br>{`
`}{`Binding style: Sewn hardcover with flat spine, thin boards with minimal square, binding with silvery threads`}<br parentName="p"></br>{`
`}{`Special features: The cover contains blind debossing by Boekbinderij Van Waarden and is printed in Sublima screen. Image sections are printed in CMYK and skeleton black, the process sections are printed in black and skeleton black, and the typographic sections and wrappers are printed in Pantone 877. The cover is printed in Pantone 877 and skeleton (mixture of Pantone 877 and black).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      