import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/VabfEvents.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SocialShare = makeShortcode("SocialShare");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className="youtube__wrap">
  <iframe width="560" height="315" src="https://www.youtube.com/embed/ENXpo812kN8?controls=0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
    <div className="share__2col">
  <div className="share__2col__link">
    <a href="https://youtu.be/ENXpo812kN8" target="_blank">
      Youtube Live をみる
    </a>
  </div>
  <div className="share__2col__icon">
    <SocialShare title={props.pageContext.frontmatter.title} mdxType="SocialShare" />
  </div>
    </div>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "980px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "75.10204081632654%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAeySbJH/xAAYEAADAQEAAAAAAAAAAAAAAAAAARECIf/aAAgBAQABBQK9vaayhYpGf//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABgQAAIDAAAAAAAAAAAAAAAAABARADKB/9oACAEBAAY/Ai1KrR//xAAcEAEAAgMAAwAAAAAAAAAAAAABABEhMUFRccH/2gAIAQEAAT8hD1W8S1n7C3IoVd9wwLkPOUYNT//aAAwDAQACAAMAAAAQww//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAdEAEAAgICAwAAAAAAAAAAAAABESEAMWGRUbHR/9oACAEBAAE/EBqXBCnnApU1Q067xhPtjfTmCk3w4FuwkiPivuEuSGta7z//2Q==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/b1f4be23c45fa99a1146db83510a8b27/1a3b7/img_1.webp 245w", "/static/b1f4be23c45fa99a1146db83510a8b27/fb835/img_1.webp 490w", "/static/b1f4be23c45fa99a1146db83510a8b27/096d3/img_1.webp 980w", "/static/b1f4be23c45fa99a1146db83510a8b27/3dcc7/img_1.webp 1470w", "/static/b1f4be23c45fa99a1146db83510a8b27/061f9/img_1.webp 1960w", "/static/b1f4be23c45fa99a1146db83510a8b27/85c38/img_1.webp 7297w"],
          "sizes": "(max-width: 980px) 100vw, 980px",
          "type": "image/webp"
        }}></source>{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/b1f4be23c45fa99a1146db83510a8b27/c168b/img_1.jpg 245w", "/static/b1f4be23c45fa99a1146db83510a8b27/bb66a/img_1.jpg 490w", "/static/b1f4be23c45fa99a1146db83510a8b27/2f919/img_1.jpg 980w", "/static/b1f4be23c45fa99a1146db83510a8b27/a7235/img_1.jpg 1470w", "/static/b1f4be23c45fa99a1146db83510a8b27/331e6/img_1.jpg 1960w", "/static/b1f4be23c45fa99a1146db83510a8b27/0ddde/img_1.jpg 7297w"],
          "sizes": "(max-width: 980px) 100vw, 980px",
          "type": "image/jpeg"
        }}></source>{`
        `}<img parentName="picture" {...{
          "className": "gatsby-resp-image-image",
          "src": "/static/b1f4be23c45fa99a1146db83510a8b27/2f919/img_1.jpg",
          "alt": "img 1",
          "title": "img 1",
          "loading": "lazy",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          }
        }}></img>{`
      `}</picture>{`
    `}</span>
    <h2>{`清水陽子(MOT アニュアル 2020 展 参加作家) オンライントーク`}</h2>
    <p>{`自然、生命、宇宙のメカニズムをテーマに、科学と芸術を融合するテクノロジーやインスタレーションを手掛ける清水陽子。VIRTUAL ART BOOK FAIR（VABF）会期中、開催の企画展「MOT アニュアル 2020 　透明な力たち」で展示されている作品を中心に、これまでのプロジェクトを紹介します。また、作家が関心のある書籍についてもご紹介いたします。`}</p>
    <ul>
      <li parentName="ul">
        <span className="font__small">
  開催内容は、都合により変更になる場合がございます。予めご了承ください。
        </span>
      </li>
    </ul>
    <div {...{
      "className": "content__info"
    }}>{`
  `}<figure parentName="div" {...{
        "className": "content__info__img"
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMEBf/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABl3uZvubIiO8Jg//EABsQAAICAwEAAAAAAAAAAAAAAAECAyIAEBMy/9oACAEBAAEFApbzp4yRB1AsWYFRfX//xAAVEQEBAAAAAAAAAAAAAAAAAAAQAf/aAAgBAwEBPwEh/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAGhAAAgMBAQAAAAAAAAAAAAAAAQIAESEQcf/aAAgBAQAGPwJyxqovnEer3ZYmLAO//8QAGhAAAwEBAQEAAAAAAAAAAAAAAAERIVExYf/aAAgBAQABPyFfaSTpaU3XGi+umkalefRUIxKYSlEL4jxYJtq0/9oADAMBAAIAAwAAABAT3wL/xAAXEQEBAQEAAAAAAAAAAAAAAAARAAEQ/9oACAEDAQE/EFm05z//xAAXEQEBAQEAAAAAAAAAAAAAAAABECEx/9oACAECAQE/EORAcn//xAAdEAEBAAMAAgMAAAAAAAAAAAABEQAhQTFRYYHw/9oACAEBAAE/EBOCI60gH7uGE4H2ZhQZKqQkOGOHHA3E19HEKdMJ8PxjVHFc8kqW1tmINAQwGlXes//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/0fe0cb7394808a1d1645ea981cecfd36/1a3b7/img_2.webp 245w", "/static/0fe0cb7394808a1d1645ea981cecfd36/fb835/img_2.webp 490w", "/static/0fe0cb7394808a1d1645ea981cecfd36/e2a84/img_2.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/0fe0cb7394808a1d1645ea981cecfd36/c168b/img_2.jpg 245w", "/static/0fe0cb7394808a1d1645ea981cecfd36/bb66a/img_2.jpg 490w", "/static/0fe0cb7394808a1d1645ea981cecfd36/d5c47/img_2.jpg 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/0fe0cb7394808a1d1645ea981cecfd36/d5c47/img_2.jpg",
              "alt": "img 2",
              "title": "img 2",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
  `}</figure>{`
  `}<div parentName="div" {...{
        "className": "content__info__title"
      }}>{`
    `}<p parentName="div">{`清水陽子`}</p>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "content__info__text"
      }}>{`
    `}<p parentName="div">{`
      清水陽子（1977－）は、自然、生命、宇宙のメカニズムをテーマに、微生物、細胞、DNA、有機物などのミクロの世界をはじめ、植物、自然、地球全体におけるマクロの現象まで、その美しさを可視化する作品を制作している。代表作に、微生物がコロニーを形成し、カラフルな培地で美しく成長・繁栄・衰退を繰り返す《Cycles
      of
      Life》、光合成によって葉に高解像度のグラフィックプリントを行う《Photosynthegraph》など。
      近年の主な個展に「Biodesign Lab Exhibition」（NARS
      Foundation、ニューヨーク、2019年）、「Layers of Life」（LAD
      GALLERY、2017年）、「The Clean Room」（LAD
      GALLERY、2015年）、グループ展に「India Design
      ID」（ニューデリー市、インド、2019年）、「Link of
      Life」（資生堂ギャラリー、2017年）、「アルスエレクトロニカ・フェスティバル」（リンツ市、オーストリア、2016年）。2020年よりアルスエレクトロニカ・フューチャーラボのアーティスト／リサーチャーとしてオーストリアのリンツ市で活動する。
    `}</p>{`
  `}</div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      