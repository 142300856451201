import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`この小さな本は、台湾人デザイナーチェン・ジェンの作品を再構築したもので、アムステルダムのデザイン事務所のランチテーブルでオランダ文化を吸収しようとした彼女の試みを記録したものだ。必死だった彼女は、テーブルで周りの人たちが何を食べどのように行動するのか（彼女にとっては未知のものごとだった）をこっそりノートにとっていた。それらのノートはその後、「ランチテーブルでの行動」を観察したパフォーマンスの台本となる。本書はそのパフォーマンスをテキスト化したもので、手を加えられたメモ、ページ上所狭しと書かれた行動の記録（噛んで、噛んで、フォークでサラダをすくって）、そしてその行為や食べ物が載った皿の写真なども含む。半透過の紙を用いることで、観察したものごとの細部にズームインしていくようなプロセスも効果的に見せている。チェンは人類学者のようにテーブルマナーを解体し、驚くような、そして時には恥ずかしくも愉快な、オランダの「ランチテーブル文化」についての考察を作り出している。今年の選考でいくつか目にしたことだが、ここでも半透明の紙が効果的に使われている。例えばタイトルとサブタイトルは部分ごとに次の、もしくはその次のページに印刷されており、「他者である、他の場所にいる」ことを明確に示している。書籍全体を通して、ピーナツバターやチョコレートクリームを塗りたくったパンのような茶色で印刷されている。`}</p>
    <hr></hr>
    <p>{`出版社： Jan van Eyck Academie, Maastricht`}<br parentName="p"></br>{`
`}{`コンセプト・デザイン：Chen Jhen, Maastricht`}</p>
    <p>{`カテゴリー： アート、写真、建築`}<br parentName="p"></br>{`
`}{`アーティスト：Chen Jhen`}<br parentName="p"></br>{`
`}{`編集：Wang Huai Yuan, Chen Jhen`}<br parentName="p"></br>{`
`}{`言語：英語`}<br parentName="p"></br>{`
`}{`写真：Chen Jhen`}<br parentName="p"></br>{`
`}{`イメージ編集：Chen Jhen`}<br parentName="p"></br>{`
`}{`リトグラフ：Chen Jhen, Maastricht; Charles Nypels Lab, Jan van Eyck Academie, Maastricht`}<br parentName="p"></br>{`
`}{`印刷：Charles Nypels Lab, Jan van Eyck Academie, Maastricht`}<br parentName="p"></br>{`
`}{`製本：Charles Nypels Lab, Jan van Eyck Academie, Maastricht`}<br parentName="p"></br>{`
`}{`フォント：Lyon (Commercial Type), Ceremony (Optimo), Akzidenz Grotesk (Berthold Type Foundry)`}<br parentName="p"></br>{`
`}{`サイズ（ミリ）：120 x 190 x 8`}<br parentName="p"></br>{`
`}{`ページ数：192`}<br parentName="p"></br>{`
`}{`発行部数：100`}<br parentName="p"></br>{`
`}{`価格：€ 38`}<br parentName="p"></br>{`
`}{`ISBN：978 90 72076 98 4`}<br parentName="p"></br>{`
`}{`本文用紙：60gsm IBO One (Igepa)`}<br parentName="p"></br>{`
`}{`カバー：200gsm Fuego® Matt Natural (Papyrus)`}<br parentName="p"></br>{`
`}{`製本方法：糸かがりソフトカバー`}<br parentName="p"></br>{`
`}{`特記事項：リソグラフ。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      