import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/VabfEvents.en.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SocialShare = makeShortcode("SocialShare");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className="youtube__wrap">
  <iframe width="560" height="315" src="https://www.youtube.com/embed/YscpoQi6Aso?controls=0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
    <div className="share__2col">
  <div className="share__2col__link">
    <a href="https://youtu.be/YscpoQi6Aso" target="_blank">
      Youtube Live をみる
    </a>
  </div>
  <div className="share__2col__icon">
    <SocialShare title={props.pageContext.frontmatter.title} mdxType="SocialShare" />
  </div>
    </div>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "980px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "133.46938775510205%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMCBAUB/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAABjdylijgbK4BRHB//xAAdEAEAAgICAwAAAAAAAAAAAAABAgMABBESISMx/9oACAEBAAEFAtN9+3d0j2chKVV1smWHwJLZUAgMZOXvjjP/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAcEAACAgIDAAAAAAAAAAAAAAABEBESADECgZH/2gAIAQEABj8C6yo2VaJYj0rS4r//xAAcEAEAAgMBAQEAAAAAAAAAAAABABEhMUFRYYH/2gAIAQEAAT8hqYdUXTbHwhwGGiE8+RwX9uDxEQA3kOTBnC9lQwnojVXhZR3mf//aAAwDAQACAAMAAAAQgAEA/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAHhABAAICAwADAAAAAAAAAAAAAQARITFBUWFxgfD/2gAIAQEAAT8QTOG39VHto60fB7CGTbanLCHqu1rxvjUDFOfR/EejdvNlMDLhsOxXMdNDFthV15EF6NkKlOJQrNneGGebdrP/2Q==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/fde6d73919592feafebb1f60574675ca/1a3b7/img_1.webp 245w", "/static/fde6d73919592feafebb1f60574675ca/fb835/img_1.webp 490w", "/static/fde6d73919592feafebb1f60574675ca/096d3/img_1.webp 980w", "/static/fde6d73919592feafebb1f60574675ca/cce77/img_1.webp 1378w"],
          "sizes": "(max-width: 980px) 100vw, 980px",
          "type": "image/webp"
        }}></source>{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/fde6d73919592feafebb1f60574675ca/c168b/img_1.jpg 245w", "/static/fde6d73919592feafebb1f60574675ca/bb66a/img_1.jpg 490w", "/static/fde6d73919592feafebb1f60574675ca/2f919/img_1.jpg 980w", "/static/fde6d73919592feafebb1f60574675ca/170bd/img_1.jpg 1378w"],
          "sizes": "(max-width: 980px) 100vw, 980px",
          "type": "image/jpeg"
        }}></source>{`
        `}<img parentName="picture" {...{
          "className": "gatsby-resp-image-image",
          "src": "/static/fde6d73919592feafebb1f60574675ca/2f919/img_1.jpg",
          "alt": "img 1",
          "title": "img 1",
          "loading": "lazy",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          }
        }}></img>{`
      `}</picture>{`
    `}</span>
    <p>{`これまで TOKYO ART BOOK FAIR 開催初日のプレビューでは欠かせないイベントの一つだったミュージックパフォーマンス。今年は、本来リアル開催でプレビュー日となっていたはずの 20 日(金)の前日にあたる 19 日(木)にバンド「Deer Revenge」によるライブセッションを配信いたします。`}</p>
    <p>{`本ライブでは、写真家ホンマタカシ が自粛期間中に都内で撮影した写真作品によるスライドに合わせて「Deer Revenge」によるパフォーマンスをお楽しみに頂ける他、ライブ終了後から VABF 会期終了までの期間限定で、ホンマタカシ の写真作品を使用したネットプリント版ブックレットの無料ダウンロードコードを公開いたします。（全国のセブンイレブンのマルチコピー機にて印刷可能）`}</p>
    <ul>
      <li parentName="ul">
        <span className="font__small">無料で視聴いただけます。</span>
      </li>
      <li parentName="ul">
        <span className="font__small">
  インターネット回線の状況やサーバー負荷、その他視聴者の PC
  環境により、映像が途切れる、または停止するなど正常に視聴できない事がございます。
        </span>
      </li>
      <li parentName="ul">
        <span className="font__small">
  配信終了後も TOKYO ART BOOK FAIR の youtube
  チャンネルで動画を視聴いただけます。
        </span>
      </li>
    </ul>
    <div className="content__noimginfo">
  <div className="content__noimginfo__title">
    <p>ホンマタカシ / 写真家</p>
  </div>
  <div className="content__noimginfo__text">
    <p>
      写真集多数、著書に『たのしい写真　よい子のための写真教室』がある。近年の作品集に「TRAILS」（MACK）、「THE
      NARCISSISTIC CITY」（MACK）がある。また2019年に「Symphony その森の子供
      mushrooms from the forest」「Looking through Le Corbusier
      windows」を刊行。 現在、東京造形大学大学院 客員教授。
    </p>
  </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      