import React, { useEffect, useState } from "react"
import Layout from "../../../layouts/ja"
import { loadExhibitors } from "../../../api"
import SearchExhibitorsList from "../../../components/SearchExhibitorsList"
import { navigate } from "gatsby"
import CommonDoc from "../../../components/CommonDoc"
import css from "./index.module.scss"

function IndexPage(props) {
  // JSONからロードした出店者リスト
  const [exhibitors, setExhibitors] = useState([])

  // コンポーネントのマウント時に出店者リストをロードする
  useEffect(() => {
    loadExhibitors().then(_exhibitors => {
      const exhibitors = JSON.parse(JSON.stringify(_exhibitors))
      setExhibitors(exhibitors)
    })
  }, [])

  return (
    <Layout
      location={props.location}
      title={`Exhibitors List`}
      show3dLayer={false}
    >
      <CommonDoc>
        <div className={`container`}>
          <div className={`content`}>
            <div className={`content__head`}>
              <h1 className={`haeding__lev1`}>
                VIRTUAL ART <br className={`only-sp`} />
                BOOK FAIR へ<br className={`only-sp`} />
                ようこそ
              </h1>
            </div>
            <div className={`content__lead`}>
              <p>
                VABFでは 3D
                空間で出展者ブースを探しながらフェアを楽しんでいただく体験をご提供します。
                <br className={`only-pc`} />
                皆さまに 3D
                空間のアートブックフェアを体験を楽しんでいただきたい一方で、スマートフォンや一部の端末では十分に処理が追いつかない場合が考えられます。
                <br className={`only-pc`} />
                このページでは 3D
                体験を十分にお楽しみいただけない環境でもアートブックフェアをご覧いただけるよう、出展者ブースをテキストリンクでご紹介します。
              </p>
            </div>
          </div>
        </div>
      </CommonDoc>

      {/* フィルターされた出店者を描画 */}
      <div className={css.exhivitorslist}>
        <SearchExhibitorsList
          exhibitors={exhibitors}
          sortKey={`name`}
          lang={`ja`}
          doModalClose={() => {
            // console.log(`modal close`)
          }}
          doClickCallBack={id => {
            navigate(`/exhibitors/${id}`, {
              state: { modal: true, id: id },
            })
          }}
        />
      </div>
      <CommonDoc>
        <div className={`container`}>
          <div className={`content`}>
            <div className={`editor__content`}>
              <section className={css.info}>
                <h2>本サイトの閲覧推奨環境</h2>
                <p>
                  本サイトを正常かつ快適にご利用いただくためには、Google Chrome
                  最新版を推奨いたします。
                  <br className={`only-pc`} />
                  なお、以下のOS・ブラウザ以外の環境では閲覧できない可能性がございますのでご了承ください。
                </p>
              </section>
              <section className={css.info}>
                <h2>推奨 OS</h2>
                <ul>
                  <li>Windows / Mac</li>
                  <li>
                    iOS / Android
                    でも動作しますが、スペックにより表示出来ない場合があります
                  </li>
                </ul>
              </section>
              <section className={css.info}>
                <h2>推奨ブラウザ</h2>
                <ul>
                  <li>Google Chrome 最新版</li>
                  <li>(レティーナディスプレイや Safari では、動作が不安定になる場合があります)</li>
                </ul>
              </section>
            </div>
          </div>
        </div>
      </CommonDoc>
    </Layout>
  )
}

export default IndexPage
