import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`またパーソナルな叙事詩、また写真を用いたリサーチプロジェクト、またもや暗い雰囲気たっぷりのイメージ。しかし本書のメランコリーと憂鬱はかなり研ぎ澄まされている。『Odsłonić』においてマリア・ダブロフスキは母親と共にウクライナを旅し、家族の歴史を探し求める。土地を追われた人々、戦争犯罪とトラウマの物語がまずイメージとして語られるが、それはドキュメンタリーというよりもほのめかしに近い。光のわずかな場所で撮影されており、暗くてよく見えない場所や風景、そして屋内にいる人々の姿はほとんど見えず、暗闇がイメージを覆っている。それは、紙の選択にも表れており、ページは茶色と黒に微かに色付けされ、本の小口も黒く塗られている。人々の手や顔、そして室内の様子がそれを包む暗闇にほぼ溶け込んでいるこれらの写真は、リトグラフ技術者や印刷業者にとっては高い壁となる。しかし技術者と写真家、そしてデザイナーの働きによって、その高い壁を超えている。それが本書の強さだろう。スイス式の切り付け表紙が本を開きやすくしている。わずかしかないタイポグラフィも作品を効果的に支えている。例えばカバーの裏に隠された参照情報を記載した地図は、撮られた写真を地理的な文脈に配置する。霧深く、繊細ながら、強く豊かな表現を有した本だ。`}</p>
    <hr></hr>
    <p>{`出版社： Self-published`}<br parentName="p"></br>{`
`}{`デザイン： Studio Another Day, Nijmegen`}</p>
    <p>{`カテゴリー： アート、写真、建築`}<br parentName="p"></br>{`
`}{`編集：Maria Dabrowski`}<br parentName="p"></br>{`
`}{`言語：英語`}<br parentName="p"></br>{`
`}{`翻訳：Maja Ciumak`}<br parentName="p"></br>{`
`}{`写真：Maria Dabrowski`}<br parentName="p"></br>{`
`}{`イメージ編集：Maria Dabrowski, Yorick de Vries`}<br parentName="p"></br>{`
`}{`用紙アドバイス：De Monsterkamer`}<br parentName="p"></br>{`
`}{`製作：ArtLibro, Breda`}<br parentName="p"></br>{`
`}{`リトグラフ：Cassochrome, Watergem (BE)`}<br parentName="p"></br>{`
`}{`印刷：Cassochrome, Watergem (BE)`}<br parentName="p"></br>{`
`}{`製本：Boekbinderij Sepeli, Evergem (BE)`}<br parentName="p"></br>{`
`}{`フォント：Euclid Circular B (Swiss Typefaces)`}<br parentName="p"></br>{`
`}{`サイズ（ミリ）：210 x 280 x 8`}<br parentName="p"></br>{`
`}{`ページ数：96`}<br parentName="p"></br>{`
`}{`発行部数：500`}<br parentName="p"></br>{`
`}{`価格：€ 38`}<br parentName="p"></br>{`
`}{`ISBN：N / a`}<br parentName="p"></br>{`
`}{`本文用紙：90gsm EOS 2.0 (Igepa), 140gsm Woodstock Noce (Fedrigoni)`}<br parentName="p"></br>{`
`}{`カバー：320gsm Pop’Set Hot Brown (Antalis)`}<br parentName="p"></br>{`
`}{`製本方法：スイス装、表表紙フラップ付き、薄茶色の糸のかがり綴じ`}<br parentName="p"></br>{`
`}{`特記事項：高密度なスクリーンを使用した UV オフセット印刷。カバーはオペークホワイト二度と高密度な黒で印刷、カバーと背に Buromac（ブルージュ、ベルギー）による白（Kurz Colorit911）の箔押し。小口に Critis(ベールネム、ベルギー)によるカバーと同色印刷。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      