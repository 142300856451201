import React, { Component } from "react"

import Layout from "../../../layouts/en"
import { Link } from "gatsby"
import css from "./index.module.scss"
import { Helmet } from "react-helmet"
import ReactPlayer from "react-player"

class IndexPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentTab: ``,
      open: false,
    }

    this._anchorClicked = this._anchorClicked.bind(this)
  }

  _anchorClicked(e) {
    const href = e.target.getAttribute(`href`)
    const targetId = href.replace(/#/g, "")

    if (this.state.currentTab !== targetId) {
      this.setState(state => ({
        currentTab: targetId,
        open: true,
      }))
    } else {
      if (this.state.open) {
        this.setState(state => ({
          currentTab: "",
          open: false,
        }))
      } else {
        this.setState(state => ({
          currentTab: targetId,
          open: true,
        }))
      }
    }

    e.preventDefault()
    return false
  }

  render() {
    return (
      <Layout
        location={this.props.location}
        title={`Fanfre Exhibition`}
        invertColor={true}
      >
        <Helmet
          bodyAttributes={{
            class: "fanfare",
          }}
        />

        {this.state.currentTab === `content1` && (
          <div className={css.mp3Player}>
            <ReactPlayer
              url="https://vabf-production.s3-ap-northeast-1.amazonaws.com/storage/fanfare/unlearning.mp3"
              playing={true}
            />
          </div>
        )}
        {this.state.currentTab === `content2` && (
          <div className={css.mp3Player}>
            <ReactPlayer
              url="https://vabf-production.s3-ap-northeast-1.amazonaws.com/storage/fanfare/display.mp3"
              playing={true}
            />
          </div>
        )}
        {this.state.currentTab === `content3` && (
          <div className={css.mp3Player}>
            <ReactPlayer
              url="https://vabf-production.s3-ap-northeast-1.amazonaws.com/storage/fanfare/connecting.mp3"
              playing={true}
            />
          </div>
        )}

        <section className={css.fanfare__container + ` container`} id={`top`}>
          <div className={`content`}>
            <div className={css.fanfare__head + ` content__head`}>
              <h1 className={css.kotebakura1 + ` haeding__lev1`}>fanfare</h1>
            </div>

            <div className={css.fanfare__read}>
              <p>
                The field of graphic design contains powerful tools to seduce,
                narrate and direct the paths from which we navigate our everyday
                life. This presents graphic design as a mediator – a keystone
                for bridging motivations, sectors and societal groups. While
                such considerations come to mimic corporate realms of innovation
                and technocratic developments, they also reflect the importance
                of a critical graphic design vocabulary. With this in mind, we
                direct a focus to graphic design projects that dare to explore
                the ways of forming, triggering and parasiting communication
                solutions. <br />
                <br />
                For TOKYO ART BOOK FAIR fanfare presents, Unlearn, Display,
                Connect – a reflection of the working attitudes and explorative
                ways of publishing detected in- and around fanfare.
              </p>
            </div>

            <nav className={css.fanfare__navi + ` only-pc`}>
              <div className={css.fanfare__navi__unit}>
                <p className={css.fanfare__navi__unit__title}>UNLEARN</p>
                <figure className={css.fanfare__navi__unit__img}>
                  <img src="/images/fanfare/icon1.png" alt="" />
                </figure>
                <a
                  href={`/images/fanfare/Unlearn.pdf`}
                  target={`_blank`}
                  className={
                    css.fanfare__navi__unit__pdf + ` ` + css.fanfare__btn
                  }
                >
                  SCRIPT
                </a>
                <a
                  href={`#content1`}
                  onClick={this._anchorClicked}
                  className={css.fanfare__navi__unit__play}
                >
                  {this.state.currentTab === "content1" && this.state.open
                    ? `<`
                    : `>`}
                </a>
                <p className={css.fanfare__navi__unit__time}>(01:16:07)</p>
              </div>

              <div className={css.fanfare__navi__unit}>
                <p className={css.fanfare__navi__unit__title}>DISPLAY</p>
                <figure className={css.fanfare__navi__unit__img}>
                  <img src="/images/fanfare/icon2.png" alt="" />
                </figure>
                <a
                  href={`/images/fanfare/Display.pdf`}
                  target={`_blank`}
                  className={
                    css.fanfare__navi__unit__pdf + ` ` + css.fanfare__btn
                  }
                >
                  SCRIPT
                </a>
                <a
                  href={`#content2`}
                  onClick={this._anchorClicked}
                  className={css.fanfare__navi__unit__play}
                >
                  {this.state.currentTab === "content2" && this.state.open
                    ? `<`
                    : `>`}
                </a>
                <p className={css.fanfare__navi__unit__time}>(01:19:58)</p>
              </div>

              <div className={css.fanfare__navi__unit}>
                <p className={css.fanfare__navi__unit__title}>CONNECT</p>
                <figure className={css.fanfare__navi__unit__img}>
                  <img src="/images/fanfare/icon3.png" alt="" />
                </figure>
                <a
                  href={`/images/fanfare/Connect.pdf`}
                  target={`_blank`}
                  className={
                    css.fanfare__navi__unit__pdf + ` ` + css.fanfare__btn
                  }
                >
                  SCRIPT
                </a>
                <a
                  href={`#content3`}
                  onClick={this._anchorClicked}
                  className={css.fanfare__navi__unit__play}
                >
                  {this.state.currentTab === "content3" && this.state.open
                    ? `<`
                    : `>`}
                </a>
                <p className={css.fanfare__navi__unit__time}>(01:26:51)</p>
              </div>
            </nav>

            <nav className={css.fanfare__navi + ` only-sp`}>
              <div className={css.fanfare__navi__unit}>
                <p className={css.fanfare__navi__unit__title}>UNLEARN</p>
                <figure className={css.fanfare__navi__unit__img}>
                  <img src="/images/fanfare/icon1.png" alt="" />
                </figure>
                <a
                  href={`/images/fanfare/Unlearn.pdf`}
                  target={`_blank`}
                  className={
                    css.fanfare__navi__unit__pdf + ` ` + css.fanfare__btn
                  }
                >
                  SCRIPT
                </a>
                <a
                  href={`#content1`}
                  onClick={this._anchorClicked}
                  className={css.fanfare__navi__unit__play}
                >
                  {this.state.currentTab === "content1" && this.state.open
                    ? `<`
                    : `>`}
                </a>
                <p className={css.fanfare__navi__unit__time}>(01:16:07)</p>
              </div>
            </nav>
            <article
              className={css.fanfare__article}
              id={`content1`}
              style={
                this.state.currentTab !== "content1"
                  ? { display: `none` }
                  : { display: `block` }
              }
            >
              <div className={`content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h1 className={css.kotebakura1 + ` haeding__lev2`}>
                      UNLEARN
                    </h1>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    Educational responsibilities, learning conditions,
                    positioning skills, and international influences.
                  </p>
                  <p>
                    The focus on learning derives from the observation of design
                    initiatives which are driven by the desire to convey and
                    circulate knowledge. Seen in this light alternative
                    educational structures, experiential learning curves, and a
                    drive for approaching learning in an activating, playful and
                    reflective manner is the centre of attention.
                  </p>
                  <p>
                    <a
                      href={`https://soundcloud.com/user-48803956/unlearning`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      Listen the audio at Sound Cloud
                    </a>
                  </p>
                </div>
              </div>

              <div className={css.fanfare__member + ` content`}>
                <div className={css.kotebakura1 + ` editor__content`}>
                  <h2>
                    FANFARE, FREJA KIR (FK)
                    <br />
                    JA JA JA NEE NEE NEE, FEMKE DEKKER (FD)
                    <br />
                    GERRIT RIETVELD ACADEMIE, DAVID BENNEWITH (DB)
                    <br />
                    SANDBERG INSTITUUT, JURGEN BEY (JB)
                    <br />
                    ROYAL ACADEMY OF ART THE HAGUE, ROOSJE KLAP (RK)
                  </h2>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>FK:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    You're all having the thing in common that you to some
                    extent direct a program or larger institution. Along with
                    this position of course comes a responsibility in terms of
                    what the students get away from it. When reading the
                    contribution from the Gerrit Rietveld Graphic Design
                    Department for the Graphic Magazine last year, you, David,
                    refer to a student quote in the intro of the magazine which
                    says:
                  </p>
                  <p>
                    "I feel guilty towards my ignorance and lack of knowledge."
                  </p>
                  <p>
                    You mention this as a quote that made an impression, and
                    also one to bring along not only as a student but also as an
                    educator. This was one or two years ago, so things might
                    have changed, but it did make me wonder if we could use this
                    quote as a stepping stone for sharing thoughts on the
                    responsibility of directing a design education?
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>DB:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    That was on a poster in a critique from a student, Yunie
                    Chae, who is also a person whose work I admire very much. To
                    use and present the format of a poster so personally in an
                    educational context touched me quite deeply. And in terms of
                    responsibility... of course, we have a responsibility to
                    teach, but I would say that responsibility is a shared
                    responsibility and that students also have a responsibility
                    to learn. So in that sense, I think there's an exchange
                    happening in school.
                    <br />
                    But one thing that I've experienced, is that I think
                    students are becoming more aware of, what they want from a
                    school, and what's on offer. And that has two sides; the
                    consumer side – which is maybe the least interesting side of
                    it, but also on the other side, an awareness or
                    understanding or pressure on what the school can offer you.
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>FK:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    So speaking of the relevance of the students to form a
                    criticality... When reading through the program descriptions
                    of the different schools (KABK, Sandberg Instituut, and the
                    Gerrit Rietveld), it seems that teaching the student to be a
                    critical thinker and to position themselves within their
                    field, is a returning key point of relevance across all the
                    schools. <br />
                    With that in mind and in order to scale a bit out: Do you
                    think that's something which to some extent represents a DNA
                    of Dutch design education, or could we think a bit about
                    what elements that are a part of Dutch education?
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>RK:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    Yeah, well, I have a bit of a fierce opponent of Dutch
                    education in that sense, because I suppose we are an
                    international community, and I feel very much that the
                    individual academy choices lead to a specific
                    responsibility. In this case, our three schools might be
                    comparable, but a school in Groningen or in Rotterdam might
                    be completely different and focus a lot more on skills on
                    crafts, which is also good. You know, there's no good or
                    bad, I think it's just a choice that we consciously made.{" "}
                    <br />
                    Being in The Hague, with all the ministries around us – the
                    ministry of education is only 10 steps away as well as
                    economic affairs – is all there. And we also collaborate
                    with these ministries to talk about how we can foster
                    change? Can we for instance enable education to become part
                    of this paradigm shift that we kind of need for our society?
                    How can we step in and is there something that we could do?
                    To connect this with what David just said about feeling
                    guilty and lack of knowledge, I think what is interesting
                    and important is that we study together, and we walk along
                    the lines together as tutors. Which is something that has
                    massively changed since the 90s, when Jurgen and I both
                    studied. Tutors were supposed to know it all, and you know,
                    as tutors, we don't know it all – and I think this is fine!
                    We just question things together and we figure things out
                    together.
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>FD:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    ...although I have to say that this is a very Dutch way of
                    thinking, and also very contemporary. I mean, we are a part
                    of this Dutch system, and you notice that a lot of students,
                    specifically in the bachelor phase for a graphic design
                    education come to the Netherlands to learn about a
                    presupposed idea of Dutch design. I don't think that's so
                    much a part of the output anymore, however, what you're
                    saying, Roosje, is for me a very specific way of treating
                    your education and building this vision on what you think
                    education should be. For me, this is a very Dutch approach.
                    Maybe, David, you would disagree?
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>DB:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    That’s funny, I was thinking about it this morning... Coming
                    from overseas and looking towards Europe, and more
                    specifically, Holland, one thing that I think was very
                    impressive to me, and still is, is how the various design
                    disciplines are historically built into the fabric of
                    society. Like, graphics, product design, urban design, all
                    of these things were part of a shared conversation, and not
                    as a layer put on top of the other one, or a last-minute
                    situation, such as having forgotten to make signage for the
                    building. <br />I noticed Dutch design as something which
                    was more integrated, perhaps more conversational and shared,
                    like an acknowledgement that disciplines have an equal value
                    and can add to each other. So, coming from a country that
                    has a relatively young cultural history, I found it kind of
                    amazing: you come to this place, you walk around the city
                    and you read it, as well as situate yourself in it.
                  </p>
                </div>
              </div>

              <div className={css.fanfare__note + ` content`}>
                <div className={`editor__content`}>
                  <p className={`font__small`}>
                    This text is an excerpt of a more extended conversation
                    recorded and presented as part of the fanfare curation for
                    the TOKYO ART BOOK FAIR 2020.
                    <br />
                    The visual footnotes present a compilation of related works
                    and references mentioned during the talk.
                    <br />
                    The full conversation is available in English and is present
                    during the VABF, or requested from fanfare.
                  </p>
                </div>
              </div>

              <div className={css.fanfare__vfn + ` content`}>
                <h2 className={`haeding__lev2`}>VISUAL FOOTNOTES</h2>

                <div className={css.fanfare__vfn__imgs}>
                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a
                      href={`https://takeaway.rietveldacademie.nl/`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/unlearn_1.png" alt="" />
                      <figcaption>
                        TAkEAwAY, Gerrit RIetveld Academie, Graphic Design
                        Department
                      </figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a href={`http://pub.sandberg.nl`} target={`_blank`}>
                      <img src="/images/fanfare/unlearn_2.png" alt="" />
                      <figcaption>PUB.sandberg.nl</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w50}>
                    <a
                      href={`https://rietveldacademie.nl/nl/search?qs=rietveld+journal+#eyJxcyI6InJpZXR2ZWxkIGpvdXJuYWwgIiwidHlwZSI6Imxpc3QiLCJmYWNldHMiOnt9fQ==`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/unlearn_3.png" alt="" />
                    </a>
                    <figcaption>
                      <a
                        href={`https://rietveldacademie.nl/nl/search?qs=rietveld+journal+#eyJxcyI6InJpZXR2ZWxkIGpvdXJuYWwgIiwidHlwZSI6Imxpc3QiLCJmYWNldHMiOnt9fQ==`}
                        target={`_blank`}
                      >
                        Rietveld Journal
                      </a>
                      <br />
                      <span className={`ig`}>
                        <a
                          href={`https://www.instagram.com/rietveldjournal/?hl=en`}
                          target={`_blank`}
                        >
                          Instagram
                        </a>
                      </span>
                    </figcaption>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w50}>
                    <a
                      href={`https://radio.rietveldacademie.nl/`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/unlearn_4.png" alt="" />
                      <figcaption>Radio Rietveld</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a
                      href={`http://graphicmag.kr/index.php?/issues/42-gerrit-rietveld-academie/`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/unlearn_5.png" alt="" />
                      <figcaption>
                        Graphic Magazine #42 GERRIT RIETVELD ACADEMIE
                      </figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a
                      href={`https://rietveldacademie.nl/en/page/5333/see-also`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/unlearn_6.png" alt="" />
                      <figcaption>
                        *See also - An Alternative Graphic Design Lexicon,
                        Gerrit Rietveld Academie, Graphic Design Department
                      </figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w50}>
                    <a href={`https://studentcouncil.nl/`} target={`_blank`}>
                      <img src="/images/fanfare/unlearn_7.png" alt="" />
                      <figcaption>Student Council</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a
                      href={`https://www.mpi.nl/news/jerome-bruner-library-new-york-nijmegen`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/unlearn_8.png" alt="" />
                      <figcaption>The Jerome Bruner Library</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a
                      href={`https://www.instagram.com/kabk_garden/`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/unlearn_9.png" alt="" />
                      <figcaption>KABK Garden</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a
                      href={`https://www.instagram.com/mushroomradio/`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/unlearn_10.png" alt="" />
                      <figcaption>Mushroom Radio</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a
                      href={`https://www.instagram.com/kabkmemes/`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/unlearn_11.png" alt="" />
                      <figcaption>KABK memes</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w50}>
                    <a
                      href={`https://whospeaks.eu/symposium/`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/unlearn_12.png" alt="" />
                      <figcaption>‘WHO SPEAKS?’ symposium</figcaption>
                    </a>
                  </figure>
                </div>
              </div>
            </article>

            <nav className={css.fanfare__navi + ` only-sp`}>
              <div className={css.fanfare__navi__unit}>
                <p className={css.fanfare__navi__unit__title}>DISPLAY</p>
                <figure className={css.fanfare__navi__unit__img}>
                  <img src="/images/fanfare/icon2.png" alt="" />
                </figure>
                <a
                  href={`/images/fanfare/Display.pdf`}
                  target={`_blank`}
                  className={
                    css.fanfare__navi__unit__pdf + ` ` + css.fanfare__btn
                  }
                >
                  SCRIPT
                </a>
                <a
                  href={`#content2`}
                  onClick={this._anchorClicked}
                  className={css.fanfare__navi__unit__play}
                >
                  {this.state.currentTab === "content2" && this.state.open
                    ? `<`
                    : `>`}
                </a>
                <p className={css.fanfare__navi__unit__time}>(01:19:58)</p>
              </div>
            </nav>
            <article
              className={css.fanfare__article}
              id={`content2`}
              style={
                this.state.currentTab !== "content2"
                  ? { display: `none` }
                  : { display: `block` }
              }
            >
              <div className={`content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h1 className={css.kotebakura1 + ` haeding__lev2`}>
                      DISPLAY
                    </h1>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    Hijacking platforms, navigating visitors, re-owning spaces,
                    graduating displays and audience limitations.
                  </p>
                  <p>
                    Whether naming it as "selecting", "curating", "editing",
                    "publishing", or hosting, the attention to the display
                    shines a light on curious ways of presenting content. This
                    section focuses on the intermediary role of graphic design
                    for bridging information, navigation and space. Here we
                    include projects that activate the relevance of the
                    audience, participants and social interactions.
                  </p>
                  <p>
                    <a
                      href={`https://soundcloud.com/user-48803956/display`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      Listen the audio at Sound Cloud
                    </a>
                  </p>
                </div>
              </div>

              <div className={css.fanfare__member + ` content`}>
                <div className={css.kotebakura1 + ` editor__content`}>
                  <h2>
                    FANFARE, FREJA KIR (FK)
                    <br />
                    JA JA JA NEE NEE NEE, FEMKE DEKKER (FD)
                    <br />
                    KUNSTVEREIN, YANA FOQUE (YF)
                    <br />
                    THE PALACE OF THE TYPOGRAPHIC MASONRY, RICHARD NIESSEN (RN)
                    <br />
                    COSMOS CARL, FREDERIQUE PISSUIS (FP)
                  </h2>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>FD:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    When we're using the term display, what are we talking
                    about? And especially when considering that the term
                    “display” maybe needs a bit more of a definition for people
                    who are not sharing similar practices or backgrounds with
                    the three of you or this world that we're in. Adding to
                    this, I think there's also a difference between having a
                    physical display space, as opposed to the online platform,
                    that Frederique (Cosmos carl) for instance is representing.
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>FP:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    Yeah, what is a display? I think, on the Cosmos Carl
                    website, it mainly means the representation of work,
                    although the work is never there itself, but presented
                    through a hyperlink on display. So while it's like a list of
                    works, it's not necessarily like an online gallery – only if
                    you come to the work through the link of Cosmos Carl, you
                    may know that it is an artwork. Whereas there's also the
                    possibility to kind of stumble upon the work. If you, for
                    instance, are randomly trying to find an Airbnb in Athens,
                    one of the houses, there is actually an artwork by us. So
                    there's a difference between the way that you enter the work
                    and the way in which you will experience it – and I think
                    this aspect also changes the way that it is on display.
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>YF:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    yeah. But although you select the works that are listed on
                    Cosmos Carl, they are not necessarily works of art in
                    themselves, or are they?
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>FP:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    Yeah, they are works of arts but also texts, pieces of
                    music, or sometimes serial results of working with curators.
                    Usually, the works are made for Cosmos Carl. And sometimes
                    there are existing projects that we know about, and then we
                    ask them to collaborate or to show it on the website.
                    <br />
                    But yeah, we are always thinking about it more as “hosting”
                    than “displaying”, because, we find that on the internet,
                    maybe this is a more natural word for how to think about the
                    display – like where it is hosted, and where it is shown?
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>FK:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    It is likewise an interesting question, because when
                    scanning each of your web presences, something they seem to
                    have in common, is that there's a kind of a playful
                    awareness or challenge of how to display or present the
                    works that you're sharing from each of your platforms. While
                    at the same time, it is actually coming really close to
                    curatorial work. Do you see these two terms as two separate
                    things, or do you think they also come together?
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>RN:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    I actually never thought about it. I think it's the first
                    thing you do, right? If you want to make a narrative or
                    collect things that you want to share you have to consider
                    how they are combined? And how do they make up a story? And
                    for me, this is the act of graphic design actually. If
                    you're not able to do so then it becomes really difficult.
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>FK:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>Yes, this comes close to editing and publishing.</p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>YF:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    I think a less daunting term for all of us coming from a
                    graphic design background is “editor” and that “curator”
                    immediately sounds a little bit too fancy.
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>FP:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>I like “selector”...</p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>RN:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    So, we were talking about this "Dutch" thing and also
                    talking about education. And then we stumbled upon the fact
                    that the Gerrit Rietveld graduation shows each year is a
                    display in itself compared to other art schools. Once there,
                    for instance, was an office set up, and now these floor line
                    structures. So each year, students are already encouraged,
                    and also aware of the notion that the work is going to be
                    displayed. While elsewhere, it's always a battle of who gets
                    the best spot. So, to have such a final show already makes
                    you aware of the playful approach you can have towards
                    presenting your work. And I think this is a very big
                    influence on how people continue their practices as well.
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>FK:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    It's something that I think also is remarkable for the
                    Werkplaats Typografie graduation shows
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>RN:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>Yes, that is sort of the same spirit.</p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>FK:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    Yes, both in the consideration of surrounding, setting and
                    concept
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>YF:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>...and mediums</p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>FK:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    Yes! and medium indeed, like, shows where everything is
                    turned into a movie or where the ideal scenario is that
                    nothing is actually physically shown.
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>YF:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    ...and this year each student is going to have an individual
                    graduation show, which is also remarkable, as the Werkplaats
                    is a place which has always been very collaborative and
                    collective. And I think it's interesting that the people
                    running the Werkplaats, first Karel Martens and now Armand
                    Mevis, always kind of go along with this attitude, like:
                    "Yeah, let's try, let's see what comes out of it!". And I am
                    curious, maybe this means that the graduation show will be
                    really small.
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>FK:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    But maybe these graphic design graduation shows that really
                    reflects an incredible experimental approach to the display
                    system – maybe this also reflects some kind of a graphic
                    design DNA: to consider all these aspects medium and context
                    as one part of the complete?
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>YF:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    I think that's a Dutch design thing... Well, and I guess
                    Dutch design also kind of goes back to borrow from the
                    Bauhaus movement a little bit.
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>FK:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    Yes, cause it's the text, it's the image, it's the space,
                    it's the performance, or the direction of the audience as
                    well – all these aspects are considered into the display of
                    the show.
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>FP:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    I actually find it always a bit overwhelming when the
                    display structure is so newly made for that situation. And I
                    think this also goes back to the way that I think about
                    Cosmos Carl and what I was saying in the beginning about its
                    natural habitat – that, there's all these elements of the
                    design that have an origin elsewhere. And when completely
                    taking that element away and putting it in this artificial
                    new design structure... I always wonder how the people that
                    made the design work, how they feel about that, or how they
                    would actually want to be displayed in a bit more relaxed
                    manner.
                  </p>
                </div>
              </div>

              <div className={css.fanfare__note + ` content`}>
                <div className={`editor__content`}>
                  <p className={`font__small`}>
                    This text is an excerpt of a more extended conversation
                    recorded and presented as part of the fanfare curation for
                    the TOKYO ART BOOK FAIR 2020.
                    <br />
                    The visual footnotes present a compilation of related works
                    and references mentioned during the talk.
                    <br />
                    The full conversation is available in English and is present
                    during the VABF, or requested from fanfare.
                  </p>
                </div>
              </div>

              <div className={css.fanfare__vfn + ` content`}>
                <h2 className={`haeding__lev2`}>VISUAL FOOTNOTES</h2>

                <div className={css.fanfare__vfn__imgs}>
                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a
                      href={`https://www.ideabooks.nl/9789490629243-blood-the-poems-and-archive-of-r-broby-johansen`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/display1.png" alt="" />
                      <figcaption>Blood</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w50}>
                    <a
                      href={`http://dextersinister.org/MEDIA/PDF/University-of-Reading.pdf`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/display2.png" alt="" />
                      <figcaption>
                        WORK IN PROGRESS: FORM AS A WAY OF THINKING
                      </figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a href={`http://fanfareinc.world/`} target={`_blank`}>
                      <img src="/images/fanfare/display6.png" alt="" />
                      <figcaption>fanfare inc</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a
                      href={`http://www.centrenationaldugraphisme.fr/en/le-signe/exhibition/building-site-cinq-installations-de-richard-niessen`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/display3.png" alt="" />
                      <figcaption>Concours-international-daffiches</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a
                      href={`http://www.palaceoftypographicmasonry.nl/`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/display4.png" alt="" />
                      <figcaption>Palace of Typography Masonry</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w50}>
                    <a
                      href={`https://www.stedelijk.nl/en/collection/36964-gerrit-rietveld-rijksmuseum-vincent-van-gogh`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/display5.png" alt="" />
                      <figcaption>Rijksmuseum Vincent van Gogh</figcaption>
                    </a>
                  </figure>
                </div>
              </div>
            </article>

            <nav className={css.fanfare__navi + ` only-sp`}>
              <div className={css.fanfare__navi__unit}>
                <p className={css.fanfare__navi__unit__title}>CONNECT</p>
                <figure className={css.fanfare__navi__unit__img}>
                  <img src="/images/fanfare/icon2.png" alt="" />
                </figure>
                <a
                  href={`/images/fanfare/Connect.pdf`}
                  target={`_blank`}
                  className={
                    css.fanfare__navi__unit__pdf + ` ` + css.fanfare__btn
                  }
                >
                  SCRIPT
                </a>
                <a
                  role={`button`}
                  href={`#content3`}
                  onClick={this._anchorClicked}
                  className={css.fanfare__navi__unit__play}
                >
                  {this.state.currentTab === "content3" && this.state.open
                    ? `<`
                    : `>`}
                </a>
                <p className={css.fanfare__navi__unit__time}>(01:26:51)</p>
              </div>
            </nav>
            <article
              className={css.fanfare__article}
              id={`content3`}
              style={
                this.state.currentTab !== "content3"
                  ? { display: `none` }
                  : { display: `block` }
              }
            >
              <div className={`content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h1 className={css.kotebakura1 + ` haeding__lev2`}>
                      CONNECT
                    </h1>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    Generosity of reproduction, organisational structure,
                    explorative freedom and publishing as democratic business
                  </p>
                  <p>
                    The focus on "connecting" devotes space to collaborative
                    design practices where tools and networks are used for the
                    benefit of expressing, exploring or manifesting opinions
                    into a societal context. As the field of design reacts to
                    societal and technological developments, we devote attention
                    to the collaborative nature of the graphic design
                    discipline. By borrowing from various disciplines, these
                    projects draw on graphic design tools to transform
                    aesthetics, purposes and meaning into shared systems.
                  </p>
                  <p>
                    <a
                      href={`https://soundcloud.com/user-48803956/connecting`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      Listen the audio at Sound Cloud
                    </a>
                  </p>
                </div>
              </div>

              <div className={css.fanfare__member + ` content`}>
                <div className={css.kotebakura1 + ` editor__content`}>
                  <h2>
                    FANFARE, FREJA KIR (FK)
                    <br />
                    JA JA JA NEE NEE NEE, FEMKE DEKKER (FD)
                    <br />
                    HACKERS & DESIGNERS, ANJA GROOTEN AND JULIETTE LIZOTTE (AG
                    AND JL)
                    <br />
                    ENTER ENTER/ROMA PUBLICATIONS, ROGER WILLEMS (RW)
                    <br />
                    NXS AND PARADYME, KAROLIEN BURRMAN (KB)
                  </h2>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>FD:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    You all have a practice right now, which is maybe a step
                    away from graphic design. Could you tell us a little bit
                    about how your current practices are still formed or shaped
                    by your background?
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>AG:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    I don't see it so much as stepping away from graphic design,
                    but more as an enhancement or an extension. I still feel
                    connected to design and graphic design and always aware of
                    it. Even if I engage in community organization or workshops,
                    I do all of that with the mindset of a designer. And that is
                    sometimes really helpful and sometimes gets in the way as
                    well. I am now, for instance, doing an artistic research
                    PhD, and in this context, the kind of determinism of the
                    designer of wanting to know where you're heading is actually
                    sometimes not so helpful. I find that very challenging, but
                    also very interesting.
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>FD:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    Juliette, you title yourself as a visual artist and designer
                    as well. Do you feel it's the same practice?
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>JL:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    Yes, it is in the sense that it's my practice so I bring the
                    two together. But yeah, some projects are definitely more
                    design-oriented like books, publications, or a logo. And I
                    actually think that's a nice part about the Netherlands,
                    that the borders are very fluid between art and design. I
                    feel very comfortable that I can work on autonomous projects
                    while still doing graphic design stuff as a freelancer, or
                    work with Hackers & Designers on some strange experimental
                    books without having to name it as a graphic design project
                    or a commissioned work.
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>FD:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    Roger, how does that work for you now that you have this
                    space that you use as a presentation space?
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>RW:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    Yeah, the project space is very much a joint project, as I'm
                    just one of the people running this. Personally, I'm still a
                    graphic designer, because most of the time I'm designing the
                    books that are published. But yeah, it's such a wide notion.
                    And I think how I ended up being a publisher, a designer and
                    having this project space, all have to do with my love for
                    books, and my desire to be completely independent. So I
                    really like to design and work on even the smallest detail,
                    but not in this institutional or more commercial setting.
                    But at the same time, I wanted to make a living out of this,
                    so it is like a very long term project and ideal situation
                    of doing the things you like with friends, and at the same
                    time also be able to make your living out of it.
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>FD:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    On that note, we are here to talk about collaborative
                    practices, maybe Freja can jump in here?
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>FK:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    Yes, well, I was trying to formulate a question of something
                    like: how can graphic design also be a tool to generate
                    collaborative projects? But when doing so, I needed to stop
                    myself realizing that I think very often (if not always)
                    Graphic Design and publishing in a broad sense, is
                    collaborative by nature. So this made me want to reformulate
                    the question into addressing if there even is such a thing
                    as a non-collaborative publishing scenario, and in that
                    sense if collaboration actually is a part of a DNA in
                    graphic design?
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>KB:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    I guess I'm the only one around this table who is not a
                    graphic designer, but I do have a studio, which is very much
                    focusing on graphic design as a medium. So for me, running
                    that design studio is already a collaborative practice per
                    se. I am trained in psychology and as an artist, and while
                    I'm bringing a lot of that into this design studio, I'm not
                    the one creating the visuals. It also means that our studio
                    is not just focusing on graphic design, but that research
                    from my personal background also feeds into that. In order
                    to execute a lot of our projects, we collaborate with
                    others. And in line with our publication project NXS (that's
                    how it all started off), it's also about creating other
                    works that respond to that publication. As such, creating
                    collaborations or networks is both our method and the goal
                    of this project. So without collaboration or connecting with
                    others, we could not even exist as a practice.
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>FD:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    Is that collaboration then always a democratic process where
                    everybody has the same say? Or do you as an organization
                    still have directing roles, where you actually have the
                    final say in the collaboration?
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={css.kotebakura1 + ` haeding__lev2`}>RW:</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    I think the design is a natural part of publishing, and that
                    it's a really collaborative project. It's like a platform
                    that you share with people. But meanwhile, the design can be
                    a very personal element. When I work with designers that
                    come up with proposals that we accept, I try to give them
                    complete freedom, because I think there's also quality in
                    allowing a personal voice, that can add a distinctive
                    graphic design element. But of course, there's not just one
                    way to look at this.
                  </p>
                </div>
              </div>

              <div className={css.fanfare__note + ` content`}>
                <div className={`editor__content`}>
                  <p className={`font__small`}>
                    This text is an excerpt of a more extended conversation
                    recorded and presented as part of the fanfare curation for
                    the TOKYO ART BOOK FAIR 2020.
                    <br />
                    The visual footnotes present a compilation of related works
                    and references mentioned during the talk.
                    <br />
                    The full conversation is available in English and is present
                    during the VABF, or requested from fanfare.
                  </p>
                </div>
              </div>

              <div className={css.fanfare__vfn + ` content`}>
                <h2 className={`haeding__lev2`}>VISUAL FOOTNOTES</h2>

                <div className={css.fanfare__vfn__imgs}>
                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a
                      href={`https://fanfarefanfare.nl/category/one-time-show/1592-the-universe-of-images`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/connect1.png" alt="" />
                      <figcaption>Physical Publishing</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w50}>
                    <a href={`http://display.observer`} target={`_blank`}>
                      <img src="/images/fanfare/connect2.png" alt="" />
                      <figcaption>Display.observer</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a href={`http://nxs.world/`} target={`_blank`}>
                      <img src="/images/fanfare/connect3.png" alt="" />
                      <figcaption>NXS</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a
                      href={`https://www.instagram.com/nxs_world/`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/connect4.png" alt="" />
                      <figcaption>NXS Side notes</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w50}>
                    <a
                      href={`https://hackersanddesigners.nl/s/Publishing/p/Coded_Bodies_Publication`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/connect5.png" alt="" />
                      <figcaption>Coded Bodies Publication</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a
                      href={`https://hackersanddesigners.nl/s/Summer_Academy_2020/p/Kick_Off_HDSA2020_Network_Imaginaries`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/connect6.png" alt="" />
                      <figcaption>
                        H&D Summer Academy 2020 Network Imaginaries
                      </figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w50}>
                    <a href={`https://enter-enter.nl/`} target={`_blank`}>
                      <img src="/images/fanfare/connect7.png" alt="" />
                      <figcaption>Enter Enter</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a
                      href={`https://enter-enter.nl/past/#item-220`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/connect8.png" alt="" />
                      <figcaption>
                        Printed Matter’s NY Art Book Fair, ROOM C (Amsterdam)
                      </figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a
                      href={`https://hypercore-protocol.org/#:~:text=Hypercore%20allows%20you%20to%20quickly,download%20everything%20from%20the%20beginning.`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/connect9.png" alt="" />
                      <figcaption>Hypercore P2P protocol</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a
                      href={`https://occasionalpapers.org/product/the-natural-enemies-of-books/`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/connect10.png" alt="" />
                      <figcaption>
                        The Natural Enemies of Books: A Messy History of Women
                        in Printing and Typography
                      </figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w50}>
                    <a
                      href={`https://www.design-research.be/by-womxn/`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/connect11.png" alt="" />
                      <figcaption>BADASS LIBRE FONTS BY WOMXN</figcaption>
                    </a>
                  </figure>
                </div>
              </div>
            </article>

            <div className={css.fanfare__section + ` content__2col`}>
              <div className={`content__2col__side`}>
                <div className={`content__title`}>
                  <h1 className={css.kotebakura1 + ` haeding__lev2`}>
                    Unlearn, Display, Connect
                  </h1>
                </div>
              </div>

              <div className={`editor__content content__2col__main`}>
                <p>
                  Around us, we detect a matrix of graphic design initiatives
                  reacting to their temporary contexts and society of norms.
                  Embedded in this observation are international influences,
                  financial conditions and institutional traces. <br />
                  Unlearn, Display, Connect presents different discussions
                  supported by a visual vocabulary of recent graphic design
                  projects. These projects tend to strive for cultural value
                  over economic gain. Through communication networks and
                  knowledge infrastructures, they generate frameworks for
                  learning, displaying, and connecting. The fundament of fanfare
                  likewise finds its place in these values.
                </p>
              </div>
            </div>

            <div className={css.fanfare__section + ` content__2col`}>
              <div className={`content__2col__side`}>
                <div className={`content__title`}>
                  <h1 className={css.kotebakura1 + ` haeding__lev2`}>Dutch</h1>
                </div>
              </div>

              <div className={`editor__content content__2col__main`}>
                <p>
                  In what way may we define Dutch graphic design? And how is
                  this reflected in the working conditions that surround us?
                  While the roots of Dutch graphic design and printmaking goes
                  way back, it is tempting to reflect on the notion of “Dutch
                  graphic design” today. In the light of technological
                  efficiencies and digital adaptation, what used to be
                  classified as “Dutch” design is perhaps increasingly becoming
                  a slippery term. In setting out to curate the section for the
                  TOKYO ART BOOK FAIR, we do not consider it the task to paint a
                  Dutch landscape of the graphic design scene – nor do we
                  believe that anyone can fulfil that job.
                </p>
              </div>
            </div>

            <div className={css.fanfare__section + ` content__2col`}>
              <div className={`content__2col__side`}>
                <div className={`content__title`}>
                  <h1 className={css.kotebakura1 + ` haeding__lev2`}>
                    fanfare
                  </h1>
                </div>
              </div>

              <div className={`editor__content content__2col__main`}>
                <p>
                  fanfare is a platform and design studio for collaboration and
                  developments within graphic design. As a platform fanfare
                  encourages awareness of exploratory approaches on visual
                  imagery and cross-disciplinary design methodology. Through
                  design direction and curation fanfare collaborate with a
                  diverse set of people, peers, educational programmes,
                  institutions, museums, and platforms.
                </p>
                <a
                  href={`/images/fanfare/Unlearn-Display-Connect.pdf`}
                  target={`_blank`}
                  className={css.fanfare__btn}
                >
                  VISUAL
                  <br />
                  FOOTNOTES
                </a>
              </div>
            </div>

            <div
              className={
                css.fanfare__section +
                ` ` +
                css.fanfare__footer +
                ` content__2col`
              }
            >
              <div className={`content__2col__side`}>
                <div className={`content__title`}>
                  <h2 className={css.kotebakura1 + ` haeding__lev1`}>
                    <Link to="#top">
                      fanfare
                      <span>PAGETOP</span>
                    </Link>
                  </h2>
                </div>
              </div>

              <div className={`editor__content content__2col__main`}>
                <p>
                  Design and curation for TOKYO ART BOOK FAIR is shaped by{" "}
                  <a href={`http://frejakir.com/`} target={`_blank`}>
                    Freja Kir
                  </a>{" "}
                  with{" "}
                  <a href={`http://www.miquelhervas.com/`} target={`_blank`}>
                    Miquel Hervás Gómez
                  </a>{" "}
                  for{" "}
                  <a href={`https://fanfarefanfare.nl/`} target={`_blank`}>
                    fanfare
                  </a>
                  <br />
                  Conversation is hosted by{" "}
                  <a href={`http://frejakir.com/`} target={`_blank`}>
                    Freja Kir
                  </a>{" "}
                  and Femke Dekker,{" "}
                  <a
                    href={`https://www.jajajaneeneenee.com/`}
                    target={`_blank`}
                  >
                    Ja Ja Ja Nee Nee Nee
                  </a>
                  <br />
                  Typeface, Kòtèba Kura, and design assistance by{" "}
                  <a
                    href={`https://www.instagram.com/kareslachevresitupeux/`}
                    target={`_blank`}
                  >
                    César Rogers
                  </a>
                  <br />
                  Jingle tune by{" "}
                  <a href={`https://www.fabianreichle.com/`} target={`_blank`}>
                    Fabian Reichle
                  </a>
                </p>
              </div>
            </div>

            <p>
              The fanfare contribution is generously supported by the Danish
              Arts Foundation and the Creative Industries Fund NL
            </p>
          </div>
        </section>
      </Layout>
    )
  }
}

export default IndexPage
