import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`アーティスト、ヴェラ・フリケルスが作り出した本物のアーティストブック。イラストレーター、写真家、編集者、そして装丁家としてフリケルスの名前が本書にクレジットされている。彼女はヤン・ファン・エイク・アカデミーのリソグラフ印刷機をフルに活用し、この印刷機のよく知られた特徴であり、フリケルスのエッグテンペラ作品を特徴付ける蛍光色を駆使する。美術史上で忘れられた、もしくは過小評価された女性アーティストたちについて、美術史の古典『A World History of Art』のデザインを模倣しつつ、ページ上ではフリケルス自身によるテスト印刷や色実験なども行われる。それらが明るく色鮮やかに重なり合い、60g/㎡ の紙が使われていることで次のページが透けてさらに賑やかになっている。ところどころ消された女性アーティストにまつわるテキスト（Wikipedia から引用）は、反転してページの裏側に印刷され、美術の歴史から消された名前に対する批評として機能し、同時にそれらの名前を再び可視化するというポジティブな姿勢も見てとれる。柔らかなカバーの背表は蝋引き糸を用いて手作業で美しく綴じられており、ここでも中ページと同様のリソグラフカラーが用いられている。カバーにほとんど内容を知らせる情報がないのは、プロジェクトの性質を考えれば効果的だと言える。`}</p>
    <hr></hr>
    <p>{`出版社： Jan van Eyck Academie, Maastricht`}<br parentName="p"></br>{`
`}{`デザイン： Vera Gulikers, Maastricht`}</p>
    <p>{`カテゴリー： アート、写真、建築`}<br parentName="p"></br>{`
`}{`アーティスト：Vera Gulikers`}<br parentName="p"></br>{`
`}{`編集：Vera Gulikers`}<br parentName="p"></br>{`
`}{`言語：英語`}<br parentName="p"></br>{`
`}{`印刷：Charles Nypels Lab, Jan van Eyck Academie, Maastricht`}<br parentName="p"></br>{`
`}{`製本：Vera Gulikers in association with Charles Nypels Lab, Jan van Eyck Academie, Maastricht`}<br parentName="p"></br>{`
`}{`フォント：Ariel (Monotype), Bodoni 72 (ITC)`}<br parentName="p"></br>{`
`}{`サイズ（ミリ）：202 x 287 x 7`}<br parentName="p"></br>{`
`}{`ページ数：140`}<br parentName="p"></br>{`
`}{`発行部数：100`}<br parentName="p"></br>{`
`}{`価格：€ 75`}<br parentName="p"></br>{`
`}{`ISBN：N / a`}<br parentName="p"></br>{`
`}{`本文用紙：60gsm IBO One (Igepa)`}<br parentName="p"></br>{`
`}{`カバー：300gsm Munken Pure Rough (Antalis)`}<br parentName="p"></br>{`
`}{`製本方法：背に透明糊付けと表紙計 4 ページ縫い込み。装丁に２色のロウ引き糸を使ったチェーンステッチ。`}<br parentName="p"></br>{`
`}{`特記事項：異なる蛍光インクを使ったリソグラフ、計４ページの表紙に丸い切り取り。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      