import React from "react"
import Layout from "../layouts/ja"
import CommonDoc from "../components/CommonDoc"
import { ModalRoutingContext } from "gatsby-plugin-modal-routing"
import css from "./ExhibitorTemplate.module.scss"
import { Link } from "gatsby"

export default props => {
  const children = props.children
  const title = props.pageContext.frontmatter.title
  const description = props.pageContext.frontmatter.description
  const content = (
    <div
      className={css.modal__wrap}
      style={{ width: `100%`, maxWidth: `1024px`, margin: `0 auto` }}
    >
      <header className={css.modal__header}>
        <h1>{title}</h1>
        <h2>{props.pageContext.frontmatter.author}</h2>
        <p>{props.pageContext.frontmatter.genre}</p>
      </header>

      <div className={css.modal__movie}>
        <iframe
          title="bdbd player"
          width="560"
          height="315"
          src={`https://www.youtube.com/embed/${props.pageContext.frontmatter.youtubeId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      <CommonDoc>
        <div className={`editor__content`}>{children}</div>
      </CommonDoc>
    </div>
  )
  return (
    <ModalRoutingContext.Consumer className={css.base}>
      {({ modal, closeTo }) => (
        <div>
          {modal ? (
            <div data-motion="up-inner-1">
              <div className={css.modal__close + ` back__wrap`}>
                <Link
                  to={`/exhibitions/best-dutch-book/`}
                  className={`btn__back`}
                ></Link>
              </div>
              {content}
            </div>
          ) : (
            <Layout
              location={props.location}
              title={title}
              description={description}
              ogImage={props.pageContext.frontmatter.ogImage}
            >
              {content}
            </Layout>
          )}
        </div>
      )}
    </ModalRoutingContext.Consumer>
  )
}
