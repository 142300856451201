import React from "react"
import css from "./index.module.scss"
import Layout from "../../../layouts/en"
import CommonDoc from "../../../components/CommonDoc"

const EventPage = props => {
  return (
    <Layout location={props.location} title={`Virtual Book Signing`}>
      <CommonDoc>
        <section className={`container`}>
          <div className={`content`}>
            <div className={`content__head`}>
              <h1 className={`haeding__lev1`}>VIRTUAL BOOK SIGNING</h1>
            </div>
            <div className={`content__lead`}>
              <p>
                During VABF, there will be a lot of book-signing organized by
                exhibitors. <br className={`only-pc`} />
                They will be held online via YouTube, Zoom, Google Meet, and
                other online conference apps, so if you find a signing
                information that interests you, be sure to click on it.{" "}
                <br className={`only-pc`} />
                Don't miss the opportunity to interact with your favorite
                artists.
              </p>
            </div>

            <article className={css.signing__inner}>
              <div className={`sub__head`}>
                <h2 className={`haeding__lev2`}>Monday, November 16, 2020</h2>
              </div>
              <ul className={css.signing__list}>
                <li>
                  17:00 – 19:00 JST <br className={`only-sp`} />
                  <a
                    href={`https://twitter.com/stevenfraserart?lang=en`}
                    target={`_blank`}
                    className={`link__underline`}
                  >
                    Steven Fraser @Steven Fraser / Do It Theatre
                  </a>
                </li>
              </ul>
            </article>

            <article className={css.signing__inner}>
              <div className={`sub__head`}>
                <h2 className={`haeding__lev2`}>Tuesday, November 17, 2020</h2>
              </div>
              <ul className={css.signing__list}>
                <li>
                  17:00 – 19:00 JST <br className={`only-sp`} />
                  <a
                    href={`https://twitter.com/stevenfraserart?lang=en`}
                    target={`_blank`}
                    className={`link__underline`}
                  >
                    Steven Fraser @Steven Fraser / Do It Theatre
                  </a>
                </li>
              </ul>
            </article>

            <article className={css.signing__inner}>
              <div className={`sub__head`}>
                <h2 className={`haeding__lev2`}>
                  Wednesday, November 18, 2020
                </h2>
              </div>
              <ul className={css.signing__list}>
                <li>
                  17:00 – 19:00 JST <br className={`only-sp`} />
                  <a
                    href={`https://twitter.com/stevenfraserart?lang=en`}
                    target={`_blank`}
                    className={`link__underline`}
                  >
                    Steven Fraser @Steven Fraser / Do It Theatre
                  </a>
                </li>
              </ul>
            </article>

            <article className={css.signing__inner}>
              <div className={`sub__head`}>
                <h2 className={`haeding__lev2`}>Thursday, November 19, 2020</h2>
              </div>
              <ul className={css.signing__list}>
                <li>
                  17:00 – 19:00 JST <br className={`only-sp`} />
                  <a
                    href={`https://twitter.com/stevenfraserart?lang=en`}
                    target={`_blank`}
                    className={`link__underline`}
                  >
                    Steven Fraser @Steven Fraser / Do It Theatre
                  </a>
                </li>
                <li>
                  18:00 – 18:45 JST <br className={`only-sp`} />
                  <a
                    href={`https://us04web.zoom.us/j/79137797819?pwd=Y2JUeTFZRkFPclRUcUZiTXo5djRoZz09`}
                    target={`_blank`}
                    className={`link__underline`}
                  >
                    Juan Diego Valera @CA L'ISIDRET
                  </a>
                </li>
                <li>
                  19:00 – 19:45 JST <br className={`only-sp`} />
                  <a
                    href={`https://us04web.zoom.us/j/77748656070?pwd=N1FINU9xbXlMYm9wRUVtRzBVZkM1Zz09`}
                    target={`_blank`}
                    className={`link__underline`}
                  >
                    Antonio M Xoubanova @CA L'ISIDRET
                  </a>
                </li>
              </ul>
            </article>

            <article className={css.signing__inner}>
              <div className={`sub__head`}>
                <h2 className={`haeding__lev2`}>Friday, November 20, 2020</h2>
              </div>
              <ul className={css.signing__list}>
                <li>
                  11:00 – 14:00 JST <br className={`only-sp`} />
                  <a
                    href={`https://kelechiazu.com/VABF-Book-Signing`}
                    target={`_blank`}
                    className={`link__underline`}
                  >
                    Kelechi Azu @Kelechi Azu
                  </a>
                </li>
                <li>
                  11:00 – 12:00 JST <br className={`only-sp`} />
                  <a
                    href={`https://us05web.zoom.us/j/87305341410?pwd=aGI5cFEzNXkxWElPekNtbkw4eGIvdz09`}
                    target={`_blank`}
                    className={`link__underline`}
                  >
                    Kanade Hamamto @BOOK MARUTE
                  </a>
                </li>
                <li>
                  17:00 – 19:00 JST <br className={`only-sp`} />
                  <a
                    href={`https://twitter.com/stevenfraserart?lang=en`}
                    target={`_blank`}
                    className={`link__underline`}
                  >
                    Steven Fraser @Steven Fraser / Do It Theatre
                  </a>
                </li>
                <li>
                  18:00 – JST <br className={`only-sp`} />
                  <a
                    href={`https://meet.google.com/ruz-ytjf-eoy`}
                    target={`_blank`}
                    className={`link__underline`}
                  >
                    Luca Santese,Marco P. Valli @Cesura Publish
                  </a>
                </li>
                <li>
                  18:00 – 19:00 JST <br className={`only-sp`} />
                  <a
                    href={`https://us04web.zoom.us/j/73885195163?pwd=bmdFVW9sZzlYdmkwNTZTeTlLUjkyQT09`}
                    target={`_blank`}
                    className={`link__underline`}
                  >
                    Aleix Plademunt @CA L'ISIDRET
                  </a>
                </li>
              </ul>
            </article>

            <article className={css.signing__inner}>
              <div className={`sub__head`}>
                <h2 className={`haeding__lev2`}>Saturday, November 21, 2020</h2>
              </div>
              <ul className={css.signing__list}>
                <li>
                  11:00 – 19:00 JST <br className={`only-sp`} />
                  <a
                    href={`https://meet.google.com/ydk-echr-qey`}
                    target={`_blank`}
                    className={`link__underline`}
                  >
                    Akane Nakajima @torch press
                  </a>
                </li>
                <li>
                  13:00 – 14:00 JST <br className={`only-sp`} />
                  <a
                    href={`https://zoom.us/j/8962850108?pwd=MjljUmkzWW5mMXYyY0tPTUM5YytQUT09`}
                    target={`_blank`}
                    className={`link__underline`}
                  >
                    Masanao Hirayama "88 Constellations" @UTRECHT
                  </a>
                </li>
                <li>
                  13:00 – 16:00 JST <br className={`only-sp`} />
                  <a
                    href={`https://meet.google.com/dgw-qgdh-tvj`}
                    target={`_blank`}
                    className={`link__underline`}
                  >
                    Photographer Tsutomu Umezawa @#itgirlie
                  </a>
                </li>
                <li>
                  14:00 – 15:30 JST <br className={`only-sp`} />
                  <a
                    href={`https://meet.google.com/cqm-qqjq-qgf`}
                    target={`_blank`}
                    className={`link__underline`}
                  >
                    LILY SHU,Kuraya Takashi,Yamazaki Yusaku, Den Gai @AWH?
                  </a>
                </li>
                <li>
                  15:00 – 15:15 JST <br className={`only-sp`} />
                  <a
                    href={`https://www.youtube.com/channel/UCuiX8sUZKmdX07TGL6OKcrg/`}
                    target={`_blank`}
                    className={`link__underline`}
                  >
                    Kiiro @Kiiro
                  </a>
                </li>
                <li>
                  17:00 – 18:00 JST <br className={`only-sp`} />
                  <a
                    href={`https://zoom.us/j/98719198826?pwd=bVM4dExpc1VDVkxtTWxWZ1BpVVhtQT09`}
                    target={`_blank`}
                    className={`link__underline`}
                  >
                    Roger Guaus @CA L'ISIDRET
                  </a>
                </li>
                <li>
                  19:00 – 19:30 JST <br className={`only-sp`} />
                  <a
                    href={`https://www.instagram.com/blankt_papir`}
                    target={`_blank`}
                    className={`link__underline`}
                  >
                    Janemaria Mekoline Pedersen (she will sign her book
                    “Dysfertil”) @Blankt Papir Press
                  </a>
                </li>
              </ul>
            </article>

            <article className={css.signing__inner}>
              <div className={`sub__head`}>
                <h2 className={`haeding__lev2`}>Sunday, November 22, 2020</h2>
              </div>
              <ul className={css.signing__list}>
                <li>
                  12:00 – 17:00 JST <br className={`only-sp`} />
                  <a
                    href={`https://meet.google.com/ydk-echr-qey`}
                    target={`_blank`}
                    className={`link__underline`}
                  >
                    Akane Nakajima @torch press
                  </a>
                </li>
              </ul>
            </article>

            <article className={css.signing__inner}>
              <div className={`sub__head`}>
                <h2 className={`haeding__lev2`}>Monday, November 23, 2020</h2>
              </div>
              <ul className={css.signing__list}>
                <li>
                  15:00 – 18:00 JST <br className={`only-sp`} />
                  <a
                    href={`http://instagram.com/wisut`}
                    target={`_blank`}
                    className={`link__underline`}
                  >
                    Wisut Ponnimit Signing with portrait sketch. @SOI BOOKS
                    Instagram
                  </a>
                </li>
                <li>
                  15:00 – 18:00 JST <br className={`only-sp`} />
                  <a
                    href={`https://us02web.zoom.us/j/83391328872?pwd=YzNZeW5XYmlyNk85OHdTZDlFaWJnQT09`}
                    target={`_blank`}
                    className={`link__underline`}
                  >
                    Wisut Ponnimit Signing with portrait sketch. @SOI BOOKS Zoom
                  </a>
                </li>

                <li>
                  17:00 – 18:00 JST <br className={`only-sp`} />
                  <a
                    href={`https://meet.google.com/eos-itxy-akj`}
                    target={`_blank`}
                    className={`link__underline`}
                  >
                    Hanayo @torch press
                  </a>
                </li>
              </ul>
            </article>
          </div>
          {/* /.content */}
        </section>
        {/* /.container */}
      </CommonDoc>
    </Layout>
  )
}

export default EventPage
