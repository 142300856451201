import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/VabfEvents.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SocialShare = makeShortcode("SocialShare");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "750px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "150.20408163265307%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAADAv/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAFeR4WQKD87ZTvFo//EABsQAAIDAQEBAAAAAAAAAAAAAAECAAMREiET/9oACAEBAAEFAgsG9lJ9zHtKR77A1Z6ewe4pFGcj1FtOf//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EAB8QAAIABQUAAAAAAAAAAAAAAAABAhEhMVEQIkFh4f/aAAgBAQAGPwI60wWSTKSkbhqdqCbSsRRcoieDw//EABsQAQADAQEBAQAAAAAAAAAAAAEAESExQVGR/9oACAEBAAE/ITJazatMG4uZv07lx4alw3Z7gAmR3KW6sPovSN9KOVxq/sVK0yat1+p//9oADAMBAAIAAwAAABCwAUH/xAAYEQADAQEAAAAAAAAAAAAAAAAAAREhMf/aAAgBAwEBPxBRdIWIw//EABgRAAIDAAAAAAAAAAAAAAAAAAABECFB/9oACAECAQE/EHGln//EACAQAQEAAgICAgMAAAAAAAAAAAERACExQVGhYXGRsfD/2gAIAQEAAT8QO4Mtzth4vjFvWdOBoI8nww95UGciuUW7NT8YYsBDvs+ccynwjo143g07JIdp/GAwIDab793CYZcit2O/rLgcpg1Zv9+sKAyvW3tq5//Z')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/c01f734d16d32a9c7bf8ab475528bbc8/1a3b7/img_1.webp 245w", "/static/c01f734d16d32a9c7bf8ab475528bbc8/fb835/img_1.webp 490w", "/static/c01f734d16d32a9c7bf8ab475528bbc8/cee62/img_1.webp 750w"],
          "sizes": "(max-width: 750px) 100vw, 750px",
          "type": "image/webp"
        }}></source>{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/c01f734d16d32a9c7bf8ab475528bbc8/c168b/img_1.jpg 245w", "/static/c01f734d16d32a9c7bf8ab475528bbc8/bb66a/img_1.jpg 490w", "/static/c01f734d16d32a9c7bf8ab475528bbc8/8666a/img_1.jpg 750w"],
          "sizes": "(max-width: 750px) 100vw, 750px",
          "type": "image/jpeg"
        }}></source>{`
        `}<img parentName="picture" {...{
          "className": "gatsby-resp-image-image",
          "src": "/static/c01f734d16d32a9c7bf8ab475528bbc8/8666a/img_1.jpg",
          "alt": "img 1",
          "title": "img 1",
          "loading": "lazy",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          }
        }}></img>{`
      `}</picture>{`
    `}</span>
    <div className="share__2col">
  <div className="share__2col__icon">
    <SocialShare title={props.pageContext.frontmatter.title} mdxType="SocialShare" />
  </div>
    </div>
    <p>{`2004 年にニューヨークにある非営利の書店 Printed Matter が NY ART BOOK FAIR がスタートし、それに続くかたちで世界各国のアートブックに携わる有志が、それぞれの都市でアートブックフェアを立ち上げていきました。2009 年にスタートした TOKYO ART BOOK FAIR もそのひとつです。`}<br />{`
2020 年はパンデミックの影響で国内外の出版社、アーティスト、ギャラリー、書店などが一堂に集まり、大勢の来場者で賑わう”アートブックの祭典”は、リアルな会場での開催が困難になりました。ここでは、TOKYO ART BOOK FAIR と同様に、来年 2 月 25 日〜28 日にヴァーチャルアートブックフェアを開催する NY 及び LA ART BOOK FAIR のディレクターのソネル・ブレスラウ、コーディネーターのエミー・カタドラルをゲストとしてお招きし、どのような準備を進めているのかお話をうかがいます。バーチャルにも残したいリアルの魅力、バーチャルだからこそできること、そして今後の展望など、コロナ禍においてもインディペンデント出版のコミュニティが集うプラットフォームを守りたいと願うオーガナイザー同士のカジュアルな対話になります。`}<br />{`
自由に海外へ移動できる未来がいつやってくるかまだわかりませんが、Printed Matter が主催する「Printed Matter’s Virtual Art Book Fair」も、どこにいても訪れることができます。トークを聞いて、ぜひ本番のイベントもご覧くださいませ！`}</p>
    <p>
  Printed Matter’s Virtual Art Book Fair 2021 年 2 月 25 日〜28 日<br />
  <a href="https://www.printedmatter.org/programs/events/1162" target="_blank">
    https://www.printedmatter.org/programs/events/1162
  </a>
    </p>
    <ul>
      <li parentName="ul">
        <span className="font__small">
  11月6日に収録したトークを、日本語字幕付きで配信いたします。
        </span>
      </li>
      <li parentName="ul">
        <span className="font__small">無料で視聴いただけます。</span>
      </li>
      <li parentName="ul">
        <span className="font__small">
  インターネット回線の状況やサーバー負荷、その他視聴者の PC
  環境により、映像が途切れる、または停止するなど正常に視聴できない事がございます。
        </span>
      </li>
      <li parentName="ul">
        <span className="font__small">
  配信終了後も TOKYO ART BOOK FAIR の youtube
  チャンネルで動画を視聴いただけます。
        </span>
      </li>
    </ul>
    <div className="content__noimginfo">
  <div className="content__noimginfo__text">
    <p>
      NY及びLA ART BOOK FAIRのディレクター、ソネル・ブレスラウ、NY及びLA ART
      BOOK FAIR、Printed
      Matterでコーディネーションを担当するエミー・カタドラル。Printed
      Matterは、2004年にNY ART BOOK FAIR、2013年にLA ART BOOK
      FAIRを立ち上げました。Printed
      Matterは、アーティストブックやアートに関係する出版物に対する理解を広めることを目的とし、1976年に設立された非営利団体です。
    </p>
  </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      