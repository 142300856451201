import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`この小さな本の表紙に描かれた輝く黒い玉が、興味深く、心を打つ物語を語る。玉は石炭のかけらで、北海道の夕張地区の炭鉱で働く人々が、自由時間に形を整え磨いたものだ。同地の炭鉱が最盛期を迎えていた頃のシンボルだが、その炭鉱はもう閉鎖されている。アムステルダム在住のアーティスト・デザイナーの桑原真理子は夕張を訪ねた際にこの独特な伝統に出会い魅了され、その文化を探っていく中で、かつて炭鉱町だった当地の年老いた人々とつながっていく。英語と日本語で著された本書は、彼女による調査報告だ。その文化について、そして炭鉱町の過去についての会話の合間に、時々記録写真が挟まれる。テキストは灰色のページに黒で印字されているが、会話相手の引用文は白で印字されている。本書は右から開けば日本語、左から開けば英語で、ちょうど中間にある観音開きのページには石炭の玉が並び、不思議な輝きを放っている。白と灰色、黒のみを効果的に使うことで、素晴らしい美しさと強度を備えることに成功している。そして、すべての要素はそれを補強するために注意深く組み合わされている。本の小口は黒に彩色され、シンプルな白い表紙のインパクトを強めており、そこには例の物言わぬ玉のひとつが静かに輝いているが、そこでももうひとつの素晴らしいアイディアが詰め込まれている。玉は UV スポットコーティングがされ、石炭のギラついた表面を完璧に再現しているのだ。書籍全体が、謎、静寂、そして寂寞のオーラを放っている。`}</p>
    <hr></hr>
    <p>{`出版社： Torch press, Tokyo (JP)`}<br parentName="p"></br>{`
`}{`デザイン： Ayumi Higuchi, Amsterdam`}</p>
    <p>{`カテゴリー： アート、写真、建築`}<br parentName="p"></br>{`
`}{`言語：英語、日本語`}<br parentName="p"></br>{`
`}{`翻訳：Ella Rutledge`}<br parentName="p"></br>{`
`}{`写真：Ayako Nishibori, Mariko Kuwahara`}<br parentName="p"></br>{`
`}{`製作：Jos Morree Fine Books, Weesp`}<br parentName="p"></br>{`
`}{`リトグラフ：Wilco Art Books (Mariska Bijl), Amersfoort`}<br parentName="p"></br>{`
`}{`印刷：Wilco Art Books, Amersfoort`}<br parentName="p"></br>{`
`}{`製本：Wilco Art Books, Amersfoort`}<br parentName="p"></br>{`
`}{`フォント：F Grotesk (RP Digital Type Foundry), Yu Gothic (Jiyu Kobo), Yu Gothic Shogo Kana (Jiyu Kobo)`}<br parentName="p"></br>{`
`}{`サイズ（ミリ）：115 x 175 x 10`}<br parentName="p"></br>{`
`}{`ページ数：104`}<br parentName="p"></br>{`
`}{`発行部数：500`}<br parentName="p"></br>{`
`}{`価格：€ 18`}<br parentName="p"></br>{`
`}{`ISBN：978 4 907562 18 2`}<br parentName="p"></br>{`
`}{`本文用紙：100gsm Fluweel 1.5 (Igepa)`}<br parentName="p"></br>{`
`}{`カバー：300gsm Lessebo Design 1.3 White (Igepa)`}<br parentName="p"></br>{`
`}{`製本方法：無線綴じソフトカバー`}<br parentName="p"></br>{`
`}{`特記事項：表紙にスポットニス印刷。小口は Critis(ベールネム、ベルギー)による黒色印刷。中身は黒と Pantone ウォームグレー７で印刷。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      