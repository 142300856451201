import {
  CircleBufferGeometry,
  LinearEncoding,
  Mesh,
  MeshLambertMaterial,
  RepeatWrapping,
  Scene,
  ShaderMaterial,
  sRGBEncoding,
} from "three"
import { grassShader } from "../../rafael/GrassShader"
import { SceneObject } from "../../types"
import { Loader } from "../../Loader"

const scale = 300

export class Ground implements SceneObject {
  private constructor(public mesh: Mesh) {}

  static init() {
    const mesh = new Mesh()
    mesh.name = "Ground"

    // Lower Ground
    const grassMaterial = new ShaderMaterial({
      ...grassShader,
      transparent: true,
      fog: false,
      depthWrite: false,
    })

    const texture1 = Loader.loadTexture("/images/grass_lite.jpg", false)
    texture1.wrapS = texture1.wrapT = RepeatWrapping
    texture1.repeat.set(scale * 2, scale * 2)
    // texture1.anisotropy = 16
    // texture1.encoding = LinearEncoding
    texture1.encoding = sRGBEncoding
    grassMaterial.uniforms["map"].value = texture1
    grassMaterial.uniformsNeedUpdate = true

    const ground = new Mesh(
      new CircleBufferGeometry(scale * 2, 32),
      grassMaterial
    )
    ground.rotateX(-Math.PI / 2)
    ground.position.setY(-1)
    ground.receiveShadow = true

    mesh.add(ground)

    // Upper Ground
    const grassMaterial2 = new MeshLambertMaterial({ fog: false })
    grassMaterial2.onBeforeCompile = shader => {
      shader.fragmentShader = shader.fragmentShader.replace(
        `vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + totalEmissiveRadiance;`,
        "vec3 outgoingLight = diffuseColor.rgb * ( 1.0 - 0.5 * ( 1.0 - getShadowMask() ) ); // shadow intensity hardwired to 0.5 here"
      )
    }

    const texture2 = Loader.loadTexture("/images/grass_lite.jpg", false)
    texture2.wrapS = texture2.wrapT = RepeatWrapping
    texture2.repeat.set(scale, scale)
    // texture2.encoding = LinearEncoding
    texture2.encoding = sRGBEncoding
    grassMaterial2.map = texture2
    grassMaterial2.needsUpdate = true

    const ground2 = new Mesh(
      new CircleBufferGeometry(scale, 32),
      grassMaterial2
    )
    ground2.rotateX(-Math.PI / 2)
    ground2.receiveShadow = true

    mesh.add(ground2)

    return new Ground(mesh)
  }

  update() {}

  dispose() {}
}
