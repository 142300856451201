import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`映画や広告の複製を通して典型的なアメリカン・カウボーイ像を探るリチャード・プリンスのコラージュ作品をまとめた 484 ページに及ぶこの作品集ほど、選考委員の間でさまざまな議論を呼んだものはなかった。ある選考委員は本書を「目立ちたがり」と評し、ほかの委員は「偉大な探究」と評した。プリンスが一大メディア Time の編集室で働いていたことも、その作品と本書に影響を与えている。コラージュのデザインは雑誌のレイアウトを模しており、用いられる視覚言語は（マルボロなどの）広告のものだ。本の後半には、光沢紙を用いたプリンス自身の「カウボーイ」作品のセクションもある。この本も、表紙と裏表紙にタイポグラフィを用いていない。その代わり、タイトルは背表紙と小口を回るように配置され、同じく背表紙と小口を包むように印刷されたイメージを縁どっている。それを過剰と見る委員もいれば、効果的とする委員もいた。しかし最終的には、本書の面白さが勝った。`}</p>
    <hr></hr>
    <p>{`出版社： Fulton Ryder, New York & Prestel Publishing, New York (USA)`}<br parentName="p"></br>{`
`}{`コンセプト・デザイン：COMA Amsterdam | New York (Cornelia Blatter, Marcel Hermans)`}</p>
    <p>{`カテゴリー： アート、写真、建築`}<br parentName="p"></br>{`
`}{`アーティスト：Richard Prince`}<br parentName="p"></br>{`
`}{`言語：英語`}<br parentName="p"></br>{`
`}{`写真：Richard Prince`}<br parentName="p"></br>{`
`}{`イメージ編集：Robert M. Rubin`}<br parentName="p"></br>{`
`}{`編集：Jana Martin`}<br parentName="p"></br>{`
`}{`用紙アドバイス：De Monsterkamer`}<br parentName="p"></br>{`
`}{`製作：ORO Graphic Project Management, Koekange`}<br parentName="p"></br>{`
`}{`レイアウト：COMA Amsterdam | New York`}<br parentName="p"></br>{`
`}{`リトグラフ：Echelon Color, Los Angeles (USA)`}<br parentName="p"></br>{`
`}{`印刷：Kösel GmbH & Co. KG, Altusried-Krugzell (DE)`}<br parentName="p"></br>{`
`}{`製本：Kösel GmbH & Co. KG, Altusried-Krugzell (DE)`}<br parentName="p"></br>{`
`}{`フォント：New Fournier BP (Swiss Typefaces), Gill Sans (Monotype)`}<br parentName="p"></br>{`
`}{`サイズ（ミリ）：230 x 305 x 28`}<br parentName="p"></br>{`
`}{`ページ数：484`}<br parentName="p"></br>{`
`}{`発行部数：5,000`}<br parentName="p"></br>{`
`}{`価格：€ 69`}<br parentName="p"></br>{`
`}{`ISBN：978 3 7913 5968 7`}<br parentName="p"></br>{`
`}{`本文用紙：115gsm Garda Gloss (Lecta), 80 and 100gsm Munken Polar Rough (Arctic Paper)`}<br parentName="p"></br>{`
`}{`カバー：300gsm Munken Polar Rough (Arctic Paper)`}<br parentName="p"></br>{`
`}{`製本方法：糸かがりソフトカバー`}<br parentName="p"></br>{`
`}{`特記事項：背、小口、天地に印刷：表表紙と裏表紙から本の縁へとつながる印刷が装丁を立体的に表現。縁のタイトルは Kösel GmbH & Co. KG（ドイツ）による印刷。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      