import { UniformsUtils, UniformsLib, ShaderChunk } from "three"
import dedent from "dedent"

export const grassShader = {
  // uniforms: {
  //     map: { value: null },
  //     ...(UniformsLib as any)["shadowmap"],
  // },
  uniforms: UniformsUtils.merge([
    UniformsLib["common"],
    (UniformsLib as any)["shadowmap"],
  ]),
  vertexShader: dedent`
    varying vec2 vUV;
    // varying float distToCamera;
    varying vec4 worldPos;

    void main() {
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        vUV = uv;

        // Get distance from camera on XZ plane
        worldPos = modelMatrix * vec4(position, 1.0);
    }
    `,
  fragmentShader: dedent`
    precision highp float;
    uniform sampler2D map;
    varying vec2 vUV;
    // varying float distToCamera;
    varying vec4 worldPos;

    float remap(float x, float xmin, float xmax, float ymin, float ymax) {
        float range1 = xmax - xmin;
        float range2 = ymax - ymin;
        return clamp((x - xmin) / range1, 0., 1.) * range2 + ymin;
    }

    void main() {
        vec2 uv = fract(vUV * 5000.);
        gl_FragColor = texture2D(map, uv);

        // gl_FragColor = LinearTosRGB(gl_FragColor);


        // float distToCamera = length(cameraPosition.xz - worldPos.xz);
        float distToCenter = length(worldPos.xz);
        gl_FragColor.a *= 1. - remap(distToCenter, 300., 600., 0., 1.);
        // gl_FragColor = vec4(fract(distToCamera));

        gl_FragColor = sRGBToLinear(gl_FragColor);
    }
    `,
}
