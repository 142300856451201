import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`青いボールペンを手に取って、練習帳に落書きする。子どもの頃、授業中に教室でそんな遊びをしなかった人はいるだろうか？　アンネマリー・ファン・ハーリンゲンはそのような遊び心に満ちた、ミスター・ロンと彼が愛するドラゴンたちにまつわる 1 冊の本を作り上げた。ボールペンを使うということは、この本を作るにあたり、この制限の多い表現手法をマスターしているデザイナーテッサ・ファン・デル・ワールスの頭に真っ先に浮かんだことだった。カバー、見返し、そして本文のすべてが青いボールペンによって描かれ、書かれている。また、中ページのタイポブラフィもうまく作用しており、ボールペンと同じ色と太さを持ちながら一般的なサンセリフで綴られた文字によって、絵とテキストの住み分けが効果的になされている。カバーには角の立った独特なタイトルが対照的な緑色の箔で印字され、全体的にうまくまとまりすぎたり、青くなりすぎることを防いでいる。個性と独特の雰囲気に満ちた統一感の高い本で、収められたドラゴンたちの物語もまた、練習帳に再び落書きをしたくなるように促す魅力で満ちている。`}</p>
    <hr></hr>
    <p>{`出版社： Uitgeverij Leopold, Amsterdam`}<br parentName="p"></br>{`
`}{`デザイン： Tessa van der Waals, Amsterdam`}</p>
    <p>{`カテゴリー： 子供や若者向けの本`}<br parentName="p"></br>{`
`}{`編集：Ria Turkenburg`}<br parentName="p"></br>{`
`}{`言語：オランダ語`}<br parentName="p"></br>{`
`}{`Illustrator: Annemarie van Haeringen`}<br parentName="p"></br>{`
`}{`イメージ編集：Ria Turkenburg`}<br parentName="p"></br>{`
`}{`リトグラフ：Crossmedia, Amsterdam`}<br parentName="p"></br>{`
`}{`印刷：Wilco Art Books, Amersfoort`}<br parentName="p"></br>{`
`}{`製本：Wilco Art Books, Amersfoort`}<br parentName="p"></br>{`
`}{`フォント：Telerysm mono 2 (Jack Usine Smeltery), Nobel (Dutch Type Library)`}<br parentName="p"></br>{`
`}{`サイズ（ミリ）：230 x 280 x 4`}<br parentName="p"></br>{`
`}{`ページ数：32`}<br parentName="p"></br>{`
`}{`発行部数：2,500`}<br parentName="p"></br>{`
`}{`価格：€ 15.99`}<br parentName="p"></br>{`
`}{`ISBN：978 90 258 7725 5`}<br parentName="p"></br>{`
`}{`本文用紙：170gsm Arcoprint Edizioni 1.3 Avorio (Fedrigoni)`}<br parentName="p"></br>{`
`}{`見返し：170gsm Arcoprint Edizioni 1.3 Avorio (Fedrigoni)`}<br parentName="p"></br>{`
`}{`装丁：135gsm Magno Gloss (Sappi Europe)`}<br parentName="p"></br>{`
`}{`製本方法：糸かがり２.5mm グレーボード紙の角背ハードカバー、一番最初と最後のページに見返し加工`}<br parentName="p"></br>{`
`}{`特記事項：中身と装丁は黒、Pantone072、Pantone 紫で印刷。装丁と背に Pantone 黄色を追加。Wilco Art Books による緑（Kurz Luxor 390）の箔押し。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      