import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/VabfEvents.en.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SocialShare = makeShortcode("SocialShare");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "980px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "70.61224489795919%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAd+aQxh//8QAFhAAAwAAAAAAAAAAAAAAAAAAEBEg/9oACAEBAAEFAoQ//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGRABAAMBAQAAAAAAAAAAAAAAAQAQETFh/9oACAEBAAE/IXkF3PKTTIAr/9oADAMBAAIAAwAAABATD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABwQAQEAAgIDAAAAAAAAAAAAAAERABAhMUFRYf/aAAgBAQABPxBRPoyOgSOfN0CLpI5JhyEvzX//2Q==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/681572f138207b0641c0104c4f4805f4/1a3b7/img_1.webp 245w", "/static/681572f138207b0641c0104c4f4805f4/fb835/img_1.webp 490w", "/static/681572f138207b0641c0104c4f4805f4/096d3/img_1.webp 980w", "/static/681572f138207b0641c0104c4f4805f4/3dcc7/img_1.webp 1470w", "/static/681572f138207b0641c0104c4f4805f4/061f9/img_1.webp 1960w", "/static/681572f138207b0641c0104c4f4805f4/f3e13/img_1.webp 2000w"],
          "sizes": "(max-width: 980px) 100vw, 980px",
          "type": "image/webp"
        }}></source>{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/681572f138207b0641c0104c4f4805f4/c168b/img_1.jpg 245w", "/static/681572f138207b0641c0104c4f4805f4/bb66a/img_1.jpg 490w", "/static/681572f138207b0641c0104c4f4805f4/2f919/img_1.jpg 980w", "/static/681572f138207b0641c0104c4f4805f4/a7235/img_1.jpg 1470w", "/static/681572f138207b0641c0104c4f4805f4/331e6/img_1.jpg 1960w", "/static/681572f138207b0641c0104c4f4805f4/9a313/img_1.jpg 2000w"],
          "sizes": "(max-width: 980px) 100vw, 980px",
          "type": "image/jpeg"
        }}></source>{`
        `}<img parentName="picture" {...{
          "className": "gatsby-resp-image-image",
          "src": "/static/681572f138207b0641c0104c4f4805f4/2f919/img_1.jpg",
          "alt": "img 1",
          "title": "img 1",
          "loading": "lazy",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          }
        }}></img>{`
      `}</picture>{`
    `}</span>
    <div className="share__2col">
  <div className="share__2col__icon">
    <SocialShare title={props.pageContext.frontmatter.title} mdxType="SocialShare" />
  </div>
    </div>
    <p>{`加賀美 健、中村穣二、平山昌尚による、TABF 恒例の 3 人によるパフォーマンスをライブ配信でお楽しみください。`}<br />{`
誰もしらない、しられていない、ハテナァーブックフェア。`}</p>
    <ul>
      <li parentName="ul">
        <span className="font__small">無料で視聴いただけます。</span>
      </li>
      <li parentName="ul">
        <span className="font__small">
  インターネット回線の状況やサーバー負荷、その他視聴者の PC
  環境により、映像が途切れる、または停止するなど正常に視聴できない事がございます。
        </span>
      </li>
      <li parentName="ul">
        <span className="font__small">
  配信終了後も TOKYO ART BOOK FAIR の youtube
  チャンネルで動画を視聴いただけます。
        </span>
      </li>
    </ul>
    <figure {...{
      "className": "content__img__small"
    }}>{`
  `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "100%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAIDBAEF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgEA/9oADAMBAAIQAxAAAAHNzbRpN64quYzjBv8A/8QAGxAAAgMAAwAAAAAAAAAAAAAAAQIAAxEEEhP/2gAIAQEAAQUCNb55vvQzkuGlDqsDAhK1WGtTCMP/xAAXEQEAAwAAAAAAAAAAAAAAAAAQAREx/9oACAEDAQE/AbIw/8QAGBEAAgMAAAAAAAAAAAAAAAAAABEBEDH/2gAIAQIBAT8BQidr/8QAHBAAAgICAwAAAAAAAAAAAAAAAAECESExMkFR/9oACAEBAAY/AuLNM0yNPF5Lktdlob9MxRSP/8QAGxABAQACAwEAAAAAAAAAAAAAAQARITFRgeH/2gAIAQEAAT8hENG+WAk/AheBxndmsPpplAyva3aPLKogX//aAAwDAQACAAMAAAAQvCc//8QAGBEBAQADAAAAAAAAAAAAAAAAAQAQETH/2gAIAQMBAT8QA8txw//EABcRAAMBAAAAAAAAAAAAAAAAAAEQETH/2gAIAQIBAT8Qsahp/8QAHBABAAMBAQADAAAAAAAAAAAAAQARIUExUXGh/9oACAEBAAE/EHx0uNnCbWhUzc+uvzLLVXNw0alm2Cm10oZ7MRW7007i/kCDRZsvCDRWewO0HiiE0xgM/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/68a4a7d59c9f250d32e77d2519b0202e/1a3b7/img_2.webp 245w", "/static/68a4a7d59c9f250d32e77d2519b0202e/fb835/img_2.webp 490w", "/static/68a4a7d59c9f250d32e77d2519b0202e/e2a84/img_2.webp 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/68a4a7d59c9f250d32e77d2519b0202e/c168b/img_2.jpg 245w", "/static/68a4a7d59c9f250d32e77d2519b0202e/bb66a/img_2.jpg 490w", "/static/68a4a7d59c9f250d32e77d2519b0202e/d5c47/img_2.jpg 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/68a4a7d59c9f250d32e77d2519b0202e/d5c47/img_2.jpg",
            "alt": "img 2",
            "title": "img 2",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>
    </figure>
    <div className="content__noimginfo">
  <div className="content__noimginfo__title">
    <p>加賀美 健</p>
  </div>
  <div className="content__noimginfo__text">
    <p>
      1974年東京都生まれ。社会現象や時事問題、カルチャーなどをジョーク的発想に変換し、彫刻、絵画、ドローイング、映像、パフォーマンスなど、メディアを横断して発表している。2010年に代官山にオリジナル商品などを扱う自身のお店（それ自体が作品）ストレンジストアをオープン。
    </p>
  </div>
    </div>
    <div className="content__noimginfo">
  <div className="content__noimginfo__title">
    <p>中村穣二</p>
  </div>
  <div className="content__noimginfo__text">
    <p>画家。1974年横浜生まれ 千葉育ち。血液型O型。</p>
  </div>
    </div>
    <div className="content__noimginfo">
  <div className="content__noimginfo__title">
    <p>平山昌尚</p>
  </div>
  <div className="content__noimginfo__text">
    <p>
      1976年神戸生まれ。絵画、ドローイング、パフォーマンスなど東京を拠点に活動。
    </p>
  </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      