import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.en.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The strength of this publication about legendary New York artist Jean-Michel Basquiat lies to no small extent in a rather bizarre limitation: owing to ‘legal issues’, it was not possible to include a single work by the artist in the book. Instead, the book concentrates on the art scene within which the young artist moved. In a rich selection of archival images, coarsely blown up onto thick, recalcitrant, uncoated paper, we witness the world in which he made his graffiti: the gritty streets of Brooklyn and the area around Times Square, at that time still dominated by prostitution and drug dealing. Contrasting with this rough outside world is a warm interior world of friendship: pictures of his girlfriend, his apartment and himself, making things, on his way to a show, working, fooling around, performing, as a guest on a TV show. The mix of the desolation of New York at that time and the warmth and intimacy of the artists’ club, the loose association of artists, performers and gallery owners among whom Basquiat moved, is presented in an almost filmic manner, with infectious directness and vitality. Both text and images are supported by the secondary colours green and red. Quotes are presented on smaller pages. The most striking feature is the strong, jazzy rhythm of text, image, use of colour, contrasting paper types and typography, which draws the reader in and gives the impression of spending a day with this young artist whose short life was about to explode, in a legendary time and place ‘where art was life and life was art’. Strong and sensitive. Rough yet controlled. And completely convincing.`}</p>
    <hr></hr>
    <p>{`Publisher: SCHUNCK, Heerlen`}<br parentName="p"></br>{`
`}{`Designer: Raw Color (Daniera ter Haar, Christoph Brach, Tijs Van Nieuwenhuysen), Eindhoven`}</p>
    <p>{`Category: Art, photo and architecture books`}<br parentName="p"></br>{`
`}{`Artist: Jean-Michel Basquiat`}<br parentName="p"></br>{`
`}{`Editor: John Bezold, Minnou Mennens, Patricia van den Ende, Jeanine Ruijters`}<br parentName="p"></br>{`
`}{`Language: Dutch, English`}<br parentName="p"></br>{`
`}{`Translator: D’Laine Camp, John Bezold`}<br parentName="p"></br>{`
`}{`Image editor: Cynthia Jordens, Fabian de Kloe`}<br parentName="p"></br>{`
`}{`Editor-in-chief: Cynthia Jordens, Fabian de Kloe`}<br parentName="p"></br>{`
`}{`Printer: Tuijtel, Hardinxveld-Giessendam`}<br parentName="p"></br>{`
`}{`Binder: Folding and sewing by Tuijtel, Hardinxveld-Giessendam. Trimming and binding by Boekbinderij Abbringh, Groningen`}<br parentName="p"></br>{`
`}{`Font: Wilma (Type-Ø-Tones), Kievit (Fontshop)`}<br parentName="p"></br>{`
`}{`Size in mm: 200 x 280 x 11`}<br parentName="p"></br>{`
`}{`Number of pages: 172`}<br parentName="p"></br>{`
`}{`Print run: 1,000`}<br parentName="p"></br>{`
`}{`Price: € 30`}<br parentName="p"></br>{`
`}{`ISBN: 978 90 74106 48 1`}<br parentName="p"></br>{`
`}{`Paper for interior: 150gsm Magno Volume (Igepa), 135gsm Magno Gloss (Igepa), 120gsm EOS 2.0 (Igepa), 90gsm Magno Natural (Sappi Europe)`}<br parentName="p"></br>{`
`}{`Endpapers: 140gsm Magno Natural (Sappi Europe)`}<br parentName="p"></br>{`
`}{`Cover: 120gsm Wibalin® Natural 500 White (Winter & Company) over 2.5 mm greyboard`}<br parentName="p"></br>{`
`}{`Binding style: Sewn hardback with flat spine. Book block and binding cut flush at head, front and tail.`}<br parentName="p"></br>{`
`}{`Special features: The interior contains three wrappers that are shorter than the normal pages.The interior is printed CMYK and Pantone 485, 545, 5035, and 7729. The cover is printed in black with screen printing in red by Hofmans Drukkerij, IJsselstein.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      