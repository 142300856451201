import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/VabfEvents.en.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SocialShare = makeShortcode("SocialShare");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className="youtube__wrap">
  <iframe width="560" height="315" src="https://www.youtube.com/embed/6Nv2YTDZFFE?controls=0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
    <div className="share__2col">
  <div className="share__2col__link">
    <a href="https://youtu.be/6Nv2YTDZFFE" target="_blank">
      Youtube Live をみる
    </a>
  </div>
  <div className="share__2col__icon">
    <SocialShare title={props.pageContext.frontmatter.title} mdxType="SocialShare" />
  </div>
    </div>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "980px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "59.59183673469387%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFA//EABUBAQEAAAAAAAAAAAAAAAAAAAID/9oADAMBAAIQAxAAAAFTV9sKURio/8QAGRABAQEAAwAAAAAAAAAAAAAAAgEDABIx/9oACAEBAAEFAszXyG5kazrhmSLmUL7/AP/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/Aaf/xAAcEAABAwUAAAAAAAAAAAAAAAAAAREhEBIxQXH/2gAIAQEABj8CgvYnInBXTVP/xAAZEAEAAwEBAAAAAAAAAAAAAAABABEhMVH/2gAIAQEAAT8h7sc2JmCvJ2sEQdswCwxUVKf/2gAMAwEAAgADAAAAEDcP/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERQf/aAAgBAwEBPxCaNVn/xAAXEQEBAQEAAAAAAAAAAAAAAAABABEx/9oACAECAQE/EO8hAy//xAAdEAEAAwACAwEAAAAAAAAAAAABABEhMVFhgZHB/9oACAEBAAE/EDXkra6Dq+4BkoC19ypzSrfnf2Y07h5WY4CAc+RBjgs//9k=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/f5e3135767f3b0af315ea756f9259829/1a3b7/img_1.webp 245w", "/static/f5e3135767f3b0af315ea756f9259829/fb835/img_1.webp 490w", "/static/f5e3135767f3b0af315ea756f9259829/096d3/img_1.webp 980w", "/static/f5e3135767f3b0af315ea756f9259829/3dcc7/img_1.webp 1470w", "/static/f5e3135767f3b0af315ea756f9259829/d6760/img_1.webp 1632w"],
          "sizes": "(max-width: 980px) 100vw, 980px",
          "type": "image/webp"
        }}></source>{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/f5e3135767f3b0af315ea756f9259829/c168b/img_1.jpg 245w", "/static/f5e3135767f3b0af315ea756f9259829/bb66a/img_1.jpg 490w", "/static/f5e3135767f3b0af315ea756f9259829/2f919/img_1.jpg 980w", "/static/f5e3135767f3b0af315ea756f9259829/a7235/img_1.jpg 1470w", "/static/f5e3135767f3b0af315ea756f9259829/648e4/img_1.jpg 1632w"],
          "sizes": "(max-width: 980px) 100vw, 980px",
          "type": "image/jpeg"
        }}></source>{`
        `}<img parentName="picture" {...{
          "className": "gatsby-resp-image-image",
          "src": "/static/f5e3135767f3b0af315ea756f9259829/2f919/img_1.jpg",
          "alt": "img 1",
          "title": "img 1",
          "loading": "lazy",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          }
        }}></img>{`
      `}</picture>{`
    `}</span>
    <p>{`本トークでは、BDBD 代表のエステル・ショルテン/Esther Scholten、選考委員会議⻑のエールコ・ファン・ウェーリ/Eelco van Welie(NAI010 Publishers ディレクター)と、グラフィックデザイナーのトマス・カストロ/Thomas Castro(アムステルダム市立美術館グラフィックデザイン部門キュレーター)をゲストとしてお招きします。オランダのブックデザインの歴史や、どのような点に着目して審査しているのかなどを話していただきます。Web サイト『The Graphic Design Review』編集⻑である室賀清徳がモデレーターを務めます。`}</p>
    <ul>
      <li parentName="ul">
        <span className="font__small">
  日本語字幕入りの事前収録した約 1 時間のトークを配信した後、17:30 より BDBD
  代表のエステル・ショルテンとの Q&A セッションを予定しております。
        </span>
      </li>
      <li parentName="ul">
        <span className="font__small">無料で視聴いただけます。</span>
      </li>
      <li parentName="ul">
        <span className="font__small">
  インターネット回線の状況やサーバー負荷、その他視聴者の PC
  環境により、映像が途切れる、または停止するなど正常に視聴できない事がございます。
        </span>
      </li>
      <li parentName="ul">
        <span className="font__small">
  配信終了後も TOKYO ART BOOK FAIR の youtube
  チャンネルで動画を視聴いただけます。
        </span>
      </li>
    </ul>
    <div className="content__noimginfo__text ">
  <div className="content__noimginfo__title">
    <p>エステル・ショルテン/Esther Scholten</p>
  </div>
  <div className="content__noimginfo__text">
    <p>Best Dutch Book Design代表。</p>
  </div>
    </div>
    <div className="content__noimginfo__text ">
  <div className="content__noimginfo__title">
    <p>エールコ・ファン・ウェーリ/Eelco van Welie</p>
  </div>
  <div className="content__noimginfo__text">
    <p>
      オランダのロッテルダムを拠点とする出版社、NAI010
      Publishersのディレクター、BDBDの選考委員会議長。
    </p>
  </div>
    </div>
    <div className="content__noimginfo__text ">
  <div className="content__noimginfo__title">
    <p>トマス・カストロ/Thomas Castro</p>
  </div>
  <div className="content__noimginfo__text">
    <p>
      グラフィックデザイナー。アムステルダム市立美術館のグラフィックデザイン部門のキューレーター。
    </p>
  </div>
    </div>
    <div className="content__noimginfo__text ">
  <div className="content__noimginfo__title">
    <p>室賀清徳</p>
  </div>
  <div className="content__noimginfo__text">
    <p>編集者。Web サイト『The Graphic Design Review』編集⻑。</p>
  </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      