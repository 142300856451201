import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.en.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`More than just a rephotography project based on Ad van Denderen’s Welkom in Suid-Africa (1991), this book is a story about inclusion and diversity featuring a wide variety of voices, perspectives and visual sources. The initiative for the book did not come from Van Denderen, but from local photographer Lebohang Tlali, who didn’t discover Van Denderen’s first book until he was a student in Cape Town, a thousand kilometres away from Welkom, his home town. This contact led to a new cooperation and marked a significant shift in contemporary documentary photography: instead of publishing about or on behalf of the subject, it is the voice of the community being portrayed that is heard in the book. Tlali, Van Denderen and author Margalith Kleijwegt bring the old photos back to the place where they were taken, give photography workshops, collect images from photo albums and add new photos of their own. The result is a ‘multi-voice visual narration’, given a clear visual structure by designer Jeremy Jansen using sparse, effective tools. Text and image sections are kept apart, creating a much-seen phenomenon this year: another ‘layer cake’ book block. The chapters are marked by wraps printed in beige, and the use of thicker, stiffer paper allows for easy thumbing to these sections. Once again, an exposed spine sewn in with the cover guarantees that the book opens nicely. The controlled, painstaking picture editing and mis en page give the many voices, personal histories and perspectives contained by this book space and direction. Exemplary.`}</p>
    <hr></hr>
    <p>{`Publisher: Paradox, Edam & Atlas Contact, Amsterdam`}<br parentName="p"></br>{`
`}{`Designer: Jeremy Jansen, Amsterdam`}</p>
    <p>{`Category: Art, photo and architecture books`}<br parentName="p"></br>{`
`}{`Editor: Atlas Contact (Tilly Hermans, Fieke Janse, Renée Peereboom)`}<br parentName="p"></br>{`
`}{`Language: Dutch`}<br parentName="p"></br>{`
`}{`Translator: Francesca de Châtel`}<br parentName="p"></br>{`
`}{`Photographer: Ad van Denderen, Lebohang Tlali and others`}<br parentName="p"></br>{`
`}{`Image editor: Ad van Denderen, Anne Ruygt, Bas Vroege`}<br parentName="p"></br>{`
`}{`Editor-in-chief: Atlas Contact (Tilly Hermans, Fieke Janse, Renée Peereboom)`}<br parentName="p"></br>{`
`}{`Lithographer: Colour & Books (Sebastiaan Hanekroot), Apeldoorn`}<br parentName="p"></br>{`
`}{`Printer: NPN Drukkers, Breda`}<br parentName="p"></br>{`
`}{`Binder: Boekbinderij Patist, Den Dolder`}<br parentName="p"></br>{`
`}{`Font: Akkurat (Lineto)`}<br parentName="p"></br>{`
`}{`Size in mm: 170 x 240 x 22`}<br parentName="p"></br>{`
`}{`Number of pages: 304`}<br parentName="p"></br>{`
`}{`Print run: 2,400`}<br parentName="p"></br>{`
`}{`Price: € 39.90`}<br parentName="p"></br>{`
`}{`ISBN: 978 900 450 3817 9`}<br parentName="p"></br>{`
`}{`Paper for interior: 120gsm Munken Lynx (Antalis), wrappers: 150gsm Munken Lynx Rough (Antalis)`}<br parentName="p"></br>{`
`}{`Cover: 300gsm Munken Lynx Rough (Antalis)`}<br parentName="p"></br>{`
`}{`Dust jacket: 170gsm Munken Lynx Rough (Antalis)`}<br parentName="p"></br>{`
`}{`Binding style: Sewn softcover with front and back cover of four pages sewn in, dust jacket over transparently glued-off spine`}<br parentName="p"></br>{`
`}{`Special features: The dust jacket is printed in black and Pantone 4535 and 871. The eight wrappers in the interior printed in black and Pantone 4535, give structure to the content of the book.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      