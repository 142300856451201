import React from "react"
import Layout from "../../../../layouts/ja"
import BdbdMenu from "../../../../components/BdbdMenu"
import css from "../index.module.scss"

const IndexPage = props => {
  return (
    <Layout location={props.location} title={`Best Dutch Book Exhibition`}>
      <section className={css.bdbd__talk + ` container`}>
        <div className={css.bdbd__content + ` content`}>
          <div className={css.bdbd__head + ` content__head`}>
            <h1 className={`haeding__lev1`}>
              BEST DUTCH <br className={`only-sp`} />
              BOOK DESIGNS
            </h1>
          </div>

          <nav className={css.bdbd__localnavi}>
            <BdbdMenu />
          </nav>

          <article className={`content__2col`}>
            <div className={`content__2col__side`}>
              <div className={`content__title`}>
                <h1 className={`haeding__lev2`}>Talk Event</h1>
              </div>
            </div>

            <div className={`editor__content content__2col__main`}>
              <section className={css.bdbd__section}>
                <h2>
                  「The Best Dutch Book Designs –
                  オランダのブックデザインをめぐって」
                </h2>
                <p>
                  日時:11 月 20 日(金)16:30 – 18:00
                  <br />
                  <br />
                  本トークでは、BDBD 代表のエステル・ショルテン/Esther
                  Scholten、選考委員会議⻑のエールコ・ファン・ウェーリ/Eelco van
                  Welie(NAI010 Publishers
                  ディレクター)と、グラフィックデザイナーのトマス・カストロ/Thomas
                  Castro(アムステルダム市立美術館グラフィックデザイン部門キュレーター)をゲストとしてお招きします。オランダのブックデザインの歴史や、どのような点に着目して審査しているのかなどを話していただきます。Web
                  サイト『The Graphic Design
                  Review』編集⻑である室賀清徳がモデレーターを務めます。
                  <br />
                  <br />
                  *日本語字幕入りの事前収録した約1時間のトークを配信した後、17:30
                  より
                  BDBD代表のエステル・ショルテンとのQ&Aセッションを予定しております。
                </p>
              </section>
            </div>
          </article>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
