import axios from "axios"

export type ExhibitorCategory = Readonly<{
  id: string
  no: string
  name_ja: string
  name_en: string
}>

export type ExhibitorGenre = Readonly<{
  id: string
  name_ja: string
  name_en: string
}>

export type ExhibitorTag = Readonly<{
  id: number
  name: string
}>

type BoothType = "normal" | "multi"

type BoothComposition = "A" | "B" | "C" | "D" | "E" | "F" | "G"

export type Exhibitor = Readonly<{
  exhibitorId: number
  name: string
  nameEn: string
  country: string
  icon: string
  category: ExhibitorCategory
  genre: ExhibitorGenre[]
  booth_type: BoothType | null
  composition: BoothComposition | null
  table_num: number
  table: string[]
  table_s: string[]
  table_sp: string[]
  wall_num: number
  wall: string[]
  wall_s: string[]
  wall_sp: string[]
  message: string[]
  tag: ExhibitorTag[]
}>

export async function loadExhibitors(): Promise<Exhibitor[]> {
  const res = await axios.get(
    "https://domg29rgj0qo4.cloudfront.net/exibitor/all.json"
  )
  return res.data.exhibitors
}

export async function loadViewCount(): Promise<number> {
  const res = await axios.get(
    "https://domg29rgj0qo4.cloudfront.net/log/requests.json"
  )
  return res.data.total
}
