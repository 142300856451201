import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.en.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This book, produced to accompany an exhibition in the Stedelijk Museum Amsterdam, contains two works by Lebanese-American artist Walid Raad. The extensive survey of the sources he carried out for his PhD thesis inspired him to produce fictitious documents for his artistic practice, and two of these form the two halves of this publication: a cahier and a leporello, handily integrated into the left-hand and right-hand flaps of the ‘archive-style’ cardboard cover with a linen band along its spine created specially by Roger Willems of Roma Publications. The cahier is a kind of facsimile of a diary, based on the diaries of Raad’s father, with ballpoint pen drawings of the bombs that fell around his house. The leporello is a kind of log of plants, the names of which are used as codes to refer to local, regional and international figures from the political landscape of the Middle East. A specially trained botanist has stuck the faces concerned onto the flowers and plants, creating a bizarre botanical catalogue. This way this has been presented in the book is perfect, with the typography on the cover subtly embossed in foil, in exactly the same colour as the linen band referred to above. The high-gloss paper used for both interiors contrasts with the archive-style cardboard surrounding it, lending these ‘documents’ a strange kind of authority; in the same way, the precision of the book’s production stresses the absurdity of the documents it contains, thereby commenting on the fissured reality of a country that for decades has been in search of a semblance of normality, but all the while has actually known only war.`}</p>
    <hr></hr>
    <p>{`Publisher: Roma Publications, Amsterdam`}<br parentName="p"></br>{`
`}{`Concept and design: Hripsimé Visser, Walid Raad, Roger Willems`}</p>
    <p>{`Category: Art, photo and architecture books`}<br parentName="p"></br>{`
`}{`Artist: Walid Raad`}<br parentName="p"></br>{`
`}{`Author: Walid Raad, Hripsimé Visser`}<br parentName="p"></br>{`
`}{`Editor: Dutton Hauhart`}<br parentName="p"></br>{`
`}{`Language: English`}<br parentName="p"></br>{`
`}{`Image editor: Walid Raad`}<br parentName="p"></br>{`
`}{`Editor-in-chief: Roger Willems`}<br parentName="p"></br>{`
`}{`Designer: Roger Willems, Amsterdam; Walid Raad, Medusa (USA)`}<br parentName="p"></br>{`
`}{`Lithographer: Zwaan Lenoir, Wormerveer`}<br parentName="p"></br>{`
`}{`Printer: Zwaan Lenoir, Wormerveer`}<br parentName="p"></br>{`
`}{`Binder: J. Voetelink & Zoon, Haarlem`}<br parentName="p"></br>{`
`}{`Font: Agipo (RP Digital Type Foundry)`}<br parentName="p"></br>{`
`}{`Size in mm: 210 x 280 x 5`}<br parentName="p"></br>{`
`}{`Number of pages: 16 pages leporello, 12 pages brochure`}<br parentName="p"></br>{`
`}{`Print run: 1,000`}<br parentName="p"></br>{`
`}{`Price: € 27.50`}<br parentName="p"></br>{`
`}{`ISBN: 978 94 92811 55 4`}<br parentName="p"></br>{`
`}{`Paper for interior: Leporello: 250gsm tom&otto Gloss (Antalis), brochure: 150gsm tom&otto Gloss (Antalis)`}<br parentName="p"></br>{`
`}{`Cover: 430gsm Cairn Eco Natural Kraft (Christiaan Janssen)`}<br parentName="p"></br>{`
`}{`Binding style: Cover with flaps, leporello is glued in the front flap, the brochure is stitched in the back flap`}<br parentName="p"></br>{`
`}{`Special features: Two different parts are bound in one cover; the spine is glued with linen (Textile Imperial 4810 Aluminium - Balacron International), the cover has grey foil stamping (Kurz Colorit 932) by KuiperDonse.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      