import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.en.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The Grand Projet is an example of twenty-first-century information design. In 640 pages, eight complex urban megaprojects from all over the world – the ‘Grandes Projets’ – are compared by an international team headed by architect and urban planner Kees Christiaanse. The complexity and quantity of the data and insights presented demands an intelligent system that allows for clarity, insight and comparison of the information. Information designer Joost Grootens proves himself more than capable of achieving this in an exemplary way, while also creating a pleasing, attractive book. In this case, the conscious choice for a book as a presentation medium is exploited as both an opportunity and a benefit, while the research and composition of all datasets, tables and the approx. 200 maps and floor plans were created using entirely digital means. The colour system, the typographical approach and the book’s multiple reference systems have all been thought out in terms of ink, paper, lay-out and book block. The result resembles a piece of music by Bach: pleasant on the ear, yet incredibly complex in its structure. The printing and binding are also flawless.`}</p>
    <hr></hr>
    <p>{`Publisher: nai010 publishers, Rotterdam`}<br parentName="p"></br>{`
`}{`Designer: SJG (Joost Grootens, Carina Schwake, Julie da Silva, Megan Adé), Amsterdam`}</p>
    <p>{`Category: Art, photo and architecture books`}<br parentName="p"></br>{`
`}{`Editor: Kees Christiaanse, Anna Gasco, Naomi C. Hanakata`}<br parentName="p"></br>{`
`}{`Language: English`}<br parentName="p"></br>{`
`}{`Illustrator: SJG (Joost Grootens, Carina Schwake, Julie da Silva, Megan Adé)`}<br parentName="p"></br>{`
`}{`Printer: Unicum | Gianotten printed media, Tilburg`}<br parentName="p"></br>{`
`}{`Binder: Boekbinderij Abbringh, Groningen`}<br parentName="p"></br>{`
`}{`Font: Lyon (Commercial Type), Neue Haas Unica Pro (Linotype), Ceremony (Optimo)`}<br parentName="p"></br>{`
`}{`Size in mm: 170 x 240 x 43`}<br parentName="p"></br>{`
`}{`Number of pages: 640`}<br parentName="p"></br>{`
`}{`Print run: 1,000`}<br parentName="p"></br>{`
`}{`Price: € 59.95`}<br parentName="p"></br>{`
`}{`ISBN: 978 94 6208 480 3`}<br parentName="p"></br>{`
`}{`Paper for interior: 115gsm Fly® Weiss 05 neu (Papier Union)`}<br parentName="p"></br>{`
`}{`Endpapers: 170gsm Amber Graphic (Antalis)`}<br parentName="p"></br>{`
`}{`Binding: 115gsm Wibalin® Buckram 546 Silver Grey (Winter & Company) over 2.5 mm greyboard`}<br parentName="p"></br>{`
`}{`Binding style: Sewn hardcover with flat spine, thin boards with minimal square`}<br parentName="p"></br>{`
`}{`Special features: 200 maps and infographics are specially designed for this publication by Studio Joost Grootens.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      