import {
  AmbientLight,
  Color,
  DirectionalLight,
  Euler,
  Mesh,
  MeshStandardMaterial,
  OrthographicCamera,
  Scene,
  Texture,
  Vector2,
  Vector3,
  WebGLRenderTarget,
} from "three"
import { World } from "./World"
import { config } from "../config"
import { SceneObject, GameState } from "../types"
import CameraControls from "../vendor/camera-controls"
import { EventEmitter } from "events"
import { Background } from "../Background"
import { Booth } from "./Booth/Booth"
import { TextMesh } from "../TextMesh"
import TWEEN from "@tweenjs/tween.js"
import { Exhibitor, loadExhibitors } from "../../api"
import { shuffle } from "lodash"
import isMobile from "is-mobile"
import { isDebugMultiBooth } from "../../utils/isDebug"
import { Loader } from "../Loader"
import { getFurnitures } from "./Booth/Compositions/utils"
import { MouseWatcher } from "../MouseWatcher"
import { getLang } from "../../utils/getLang"
import { MoveDir } from "../Game"

const bgmList = [
  "booth_world/01_BOA",
  "booth_world/01_Bounty",
  "booth_world/01_Culatra_Island",
  "booth_world/01_En_Mi_Barrio",
  "booth_world/01_Peperomia_Seedling",
  "booth_world/02_Free_Rod_MckMoon",
  "booth_world/02_Mis_Abuelos",
  "booth_world/02_Wilson",
  "booth_world/03_I'm_Dreaming",
  "booth_world/03_Jazz",
  "booth_world/04_GREETINGS_TO_IDRIS",
  "booth_world/04_Monk",
  "booth_world/04_Sin_World",
  "booth_world/04_Ven_Mamacita",
  "booth_world/05_Sin_Swing",
  "booth_world/05_Soft_Meadow",
  "booth_world/05_Taboo_Bass_Dr",
  "booth_world/06_A_Stimulant_To_The_Imagination",
  "booth_world/06_Out_Of_Reach",
  "booth_world/07_Stand_By_Your",
  "booth_world/07_Tammy",
  "booth_world/07_YOULL_NEVER_GET_TO_HEAVEN",
  "booth_world/08_Blink_Silently",
  "booth_world/08_Gogo",
  "booth_world/08_Once_A_Pun_A_Time",
  "booth_world/08_Psychoswing",
  "booth_world/09_Acoustically_Silent_Switching",
  "booth_world/09_Break_Me,_Break_My_Horse",
  "booth_world/09_Traces",
  "booth_world/09_Walz_5",
  "booth_world/10_Goddess",
  "booth_world/10_Tremendously_Biting_Accent",
  "booth_world/11_Tabu",
  "booth_world/13_Jankovski",
  "booth_world/14_Don't_Think_Right,_It's_All_Twice",
  "booth_world/15_Winter_Echo",
  "booth_world/16_Previn_-_Rose",
  "booth_world/18_Kino",
  "booth_world/19_Poker",
  "booth_world/27_B2_Love",
]

/**
 * ランダムなBGMの名前を取得する。
 * 同じBGMが2回連続で鳴らないよう、一つ前のBGMを記憶している。
 */
let lastBGM: string | undefined
function getRandomBGM(): string {
  let newBGM
  do {
    newBGM = bgmList[Math.floor(Math.random() * bgmList.length)]
  } while (newBGM === lastBGM)

  lastBGM = newBGM
  return newBGM
}

type BoothFilter = Readonly<{
  genre?: string
  tag?: number
}>

export function getFilterFromUrl(): BoothFilter {
  if (typeof window === "undefined") {
    return {}
  }
  const params = new URLSearchParams(window.location.search)
  const tag = params.get("tag")
  return {
    genre: params.get("genre") ?? undefined,
    tag: tag !== null ? parseInt(tag, 10) : undefined,
  }
}

function isBoothVisible(booth: Booth, filter: BoothFilter): boolean {
  const exhibitor = booth.exhibitor
  if (filter.genre === undefined && filter.tag === undefined) {
    return true
  }
  if (exhibitor.genre.some(g => g.id === filter.genre)) {
    return true
  }
  if (exhibitor.tag.some(t => t.id === filter.tag)) {
    return true
  }
  return false
}

async function getTextureRecord(
  name: string
): Promise<Record<string, Texture>> {
  const model = await Loader.loadGLTF(`${name}_bundle.glb`)
  const textures: Record<string, Texture> = {}
  model.traverse(o => {
    const m = o as Mesh
    if (m.isMesh) {
      const mat = m.material as MeshStandardMaterial
      if (mat.map) {
        const [_, exhibitorId] = mat.map.name.split("_")
        textures[exhibitorId] = mat.map
      }
    }
  })
  return textures
}

async function getTexturesRecord(
  name: string
): Promise<Record<string, Texture[]>> {
  const model = await Loader.loadGLTF(`${name}_bundle.glb`)
  const textures: Record<string, Texture[]> = {}
  model.traverse(o => {
    const m = o as Mesh
    if (m.isMesh) {
      const mat = m.material as MeshStandardMaterial
      if (mat.map) {
        const [_, exhibitorId, indexStr] = mat.map.name.split("_")
        const index = parseInt(indexStr, 10)

        if (textures[exhibitorId] === undefined) {
          textures[exhibitorId] = []
        }
        textures[exhibitorId][index] = mat.map
      }
    }
  })
  return textures
}

function getMinZoom(): number {
  return isMobile() ? config.HALL_MIN_ZOOM_SP : config.HALL_MIN_ZOOM
}

export class BoothWorld extends World {
  public readonly renderTarget: WebGLRenderTarget
  public readonly camera: OrthographicCamera
  protected cameraControls: CameraControls
  private bgmTimer: NodeJS.Timeout | undefined

  private state: GameState = {
    boothId: undefined,
    boothFocus: 0,
  }

  private constructor(
    id: number,
    private events: EventEmitter,
    canvas: HTMLCanvasElement,
    scene: Scene,
    objects: SceneObject[],
    private booths: Booth[],
    private nameText: TextMesh
  ) {
    super(id, canvas, scene, objects)

    this.renderTarget = new WebGLRenderTarget(
      canvas.offsetWidth,
      canvas.offsetHeight
    )

    this.camera = this.initCamera()
    this.cameraControls = this.initCameraControls()

    this.events.on("leaveVendor", this.leaveVendor)
    this.events.on("filterBooths", this.filterBooths)

    this.reset()
  }

  static async init(
    id: number,
    events: EventEmitter,
    canvas: HTMLCanvasElement
  ): Promise<BoothWorld> {
    const scene = new Scene()
    scene.background = new Color(0xdcfff9)

    // Lighting
    const ambientLight = new AmbientLight(0xeeeeee)
    scene.add(ambientLight)

    const dirLight = new DirectionalLight(0xffffff, 0.3)
    dirLight.position.set(-10, 10, -10) // 画面左
    scene.add(dirLight)

    // ファニチャーを予めロードしておく
    BoothWorld.preloadFurnitures()

    // 画像、アセットをロード
    const isPC = !isMobile()
    const [
      exhibitors,
      iconTextures,
      wallTextures,
      tableTextures,
    ] = await Promise.all([
      BoothWorld.getExhibitors(),
      getTextureRecord("icon"),
      getTexturesRecord(isPC ? "wall" : "wallSp"),
      getTexturesRecord(isPC ? "table" : "tableSp"),
    ])

    // ブースを生成
    const lang = getLang()
    const booths: Booth[] = await Promise.all(
      exhibitors.map(exhibitor =>
        Booth.init(
          scene,
          exhibitor,
          iconTextures[exhibitor.exhibitorId],
          wallTextures[exhibitor.exhibitorId] ?? [],
          tableTextures[exhibitor.exhibitorId] ?? [],
          lang
        )
      )
    )

    // 背景
    const bg = Background.init(scene, new Vector3(0, -1, 0))

    // ブースのマウスオーバー時に表示するテキスト
    const nameText = await TextMesh.init("", "BoothMouseOver", { width: 640 })
    scene.add(nameText.mesh)

    return new BoothWorld(id, events, canvas, scene, booths, booths, nameText)
  }

  static async getExhibitors(): Promise<Exhibitor[]> {
    // 出展者データをロードし、シャッフルする
    let exhibitors = await loadExhibitors()
    if (isDebugMultiBooth()) {
      // デバッグモードなら複数ブースだけ表示する
      exhibitors = exhibitors.filter(e => e.booth_type === "multi")
    }

    // 名前が長いブースは右側に配置する
    const longNameExhibitorIds = [318, 361, 414, 416, 447]
    const [longNameExhibitors, shortNameExhibitors]: [
      Exhibitor[],
      Exhibitor[]
    ] = [[], []]
    for (const e of exhibitors) {
      if (longNameExhibitorIds.includes(e.exhibitorId)) {
        longNameExhibitors.push(e)
      } else {
        shortNameExhibitors.push(e)
      }
    }
    exhibitors = [...shortNameExhibitors, ...longNameExhibitors]

    // テスト出展者は除外する
    // TODO: 不要になったら消す
    const ignoredBoothIds = [13, 593, 915]
    exhibitors = exhibitors.filter(
      e => !ignoredBoothIds.includes(e.exhibitorId)
    )

    return exhibitors
  }

  /**
   * Furnitureのモデルを事前にロードしてキャッシュに乗せておく
   */
  static preloadFurnitures(): void {
    const furnitures = getFurnitures()
    for (const data of furnitures) {
      Loader.loadGLTF(data.url)
    }
  }

  initCamera(): OrthographicCamera {
    const frustumSize = config.HALL_FRUSTUM_SIZE
    const aspect = this.canvas.offsetWidth / this.canvas.offsetHeight
    const w2 = (frustumSize * aspect) / 2
    const h2 = frustumSize / 2
    const camera = new OrthographicCamera(-w2, w2, h2, -h2, 0.01, 1000)

    return camera
  }

  initCameraControls(): CameraControls {
    const cameraControls = new CameraControls(this.camera, this.canvas)
    cameraControls.mouseButtons = {
      left: CameraControls.ACTION.TRUCK,
      right: CameraControls.ACTION.ROTATE,
      middle: CameraControls.ACTION.NONE,
      wheel: CameraControls.ACTION.ZOOM,
    }
    cameraControls.touches = {
      one: CameraControls.ACTION.TOUCH_TRUCK,
      two: CameraControls.ACTION.TOUCH_ZOOM,
      three: CameraControls.ACTION.TOUCH_ROTATE,
    }

    // カメラの動きを制限する
    cameraControls.minZoom = getMinZoom()
    cameraControls.maxPolarAngle = Math.PI / 3
    cameraControls.minPolarAngle = Math.PI / 4
    cameraControls.dampingFactor = 0.07

    // モバイルでは回転方向を逆にする
    if (isMobile()) {
      cameraControls.polarRotateSpeed = -1
      cameraControls.azimuthRotateSpeed = -1
    }

    return cameraControls
  }

  dispose() {
    super.dispose()
    this.events.off("leaveVendor", this.leaveVendor)
    this.events.off("filterBooths", this.filterBooths)
  }

  async start(): Promise<void> {
    if (document.visibilityState !== "visible") {
      // タブが非表示の場合は表示されるのを待ってから続きをおこなう
      const start = () => {
        if (document.visibilityState === "visible") {
          document.removeEventListener("visibilitychange", start)
          this.showVisibleBooths()
        }
      }
      document.addEventListener("visibilitychange", start)
    } else {
      await this.showVisibleBooths()
    }
    MouseWatcher.isEnabled = true
  }

  async showVisibleBooths(): Promise<void> {
    // ジャンルかタグの条件にマッチするブースを取得
    const filter = getFilterFromUrl()
    let visibleBooths = this.booths.filter(b => isBoothVisible(b, filter))
    if (visibleBooths.length === 0) {
      // マッチするブースが存在しない場合は全ブース表示する
      // 何も表示されないと体験が悪いので……
      visibleBooths = this.booths
    }

    // ブースの配置を計算
    const { positions, center } = BoothWorld.computePositions(visibleBooths)

    // Move camera to the center
    const cameraPos = new Vector3(0, 0, config.HALL_CAMERA_DISTANCE)
    cameraPos.applyEuler(
      new Euler(config.HALL_CAMERA_ROT_X, config.HALL_CAMERA_ROT_Y, 0)
    )
    cameraPos.add(center)
    this.cameraControls.setLookAt(
      cameraPos.x,
      cameraPos.y,
      cameraPos.z,
      center.x,
      center.y,
      center.z,
      false
    )

    // 1フレームずつboothを追加する
    const boothCount = this.booths.length
    let i = 0
    let iVisible = 0 // 表示するブースのindex
    new TWEEN.Tween({ t: 0 })
      .to({ t: 1 }, 10000)
      .onUpdate(() => {
        if (i < boothCount) {
          const booth = this.booths[i++]
          this.scene.add(booth.mesh)

          if (isBoothVisible(booth, filter)) {
            if (iVisible < positions.length) {
              const p = positions[iVisible++]
              booth.mesh.position.set(p.x, p.y, p.z)

              // 中央に近いブースはアニメーションする
              if (booth.mesh.position.length() < 20) {
                booth.mesh.scale.set(0, 0, 0)
                booth.scale(1, 100)
              }
            }
          } else {
            booth.scale(0)
          }
        }
      })
      .start()
  }

  reset() {
    this.resetMoving()

    // カメラを初期位置に移動
    const cameraPos = new Vector3(0, 0, config.HALL_CAMERA_DISTANCE)
    cameraPos.applyEuler(
      new Euler(config.HALL_CAMERA_ROT_X, config.HALL_CAMERA_ROT_Y, 0)
    )
    this.cameraControls.zoomTo(getMinZoom(), false)
    this.cameraControls.setLookAt(
      cameraPos.x,
      cameraPos.y,
      cameraPos.z,
      0,
      0,
      0,
      false
    )
  }

  resize(width: number, height: number) {
    const frustumSize = 20
    const aspect = width / height
    this.camera.left = (-frustumSize * aspect) / 2
    this.camera.right = (frustumSize * aspect) / 2
    this.camera.top = frustumSize / 2
    this.camera.bottom = -frustumSize / 2
    this.camera.updateProjectionMatrix()
    const dpr = window.devicePixelRatio
    this.renderTarget.setSize(width * dpr, height * dpr)
  }

  loop(): void {
    if (!this.enabled) {
      return
    }
    this.state.boothFocus += this.state.boothId !== undefined ? 0.01 : -0.01
    this.state.boothFocus = Math.min(Math.max(this.state.boothFocus, 0), 1)

    // Move objects
    for (const o of this.objects) {
      o.update(this.state, this.camera)
    }

    // Update TextFade
    const zoom = (this.camera as OrthographicCamera).zoom
    const showText = config.HALL_TEXT_ZOOM_THRESHOLD < zoom
    TextMesh.fade("Booth", showText)

    // Move camera
    const delta = this.clock.getDelta()
    const hasControlsUpdated = this.cameraControls.update(delta)
  }

  getBoothUnderMouse(x: number, y: number): Booth | undefined {
    this.raycaster.setFromCamera(new Vector2(x, y), this.camera)

    const intersects = this.raycaster.intersectObjects(
      this.scene.children,
      true
    )

    const mesh = intersects.find(i => i.object.name.includes("Booth"))
      ?.object as Mesh

    const id = mesh?.name.split(" ")[1]
    if (id) {
      return Booth.findById(id)
    }
  }

  private lastBoothUnderMouse: Booth | undefined

  onMouseMove(x: number, y: number): void {
    const booth = this.getBoothUnderMouse(x, y)
    if (booth) {
      if (this.lastBoothUnderMouse !== booth) {
        const lastBooth = this.lastBoothUnderMouse
        this.lastBoothUnderMouse = booth

        // ブースをスケールする。awaitする必要はない
        booth.scale(1.03, 100)
        lastBooth?.scale(1, 100)

        // テキストのマテリアルを変更する。awaitする必要はない
        booth.name.changeMaterial("BoothMouseOver", 0xff0000)
        lastBooth?.name.changeMaterial("Booth", 0x222222)
      }

      // マウスカーソルを更新する
      if (this.state.boothId === undefined) {
        document.body.style.cursor = "pointer"
      }
    } else {
      if (this.lastBoothUnderMouse) {
        this.lastBoothUnderMouse.name.changeMaterial("Booth", 0x222222)
        this.lastBoothUnderMouse?.scale(1, 100)
        this.lastBoothUnderMouse = undefined
      }

      document.body.style.cursor = ""
    }
  }

  onClick(x: number, y: number): void {
    // カメラの矯正移動中は何もしない
    if (!this.cameraControls.enabled) {
      return
    }

    const booth = this.getBoothUnderMouse(x, y)
    if (!booth) {
      return
    }

    this.focusBooth(booth)
  }

  escape() {
    this.events.emit("requestLeaveVendor")
  }

  resetCameraCenter(): void {
    // 画面中央の座標を得る
    const cameraPos = this.camera.position
    const cameraRay = new Vector3(0, 0, 1)
      .applyEuler(this.camera.rotation)
      .normalize()
    const groundPos = cameraPos
      .clone()
      .sub(
        cameraRay.clone().multiplyScalar(Math.abs(cameraPos.y / cameraRay.y))
      )

    // カメラの回転の中心が画面中央になるようにする
    this.cameraControls.setTarget(groundPos.x, groundPos.y, groundPos.z, true)
  }

  async enterWorld(prevWorldId: number) {
    this.showVisibleBooths() // awaitしない

    if (prevWorldId === 0) {
      this.cameraControls.enabled = false
      this.cameraControls.minZoom = 0
      const isPC = !isMobile()
      const minZoom = getMinZoom()

      await new Promise(resolve => {
        const p = new Vector3()
        new TWEEN.Tween({ t: isPC ? 0 : 0.5 })
          .to({ t: 1 }, 2000)
          .easing(isPC ? TWEEN.Easing.Cubic.InOut : TWEEN.Easing.Cubic.Out)
          .onUpdate(({ t }) => {
            this.cameraControls.zoomTo((t * 0.9 + 0.1) * minZoom, false)
          })
          .onComplete(resolve)
          .start()
      })

      this.camera.zoom = minZoom
      this.cameraControls.enabled = true
      this.cameraControls.minZoom = minZoom
    }

    this.events.emit("areaChanged", "booth")

    // ランダムなBGMを再生
    this.events.emit("playBGM", getRandomBGM())
    const interval = isMobile() ? 3 : 2 // PCなら2分間隔、モバイルなら3分間隔
    this.bgmTimer = setInterval(() => {
      this.events.emit("playBGM", getRandomBGM())
    }, interval * 60 * 1000)
  }

  async leaveWorld(nextWorldId: number) {
    // BGM自動再生を停止
    if (this.bgmTimer) {
      clearInterval(this.bgmTimer)
    }

    await new Promise(ok => setTimeout(ok, 2000))

    for (const b of this.booths) {
      this.scene.remove(b.mesh)
    }
  }

  moveToVendor(id: string): void {
    const booth = this.booths.find(b => b.id === id)
    if (booth) {
      this.focusBooth(booth)
    }
  }

  leaveVendor = (): void => {
    const zoom = getMinZoom()
    this.cameraControls.minZoom = zoom
    this.cameraControls.zoomTo(zoom, true)
    this.events.emit("playSE", `se/4`)
    this.state.boothId = undefined
  }

  private focusBooth(booth: Booth) {
    this.state.boothId = booth.id
    document.body.style.cursor = ""

    // ブースのBoundingSphereを取得
    const s = booth.getBoundingSphereOfComposition()
    const objectPos = s.center.clone()
    objectPos.setY(1) // 少し上にフォーカスする

    if (!isMobile()) {
      // PCの場合はサイドバーを考慮し、フォーカスをズラす
      objectPos.x -= 1
    }

    // カメラ位置を計算
    const cameraPos = new Vector3(0, 0, config.BOOTH_CAMERA_DISTANCE)
    cameraPos.applyEuler(
      new Euler(config.BOOTH_CAMERA_ROT_X, config.BOOTH_CAMERA_ROT_Y, 0)
    )
    cameraPos.add(objectPos)

    // カメラを移動し、オブジェクトに視点を合わせる
    this.cameraControls.setLookAt(
      cameraPos.x,
      cameraPos.y,
      cameraPos.z,
      objectPos.x,
      objectPos.y,
      objectPos.z,
      true
    )

    // BoundingBoxからズーム倍率を計算し、ズームする
    const diameter = s.radius * 2 + 0.5 // マージンを追加しておく
    const width = this.camera.right - this.camera.left
    const height = this.camera.top - this.camera.bottom
    const zoom = Math.min(width / diameter, height / diameter)
    this.cameraControls.zoomTo(zoom, true)
    this.cameraControls.minZoom = zoom

    // ベンダーに話しかけるメニューを表示する
    this.events.emit("talkToVendor", booth.id)

    // SEを鳴らす
    this.events.emit("playSE", `se/3`)
  }

  filterBooths = async (ids: string[] | undefined): Promise<void> => {
    this.lastBoothUnderMouse = undefined

    // Hide all booths
    for (const b of this.booths) {
      b.scale(0)
    }

    const filteredBooths =
      ids !== undefined
        ? this.booths.filter(b => ids.includes(b.id))
        : this.booths

    const { positions, center } = BoothWorld.computePositions(filteredBooths)

    // Move camera to the center
    this.cameraControls.setLookAt
    const cameraPos = new Vector3(0, 0, config.HALL_CAMERA_DISTANCE)
    cameraPos.applyEuler(
      new Euler(config.HALL_CAMERA_ROT_X, config.HALL_CAMERA_ROT_Y, 0)
    )
    cameraPos.add(center)
    this.cameraControls.setLookAt(
      cameraPos.x,
      cameraPos.y,
      cameraPos.z,
      center.x,
      center.y,
      center.z,
      true
    )

    // Show filtered booths
    for (let i = 0; i < filteredBooths.length; i++) {
      const booth = filteredBooths[i]
      const p = positions[i]
      booth.mesh.position.set(p.x, p.y, p.z)

      // 中央に近いブースはアニメーションする
      if (booth.mesh.position.clone().sub(center).length() < 20) {
        booth.scale(1, 100)
        await new Promise(ok => setTimeout(ok, 20)) // Wait a bit
      } else {
        booth.scale(1)
      }
    }
  }

  static computePositions(
    booths: Booth[]
  ): { positions: Vector3[]; center: Vector3 } {
    // 消費するセル数をカウント
    const totalCells = booths.reduce((acc, b) => acc + b.compo.cells.length, 0)

    // セル数から、グリッドの一辺の長さを割り出す
    const dim = Math.ceil(Math.sqrt(totalCells))

    // 位置の最大値/最小値
    let minX = Infinity
    let minZ = Infinity
    let maxX = -Infinity
    let maxZ = -Infinity

    const positions = []
    const isUsed: boolean[] = []
    for (let i = 0, cellIndex = 0; i < booths.length; i++) {
      // 最も近い未使用のセルを探す
      while (isUsed[cellIndex]) {
        cellIndex++
      }

      // セルのxz座標を計算
      const x = Math.floor(cellIndex / dim)
      const z = cellIndex - x * dim

      // セルの座標から実際の位置を計算
      const p = new Vector3(x - dim / 2, 0, z - dim / 2).multiplyScalar(
        config.HALL_CELL_SIZE
      )
      positions.push(p)

      // 占有するセルの使用済みフラグを立てておく
      for (const cell of booths[i].compo.cells) {
        const cellX = x + cell[0]
        const cellZ = z + cell[1]
        isUsed[cellX * dim + cellZ] = true
      }

      // 位置の最大値/最小値を更新
      minX = Math.min(p.x, minX)
      minZ = Math.min(p.z, minZ)
      maxX = Math.max(p.x, maxX)
      maxZ = Math.max(p.z, maxZ)
    }

    const center = new Vector3((minX + maxX) / 2, 0, (minZ + maxZ) / 2)

    return {
      positions,
      center,
    }
  }

  private isMoving: Record<MoveDir, boolean> = {
    left: false,
    right: false,
    up: false,
    down: false,
  }
  private moveVelocity: Record<MoveDir, number> = {
    left: 0,
    right: 0,
    up: 0,
    down: 0,
  }

  setMoving(dir: MoveDir, isMoving: boolean) {
    this.isMoving[dir] = isMoving
  }

  resetMoving() {
    this.isMoving["left"] = false
    this.isMoving["right"] = false
    this.isMoving["up"] = false
    this.isMoving["down"] = false
    this.moveVelocity["left"] = 0
    this.moveVelocity["right"] = 0
    this.moveVelocity["up"] = 0
    this.moveVelocity["down"] = 0
  }

  move(deltaTime: number) {
    for (const key of Object.keys(this.moveVelocity) as MoveDir[]) {
      let velocity = this.moveVelocity[key]
      velocity = Math.min(
        Math.max(velocity + (this.isMoving[key] ? 0.1 : -0.05), 0),
        1
      )
      this.moveVelocity[key] = velocity
      this.moveByDir(key, velocity * deltaTime)
    }
  }

  private moveByDir(dir: MoveDir, speed: number) {
    if (dir === "left") {
      this.cameraControls.truck(-8 * speed, 0, true)
    } else if (dir === "right") {
      this.cameraControls.truck(8 * speed, 0, true)
    } else if (dir === "up") {
      this.cameraControls.truck(0, -6 * speed, true)
    } else if (dir === "down") {
      this.cameraControls.truck(0, 6 * speed, true)
    }
  }
}
