import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/VabfEvents.en.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SocialShare = makeShortcode("SocialShare");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className="youtube__wrap">
  <iframe width="560" height="315" src="https://www.youtube.com/embed/lRZOSy1Wz1I?controls=0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
    <div className="share__2col">
  <div className="share__2col__link">
    <a href="https://youtu.be/lRZOSy1Wz1I" target="_blank">
      Youtube Live をみる
    </a>
  </div>
  <div className="share__2col__icon">
    <SocialShare title={props.pageContext.frontmatter.title} mdxType="SocialShare" />
  </div>
    </div>
    <figure {...{
      "className": "content__img__middle"
    }}>{`
  `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "980px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "133.0612244897959%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEAwX/xAAXAQADAQAAAAAAAAAAAAAAAAABAgME/9oADAMBAAIQAxAAAAGpRLLISlh2F3zkeMIal//EAB8QAAICAQQDAAAAAAAAAAAAAAECAwQSABETIyEiJP/aAAgBAQABBQK22KK21VC7C6etD8ajxdGMca4VBE7i8OmH2o8jrr//xAAVEQEBAAAAAAAAAAAAAAAAAAAQQf/aAAgBAwEBPwEp/8QAHBEAAgEFAQAAAAAAAAAAAAAAAAESAhEhMnHw/9oACAECAQE/AVqi3sCSgukaj//EACEQAAEDAwQDAAAAAAAAAAAAAAEAAhESMTIhUXGBEyJB/9oACAEBAAY/AvSZ+nZVTU9T56e1l0so4usQeSr5IudIUtaSp2KIdrdaOIX/xAAdEAACAwACAwAAAAAAAAAAAAABEQAhMUFRcZGx/9oACAEBAAE/Ie2hocMGqpEnmNrxGGI5vA+xRBtVYrjPaojFkSVCEIgWVzsHATHALyogZx2gqDNoz//aAAwDAQACAAMAAAAQR/8Aj//EABcRAQEBAQAAAAAAAAAAAAAAAAEAITH/2gAIAQMBAT8QesS6hy//xAAbEQADAAIDAAAAAAAAAAAAAAAAARFBgSGR0f/aAAgBAgEBPxBFaZGlx16LLYaHxJs//8QAIBABAAICAgIDAQAAAAAAAAAAAQARITFRYUFxgZHB0f/aAAgBAQABPxB7UUiPk4C4qFmRutPglPKurd+2/wA4h2mjrDMa/HcJQLarsd+fPUa4oNaDoL1/YAqw1NXutwo0bo4NB3AIE0YfXWY1pjL4vDCYIABOAcfUDtTIQL5n/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/baf961aadc1163057b30c8e2e5e69dbd/1a3b7/img_1.webp 245w", "/static/baf961aadc1163057b30c8e2e5e69dbd/fb835/img_1.webp 490w", "/static/baf961aadc1163057b30c8e2e5e69dbd/096d3/img_1.webp 980w", "/static/baf961aadc1163057b30c8e2e5e69dbd/46b4f/img_1.webp 1125w"],
            "sizes": "(max-width: 980px) 100vw, 980px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/baf961aadc1163057b30c8e2e5e69dbd/c168b/img_1.jpg 245w", "/static/baf961aadc1163057b30c8e2e5e69dbd/bb66a/img_1.jpg 490w", "/static/baf961aadc1163057b30c8e2e5e69dbd/2f919/img_1.jpg 980w", "/static/baf961aadc1163057b30c8e2e5e69dbd/0df69/img_1.jpg 1125w"],
            "sizes": "(max-width: 980px) 100vw, 980px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/baf961aadc1163057b30c8e2e5e69dbd/2f919/img_1.jpg",
            "alt": "img 1",
            "title": "img 1",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>
    </figure>
    <h2>{`POST/PHOTO とは何か？`}</h2>
    <p>{`2010 年代の、社会全体のデジタルトランスフォームにより、写真も根底からシフトをとげた。銀塩システムからデータへの移行は、アートとしての写真を拡張しただけでなく、「写真とは何か？」という問いにより、写真全体をひっくり返してしまった。
POST/PHOTO は、それをさらに加速するキーワードである。コロナによって刻印された 2020 年代の写真の行方を、討議する。`}</p>
    <ul>
      <li parentName="ul">
        <span className="font__small">無料で視聴いただけます。</span>
      </li>
      <li parentName="ul">
        <span className="font__small">
  インターネット回線の状況やサーバー負荷、その他視聴者の PC
  環境により、映像が途切れる、または停止するなど正常に視聴できない事がございます。
        </span>
      </li>
      <li parentName="ul">
        <span className="font__small">
  配信終了後も TOKYO ART BOOK FAIR の youtube
  チャンネルで動画を視聴いただけます。
        </span>
      </li>
    </ul>
    <figure {...{
      "className": "content__img__small"
    }}>{`
  `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "922px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "98.77551020408163%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAUEBgP/xAAYAQACAwAAAAAAAAAAAAAAAAABAwACBP/aAAwDAQACEAMQAAABrxOj459PVTMlnAIOYaR//8QAGxAAAgMBAQEAAAAAAAAAAAAAAQMAAgQRExT/2gAIAQEAAQUC8RHN4ToZ2AdtnugLjMq72+Jc/8QAGREAAgMBAAAAAAAAAAAAAAAAAAECEBGB/9oACAEDAQE/AZPDo6//xAAaEQACAwEBAAAAAAAAAAAAAAAAAQIDERJC/9oACAECAQE/AaIKW6NpeCt5I6Z//8QAIBAAAQIFBQAAAAAAAAAAAAAAAQARAhAhMUEDIlFhcf/aAAgBAQAGPwK5T6ZLPUrbEWlXGFx7J6g9K8S//8QAIBABAAEEAAcAAAAAAAAAAAAAAQAQESExQVGBkaHR8f/aAAgBAQABPyHJfzxx0cmmAQzmSlw2F1zghLkFLy9gn3z1P//aAAwDAQACAAMAAAAQLBh9/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARIf/aAAgBAwEBPxCAkPLC/8QAGxEAAgEFAAAAAAAAAAAAAAAAAAERIUFhoeH/2gAIAQIBAT8QZNYNIWnB1BmU/8QAHBABAAICAwEAAAAAAAAAAAAAAREhADEQQWFR/9oACAEBAAE/EFWnmZGYrvDhZlrPDzBQNoMvHccs1NstK1Z5GiCtQHDQ3bWSfusUVvbyf//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/e5ce218e458947e522cc2762ca29db99/1a3b7/img_2.webp 245w", "/static/e5ce218e458947e522cc2762ca29db99/fb835/img_2.webp 490w", "/static/e5ce218e458947e522cc2762ca29db99/7a738/img_2.webp 922w"],
            "sizes": "(max-width: 922px) 100vw, 922px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/e5ce218e458947e522cc2762ca29db99/c168b/img_2.jpg 245w", "/static/e5ce218e458947e522cc2762ca29db99/bb66a/img_2.jpg 490w", "/static/e5ce218e458947e522cc2762ca29db99/02899/img_2.jpg 922w"],
            "sizes": "(max-width: 922px) 100vw, 922px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/e5ce218e458947e522cc2762ca29db99/02899/img_2.jpg",
            "alt": "img 2",
            "title": "img 2",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>
    </figure>
    <div className="content__noimginfo">
  <div className="content__noimginfo__title">
    <p>多和田有希</p>
  </div>
  <div className="content__noimginfo__text">
    <p>
      1978年、静岡県生まれ。東北大学卒業後、ロンドン芸術大学キャンバウェルカレッジ写真学科を経て東京藝術大学大学院博士課程修了。2019年より京都芸術大学、美術工芸学科で教鞭を執る。人間の精神的治癒のシステムをテーマに、写真療法のリサーチと、自らの撮影
      した写真のプリントを焼く、削るなどの独自のプロセスによって作品を制作する。主な個展に「悪魔祓い、
      系統樹、神経の森」(G/Pgallery、東京、2018)「Burnt Photographs」(TARO
      NASU、東京、2012)、主なグループ展に「5×3」(Kunstraum
      Düsseldorf、デュッセルドルフ、2015)、「カミナリとアート 光 / 電気 / 神さま
      」(群馬県立館林美術館、群馬、2017)、「HAMAMATSU SESSIONS」(Hirano Art
      Gallery、 浜松、2019)など。
    </p>
  </div>
    </div>
    <div className="content__noimginfo">
  <div className="content__noimginfo__title">
    <p>川島崇志</p>
  </div>
  <div className="content__noimginfo__text">
    <p>
      1985年、宮城県白石市生まれ。東京を拠点に活動を行
      なっている。東京工芸学大学院芸術学研究科博士前期
      課程メディアアート専攻写真領域修了。修士(芸術学)
      取得。自然現象をモチーフとして、圧倒的な自然の力
      によってもたらされるカタストロフィや、土地の記憶
      を辿る不在の物語を、自分自身の経験や問いを交えな
      がら架空のストーリーによって提示するという作品を
      制作してきた。主な個展に「Doorgang」(G/P gallery、 東 京、2018)、「Absence
      and Ambience」( 大 和 日 英
      基金ジャパンハウスギャラリー、ロンドン・イギリス、 2017)、 主 な 受 賞
      に「Foam Talent 2019」(Foam Museum、アムステルダム・オランダ、2019)、第18
      回文化庁メディア芸術祭(審査委員会推薦作品、東京、 2015)などがある
    </p>
  </div>
    </div>
    <div className="content__noimginfo">
  <div className="content__noimginfo__title">
    <p>後藤繁雄</p>
  </div>
  <div className="content__noimginfo__text">
    <p>
      編集者、クリエイティブディレクター、アートプロデューサー。京都芸術大学教授。坂本龍一、細野晴臣、篠山紀信、荒木経惟、蜷川実花、名和晃平らのアーティストブック、写真集を編集。展覧会のキュレイション、若手アーティストの発掘・育成・サポート、育成などにも力を入れる。また、ギャラリー/出版社G/P＋abpを主宰。アートブック振興のためNEOTOKYOZINEのプロデュースも行う。また篠山紀信・蜷川実花らの大型美術館巡回展を成功させた。
    </p>
  </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      