import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`アド・ファン・デンデレンの『Welkom in Suid-Africa (1991)』を再撮影しただけのプロジェクトではなく、本書は多様な声、視点、そして視覚言語を包み受け入れることについての物語でもある。本書の制作を提案したのはファン・デンデレンではなく、一地方の写真家レボハング・トラリで、彼は自分の故郷であるウェルコムから 1000 キロも離れたケープタウンで学生をしていた頃に、 ファン・デンデレンの最初の作品集を発見する。この出会いが新たな共同作業を生み、現代のドキュメンタリー写真に一石を投じることになる。被写体について、あるいは被写体のために出版するのではなく、その作品集から聞こえてきた共同体の声を本書は描いている。トラリとファン・デンデレン、そして作家のマルガリス・クレイウェフトは、古い写真をそれらが撮影された場所に持ち出して写真ワークショップを開き、アルバムから写真を収集し、彼ら自身が撮影した写真も加えた。そしてデザイナージェレミ・ヤンセンによる最低限だが効果的なビジュアルの構造が組み合わさって生まれたのが、「複数の声による語り」である。各章はベージュの厚く硬い紙で区切られ、ページがめくりやすくなっている。ここでも綴じ部分は隠れておらず、カバーと本紙が縫い合わされており、楽に開けるようになっている。抑制され、注意深くなされた写真編集と配置によって多くの声、個人史、視座に空間と方向性を与えている。模範的な 1 冊だ。`}</p>
    <hr></hr>
    <p>{`出版社： Paradox, Edam & Atlas Contact, Amsterdam`}<br parentName="p"></br>{`
`}{`デザイン： Jeremy Jansen, Amsterdam`}</p>
    <p>{`カテゴリー： アート、写真、建築`}<br parentName="p"></br>{`
`}{`編集：Atlas Contact (Tilly Hermans, Fieke Janse, Renée Peereboom)`}<br parentName="p"></br>{`
`}{`言語：オランダ語`}<br parentName="p"></br>{`
`}{`翻訳：Francesca de Châtel`}<br parentName="p"></br>{`
`}{`写真：Ad van Denderen, Lebohang Tlali and others`}<br parentName="p"></br>{`
`}{`イメージ編集：Ad van Denderen, Anne Ruygt, Bas Vroege`}<br parentName="p"></br>{`
`}{`編集：Atlas Contact (Tilly Hermans, Fieke Janse, Renée Peereboom)`}<br parentName="p"></br>{`
`}{`リトグラフ：Colour & Books (Sebastiaan Hanekroot), Apeldoorn`}<br parentName="p"></br>{`
`}{`印刷：NPN Drukkers, Breda`}<br parentName="p"></br>{`
`}{`製本：Boekbinderij Patist, Den Dolder`}<br parentName="p"></br>{`
`}{`フォント：Akkurat (Lineto)`}<br parentName="p"></br>{`
`}{`サイズ（ミリ）：170 x 240 x 22`}<br parentName="p"></br>{`
`}{`ページ数：304`}<br parentName="p"></br>{`
`}{`発行部数：2,400`}<br parentName="p"></br>{`
`}{`価格：€ 39.90`}<br parentName="p"></br>{`
`}{`ISBN：978 900 450 3817 9`}<br parentName="p"></br>{`
`}{`本文用紙：120gsm Munken Lynx (Antalis), wrappers: 150gsm Munken Lynx Rough (Antalis)`}<br parentName="p"></br>{`
`}{`カバー：300gsm Munken Lynx Rough (Antalis)`}<br parentName="p"></br>{`
`}{`出すとジャケット：170gsm Munken Lynx Rough (Antalis)`}<br parentName="p"></br>{`
`}{`製本方法：糸かがりソフトカバー、表紙計 4 ページ縫い込み、透明に糊付けされた背の上にダストジャケット`}<br parentName="p"></br>{`
`}{`特記事項：ダストジャケットは黒、Pantone4535 と 871 で印刷。本の内容を仕切る 8 つのタイトルページは黒と Pantone4535 で印刷。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      