import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/DefaultPage.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`ディレクター：中島佑介`}</p>
    <p>{`アートディレクター ：田中義久`}<br parentName="p"></br>{`
`}{`デザイナー：山田悠太朗`}</p>
    <p>{`ウェブディレクター：萩原俊矢`}<br parentName="p"></br>{`
`}{`ウェブプロジェクトマネージャー：小野友資`}<br parentName="p"></br>{`
`}{`ウェブ 3D プログラマー: 天城孝義`}<br parentName="p"></br>{`
`}{`ウェブプログラマー：天城孝義、黒田明宏、萩原俊矢、長谷川司、松田翔伍、宮崎信人`}<br parentName="p"></br>{`
`}{`3D プロトタイピング: 原淳之助`}<br parentName="p"></br>{`
`}{`ウェブデザイナー：井山桂一`}</p>
    <p>{`空間デザイン：木内俊克、sunayama studio（砂山太一、工藤外四）`}</p>
    <p>{`コーポレイトリレーション：濱中敦史`}<br parentName="p"></br>{`
`}{`会場構成：西尾健史`}<br parentName="p"></br>{`
`}{`PR：鳥海陽子`}<br parentName="p"></br>{`
`}{`翻訳：ジョン・B・カイザー、サム・セイン、キム・テラン、宮城太、横山聡子、片桐由賀`}<br parentName="p"></br>{`
`}{`キュレーター（Dutch Artists’ Book: Then and Now）：深井佐和子`}</p>
    <p>{`効果音：三宅亮太`}<br parentName="p"></br>{`
`}{`BGM 選曲：COMPUMA`}</p>
    <p>{`プロジェクトマネージャー：岡部史絵、東直子、中山庸子、山田沙也香、角田芽央子、上遠野ゆい`}<br parentName="p"></br>{`
`}{`プロジェクトリーダー：大智由実子、尾関加純、来海万由、高橋凉太、戸倉里奈`}<br parentName="p"></br>{`
`}{`サポートスタッフ：飯村大樹、石井輝紀 、池澤加那、井上啓、岩井友香、高毓安、鈴木美乃里、田澤陽、浜田亜希、松永茜、宮越裕生、森かおる`}</p>
    <p>{`共催：一般社団法人東京アートブックフェア、公益財団法人東京都歴史文化財団 東京都現代美術館`}<br parentName="p"></br>{`
`}{`協賛：富士ゼロックス株式会社、三菱地所株式会社、株式会社 資生堂、株式会社イニュニック、理想科学工業株式会社`}<br parentName="p"></br>{`
`}{`助成：オランダ王国大使館、DutchCulture オランダ国際文化協力センター`}<br parentName="p"></br>{`
`}{`協力：Creative Industries Fund NL、オランダ文学基金、モンドリアン財団、Aēsop`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      