import {
  DoubleSide,
  Mesh,
  MeshBasicMaterial,
  PlaneBufferGeometry,
  Texture,
  Vector3,
} from "three"
import {
  UNIT,
  FURNITURE_MARGIN_WALL,
  FURNITURE_MARGIN_TABLE,
} from "../constants"
import { Composition } from "./types"
import { getRandomFurniture, randInt } from "./utils"

const PLANE_GEOMETRY = new PlaneBufferGeometry(1.2, 0.9)

type CompositionPatternName =
  | "A"
  | "B"
  | "C"
  | "D"
  | "E"
  | "F"
  | "G"
  | "H"
  | "I"
  | "J"
  | "K"
  | "L"

/** Wallの向き。0:横長 1:縦長 */
type WallRotation = 0 | 1

/** Tableの向き。0:横長 1:縦長 */
type TableRotation = 0 | 1

/** WallをTableの中心からどれだけズラすか */
type WallOffset = 0 | 1 | 2

type CompositionPattern = [WallRotation, TableRotation, WallOffset]

const patterns: Record<CompositionPatternName, CompositionPattern> = {
  A: [0, 0, 0],
  B: [0, 0, 1],
  C: [0, 0, 2],
  D: [1, 0, 0],
  E: [1, 0, 1],
  F: [1, 0, 2],
  G: [0, 1, 0],
  H: [0, 1, 1],
  I: [0, 1, 2],
  J: [1, 1, 0],
  K: [1, 1, 1],
  L: [1, 1, 2],
}

export class NormalComposition implements Composition {
  public readonly cells: [number, number][] = [[0, 0]]
  public readonly profileOffset = new Vector3(0, 0, 0)

  private constructor(public readonly mesh: Mesh) {}

  static async init(
    name: string,
    tables: Texture[],
    walls: Texture[],
    pattern: CompositionPatternName
  ): Promise<NormalComposition> {
    const root = new Mesh()
    root.name = name

    // パラメータを初期化
    const [wallRot, tableRot, wallOffset] = patterns[pattern]
    const wallXUnit = (tableRot === 0 ? 1.2 : 0.9) / 2

    // Table
    const table = new Mesh(
      PLANE_GEOMETRY,
      new MeshBasicMaterial({
        map: tables[0] ?? null,
      })
    )
    table.position.set(0, UNIT * 10, 0)
    if (tableRot === 1) {
      table.rotateY(-Math.PI / 2)
    }
    table.rotateX(-Math.PI / 2)
    root.add(table)

    // Wall
    const wall = new Mesh(
      wallRot === 0
        ? new PlaneBufferGeometry(1.2, 0.9)
        : new PlaneBufferGeometry(0.9, 1.2),
      new MeshBasicMaterial({
        map: walls[0] ?? null,
        side: DoubleSide,
      })
    )
    if (tableRot === 0) {
      wall.position.set(
        -wallOffset * wallXUnit,
        UNIT * 10 + (wallRot === 0 ? 0.45 : 0.6),
        -0.45 - UNIT
      )
    } else {
      wall.position.set(
        -wallOffset * wallXUnit,
        UNIT * 10 + (wallRot === 0 ? 0.45 : 0.6),
        -0.6 - UNIT
      )
    }
    root.add(wall)

    // Wall Furniture
    const wallFurniture = await getRandomFurniture()
    const wp = wall.position
    wallFurniture.position.set(
      wp.x + (randInt(3) - 1) * 0.4,
      wp.y - 0.45 - randInt(2) * UNIT * 4,
      wp.z - (randInt(1) * 3 + 4) * UNIT - FURNITURE_MARGIN_WALL
    )
    wallFurniture.rotateY((randInt(4) * Math.PI) / 2)
    root.add(wallFurniture)

    // Table Furniture
    const tableFurniture = await getRandomFurniture()
    const tp = table.position
    tableFurniture.position.set(
      tp.x + (randInt(2) - 0.5) * 0.6,
      tp.y - (randInt(3) * 2 + 5) * UNIT - FURNITURE_MARGIN_TABLE,
      tp.z + (randInt(2) - 0.5) * 0.45
    )
    tableFurniture.rotateY((randInt(4) * Math.PI) / 2)
    root.add(tableFurniture)

    return new NormalComposition(root)
  }
}
