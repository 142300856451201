import dedent from "dedent"

export const TransitionShader = {
  uniforms: {
    tex1: { value: null },
    tex2: { value: null },
    fade: { value: 1.0 },
  },
  vertexShader: dedent`
        varying vec2 vUv;

        void main() {
        	vUv = uv;
        	gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
    `,
  fragmentShader: dedent`
        precision highp float;
        uniform sampler2D tex1;
        uniform sampler2D tex2;
        uniform float fade;
        varying vec2 vUv;

        void main() {
            vec4 col = mix(
                texture2D(tex1, vUv),
                texture2D(tex2, vUv),
                min(fade, 1.0)
            );
            gl_FragColor = col;
        }
    `,
}
