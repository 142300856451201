import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.en.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This small book is a report on a re-enactment project by Taiwanese designer Chen Jhen, recalling her attempts to assimilate into Dutch culture at the lunch table of an Amsterdam design agency. Desperate, she secretly jots down very precise notes on the – for her unfathomable – way the other people around the table are eating and behaving. These notes then form the script for a performance of this observed ‘lunch table behaviour’. The book is a written-out version of the performance: her reworked notes, descriptions of actions spread across the pages (‘chewing – chewing – scooping some salad with fork’) and photos of gestures and plates of food. Using a visible screen works like a translation of the process of zooming in on the details observed. Chen dissects table manners like an anthropologist, resulting in a surprising, at times rather embarrassing but also hilarious reflection on Dutch ‘lunch table culture’. As we have seen several times this year, creative use is made of transparent paper: for example in the title and subtitle, printed in sections on the next and even the following page – so ‘being the other’ is literally consigned to another page. The whole book is printed in the shades of brown you get when you mash up bread with peanut butter and chocolate paste.`}</p>
    <hr></hr>
    <p>{`Publisher: Jan van Eyck Academie, Maastricht`}<br parentName="p"></br>{`
`}{`Concept and design: Chen Jhen, Maastricht`}</p>
    <p>{`Category: Art, photo and architecture books`}<br parentName="p"></br>{`
`}{`Artist: Chen Jhen`}<br parentName="p"></br>{`
`}{`Author: Chen Jhen`}<br parentName="p"></br>{`
`}{`Editor: Wang Huai Yuan, Chen Jhen`}<br parentName="p"></br>{`
`}{`Language: English`}<br parentName="p"></br>{`
`}{`Photographer: Chen Jhen`}<br parentName="p"></br>{`
`}{`Image editor: Chen Jhen`}<br parentName="p"></br>{`
`}{`Lithographer: Chen Jhen, Maastricht; Charles Nypels Lab, Jan van Eyck Academie, Maastricht`}<br parentName="p"></br>{`
`}{`Printer: Charles Nypels Lab, Jan van Eyck Academie, Maastricht`}<br parentName="p"></br>{`
`}{`Binder: Charles Nypels Lab, Jan van Eyck Academie, Maastricht`}<br parentName="p"></br>{`
`}{`Font: Lyon (Commercial Type), Ceremony (Optimo), Akzidenz Grotesk (Berthold Type Foundry)`}<br parentName="p"></br>{`
`}{`Size in mm: 120 x 190 x 8`}<br parentName="p"></br>{`
`}{`Number of pages: 192`}<br parentName="p"></br>{`
`}{`Print run: 100`}<br parentName="p"></br>{`
`}{`Price: € 38`}<br parentName="p"></br>{`
`}{`ISBN: 978 90 72076 98 4`}<br parentName="p"></br>{`
`}{`Paper for interior: 60gsm IBO One (Igepa)`}<br parentName="p"></br>{`
`}{`Cover: 200gsm Fuego® Matt Natural (Papyrus)`}<br parentName="p"></br>{`
`}{`Binding style: Sewn softcover`}<br parentName="p"></br>{`
`}{`Special features: Risography`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      