import React from "react"
import css from "./index.module.scss"
import Layout from "../../../layouts/ja"
import CommonDoc from "../../../components/CommonDoc"

const ExhivitorsEventPage = props => {
  return (
    <Layout location={props.location} title={`EXHIBITORS' EVENTS`}>
      <CommonDoc>
        <section className={`container`}>
          <div className={`content`}>
            <div className={`content__head`}>
              <h1 className={`haeding__lev1`}>EXHIBITORS' EVENTS</h1>
            </div>
            <div className={`content__lead`}>
              <p>
                会期中はVABFの出展者が企画するトークショー、ライブドローイング、スタジオ訪問などさまざまななイベントが開催されます。ぜひ合わせてご覧ください！
              </p>
            </div>

            <article className={css.ex__section}>
              <div className={`sub__head`}>
                <h2 className={`haeding__lev2`}>November 16, 2020 (Monday)</h2>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>11:00 – 19:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://kelechiazu.com/Tokyo-VABF-2020-Emo-Farm`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        「Emo Farm book signing with an emo drawing of you!」
                      </span>
                      @ ケレチ　アズー
                    </a>
                  </dt>
                  <dd>ケレチ　アズー</dd>
                </dl>
                <p className={css.ex__description}>
                  16th -20 attendees can submit an image for me to draw them as
                  an emo kid. This is a part of the book signing process. I
                  would like for exhibitors to email me a screenshot of their
                  emo designs they've generated on my website and along with a
                  photo of them selves to complete the drawing. The emo
                  generator on my website will allow them to look like a
                  character in the book. *I am currently creating this portion
                  on my website.
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>12:00 – JST</p>
                <dl>
                  <dt>
                    <a
                      href={`http://sloweditions.info`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「ジェスジット・ギルのスタジオにて」</span>@ Slow
                      Editions
                    </a>
                  </dt>
                  <dd>ジェスジット・ギル</dd>
                </dl>
                <p className={css.ex__description}>
                  Slow Editionsから新作Dream
                  Doorをリリースするジェスジット・ギルさんのスタジオをご紹介します。11月16日(月)〜23日(月・祝)の間、sloweditions.infoにて公開されます。
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>17:00 – 19:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://twitter.com/stevenfraserart?lang=en`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「Sketch Request」</span>@ Steven Fraser / Do It
                      Theatre
                    </a>
                  </dt>
                  <dd>Steven Fraser</dd>
                </dl>
                <p className={css.ex__description}>
                  私はすべての注文でスケッチをします。個人的なスケッチをリクエストしたい場合は、ツイッターでお知らせください。
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>17:00 – JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.youtube.com/channel/UCv7kr2llIg9mUHMiEsjTnvg/featured`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        「POST/PHOTOの時代がやってきた—来るべき「写真家」と注目すべき「写真集」の動向」
                      </span>
                      @ G/P＋abp
                    </a>
                  </dt>
                  <dd>多和田有希×川島崇志×後藤繁雄</dd>
                </dl>
                <p className={css.ex__description}>
                  2010年代のデジタルトランスフォームによる写真の根底的なシフト。POST/PHOTOというキーワードを中心にコロナに刻印された2020年代の写真の行方を討議する。
                </p>
              </div>
            </article>

            <article className={css.ex__section}>
              <div className={`sub__head`}>
                <h2 className={`haeding__lev2`}>November 17, 2020 (Tuesday)</h2>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>All day</p>
                <dl>
                  <dt>
                    <a
                      href={`http://sloweditions.info`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「ジェスジット・ギルのスタジオにて」</span>@ Slow
                      Editions
                    </a>
                  </dt>
                  <dd>ジェスジット・ギル</dd>
                </dl>
                <p className={css.ex__description}>
                  Slow Editionsから新作Dream
                  Doorをリリースするジェスジット・ギルさんのスタジオをご紹介します。11月16日(月)〜23日(月・祝)の間、sloweditions.infoにて公開されます。
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>11:00 – 19:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://kelechiazu.com/Tokyo-VABF-2020-Emo-Farm`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        「Emo Farm book signing with an emo drawing of you!」
                      </span>
                      @ ケレチ　アズー
                    </a>
                  </dt>
                  <dd>ケレチ　アズー</dd>
                </dl>
                <p className={css.ex__description}>
                  16th -20 attendees can submit an image for me to draw them as
                  an emo kid. This is a part of the book signing process. I
                  would like for exhibitors to email me a screenshot of their
                  emo designs they've generated on my website and along with a
                  photo of them selves to complete the drawing. The emo
                  generator on my website will allow them to look like a
                  character in the book. *I am currently creating this portion
                  on my website.
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>17:00 – 19:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://twitter.com/stevenfraserart?lang=en`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「Sketch Request」</span>@ Steven Fraser / Do It
                      Theatre
                    </a>
                  </dt>
                  <dd>Steven Fraser</dd>
                </dl>
                <p className={css.ex__description}>
                  私はすべての注文でスケッチをします。個人的なスケッチをリクエストしたい場合は、ツイッターでお知らせください。
                </p>
              </div>
            </article>

            <article className={css.ex__section}>
              <div className={`sub__head`}>
                <h2 className={`haeding__lev2`}>
                  November 18, 2020 (Wednesday)
                </h2>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>All day</p>
                <dl>
                  <dt>
                    <a
                      href={`http://sloweditions.info`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「ジェスジット・ギルのスタジオにて」</span>@ Slow
                      Editions
                    </a>
                  </dt>
                  <dd>ジェスジット・ギル</dd>
                </dl>
                <p className={css.ex__description}>
                  Slow Editionsから新作Dream
                  Doorをリリースするジェスジット・ギルさんのスタジオをご紹介します。11月16日(月)〜23日(月・祝)の間、sloweditions.infoにて公開されます。
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>11:00 – 19:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://kelechiazu.com/Tokyo-VABF-2020-Emo-Farm`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        「Emo Farm book signing with an emo drawing of you!」
                      </span>
                      @ ケレチ　アズー
                    </a>
                  </dt>
                  <dd>ケレチ　アズー</dd>
                </dl>
                <p className={css.ex__description}>
                  16th -20 attendees can submit an image for me to draw them as
                  an emo kid. This is a part of the book signing process. I
                  would like for exhibitors to email me a screenshot of their
                  emo designs they've generated on my website and along with a
                  photo of them selves to complete the drawing. The emo
                  generator on my website will allow them to look like a
                  character in the book. *I am currently creating this portion
                  on my website.
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>13:30 – 14:30 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.instagram.com/hnkseminar_kduniversity/`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「LIVE PAINT｛WE LOVE LINES_aadp｝」</span>@ 廣中薫
                    </a>
                  </dt>
                  <dd>HNKzemi (ヒロナカゼミ)　/ 神戸芸術工科大学</dd>
                </dl>
                <p className={css.ex__description}>
                  作品監修：神戸芸術工科大学 HNKzemi
                  (ヒロナカゼミ)チームによるLIVE PAINT
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>17:00 – 19:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://twitter.com/stevenfraserart?lang=en`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「Sketch Request 」</span>@ Steven Fraser / Do It
                      Theatre
                    </a>
                  </dt>
                  <dd>Steven Fraser</dd>
                </dl>
                <p className={css.ex__description}>
                  私はすべての注文でスケッチをします。個人的なスケッチをリクエストしたい場合は、ツイッターでお知らせください。
                </p>
              </div>
            </article>

            <article className={css.ex__section}>
              <div className={`sub__head`}>
                <h2 className={`haeding__lev2`}>
                  November 19, 2020 (Thursday)
                </h2>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>All day</p>
                <dl>
                  <dt>
                    <a
                      href={`http://sloweditions.info`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「ジェスジット・ギルのスタジオにて」</span>@ Slow
                      Editions
                    </a>
                  </dt>
                  <dd>ジェスジット・ギル</dd>
                </dl>
                <p className={css.ex__description}>
                  Slow Editionsから新作Dream
                  Doorをリリースするジェスジット・ギルさんのスタジオをご紹介します。11月16日(月)〜23日(月・祝)の間、sloweditions.infoにて公開されます。
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>06:00 – 07:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://vimeo.com/466374355`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「Edith Heath: Philosophies のメイキング」</span>@
                      Information Office
                    </a>
                  </dt>
                  <dd>
                    Derek Barnett (principal, Information Office), Chris Marino
                    (Curator, Environmental Design Archives, UC Berkeley), Rosa
                    Novak (writer), Jay Stewart (Trustee, Brian and Edith Heath
                    Foundation), Jennifer M. Volland (curator)
                  </dd>
                </dl>
                <p className={css.ex__description}>
                  環境デザインアーカイブの出版物 "Edith Heath. フィロソフィー".
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>11:00 – 19:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://kelechiazu.com/Tokyo-VABF-2020-Emo-Farm`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        「Emo Farm book signing with an emo drawing of you!」
                      </span>
                      @ ケレチ　アズー
                    </a>
                  </dt>
                  <dd>ケレチ　アズー</dd>
                </dl>
                <p className={css.ex__description}>
                  16th -20 attendees can submit an image for me to draw them as
                  an emo kid. This is a part of the book signing process. I
                  would like for exhibitors to email me a screenshot of their
                  emo designs they've generated on my website and along with a
                  photo of them selves to complete the drawing. The emo
                  generator on my website will allow them to look like a
                  character in the book. *I am currently creating this portion
                  on my website.
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>14:30 – 16:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://meet.google.com/iry-iwik-cxi`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「わくわくリソグラフ実演 in 本屋・生活綴方」</span>@
                      MISOGRAPH
                    </a>
                  </dt>
                  <dd>小林大悟</dd>
                </dl>
                <p className={css.ex__description}>
                  本屋・生活綴方さん所蔵のリソグラフ印刷機をお借りしてリソ印刷の様子を生実演。刷り上がりにドキドキしながらみんなでリソを楽しみましょう！
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>17:00 – 19:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://twitter.com/stevenfraserart?lang=en`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「Sketch Request」</span>@ Steven Fraser / Do It
                      Theatre
                    </a>
                  </dt>
                  <dd>Steven Fraser</dd>
                </dl>
                <p className={css.ex__description}>
                  私はすべての注文でスケッチをします。個人的なスケッチをリクエストしたい場合は、ツイッターでお知らせください。
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>21:00 – JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.youtube.com/channel/UCv7kr2llIg9mUHMiEsjTnvg/featured`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「POST/PHOTOトーキング①」</span>@ G/P＋abp
                    </a>
                  </dt>
                  <dd>水木塁×後藤繁雄</dd>
                </dl>
                <p className={css.ex__description}>
                  2010年代のデジタルトランスフォームによる写真の根底的なシフト。POST/PHOTOというキーワードを中心にコロナに刻印された2020年代の写真の行方を討議する。
                </p>
              </div>
            </article>

            <article className={css.ex__section}>
              <div className={`sub__head`}>
                <h2 className={`haeding__lev2`}>November 20, 2020 (Friday)</h2>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>All day</p>
                <dl>
                  <dt>
                    <a
                      href={`http://sloweditions.info`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「ジェスジット・ギルのスタジオにて」</span>@ Slow
                      Editions
                    </a>
                  </dt>
                  <dd>ジェスジット・ギル</dd>
                </dl>
                <p className={css.ex__description}>
                  Slow Editionsから新作Dream
                  Doorをリリースするジェスジット・ギルさんのスタジオをご紹介します。11月16日(月)〜23日(月・祝)の間、sloweditions.infoにて公開されます。
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>11:00 – 19:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://kelechiazu.com/Tokyo-VABF-2020-Emo-Farm`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        「Emo Farm book signing with an emo drawing of you!」
                      </span>
                      @ ケレチ　アズー
                    </a>
                  </dt>
                  <dd>ケレチ　アズー</dd>
                </dl>
                <p className={css.ex__description}>
                  16th -20 attendees can submit an image for me to draw them as
                  an emo kid. This is a part of the book signing process. I
                  would like for exhibitors to email me a screenshot of their
                  emo designs they've generated on my website and along with a
                  photo of them selves to complete the drawing. The emo
                  generator on my website will allow them to look like a
                  character in the book. *I am currently creating this portion
                  on my website.
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>12:30 – 14:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://meet.google.com/nvo-djvv-ssg`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>チエの店番</span>@ #イットガーリー
                    </a>
                  </dt>
                  <dd>チエ</dd>
                </dl>
                <p className={css.ex__description}>
                  Zineのモデル チエさんが店頭に立ちます。何でも言ってね。
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>13:00 – 15:00 JST </p>
                <dl>
                  <dt>
                    <a
                      href={`https://us02web.zoom.us/j/86293523230`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        「くにこ先生と横山先生の役に立たないW人生相談」
                      </span>
                      @ 888ブックス × 長崎訓子＋rn books
                    </a>
                  </dt>
                  <dd>横山裕一＋長崎訓子</dd>
                </dl>
                <p className={css.ex__description}>
                  漫画家/美術家の横山裕一先生とイラストレーターの長崎訓子先生が、ファンのみなさまのお悩みにズバリこたえます。
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>13:00 – 15:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://us05web.zoom.us/j/86761000389?pwd=VTFJK0hkaVJybXRqQU8rRVZKaTl2UT09`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        「山口一郎さんによるポスター色つけライブパフォーマンス」
                      </span>
                      @ BOOKMARUTE
                    </a>
                  </dt>
                  <dd>山口一郎</dd>
                </dl>
                <p className={css.ex__description}>
                  山口一郎さんにお越しいただきポスターの色つけのライブパフォーマンスをしていただきます。ポスターは、当店のオンラインストアにてご購入していただけます。
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>15:00 – 16:30 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://meet.google.com/ity-wekz-fch`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「臨時オンライン彫刻」</span>@
                      社会彫刻の夕べ編集委員会
                    </a>
                  </dt>
                  <dd>社会彫刻の夕べ編集委員会</dd>
                </dl>
                <p className={css.ex__description}>
                  「身体性と距離感、実存とバーチャル」をテーマに話し合います。社会彫刻に興味のある方、バーチャル空間に違和感のある方、お待ちしております。
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>15:00 – 16:00 JST </p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.youtube.com/channel/UC4hrE5AjR_9rQvfl-A5lv1A/videos`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「Daily Report Extras」</span>@ Daily Report
                    </a>
                  </dt>
                  <dd>
                    Teras Belakang Studio, Band Performance: Fleur, Zzaf,
                    Texpack, Dj set The Sastro
                  </dd>
                </dl>
                <p className={css.ex__description}>
                  The Daily Report (visual) collaborates with the Teras Belakang
                  Studio (music) as a form of activation for the 2020 Virtual
                  Art Book Fair (Tokyo Art Book Fair 2020) on November 16 to
                  23.The activation was held in the form of a live stream show
                  with the social media platform Youtube. The content of the
                  live stream show is a product showcase & music performance.The
                  event will be held for 2 days, the day of which lasts 1 hour
                  (conditional).
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>17:00 – 18:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.youtube.com/watch?v=vAdpJ2ywL_U`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>Daily Report Extras</span>@ Daily Report
                    </a>
                  </dt>
                  <dd>Fleur, Zzuf, Texpack, and Karon n Roll.</dd>
                </dl>
                <p className={css.ex__description}>
                  In collaboration with Studio Teras Belakang, Daily Report
                  present Daily Report Extras. This is a virtual show run
                  exclusively for the 2020 edition of Tokyo Art Book Fair. This
                  show is streamed and will be archived on Daily Report Extras
                  YouTube channel.
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>17:00 – 19:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://twitter.com/stevenfraserart?lang=en`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「Sketch Request 」</span>@ Steven Fraser / Do It
                      Theatre
                    </a>
                  </dt>
                  <dd>Steven Fraser</dd>
                </dl>
                <p className={css.ex__description}>
                  私はすべての注文でスケッチをします。個人的なスケッチをリクエストしたい場合は、ツイッターでお知らせください。
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>18:00 – 19:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.youtube.com/channel/UCGLCdJbLVbzZXJ2hpjSX2nQ`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        「プリンティングディレクター高栁昇のCMYK
                        Photoshopチュートリアル」
                      </span>
                      @ 株式会社　東京印書館
                    </a>
                  </dt>
                  <dd>
                    高栁昇/山口雅彦/下中周介 (TOKYO INSHOKAN PRINTING CO.,LTD.)
                  </dd>
                </dl>
                <p className={css.ex__description}>
                  東京印書館プリンティングディレクター高栁昇によるオンラインPhotoshop講座。写真印刷で光を効果的に表現するためのポイントをYouTubeライブで徹底公開！
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>18:00 – 19:30 JST </p>
                <dl>
                  <dt>
                    <a
                      href={`https://youtu.be/xWncBcEBL2E`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        「『消去の詩学と政治 』 - ユン•リー 出版物の発売」
                      </span>
                      @ oneacre.online
                    </a>
                  </dt>
                  <dd>ユン・リ oneacre.online</dd>
                </dl>
                <p className={css.ex__description}>
                  oneacre.online is pleased to invite you to the publication
                  launch of Yun Lee— Poetics and Politics of Erasure, please
                  join us on Friday 20 November between 10:00 and 11:30 at the
                  following link.〈PROGRAM〉Introduction by oneacre.online /
                  Reading by Yun Lee / Panel Discussion
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>18:30 – 19:30 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.instagram.com/kaoruhironaka/`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「LIVE PAINT｛WE LOVE LINES_aadp｝」</span>@ 廣中薫
                    </a>
                  </dt>
                  <dd>アート&デザイン プロジェクト / 神戸芸術工科大学</dd>
                </dl>
                <p className={css.ex__description}>
                  作品監修：神戸芸術工科大学の学科横断(イメージ基礎)チームによる「LINE遊び」=
                  LINES _LIVE PAINT
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>19:00 – 21:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.facebook.com/Reminders.Photography.Stronghold`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        「PHOTOBOOK AS OBJECT / PHOTOBOOK WHO CARES AT VIRTUAL
                        ART BOOK FAIR」 出展作家と写真集を読む　
                      </span>
                      @ PHOTOBOOK AS OBJECT PHOTOBOOK WHO CARES
                    </a>
                  </dt>
                  <dd>
                    上田順平、エレナ・コルキナ、王露、岡本裕志、木村肇、楠本涼、鈴木萌、髙木佑輔、千賀健史、地蔵ゆかり、野村幹太、藤井ヨシカツ、マラ・カタラン、ルイス・リム
                  </dd>
                </dl>
                <p className={css.ex__description}>
                  参加作家による写真集のプレゼンテーションを聴きながら、写真集を手に取ってご覧いただきます。Facebook
                  Liveでも配信予定。
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>20:00 - JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://instagram.com/pgi_gallery/`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        「川田喜久治「20」M Label by bookshop M
                        「作品を写真集にすること」」
                      </span>
                      @ PGI
                    </a>
                  </dt>
                  <dd>町口景 x 川田洋平 x 高橋朗</dd>
                </dl>
                <p className={css.ex__description}>
                  2021年初春にM
                  Labelで発刊予定の川田喜久治「20」。写真集にする作業が今まさに始まったばかり本について、作品を写真集にする作業、作り手の頭の中を探ります。出版記念トークは数あれど、未出版についての本についてのトーク。bookshop
                  Mの浅草支部からお届けします。どうぞお楽しみに！
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>20:00 – 21:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://us04web.zoom.us/j/77311395151?pwd=KzNiYnZENnZoclVOU3ZvMElTV2RIdz09`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「一緒にお酒を飲みましょう！ イベント」</span>@
                      yoranhan2people
                    </a>
                  </dt>
                  <dd>Jaeyi Park , Sim Haeji </dd>
                </dl>
                <p className={css.ex__description}>
                  一緒にお酒を飲みながら本について知りたいことや本の紹介をよりフレンドリーにする目的のイベント
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>21:00 – 22:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.instagram.com/remote_collage/?hl=ja`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「real time session」</span>@ remote collage
                    </a>
                  </dt>
                  <dd>木村里奈/中村彩梨/根岸桃子</dd>
                </dl>
                <p className={css.ex__description}>
                  remote
                  collage公式インスタグラムにて、リアルタイムでコラージュする様子をライブ配信します。
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>Irregular hour</p>
                <dl>
                  <dt>
                    <a
                      href={`https://meet.google.com/fde-hnxy-zuz?pli=1&authuser=1`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        「写真集『SCULPTURE in the
                        CITY』の解説・その他出展中の作品解説・彫刻の公開制作」
                      </span>
                      @ 山口正樹 / Photographic Works
                    </a>
                  </dt>
                  <dd>山口正樹（本人）</dd>
                </dl>
                <p className={css.ex__description}>
                  Google Meetにて、期間中毎日配信します。写真集「SCULPTURE in
                  the CITY」の解説・その他出展中の作品解説・彫刻の制作などなど。
                </p>
              </div>
            </article>

            <article className={css.ex__section}>
              <div className={`sub__head`}>
                <h2 className={`haeding__lev2`}>
                  November 21, 2020 (Saturday)
                </h2>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>All day</p>
                <dl>
                  <dt>
                    <a
                      href={`http://sloweditions.info`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「ジェスジット・ギルのスタジオにて」</span>@ Slow
                      Editions
                    </a>
                  </dt>
                  <dd>ジェスジット・ギル</dd>
                </dl>
                <p className={css.ex__description}>
                  Slow Editionsから新作Dream
                  Doorをリリースするジェスジット・ギルさんのスタジオをご紹介します。11月16日(月)〜23日(月・祝)の間、sloweditions.infoにて公開されます。
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>10:00 – 11:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.instagram.com/sloweditions/`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        「アーティストブックの時間、ページをめくろう」
                      </span>
                      @ Slow Editions
                    </a>
                  </dt>
                  <dd>ユニス・ルック</dd>
                </dl>
                <p className={css.ex__description}>
                  ユニス・ルックが、お気に入りのアーティストブックをご紹介します。一緒にページをめくりましょう。11月21日(土)午前10時(日本時間)より1時間。インスタグラムライブ(@sloweditions)にて。
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>11:00 – JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.instagram.com/klassic_kool_shoppe/`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        「アリシアのクラシック・クール・ショップでは、カール・ディドゥールの音楽パフォーマンスをお楽しみいただけます。」
                      </span>
                      @ Alicia's Klassic Kool Shoppe
                    </a>
                  </dt>
                  <dd>Carl Didur</dd>
                </dl>
                <p className={css.ex__description}>
                  カール・ディドゥール（Zacht
                  Automaat）は、エレクトリック・ピアノの穏やかなアルペジオで種族間のギャップを越えようとしている。
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>11:00 – 13:00 JST </p>
                <dl>
                  <dt>
                    <a
                      href={`https://montgomerycollege.zoom.us/j/3226439703?pwd=MnFqMzd3N3FDbS9GZ1RHYTFvTTBUUT09`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>著者の読み</span>@ Sybil Press
                    </a>
                  </dt>
                  <dd>
                    Jillian Gomez, Norberto Gomez, Sara Goodman, and Carrie
                    Rennolds
                  </dd>
                </dl>
                <p className={css.ex__description}>
                  Sybil
                  Pressの共同創設者や特別ゲストに参加して、ライブリーディングを行ってください。マイクは、私たちと一緒に資料を読みたい人にも開放されます。
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>11:00 – 12:00 JST </p>
                <dl>
                  <dt>
                    <a
                      href={`https://meet.google.com/zpy-wtxq-fcb`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「アートブックトークショー第一」</span>@ Art Byte
                      Critique
                    </a>
                  </dt>
                  <dd>Deanna Gabiga, Yuko Kamei, Janette Maxey and Lori Ono</dd>
                </dl>
                <p className={css.ex__description}>
                  These four Art Byte Critique art book creators will have a
                  roundtable discussion and answer questions regarding their art
                  book making processes and participation in the Tokyo Art Book
                  Fair.
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>12:00 – JST </p>
                <dl>
                  <dt>
                    <a
                      href={`https://instagram.com/pgi_gallery/`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「写真集について」</span>@ PGI
                    </a>
                  </dt>
                  <dd>濱田祐史 x 高橋朗</dd>
                </dl>
                <p className={css.ex__description}>
                  これまで出版して来た写真集について作家本人にお話を伺います。特に2021年初春にミュゼ浜口陽三・ヤマサコレクションにて展示予定のC/M/Yについて。作品コンセプトを本に再構築した写真集の魅力を改めて解説。
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>12:00 – 13:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.instagram.com/remote_collage/?hl=ja`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「real time session」</span>@ remote collage
                    </a>
                  </dt>
                  <dd>木村里奈/中村彩梨/根岸桃子</dd>
                </dl>
                <p className={css.ex__description}>
                  remote
                  collage公式インスタグラムにて、リアルタイムでコラージュする様子をライブ配信します。
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>13:00 – 15:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://us05web.zoom.us/j/89541792252?pwd=Y1RIOE94TkdtVXlpUWlGTUMrdWFjQT09`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        「山口一郎さんによるポスター色つけライブパフォーマンス」
                      </span>
                      @ BOOKMARUTE
                    </a>
                  </dt>
                  <dd>山口一郎</dd>
                </dl>
                <p className={css.ex__description}>
                  山口一郎さんにお越しいただきポスターの色つけのライブパフォーマンスをしていただきます。ポスターは、当店のオンラインストアにてご購入していただけます。
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>14:00 – JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.instagram.com/shortbangs`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「ライブドローイング」</span>@ Yuki MIKAMI
                    </a>
                  </dt>
                  <dd>Yuki MIKAMI</dd>
                </dl>
                <p className={css.ex__description}>
                  ライブドローイングをします。数分で終了予定です。お見逃しなく！
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>14:00 – 15:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://meet.google.com/vjn-pvzs-ecp`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「EMOJI 日本昔ばなし」 読み聞かせ</span>@
                      オラダノブックス YAMAGATA
                    </a>
                  </dt>
                  <dd>五月女 仁、五月女 友里衣</dd>
                </dl>
                <p className={css.ex__description}>
                  日本に古くから伝わる民話・伝承を、スマートホンに搭載されている「EMOJI(絵文字)」のみで綴った本「絵文字
                  日本昔ばなし」に収録されている物語の中から「桃太郎」を始めとする数編をピックアップし、オンラインミーティングアプリの画面共有機能を用いてリアルタイムに読み聞かせします。
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>15:00 – 17:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.youtube.com/channel/UCGLCdJbLVbzZXJ2hpjSX2nQ`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        「プリンティングディレクター高栁昇の写真集レビュー /
                        ライブQ&A」
                      </span>
                      @ 株式会社　東京印書館
                    </a>
                  </dt>
                  <dd>
                    高栁昇 / 奥村博 / 下中周介 (TOKYO INSHOKAN PRINTING
                    CO.,LTD.)
                  </dd>
                </dl>
                <p className={css.ex__description}>
                  PD高栁が印刷・製版を手がけた写真集を製版者の視点でレビュー！後半は編集者、デザイナー、写真家の方々より普段頂く様々な質問にお答えします。
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>16:00 – 18:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.instagram.com/kaoruhironaka/`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「TOWER- DRAWING」</span>@ 廣中薫
                    </a>
                  </dt>
                  <dd>廣中薫 & kobe Artists</dd>
                </dl>
                <p className={css.ex__description}>
                  現在個展開催中、神戸ポートタワー展望2F(地上79m)
                  にて、風景x光と絡めた線を引いていきます。この日はポートタワーの57歳の誕生日記念日でもあります。
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>18:00 – 18:30 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.instagram.com/yuichiro_higashiji_form`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「ブックカッティング for Mountain」</span>@ crevasse
                      + Miriam Gallery
                    </a>
                  </dt>
                  <dd>東地雄一郎　</dd>
                </dl>
                <p className={css.ex__description}>
                  みなさんがよく知っている_あの山(Mountain)_を切るパフォーマンスをします。あなたは、山の断面をみることができます。
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>18:00 – 18:30 JST </p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.instagram.com/blankt_papir/`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        「Talk with artist Janemaria Mekoline Pedersen about her
                        book “Dysfertil"」
                      </span>
                      @ Blankt Papir Press
                    </a>
                  </dt>
                  <dd>
                    ユリア・メヒナーセンとヤネマリア・メコリン・ペデルセン
                  </dd>
                </dl>
                <p className={css.ex__description}>
                  Janemaria Mekoline
                  Pedersenが、彼女の新しく出版された写真アーティストの本「Dysfertil」について語ります。
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>20:00 – JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.youtube.com/channel/UCv7kr2llIg9mUHMiEsjTnvg/featured`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「POST/PHOTOトーキング②」</span>@ G/P＋abp
                    </a>
                  </dt>
                  <dd>
                    梅沢英樹×後藤繁雄、羽地優太郎×後藤繁雄、児嶋啓多×後藤繁雄
                  </dd>
                </dl>
                <p className={css.ex__description}>
                  2010年代のデジタルトランスフォームによる写真の根底的なシフト。POST/PHOTOというキーワードを中心にコロナに刻印された2020年代の写真の行方を討議する。
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>Irregular</p>
                <dl>
                  <dt>
                    <a
                      href={`https://meet.google.com/fde-hnxy-zuz?pli=1&authuser=1`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        「写真集『SCULPTURE in the
                        CITY』の解説・その他出展中の作品解説・彫刻の公開制作」
                      </span>
                      @ 山口正樹 / Photographic Works
                    </a>
                  </dt>
                  <dd>山口正樹（本人）</dd>
                </dl>
                <p className={css.ex__description}>
                  Google Meetにて、期間中毎日配信します。写真集「SCULPTURE in
                  the CITY」の解説・その他出展中の作品解説・彫刻の制作などなど。
                </p>
              </div>
            </article>

            <article className={css.ex__section}>
              <div className={`sub__head`}>
                <h2 className={`haeding__lev2`}>November 22, 2020 (Sunday)</h2>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>All day</p>
                <dl>
                  <dt>
                    <a
                      href={`http://sloweditions.info`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「ジェスジット・ギルのスタジオにて」</span>@ Slow
                      Editions
                    </a>
                  </dt>
                  <dd>ジェスジット・ギル</dd>
                </dl>
                <p className={css.ex__description}>
                  Slow Editionsから新作Dream
                  Doorをリリースするジェスジット・ギルさんのスタジオをご紹介します。11月16日(月)〜23日(月・祝)の間、sloweditions.infoにて公開されます。
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>11:00 – 13:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://montgomerycollege.zoom.us/j/3226439703?pwd=MnFqMzd3N3FDbS9GZ1RHYTFvTTBUUT09`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>著者の読み</span>@ Sybil Press
                    </a>
                  </dt>
                  <dd>
                    Jillian Gomez, Norberto Gomez, Sara Goodman, and Carrie
                    Rennolds
                  </dd>
                </dl>
                <p className={css.ex__description}>
                  Sybil
                  Pressの共同創設者や特別ゲストに参加して、ライブリーディングを行ってください。マイクは、私たちと一緒に資料を読みたい人にも開放されます。
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>13:00 – 14:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://us02web.zoom.us/j/83683246233`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        「マンガとエッセイ、フィクションとノンフィクション」
                      </span>
                      @ 長崎訓子＋rn books
                    </a>
                  </dt>
                  <dd>岡藤真依、野口理恵（rn press）</dd>
                </dl>
                <p className={css.ex__description}>
                  「USO2」に漫画を寄稿している岡藤真依さんと、エッセイ（漫画）を描くことについてお話しします。
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>13:00 – 19:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://zoom.us/j/8962850108?pwd=MjljUmkzWW5mMXYyY0tPTUM5YytQUT09`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        「 "宇宙人似顔絵" （仲介人：堀 道広）※要予約」
                      </span>
                      @ ユトレヒト
                    </a>
                  </dt>
                  <dd>堀 道広</dd>
                </dl>
                <p className={css.ex__description}>
                  仮想世界からあなたの似顔絵がハガキで届きます。11/17より
                  <a
                    href={`https://utrecht.jp`}
                    target={`_blank`}
                    className={`link__underline link__blank`}
                  >
                    ユトレヒトサイト
                  </a>
                  で受付
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>13:00 – 14:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.publ-site.org/laughter/content/video/`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「ページタウン・ライブコラージュ」</span>@ Yasushi
                      Cho
                    </a>
                  </dt>
                  <dd>Yasushi Cho</dd>
                </dl>
                <p className={css.ex__description}>
                  ページタウンは Yasushi Cho
                  が継続的に制作しているコラージュ作品です。日々ポスティングされる広告チラシのマップから架空のタウンマップをコラージュします。
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>13:00 – 16:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://zoom.us/j/3972530638?pwd=T1FxRXl3TjRJMzVvRUNmeFAzeEhWQT09`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「シルクスクリーン・クリニック」</span>@ NEW
                      POSITIONS patterned papers
                    </a>
                  </dt>
                  <dd>
                    久利屋グラフィック　松村　宏・田中智美、NEW POSITIONS
                    patterned papers　ウエハラサチコ
                  </dd>
                </dl>
                <p className={css.ex__description}>
                  ’80年以来、田中一光など数多くのアーティストの作品を手がけてきたプロの刷り師・松村宏さんをお招きしてシルクスクリーンなんでも相談をやります。
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>14:00 – JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.instagram.com/shortbangs`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「ライブドローイング」</span>@ Yuki MIKAMI
                    </a>
                  </dt>
                  <dd>Yuki MIKAMI</dd>
                </dl>
                <p className={css.ex__description}>
                  ライブドローイングをします。数分で終了予定です。お見逃しなく！
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>15:00 – 16:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.youtube.com/channel/UC4hrE5AjR_9rQvfl-A5lv1A/videos`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「Daily Report Extras」</span>@ Daily Report
                    </a>
                  </dt>
                  <dd>Fleur, Zzuf, Texpack, and Karon n Roll</dd>
                </dl>
                <p className={css.ex__description}>
                  In collaboration with Studio Teras Belakang, Daily Report
                  present Daily Report Extras. This is a virtual show run
                  exclusively for the 2020 edition of Tokyo Art Book Fair. This
                  show is streamed and will be archived on Daily Report Extras
                  YouTube channel.
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>16:00 – 17:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://us02web.zoom.us/j/83391328872?pwd=YzNZeW5XYmlyNk85OHdTZDlFaWJnQT09`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「プラープダー・ユン ミニ・トーク」</span>@ SOI
                      BOOKS
                    </a>
                  </dt>
                  <dd>プラープダー・ユン、福冨渉（通訳）</dd>
                </dl>
                <p className={css.ex__description}>
                  タイの現代作家プラープダー・ユンのミニ・トーク。今回発表される最新短編、制作中のドラマ、最新タイカルチャーまで、楽しく話してもらいます。
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>17:00 – 18:00 JST </p>
                <dl>
                  <dt>
                    <a
                      href={`https://meet.google.com/xfx-ihay-xdr`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「アートブックトークショー第二」</span>@ Art Byte
                      Critique
                    </a>
                  </dt>
                  <dd>Arthur Huang, Eva Li, Misako Oba, Nick West</dd>
                </dl>
                <p className={css.ex__description}>
                  These four Art Byte Critique art book creators will have a
                  roundtable discussion and answer questions regarding their art
                  book making processes and participation in the Tokyo Art Book
                  Fair.
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>17:30 - 18:30 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://us02web.zoom.us/j/83391328872?pwd=YzNZeW5XYmlyNk85OHdTZDlFaWJnQT09`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「モモコのタイクッキング in 三崎港」</span>@ SOI
                      BOOKS
                    </a>
                  </dt>
                  <dd>
                    <a
                      href={`https://instagram.com/momokothaicookmortartokyo`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      植田桃子（タイ料理研究家）
                    </a>
                  </dd>
                </dl>
                <p className={css.ex__description}>
                  タイ在住経験8年の料理研究家モモコによる調理実演とトーク。神奈川県県三浦半島の三崎港でその日に仕入れた材料で即興タイ料理を作って食べます。
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>19:00 – JST</p>
                <dl>
                  <dt>
                    <a
                      href={`http://www.emon-arc.com/`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        「飽くなき探究心を抱く写真家・作家にフォーカスするキュレーションメディア。」
                      </span>
                    </a>
                    @ Kiiro
                  </dt>
                  <dd>Kiiro, EMON PHOTO GALLERY</dd>
                </dl>
                <p className={css.ex__description}>
                  オンラインを使った映像ドキュメンタリー『EMON
                  Archives』は、記録メディアを使って挑戦を続ける作家Kiiroにフォーカス。
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>20:30 – 21:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://us02web.zoom.us/j/83391328872?pwd=YzNZeW5XYmlyNk85OHdTZDlFaWJnQT09`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「モモコモーションミニライブとトーク 」</span>@ SOI
                      BOOKS
                    </a>
                  </dt>
                  <dd>
                    <a
                      href={`https://instagram.com/momokothaicookmortartokyo`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      モモコモーション（シンガーソングライター）
                    </a>
                  </dd>
                </dl>
                <p className={css.ex__description}>
                  モモコモーションがタイで制作したアルバム「Punk in a
                  Coma」の中から数曲をうたいます。制作時の思い出なども交えたトークも。
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>22:00 – JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.instagram.com/sloweditions/`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「アキツユコの空間 」</span>@ Slow Editions
                    </a>
                  </dt>
                  <dd>アキツユコ</dd>
                </dl>
                <p className={css.ex__description}>
                  Slow
                  Editionsは、敬愛する音楽家アキツユコさんの演奏を配信します。11月22日(日)22時(日本時間)、インスタグラムのIG
                  TV (@sloweditions)にて。
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>24:00 – JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.instagram.com/kaoruhironaka/`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>N I W 制作ムービー 発信</span>@ 廣中薫
                    </a>
                  </dt>
                  <dd>
                    コラボレーションbooks (デザイナーUshiyamさん X HNK )チーム
                  </dd>
                </dl>
                <p className={css.ex__description}>
                  「 N I W」：デザイナーUshiyam と
                  HNKによるコラボレーション・チームです。Bricolage zine 制作
                  (working in progress) を ONAIR 公開。出来上がったzine は
                  、最終日11/23に本棚へ置きます。
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>Irregular </p>
                <dl>
                  <dt>
                    <a
                      href={`https://meet.google.com/fde-hnxy-zuz?pli=1&authuser=1`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        「写真集『SCULPTURE in the
                        CITY』の解説・その他出展中の作品解説・彫刻の公開制作」
                      </span>
                      @ 山口正樹 / Photographic Works
                    </a>
                  </dt>
                  <dd>山口正樹</dd>
                </dl>
                <p className={css.ex__description}>
                  Google Meetにて、期間中毎日配信します。写真集「SCULPTURE in
                  the CITY」の解説・その他出展中の作品解説・彫刻の制作などなど。
                </p>
              </div>
            </article>

            <article className={css.ex__section}>
              <div className={`sub__head`}>
                <h2 className={`haeding__lev2`}>
                  November 23, 2020 (Monday / Holiday)
                </h2>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>All day</p>
                <dl>
                  <dt>
                    <a
                      href={`http://sloweditions.info`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「ジェスジット・ギルのスタジオにて」</span>@ Slow
                      Editions
                    </a>
                  </dt>
                  <dd>ジェスジット・ギル</dd>
                </dl>
                <p className={css.ex__description}>
                  Slow Editionsから新作Dream
                  Doorをリリースするジェスジット・ギルさんのスタジオをご紹介します。11月16日(月)〜23日(月・祝)の間、sloweditions.infoにて公開されます。
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>12:00 – JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://instagram.com/pgi_gallery/`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「新刊写真集『糸をつむぐ』について」</span>@ PGI
                    </a>
                  </dt>
                  <dd>高橋宗正 x 高橋朗</dd>
                </dl>
                <p className={css.ex__description}>
                  11月1日に出版された新刊「糸をつむぐ」についてお話を伺います。出版レーベルVEROを立ち上げ、デザイナーと二人三脚で本を作る高橋宗正さん。本の内容についてはもとより、自費出版のこともお聞きします。
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>13:00 – JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://us02web.zoom.us/j/88994714604`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「イラストレーションと発注」</span>@ 長崎訓子＋rn
                      books
                    </a>
                  </dt>
                  <dd>
                    若林恵（黒鳥社）、長崎訓子 / 司会：野口理恵（rn press）
                  </dd>
                </dl>
                <p className={css.ex__description}>
                  編集者である若林恵さんをゲストに雑誌や書籍のイラストレーションの発注事情についてお話しします。
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>13:00 – 14:30 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://us05web.zoom.us/j/81354106088?pwd=STRoRVVhSHRraVBobmhEbWEyazA0QT09`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        「TALK EVENT: Fossa Magna- Their landscapes /
                        フォッサマグナ- 彼らのランドスケープ@VABF」
                      </span>
                      @ assignments
                    </a>
                  </dt>
                  <dd>
                    竹之内耕 / 地質学・博士（理学）、四方幸子 / キュレーティング
                    / 批評、アバロス村野敦子 / 写真家
                  </dd>
                </dl>
                <p className={css.ex__description}>
                  2020年3月POST で開催されたアバロス村野敦子「Fossa
                  Magna-彼らの露頭と堆積」展示期間に予定、感染症流行の影響で延期されていたトークイベント。
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>13:00 – 16:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://zoom.us/j/3972530638?pwd=T1FxRXl3TjRJMzVvRUNmeFAzeEhWQT09`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「シルクスクリーン・クリニック」</span>@ NEW
                      POSITIONS patterned papers
                    </a>
                  </dt>
                  <dd>
                    久利屋グラフィック　松村　宏・田中智美、NEW POSITIONS
                    patterned papers　ウエハラサチコ
                  </dd>
                </dl>
                <p className={css.ex__description}>
                  ’80年以来、田中一光など数多くのアーティストの作品を手がけてきたプロの刷り師・松村宏さんをお招きしてシルクスクリーンなんでも相談をやります。
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>14:00 – 16:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://youtu.be/zyav1omwosw`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「オンラインプレゼンテーション」</span>@ 21th
                      Century home works :MeMe Design School
                    </a>
                  </dt>
                  <dd>
                    児玉明久、塚田悠太、中尾さや、本多亜実、三橋雅子、三好誠、横尾徹也
                  </dd>
                </dl>
                <p className={css.ex__description}>
                  参加者によるプレゼンテーション。制作に至る契機、コンセプト、アプローチなど発表を行います。ミームデザイン学校代表
                  中垣信夫校長にも参加いただく予定です。
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>15:00 – 16:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.instagram.com/remote_collage/?hl=ja`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>「real time session」</span>@ remote collage
                    </a>
                  </dt>
                  <dd>木村里奈 / 中村彩梨 / 根岸桃子</dd>
                </dl>
                <p className={css.ex__description}>
                  remote
                  collage公式インスタグラムにて、リアルタイムでコラージュする様子をライブ配信します。
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>Irregular</p>
                <dl>
                  <dt>
                    <a
                      href={`https://meet.google.com/fde-hnxy-zuz?pli=1&authuser=1`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        「写真集『SCULPTURE in the
                        CITY』の解説・その他出展中の作品解説・彫刻の公開制作」
                      </span>
                      @ 山口正樹 / Photographic Works
                    </a>
                  </dt>
                  <dd>山口正樹（本人）</dd>
                </dl>
                <p className={css.ex__description}>
                  Google Meetにて、期間中毎日配信します。写真集「SCULPTURE in
                  the CITY」の解説・その他出展中の作品解説・彫刻の制作などなど。
                </p>
              </div>
            </article>
          </div>
          {/* /.content */}
        </section>
        {/* /.container */}
      </CommonDoc>
    </Layout>
  )
}

export default ExhivitorsEventPage
