import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/VabfEvents.en.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SocialShare = makeShortcode("SocialShare");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className="youtube__wrap">
  <iframe width="560" height="315" src="https://www.youtube.com/embed/J8bQ8ZgWP9o?controls=0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
    <div className="share__2col">
  <div className="share__2col__link">
    <a href="https://youtu.be/J8bQ8ZgWP9o" target="_blank">
      Youtube Live をみる
    </a>
  </div>
  <div className="share__2col__icon">
    <SocialShare title={props.pageContext.frontmatter.title} mdxType="SocialShare" />
  </div>
    </div>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "980px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "52.6530612244898%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwQA/8QAFgEBAQEAAAAAAAAAAAAAAAAAAwEC/9oADAMBAAIQAxAAAAGydxw9mTQ//8QAGxAAAgIDAQAAAAAAAAAAAAAAAQIDEQATIjL/2gAIAQEAAQUCl5VD1eTGgjHann//xAAWEQADAAAAAAAAAAAAAAAAAAACEDL/2gAIAQMBAT8BGV//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPwFn/8QAHRAAAQMFAQAAAAAAAAAAAAAAAQACEBESISMkYf/aAAgBAQAGPwIbSxDou8WXUhsf/8QAGxABAAIDAQEAAAAAAAAAAAAAAQAxESFBUaH/2gAIAQEAAT8hKixbvMV+UuAejxYgYexgLbAYan//2gAMAwEAAgADAAAAEJD/AP/EABgRAQADAQAAAAAAAAAAAAAAAAEAEdHw/9oACAEDAQE/EDysi0z/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/EB//xAAbEAEAAgMBAQAAAAAAAAAAAAABABEhMUGBsf/aAAgBAQABPxAgWALuhxCZqvoaagV+4JbJpvPGXaBLwZxHMjv2f//Z')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/2dd838002b148778fa972ea3fd07b719/1a3b7/img_1.webp 245w", "/static/2dd838002b148778fa972ea3fd07b719/fb835/img_1.webp 490w", "/static/2dd838002b148778fa972ea3fd07b719/096d3/img_1.webp 980w", "/static/2dd838002b148778fa972ea3fd07b719/71b6b/img_1.webp 1200w"],
          "sizes": "(max-width: 980px) 100vw, 980px",
          "type": "image/webp"
        }}></source>{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/2dd838002b148778fa972ea3fd07b719/c168b/img_1.jpg 245w", "/static/2dd838002b148778fa972ea3fd07b719/bb66a/img_1.jpg 490w", "/static/2dd838002b148778fa972ea3fd07b719/2f919/img_1.jpg 980w", "/static/2dd838002b148778fa972ea3fd07b719/2e17a/img_1.jpg 1200w"],
          "sizes": "(max-width: 980px) 100vw, 980px",
          "type": "image/jpeg"
        }}></source>{`
        `}<img parentName="picture" {...{
          "className": "gatsby-resp-image-image",
          "src": "/static/2dd838002b148778fa972ea3fd07b719/2f919/img_1.jpg",
          "alt": "img 1",
          "title": "img 1",
          "loading": "lazy",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          }
        }}></img>{`
      `}</picture>{`
    `}</span>
    <p>{`VIRTUAL ART BOOK FAIR（VABF）会期中、東京都現代美術館で開催している「石岡瑛子　血が、汗が、涙がデザインできるか」展に関連して、本展にあわせて刊行される評伝『TIMELESS 　石岡瑛子とその時代』(朝日新聞出版)著者の河尻亨一さん（元・広告批評編集長）と、本展担当学芸員の藪前知子によるトークセッションを開催します。石岡瑛子がデザインや企画構成を手掛けた本についてや、小学館から刊行される展覧会図録についても触れつつ、石岡瑛子を語りつくします。トークセッションの様子は当日、VIRTUAL ART BOOK FAIR のサイトからライブ配信でも同時にご覧頂けます。また後日、アーカイブも公開致します。`}</p>
    <p className="font__small">
  日時：2020年11月20日（金）14:00-15:30（開場13:45-）
  <br />
  場所：東京都現代美術館　講堂（地下2F） 参加費：無料（先着100名）
  <br />
  トークセッションはライブ配信でもご覧頂けます。後日、アーカイブも公開致します。
  <br />
  ライブ配信URL： 登壇者：河尻亨一（編集者）、藪前知子（東京都現代美術館）
  <br />
  ※開催内容は、都合により変更になる場合がございます。予めご了承ください。
    </p>
    <div {...{
      "className": "content__info"
    }}>{`
  `}<figure parentName="div" {...{
        "className": "content__info__img"
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "184px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAQADAQAAAAAAAAAAAAAAAAUBAwQG/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgMB/9oADAMBAAIQAxAAAAHiqx++boMBvmthYUQF/8QAGxAAAgIDAQAAAAAAAAAAAAAAAQIAEQMSMTL/2gAIAQEAAQUCLOcuPzEtmR9Hg7Z3Fz//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAVEQEBAAAAAAAAAAAAAAAAAAABIP/aAAgBAgEBPwFj/8QAHhAAAgEDBQAAAAAAAAAAAAAAAAEQETEyAhIhUZH/2gAIAQEABj8CyaFWL8mxuvU3MtXp/8QAHBABAAIDAAMAAAAAAAAAAAAAAQAhETFREEFh/9oACAEBAAE/IUAAH1EuRl74NCzsYbzbnyDEmRUyO29wok//2gAMAwEAAgADAAAAEMc/PP/EABYRAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPxASIL//xAAXEQADAQAAAAAAAAAAAAAAAAAAARAR/9oACAECAQE/EEMHP//EABwQAQADAAIDAAAAAAAAAAAAAAEAESFBYTFxgf/aAAgBAQABPxBxzAOAdReKnQVfyXCqOysBGrsCnsgpLmKOSVJRoa2vcSL5I//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/26db1819a598c3523f5c17bcc869146d/cc1c3/img_2.webp 184w"],
              "sizes": "(max-width: 184px) 100vw, 184px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/26db1819a598c3523f5c17bcc869146d/b9938/img_2.jpg 184w"],
              "sizes": "(max-width: 184px) 100vw, 184px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/26db1819a598c3523f5c17bcc869146d/b9938/img_2.jpg",
              "alt": "img 2",
              "title": "img 2",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
  `}</figure>{`
  `}<div parentName="div" {...{
        "className": "content__info__title"
      }}>{`
    `}<p parentName="div">{`
      河尻亨一 `}<br parentName="p" {...{
            "className": "only-sp"
          }}></br>{`
      `}<span parentName="p" {...{
            "className": "font__xs"
          }}>{`(かわじり こういち)`}</span>{`
    `}</p>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "content__info__text"
      }}>{`
    `}<p parentName="div">{`
      編集者。1974
      年大阪市生まれ。早稲田大学政治経済学部卒。美大予備校講師をへてマドラ出版入社。雑誌「広告批評」在籍中には、広告を中心に多様なカルチャー領域とメディア、社会事象を横断する数々の特集を手がけ、国内外の多くのクリエイター、企業のキーパーソンにインタビューを行う。現在は取材・執筆からイベント、企業コンテンツの企画制作ほか、広告とジャーナリズムをつなぐ活動を展開。カンヌ国際クリエイティビティフェスティバルを取材するなど、海外の動向にも詳しい。訳書に『CREATIVE
      SUPERPOWERS』がある。
    `}</p>{`
  `}</div>
    </div>
    <div {...{
      "className": "content__info"
    }}>{`
  `}<figure parentName="div" {...{
        "className": "content__info__img"
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "184px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "101.08695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMFBP/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAAB7EJZu+k1MjSCQP/EABsQAAIDAQEBAAAAAAAAAAAAAAECAwQSIQA0/9oACAEBAAEFArDZHd+vsAj7VI3EiSjV6TqUvl//xAAVEQEBAAAAAAAAAAAAAAAAAAAgQf/aAAgBAwEBPwGD/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAHxAAAQMDBQAAAAAAAAAAAAAAAQACEQMxURAhQnGh/9oACAEBAAY/AmxtJhBpM40aOV1RqnxBwsUwHCd0mL//xAAdEAEBAQACAgMAAAAAAAAAAAABEQAxQRCxIWGh/9oACAEBAAE/IZpxxjc4ha3n46fFQyonWomg1yof2YgNOmANBBP5vY97/9oADAMBAAIAAwAAABCswDz/xAAWEQEBAQAAAAAAAAAAAAAAAAARASD/2gAIAQMBAT8QgnH/xAAWEQEBAQAAAAAAAAAAAAAAAAARASD/2gAIAQIBAT8Qq4//xAAhEAEAAgEDBAMAAAAAAAAAAAABABEhMUFREHGRobHB0f/aAAgBAQABPxBXYLcc2ePccW2TuyF7hkehiVN+DF3LHCjw1LmL8KUpM19Q+DltpgWBfGCb2/J6fyz/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/538854418a1a9f0c0721e0d6ec03c5ab/cc1c3/img_3.webp 184w"],
              "sizes": "(max-width: 184px) 100vw, 184px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/538854418a1a9f0c0721e0d6ec03c5ab/b9938/img_3.jpg 184w"],
              "sizes": "(max-width: 184px) 100vw, 184px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/538854418a1a9f0c0721e0d6ec03c5ab/b9938/img_3.jpg",
              "alt": "img 3",
              "title": "img 3",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
  `}</figure>{`
  `}<div parentName="div" {...{
        "className": "content__info__title"
      }}>{`
    `}<p parentName="div">{`
      藪前知子 `}<br parentName="p" {...{
            "className": "only-sp"
          }}></br>{`
      `}<span parentName="p" {...{
            "className": "font__xs"
          }}>{`(やぶまえ ともこ)`}</span>{`
    `}</p>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "content__info__text"
      }}>{`
    `}<p parentName="div">{`
      1974年東京都生まれ。東京都現代美術館学芸員。これまでの主な担当企画に「大竹伸朗　全景　1955-2006」（2006）、「山口小夜子　未来を着る人」（2015）、「おとなもこどもも考える　ここはだれの場所？」（2015）、「MOTアニュアル2019
      Echo after
      Echo：仮の声、新しい影」（2019）など。外部企画に「札幌国際芸術祭2017」（2017）、「gallery
      αMプロジェクト
      東京計画2019」（2019）など。現在、東京都現代美術館で開催中の「石岡瑛子
      血が、汗が、涙がデザインできるか」展の企画構成を担当。
    `}</p>{`
  `}</div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      