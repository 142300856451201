import MovePageConfirmParam from "../../../utils/MovePageConfirmParam"

const movePageParamsJa: Record<string, MovePageConfirmParam> = {
  Potato: new MovePageConfirmParam(
    "/exhibitors/",
    "Exhibitor Booth",
    "国内外から集結した約230組の個性豊かな出版社、ギャラリー、アーティストのオンラインブースが並んでいます。個性豊かなブースの様子や、リアルタイムでのコミュニケーションをお楽しみください。",
    undefined,
    "exhibitors"
  ),
  TheBestDutchBookDesigns: new MovePageConfirmParam(
    "/exhibitions/best-dutch-book/",
    `Exhibition 1: "Best Dutch Book Designs"`,
    "1926年に設立され、ヨーロッパで最も長い歴史をもつオランダのブックデザインアワード「Best Dutch Book Designs」の展覧会です。"
  ),
  DutchArtistBooks_0: new MovePageConfirmParam(
    "/exhibitions/dutch-artist-book/",
    `Exhibition 2: "Dutch Artists’ Book: Then and Now"`,
    "オランダ出版文化を牽引する12名のキーパーソンの語る言葉から、オランダにおけるアートブックの進化を紐解きます。"
  ),
  DutchArtistBooks_1: new MovePageConfirmParam(
    "/exhibitions/dutch-artist-book/",
    `Exhibition 2: "Dutch Artists’ Book: Then and Now"`,
    "オランダ出版文化を牽引する12名のキーパーソンの語る言葉から、オランダにおけるアートブックの進化を紐解きます。"
  ),
  DutchArtistBooks_2: new MovePageConfirmParam(
    "/exhibitions/dutch-artist-book/",
    `Exhibition 2: "Dutch Artists’ Book: Then and Now"`,
    "オランダ出版文化を牽引する12名のキーパーソンの語る言葉から、オランダにおけるアートブックの進化を紐解きます。"
  ),
  Rafael: new MovePageConfirmParam(
    "/rafael/",
    "Exhibition 4: Rafaël Rozendaal “Shadow Objects Sculpture Park",
    "VABFメイン会場の“屋外”には、ネットアートのパイオニアとして活躍するオランダ人アーティスト、ラファエル・ローゼンダールによるデジタル彫刻「Shadow Object」が並ぶ公園が広がっています。"
  ),
  Fanfare: new MovePageConfirmParam(
    "/exhibitions/fanfare",
    "Exhibition 3: fanfare “Unlearn, Display, Connect”",
    "デザインスタジオfanfareが企画する本展。「Learning（学び）」「Displaying（ディスプレイ）」「Connecting（つながり）」という3つのキーワードに紐つぐ鼎談や作品を通じて、今日のオランダにおけるグラフィックデザインを探求します。"
  ),
  PotatoChips: new MovePageConfirmParam(
    "/events/virtual-book-signing/",
    "BOOK SIGNING",
    "出展者が企画するブックサイニングイベントの一覧です。"
  ),
  PotatoM: new MovePageConfirmParam(
    "https://tokyoartbookfair.com/events/",
    "VABF Event",
    "VABFが企画するイベントの一覧です。"
  ),
  PotatoS: new MovePageConfirmParam(
    "/events/exhibitors-events/",
    "EXHIBITORS' EVENT",
    "出展者が企画するイベント情報です。"
  ),
  FriedPotato: new MovePageConfirmParam(
    "https://9204.teacup.com/vabf/bbs",
    "LOUNGE",
    "誰でも書き込みができる掲示板が設置されています。ブックフェアで見つけたアーティストや戦利品を、こちらで共有してください。"
  ),
  SweetPotato: new MovePageConfirmParam(
    "/information-desk/",
    "INFORMATION",
    "VABFについてのご案内です。"
  ),
  Iridesse: new MovePageConfirmParam(
    "/printing-laboratory/",
    "FUJI XEROX ZINE Laboratory",
    "ハイスペックのオンデマンド印刷機、富士ゼロックスのIridesseを使ったZINEをご紹介します。抽選で100名様まで特殊色を使ったZINE無料印刷キャンペーンも開催中！"
  ),
  Kiosk: new MovePageConfirmParam(
    "https://vabf.shop/",
    "VABF KIOSK",
    "VIRTUAL ART BOOK FAIRのクラウドファンディングサイト。ご支援おねがいします！"
  ),
  Dolphin: new MovePageConfirmParam(
    "/exhibitors/4823",
    "Ryota Miyake",
    "VABFのエントランスの音楽やSEを制作する三宅亮太のオンラインブースへ！"
  ),
  Ship: new MovePageConfirmParam(
    "https://www.honkawamachi.com/",
    "本と川と街",
    "東京都現代美術館近く、深川エリアで本をテーマにしたイベントを開催中。"
  ),
  Donation: new MovePageConfirmParam(
    "https://vabf.shop/items/5f6dbd4993f6192f7fd89ce9",
    "Donation"
  ),
  Credits: new MovePageConfirmParam("/credits", "Credits"),

  Prev_1: new MovePageConfirmParam(
    "https://youtu.be/eNlziMkg_Yc",
    "Previewer 神谷圭介 (テニスコート)",
    "VABFオープン前にプレビューしてもらいました！フェアの楽しみ方の参考にしてください。"
  ),
  Prev_2: new MovePageConfirmParam(
    "https://youtu.be/QYMt0lHsJPU",
    "Previewer 川内タカ",
    "VABFオープン前にプレビューしてもらいました！フェアの楽しみ方の参考にしてください。"
  ),
  Prev_3: new MovePageConfirmParam(
    "https://youtu.be/nvEodN5CIMs",
    "Previewer 山本千織 (chioben) x 熊谷彰博 x 大江よう (TEXT)",
    "VABFオープン前にプレビューしてもらいました！フェアの楽しみ方の参考にしてください。"
  ),
  Prev_4: new MovePageConfirmParam(
    "https://youtu.be/eqer6f-ZLGw",
    "Previewer Pen編集部 石川康太",
    "VABFオープン前にプレビューしてもらいました！フェアの楽しみ方の参考にしてください。"
  ),
  Prev_5: new MovePageConfirmParam(
    "https://youtu.be/tPVJRnZiO30",
    "Previewer ウェブディレクター 大平かりん",
    "VABFオープン前にプレビューしてもらいました！フェアの楽しみ方の参考にしてください。"
  ),

  Human: new MovePageConfirmParam(
    "https://www.mot-art-museum.jp/",
    "東京都現代美術館",
    "東京都現代美術館へようこそ"
  ),
}

const movePageParamsEn: Record<string, MovePageConfirmParam> = {
  Potato: new MovePageConfirmParam(
    "/exhibitors/",
    "Exhibitor Booth",
    "Browse the lineup of online booths of the 230 unique publishers, galleries, artists from both Japan and abroad who have come together for the event. Perusers can check out information on each distinct booth while communicating with proprietors in real-time!",
    undefined,
    "exhibitors"
  ),
  TheBestDutchBookDesigns: new MovePageConfirmParam(
    "/exhibitions/best-dutch-book/",
    `Exhibition 1: "Best Dutch Book Designs"`,
    "This is an exhibition centering around Best Dutch Book Designs, established in 1926 in the Netherlands. Best Dutch Book Designs has the distinction of being Europe's longest-running book design award."
  ),
  DutchArtistBooks_0: new MovePageConfirmParam(
    "/exhibitions/dutch-artist-book/",
    `Exhibition 2: "Dutch Artists’ Book: Then and Now"`,
    "Here, 12 key figures at the forefront of Dutch publishing culture unravel the history as well as the evolution of art book design in the Netherlands."
  ),
  DutchArtistBooks_1: new MovePageConfirmParam(
    "/exhibitions/dutch-artist-book/",
    `Exhibition 2: "Dutch Artists’ Book: Then and Now"`,
    "Here, 12 key figures at the forefront of Dutch publishing culture unravel the history as well as the evolution of art book design in the Netherlands."
  ),
  DutchArtistBooks_2: new MovePageConfirmParam(
    "/exhibitions/dutch-artist-book/",
    `Exhibition 2: "Dutch Artists’ Book: Then and Now"`,
    "Here, 12 key figures at the forefront of Dutch publishing culture unravel the history as well as the evolution of art book design in the Netherlands."
  ),
  Rafael: new MovePageConfirmParam(
    "/en/rafael/",
    "Exhibition 4: Rafaël Rozendaal “Shadow Objects Sculpture Park",
    `The "outside" area that makes up the main venue of VABF is a park decorated with digital sculptures called "Shadow Objects," created by Dutch artist and net-art pioneer Rafaël Rozendaal.`
  ),
  Fanfare: new MovePageConfirmParam(
    "/en/exhibitions/fanfare",
    "Exhibition 3: fanfare “Unlearn, Display, Connect”",
    "Design studio fanfare spearheads this exhibit. Here, we explore the landscape of graphic design in the Netherlands today by discussing the keywords of Learning, Displaying, and Connecting while taking a look at relevant works and pieces."
  ),
  PotatoChips: new MovePageConfirmParam(
    "/en/events/virtual-book-signing/",
    "BOOK SIGNING",
    "A list of book-signing events organized by participating exhibitors."
  ),
  PotatoM: new MovePageConfirmParam(
    "https://tokyoartbookfair.com/en/events/",
    "VABF Event",
    "This section lists information on events organized by the VABF."
  ),
  PotatoS: new MovePageConfirmParam(
    "/en/events/exhibitors-events/",
    "EXHIBITORS' EVENT",
    "This section lists information on events organized by participating exhibitors."
  ),
  FriedPotato: new MovePageConfirmParam(
    "https://9204.teacup.com/vabf/bbs",
    "LOUNGE",
    "At the Lounge, you'll find a communal messaging and bulletin board where participants are free to write posts and comments as they enjoy the fair. Feel free to connect with other fairgoers about your VABF spoils, discuss interesting artists you may have discovered, and more."
  ),
  SweetPotato: new MovePageConfirmParam(
    "/en/information-desk/",
    "INFORMATION",
    "Here, you can find detailed information regarding the VABF."
  ),
  Iridesse: new MovePageConfirmParam(
    "/en/printing-laboratory/",
    "FUJI XEROX ZINE Laboratory",
    "Introducing a line-up of zines printed using the Iridesse, a high-spec, on-demand printer by Fuji Xerox. Enter into our lottery and you may be selected among 100 winners to get your Zine printed for free – and in special colors!"
  ),
  Kiosk: new MovePageConfirmParam(
    "https://vabf.shop/",
    "VABF KIOSK",
    "The crowdfunding portal for the Virtual Art Book Fair. We would appreciate your support!"
  ),
  Dolphin: new MovePageConfirmParam(
    "/en/exhibitors/4823",
    "Ryota Miyake",
    "The online booth of Ryota Miyake who produced the VABF entrance music and sound effects"
  ),
  Ship: new MovePageConfirmParam(
    "https://www.honkawamachi.com/",
    "本と川と街",
    "Book-themed events are held in the Fukagawa area near the Museum of Contemporary Art Tokyo."
  ),
  Donation: new MovePageConfirmParam(
    "https://vabf.shop/items/5f6dbd4993f6192f7fd89ce9",
    "Donation"
  ),
  Credits: new MovePageConfirmParam("/credits", "Credits"),

  Prev_1: new MovePageConfirmParam(
    "https://youtu.be/eNlziMkg_Yc",
    "Previewer Keisuke Kamiya (Tennis Court)",
    "Want to get a better idea of how to enjoy the fair? We had some guests come in before our opening day to show you how they experienced it."
  ),
  Prev_2: new MovePageConfirmParam(
    "https://youtu.be/QYMt0lHsJPU",
    "Previewer Taka Kawachi",
    "Want to get a better idea of how to enjoy the fair? We had some guests come in before our opening day to show you how they experienced it."
  ),
  Prev_3: new MovePageConfirmParam(
    "https://youtu.be/nvEodN5CIMs",
    "Previewer Chiori Yamamoto (chioben) x Akihiro Kumagaya x You Ooe (TEXT)",
    "Want to get a better idea of how to enjoy the fair? We had some guests come in before our opening day to show you how they experienced it."
  ),
  Prev_4: new MovePageConfirmParam(
    "https://youtu.be/eqer6f-ZLGw",
    "Previewer Pen Magazine Kota Ishikawa",
    "Want to get a better idea of how to enjoy the fair? We had some guests come in before our opening day to show you how they experienced it."
  ),
  Prev_5: new MovePageConfirmParam(
    "https://youtu.be/tPVJRnZiO30",
    "Previewer Web Director Karin Ohira",
    "Want to get a better idea of how to enjoy the fair? We had some guests come in before our opening day to show you how they experienced it."
  ),

  Human: new MovePageConfirmParam(
    "https://www.mot-art-museum.jp/",
    "Museum of Contemporary Art Tokyo",
    "Welcome to Museum of Contemporary Art Tokyo"
  ),
}

export const movePageParams = {
  ja: movePageParamsJa,
  en: movePageParamsEn,
}
