import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/DefaultPage.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`TOKYO ART BOOK FAIR (hereinafter referred to as “TABF” or “we”/“our”/“us”) has adopted a privacy policy (hereinafter referred to as this “Privacy Policy”) based on our collection and use of personal data on the TABF website and all related services (hereinafter referred to as the “Service”).`}</p>
    <h2>{`1. Basic Policy for Protecting Personal Information`}</h2>
    <ol>
      <li parentName="ol">{`In order to protect the personal data of users, TABF has established this Privacy Policy regarding the acquisition and use of personal data of users by TABF, and as we improve the management system, we will work to make this policy known to officers and employees.`}</li>
      <li parentName="ol">{`TABF complies with Japan's Personal Information Protection Law as well as the EU’s General Data Protection Regulation (hereinafter referred to as “GDPR”) and California Consumer Privacy Act of 2018 (hereinafter referred to as “CCPA”) and other individual laws and regulations in various regions. As we take care that this Privacy Policy does not conflict with these laws, we endeavor to take appropriate measures when rights are exercised in accordance with their corresponding laws.`}</li>
    </ol>
    <h2>{`2. Definition`}</h2>
    <ol>
      <li parentName="ol">{`The definition of “Personal Data” shall be stipulated by Japan’s Personal Information Protection Law and refers to information relating to a living person, including name, date of birth, address, phone number, contact information, and other data that can identify a specific individual.`}</li>
      <li parentName="ol">{`The term “Past and Characteristic Data” shall refer to information other than Personal Data as defined in the previous section, including services used, website and advertisement history, keywords searched by users, date and time of user access, access type, usage environment, postal code, gender, occupation, age, IP address, cookie data, location data, individual identification information of used device, etc.`}</li>
    </ol>
    <h2>{`3. Collection of Personal Data`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`TABF may acquire the following information on our website. TABF shall properly acquire, use, and manage each piece of data, and handle each collected item appropriately within the scope of its purpose of use.`}</p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`User-registered Data`}<br parentName="p"></br>{`
`}{`Information such as name, address, phone number, and email address may be collected when receiving an exhibition application, inquiry, or other contact.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Cookies`}<br parentName="p"></br>{`
`}{`TABF uses cookies for the below purposes. Users can choose whether or not to store cookies, but if they refuse to store cookies, they may not be able to access all features of the Service.`}</p>
            <ul parentName="li">
              <li parentName="ul">{`To improve user convenience such as saving user settings`}</li>
              <li parentName="ul">{`For security reasons such as session maintenance and protection`}</li>
              <li parentName="ul">{`To understand the visitor count and usage patterns of the Service and improve service quality`}</li>
            </ul>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Analytics`}<br parentName="p"></br>{`
`}{`This Service uses the access analysis tool “Google Analytics” by Google which uses cookies to collect traffic data. This traffic data is collected anonymously and is not personally identifiable. Users can refuse the collection by disabling cookies, so we ask you to check the settings of your browser. If you would like to know more about this point, please refer to the “Google Analytics Terms of Service.”`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Log`}<br parentName="p"></br>{`
`}{`When a user accesses the Service, log data is automatically generated and saved. This service analyzes the user's environment and uses it to provide better services and to prevent fraudulent activities that interfere with the provision of normal service.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Equipment Information`}<br parentName="p"></br>{`
`}{`The Service may acquire IP data from the user such as device information (individual identification information of the device, etc.) and OS information. TABF uses this information to provide better services and to prevent fraudulent activity that interferes with identity verification and the provision of normal services. Device information details acquired by TABF differ depending on the OS used by the user. Please check the policy of the device manufacturer or software provider for more information on what data can be collected.`}</p>
          </li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li">{`As a general rule, the collection and use of personal data of people in the EEA (European Economic Area) region requires consent. However, we may collect and use personal data even without user consent, (1) when responding to user requests, (2) when it is necessary to protect our legitimate interests, (3) when required by law, and (4) when it is necessary to improve the services of this corporation, etc.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The user may withdraw consent as outlined in the preceding paragraph at any time by contacting the designated point of contact in TABF. However, even if the consent is withdrawn, this will not affect the legality of the collection and use of data gathered before consent was withdrawn.`}</p>
      </li>
    </ol>
    <h2>{`4. Purpose for Use`}</h2>
    <p>{`TABF uses the personal data we have collected for the following purposes:`}</p>
    <ol>
      <li parentName="ol">{`To verify user identity`}</li>
      <li parentName="ol">{`To prevent unauthorized access`}</li>
      <li parentName="ol">{`To enable users a smooth user experience`}</li>
      <li parentName="ol">{`To provide, maintain, and improve the Service`}</li>
      <li parentName="ol">{`To create statistical data regarding the use of the Service`}</li>
      <li parentName="ol">{`To conduct a questionnaire regarding the Service currently provided as well as plans for a future Service`}</li>
      <li parentName="ol">{`To respond to user inquiries`}</li>
      <li parentName="ol">{`To announce information about the Service and advertising information for businesses outside of TABF`}</li>
      <li parentName="ol">{`To devise and provide new plans for the Service in the future`}</li>
      <li parentName="ol">{`To make vital announcements regarding the Service as necessary`}</li>
    </ol>
    <h2>{`5. Provision of Data`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`In principle, we will not provide personal information to third parties without prior consent from the user except in the following cases:`}</p>
        <ol parentName="li">
          <li parentName="ol">{`When it is required by law`}</li>
          <li parentName="ol">{`When it is necessary to protect someone’s life, body, or property and it is difficult to obtain consent`}</li>
          <li parentName="ol">{`When it is particularly necessary to improve public health or promote the sound development of children and it is difficult to obtain consent`}</li>
          <li parentName="ol">{`When it is necessary for a national institution, a local public body, or an entrusted party to cooperate in carrying out the affairs stipulated by laws and regulations, provided obtaining consent would carry the risk of hindering the performance of this task`}</li>
          <li parentName="ol">{`When TABF outsources all or a portion of personal data as necessary in order to achieve the purpose of use`}</li>
          <li parentName="ol">{`When personal data is forwarded due to a merger, corporate split, business transfer, or other reasons related to a succession of business`}</li>
          <li parentName="ol">{`When personal data is to be shared with a specific person and the contents, scope, purpose, and the name of the party proposed to manage the data in question are provided in advance to the user in easily accessible state.`}</li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li">{`TABF has no history of providing or selling personal information for commercial purposes.`}</p>
      </li>
    </ol>
    <h2>{`6. Deposition of Data`}</h2>
    <p>{`TABF may entrust all or a portion of personal data obtained from the user to a business consignee provided it is deemed necessary to achieve the purpose of use. In that event, TABF will thoroughly examine the other party’s eligibility as a business consignee and will strive to manage the data appropriately.`}</p>
    <h2>{`7. Joint Use of Data`}</h2>
    <p>{`If, within the provision of this Service, a business partner who works jointly with TABF deems it necessary, TABF may share personal data with that business partner provided that prior consent is received from the user. In this case, we will first share with the user the purpose of use, the name of the business partner, the data type, and the administrator name before beginning joint use of the data.`}</p>
    <h2>{`8. Confirming and Adjusting Personal Data`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Based on GDPR, etc., the user (1) confirms whether personal data has been acquired and used, (2) requests correction of inaccurate personal data, and (3) in certain cases can request the deletion of data, and (4) in some cases can restrict the use of personal data.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`When the user makes a request such as described in the preceding section, it is necessary to follow the procedure established TABF. In addition, we may charge a disclosure fee decided by TABF separately from this document.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Notwithstanding the provisions of 1., you may not make a claim in the following cases:`}</p>
        <ol parentName="li">
          <li parentName="ol">{`When there is a risk that disclosure, etc. may harm the life, body, property or other rights and interests of the user or a third party`}</li>
          <li parentName="ol">{`When there is a risk that disclosure, etc. will significantly hinder the proper implementation of our business`}</li>
          <li parentName="ol">{`When disclosure etc. violates the law`}</li>
          <li parentName="ol">{`When it cannot be confirmed that the request is from the person themselves`}</li>
          <li parentName="ol">{`When it is technically impossible or when an excessive amount of work is required`}</li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If CCPA is applied, the user has the below rights under the law. However, when the user exercises such rights, their identity will be verified by the methods specified by TABF.`}</p>
        <ol parentName="li">
          <li parentName="ol">{`The right to request the provision of personal data up to twice a year which has been collected by TABF within the past 12 months`}</li>
          <li parentName="ol">{`The right to delete personal information in certain cases stipulated by law`}</li>
          <li parentName="ol">{`The right not to be discriminated against`}</li>
          <li parentName="ol">{`The right to request the suspension of sales of personal data currently and in the future`}</li>
        </ol>
      </li>
    </ol>
    <h2>{`9. Suspension of Use, etc.`}</h2>
    <p>{`If, for the reason that their personal data is being used beyond the scope of defined usage or that the data has been collected by fraudulent means, the user requests to TABF the deletion or suspension of data usage (hereinafter referred to as “Suspension of Use”), TABF shall conduct the necessary investigation without delay, and based on the result, shall conduct Suspension of Use and notify the user accordingly. However, should the Suspension of Use process require a large sum of money, or should it otherwise proves difficult to enact and we find it is possible to protect the rights and interests of the user through another measure, TABF will opt for said alternative.`}</p>
    <h2>{`10. Privacy Policy Revisions`}</h2>
    <ol>
      <li parentName="ol">{`The contents of this Privacy Policy can be revised without notifying the user. If CCPA applies, we will revise this Privacy Policy at least once every 12 months.`}</li>
      <li parentName="ol">{`Unless otherwise specified by TABF, the revised Privacy Policy shall take effect from the time it is posted on the TABF website.`}</li>
      <li parentName="ol">{`If this Privacy Policy is provided in a language other than Japanese and the meaning of this policy in a foreign language differs from the intention of this policy in Japanese, the Japanese version of the Privacy Policy takes precedence.`}</li>
    </ol>
    <h2>{`Contact`}</h2>
    <p>{`Please contact the following address for any questions or concerns regarding this Privacy Policy:`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "mailto:info@tokyoartbookfair.com"
      }}>{`info@tokyoartbookfair.com`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      