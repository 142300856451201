import React from "react"
import Layout from "../layouts/ja"
import { Link } from "gatsby"
import CommonDoc from "../components/CommonDoc"
import css from "./VabfEvents.module.scss"
import { MDXProvider } from "@mdx-js/react"
import SocialShare from "../components/SocialShare"
const shortcodes = { SocialShare }

export default props => {
  const children = props.children
  const title = props.pageContext.frontmatter.title
  const date = props.pageContext.frontmatter.date
  const time = props.pageContext.frontmatter.time
  const description = props.pageContext.frontmatter.description
  const langDir = props.locale === `en` ? "/en" : ""


  const dateFormat = {
    _fmt: {
      dd: function (date) {
        return ("0" + date.getDate()).slice(-2)
      },
      yyyy: function (date) {
        return date.getFullYear() + ""
      },
      w: function (date) {
        return [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ][date.getDay()]
      },
      MMMM: function (date) {
        return [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ][date.getMonth()]
      },
    },
    _priority: ["dd", "yyyy", "w", "MMMM"],
    format: function (date, format) {
      return this._priority.reduce(
        (res, fmt) => res.replace(fmt, this._fmt[fmt](date)),
        format
      )
    },
  }

  return (
    <Layout
      location={props.location}
      title={title}
      description={description}
      ogImage={props.pageContext.frontmatter.ogImage}
    >
      <CommonDoc>
        <section className={`container`}>
          <div className={`content`}>
            <div className={`content__head`}>
              <h1 className={`haeding__lev1`}>VABF EVENTS</h1>
            </div>

            <article className={`content__2col`}>
              <div className={`content__2col__side`}>
                <div className={`content__back`}>
                  <Link to={`${langDir}/events/`} className={`link__back`}>
                    戻る
                  </Link>
                </div>

                <div className={css.eventdetail__time}>
                  <time>
                    {dateFormat.format(new Date(date), "w, MMMM dd, yyyy,")}
                    <br />
                    {time}
                  </time>
                </div>

                <div className={`content__title`}>
                  <h1 className={`haeding__lev2`}>{title}</h1>
                </div>
              </div>
              {/* /.content__side */}

              <div className={`editor__content content__2col__main`}>
                <MDXProvider components={shortcodes}>{children}</MDXProvider>
              </div>
              {/* /.content__main */}
            </article>
          </div>
          {/* /.content */}
        </section>
        {/* /.container */}
      </CommonDoc>
    </Layout>
  )
}
