import React from "react"
import css from "./LanguageSwitcher.module.scss"

const LanguageSwitcher = props => {
  const links = props.langs.map(lang => (
    <a href={lang.link} key={lang.langKey}>
      {!lang.selected && <>{lang.langKey}</>}
    </a>
  ))

  return (
    <div className={css.base} data-motion="lang">
      {links}
    </div>
  )
}
export default LanguageSwitcher
