import React, { useEffect, useState, useCallback, useContext } from "react"
import Layout from "../layouts/en"
import { useEvents } from "../hooks/index"
import { LoadingMask } from "../components/LoadingMask"
import MovePageConfirm from "../components/MovePageConfirm"
import Message from "../components/Message"
import Helper from "../components/Helper"
import { graphql, useStaticQuery } from "gatsby"
import SearchExhibitors from "../components/SearchExhibitors"
import { GlobalStateContext } from "../context/GlobalContextProvider"

function IndexPage(props) {
  // 3D のローディング状況を知るために Global なコンテキストを利用
  const state = useContext(GlobalStateContext)

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            version
          }
        }
      }
    `
  )

  // イベント系の設定
  // =============
  const events = useEvents()
  const [areaId, setAreaId] = useState("entrance")
  const [isBadge, setBadge] = useState(false) // インフォメーションのバッジ

  /**
   * インフォメーションのバッジチェック
   */
  const chkBadge = useCallback(id => {
    let infoOpened = false
    try {
      // areaのインフォメーションが開かれたことがあるかチェック
      infoOpened = !!localStorage.getItem("infoOpened" + id)
    } catch {}

    // 開かれたことがなければバッジをつける
    setBadge(!infoOpened)
  }, [])

  const onAreaChanged = useCallback(
    id => {
      // console.log(`ヘルプのareaIdが ${id} に変更`)
      setAreaId(id)
      chkBadge(id)
    },
    [chkBadge]
  )

  useEffect(() => {
    // ページ初期化時の処理
    chkBadge(areaId)
    events.on("areaChanged", onAreaChanged)

    // ページ破棄時の処理
    return () => {
      events.off("areaChanged", onAreaChanged)
    }
  }, [events, areaId, chkBadge, onAreaChanged])

  return (
    <Layout
      location={props.location}
      title={`Welcome to Virtual Art Book Fair`}
      show3dLayer={true}
    >
      <div>
        <MovePageConfirm />
        <Message lang={`en`} />

        <SearchExhibitors lang={`en`} />
        {/* 右下はてなボタン */}
        <Helper
          areaId={areaId}
          lang={`en`}
          isBadge={isBadge}
          setBadge={setBadge}
        />
      </div>

      {/*  ローディングレイヤーの ON / OFF を GlobalState の値を見て変更します */}
      <LoadingMask
        isLoading={!state.webGlLoaded}
        version={site.siteMetadata.version}
        lang={`en`}
      />
    </Layout>
  )
}

export default IndexPage
