import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.en.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Giacomo Joyce is a posthumously published short text by James Joyce dating from 1914, now republished by Stichting De Roos. The text – translated by Robbert-Jan Henkes and Erik Bindervoet and supplemented by an afterword, annotations and illustrations (by Bindervoet) – is followed in facsimile by the English text as originally published. In a delightful ‘Droste effect’, this in turn contains a facsimile of Joyce’s manuscript. The aim of this presentation of interplay between original and reproduction is evidently not to make the facsimile as faithful a copy of the original as possible. On the contrary: the process of reproduction has been made visible by reproducing the English edition as if ‘using a photocopier’. This section is printed on delicate 60gsm paper, resulting in an attractive contrast with the sturdier 100gsm paper used for the preceding section, but which is nevertheless pleasant to the touch. The title’s typography again presents a beautiful graphic interplay of lines, this time featuring the first names of the author and the love-struck hero of the short text: James and Giacomo. On the cover and on the artfully folded slip case in particular, the optical effect of these two names, hatched in two directions and printed one over the top of the other, is startling – all the more so as the printing is in one colour only. Opinions differ on the usefulness of such slip cases, and we must mention one spread that didn’t turn out as well as it could have (p. 44-46, where the beloved’s face is pitilessly marred by the spine). Nevertheless, the jury was unanimous in its verdict: an intelligent, sensitively designed book that convincingly marries together form and content.`}</p>
    <hr></hr>
    <p>{`Publisher: Stichting De Roos, Utrecht`}<br parentName="p"></br>{`
`}{`Designer: Niels Schrader, Amsterdam`}</p>
    <p>{`Category: Prose, poetry or graphic novels`}<br parentName="p"></br>{`
`}{`Author: James Joyce, Erik Bindervoet, Robbert-Jan Henkes`}<br parentName="p"></br>{`
`}{`Editor: Erik Bindervoet`}<br parentName="p"></br>{`
`}{`Language: Dutch`}<br parentName="p"></br>{`
`}{`Translator: Erik Bindervoet, Robbert-Jan Henkes`}<br parentName="p"></br>{`
`}{`Illustrator: Erik Bindervoet`}<br parentName="p"></br>{`
`}{`Lithographer: Zwaan Lenoir, Wormerveer`}<br parentName="p"></br>{`
`}{`Printer: Zwaan Lenoir, Wormerveer`}<br parentName="p"></br>{`
`}{`Binder: Boekbinderij Van Waarden, Zaandam`}<br parentName="p"></br>{`
`}{`Font: Founders Grotesk (Klim Type Foundry)`}<br parentName="p"></br>{`
`}{`Size in mm: 155 x 220 x 12`}<br parentName="p"></br>{`
`}{`Number of pages: 136`}<br parentName="p"></br>{`
`}{`Print run: 175`}<br parentName="p"></br>{`
`}{`Price: € 90`}<br parentName="p"></br>{`
`}{`ISBN: N / a`}<br parentName="p"></br>{`
`}{`Paper for interior: 60gsm PlanoPak (Papyrus), 100gsm Lessebo Design 1.3 Ivory (Igepa)`}<br parentName="p"></br>{`
`}{`Endpapers: 120gsm Pop’Set Ultra Red (Antalis)`}<br parentName="p"></br>{`
`}{`Binding: 120gsm Pop’Set Ultra Red (Antalis) over 1.5 mm greyboard`}<br parentName="p"></br>{`
`}{`Slip case: 240gsm Pop’Set Ultra Red (Antalis)`}<br parentName="p"></br>{`
`}{`Binding style: Sewn hardback with flat spine, thin boards with minimal square`}<br parentName="p"></br>{`
`}{`Special features: Parts of the text in black are overprinted in magenta.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      