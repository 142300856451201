import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.en.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Where the River Runs is an overview of the work of Surinamese Dutch artist Remy Jungerman, who draws as much on the Modernism of Rietveld and Mondriaan as on Winti beliefs and the culture of the Maroons. The book opens with the monumental work created by Jungerman for the 2019 Venice Biennale, followed by a rhythmic overview of his work in an extensive pictorial section. The second part of the book is printed entirely in four layers of opaque white on red pages – a reference to the artist’s use of white kaolin clay. An idea that not only connects to Jungerman’s work, but also gives the book a clear identity of its own. The large blocks of robust typography that fill the pages of this section have a monumental, sculptural look and make for a good continuation of the rhythm of the visual section. At the back of the book, we return to the installation for the Biennale, recognisable by the Muskat Grey paper also used for the first section. The images flow naturally onto the likewise Muskat Grey cardboard of the cover, which is cleverly sewn into the book block at the back only and then folded outwards, around the exposed spine and across the front, mutating into the front board. Logical, effective, beautiful, convincing: the jury’s decision was unanimous.`}</p>
    <hr></hr>
    <p>{`Publisher: Jap Sam Books (Eleonoor Jap Sam), Prinsenbeek`}<br parentName="p"></br>{`
`}{`Designer: Mainstudio (Edwin van Gelder, Florian Schimanski), Amsterdam`}</p>
    <p>{`Category: Art, photo and architecture books`}<br parentName="p"></br>{`
`}{`Artist: Remy Jungerman`}<br parentName="p"></br>{`
`}{`Editor: Rob Perrée`}<br parentName="p"></br>{`
`}{`Language: English`}<br parentName="p"></br>{`
`}{`Translator: Jane Hall, Michael Meert`}<br parentName="p"></br>{`
`}{`Photographer: Aatjan Renders`}<br parentName="p"></br>{`
`}{`Image editor: Remy Jungerman, Edwin van Gelder`}<br parentName="p"></br>{`
`}{`Editor-in-chief: Eleonoor Jap Sam`}<br parentName="p"></br>{`
`}{`Lithographer: robstolk® (Alex Feenstra), Amsterdam`}<br parentName="p"></br>{`
`}{`Printer: robstolk®, Amsterdam`}<br parentName="p"></br>{`
`}{`Binder: Boekbinderij Patist, Den Dolder`}<br parentName="p"></br>{`
`}{`Font: Funktional Grotesk (Typografische Systeme)`}<br parentName="p"></br>{`
`}{`Size in mm: 220 x 310 x 13`}<br parentName="p"></br>{`
`}{`Number of pages: 160`}<br parentName="p"></br>{`
`}{`Print run: 1,000`}<br parentName="p"></br>{`
`}{`Price: € 29,50`}<br parentName="p"></br>{`
`}{`ISBN: 978 94 92852 13 7`}<br parentName="p"></br>{`
`}{`Paper for interior: 100gsm Arcoprint Milk 1.5 (Fedrigoni), 140gsm Muskat Grey (Igepa), 120gsm Forever Red (Igepa)`}<br parentName="p"></br>{`
`}{`Cover: 290gsm Muskat Grey (Igepa)`}<br parentName="p"></br>{`
`}{`Binding style: Swiss-style cut-flush binding, transparently glued-off spine with front and back cover of six pages that is glued at the back and is folded to the front.`}<br parentName="p"></br>{`
`}{`Special features: The text of the essays is printed four times in opaque white. The cover is printed in CMYK and opaque white.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      