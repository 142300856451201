import React from "react"
import Layout from "../../../../layouts/en"
import BdbdMenu from "../../../../components/BdbdMenu.en"
import css from "../index.module.scss"
import ImageSlider from "../../../../components/ImageSlider"

const IndexPage = props => {
  return (
    <Layout location={props.location} title={`Best Dutch Book Exhibition`}>
      <section className={css.bdbd__report + ` container`}>
        <div className={css.bdbd__content + ` content`}>
          <div className={css.bdbd__head + ` content__head`}>
            <h1 className={`haeding__lev1`}>
              BEST DUTCH <br className={`only-sp`} />
              BOOK DESIGNS
            </h1>
          </div>

          <nav className={css.bdbd__localnavi}>
            <BdbdMenu />
          </nav>

          <article className={css.bdbd__section + ` content__2col`}>
            <div className={`content__2col__side`}>
              <div className={`content__title`}>
                <h1 className={`haeding__lev2`}>
                  The judging for The Best Dutch Book Designs 2019
                </h1>
              </div>
            </div>

            <div className={`editor__content content__2col__main`}>
              <p>
                The judging for The Best Dutch Book Designs 2019 took place on
                the eve of an unprecedented chapter in the history of the
                Netherlands and the world. All of the 278 books judged were
                published in 2019. Not one of them contains the word
                ‘coronavirus’. By the time this jury report came to be written,
                however, the whole of the Netherlands was in the grip of an
                ‘intelligent lockdown’. By week 14, Albert Camus’ The Plague was
                at number 3 in the top 60 bestseller list. The #Ikleesthuis
                (#ireadathome) campaign attracted a great deal of attention, but
                on 15 March Wilma de Rek wrote in Dutch newspaper de Volkskrant
                that one-third of Dutch book retailers were staring bankruptcy
                in the face. At the time of writing this report, no one knows
                how the ‘new normal’ and the ‘metre-and-a-half society’ will
                work, let alone what all this will mean for books. It may seem
                trivial – almost inappropriate – at such times to be discussing
                bindings, choices of paper and fluorescent inks. Nevertheless,
                it is worth taking a closer look.
              </p>
              <ImageSlider>
                <img src="/images/bdbd/jury2.jpg" alt="" />
                <img src="/images/bdbd/selectie3.jpg" alt="" />
              </ImageSlider>

              <p>
                U bevindt zich hier (You are here) – the title of one of the
                books chosen by the jury – documents the 24-hour economy: the
                consumer society in which we are always ‘on’. The thing that
                immediately jumps out now is that no one is maintaining social
                distance. It’s like suddenly looking at a documentary from
                another age, even though these photographs were taken less than
                a year ago. Lowlands festival, Amsterdam Fashion Week, the
                Huishoudbeurs household goods fair, a meeting of carnival
                society De Spoedjes: all events that are no longer possible
                today. The ink on this book was hardly dry before ‘the
                Netherlands as it is’ became ‘the Netherlands as we may never
                see it again’. It’s impossible now to see these books other than
                through this prism, even if the judging took place in January –
                a time still pre-coronavirus. But there is comfort to be found
                too. When Red Disappears is a virus-free dive into the waters
                around the province of Zeeland with underwater still lifes that
                would make the seventeenth-century old masters gasp. The
                timeless colour world this book so sublimely evokes feels
                strangely immune to the virus – corona-proof, as it were. Other
                books, such as Henk Wildschut’s Rooted, embrace a theme – in
                this case the lives of refugees – that remains as relevant as
                ever, driven by the forces of globalisation and migration, which
                are also influencing the spread of the virus.
              </p>

              <ImageSlider>
                <img src="/images/bdbd/U bevindt zich hier_cover.jpg" alt="" />
                <img src="/images/bdbd/U bevindt zich hier_01.jpg" alt="" />
                <img src="/images/bdbd/U bevindt zich hier_02.jpg" alt="" />
              </ImageSlider>

              <ImageSlider>
                <img src="/images/bdbd/When Red Disappears_cover.jpg" alt="" />
                <img src="/images/bdbd/When Red Disappears_01.jpg" alt="" />
                <img src="/images/bdbd/When Red Disappears_02.jpg" alt="" />
              </ImageSlider>

              <ImageSlider>
                <img src="/images/bdbd/Rooted_cover.jpg" alt="" />
                <img src="/images/bdbd/Rooted_01.jpg" alt="" />
                <img src="/images/bdbd/Rooted_02.jpg" alt="" />
              </ImageSlider>

              <p>
                But it would not be fair to assess this selection from a
                post-corona perspective. As remarked above, these books were
                made in 2019 and the judging took place in January 2020. The
                trends and observations raised in their pages are from 2019. We
                can pick out a few of these. As more neutral observations:
                ‘layer cake’ book blocks of text and pictorial sections; an
                undiminished love of ‘bare spines’; coloured edges; picture
                books with a cover either without images or without words. The
                jury was critical of hyper-aestheticisation: impossible and
                unnecessary slip cases; meaningless excess of inks and
                materials; ostentatious ‘paper porn’. In a more positive sense,
                the jury members were impressed by: lots of adventure and play
                with (semi-)translucent paper; typography shimmering through
                several pages; mirrored images making their presence felt from
                the other side of the page. Also the intelligent use of the
                tactile qualities of the materials and processes used –
                neobond®, for example, or page edges roughed to the texture of
                velvet. Many of the books deal with the relationship between
                human and plant, man and nature. Diversity is clearly a theme,
                but from the perspective of the ‘other’, or by incorporating
                several perspectives. Research focused on identity (personal or
                collective) figured prominently in the archive-based projects.
                Migration and globalisation play a role.
              </p>

              <ImageSlider>
                <img src="/images/bdbd/American Origami_cover01.jpg" alt="" />
                <img src="/images/bdbd/American Origami_01.jpg" alt="" />
                <img src="/images/bdbd/American Origami_cover02.jpg" alt="" />
              </ImageSlider>

              <ImageSlider>
                <img
                  src="/images/bdbd/Bryophetes and Lichens of Letterewe_cover.jpg"
                  alt=""
                />
                <img
                  src="/images/bdbd/Bryophetes and Lichens of Letterewe_01.jpg"
                  alt=""
                />
              </ImageSlider>

              <ImageSlider>
                <img src="/images/bdbd/Odstonic_02.jpg" alt="" />
                <img src="/images/bdbd/Odstonic_01.jpg" alt="" />
              </ImageSlider>

              <p>
                All of the books selected demonstrate exceptional, intelligent,
                surprising, innovative or simply highly attractive ways of
                connecting or bringing together these topics with design, form,
                manifestation and execution. Alongside admiration and
                excitement, they often also brought solace. Something we can
                appreciate all the more right now.
              </p>

              <p>
                Eelco van Welie, writing for all the members of the panel
                <br />
                April 2020
              </p>

              <h3>Jury, The Best Dutch Book Designs 2019</h3>
              <p>
                Jeremy Jansen (designer), Mijke Wondergem (designer), Eelco van
                Welie (managing director NAI010 publishers), Martijn Kicken
                (adviser, Tielen printers) and Suzanna Héman (assistant curator,
                Stedelijk Museum Amsterdam)
              </p>

              <p className={`font__small`}>
                Entries in whose genesis any member of the panel had been
                involved were passed on to the shadow panel, which is made up of
                former members of the main panel. This year the shadow panel
                considered eighteen books, seven of of which were submitted for
                the final judging (the judge concerned being excluded from
                discussion of the book in question). At the end of the process
                two such books remained in the final selection: The Grand
                Project and The Kindness of One.
              </p>

              <h3>Shadow panel, The Best Dutch Book Designs 2019</h3>
              <p>
                Bregitta Kramer (printing consultant), Ron van Roon (designer),
                Michaël Snitker (designer) and Astrid Vorstermans (publisher,
                Valiz)
              </p>
            </div>
          </article>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
