import React, { useEffect, useCallback } from "react"
import { useEvents } from "../hooks"
import { useState } from "react"
import css from "./Message.module.scss"
import MessageContent from "./MessageContent"
import { FormattedMessage } from "react-intl"

function Message(prop) {
  const [postData, setPostData] = useState(null) // JSONデータ
  const [message, setMessage] = useState(null) // JSONデータ
  // event hook
  const events = useEvents()

  /**
   * メッセージモーダル表示
   * @param {Number} id メッセージ表示させるID
   */
  const doMessage = useCallback(id => {
    setPostData({ loading: true })
    const url = `https://domg29rgj0qo4.cloudfront.net/exibitor/${id}/single.json`
    fetch(url, {
      mode: "cors",
    })
      .then(res => {
        return res.json()
      })
      .catch(err => {
        // console.log("rejectedの処理です")
        // console.error(err)
      })
      .then(resJson => {
        // 同じタイミングで state が更新されるようにここで反映
        setMessage(getTheMessageAndIncrement(resJson))
        setPostData(resJson)
        return false
      })
  }, [])

  /**
   * メッセージのカウントを増やします。
   * @constructor
   */
  const getTheMessageAndIncrement = json => {
    const lastUpdated = localStorage.getItem("messageLastUpdatedTime") // ローカルストレージに時間を記録
    const shouldUpdate =
      lastUpdated && new Date().getTime() - lastUpdated > 1000

    const messageId = json.exhibitorId
    const messageStr = localStorage.getItem("message")
    let messageObj = {}
    if (messageStr) {
      messageObj = JSON.parse(messageStr)
    }
    // console.log(`messageStr: `, messageStr)
    // console.log(`Local Storage 内のメッセージ: `, messageObj)
    // console.log(`現在の messageId: `, messageId)

    // メッセージが一つも登録されていない場合は空文字でリターン
    let isEmpty = true
    for (let i = 0; i < json.message.length; i += 1) {
      if (json.message[i].length > 0) {
        isEmpty = false
        break
      }
    }
    if (isEmpty) {
      return "準備中 / Under preparation"
    }

    let count = 0 // メッセージ表示回数
    if (messageObj && messageObj.hasOwnProperty(messageId)) {
      if (shouldUpdate) {
        // インクリメントする
        count = messageObj[messageId] + 1
        if (count > json.message.length - 1) count = 0
        // 空のコメントは飛ばす
        while (json.message[count] === "") {
          // console.log(`The message no ${count} is empty, skip it.`)
          count += 1
          if (count > json.message.length - 1) count = 0
        }
      } else {
        // console.log(`This is continuous click, comments will not increment`)
        count = messageObj[messageId]
      }
    }
    messageObj[messageId] = count
    localStorage.setItem("message", JSON.stringify(messageObj)) // ローカルストレージ登録
    localStorage.setItem("messageLastUpdatedTime", new Date().getTime()) // ローカルストレージに時間を記録

    return json.message[count] // メッセージ取得
  }

  /**
   * ブースを見る
   * @param {Object} e イベントオブジェクト
   */
  const gotoDetail = useCallback(
    e => {
      events.emit("openVendor", postData.exhibitorId, prop.lang)
    },
    [events, postData, prop.lang]
  )

  /**
   * 立ち去る
   * @param {Object} e イベントオブジェクト
   */
  const closeMessage = useCallback(
    e => {
      if (postData) {
        events.emit("leaveVendor", postData.exhibitorId)
        setPostData(null)
      }
    },
    [events, postData]
  )

  const changeArea = useCallback(area => {
    if (area !== "booth") {
      setPostData(null)
    }
  }, [])

  useEffect(() => {
    events.on("talkToVendor", doMessage)
    events.on("requestLeaveVendor", closeMessage)
    events.on("changeArea", changeArea)
    return () => {
      events.off("talkToVendor", doMessage)
      events.off("requestLeaveVendor", closeMessage)
      events.off("changeArea", changeArea)
    }
  }, [events, doMessage, closeMessage, changeArea])

  if (!postData) {
    return <></>
  }

  if (postData.loading) {
    return (
      <>
        <div className={css.container}>
          <section>Loading</section>
        </div>
      </>
    )
  }

  return (
    <div>
      <div className={css.container} data-motion="side-modal">
        <section className={css.content}>
          {<MessageContent postData={postData} message={message} />}
          <div className={css.btn}>
            <ul className={`btn__wrap__col`}>
              <li data-motion="up-inner-1">
                <button className={`btn__back`} onClick={closeMessage}>
                  <FormattedMessage id={`leave`} />
                </button>
              </li>
              <li data-motion="up-inner-1">
                {postData.icon && (
                  <button className={`btn`} onClick={gotoDetail}>
                    <FormattedMessage id={`showBooth`} />
                  </button>
                )}
              </li>
            </ul>
          </div>
        </section>
      </div>
      <button onClick={closeMessage} className={css.hitarea}>
        <span className={`u-reader`}>close</span>
      </button>
    </div>
  )
}

export default Message
