import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.en.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This urgent book by Henk Wildschut is his third to deal with refugees and migrants. The book is about the hope, solace and dignity refugees find by creating micro gardens in the usually bleak surroundings of their temporary abodes. This showcases the level of mastery attained by Wildschut and designer Robin Uleman in photography, design and execution. The images of plants and small plots of protected soil in the desolate context of a refugee camp represent domesticity and longing for something to hold onto in an uncertain world. The connection between the various plants, places and situations is made in a highly effective way by alternating pages of two different lengths. This means the image on the page below is able to contribute to the image on the spread above it: an extremely natural, convincing effect. The same effect is achieved by different means on the jacket: folded in the American style – inwards at the top and outwards at the bottom – here too this creates a connection between the photos on each side of the paper. The cover, sewn in with the book block under this jacket (in Muskat Brown and black) offers a pleasant contrast and the coarse screen used works almost like a trellis. A highly controlled, painstaking book in terms of design, concept and execution. The jury’s verdict was unanimous.`}</p>
    <hr></hr>
    <p>{`Publisher: Henk Wildschut, Amsterdam`}<br parentName="p"></br>{`
`}{`Designer: Robin Uleman, Amsterdam`}</p>
    <p>{`Category: Art, photo and architecture books`}<br parentName="p"></br>{`
`}{`Editor: Vic Joseph, Joost van Beek`}<br parentName="p"></br>{`
`}{`Language: English`}<br parentName="p"></br>{`
`}{`Translator: Vic Joseph`}<br parentName="p"></br>{`
`}{`Photographer: Henk Wildschut`}<br parentName="p"></br>{`
`}{`Image editor: Robin Uleman, Henk Wildschut`}<br parentName="p"></br>{`
`}{`Editor-in-chief: Robin Uleman, Vic Joseph`}<br parentName="p"></br>{`
`}{`Lithographer: Marc Gijzen beeldbewerking & digitale lithografie, Voorburg`}<br parentName="p"></br>{`
`}{`Printer: NPN Drukkers, Breda`}<br parentName="p"></br>{`
`}{`Binder: Boekbinderij Patist, Den Dolder`}<br parentName="p"></br>{`
`}{`Font: New Century Schoolbook STD (Adobe)`}<br parentName="p"></br>{`
`}{`Size in mm: 190 x 255 x 12`}<br parentName="p"></br>{`
`}{`Number of pages: 152`}<br parentName="p"></br>{`
`}{`Print run: 750`}<br parentName="p"></br>{`
`}{`Price: € 39`}<br parentName="p"></br>{`
`}{`ISBN: 978 90 825885 1 4`}<br parentName="p"></br>{`
`}{`Paper for interior: 120gsm Munken Lynx Rough (Antalis)`}<br parentName="p"></br>{`
`}{`Cover: 290gsm Muskat Brown (Igepa)`}<br parentName="p"></br>{`
`}{`Dust jacket: 120gsm Wibalin® Buckram 500 White (Winter & Company)`}<br parentName="p"></br>{`
`}{`Binding style: Sewn softcover with front and back cover of four pages sewn in, dust jacket over transparently glued-off spine`}<br parentName="p"></br>{`
`}{`Special features: Some pages are 16 mm shorter at the tail. The book block consists of two types of sections (8 pages each) each folded in a different way and alternating, which contributes to a varied rhythm between the normal and the shorter pages. The book block and binding are cut flush at the head, front and tail. Printing is in CMYK and Pantone Green. The dust jacket is folded in the American style: the head is folded inside, the tail is folded outside. The cover is printed in black and then twice more in opaque white.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      