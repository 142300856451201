import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`このラフな文書＝カイエの強く印象的なカバーは、ロープと絵の具、そしてキャンバスを用いて作られるイデ・アンドレーの絵画作品が持つエネルギーを放っているようだ。彼の絵画が、絵画というアクションの痕跡であるように、本書も彼の作品ができあがる空間や状況、そしてアーティストの制作姿勢や性格など、その痕跡を表現する。「カイエ」という言葉は少々古臭いかもしれないが、書類の束が綴じられ、例えば 8 ページと 105 ページにかけてギターを抱えて体をそらす人物のように、イメージは時に書籍の後半に回り込むように配置されている。同時に、書籍の設計は一寸のぶれもなく正確で、黒い糸の模範的な中綴じと、最高級の印刷、リトグラフ技術とデザインによって、雑多になることなくロックンロールが生まれている。テキストとカバーの明度の高い蛍光イエローが書籍全体を浮かび上がらせ、写真の編集もスイングするかのようだ。ページを折り曲げることでカイエとして成立させ、綴じには目立つ糸が使われ、黄色から白いページへの移行が情け容赦なく写真の見開き上を横断する。このような本を作るというアクションは、アーティストの制作プロセスにおいて行われる絵画のアクションそのものだ。激しさと生っぽさ、そして正確さが同居している。`}</p>
    <hr></hr>
    <p>{`出版社： Jap Sam Books (Eleonoor Jap Sam), Prinsenbeek in association with Sieger White Stichting and Prins Bernhard Cultuurfonds`}<br parentName="p"></br>{`
`}{`デザイン： Team Thursday, Rotterdam`}</p>
    <p>{`カテゴリー： アート、写真、建築`}<br parentName="p"></br>{`
`}{`アーティスト：Ide André`}<br parentName="p"></br>{`
`}{`編集：Mirjam Westen`}<br parentName="p"></br>{`
`}{`言語：オランダ語、英語`}<br parentName="p"></br>{`
`}{`翻訳：Gerard Forde`}<br parentName="p"></br>{`
`}{`写真：Ide André, Peter Cox, Oliver Schönemann, William van de Voort, Stefan Voelker, Floris de Vries, COSAR HMT`}<br parentName="p"></br>{`
`}{`イメージ編集：Team Thursday, Ide André`}<br parentName="p"></br>{`
`}{`リトグラフ：BFC graphics and design (Bert van der Horst), Amersfoort`}<br parentName="p"></br>{`
`}{`印刷：Graphius Group, Gent (BE)`}<br parentName="p"></br>{`
`}{`製本：Graphius Group, Gent (BE)`}<br parentName="p"></br>{`
`}{`フォント：Neue Haas Grotesk Display (Linotype)`}<br parentName="p"></br>{`
`}{`サイズ（ミリ）：210 x 297 x 9`}<br parentName="p"></br>{`
`}{`ページ数：100`}<br parentName="p"></br>{`
`}{`発行部数：750`}<br parentName="p"></br>{`
`}{`価格：€ 20`}<br parentName="p"></br>{`
`}{`ISBN：978 94 92852 15 1`}<br parentName="p"></br>{`
`}{`本文用紙：100gsm Lessebo Design 1.3 White (Igepa)`}<br parentName="p"></br>{`
`}{`カバー：200gsm Lessebo Design 1.3 White (Igepa)`}<br parentName="p"></br>{`
`}{`製本方法：中ミシン綴じ`}<br parentName="p"></br>{`
`}{`特記事項：黒糸綴じ。小口未断裁、中身は 100 ページ、カバーは 12 ページで構成、CMYK、Pantone803、8003 で印刷。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      