import {
  WebGLRenderer,
  PerspectiveCamera,
  OrthographicCamera,
  Clock,
  Raycaster,
  Scene,
  WebGLRenderTarget,
} from "three"
import CameraControls from "../vendor/camera-controls"
import type { SceneObject } from "../types"
import { MoveDir } from "../Game"

export abstract class World {
  protected enabled = false
  public abstract readonly camera: PerspectiveCamera | OrthographicCamera
  public abstract readonly renderTarget: WebGLRenderTarget
  protected abstract cameraControls: CameraControls
  protected raycaster = new Raycaster()
  protected clock = new Clock()

  protected constructor(
    public readonly id: number,
    protected canvas: HTMLCanvasElement,
    public readonly scene: Scene,
    protected objects: SceneObject[]
  ) {}

  protected abstract initCamera(): PerspectiveCamera | OrthographicCamera

  protected abstract initCameraControls(): CameraControls

  abstract resize(width: number, height: number): void
  abstract resetCameraCenter(): void
  abstract onClick(x: number, y: number): void
  abstract onMouseMove(x: number, y: number): void
  abstract loop(): void

  reset() {}

  dispose() {
    for (const o of this.objects) {
      o.dispose()
    }
  }

  async start(): Promise<void> {}

  render(renderer: WebGLRenderer): void {
    if (!this.enabled) {
      return
    }
    renderer.render(this.scene, this.camera)
  }

  isEnabled(): boolean {
    return this.enabled
  }

  setEnabled(isEnabled: boolean): void {
    this.enabled = isEnabled
    this.cameraControls.enabled = isEnabled
  }

  escape(): void {}

  abstract setMoving(dir: MoveDir, isMoving: boolean): void
  abstract resetMoving(): void
  abstract move(deltaTime: number): void

  async enterWorld(prevWorldId: number): Promise<void> {}
  async leaveWorld(nextWorldId: number): Promise<void> {}
}
