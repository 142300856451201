import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`『Were it not for』はテキストとイメージを用いた残酷な詩だ。モハベ砂漠で人々が進める建設や開発、あるいはそれらの打ち捨てられた残骸の 218 ものモノクロ写真と、813 の「Were it not for ...」で始まる文章から構成されている。テキストとイメージの組み合わせはランダムだが、効果的だ。本書は、1990 年代以降ネットワーク技術とネオリベラル主義が組み合わさり推進されてきた、すべては可能だという「カリフォルニアン・イデオロギー」の結果を記録したものとも言える。アシュキ自身の言葉を借りれば、「テキストと写真は、私たちの世界で『カリフォルニアン・イデオロギー』が何を生み出したかを教えてくれる。『すべては可能』という状況は、それとは全く逆の結果をもたらした。」テキスト列と写真は同じフォーマットで配置され、ミニマルに連続し、クラフトボードのカバーの力強い簡潔さ、そして折り返しのないペーパーバックフォーマットなど、全体を通してシンプルさが際立つ。トリプルトーン（スミ 1 版と特色グレーの 2 版、計 3 版を用いて印刷すること。モノクロ写真などを階調豊かに表現できる）を使ったリトグラフィと印刷が、無慈悲な写真に深みと仔細さ、そしてニュアンスを与えている。裏表紙のイメージは不透明の白を重ねることで茶色のクラフトボードから浮かび上がっている。正確で効果的な技術の好例で、緊迫したイメージのシークエンスや刺激的かつ不穏なナラティヴも相まって、抜きん出た存在感を持っている。`}</p>
    <hr></hr>
    <p>{`出版社： Fw:Books, Amsterdam`}<br parentName="p"></br>{`
`}{`デザイン： Hans Gremmen, Amsterdam`}</p>
    <p>{`カテゴリー： アート、写真、建築`}<br parentName="p"></br>{`
`}{`編集：Hans Gremmen, Michael Ashkin`}<br parentName="p"></br>{`
`}{`言語：英語`}<br parentName="p"></br>{`
`}{`写真：Michael Ashkin`}<br parentName="p"></br>{`
`}{`イメージ編集：Hans Gremmen, Michael Ashkin`}<br parentName="p"></br>{`
`}{`印刷：Wilco Art Books, Amersfoort`}<br parentName="p"></br>{`
`}{`製本：Wilco Art Books, Amersfoort`}<br parentName="p"></br>{`
`}{`フォント：Highway Gothic Expanded, series F 2000 (FHWA)`}<br parentName="p"></br>{`
`}{`サイズ（ミリ）：215 x 280 x 19`}<br parentName="p"></br>{`
`}{`ページ数：256`}<br parentName="p"></br>{`
`}{`発行部数：1,000`}<br parentName="p"></br>{`
`}{`価格：€ 35`}<br parentName="p"></br>{`
`}{`ISBN：978 94 90119 80 5`}<br parentName="p"></br>{`
`}{`本文用紙：130gsm Lessebo Design Smooth 1.2 Natural (Igepa)`}<br parentName="p"></br>{`
`}{`カバー：280gsm Kraftliner (Koninklijke Moorman Karton)`}<br parentName="p"></br>{`
`}{`製本方法：糸かがりソフトカバー`}<br parentName="p"></br>{`
`}{`特記事項：黒、Pantone ウォームグレー 8 と Pantone 黒の３色で印刷。裏表紙の画像は白で印刷した後、同じモノを黒で刷り込み。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      