import React, { useState } from "react"
import Layout from "../layouts/en"
import { Link } from "gatsby"
import Img from "gatsby-image"
import css from "./DutchArtistBook.module.scss"
import css2 from "./ExhibitorTemplate.module.scss"
import ImageSlider from "../components/ImageSlider"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { nl2br } from "../utils/nl2br"

export default function DabPost(props) {
  const [activeModalType, setActiveModalType] = useState(null) // JSONデータ

  const frontmatter = props.data.mdx.frontmatter

  const title = frontmatter.title
  const description = frontmatter.description
  const author = frontmatter.author
  const profile = frontmatter.profile

  const langDir = props.locale === `en` ? "/en" : ""

  // モーダルをひらく
  const modalOpen = (index, type) => {
    document.body.style.overflow = "hidden"
    setActiveModalType({
      type: type,
      index: index,
    })
  }

  const modalClose = (index, type) => {
    document.body.style.overflow = "auto"
    setActiveModalType(null)
  }

  // モーダルの中身を生成
  const getModalContent = object_ => {
    const type = object_.type
    const index = object_.index

    const slideContent = frontmatter[type][index].images.map((item, index) => (
      <div key={index}>
        <Img fluid={item.childImageSharp.fluid} />
      </div>
    ))

    const content = (
      <div>
        <header className={css2.modal__header}>
          <h1>{nl2br(frontmatter[type][index].title)}</h1>
        </header>

        <ImageSlider>{slideContent}</ImageSlider>
      </div>
    )

    return content
  }

  // メインのスライド
  const listOfMainImages = frontmatter.images
  const mainSlideItems = listOfMainImages.map((image, index) => (
    <div key={index}>
      <Img fluid={image.childImageSharp.fluid} />
    </div>
  ))

  // 影響を受けた本のリスト
  const listOfImpressed = frontmatter.impressed.map((impressedItem, index) => (
    <div key={index} className={css.dab__items__item}>
      <button
        onClick={() => {
          modalOpen(index, "impressed")
        }}
        className={css.dab__items__item__link + ` button-reset`}
      >
        <Img
          className={css.dab__items__item__img}
          fluid={impressedItem.thumbnail.childImageSharp.fluid}
        />
        <h3 className={css.dab__items__item__title}>
          {nl2br(impressedItem.title)}
        </h3>
        {/* <p className={css.dab__items__item__description}>
          {impressedItem.description}
        </p> */}
      </button>
    </div>
  ))

  // 制作した本のリスト
  const listOfCreated = frontmatter.created.map((createdItem, index) => {
    return (
      <div key={index} className={css.dab__items__item}>
        <button
          onClick={() => {
            modalOpen(index, "created")
          }}
          className={css.dab__items__item__link + ` button-reset`}
        >
          <Img
            className={css.dab__items__item__img}
            fluid={createdItem.thumbnail.childImageSharp.fluid}
          />
          <h3 className={css.dab__items__item__title}>
            {nl2br(createdItem.title)}
          </h3>
        </button>
      </div>
    )
  })

  return (
    <Layout
      location={props.location}
      title={title}
      description={description}
      ogImage={frontmatter.ogImage}
    >
      {activeModalType && (
        <div className={`vabfModal__Content`}>
          <div className={css.modal + ` ` + css2.modal}>
            <div className={css2.modal__close + ` back__wrap`}>
              <button
                onClick={() => {
                  // setActiveModalType(null)
                  modalClose()
                }}
              >
                <span className={`u-reader`}>Close Modal</span>
              </button>
            </div>

            <div
              className={css2.modal__wrap}
              style={{ width: `100%`, maxWidth: `1024px`, margin: `0 auto` }}
            >
              {getModalContent(activeModalType)}
            </div>
          </div>
        </div>
      )}

      <section className={`container`}>
        <div className={`content`}>
          <div className={`content__head`}>
            <h1 className={`haeding__lev1`}>
              DUTCH ARTISTS’ BOOK: <br className={`only-sp`} />
              THEN AND NOW
            </h1>
          </div>

          <article className={css.dab__main + ` content__2col`}>
            <div className={`content__2col__side`}>
              <div className={`content__back`}>
                <Link
                  to={`${langDir}/en/exhibitions/dutch-artist-book/`}
                  className={`link__back`}
                >
                  BACK
                </Link>
              </div>
            </div>
            {/* /.content__side */}

            <div className={`editor__content content__2col__main`}>
              {frontmatter.youtubeId && (
                <div className={css.dab__yt + ` youtube__wrap`}>
                  <iframe
                    title="dab player"
                    width="560"
                    height="315"
                    src={`https://www.youtube.com/embed/${frontmatter.youtubeId}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              )}

              <div className={css.dab__profile}>
                <h2>{author}</h2>
                <p>{profile}</p>
              </div>

              <hr />
              <ImageSlider>{mainSlideItems}</ImageSlider>
              {frontmatter.photoby && (
                <p className={`font__small align__right`}>
                  {nl2br(frontmatter.photoby)}
                </p>
              )}
              <hr />

              <MDXRenderer>{props.data.mdx.body}</MDXRenderer>
            </div>
            {/* /.content__main */}
          </article>

          <article className={css.dab__books}>
            <h2>Inspired Books</h2>
            <div className={css.dab__items}>{listOfImpressed}</div>
          </article>

          <article className={css.dab__books}>
            <h2>Works</h2>
            <div className={css.dab__items}>{listOfCreated}</div>
          </article>
        </div>
        {/* /.content */}
      </section>
      {/* /.container */}
    </Layout>
  )
}

// gatsby-node.js の createPage から受け取った slag で絞り込んでクエリを生成
// https://www.gatsbyjs.com/plugins/gatsby-plugin-mdx/#mdxrenderer
export const query = graphql`
  query($slug: String!) {
    mdx(slug: { eq: $slug }) {
      body
      frontmatter {
        title
        description
        author
        profile
        youtubeId
        photoby
        ogImage
        images {
          id
          childImageSharp {
            fluid(maxWidth: 1280) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        impressed {
          description
          images {
            childImageSharp {
              fluid(maxWidth: 1280) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          thumbnail {
            id
            childImageSharp {
              fluid(maxWidth: 1280) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title
        }

        created {
          description
          images {
            childImageSharp {
              fluid(maxWidth: 1280) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          thumbnail {
            id
            childImageSharp {
              fluid(maxWidth: 1280) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title
        }
      }
    }
  }
`
