import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { Mesh, Object3D, Scene, Texture, TextureLoader } from "three"
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader"

export class Loader {
  static gltfLoader = new GLTFLoader()
  static textureLoader = new TextureLoader()

  static gltfCache = new Map<string, Promise<Scene>>()
  static textureCache = new Map<string, Texture>()

  static init() {
    // DRACOローダーを事前に初期化
    const dracoLoader = new DRACOLoader()
    dracoLoader.setDecoderPath("/scripts/draco/")
    dracoLoader.preload()
    Loader.gltfLoader.setDRACOLoader(dracoLoader)
  }

  static async loadGLTF(
    _url: string,
    traverse?: (obj: Object3D) => void
  ): Promise<Scene> {
    const url = `/models_compressed/${_url}`

    const cached = this.gltfCache.get(url)
    if (cached) {
      return (await cached).clone()
    }

    const gltfPromise = this.gltfLoader.loadAsync(url).then(t => t.scene)
    this.gltfCache.set(url, gltfPromise)

    const gltf = await gltfPromise
    if (traverse) {
      ;(gltf as Object3D).traverse(traverse)
    }
    return gltf.clone()
  }

  static loadTexture(path: string, useCache: boolean = true): Texture {
    const cached = this.textureCache.get(name)
    if (cached && useCache) {
      return cached
    }

    const texture = this.textureLoader.load(path)
    if (useCache) {
      this.textureCache.set(path, texture)
    }
    return texture
  }
}
