import React from "react"
import DefaultPageLayout from "./DefaultPageLayout"
import DefaultPageLayoutEn from "./DefaultPageLayout.en"
export default props => {
  return (
    <>
      {props.pageContext.langKey ==="ja"?(
        <DefaultPageLayout {...props} />
      ):(
        <DefaultPageLayoutEn {...props} />
      )}
    </>
  )
}
