import React from "react"
import Layout from "../../../layouts/en"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import css from "./index.module.scss"

const IndexPage = props => {
  let posts = []
  for (let i = 0; i < props.data.allMdx.edges.length; i += 1) {
    let thePost = props.data.allMdx.edges[i].node

    posts.push(
      <li key={i} className={css.dab__items__item}>
        <Link to={`/${thePost.slug}`} className={css.dab__items__item__link}>
          <Img
            className={css.dab__items__item__img}
            fluid={thePost.frontmatter.thumbnail.childImageSharp.fluid}
          />
          <h2 className={css.dab__items__item__title}>
            {thePost.frontmatter.author}
          </h2>
          <p className={css.dab__items__item__description}>
            {thePost.frontmatter.description}
          </p>
        </Link>
      </li>
    )
  }

  return (
    <Layout location={props.location} title={`Dutch Artists Book Exhibition`}>
      <section className={`container`}>
        <div className={css.dab__content + ` content`}>
          <div className={css.dab__head + ` content__head`}>
            <h1 className={`haeding__lev1`}>
              DUTCH ARTISTS’ BOOK: <br className={`only-sp`} />
              THEN AND NOW
            </h1>
          </div>

          <div className={css.dab__read}>
            <p>
              This is the Dutch edition of “Japanese Artists’ Book Then and
              Now,” a 2019 title that explores the evolution of art books in
              Japan through the words of creators. This year’s event will
              showcase interview videos with leaders of modern Dutch publishing
              culture on how they first discovered the print media scene, their
              raw experiences and how those experiences led to their current
              work, and the various print media that inspired them. Interviewees
              will share their personal experiences as they discuss the path of
              Dutch publishing’s extraordinary progress, the relationship
              between artists, designers, and bookstores, the unique ideas that
              the platform of print media makes possible, as well as the role of
              creativity at the height of today’s COVID crisis.
            </p>
          </div>

          <ul className={css.dab__items}>{posts}</ul>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
export const posts = graphql`
  query {
    allMdx(
      filter: { slug: { regex: "/^en/.+dutch-artist-book/" } }
      sort: { fields: slug }
    ) {
      edges {
        node {
          id
          slug
          frontmatter {
            title
            author
            description
            thumbnail {
              id
              childImageSharp {
                fluid(maxWidth: 1280) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          excerpt
        }
      }
    }
  }
`
