import React, { Component } from "react"

import Layout from "../../../layouts/ja"
import { Link } from "gatsby"
import css from "./index.module.scss"
import { Helmet } from "react-helmet"
import ReactPlayer from "react-player"

class IndexPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentTab: ``,
      open: false,
    }

    this._anchorClicked = this._anchorClicked.bind(this)
  }

  _anchorClicked(e) {
    const href = e.target.getAttribute(`href`)
    const targetId = href.replace(/#/g, "")

    if (this.state.currentTab !== targetId) {
      this.setState(state => ({
        currentTab: targetId,
        open: true,
      }))
    } else {
      if (this.state.open) {
        this.setState(state => ({
          currentTab: "",
          open: false,
        }))
      } else {
        this.setState(state => ({
          currentTab: targetId,
          open: true,
        }))
      }
    }

    e.preventDefault()
    return false
  }

  render() {
    return (
      <Layout
        location={this.props.location}
        title={`Fanfre Exhibition`}
        invertColor={true}
      >
        <Helmet
          bodyAttributes={{
            class: "fanfare",
          }}
        />

        {this.state.currentTab === `content1` && (
          <div className={css.mp3Player}>
            <ReactPlayer
              url="https://vabf-production.s3-ap-northeast-1.amazonaws.com/storage/fanfare/unlearning.mp3"
              playing={true}
            />
          </div>
        )}
        {this.state.currentTab === `content2` && (
          <div className={css.mp3Player}>
            <ReactPlayer
              url="https://vabf-production.s3-ap-northeast-1.amazonaws.com/storage/fanfare/display.mp3"
              playing={true}
            />
          </div>
        )}
        {this.state.currentTab === `content3` && (
          <div className={css.mp3Player}>
            <ReactPlayer
              url="https://vabf-production.s3-ap-northeast-1.amazonaws.com/storage/fanfare/connecting.mp3"
              playing={true}
            />
          </div>
        )}

        <section className={css.fanfare__container + ` container`} id={`top`}>
          <div className={`content`}>
            <div className={css.fanfare__head + ` content__head`}>
              <h1 className={css.kotebakura1 + ` haeding__lev1`}>fanfare</h1>
            </div>

            <div className={css.fanfare__read}>
              <p>
                グラフィックデザインの分野には、私たちが日常生活を送るためのコースへ誘導し、そのことを語り、そして指示するための強力なツールが含まれている。これはグラフィックデザインを仲介人、つまりモチベーションやセクター、そして社会的なグループの橋渡しをするためのキーストーンとみなしているということである。このような考察は、企業の革新と技術者支配主義の発展の領域を模倣するようになる一方で、批判的なグラフィックデザインの語彙の重要性を反映している。
                <br />
                このことを念頭に置いて、私達はコミュニケーションにおける解決策を形成し、誘発し、そして寄生する方法をあえて探ろうとするグラフィックデザインのプロジェクトに焦点を合わせた。
              </p>
            </div>

            <nav className={css.fanfare__navi + ` only-pc`}>
              <div className={css.fanfare__navi__unit}>
                <p className={css.fanfare__navi__unit__title}>UNLEARN</p>
                <figure className={css.fanfare__navi__unit__img}>
                  <img src="/images/fanfare/icon1.png" alt="" />
                </figure>
                <a
                  href={`/images/fanfare/Unlearn.pdf`}
                  target={`_blank`}
                  className={
                    css.fanfare__navi__unit__pdf + ` ` + css.fanfare__btn
                  }
                >
                  SCRIPT
                </a>
                <a
                  role={`button`}
                  href={`#content1`}
                  onClick={this._anchorClicked}
                  className={css.fanfare__navi__unit__play}
                >
                  {this.state.currentTab === "content1" && this.state.open
                    ? `<`
                    : `>`}
                </a>
                <p className={css.fanfare__navi__unit__time}>(01:16:07)</p>
              </div>

              <div className={css.fanfare__navi__unit}>
                <p className={css.fanfare__navi__unit__title}>DISPLAY</p>
                <figure className={css.fanfare__navi__unit__img}>
                  <img src="/images/fanfare/icon2.png" alt="" />
                </figure>
                <a
                  href={`/images/fanfare/Display.pdf`}
                  target={`_blank`}
                  className={
                    css.fanfare__navi__unit__pdf + ` ` + css.fanfare__btn
                  }
                >
                  SCRIPT
                </a>
                <a
                  role={`button`}
                  href={`#content2`}
                  onClick={this._anchorClicked}
                  className={css.fanfare__navi__unit__play}
                >
                  {this.state.currentTab === "content2" && this.state.open
                    ? `<`
                    : `>`}
                </a>
                <p className={css.fanfare__navi__unit__time}>(01:19:58)</p>
              </div>

              <div className={css.fanfare__navi__unit}>
                <p className={css.fanfare__navi__unit__title}>CONNECT</p>
                <figure className={css.fanfare__navi__unit__img}>
                  <img src="/images/fanfare/icon3.png" alt="" />
                </figure>
                <a
                  href={`/images/fanfare/Connect.pdf`}
                  target={`_blank`}
                  className={
                    css.fanfare__navi__unit__pdf + ` ` + css.fanfare__btn
                  }
                >
                  SCRIPT
                </a>
                <a
                  role={`button`}
                  href={`#content3`}
                  onClick={this._anchorClicked}
                  className={css.fanfare__navi__unit__play}
                >
                  {this.state.currentTab === "content3" && this.state.open
                    ? `<`
                    : `>`}
                </a>
                <p className={css.fanfare__navi__unit__time}>(01:26:51)</p>
              </div>
            </nav>

            <nav className={css.fanfare__navi + ` only-sp`}>
              <div className={css.fanfare__navi__unit}>
                <p className={css.fanfare__navi__unit__title}>UNLEARN</p>
                <figure className={css.fanfare__navi__unit__img}>
                  <img src="/images/fanfare/icon1.png" alt="" />
                </figure>
                <a
                  href={`/images/fanfare/Unlearn.pdf`}
                  target={`_blank`}
                  className={
                    css.fanfare__navi__unit__pdf + ` ` + css.fanfare__btn
                  }
                >
                  SCRIPT
                </a>
                <a
                  role={`button`}
                  href={`#content1`}
                  onClick={this._anchorClicked}
                  className={css.fanfare__navi__unit__play}
                >
                  {this.state.currentTab === "content1" && this.state.open
                    ? `<`
                    : `>`}
                </a>
                <p className={css.fanfare__navi__unit__time}>(01:16:07)</p>
              </div>
            </nav>
            <article
              className={css.fanfare__article}
              id={`content1`}
              style={
                this.state.currentTab !== "content1"
                  ? { display: `none` }
                  : { display: `block` }
              }
            >
              <div className={`content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h1 className={css.kotebakura1 + ` haeding__lev2`}>
                      UNLEARN
                    </h1>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    教育の責任、学習状況、ポジショニングスキル、国際的な影響力。
                  </p>
                  <p>
                    学ぶことの焦点は、知識を伝達し広めたいという欲求によって動かされるデザインの自発性を観察することによって得られる。
                    <br />
                    この代替可能な教育構造の中に見られる経験的な学習曲線および、活動的で遊び心があり、反射的な方法での学習へのアプローチするための同期が注目の中心である。
                  </p>
                  <p>
                    <a
                      href={`https://soundcloud.com/user-48803956/unlearning`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      音声をSoundCloudで開く
                    </a>
                  </p>
                </div>
              </div>

              <div className={css.fanfare__member + ` content`}>
                <div className={`editor__content`}>
                  <h2>
                    FANFARE, フレーヤ・キル (FK)
                    <br />
                    JA JA JA NEE NEE NEE, フェムケ・デッケル (FD)
                    <br />
                    GERRIT RIETVELD ACADEMIE, デイビット・ベネヴィス (DB)
                    <br />
                    SANDBERG INSTITUUT, ユルゲン・ベイ (JB)
                    <br />
                    ROYAL ACADEMY OF ART THE HAGUE, ローシェ・クラップ (RK)
                  </h2>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>Freja Kir（以下FK）</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    皆さんに共通するのは、それぞれが何らかの形でプログラムや教育施設のディレクターをされているということですね。そういう立場にはもちろん、学生がそこから何を得るのかという責任が伴います。ヘリット・リートフェルト・アカデミーのグラフィックデザイン科が去年、Graphic
                    Magazineに寄稿した文章で、あなたは冒頭で学生の言葉を引用していますね。
                  </p>
                  <p>「自分の無知と知識の欠如に罪悪感を感じる」</p>
                  <p>
                    この言葉は深く印象に残った、学生としてだけでなく教育者としても心に留めておくのにふさわしいとあなたは書いています。1，2年前なので心境も変わっているかもしれませんが、この言葉を足掛かりにデザイン教育プログラムのディレクターとしての責任についての意見を交換するのはどうだろうと思いました。
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>
                      David Bennewith（以下DB）
                    </h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    ユニー・チェ（Yunie
                    Chae）という学生が批評として提出したポスターにあった言葉です。チェは本当に素晴らしい作品を作る作家の一人でもあります。教育の場で、ポスターという表現形式をあんなにも個人的に使ってみせたことに深く心を動かされました。また、責任についてですが…もちろん、私たちには教える責任があります。でもその責任は連帯責任であり、学生にも学ぶ責任があると思います。そういう意味で、学校は交流が起こる場だといえるでしょう。
                    <br />
                    ただ、経験から言うと、最近の学生は自分が学校に何を求めているか、そして何が提供されているかをより意識していると思います。そこには二つの側面があります。ひとつは消費者的側面―最も面白くない部分かもしれませんが。そして、もうひとつは学校が自分に何を与えてくれるかについての気づき、理解、もしくはプレッシャーです。
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>FK</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    学生が批判的思考を持つことの重要性についてですが…様々な学校（KABK、Sandberg
                    Instituut、ヘリット・リートフェルト・アカデミー）のプログラム概要を読んでいると、学生をクリティカル・シンカーとして育て、自分の分野での立ち位置を見つけることを教えることがどこの学校でも重視されているポイントのように思えます。
                    <br />
                    それを踏まえてもう少し話を広げますが、この流れはある意味でオランダのデザイン教育のDNAを表していると思いますか？もしくは、他にオランダの教育制度を構成する要素として思いつくものがあったら教えてください。
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>Roosje Klap（以下RK）</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    そうですね、私はそういう意味での「オランダの教育制度」には猛烈に反対しているところがあります。私たちは国際的なコミュニティだと考えていますし、個々の教育機関の選択が独自の責任につながると強く感じているからです。この場合は、３つの学校は似通った方針だったかもしれません。でも、フローニンゲンやロッテルダムの学校はまったく違ってスキルや技能により重点を置いているかもしれませんし、それも同じように素晴らしいことです。どちらがいい、悪いということはないのです。ただそれを意識的に選択したというだけです。
                    <br />
                    私たちの学校はハーグにあるので、周りには政府の機関ばかりです。ほんの10歩歩けば教育省に着きますし、経済部もすぐそこです。これらの機関と協力してどのように変化を促進できるかよく話し合います。たとえば、おそらく今、私たちが必要としている社会のパラダイムシフトの一部に教育も含むことができるだろうか？そこに私たちはどうかかわっていくか、何かできることはあるだろうか？といった話です。そして、さっきのデイヴィッドの話にあった知識の欠如に対する罪悪感に戻ると、私にとって興味深く重要なのは、共に学ぶこと、指導者として隣を歩いていくことだと思います。ユルゲンと私が学生だった90年代から比べると、これは非常に大きな変化です。その頃は指導者は何でも知っていると考えられていました。そして指導する立場の皆さんはご存じのように、私たちは何でも知っているわけではないし―それでいいと思います！一緒に問いかけをして、一緒に答えを見つけていけばいいのです。
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>Femke Dekker（以下FD）</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    …とはいえ、それはとてもオランダらしい考え方だと言わずにはいられません。それに、とても近代的です。つまり、私たちはみんなこのオランダというシステムの一部であり、多くの学生、特にグラフィックデザインの学部生はこれが「オランダのデザイン」だと考えているものを学びたいと思ってオランダに来ているように見えます。ただ、アウトプットに関してはその限りではなさそうです。ローシェ、あなたの考え方は教育をどのように扱うかについての非常に明確な意見で、教育とはどうあるべきかの理念をはっきりと打ち出しているように感じます。私にとっては、これはとてもオランダ的なアプローチです。デイヴィッド、あなたは違う意見がありそうですね。
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>DB</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    面白いことに、ちょうど今朝考えていたんです…。海外からヨーロッパ、もっといえばオランダにやってきて、私にとって一番印象的だったのは、今もですが、歴史を通して様々なデザインの分野が社会の骨組みに組み込まれていることです。グラフィック、プロダクトデザイン、都市デザイン、そのすべてが対話の一部となっていて、しかも重要性に優劣があるわけでもなく、建物の看板をつくるのを忘れていた時みたいに土壇場で何とかしないといけないという状況でもない。オランダのデザインはより暮らしの一部として溶け込んでいて、それぞれの分野が同じだけの価値があり、互いに効果を引き出し合えるという認識のように、対話的で共有されたものなのだと気がつきました。文化的な歴史が比較的浅い国から来た自分には、素晴らしいことに感じられました。オランダを訪れて街を歩くと、自分が景色の一部になるだけでなく、街並みを読むことができるんです。
                  </p>
                </div>
              </div>

              <div className={css.fanfare__note + ` content`}>
                <div className={`editor__content`}>
                  <p className={`font__small`}>
                    このテキストは、TOKYO ART BOOK FAIR
                    2020のfanfareのキュレーションの一部として記録・発表された対談から抜粋したものです。
                    <br />
                    脚注の画像は関連作品を集めたものと対談中に言及のあった参考文献を示しています。
                    <br />
                    対談の全編は英語で参照することができ、VABFの開催期間中に公開されています。また、fanfareに資料請求することも可能です。
                  </p>
                </div>
              </div>

              <div className={css.fanfare__vfn + ` content`}>
                <h2 className={`haeding__lev2`}>VISUAL FOOTNOTES</h2>

                <div className={css.fanfare__vfn__imgs}>
                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a
                      href={`https://takeaway.rietveldacademie.nl/`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/unlearn_1.png" alt="" />
                      <figcaption>
                        TAkEAwAY, Gerrit RIetveld Academie, Graphic Design
                        Department
                      </figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a href={`http://pub.sandberg.nl`} target={`_blank`}>
                      <img src="/images/fanfare/unlearn_2.png" alt="" />
                      <figcaption>PUB.sandberg.nl</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w50}>
                    <a
                      href={`https://rietveldacademie.nl/nl/search?qs=rietveld+journal+#eyJxcyI6InJpZXR2ZWxkIGpvdXJuYWwgIiwidHlwZSI6Imxpc3QiLCJmYWNldHMiOnt9fQ==`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/unlearn_3.png" alt="" />
                    </a>
                    <figcaption>
                      <a
                        href={`https://rietveldacademie.nl/nl/search?qs=rietveld+journal+#eyJxcyI6InJpZXR2ZWxkIGpvdXJuYWwgIiwidHlwZSI6Imxpc3QiLCJmYWNldHMiOnt9fQ==`}
                        target={`_blank`}
                      >
                        Rietveld Journal
                      </a>
                      <br />
                      <span className={`ig`}>
                        <a
                          href={`https://www.instagram.com/rietveldjournal/?hl=en`}
                          target={`_blank`}
                        >
                          Instagram
                        </a>
                      </span>
                    </figcaption>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w50}>
                    <a
                      href={`https://radio.rietveldacademie.nl/`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/unlearn_4.png" alt="" />
                      <figcaption>Radio Rietveld</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a
                      href={`http://graphicmag.kr/index.php?/issues/42-gerrit-rietveld-academie/`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/unlearn_5.png" alt="" />
                      <figcaption>
                        Graphic Magazine #42 GERRIT RIETVELD ACADEMIE
                      </figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a
                      href={`https://rietveldacademie.nl/en/page/5333/see-also`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/unlearn_6.png" alt="" />
                      <figcaption>
                        *See also - An Alternative Graphic Design Lexicon,
                        Gerrit Rietveld Academie, Graphic Design Department
                      </figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w50}>
                    <a href={`https://studentcouncil.nl/`} target={`_blank`}>
                      <img src="/images/fanfare/unlearn_7.png" alt="" />
                      <figcaption>Student Council</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a
                      href={`https://www.mpi.nl/news/jerome-bruner-library-new-york-nijmegen`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/unlearn_8.png" alt="" />
                      <figcaption>The Jerome Bruner Library</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a
                      href={`https://www.instagram.com/kabk_garden/`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/unlearn_9.png" alt="" />
                      <figcaption>KABK Garden</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a
                      href={`https://www.instagram.com/mushroomradio/`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/unlearn_10.png" alt="" />
                      <figcaption>Mushroom Radio</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a
                      href={`https://www.instagram.com/kabkmemes/`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/unlearn_11.png" alt="" />
                      <figcaption>KABK memes</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w50}>
                    <a
                      href={`https://whospeaks.eu/symposium/`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/unlearn_12.png" alt="" />
                      <figcaption>‘WHO SPEAKS?’ symposium</figcaption>
                    </a>
                  </figure>
                </div>
              </div>
            </article>

            <nav className={css.fanfare__navi + ` only-sp`}>
              <div className={css.fanfare__navi__unit}>
                <p className={css.fanfare__navi__unit__title}>DISPLAY</p>
                <figure className={css.fanfare__navi__unit__img}>
                  <img src="/images/fanfare/icon2.png" alt="" />
                </figure>
                <a
                  href={`/images/fanfare/Display.pdf`}
                  target={`_blank`}
                  className={
                    css.fanfare__navi__unit__pdf + ` ` + css.fanfare__btn
                  }
                >
                  SCRIPT
                </a>
                <a
                  role={`button`}
                  href={`#content2`}
                  onClick={this._anchorClicked}
                  className={css.fanfare__navi__unit__play}
                >
                  {this.state.currentTab === "content2" && this.state.open
                    ? `<`
                    : `>`}
                </a>
                <p className={css.fanfare__navi__unit__time}>(01:19:58)</p>
              </div>
            </nav>
            <article
              className={css.fanfare__article}
              id={`content2`}
              style={
                this.state.currentTab !== "content2"
                  ? { display: `none` }
                  : { display: `block` }
              }
            >
              <div className={`content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h1 className={css.kotebakura1 + ` haeding__lev2`}>
                      DISPLAY
                    </h1>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    プラットフォームのハイジャック、訪問者のナビゲート、スペースの再所有、卒業制作のディスプレイ、聴衆の制限。
                  </p>
                  <p>
                    それをセレクション、キュレーション、編集作業、出版活動もしくは主催することと名付けるかどうかに関わらず、ディスプレイへの注目は、興味深いコンテンツの提示方法に光を当てている。ここでは、情報、ナビゲーション、空間を橋渡しするグラフィックデザインの中間的な役割に注目する。そしてオーディエンス、参加者、社会的相互作用の関連性を活性化させるプロジェクトを取り上げる。
                  </p>
                  <p>
                    <a
                      href={`https://soundcloud.com/user-48803956/display`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      音声をSoundCloudで開く
                    </a>
                  </p>
                </div>
              </div>

              <div className={css.fanfare__member + ` content`}>
                <div className={`editor__content`}>
                  <h2>
                    FANFARE, フレーヤ・キル (FK)
                    <br />
                    JA JA JA NEE NEE NEE, フェムケ・デッケル (FD)
                    <br />
                    KUNSTVEREIN, ヤナ・フォック (YF)
                    <br />
                    THE PALACE OF THE TYPOGRAPHIC MASONRY, リシャルト・ニセン
                    (RN)
                    <br />
                    COSMOS CARL, フレデリケ・ピッスイセ (FP)
                  </h2>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>Femke Dekker（以下FD）</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    私たちと同じような背景や業界を共有していない人達に向けてディスプレイという言葉を使う時、私たちは何について話をしているのでしょうか？また、オンラインのプラットフォームに対して、例えばフレデリック（コスモスカール）に代表されるように、物理体なディスプレイ空間を持つのかどうかに関しても違いがあると思います。
                    <br />
                    ※コスモスカールとは、オンライン上の表現空間。様々なアーティストやライターが自由にリンクを貼り、サイトに訪れた人はそれを自由に閲覧できる。
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>
                      Frederique Pissuise（以下FP）
                    </h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    そうですね、ディスプレイとは何でしょうか。私が思うに、コスモスカールのサイト上ではそれは主に作品の表現を指しています。作品自体はそこにありませんが、ディスプレイされたハイパーリンクを通して表現されています。ですから、作品一覧表のようである一方、必ずしもオンラインギャラリーのようではありません。コスモスカールのリンクを通して作品にたどり着いた場合のみ、それが芸術作品であるとわかるのです。一方で、作品に偶然めぐり逢う可能性もあります。例えば、アテネでAirbnbを無作為に探そうとすると、その家の中のひとつに私たちの作品が飾ってあります。このようにして作品に出会う方法とそれを体験する方法には違いがあります。このことはディスプレイの方法を変化させるものだと思います。
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>Yana Foque（以下YF）</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    そうですね。けれども、コスモスカールに載せる作品を選ぶとしても、それ自体が必ずしもアート作品とは限りませんよね？
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>FP</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    はい。アート作品はありますが、それ以外にもテキスト、楽曲、時にはキュレーターとの共同作業の結果であることもあります。通常は作品はコスモスカールのために制作されていますが、ときには既に知っているプロジェクトもあり、アーティストに協力を呼びかけてウェブサイトへの掲載をお願いしたりします。
                    <br />
                    けれども、私たちはそれを「ディスプレイしている」というよりは「開催している」ものとして考えています。なぜなら、どこで開催され、どこで発表されているかというように、これがディスプレィについての考え方としてより自然な言葉かもしれないからです。
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>Freja Kir（以下FK）</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    それは面白い考え方ですね。なぜなら、それぞれのウェブサイトで共通しているように見えるものが、自分のプラットフォームで共有している作品のディスプレイや発表について、一種の遊び心のある認識や挑戦を伴っているからです。一方でそれはキュレーターの仕事に極めて近くなっています。ディスプレイとキュレーション、これらふたつの言葉を全く別物だと思いますか？それとも一つのまとまりだと思いますか？
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>
                      Richard Niessen（以下RN）
                    </h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    私はそのことについて考えたことはありません。でも私が想像するに、あなたは真っ先にそのことを考えるでしょう？もしあなたが誰かに共有したいと思うようなストーリーを作ったり、何かを収集したいなら、それらをどのように組み合わせるかまた、どのように物語を作り上げるのかを検討する必要があると思います。そして、私にとってそれは実際にグラフィックデザインをすることです。それができないならば、とても難しい状況になります。
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>FK</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    そうですね、これは編集や出版に近いものだと感じています。
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>YF</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    私が思うにグラフィックデザインをやってきた私たち皆にとって、それほど気の遠くなるような言葉ではないのは「編集」ではないでしょうか。「キュレーター」という言葉は少し派手すぎると感じてしまいます。
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>FP</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>私は「セレクター」という表現が好きです...</p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>RN</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    さて、私たちは「オランダ的」なことについて話し、また、教育についても話してきました。そして私たちは、ヘリット・リートフェルト・アカデミーの卒業制作は、毎年、他の美術学校と比較するべきディスプレイであるという話になりました。例えば、かつてそこにはオフィスがありましたが、今はフロアラインの構造になっています。このことによって学生は自分の作品が展示されるということを具体的に想像することができ、それがやる気につながるのです。一方では、誰が一番良い展示スペースを手にするのかという競争もあるので、そのような卒業制作発表があるということによって、それに向けて遊び心に満ちた取り組みができるのです。そしてこのことは、どのように自身の制作を続けていくかということにも非常に大きく影響しています。
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>FK</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    私がもう一つ思いついたのは、Werkplaats
                    Typografieの卒業発表です。
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>RN</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    そうですね、彼らも同じような精神を持っていると思います。
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>FK</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    どちらも周囲の環境、設定、コンセプトにおいて同じであると言えますね。
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>YF</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>…そしてメディア。</p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>FK</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    そう！メディアはすべてが映画になっているところや、理想的なシナリオが実際には物理的に何も表示されていないところを示しています。
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>YF</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    …そして今年、それぞれの学生が個人の卒業発表会を行う予定です。Werkplaatsは常に協力的かつ集合的な場所なので、これはすばらしいことだと思います。初代はカレル・マルテンス、現在はアーマンド・メーフィスなど、Werkplaatsを運営している人々が常に「やってみよう。結果がどうなるか見てみよう！」という姿勢を持っていることがとても興味深いと思っています。そして私は、卒業発表がとても小規模になることに関して興味深いと感じています。
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>FK</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    しかし、これらのグラフィックデザインの卒業シ発表は、ディスプレイシステムへの実験的なアプローチを本当に反映しているのかもしれません。そしてこれはまた、おそらくグラフィックデザインのDNAのようなものも反映しています。それはこれらすべての要素となっている媒体やコンテクストを完成作品の一部と見なすためでしょうか？
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>YF</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    それはオランダのデザインに関わることだと思います...
                    そうですね、私の推測では、オランダのデザインは時代をさかのぼって、バウハウス運動も少し取り入れているのではないでしょうか。
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>FK</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    はい、それはテキスト、イメージ、空間、パフォーマンス、大衆の方向性でもあるので、これらすべての要素が制作発表のディスプレイに考慮されています。
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>FP</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    私は実は、ディスプレイ構造が真新しく作られる時、いつも若干それに圧倒されていることに気がつきます。そしてまた、このことがコスモスカールに対する私の考え方や冒頭で自然環境についてお話したこと—
                    すべてのデザインの要素には、どこかにその起源がある—
                    に私を引き戻すと思っています。そして、その要素を完全に取り除き、それをこの人工的な新しいデザイン構造の中に置いた時…
                    私は常に不思議に思っています。
                    <br />
                    このデザインを作った人はどのように仕事をしているのか、また、もっと気軽に言うならば、本当はどのようにディスプレイしてほしいのだろうかと。
                  </p>
                </div>
              </div>

              <div className={css.fanfare__note + ` content`}>
                <div className={`editor__content`}>
                  <p className={`font__small`}>
                    このテキストは、TOKYO ART BOOK FAIR
                    2020のfanfareのキュレーションの一部として記録・発表された対談から抜粋したものです。
                    <br />
                    脚注の画像は関連作品を集めたものと対談中に言及のあった参考文献を示しています。
                    <br />
                    対談の全編は英語で参照することができ、VABFの開催期間中に公開されています。また、fanfareに資料請求することも可能です。
                  </p>
                </div>
              </div>

              <div className={css.fanfare__vfn + ` content`}>
                <h2 className={`haeding__lev2`}>VISUAL FOOTNOTES</h2>

                <div className={css.fanfare__vfn__imgs}>
                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a
                      href={`https://www.ideabooks.nl/9789490629243-blood-the-poems-and-archive-of-r-broby-johansen`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/display1.png" alt="" />
                      <figcaption>Blood</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w50}>
                    <a
                      href={`http://dextersinister.org/MEDIA/PDF/University-of-Reading.pdf`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/display2.png" alt="" />
                      <figcaption>
                        WORK IN PROGRESS: FORM AS A WAY OF THINKING
                      </figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a href={`http://fanfareinc.world/`} target={`_blank`}>
                      <img src="/images/fanfare/display6.png" alt="" />
                      <figcaption>fanfare inc</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a
                      href={`http://www.centrenationaldugraphisme.fr/en/le-signe/exhibition/building-site-cinq-installations-de-richard-niessen`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/display3.png" alt="" />
                      <figcaption>Concours-international-daffiches</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a
                      href={`http://www.palaceoftypographicmasonry.nl/`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/display4.png" alt="" />
                      <figcaption>Palace of Typography Masonry</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w50}>
                    <a
                      href={`https://www.stedelijk.nl/en/collection/36964-gerrit-rietveld-rijksmuseum-vincent-van-gogh`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/display5.png" alt="" />
                      <figcaption>Rijksmuseum Vincent van Gogh</figcaption>
                    </a>
                  </figure>
                </div>
              </div>
            </article>

            <nav className={css.fanfare__navi + ` only-sp`}>
              <div className={css.fanfare__navi__unit}>
                <p className={css.fanfare__navi__unit__title}>CONNECT</p>
                <figure className={css.fanfare__navi__unit__img}>
                  <img src="/images/fanfare/icon2.png" alt="" />
                </figure>
                <a
                  href={`/images/fanfare/Connect.pdf`}
                  target={`_blank`}
                  className={
                    css.fanfare__navi__unit__pdf + ` ` + css.fanfare__btn
                  }
                >
                  SCRIPT
                </a>
                <a
                  href={`#content3`}
                  onClick={this._anchorClicked}
                  className={css.fanfare__navi__unit__play}
                >
                  {this.state.currentTab === "content3" && this.state.open
                    ? `<`
                    : `>`}
                </a>
                <p className={css.fanfare__navi__unit__time}>(01:26:51)</p>
              </div>
            </nav>
            <article
              className={css.fanfare__article}
              id={`content3`}
              style={
                this.state.currentTab !== "content3"
                  ? { display: `none` }
                  : { display: `block` }
              }
            >
              <div className={`content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h1 className={css.kotebakura1 + ` haeding__lev2`}>
                      CONNECT
                    </h1>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    再生産の寛容性、組織構造、探索の自由、民主的ビジネスとしての出版
                  </p>
                  <p>
                    「コネクト」に焦点を当てると、社会的な文脈の中で意見を表現したり、探求したり、顕在化させたりするためにツールやネットワークを使用して、共同でデザインを実践することために空間を使用していることがわかる。デザインの分野が社会的・技術的な発展に対応していく中で、私たちはグラフィックデザインの分野の共同性に注目している。様々な分野からの借用によって、これらのプロジェクトは、美学、目的、意味を共有システムに変換するためにグラフィックデザインのツールを使用している。
                  </p>
                  <p>
                    <a
                      href={`https://soundcloud.com/user-48803956/connecting`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      音声をSoundCloudで開く
                    </a>
                  </p>
                </div>
              </div>

              <div className={css.fanfare__member + ` content`}>
                <div className={`editor__content`}>
                  <h2>
                    FANFARE, フレーヤ・キル (FK)
                    <br />
                    JA JA JA NEE NEE NEE, フェムケ・デッケル (FD)
                    <br />
                    HACKERS & DESIGNERS,
                    アンヤ・フローテンとジュリエット・リゾッテ (AG AND JL)
                    <br />
                    ENTER ENTER/ROMA PUBLICATIONS,ロジャー・ウィレムス (RW)
                    <br />
                    NXS AND PARADYME, カロリン・ブールマン (KB)
                  </h2>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>Femke Dekker（以下FD）</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    皆さんは今それぞれの仕事をされていて、それはグラフィックデザインから一歩離れたもののようですね。それでも現在の仕事が皆さんのバックグラウンドから形作られているというのはどういうことか、聞かせていただけますか？
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>Anja Grooten（以下AG）</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    グラフィックデザインから一歩離れているとは思いません。むしろ強化とか拡張だと思っています。私は今もグラフィックデザインと繋がっているということを常に感じています。コミュニティやワークショップに参加する場合でも、自分はデザイナーであるという姿勢で参加しています。それが役立つことも多いですが、時には邪魔になることもあります。例えば、今芸術研究の博士号を取ろうとしているのですが、そこでは自分がどこに向かっているのかを知りたいという、デザイナーの決定論のようなものはあまり役に立ちません。そのことを非常に難しく感じますが、一方で楽しくもあります。
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>FD</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    ジュリエットさんはビジュアルアーティスト兼デザイナーであると名乗っておられますね。その二つは同じ仕事だと考えていますか？
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>
                      Juliette Lizotte（以下JL）
                    </h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    はい、自分の仕事という意味では、一緒だと考えています。でも、そうですね、プロジェクトによってはデザインの領域に寄ったものもあります。たとえば本、印刷物、ロゴなどがそうだと言えます。アートとデザインの境界がはっきりしないのが、オランダの良いところだと考えています。フリーランスでグラフィックデザイン的なことを自分のプロジェクトでやったり、Hackers
                    &
                    Designersと実験的な印刷物の仕事をしたりして、それをグラフィックデザインのプロジェクトだとか、頼まれた仕事だとか分類しなくて良いのはとても居心地が良いものです。
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>FD</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    ロジャーさん、プレゼンテーションのために使える場所があることについてどう考えていますか？
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>Roger Willems（以下RW）</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    そうですね、プロジェクトスペースは共同のプロジェクトで、私は運営者の一人にすぎません。私自身は今でもグラフィックデザイナーで、ほとんどの場合、出版用の本のデザインをしています。と言っても、ずいぶん広い意味になります。現在のように出版者であり、デザイナーであり、プロジェクトスペースの所有者という状況になったのは、ひとえに本が好きだから、そして完全に独立したいからです。デザインによって細部までつき詰めるのが好きなのですが、組織的な場や商業的な場ではそうはいきません。しかし同時にこの仕事で食べて行こうとしている訳ですから、非常に長期的なプロジェクトのようなものであり、好きなことを友達と一緒にできる最高のシチュエーションであり、同時に生活のためのものでもあります。
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>FD</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    ところで、今回はコラボレーションプロジェクトについて話すために来ていただきました。フレーヤさんにお話を伺ってもいいですか？
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>Freja Kir（以下FK）</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    そうですね、今日はこんなことを聞いてみたいと思っていたんです。グラフィックデザインはどのようにしてコラボレーションを生むツールになるのでしょうか。しかし次のことに気づいたんです。それは常にではないにせよ、非常に多くの場合、グラフィックデザインと出版は広い意味で、元からコラボレーションを行なっているということです。ですので、次のような質問に変えたいと思います。コラボレーションを行わない出版のシナリオなんてあるのでしょうか。またその意味において、コラボレーションとはグラフィックデザインのDNAに刻まれたものではないのでしょうか。
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>
                      Karolien Buurman（以下KB）
                    </h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    この中でグラフィックデザイナーでないのは私だけだと思います。しかし私はスタジオを所有しており、そこは表現手段としてグラフィックデザインにかなり特化しています。ですので私にとって、そのデザインスタジオを経営することが既にそれ自体コラボレーションなであると言えます。私は心理学と芸術の勉強をしてきました。そしてそこで学んだことの多くをこのデザインスタジオで発揮していますが、自分でビジュアルを制作してはいません。なのでこうも言えます。このスタジオはグラフィックデザインだけに特化しているのではなく、私自身の出自に基づく研究も反映しています。プロジェクトの実施に当たっては、他の人たちとコラボレーションしています。出版プロジェクトNSX（すべての出発点になったものです）に則して言うと、出版物に呼応した別の作品を制作するということです。このように、コラボレーションやネットワークを作り上げることは、プロジェクトの手法でありゴールでもあります。コラボレーションや他の人たちとの繋がりなくしては、私たちの仕事も存在し得ません。
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>FD</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    では、そのコラボレーションは常に全員が発言権を持つ民主的なものでしょうか。それとも組織としての指導的役割があり、コラボレーションに関し最終決定権があるのでしょうか。
                  </p>
                </div>
              </div>

              <div className={css.fanfare__talk + ` content__2col`}>
                <div className={`content__2col__side`}>
                  <div className={`content__title`}>
                    <h2 className={`haeding__lev2`}>RW</h2>
                  </div>
                </div>

                <div className={`editor__content content__2col__main`}>
                  <p>
                    デザインは元来出版活動の一部であり、まさにコラボレーションプロジェクトだと思います。つまり人々が共有するプラットフォームのようなものです。しかし一方で、デザインは非常に個人的な要素からなることもあります。一緒に仕事をするデザイナーが提案したものを受け入れるとなったら、デザイナーには完全に自由にやってもらおう思っています。個人の色を出してもらうことで、グラフィックデザインに特色ある要素を加えることができるからです。もちろん、これに関する見方は一つではありませんが。
                  </p>
                </div>
              </div>

              <div className={css.fanfare__note + ` content`}>
                <div className={`editor__content`}>
                  <p className={`font__small`}>
                    このテキストは、TOKYO ART BOOK FAIR
                    2020のfanfareのキュレーションの一部として記録・発表された対談から抜粋したものです。
                    <br />
                    脚注の画像は関連作品を集めたものと対談中に言及のあった参考文献を示しています。
                    <br />
                    対談の全編は英語で参照することができ、VABFの開催期間中に公開されています。また、fanfareに資料請求することも可能です。
                  </p>
                </div>
              </div>

              <div className={css.fanfare__vfn + ` content`}>
                <h2 className={`haeding__lev2`}>VISUAL FOOTNOTES</h2>

                <div className={css.fanfare__vfn__imgs}>
                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a
                      href={`https://fanfarefanfare.nl/category/one-time-show/1592-the-universe-of-images`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/connect1.png" alt="" />
                      <figcaption>Physical Publishing</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w50}>
                    <a href={`http://display.observer`} target={`_blank`}>
                      <img src="/images/fanfare/connect2.png" alt="" />
                      <figcaption>Display.observer</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a href={`http://nxs.world/`} target={`_blank`}>
                      <img src="/images/fanfare/connect3.png" alt="" />
                      <figcaption>NXS</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a
                      href={`https://www.instagram.com/nxs_world/`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/connect4.png" alt="" />
                      <figcaption>NXS Side notes</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w50}>
                    <a
                      href={`https://hackersanddesigners.nl/s/Publishing/p/Coded_Bodies_Publication`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/connect5.png" alt="" />
                      <figcaption>Coded Bodies Publication</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a
                      href={`https://hackersanddesigners.nl/s/Summer_Academy_2020/p/Kick_Off_HDSA2020_Network_Imaginaries`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/connect6.png" alt="" />
                      <figcaption>
                        H&D Summer Academy 2020 Network Imaginaries
                      </figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w50}>
                    <a href={`https://enter-enter.nl/`} target={`_blank`}>
                      <img src="/images/fanfare/connect7.png" alt="" />
                      <figcaption>Enter Enter</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a
                      href={`https://enter-enter.nl/past/#item-220`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/connect8.png" alt="" />
                      <figcaption>
                        Printed Matter’s NY Art Book Fair, ROOM C (Amsterdam)
                      </figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a
                      href={`https://hypercore-protocol.org/#:~:text=Hypercore%20allows%20you%20to%20quickly,download%20everything%20from%20the%20beginning.`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/connect9.png" alt="" />
                      <figcaption>Hypercore P2P protocol</figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w25}>
                    <a
                      href={`https://occasionalpapers.org/product/the-natural-enemies-of-books/`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/connect10.png" alt="" />
                      <figcaption>
                        The Natural Enemies of Books: A Messy History of Women
                        in Printing and Typography
                      </figcaption>
                    </a>
                  </figure>

                  <figure className={css.thumb + ` ` + css.thumb__w50}>
                    <a
                      href={`https://www.design-research.be/by-womxn/`}
                      target={`_blank`}
                    >
                      <img src="/images/fanfare/connect11.png" alt="" />
                      <figcaption>BADASS LIBRE FONTS BY WOMXN</figcaption>
                    </a>
                  </figure>
                </div>
              </div>
            </article>

            <div className={css.fanfare__section + ` content__2col`}>
              <div className={`content__2col__side`}>
                <div className={`content__title`}>
                  <h1 className={css.kotebakura1 + ` haeding__lev2`}>
                    Unlearn, Display, Connect
                  </h1>
                </div>
              </div>

              <div className={`editor__content content__2col__main`}>
                <p>
                  私たちの周りには、一時的な状況や社会の規範に反応するグラフィックデザインへ取組む環境が存在している。この観察の中には、国際的な影響、財政状況、制度的な形跡が含まれている。
                  <br />「
                  アンラーン、ディスプレイ、コネクト」では、最近のグラフィックデザインプロジェクトの視覚的な語彙に支えられた様々なディスカッションを紹介している。これらのプロジェクトは、経済的な利益よりも文化的な価値を追求する傾向がある。コミュニケーション・ネットワークや知識のインフラを介して，ラーニング、ディスプレイ、コネクトのフレームワークを作り出している。Fanfareの基本的な考え方もまた、これらの価値観の中に位置している。
                </p>
              </div>
            </div>

            <div className={css.fanfare__section + ` content__2col`}>
              <div className={`content__2col__side`}>
                <div className={`content__title`}>
                  <h1 className={css.kotebakura1 + ` haeding__lev2`}>Dutch</h1>
                </div>
              </div>

              <div className={`editor__content content__2col__main`}>
                <p>
                  オランダのグラフィックデザインはどのように定義されるのだろうか？そして、それは私たちを取り巻く環境にどのように反映されているのか？オランダのグラフィックデザインとプリントメイキングのルーツを辿ると遥か昔にさかのぼるが、今日の「ダッチグラフィックデザイン」の概念について考えてみよう。技術的な効率とデジタルへの適応に合わせて、かつて「ダッチ」デザインと分類されていたものは、かなり違った意味になってきているのかもしれない。TOKYO
                  ART BOOK
                  FAIRでの展覧会をキュレーションするにあたり、オランダのグラフィックデザインの領域を説明する仕事とだは考えていないし、誰かがその仕事をこなせるとも思っていない。
                </p>
              </div>
            </div>

            <div className={css.fanfare__section + ` content__2col`}>
              <div className={`content__2col__side`}>
                <div className={`content__title`}>
                  <h1 className={css.kotebakura1 + ` haeding__lev2`}>
                    fanfare
                  </h1>
                </div>
              </div>

              <div className={`editor__content content__2col__main`}>
                <p>
                  fanfareは、グラフィックデザインにおけるコラボレーションと開発のためのプラットフォームおよびデザインスタジオである。fanfareはプラットフォームとして、視覚的なイメージやアカデミックなデザイン方法論の探求的なアプローチへの認識を促進する。デザインのディレクションとキュレーションを通して、fanfareは多様な人々、仲間、教育プログラムと、機関、美術館、プラットフォームとのコラボレーションを行っている。
                </p>
                <a
                  href={`/images/fanfare/Unlearn-Display-Connect.pdf`}
                  target={`_blank`}
                  className={css.fanfare__btn}
                >
                  VISUAL
                  <br />
                  FOOTNOTES
                </a>
              </div>
            </div>

            <div
              className={
                css.fanfare__section +
                ` ` +
                css.fanfare__footer +
                ` content__2col`
              }
            >
              <div className={`content__2col__side`}>
                <div className={`content__title`}>
                  <h2 className={css.kotebakura1 + ` haeding__lev1`}>
                    <Link to="#top">
                      fanfare
                      <span>PAGETOP</span>
                    </Link>
                  </h2>
                </div>
              </div>

              <div className={`editor__content content__2col__main`}>
                <p>
                  デザイン、キュレーション：
                  <a href={`http://frejakir.com/`} target={`_blank`}>
                    フレーヤ・キル（Freja Kir）
                  </a>
                  、
                  <a href={`http://www.miquelhervas.com/`} target={`_blank`}>
                    Miquel Hervás Gómez
                  </a>
                  （
                  <a href={`https://fanfarefanfare.nl/`} target={`_blank`}>
                    fanfare
                  </a>
                  ）
                  <br />
                  モデレーター：
                  <a href={`http://frejakir.com/`} target={`_blank`}>
                    フレーヤ・キル
                  </a>
                  、フェムケ・デッケル（Femke Dekker）、
                  <a
                    href={`https://www.jajajaneeneenee.com/`}
                    target={`_blank`}
                  >
                    Ja Ja Ja Nee Nee Nee
                  </a>
                  <br />
                  タイプフェイス：Kòtèba Kura
                  <br />
                  デザイン協力：
                  <a
                    href={`https://www.instagram.com/kareslachevresitupeux/`}
                    target={`_blank`}
                  >
                    セーザル・ロジャーズ（César Rogers）
                  </a>
                  、
                  <br />
                  <a href={`https://www.fabianreichle.com/`} target={`_blank`}>
                    ジングル・チューン：ファビアン・ライフレ（Fabian Reichle）
                  </a>
                </p>
              </div>
            </div>

            <p>
              The fanfare contribution is generously supported by the Danish
              Arts Foundation and the Creative Industries Fund NL
            </p>
          </div>
        </section>
      </Layout>
    )
  }
}

export default IndexPage
