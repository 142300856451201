import React from "react"
import Layout from "../../../../layouts/ja"
import BdbdMenu from "../../../../components/BdbdMenu"
import css from "../index.module.scss"

const IndexPage = props => {
  return (
    <Layout location={props.location} title={`Best Dutch Book Exhibition`}>
      <section className={css.bdbd__about + ` container`}>
        <div className={css.bdbd__content + ` content`}>
          <div className={css.bdbd__head + ` content__head`}>
            <h1 className={`haeding__lev1`}>
              BEST DUTCH <br className={`only-sp`} />
              BOOK DESIGNS
            </h1>
          </div>

          <nav className={css.bdbd__localnavi}>
            <BdbdMenu />
          </nav>

          <article className={`content__2col`}>
            <div className={`content__2col__side`}>
              <div className={`content__title`}>
                <h1 className={`haeding__lev2`}>Outline</h1>
              </div>
            </div>

            <div className={`editor__content content__2col__main`}>
              <section className={css.bdbd__section}>
                <h2>歴史</h2>
                <p>
                  1926年に設立された「Best Dutch Book
                  Design（BDBD）」は、ヨーロッパにおけるブックデザインに関するイベントの中でも最古のものです。しかし、その活動が中断された時期もありました。本賞は1925-32年、1948-71年、そして1986年から現在までと、大きく3つの時代に分けることができます。最初は、デザイナーのS.H.デ・ロースを中心としたNederlandsch
                  Verbond van
                  Boekenvrienden（オランダ・フレンズ・オブ・ブック連盟）によって、オランダで作られた本の中から50冊を選ぶ「keurcollectie（チョイス・セレクション）」としてスタートし、1930年代の経済の停滞と急落によって中断を余儀なくされます。その後、CPNB（オランダ書籍総合促進財団）の援助を受けることで1948年に審査が再開されましたが、1971年に賞の目玉である、受賞作を記録として残すカタログの制作費の捻出が難しくなり再度中断しました。1986年、ヘンク・クライマがCPNBのディレクターに就任し、BDBDの再開を強く後押しました。年に一度のアワードは、印刷や出版におけるデザインの分野が、常に刷新され続けていることを示すものであり、アムステルダム私市立美術館などの文化施設や出版、印刷業界からサポートを受けています。保健・福祉・文化省からの助成、そして再びCPNBの援助を得たことで、15年ぶりにBDBDとして選出された書籍を三たび紹介することができました。政府からの助成が終了したため、1998年からは別の財団によって事業が継続されたことで、スポンサーを探すことも可能になりました。新たな財団は、CPNB、BNO（オランダデザイン協会)、そしてGrafische
                  Cultuur
                  Stichtingの3つの組織が共同で運営しています。運営はディレクター、ユスト・エンスヘデーの手に委ねられ、アムステルダムのPictoright、製紙会社Antalis、そして、ロッセルのL.
                  van Heek
                  Textilesの資金的サポートを受けています。2015年にエンスヘデーが引退し、SPNBにも勤務するエステル・ショルテンが後任につきました。現在BDBDは、Igepa
                  Nederlandもスポンサーに加わっています。
                </p>
              </section>

              <section className={css.bdbd__section}>
                <h2>ミッション</h2>
                <p>
                  財団のミッションは、優れたブックデザインとオランダで制作製作された書籍の重要性を伝え、それらに対する関心を高めることです。
                </p>
              </section>
            </div>
          </article>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
