import React, { useEffect } from "react"
import css from "./Message.module.scss"
import { decodeHTMLEntities } from "../utils/decodeHTMLEntities"
import { nl2br } from "../utils/nl2br"
function MessageContent(prop) {
  const postData = prop.postData

  const getTheMessage = () => {
    const messageNow = decodeHTMLEntities(prop.message)
    // メッセージ取得
    if (messageNow.length > 200) {
      return <p>{nl2br(prop.message)}</p>
    } else {
      let convert = []
      Array.from(messageNow).forEach((key, index) => {
        if (key === " ") {
          convert.push(" ")
        } else if (key.match(/\r?\n/g)) {
          convert.push(<br key={`br-` + index} />)
        } else {
          convert.push(<span key={`spna-` + index}>{key}</span>)
        }
      })
      return <p>{convert}</p>
    }
  }

  useEffect(() => {
    const head = document.querySelector("#js-message-head")
    const img = document.querySelector("#js-message-img")
    const name = document.querySelector("#js-message-name")
    // アイコン画像より名前が短い場合は名前を上下中央に
    if (img && name.clientHeight < img.clientHeight) {
      head.dataset.flex = "true"
    }
  }, [])

  return (
    <>
      <div
        className={css.head}
        data-motion="up-inner-1"
        id="js-message-head"
        data-flex="false"
      >
        {postData.icon && (
          <figure className={css.icon} id="js-message-img">
            <img src={postData.icon} alt={postData.name} />
          </figure>
        )}
        <h2 className={css.name} id="js-message-name">
          {decodeHTMLEntities(postData.name)}
        </h2>
      </div>
      <div className={css.text} data-motion="up-inner-3">
        <div>{getTheMessage()}</div>
      </div>
    </>
  )
}

export default MessageContent
