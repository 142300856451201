import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/VabfEvents.en.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SocialShare = makeShortcode("SocialShare");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "980px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "74.69387755102042%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwQA/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAL/2gAMAwEAAhADEAAAAZknuFw6H//EABwQAQACAQUAAAAAAAAAAAAAAAEAAgMEEBESE//aAAgBAQABBQIC8wLtVr107xh9Gf/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABcRAQADAAAAAAAAAAAAAAAAAAACERL/2gAIAQIBAT8Bk3T/xAAbEAACAwADAAAAAAAAAAAAAAABEQAQIUJxkf/aAAgBAQAGPwIJCEceqT2sPon/xAAbEAEAAwEAAwAAAAAAAAAAAAABABEhQTFRYf/aAAgBAQABPyExCrywuS72GhaOyxwpPiu1HnnuH//aAAwDAQACAAMAAAAQfP8A/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARMf/aAAgBAwEBPxA7ILt//8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERQf/aAAgBAgEBPxCoYM//xAAfEAEBAAIBBAMAAAAAAAAAAAABEQAhMVFhgZGh4fH/2gAIAQEAAT8Q0jEwSd1vwZTwnXUPveA4Urkk95XEQ0iin7gKFUoBXc58hg3SOxRt31z/2Q==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/1a051a2ecefdb360dae0c3ccbf22017c/1a3b7/img_1.webp 245w", "/static/1a051a2ecefdb360dae0c3ccbf22017c/fb835/img_1.webp 490w", "/static/1a051a2ecefdb360dae0c3ccbf22017c/096d3/img_1.webp 980w", "/static/1a051a2ecefdb360dae0c3ccbf22017c/3dcc7/img_1.webp 1470w", "/static/1a051a2ecefdb360dae0c3ccbf22017c/061f9/img_1.webp 1960w", "/static/1a051a2ecefdb360dae0c3ccbf22017c/d9f6b/img_1.webp 2466w"],
          "sizes": "(max-width: 980px) 100vw, 980px",
          "type": "image/webp"
        }}></source>{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/1a051a2ecefdb360dae0c3ccbf22017c/c168b/img_1.jpg 245w", "/static/1a051a2ecefdb360dae0c3ccbf22017c/bb66a/img_1.jpg 490w", "/static/1a051a2ecefdb360dae0c3ccbf22017c/2f919/img_1.jpg 980w", "/static/1a051a2ecefdb360dae0c3ccbf22017c/a7235/img_1.jpg 1470w", "/static/1a051a2ecefdb360dae0c3ccbf22017c/331e6/img_1.jpg 1960w", "/static/1a051a2ecefdb360dae0c3ccbf22017c/26d47/img_1.jpg 2466w"],
          "sizes": "(max-width: 980px) 100vw, 980px",
          "type": "image/jpeg"
        }}></source>{`
        `}<img parentName="picture" {...{
          "className": "gatsby-resp-image-image",
          "src": "/static/1a051a2ecefdb360dae0c3ccbf22017c/2f919/img_1.jpg",
          "alt": "img 1",
          "title": "img 1",
          "loading": "lazy",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          }
        }}></img>{`
      `}</picture>{`
    `}</span>
    <div className="share__2col">
  <div className="share__2col__icon">
    <SocialShare title={props.pageContext.frontmatter.title} mdxType="SocialShare" />
  </div>
    </div>
    <p>{`ロンドンを拠点にフォトグラファー、アーティストとして活動するヴァレリー・フィリップスと彼女の写真集や ZINE をディストリビュートしてきた MARGINAL PRESS の大智由実子が、ヴァレリーのロンドンの自宅とイベント会場の有楽町 micro FOOD & IDEA MARKET を Zoom で繋ぎ、アットホームなガールズトークを繰り広げます。`}<br />{`
Slumber Party（スランバーパーティー）とは、英語でパジャマパーティーの意味。トークイベントは日本時間の夕方５時スタートですが、ロンドン時間では朝８時。ヴァーチャルで様々なイベントが行われ世界中が簡単に繋がれるようになりましたが、時差は無くなりません。寝起きのヴァレリーと、ちょっと早いけどこれから寝る準備をするユミコがパジャマで配信する台本無しのすっぴんトーク！`}</p>
    <p>{`東京の会場（有楽町 micro FOOD & IDEA MARKET）では、トーク終了後もロンドンにいるヴァレリーと Zoom でつないだままサイン会に移行します。ヴァレリーの新刊『I had a dream you married a boy』をお買い上げの方にスペシャルなマシーン!? を使ったユニークな遠隔サイン会を開催します。`}</p>
    <ul>
      <li parentName="ul">
        <span className="font__small">無料で視聴いただけます。</span>
      </li>
      <li parentName="ul">
        <span className="font__small">
  大智由美子がトークを行う有楽町 micro FOOD & IDEA
  MARKETには、先着20名様までの着席のスペースがございます。
  <br />
  　有楽町 micro FOOD & IDEA MARKET
  <br />
  　100-0006 東京都千代田区有楽町1-10-1 有楽町ビル 1F
        </span>
      </li>
      <li parentName="ul">
        <span className="font__small">
  インターネット回線の状況やサーバー負荷、その他視聴者の PC
  環境により、映像が途切れる、または停止するなど正常に視聴できない事がございます。
        </span>
      </li>
      <li parentName="ul">
        <span className="font__small">
  配信終了後も TOKYO ART BOOK FAIR の youtube
  チャンネルで動画を視聴いただけます。
        </span>
      </li>
    </ul>
    <div className="content__noimginfo">
  <div className="content__noimginfo__title">
    <p>ヴァレリー・フィリップス</p>
  </div>
  <div className="content__noimginfo__text">
    <p>
      ニューヨーク出身の写真家。パンク、宇宙、スケートカルチャー、ブルータリズム建築、コラージュアート、メールアート、ヴィンテージおもちゃがインスピレーション源。音楽に夢中なクラブキッズだった頃に、雑誌のエディトリアルワークやレコードジャケットの撮影のためにロンドンへと移住し、パーソナルプロジェクトを行いながらファッションの分野でも活躍するように。2001年より自費出版の写真集やZINEを精力的に発表し続けている。
    </p>
  </div>
    </div>
    <div className="content__noimginfo">
  <div className="content__noimginfo__title">
    <p>
      大智 由実子 <br className="only-sp" />
      <span className="font__xs">(MARGINAL PRESS)</span>
    </p>
  </div>
  <div className="content__noimginfo__text">
    <p>
      2012年よりMARGINAL
      PRESSとしてディストリビューション活動を開始し、海外のアートブックやインディペンデントマガジンを日本に紹介。2017年に活動を休止し、現在はサウナ・銭湯・温泉巡りをしつつライターとして活動する。
    </p>
  </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      