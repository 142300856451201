import { SceneObject } from "./types"
import {
  Mesh,
  Vector3,
  Scene,
  PlaneGeometry,
  MeshBasicMaterial,
  Vector2,
  RepeatWrapping,
  PlaneBufferGeometry,
  LinearEncoding,
} from "three"
import { Loader } from "./Loader"

export class Background implements SceneObject {
  private constructor(private meshes: Mesh[]) {}

  static init(scene: Scene, position: Vector3): Background {
    const geo = new PlaneBufferGeometry(1000, 1000, 2, 2)
    const texture = Loader.loadTexture("/images/potato.jpg")
    texture.wrapS = texture.wrapT = RepeatWrapping
    texture.repeat.set(10, 10)
    texture.encoding = LinearEncoding
    const mat = new MeshBasicMaterial({
      map: texture,
    })
    const mesh = new Mesh(geo, mat)
    mesh.rotateX(-Math.PI / 2)
    mesh.position.add(position)
    scene.add(mesh)

    return new Background([mesh])
  }

  update() {}

  dispose() {
    for (const m of this.meshes) {
      m.remove()
    }
  }
}
