export const config = {
  HALL_FRUSTUM_SIZE: 20,
  HALL_CAMERA_DISTANCE: 100,
  HALL_CAMERA_ROT_X: -Math.PI / 4,
  HALL_CAMERA_ROT_Y: -Math.PI / 5,
  HALL_TEXT_ZOOM_THRESHOLD: 1.2,
  HALL_CELL_SIZE: 6,
  HALL_MIN_ZOOM: 1,
  HALL_MIN_ZOOM_SP: 1.5,

  BOOTH_CAMERA_DISTANCE: 100,
  BOOTH_CAMERA_ROT_X: -Math.PI / 4,
  BOOTH_CAMERA_ROT_Y: -Math.PI / 5,
}
