import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`『Back Stages』は、世界中の芸術と工芸が生まれるさまざまな場と時間を記録した写真集だ。頭上からの撮影という単一の視点を用いることでそれらの場の鮮やかな美学を明らかにし、ミステリアスで魅力的かつユニバーサルな視点から、ある文化が生まれるプロセスを観察できるようになっている。文化が実際に生まれる場、そして文化とアイデンティティの形成過程を追った書籍それ自体が、同じような制作のプロセスを経ていることは不思議ではないだろう。例えば、表紙に使われるグレーの厚紙は地の素材が見え、背表紙の生地は目が覚めるようなオレンジ色で、綴じ部分に使われている糸には彩色され、図版パートはコート紙に印刷、テキストパートに使われている灰色がかったリサイクル紙「Recystar® Nature」と好対照をなしている。表紙と裏表紙のタイポグラフィは、極めて模範的な箔押しが施されている。印刷とリトグラフのおかげで、素晴らしく開放的で軽快な印刷物になっている。アーティストら自身によって写真は編集され、セリネ・フルカが知的に具体化した。結果として、世界中のどこであれ、芸術と文化が作りだされる場が誕生することへの賛歌となっている。`}</p>
    <hr></hr>
    <p>{`出版社： Onomatopee Projects, Eindhoven`}<br parentName="p"></br>{`
`}{`デザイン： Céline Hurka, Den Haag`}</p>
    <p>{`カテゴリー： アート、写真、建築`}<br parentName="p"></br>{`
`}{`アーティスト：Katrin Korfmann, Jens Pfeifer`}<br parentName="p"></br>{`
`}{`編集：Katrin Korfmann, Jens Pfeifer`}<br parentName="p"></br>{`
`}{`言語：英語`}<br parentName="p"></br>{`
`}{`翻訳：Nanne op ’t Ende, Josh Plough, Pernilla Ellens`}<br parentName="p"></br>{`
`}{`写真：Katrin Korfmann, Jens Pfeifer`}<br parentName="p"></br>{`
`}{`イメージ編集：Katrin Korfmann, Jens Pfeifer`}<br parentName="p"></br>{`
`}{`編集：Katrin Korfmann, Jens Pfeifer`}<br parentName="p"></br>{`
`}{`リトグラフ：Unicum | Gianotten Printed Media (Gerrie van Beek), Tilburg`}<br parentName="p"></br>{`
`}{`印刷：Unicum | Gianotten Printed Media, Tilburg`}<br parentName="p"></br>{`
`}{`製本：Boekbinderij Van Mierlo, Nijmegen`}<br parentName="p"></br>{`
`}{`フォント：Junior (Selina Bernet)`}<br parentName="p"></br>{`
`}{`サイズ（ミリ）：225 x 285 x 10`}<br parentName="p"></br>{`
`}{`ページ数：124`}<br parentName="p"></br>{`
`}{`発行部数：700`}<br parentName="p"></br>{`
`}{`価格：€ 27`}<br parentName="p"></br>{`
`}{`ISBN：978 94 93148 06 2`}<br parentName="p"></br>{`
`}{`本文用紙：150gsm Condat Matt Périgord (Papier Union), 100gsm and Recystar® Nature (Papyrus)`}<br parentName="p"></br>{`
`}{`見返し：140gsm Sirio Color Pietra (Fedrigoni)`}<br parentName="p"></br>{`
`}{`装丁：120gsm Wibalin® Natural 500 White (Winter & Company) over 2.5 mm Eskaboard (Koninklijke Moorman Karton)`}<br parentName="p"></br>{`
`}{`製本方法：糸かがりハードカバー見返し加工、背にリネン(テキスタイル：Brillianta® 4196 - Van Heek Textiles 社)糊付け`}<br parentName="p"></br>{`
`}{`特記事項：灰色糸で綴じ、Boekbinderij Van Mierlo による艶黒(Kurz Luxor 362)の箔押し、背に Saenscreen による黒スクリーン印刷。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      