import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.en.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skin, body, eroticism, exoticism, criticism and identity: Hadassah Emmerich’s colourful recent work experiments with intimacy and distance, abstraction and recognisability, objectification and the position of the viewer. In cooperation with the artist, designer Esther Le Roy got into – or rather under – the skin of these works of art and designed a book that approaches the work from the inside out, both in terms of form and content. The special synthetic material used for the cover – neobond® – refers to the vinyl printing technique used by Emmerich, but is also reminiscent of the skins of her paintings – and the skin of the abstracted bodies and body parts depicted in these paintings. The large format is also derived from Emmerich’s works. The sensory experience continues inside the book with contrasting choices of paper, an exciting grid which leaves a lot of space for the works themselves, making good use of the graphic quality of the repeating visual shapes. The iridescent, metallic-like body shapes with their strong yet nuanced colours are painstakingly produced on paper that is not too white, and the typography on the cover printed in silver foil could be seen as a reflection – literally – of this metallic quality. The credits name Hadassah Emmerich herself as the book’s editor, ‘in cooperation with the designer’, with Esther Le Roy credited for ‘concept & design’, ‘in conversation with the artist’. A ‘Möbius strip’ leading from designer to artist and back again is visible and palpable in this book that is both sensual and critical.`}</p>
    <hr></hr>
    <p>{`Publisher: Onomatopee Projects, Eindhoven`}<br parentName="p"></br>{`
`}{`Designer: Esther Le Roy, Brussel (BE)`}</p>
    <p>{`Category: Art, photo and architecture books`}<br parentName="p"></br>{`
`}{`Artist: Hadassah Emmerich`}<br parentName="p"></br>{`
`}{`Editor: Koen Leemans`}<br parentName="p"></br>{`
`}{`Language: English, Dutch, Frans`}<br parentName="p"></br>{`
`}{`Translator: Gerard Forde, Isabelle Grynberg`}<br parentName="p"></br>{`
`}{`Photographer: Peter Cox, Carel de Groot, Hugard & Vanoverschelde, Jeroen Nieuwenhuize, Teri Romkey`}<br parentName="p"></br>{`
`}{`Image editor: Hadassah Emmerich, Esther Le Roy`}<br parentName="p"></br>{`
`}{`Editor-in-chief: Hadassah Emmerich, Esther Le Roy`}<br parentName="p"></br>{`
`}{`Lithographer: Die Keure, Brugge (BE)`}<br parentName="p"></br>{`
`}{`Printer: Die Keure, Brugge (BE)`}<br parentName="p"></br>{`
`}{`Binder: Boekbinderij Van Mierlo, Nijmegen`}<br parentName="p"></br>{`
`}{`Font: Favorit (Dinamo)`}<br parentName="p"></br>{`
`}{`Size in mm: 255 x 345 x 6`}<br parentName="p"></br>{`
`}{`Number of pages: 96`}<br parentName="p"></br>{`
`}{`Print run: 1,000`}<br parentName="p"></br>{`
`}{`Price: € 30`}<br parentName="p"></br>{`
`}{`ISBN: 978 94 93148 09 3`}<br parentName="p"></br>{`
`}{`Paper for interior: 110gsm Woodstock Cipria (Fedrigoni), 150gsm Symbol Matt Plus Premium White (Fedrigoni)`}<br parentName="p"></br>{`
`}{`Endpapers: 170gsm Sirio Color Perla (Fedrigoni)`}<br parentName="p"></br>{`
`}{`Binding: 150gsm neobond® white (Papyrus)`}<br parentName="p"></br>{`
`}{`Binding style: Sewn hardback with flat spine, 1.5 mm greyboard`}<br parentName="p"></br>{`
`}{`Special features: Silver foil stamping (Kurz Alufin Mat) by Boekbinderij Van Mierlo.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      