import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.en.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The most accomplished of a three-part poetry series published by De Bezige Bij. The shiny mother of pearl cover puts us in mind of shells, drawing us into the subject matter before we even open the book. The enlargement of two words from the title – ‘zoon’ (‘son’) and ‘zee’ (‘sea’) allow the cover design to really speak. In the main matter too, contrasting typography – the sober sans serif Suisse Int’l combined with the stylish SangBleu Versailles – is used, and designer Michaël Snitker also brings these contrasting fonts together to great effect in the contents list. The translucency of the paper is used to good effect where appropriate – for example on the title page, which is visible though the half title page. In other places, an almost imperceptibly light backdrop is printed over pages marking a separation between sections; so light it is almost as if the ink has been blown across the page. A touch of class – glamour even – in this simply yet effectively designed poetry anthology.`}</p>
    <hr></hr>
    <p>{`Publisher: De Bezige Bij, Amsterdam`}<br parentName="p"></br>{`
`}{`Designer: Michaël Snitker, Amsterdam`}</p>
    <p>{`Category: Prose, poetry or graphic novels`}<br parentName="p"></br>{`
`}{`Editor: Merijn Hollestelle`}<br parentName="p"></br>{`
`}{`Language: Dutch`}<br parentName="p"></br>{`
`}{`Lithographer: DPS, Amsterdam`}<br parentName="p"></br>{`
`}{`Printer: Wilco bv | Boeken en Tijdschriften, Amersfoort`}<br parentName="p"></br>{`
`}{`Binder: Wilco bv | Boeken en Tijdschriften, Amersfoort`}<br parentName="p"></br>{`
`}{`Font: Suisse Int’l (Swiss Typefaces), SangBleu Versailles (Swiss Typefaces), Antique Gothic (Production Type)`}<br parentName="p"></br>{`
`}{`Size in mm: 160 x 240 x 8mm`}<br parentName="p"></br>{`
`}{`Number of pages: 84`}<br parentName="p"></br>{`
`}{`Print run: 1,000`}<br parentName="p"></br>{`
`}{`Price: € 21.99`}<br parentName="p"></br>{`
`}{`ISBN: 978 94 031 0850 6`}<br parentName="p"></br>{`
`}{`Paper for interior: 80gsm Munken Premium Cream 19.5 (Arctic Paper)`}<br parentName="p"></br>{`
`}{`Cover: 250gsm Curious Metallics Lustre (Antalis)`}<br parentName="p"></br>{`
`}{`Binding style: Sewn softcover with flaps`}<br parentName="p"></br>{`
`}{`Special features: Silver foil stamping on cover (Kurz Luxor 377) by Wilco bv | Boeken en Tijdschriften`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      