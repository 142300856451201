import React from "react"
import { Link } from "gatsby"
import css from "./BdbdMenu.module.scss"

const BdbdMenu = props => {
  return (
    <ul className={css.bdbdMenu}>
      <li>
        <Link
          activeClassName={css.bdbdMenu__active}
          to={`/exhibitions/best-dutch-book/`}
        >
          展示
        </Link>
      </li>
      <li>
        <Link
          activeClassName={css.bdbdMenu__active}
          to={`/exhibitions/best-dutch-book/report/`}
        >
          審査員レポート
        </Link>
      </li>
      <li>
        <Link
          activeClassName={css.bdbdMenu__active}
          to={`/exhibitions/best-dutch-book/about/`}
        >
          概要
        </Link>
      </li>
      <li>
        <Link
          activeClassName={css.bdbdMenu__active}
          to={`/exhibitions/best-dutch-book/talk/`}
        >
          トークイベント
        </Link>
      </li>
    </ul>
  )
}
export default BdbdMenu
