import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/DefaultPage.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Director: Yusuke Nakajima`}</p>
    <p>{`Art Director: Yoshihisa Tanaka`}<br parentName="p"></br>{`
`}{`Designer: Yutaro Yamada`}</p>
    <p>{`Web Director: Shunya Hagiwara`}<br parentName="p"></br>{`
`}{`Web Project Manager: Yusuke Ono`}<br parentName="p"></br>{`
`}{`Web Programmer: Takayoshi Amagi, Akihiro Kuroda, Shunya Hagiwara, Tsukasa Hasegawa, Shogo Matsuda, Nobuhito Miyazaki`}<br parentName="p"></br>{`
`}{`3D Prototyping: Junnosuke Hara`}<br parentName="p"></br>{`
`}{`Web Designer: Keiichi Iyama`}</p>
    <p>{`Space Designer: Toshikatsu Kiuchi, sunayama studio (Taichi Sunayama, Gaishi Kudo)`}</p>
    <p>{`Corporate Relations: Atsushi Hamanaka`}<br parentName="p"></br>{`
`}{`Venue Interior Coordinator: Takeshi Nishio`}<br parentName="p"></br>{`
`}{`PR: Yoko Toriumi`}<br parentName="p"></br>{`
`}{`Translator: John B Keyser, Sam Sain, Kim Tae Rang, Futoshi Miyagi, Satoko Yokoyama, Yuka Katagiri`}<br parentName="p"></br>{`
`}{`Curator（Dutch Artists’ Book: Then and Now）: Sawako Fukai`}</p>
    <p>{`Sound Effect：Ryota Miyake`}<br parentName="p"></br>{`
`}{`BGM Select：COMPUMA`}</p>
    <p>{`Project Manager: Shie Okabe, Naoko Higashi, Yoko Nakayama, Sayaka Yamada, Kanako Tsunoda, Yui Katono`}<br parentName="p"></br>{`
`}{`Project Leader: Yumiko Ohchi, Kasumi Ozeki, Mayu Kimachi, Ryota Takahashi, Rina Tokura`}<br parentName="p"></br>{`
`}{`Support Staff: Hiroki Iimura, Teruki Ishii, Kana Ikezawa, Hajime Inoue, Yuka Iwai,`}<br parentName="p"></br>{`
`}{`Yu An Kao, Minori Suzuki, Minami Tazawa, Aki Hamada, Akane Matsunaga, Yu Miyakoshi, Kaoru Mori`}</p>
    <p>{`Organizer: TOKYO ART BOOK FAIR, Museum of Contemporary Art Tokyo operated by Tokyo Metropolitan Foundation for History and Culture`}<br parentName="p"></br>{`
`}{`Sponsor: Fuji Xerox Co., Ltd., Mitsubishi Estate Co., Ltd., SHISEIDO, inuuniq Co., Ltd., Riso Kagaku Corporation`}<br parentName="p"></br>{`
`}{`Grants: Embassy of the Kingdom of the Netherlands, DutchCulture`}<br parentName="p"></br>{`
`}{`Support: Creative Industries Fund NL, Dutch Foundation For Literature, Mondriaan Fund, Aēsop`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      