import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`オランダ人リースベスと連合国軍に属するアフリカ系アメリカ人フランクリンの第二次世界大戦下の関係を描いた、事実をもとにしたフィクショナルなグラフィックノベルは、選考会でも全員が推した。アフリカ系アメリカ人兵士たち、そして彼らとオランダ住人たちが戦後育みながらも忘れられた関係性が、2 種類のインクを用いた非常にパワフルなグラフィック言語で語られる。インクは肌の色に紐づいてはいるが、その使われ方はステレオタイプに陥ってはいない。物語に関連する歴史的資料はページ間に袋綴じされている。前付けと後付は青と黒で、カバーは白と赤で印刷されており、オランダ国旗を模した色使いとなっている。カバーと中ページに使用されているフェルトのように繊細な紙は歴史的資料のような手触りで、細心の注意を払われたデザインはこれが現代の書籍にほかならないことを証明している。紙のしなやかさもあって、ページのめくりやすい、シンプルなペーパーバックとなっている。`}</p>
    <hr></hr>
    <p>{`出版社： ROSE stories / Stichting LovingDay.NL, Amsterdam`}<br parentName="p"></br>{`
`}{`デザイン： Lyanne Tonk, Amsterdam`}</p>
    <p>{`カテゴリー：散文、詩、グラフィックノベル`}<br parentName="p"></br>{`
`}{`編集：Dorrit Emmer, Kees Ribbens, Leonie Soet`}<br parentName="p"></br>{`
`}{`言語：オランダ語、英語`}<br parentName="p"></br>{`
`}{`翻訳：Rikkert Stuve`}<br parentName="p"></br>{`
`}{`イラスト：Brian Elstak (colouring by Hedy Tjin and Julian Mostert)`}<br parentName="p"></br>{`
`}{`編集：Maarten van Hinte, Marga Altena`}<br parentName="p"></br>{`
`}{`製作：Tim Beijer Producties, Utrecht`}<br parentName="p"></br>{`
`}{`印刷：Wilco bv | Boeken en Tijdschriften, Amersfoort`}<br parentName="p"></br>{`
`}{`製本：Wilco bv | Boeken en Tijdschriften, Amersfoort`}<br parentName="p"></br>{`
`}{`フォント：Plantin (Monotype), LL Unica77 (Lineto)`}<br parentName="p"></br>{`
`}{`サイズ（ミリ）：155 x 215 x 14`}<br parentName="p"></br>{`
`}{`ページ数：160`}<br parentName="p"></br>{`
`}{`発行部数：3,000`}<br parentName="p"></br>{`
`}{`価格：€ 16.95`}<br parentName="p"></br>{`
`}{`ISBN：978 90 83002 85 9`}<br parentName="p"></br>{`
`}{`本文用紙：120gsm Soporset Premium Offset (Igepa), 90gsm Munken Premium Cream 19.5 (Arctic Paper)`}<br parentName="p"></br>{`
`}{`カバー：250gsm Crush Corn (Igepa)`}<br parentName="p"></br>{`
`}{`製本方法：糸かがりソフトカバーフラップ付き`}<br parentName="p"></br>{`
`}{`特記事項：中身の主要部分は Pantone 4645、7520 と黒、最初と最後のセクションは Pantone2727（青）と黒、フラップ（カバー）は Pantone032（赤）で印刷。これらの赤、白、青はオランダの国旗を表している。中身の一部は袋綴じのページになっている。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      