import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.en.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Were it not for is a grim poem in text and images. It brings together 218 desolate black-and-white photos of human construction and development activities in the Mojave desert – or the forlorn remnants of these – with a text work consisting of 813 sentences starting with ‘Were it not for ...’ The combinations of text and image are random, but their effect is anything but. The book can be read as a recording of the result of the ‘Californian Ideology’ – the belief, supported since the 1990s by a combination of network technologies and neoliberalism, that anything is possible. In Ashkin’s own words: ‘Both the text and photos show what the “Californian Ideology” has produced in our world. A situation where “anything is possible” has produced its opposite.’ The minimalism of the repeating lines of text and the photographs, always in the same full-page format, is continued in the forceful simplicity of the kraft board cover and the simple execution as a paperback without flaps. The lithography and printing, in tritone, lend depth, detail and nuance to the merciless photos. The image on the rear board is built up using opaque white to lift it from the brown of the kraft board. An example of the kind of exact, well-chosen choices which, together with the tense image sequences and the exciting if disturbing narrative, set this book apart from the rest.`}</p>
    <hr></hr>
    <p>{`Publisher: Fw:Books, Amsterdam`}<br parentName="p"></br>{`
`}{`Designer: Hans Gremmen, Amsterdam`}</p>
    <p>{`Category: Art, photo and architecture books`}<br parentName="p"></br>{`
`}{`Editor: Hans Gremmen, Michael Ashkin`}<br parentName="p"></br>{`
`}{`Language: English`}<br parentName="p"></br>{`
`}{`Photographer: Michael Ashkin`}<br parentName="p"></br>{`
`}{`Image editor: Hans Gremmen, Michael Ashkin`}<br parentName="p"></br>{`
`}{`Printer: Wilco Art Books, Amersfoort`}<br parentName="p"></br>{`
`}{`Binder: Wilco Art Books, Amersfoort`}<br parentName="p"></br>{`
`}{`Font: Highway Gothic Expanded, series F 2000 (FHWA)`}<br parentName="p"></br>{`
`}{`Size in mm: 215 x 280 x 19`}<br parentName="p"></br>{`
`}{`Number of pages: 256`}<br parentName="p"></br>{`
`}{`Print run: 1,000`}<br parentName="p"></br>{`
`}{`Price: € 35`}<br parentName="p"></br>{`
`}{`ISBN: 978 94 90119 80 5`}<br parentName="p"></br>{`
`}{`Paper for interior: 130gsm Lessebo Design Smooth 1.2 Natural (Igepa)`}<br parentName="p"></br>{`
`}{`Cover: 280gsm Kraftliner (Koninklijke Moorman Karton)`}<br parentName="p"></br>{`
`}{`Binding style: Sewn softcover`}<br parentName="p"></br>{`
`}{`Special features: Printing in tritone (black, Pantone Warm Gray 8 and Pantone Black). On the back cover, a white shape is printed in reverse, followed by the same shape printed in black.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      