import { Vector3 } from "three"

// 0 = 横, 1 = 縦
type Dir = 0 | 1

// x, z, テーブルの向き
type TableInfo = Readonly<[number, number, Dir]>

// x, y, 壁の向き, テーブルの向き
type WallInfo = Readonly<[number, number, Dir, Dir]>

type PatternInfo = Readonly<{
  walls: WallInfo[]
  tables: TableInfo[]
  cells: [number, number][]
  profileOffset: Vector3
  xMargin?: 0
}>

export const defaultMultiCompositionData: PatternInfo = {
  walls: [[0, 0, 0, 0]],
  tables: [[0, 0, 0]],
  cells: [[0, 0]],
  profileOffset: new Vector3(0, 0, 0),
}

export const multiCompositionData: Record<number, PatternInfo> = {
  // Japanese booths
  273: {
    walls: [
      [0, 0, 0, 0],
      [1, 0, 0, 0],
      [2, 0, 0, 0],
    ],
    tables: [
      [0, 0, 0],
      [1, 0, 0],
      [2, 0, 0],
    ],
    cells: [
      [0, 0],
      [1, 0],
    ],
    profileOffset: new Vector3(0, 0, 0),
  },
  275: {
    walls: [
      [0, 0, 0, 0],
      [1, 0, 0, 0],
    ],
    tables: [
      [0, 0, 0],
      [1, 0, 0],
    ],
    cells: [[0, 0]],
    profileOffset: new Vector3(0, 0, 0),
  },
  277: {
    walls: [
      [0, 0, 1, 0],
      [1, 0, 1, 0],
      [2, 0, 1, 0],
      [3, 0, 1, 0],
    ],
    tables: [
      [0, 0, 0],
      [1, 0, 0],
      [2, 0, 0],
      [3, 0, 0],
    ],
    cells: [
      [0, 0],
      [1, 0],
    ],
    profileOffset: new Vector3(0, 0, 0),
  },
  295: {
    walls: [
      [0, 0, 0, 0],
      [1, 0, 0, 0],
    ],
    tables: [
      [0, 0, 0],
      [1, 0, 0],
    ],
    cells: [[0, 0]],
    profileOffset: new Vector3(0, 0, 0),
  },
  297: {
    walls: [
      [0, 0, 0, 1],
      [1, 0, 0, 1],
    ],
    tables: [[0.7, 0, 1]],
    cells: [[0, 0]],
    profileOffset: new Vector3(0, 0, 0),
    xMargin: 0,
  },
  303: {
    walls: [
      [0, 0, 0, 0],
      [1, 0, 0, 0],
    ],
    tables: [[0.5, 0, 0]],
    cells: [[0, 0]],
    profileOffset: new Vector3(0, 0, 0),
  },
  304: {
    walls: [
      [0, 0, 0, 0],
      [1, 0, 0, 0],
    ],
    tables: [
      [0, 0, 0],
      [1, 0, 0],
    ],
    cells: [[0, 0]],
    profileOffset: new Vector3(0, 0, 0),
  },
  312: {
    walls: [[0, 0, 0, 1]],
    tables: [
      [1, 0, 1],
      [1, 1, 1],
    ],
    cells: [[0, 0]],
    profileOffset: new Vector3(0, 0, 0),
  },
  317: {
    walls: [
      [0, 0, 0, 0],
      [1, 0, 0, 0],
    ],
    tables: [
      [0, 0, 0],
      [1, 0, 0],
    ],
    cells: [[0, 0]],
    profileOffset: new Vector3(0, 0, 0),
  },
  318: {
    walls: [
      [0, 0, 0, 0],
      [1, 0, 0, 0],
    ],
    tables: [
      [0.5, 0, 0],
      [1.5, 0, 0],
    ],
    cells: [[0, 0]],
    profileOffset: new Vector3(0, 0, 0),
  },
  320: {
    walls: [
      [0, 0, 0, 0],
      [1, 0, 0, 0],
    ],
    tables: [[0.5, 0, 0]],
    cells: [[0, 0]],
    profileOffset: new Vector3(0, 0, 0),
    xMargin: 0,
  },
  328: {
    walls: [
      [0, 0, 0, 0],
      [0, 1, 0, 0],
    ],
    tables: [
      [0, 0, 0],
      [0, 1, 0],
    ],
    cells: [[0, 0]],
    profileOffset: new Vector3(0, 0, 0),
  },
  333: {
    walls: [[0.5, 0, 0, 0]],
    tables: [
      [0, 0, 0],
      [1, 0, 0],
    ],
    cells: [[0, 0]],
    profileOffset: new Vector3(0, 0, 0),
  },
  347: {
    walls: [
      [0, 0, 0, 0],
      [0, 1, 0, 0],
    ],
    tables: [[0.5, 0, 0]],
    cells: [[0, 0]],
    profileOffset: new Vector3(0, 0, 0),
  },
  354: {
    walls: [
      [0, 0, 0, 1],
      [0, 1, 0, 1],
    ],
    tables: [[0.5, 0, 1]],
    cells: [[0, 0]],
    profileOffset: new Vector3(0, 0, 0),
  },
  358: {
    walls: [
      [0, 0, 1, 0],
      [1.2, 0, 1, 0],
    ],
    tables: [
      [0.4, 0, 0],
      [1.6, 0, 0],
    ],
    cells: [
      [0, 0],
      [1, 0],
    ],
    profileOffset: new Vector3(0, 0, 0),
  },
  364: {
    walls: [
      [0, 0, 1, 1],
      [0.75, 0, 1, 1],
    ],
    tables: [[1.5, 0, 1]],
    cells: [
      [0, 0],
      [1, 0],
    ],
    profileOffset: new Vector3(0, 0, 0),
  },
  374: {
    walls: [
      [0, 0, 0, 1],
      [1, 0, 0, 1],
    ],
    tables: [[1.5, 0, 1]],
    cells: [
      [0, 0],
      [1, 0],
    ],
    profileOffset: new Vector3(0, 0, 0),
  },
  377: {
    walls: [
      [0, 0, 1, 0],
      [1, 0, 1, 0],
    ],
    tables: [
      [0, 0, 0],
      [1, 0, 0],
    ],
    cells: [[0, 0]],
    profileOffset: new Vector3(0, 0, 0),
  },
  378: {
    walls: [
      [0, 0, 0, 1],
      [1, 0, 0, 1],
    ],
    tables: [[1.5, 0, 1]],
    cells: [[0, 0]],
    profileOffset: new Vector3(0, 0, 0),
  },
  381: {
    walls: [
      [0, 0, 0, 0],
      [1, 0, 0, 0],
    ],
    tables: [[0.5, 0, 0]],
    cells: [[0, 0]],
    profileOffset: new Vector3(0, 0, 0),
  },
  385: {
    walls: [
      [0, 0, 0, 0],
      [1, 0, 0, 0],
      [2, 0, 0, 0],
    ],
    tables: [
      [0, 0, 0],
      [1, 0, 0],
      [2, 0, 0],
    ],
    cells: [
      [0, 0],
      [1, 0],
    ],
    profileOffset: new Vector3(0, 0, 0),
  },
  391: {
    walls: [
      [0, 0, 0, 0],
      [1, 0, 0, 0],
    ],
    tables: [
      [0, 0, 0],
      [1, 0, 0],
    ],
    cells: [[0, 0]],
    profileOffset: new Vector3(0, 0, 0),
  },
  402: {
    walls: [
      [0, 0, 0, 0],
      [1, 0, 0, 0],
      [2, 0, 0, 0],
      [3, 0, 0, 0],
    ],
    tables: [
      [0, 0, 0],
      [1, 0, 0],
      [2, 0, 0],
      [3, 0, 0],
    ],
    cells: [
      [0, 0],
      [1, 0],
    ],
    profileOffset: new Vector3(0, 0, 0),
  },
  492: {
    walls: [
      [0, 0, 0, 0],
      [1, 0, 0, 0],
      [2, 0, 0, 0],
    ],
    tables: [
      [0, 0, 0],
      [1, 0, 0],
      [3, 0, 0],
    ],
    cells: [
      [0, 0],
      [1, 0],
    ],
    profileOffset: new Vector3(0, 0, 0),
  },
  493: {
    walls: [
      [0, 0, 0, 0],
      [2, 0, 0, 0],
    ],
    tables: [
      [1, 0, 0],
      [3, 0, 0],
    ],
    cells: [
      [0, 0],
      [1, 0],
    ],
    profileOffset: new Vector3(0, 0, 0),
  },
  582: {
    walls: [
      [0, 0, 0, 0],
      [1, 0, 0, 0],
      [2, 0, 0, 0],
    ],
    tables: [
      [0, 0, 0],
      [1, 0, 0],
      [2, 0, 0],
    ],
    cells: [
      [0, 0],
      [1, 0],
    ],
    profileOffset: new Vector3(0, 0, 0),
  },

  // English booths
  430: {
    walls: [
      [0, 0, 0, 0],
      [1, 0, 0, 0],
    ],
    tables: [
      [0, 0, 0],
      [1, 0, 0],
    ],
    cells: [[0, 0]],
    profileOffset: new Vector3(0, 0, 0),
  },
  433: {
    walls: [
      [0, 0, 0, 0],
      [1, 0, 0, 0],
      [2, 0, 0, 0],
    ],
    tables: [
      [0, 0, 0],
      [1, 0, 0],
      [2, 0, 0],
    ],
    cells: [
      [0, 0],
      [1, 0],
    ],
    profileOffset: new Vector3(0, 0, 0),
  },
  434: {
    walls: [
      [0, 0, 0, 0],
      [1, 0, 0, 0],
      [2, 0, 0, 0],
    ],
    tables: [
      [0, 0, 0],
      [1, 0, 0],
      [2, 0, 0],
    ],
    cells: [
      [0, 0],
      [1, 0],
    ],
    profileOffset: new Vector3(0, 0, 0),
  },
  437: {
    walls: [
      [0, 0, 0, 0],
      [1, 0, 0, 0],
    ],
    tables: [[1.5, 0, 0]],
    cells: [[0, 0]],
    profileOffset: new Vector3(0, 0, 0),
    xMargin: 0,
  },
  441: {
    walls: [
      [0, 0, 1, 0],
      [1, 0, 1, 0],
    ],
    tables: [
      [0, 0, 0],
      [1, 0, 0],
    ],
    cells: [[0, 0]],
    profileOffset: new Vector3(0, 0, 0),
  },
  447: {
    walls: [[0, 0, 0, 0]],
    tables: [
      [0.5, 0, 0],
      [0.5, 1, 0],
    ],
    cells: [[0, 0]],
    profileOffset: new Vector3(0, 0, 0),
  },
  448: {
    walls: [[0, 0, 0, 1]],
    tables: [
      [0.5, 0, 1],
      [0.5, 1, 1],
    ],
    cells: [[0, 0]],
    profileOffset: new Vector3(0, 0, 0),
  },
  461: {
    walls: [[0, 0, 0, 1]],
    tables: [
      [0.5, 0, 1],
      [0.5, 1, 1],
    ],
    cells: [[0, 0]],
    profileOffset: new Vector3(0, 0, 0),
  },
  466: {
    walls: [[0, 0, 0, 0]],
    tables: [
      [0, 0, 0],
      [0, 1, 0],
      [0, 2, 0],
    ],
    cells: [[0, 0]],
    profileOffset: new Vector3(0, 0, 0),
  },
  467: {
    walls: [[1, 0, 0, 0]],
    tables: [
      [0, 0, 0],
      [1, 0, 0],
      [2, 0, 0],
    ],
    cells: [[0, 0]],
    profileOffset: new Vector3(0, 0, 0),
  },
  468: {
    walls: [[0, 0, 1, 0]],
    tables: [
      [0, 0, 0],
      [0, 1, 0],
    ],
    cells: [[0, 0]],
    profileOffset: new Vector3(0, 0, 0),
  },
  476: {
    walls: [
      [0, 0, 0, 0],
      [0, 1, 0, 0],
    ],
    tables: [
      [0.5, 0, 0],
      [0.5, 1, 0],
    ],
    cells: [[0, 0]],
    profileOffset: new Vector3(0, 0, 0),
  },
  486: {
    walls: [
      [0, 0, 0, 0],
      [1, 0, 0, 0],
    ],
    tables: [[0.5, 0, 0]],
    cells: [[0, 0]],
    profileOffset: new Vector3(0, 0, 0),
  },
  784: {
    walls: [[0.5, 0, 0, 0]],
    tables: [
      [0, 0, 0],
      [1, 0, 0],
    ],
    cells: [[0, 0]],
    profileOffset: new Vector3(0, 0, 0),
  },
}
