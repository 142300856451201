import React from "react"

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const initialState = {
  useSound: true,
  counter: 0,
  webGlLoaded: false,
}

function reducer(state, action) {
  switch (action.type) {
    case "SOUND_ON": {
      // console.log("ON")
      return {
        ...state,
        useSound: true,
      }
    }
    case "SOUND_OFF": {
      // console.log("OFF")
      return {
        ...state,
        useSound: false,
      }
    }
    case "INCREMENT_TEST_COUNT": {
      return {
        ...state,
        counter: state.counter + 1,
      }
    }
    case "WEBGL_LOADED": {
      // console.log(`GlobalContextProvider: 3D シーンがロードされました`)
      return {
        ...state,
        webGlLoaded: action.payload,
      }
    }
    case "CHROME_RECOMMENDED_ACCEPT": {
      return {
        ...state,
        browserAccept: true,
      }
    }
    default:
      throw new Error("Bad Action Type")
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
