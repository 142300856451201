import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.en.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A simple, powerful book – an unfussy, voluminous paperback based on a very clear idea: all the Rembrandts in the Rijksmuseum – all 22 paintings, 60 drawings and the 300 best prints of his etchings – in one book. This idea arose during the exhibition of ‘all the Rembrandts in the Rijks’, and had to be produced at the speed of light in order to be available while the exhibition was still running. The result is a rugged paperback based on a very simple concept: the works on the right, whole page, and the short texts on the left; more than 800 such pages with never a dull moment. The lion’s share of the book is made up of the etchings, with the first and last pages of the book and the front cover showing details from these, beautifully revealing the artist’s signature nervy, genius scratching. The front cover, without text, works very well in combination with the nice bold typography of the title on the spine, which is more than six centimetres thick. The book opens well for a paperback with in excess of 800 pages. Every aspect is testimony to clear choices, deftly chosen limitations and superb execution.`}</p>
    <hr></hr>
    <p>{`Publisher: Rijksmuseum, Amsterdam`}<br parentName="p"></br>{`
`}{`Designer: Irma Boom, Amsterdam`}</p>
    <p>{`Category: Art, photo and architecture books`}<br parentName="p"></br>{`
`}{`Editor: Pauline Kintz, Jane Turner`}<br parentName="p"></br>{`
`}{`Language: Dutch, English`}<br parentName="p"></br>{`
`}{`Translator: Kist & Kilian`}<br parentName="p"></br>{`
`}{`Image editor: Ellen Slob`}<br parentName="p"></br>{`
`}{`Editor-in-chief: Geri Klazema, Barbera van Kooij`}<br parentName="p"></br>{`
`}{`Lay-out: Irma Boom, Amsterdam`}<br parentName="p"></br>{`
`}{`Lithographer: BFC graphics and design (Bert van der Horst), Amersfoort`}<br parentName="p"></br>{`
`}{`Printer: Wilco Art Books, Amersfoort`}<br parentName="p"></br>{`
`}{`Binder: Wilco Art Books, Amersfoort`}<br parentName="p"></br>{`
`}{`Font: Rijksmuseum`}<br parentName="p"></br>{`
`}{`Size in mm: 150 x 210 x 61`}<br parentName="p"></br>{`
`}{`Number of pages: 824`}<br parentName="p"></br>{`
`}{`Print run: 6,000`}<br parentName="p"></br>{`
`}{`Price: € 40`}<br parentName="p"></br>{`
`}{`ISBN: 978 94 6208 509 1`}<br parentName="p"></br>{`
`}{`Paper for interior: 100gsm Arcoprint Milk 1.5 (Fedrigoni)`}<br parentName="p"></br>{`
`}{`Cover: 300gsm Munken Print White 1.5 (Antalis)`}<br parentName="p"></br>{`
`}{`Binding style: Sewn softcover`}<br parentName="p"></br>{`
`}{`Special features: The cover and the interior are printed in Pantone 072.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      