import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`『The Kindness of One』は、1940 年に実業家でありオランダ領事だったヤン・ズヴェルテンディクがどのようにユダヤ人をリトアニアから日本へと脱出させたのかを、直感的かつパーソナルに探る本だ。過去、そして現在の難民の体験についてのリサーチプロジェクトとして、または共感、そしてささやかな行為としてこの物語は構成されている。印刷に使われているのは黒とシルバーのインクだけだが、さまざまな色のニュアンスを持っているような印象を受ける。茶と灰の混じったケルン紙と、明るめのムンケン・リンクスラフ紙という、用いられた 2 つの異なる用紙が優れた効果を生んでいる。より明確な意図のもと選択されているのは、リトアニアのパートがムンケン、日本のパートがケルンで刷られている点だろう。2 種類の紙の巧みな選択によって、本書を読み、見るという体験に触感の豊かさが加わっている。柔らかいブルーグレーの表紙と合わせて、作品を通して心地よくも陰影のある一貫性を有している。印刷には細心の注意が払われ、スイスブロシュア式の綴じによってページも美しく開く。書籍とは経験、調査、あるいはアートプロジェクトを伝えるものであり、同時にそれ自体が体験にも、美術作品にもなり得る。そして本書のように、崇高な存在にもなることができるのだ。`}</p>
    <hr></hr>
    <p>{`出版社： WeWest, West Graftdijk in association with Kaunas Photography Gallery, Kaunas (LT)`}<br parentName="p"></br>{`
`}{`デザイン： Akiko Wakabayashi, Amsterdam`}</p>
    <p>{`カテゴリー： アート、写真、建築`}<br parentName="p"></br>{`
`}{`アーティスト：Margaret Lansink, Rene van Hulst`}<br parentName="p"></br>{`
`}{`言語：英語`}<br parentName="p"></br>{`
`}{`写真：Margaret Lansink`}<br parentName="p"></br>{`
`}{`イメージ編集：Margaret Lansink, Akiko Wakabayashi`}<br parentName="p"></br>{`
`}{`編集：Katherine Oktober Matthews`}<br parentName="p"></br>{`
`}{`リトグラフ：Marc Gijzen beeldbewerking & digitale lithografie, Voorburg`}<br parentName="p"></br>{`
`}{`印刷：Drukkerij Tielen, Boxtel`}<br parentName="p"></br>{`
`}{`製本：Boekbinderij Patist, Den Dolder`}<br parentName="p"></br>{`
`}{`フォント：Fortescue Pro (Colophon Foundry)`}<br parentName="p"></br>{`
`}{`サイズ（ミリ）：190 x 270 x 10`}<br parentName="p"></br>{`
`}{`ページ数：120`}<br parentName="p"></br>{`
`}{`発行部数：500`}<br parentName="p"></br>{`
`}{`価格：€ 40`}<br parentName="p"></br>{`
`}{`ISBN：978 609 80 99 33 1`}<br parentName="p"></br>{`
`}{`本文用紙：100gsm Cairn Eco white (Christiaan Janssen), 120gsm Munken Lynx Rough (Antalis)`}<br parentName="p"></br>{`
`}{`カバー：250gsm Materica Acqua (Fedrigoni)`}<br parentName="p"></br>{`
`}{`製本方法：スイス装、背に透明糊付け、表表紙フラップ付き`}<br parentName="p"></br>{`
`}{`特記事項：中身は黒糸で綴じられ、Pantone877、スミベタ(K100%)と Ridge Black 高着色 RT195 で印刷。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      