import React from "react"
import css from "./index.module.scss"
import Layout from "../../../layouts/en"
import CommonDoc from "../../../components/CommonDoc"

const ExhivitorsEventPage = props => {
  return (
    <Layout location={props.location} title={`EXHIBITORS' EVENTS`}>
      <CommonDoc>
        <section className={`container`}>
          <div className={`content`}>
            <div className={`content__head`}>
              <h1 className={`haeding__lev1`}>EXHIBITORS' EVENTS</h1>
            </div>
            <div className={`content__lead`}>
              <p>
                Here are some other related events that are being held around
                the same time as VABF.
              </p>
            </div>

            <article className={css.ex__section}>
              <div className={`sub__head`}>
                <h2 className={`haeding__lev2`}>November 16, 2020 (Monday)</h2>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>11:00 – 19:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://kelechiazu.com/Tokyo-VABF-2020-Emo-Farm`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        Emo Farm book signing with an emo drawing of you!
                      </span>
                      @ Kelechi Azu
                    </a>
                  </dt>
                  <dd>Kelechi Azu</dd>
                </dl>
                <p className={css.ex__description}>
                  16th -20 attendees can submit an image for me to draw them as
                  an emo kid. This is a part of the book signing process. I
                  would like for exhibitors to email me a screenshot of their
                  emo designs they've generated on my website and along with a
                  photo of them selves to complete the drawing. The emo
                  generator on my website will allow them to look like a
                  character in the book. *I am currently creating this portion
                  on my website.
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>12:00 – JST</p>
                <dl>
                  <dt>
                    <a
                      href={`http://sloweditions.info`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>In the studio with Jesjit Gill</span>@ Slow Editions
                    </a>
                  </dt>
                  <dd>Jesjit Gill</dd>
                </dl>
                <p className={css.ex__description}>
                  Slow Editions invited Jesjit Gill to share a glimpse into his
                  studio in conjunction with the release of Gill's 'Dream Door'
                  edition. The feature will be on http://sloweditions.info from
                  Friday, November 16, 2020, to Monday, November 23, 2020.
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>17:00 – 19:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://twitter.com/stevenfraserart?lang=en`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>Sketch Request</span>@ Steven Fraser / Do It Theatre
                    </a>
                  </dt>
                  <dd>Steven Fraser</dd>
                </dl>
                <p className={css.ex__description}>
                  I will be giving sketches with all orders. If you would like
                  to request a personal sketch you can let me know via twitter.
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>17:00 – JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.youtube.com/channel/UCv7kr2llIg9mUHMiEsjTnvg/featured`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        POST/PHOTOの時代がやってきた
                        —来るべき「写真家」と注目すべき「写真集」の動向
                      </span>
                      @ G/P＋abp
                    </a>
                  </dt>
                  <dd>Yuki Tawada × Takashi Kwashima × Shigeo Goto</dd>
                </dl>
                <p className={css.ex__description}>
                  2010年代のデジタルトランスフォームによる写真の根底的なシフト。POST/PHOTOというキーワードを中心にコロナに刻印された2020年代の写真の行方を討議する。
                </p>
              </div>
            </article>

            <article className={css.ex__section}>
              <div className={`sub__head`}>
                <h2 className={`haeding__lev2`}>November 17, 2020 (Tuesday)</h2>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>All day</p>
                <dl>
                  <dt>
                    <a
                      href={`http://sloweditions.info`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>In the studio with Jesjit Gill</span>@ Slow Editions
                    </a>
                  </dt>
                  <dd>Jesjit Gill</dd>
                </dl>
                <p className={css.ex__description}>
                  Slow Editions invited Jesjit Gill to share a glimpse into his
                  studio in conjunction with the release of Gill's 'Dream Door'
                  edition. The feature will be on http://sloweditions.info from
                  Friday, November 16, 2020, to Monday, November 23, 2020.
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>11:00 – 19:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://kelechiazu.com/Tokyo-VABF-2020-Emo-Farm`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        Emo Farm book signing with an emo drawing of you!
                      </span>
                      @ Kelechi Azu
                    </a>
                  </dt>
                  <dd>Kelechi Azu</dd>
                </dl>
                <p className={css.ex__description}>
                  16th -20 attendees can submit an image for me to draw them as
                  an emo kid. This is a part of the book signing process. I
                  would like for exhibitors to email me a screenshot of their
                  emo designs they've generated on my website and along with a
                  photo of them selves to complete the drawing. The emo
                  generator on my website will allow them to look like a
                  character in the book. *I am currently creating this portion
                  on my website.
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>17:00 – 19:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://twitter.com/stevenfraserart?lang=en`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>Sketch Request</span>@ Steven Fraser / Do It Theatre
                    </a>
                  </dt>
                  <dd>Steven Fraser</dd>
                </dl>
                <p className={css.ex__description}>
                  I will be giving sketches with all orders. If you would like
                  to request a personal sketch you can let me know via twitter.
                </p>
              </div>
            </article>

            <article className={css.ex__section}>
              <div className={`sub__head`}>
                <h2 className={`haeding__lev2`}>
                  November 18, 2020 (Wednesday)
                </h2>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>All day</p>
                <dl>
                  <dt>
                    <a
                      href={`http://sloweditions.info`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>In the studio with Jesjit Gill</span>@ Slow Editions
                    </a>
                  </dt>
                  <dd>Jesjit Gill</dd>
                </dl>
                <p className={css.ex__description}>
                  Slow Editions invited Jesjit Gill to share a glimpse into his
                  studio in conjunction with the release of Gill's 'Dream Door'
                  edition. The feature will be on http://sloweditions.info from
                  Friday, November 16, 2020, to Monday, November 23, 2020.
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>11:00 – 19:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://kelechiazu.com/Tokyo-VABF-2020-Emo-Farm`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        Emo Farm book signing with an emo drawing of you!
                      </span>
                      @ Kelechi Azu
                    </a>
                  </dt>
                  <dd>Kelechi Azu</dd>
                </dl>
                <p className={css.ex__description}>
                  16th -20 attendees can submit an image for me to draw them as
                  an emo kid. This is a part of the book signing process. I
                  would like for exhibitors to email me a screenshot of their
                  emo designs they've generated on my website and along with a
                  photo of them selves to complete the drawing. The emo
                  generator on my website will allow them to look like a
                  character in the book. *I am currently creating this portion
                  on my website.
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>13:30 – 14:30 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.instagram.com/hnkseminar_kduniversity/`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>LIVE PAINT｛WE LOVE LINES_aadp｝　</span>@
                      KaoruHIRONAKA
                    </a>
                  </dt>
                  <dd>HNKzemi (ヒロナカゼミ)　/ 神戸芸術工科大学</dd>
                </dl>
                <p className={css.ex__description}>
                  作品監修：神戸芸術工科大学 HNKzemi
                  (ヒロナカゼミ)チームによるLIVE PAINT
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>17:00 – 19:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://twitter.com/stevenfraserart?lang=en`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>Sketch Request</span>@ Steven Fraser / Do It Theatre
                    </a>
                  </dt>
                  <dd>Steven Fraser</dd>
                </dl>
                <p className={css.ex__description}>
                  I will be giving sketches with all orders. If you would like
                  to request a personal sketch you can let me know via twitter.
                </p>
              </div>
            </article>

            <article className={css.ex__section}>
              <div className={`sub__head`}>
                <h2 className={`haeding__lev2`}>
                  November 19, 2020 (Thursday)
                </h2>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>All day</p>
                <dl>
                  <dt>
                    <a
                      href={`http://sloweditions.info`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>In the studio with Jesjit Gill</span>@ Slow Editions
                    </a>
                  </dt>
                  <dd>Jesjit Gill</dd>
                </dl>
                <p className={css.ex__description}>
                  Slow Editions invited Jesjit Gill to share a glimpse into his
                  studio in conjunction with the release of Gill's 'Dream Door'
                  edition. The feature will be on http://sloweditions.info from
                  Friday, November 16, 2020, to Monday, November 23, 2020.
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>06:00 – 07:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://vimeo.com/466374355`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>The Making of “Edith Heath: Philosophies”</span>@
                      Information Office
                    </a>
                  </dt>
                  <dd>
                    Derek Barnett (principal, Information Office), Chris Marino
                    (Curator, Environmental Design Archives, UC Berkeley), Rosa
                    Novak (writer), Jay Stewart (Trustee, Brian and Edith Heath
                    Foundation), Jennifer M. Volland (curator)
                  </dd>
                </dl>
                <p className={css.ex__description}>
                  A discussion centered around the making of the forthcoming
                  Environmental Design Archive publication "Edith Heath:
                  Philosophies.
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>11:00 – 19:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://kelechiazu.com/Tokyo-VABF-2020-Emo-Farm`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        Emo Farm book signing with an emo drawing of you!
                      </span>
                      @ Kelechi Azu
                    </a>
                  </dt>
                  <dd>Kelechi Azu</dd>
                </dl>
                <p className={css.ex__description}>
                  16th -20 attendees can submit an image for me to draw them as
                  an emo kid. This is a part of the book signing process. I
                  would like for exhibitors to email me a screenshot of their
                  emo designs they've generated on my website and along with a
                  photo of them selves to complete the drawing. The emo
                  generator on my website will allow them to look like a
                  character in the book. *I am currently creating this portion
                  on my website.
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>14:30 – 16:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://meet.google.com/iry-iwik-cxi`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        Exciting Risograph Demonstration in Seikatsu Tsudurikata
                        (Bookstore)
                      </span>
                      @ MISOGRAPH
                    </a>
                  </dt>
                  <dd>Daigo Kobayashi</dd>
                </dl>
                <p className={css.ex__description}>
                  Live demonstration of risograph owned by Seikatsu Tsudurikata
                  bookstore. Let's enjoy riso together while getting excited
                  after printing!
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>17:00 – 19:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://twitter.com/stevenfraserart?lang=en`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>Sketch Request</span>@ Steven Fraser / Do It Theatre
                    </a>
                  </dt>
                  <dd>Steven Fraser</dd>
                </dl>
                <p className={css.ex__description}>
                  I will be giving sketches with all orders. If you would like
                  to request a personal sketch you can let me know via twitter.
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>21:00 – JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.youtube.com/channel/UCv7kr2llIg9mUHMiEsjTnvg/featured`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>POST/PHOTO TALKING 01</span>@ G/P＋abp
                    </a>
                  </dt>
                  <dd>Rui Mizuki×Shigeo Goto</dd>
                </dl>
                <p className={css.ex__description}>
                  2010年代のデジタルトランスフォームによる写真の根底的なシフト。POST/PHOTOというキーワードを中心にコロナに刻印された2020年代の写真の行方を討議する。
                </p>
              </div>
            </article>

            <article className={css.ex__section}>
              <div className={`sub__head`}>
                <h2 className={`haeding__lev2`}>November 20, 2020 (Friday)</h2>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>All day</p>
                <dl>
                  <dt>
                    <a
                      href={`http://sloweditions.info`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>In the studio with Jesjit Gill</span>@ Slow Editions
                    </a>
                  </dt>
                  <dd>Jesjit Gill</dd>
                </dl>
                <p className={css.ex__description}>
                  Slow Editions invited Jesjit Gill to share a glimpse into his
                  studio in conjunction with the release of Gill's 'Dream Door'
                  edition. The feature will be on http://sloweditions.info from
                  Friday, November 16, 2020, to Monday, November 23, 2020.
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>11:00 – 19:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://kelechiazu.com/Tokyo-VABF-2020-Emo-Farm`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        Emo Farm book signing with an emo drawing of you!
                      </span>
                      @ Kelechi Azu
                    </a>
                  </dt>
                  <dd>Kelechi Azu</dd>
                </dl>
                <p className={css.ex__description}>
                  16th -20 attendees can submit an image for me to draw them as
                  an emo kid. This is a part of the book signing process. I
                  would like for exhibitors to email me a screenshot of their
                  emo designs they've generated on my website and along with a
                  photo of them selves to complete the drawing. The emo
                  generator on my website will allow them to look like a
                  character in the book. *I am currently creating this portion
                  on my website.
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>12:30 – 14:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://meet.google.com/nvo-djvv-ssg`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>Chie store keeper</span>@ #itgirlie
                    </a>
                  </dt>
                  <dd>Chie</dd>
                </dl>
                <p className={css.ex__description}>
                  Zine model Chie will be in the store. Say whatever you want.
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>13:00 – 15:00 JST </p>
                <dl>
                  <dt>
                    <a
                      href={`https://us02web.zoom.us/j/86293523230`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>くにこ先生と横山先生の役に立たないW人生相談</span>@
                      888Books × KunikoNagasaki+ rn press
                    </a>
                  </dt>
                  <dd>Yuichi Yokoyama＋Kuniko Nagasaki</dd>
                </dl>
                <p className={css.ex__description}>
                  漫画家/美術家の横山裕一先生とイラストレーターの長崎訓子先生が、ファンのみなさまのお悩みにズバリこたえます。
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>13:00 – 15:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://us05web.zoom.us/j/86761000389?pwd=VTFJK0hkaVJybXRqQU8rRVZKaTl2UT09`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        山口一郎さんによるポスター色つけライブパフォーマンス
                      </span>
                      @ BOOKMARUTE
                    </a>
                  </dt>
                  <dd>Ichiro Yamaguchi</dd>
                </dl>
                <p className={css.ex__description}>
                  山口一郎さんにお越しいただきポスターの色つけのライブパフォーマンスをしていただきます。ポスターは、当店のオンラインストアにてご購入していただけます。
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>15:00 – 16:30 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://meet.google.com/ity-wekz-fch`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>臨時オンライン彫刻</span>@ SPE Editorial Board
                    </a>
                  </dt>
                  <dd>SPE Editorial Board</dd>
                </dl>
                <p className={css.ex__description}>
                  「身体性と距離感、実存とバーチャル」をテーマに話し合います。社会彫刻に興味のある方、バーチャル空間に違和感のある方、お待ちしております。
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>15:00 – 16:00 JST </p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.youtube.com/channel/UC4hrE5AjR_9rQvfl-A5lv1A/videos`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>Daily Report Extras</span>@ Daily Report
                    </a>
                  </dt>
                  <dd>
                    Teras Belakang Studio, Band Performance: Fleur, Zzaf,
                    Texpack, Dj set The Sastro
                  </dd>
                </dl>
                <p className={css.ex__description}>
                  The Daily Report (visual) collaborates with the Teras Belakang
                  Studio (music) as a form of activation for the 2020 Virtual
                  Art Book Fair (Tokyo Art Book Fair 2020) on November 16 to
                  23.The activation was held in the form of a live stream show
                  with the social media platform Youtube. The content of the
                  live stream show is a product showcase & music performance.The
                  event will be held for 2 days, the day of which lasts 1 hour
                  (conditional).
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>17:00 – 18:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.youtube.com/watch?v=vAdpJ2ywL_U`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>Daily Report Extras</span>@ Daily Report
                    </a>
                  </dt>
                  <dd>Fleur, Zzuf, Texpack, and Karon n Roll.</dd>
                </dl>
                <p className={css.ex__description}>
                  In collaboration with Studio Teras Belakang, Daily Report
                  present Daily Report Extras. This is a virtual show run
                  exclusively for the 2020 edition of Tokyo Art Book Fair. This
                  show is streamed and will be archived on Daily Report Extras
                  YouTube channel.
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>17:00 – 19:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://twitter.com/stevenfraserart?lang=en`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>Sketch Request</span>@ Steven Fraser / Do It Theatre
                    </a>
                  </dt>
                  <dd>Steven Fraser</dd>
                </dl>
                <p className={css.ex__description}>
                  I will be giving sketches with all orders. If you would like
                  to request a personal sketch you can let me know via twitter.
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>18:00 – 19:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.youtube.com/channel/UCGLCdJbLVbzZXJ2hpjSX2nQ`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        CMYK Photoshop Tutorial by Printing Director Noboru
                        Takayanagi
                      </span>
                      @ TOKYO INSHOKAN PRINTING CO.,LTD.
                    </a>
                  </dt>
                  <dd>
                    Noboru Takayanagi/Masahiko Yamaguchi/Shusuke Shimonaka
                    (TOKYO INSHOKAN PRINTING CO.,LTD.)
                  </dd>
                </dl>
                <p className={css.ex__description}>
                  TOKYO INSHOKAN's Printing Director Noboru takayanagi will show
                  tips on how to express light effectively in photo book
                  printing by Photoshop.The special tutorial will be streamed on
                  YouTube Live.
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>18:00 – 19:30 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://youtu.be/xWncBcEBL2E`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        Poetics and Politics of Erasure — Yun Lee Publication
                        Launch
                      </span>
                      @ oneacre.online
                    </a>
                  </dt>
                  <dd>Yun Lee with oneacre.online</dd>
                </dl>
                <p className={css.ex__description}>
                  oneacre.online is pleased to invite you to the publication
                  launch of Yun Lee— Poetics and Politics of Erasure, please
                  join us on Friday 20 November between 10:00 and 11:30 at the
                  following link.〈PROGRAM〉Introduction by oneacre.online /
                  Reading by Yun Lee / Panel Discussion
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>18:30 – 19:30 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.instagram.com/kaoruhironaka/`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>LIVE PAINT｛WE LOVE LINES_aadp｝</span>@
                      KaoruHIRONAKA
                    </a>
                  </dt>
                  <dd>AADP(Art and Design Project / Kobe Design University)</dd>
                </dl>
                <p className={css.ex__description}>
                  作品監修：神戸芸術工科大学の学科横断(イメージ基礎)チームによる「LINE遊び」=
                  LINES _LIVE PAINT
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>19:00 – 21:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.facebook.com/Reminders.Photography.Stronghold`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        "PHOTOBOOK AS OBJECT / PHOTOBOOK WHO CARES AT VIRTUAL
                        ART BOOK FAIR" Read our photobooks with the author
                      </span>
                      @ PHOTOBOOK AS OBJECT PHOTOBOOK WHO CARES
                    </a>
                  </dt>
                  <dd>
                    Elena Kholkina, Hiroshi Okamoto, Hajime Kimura, Junpei Ueda,
                    Kanta Nomura, Kenji Chiga, Louis Lim, Wang Lu, Mara Catalan,
                    Moe Suzuki, Ryo Kusumoto, Yoshikatsu Fujii, Yukari Chikura,
                    Yusuke Takagi
                  </dd>
                </dl>
                <p className={css.ex__description}>
                  This event will allow you to listen to a presentation of their
                  photobooks by the author and read the books at the same time.
                  The event will also be streamed on Facebook Live.
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>20:00 – JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://instagram.com/pgi_gallery/`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        川田喜久治「20」M Label by bookshop M
                        「作品を写真集にすること」
                      </span>
                      @ PGI
                    </a>
                  </dt>
                  <dd>Hikari Machiguchi x Yohei Kawada x Akira Takahashi</dd>
                </dl>
                <p className={css.ex__description}>
                  2021年初春にM
                  Labelで発刊予定の川田喜久治「20」。写真集にする作業が今まさに始まったばかり本について、作品を写真集にする作業、作り手の頭の中を探ります。出版記念トークは数あれど、未出版についての本についてのトーク。bookshop
                  Mの浅草支部からお届けします。どうぞお楽しみに！
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>20:00 – 21:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://us04web.zoom.us/j/77311395151?pwd=KzNiYnZENnZoclVOU3ZvMElTV2RIdz09`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>Let's drink together! Event</span>@ yoranhan2people
                    </a>
                  </dt>
                  <dd>Jaeyi Park , Sim Haeji </dd>
                </dl>
                <p className={css.ex__description}>
                  an event aimed at making questions about books and
                  introductions to books more friendly while drinking together.
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>21:00 – 22:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.instagram.com/remote_collage/?hl=ja`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>real time session</span>@ remote collage
                    </a>
                  </dt>
                  <dd>Rina Kimura/Ayari Nakamura/Momoko Negishi</dd>
                </dl>
                <p className={css.ex__description}>
                  We will live-stream session in real time on the official
                  remote collage Instagram.
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>Irregular hour</p>
                <dl>
                  <dt>
                    <a
                      href={`https://meet.google.com/fde-hnxy-zuz?pli=1&authuser=1`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        Artist talk of the photo book "SCULPTURE in the CITY"
                        and other works on display, sculpture demonstration
                      </span>
                      @ Masaki Yamaguchi / Photographic Works
                    </a>
                  </dt>
                  <dd>Masaki Yamaguchi (the artist himself)</dd>
                </dl>
                <p className={css.ex__description}>
                  It will be delivered daily on Google Meet during the period.
                </p>
              </div>
            </article>

            <article className={css.ex__section}>
              <div className={`sub__head`}>
                <h2 className={`haeding__lev2`}>
                  November 21, 2020 (Saturday)
                </h2>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>All day</p>
                <dl>
                  <dt>
                    <a
                      href={`http://sloweditions.info`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>In the studio with Jesjit Gill</span>@ Slow Editions
                    </a>
                  </dt>
                  <dd>Jesjit Gill</dd>
                </dl>
                <p className={css.ex__description}>
                  Slow Editions invited Jesjit Gill to share a glimpse into his
                  studio in conjunction with the release of Gill's 'Dream Door'
                  edition. The feature will be on http://sloweditions.info from
                  Friday, November 16, 2020, to Monday, November 23, 2020.
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>10:00 – 11:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.instagram.com/sloweditions/`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>Turn the pages: Artist book hour</span>@ Slow
                      Editions
                    </a>
                  </dt>
                  <dd>Eunice Luk</dd>
                </dl>
                <p className={css.ex__description}>
                  Join Eunice Luk @sloweditions for a 1-hour show and tell on
                  Instagram Live. Luk will present a variety of artist books
                  from her collection.
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>11:00 – 13:00 JST </p>
                <dl>
                  <dt>
                    <a
                      href={`https://montgomerycollege.zoom.us/j/3226439703?pwd=MnFqMzd3N3FDbS9GZ1RHYTFvTTBUUT09`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>Author Readings</span>@ Sybil Press
                    </a>
                  </dt>
                  <dd>
                    Jillian Gomez, Norberto Gomez, Sara Goodman, and Carrie
                    Rennolds
                  </dd>
                </dl>
                <p className={css.ex__description}>
                  A short introduction: Join Sybil Press co-founders, and
                  special guests, as they do some live readings. The mic will
                  also be open to anyone who would like to read material along
                  with us.
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>11:00 – 12:00 JST </p>
                <dl>
                  <dt>
                    <a
                      href={`https://meet.google.com/zpy-wtxq-fcb`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>An Art Book Conversation #1</span>@ Art Byte
                      Critique
                    </a>
                  </dt>
                  <dd>Deanna Gabiga, Yuko Kamei, Janette Maxey and Lori Ono</dd>
                </dl>
                <p className={css.ex__description}>
                  These four Art Byte Critique art book creators will have a
                  roundtable discussion and answer questions regarding their art
                  book making processes and participation in the Tokyo Art Book
                  Fair.
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>11:00 – JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.instagram.com/klassic_kool_shoppe/`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        Alicia's Klassic Kool Shoppe presents a music
                        performance by Carl Didur
                      </span>
                      @ Alicia's Klassic Kool Shoppe
                    </a>
                  </dt>
                  <dd>Carl Didur</dd>
                </dl>
                <p className={css.ex__description}>
                  Carl Didur (Zacht Automaat) attempts to cross the gap between
                  species with gentle arpeggios on the Electric Piano, playing
                  for the most aloof, yet intimate audience: his elderly cat
                  Hibou.
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>12:00 – JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://instagram.com/pgi_gallery/`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>写真集について</span>@ PGI
                    </a>
                  </dt>
                  <dd>Yuji Hamada x Akira Takahashi</dd>
                </dl>
                <p className={css.ex__description}>
                  これまで出版して来た写真集について作家本人にお話を伺います。特に2021年初春にミュゼ浜口陽三・ヤマサコレクションにて展示予定のC/M/Yについて。作品コンセプトを本に再構築した写真集の魅力を改めて解説。
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>12:00 – 13:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.instagram.com/remote_collage/?hl=ja`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>real time session</span>@ remote collage
                    </a>
                  </dt>
                  <dd>Rina Kimura/Ayari Nakamura/Momoko Negishi</dd>
                </dl>
                <p className={css.ex__description}>
                  We will live-stream session in real time on the official
                  remote collage Instagram.
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>13:00 – 15:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://us05web.zoom.us/j/89541792252?pwd=Y1RIOE94TkdtVXlpUWlGTUMrdWFjQT09`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        山口一郎さんによるポスター色つけライブパフォーマンス
                      </span>
                      @ BOOKMARUTE
                    </a>
                  </dt>
                  <dd>Ichiro Yamaguchi</dd>
                </dl>
                <p className={css.ex__description}>
                  山口一郎さんにお越しいただきポスターの色つけのライブパフォーマンスをしていただきます。ポスターは、当店のオンラインストアにてご購入していただけます。
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>14:00 – JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.instagram.com/shortbangs`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>Live Drawing</span>@ Yuki MIKAMI
                    </a>
                  </dt>
                  <dd>Yuki MIKAMI</dd>
                </dl>
                <p className={css.ex__description}>
                  ライブドローイングをします。数分で終了予定です。お見逃しなく！
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>14:00 – 15:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://meet.google.com/vjn-pvzs-ecp`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        Public reading event "Japanese old fairy tales(EMOJI
                        version)"{" "}
                      </span>
                      @ ORADANO BOOKS YAMAGATA
                    </a>
                  </dt>
                  <dd>Jin Sotome、Yurie Sotome</dd>
                </dl>
                <p className={css.ex__description}>
                  Realtime public reading event about "Japanese old fairy
                  tales(EMOJI version).This book contains some old fairy tales
                  are written only in EMOJI.People all over the world will
                  understand wonderful stories.We will read "Momotaro"and more."
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>15:00 – 17:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.youtube.com/channel/UCGLCdJbLVbzZXJ2hpjSX2nQ`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        Printing Director Noboru Takayanagi on photo books he
                        printed with Q&A session
                      </span>
                      @ TOKYO INSHOKAN PRINTING CO.,LTD.
                    </a>
                  </dt>
                  <dd>Noboru Takayanagi/Hiroshi Okumura/Shusuke Shimonaka</dd>
                </dl>
                <p className={css.ex__description}>
                  TOKYO INSHOKAN's Printing Director Noboru takayanagi will show
                  tips on how to express light effectively in photo book
                  printing by Photoshop.The special tutorial will be streamed on
                  YouTube Live.
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>16:00 – 18:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.instagram.com/kaoruhironaka/`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span> [TOWER- DRAWING｝</span>@ KaoruHIRONAKA
                    </a>
                  </dt>
                  <dd>KaoruHIRONAKA & kobe Artists</dd>
                </dl>
                <p className={css.ex__description}>
                  November 21, Site-specific Drawing at Kobe-Port tower.A huge
                  space where a solo exhibition is currently being held.It is
                  also the 57th birthday of the tower.
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>18:00 – 18:30 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.instagram.com/yuichiro_higashiji_form`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>Book Cutting for Mountain</span>@ crevasse + Miriam
                      Gallery
                    </a>
                  </dt>
                  <dd>Higashiji Yuichiro</dd>
                </dl>
                <p className={css.ex__description}>
                  We will be performing a cut of that Mountain you're all
                  familiar with. You can see a cross-section of the Mountain.
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>18:00 – 18:30 JST </p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.instagram.com/blankt_papir/`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        Talk with artist Janemaria Mekoline Pedersen about her
                        book “Dysfertil"
                      </span>
                      @ Blankt Papir Press
                    </a>
                  </dt>
                  <dd>Julia Mejnertsen and Janemaria Mekoline Pedersen</dd>
                </dl>
                <p className={css.ex__description}>
                  Janemaria Mekoline Pedersen will talk about her newly
                  published photographic artist book “Dysfertil”.
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>20:00 – JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.youtube.com/channel/UCv7kr2llIg9mUHMiEsjTnvg/featured`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>POST/PHOTO TALKING 02</span>@ G/P＋abp
                    </a>
                  </dt>
                  <dd>
                    Hideki Umezawa×Shigeo Goto, Yutarou Haneji×Shigeo Goto,
                    Keita Kojima×Shigeo Goto
                  </dd>
                </dl>
                <p className={css.ex__description}>
                  2010年代のデジタルトランスフォームによる写真の根底的なシフト。POST/PHOTOというキーワードを中心にコロナに刻印された2020年代の写真の行方を討議する。
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>Irregular</p>
                <dl>
                  <dt>
                    <a
                      href={`https://meet.google.com/fde-hnxy-zuz?pli=1&authuser=1`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        Artist talk of the photo book "SCULPTURE in the CITY"
                        and other works on display, sculpture demonstration
                      </span>
                      @ Masaki Yamaguchi / Photographic Works
                    </a>
                  </dt>
                  <dd>Masaki Yamaguchi (the artist himself)</dd>
                </dl>
                <p className={css.ex__description}>
                  It will be delivered daily on Google Meet during the period.
                </p>
              </div>
            </article>

            <article className={css.ex__section}>
              <div className={`sub__head`}>
                <h2 className={`haeding__lev2`}>November 22, 2020 (Sunday)</h2>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>All day</p>
                <dl>
                  <dt>
                    <a
                      href={`http://sloweditions.info`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>In the studio with Jesjit Gill</span>@ Slow Editions
                    </a>
                  </dt>
                  <dd>Jesjit Gill</dd>
                </dl>
                <p className={css.ex__description}>
                  Slow Editions invited Jesjit Gill to share a glimpse into his
                  studio in conjunction with the release of Gill's 'Dream Door'
                  edition. The feature will be on http://sloweditions.info from
                  Friday, November 16, 2020, to Monday, November 23, 2020.
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>11:00 – 13:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://montgomerycollege.zoom.us/j/3226439703?pwd=MnFqMzd3N3FDbS9GZ1RHYTFvTTBUUT09`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>Author Readings</span>@ Sybil Press
                    </a>
                  </dt>
                  <dd>
                    Jillian Gomez, Norberto Gomez, Sara Goodman, and Carrie
                    Rennolds
                  </dd>
                </dl>
                <p className={css.ex__description}>
                  A short introduction: Join Sybil Press co-founders, and
                  special guests, as they do some live readings. The mic will
                  also be open to anyone who would like to read material along
                  with us.
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>13:00 – 14:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://us02web.zoom.us/j/83683246233`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        「マンガとエッセイ、フィクションとノンフィクション」
                      </span>
                      @ KunikoNagasaki＋rn books
                    </a>
                  </dt>
                  <dd>Mai Okafuji、Rie Noguchi</dd>
                </dl>
                <p className={css.ex__description}>
                  「USO2」に漫画を寄稿している岡藤真依さんと、エッセイ（漫画）を描くことについてお話しします。
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>13:00 – 19:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://zoom.us/j/8962850108?pwd=MjljUmkzWW5mMXYyY0tPTUM5YytQUT09`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>"宇宙人似顔絵" （仲介人：堀 道広）※要予約</span>@
                      UTRECHT
                    </a>
                  </dt>
                  <dd>Michihiro Hori</dd>
                </dl>
                <p className={css.ex__description}>
                  仮想世界からあなたの似顔絵がハガキで届きます。11/17より
                  <a
                    href={`https://utrecht.jp`}
                    target={`_blank`}
                    className={`link__underline link__blank`}
                  >
                    ユトレヒトサイト
                  </a>
                  で受付
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>13:00 – 14:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.publ-site.org/laughter/content/video/`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>PAGE TOWN - Live Collage</span>@ Yasushi Cho
                    </a>
                  </dt>
                  <dd>Yasushi Cho</dd>
                </dl>
                <p className={css.ex__description}>
                  The page town is collage work which Yasushi Cho keeps making.
                  Maps on the advertisement flyers posted every day would be
                  collaged as a fictional town map.
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>13:00 – 16:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://zoom.us/j/3972530638?pwd=T1FxRXl3TjRJMzVvRUNmeFAzeEhWQT09`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>Silkscreen Clinic</span>@ NEW POSITIONS patterned
                      papers
                    </a>
                  </dt>
                  <dd>
                    Hiroshi Matsumura, Tomomi Tanaka / CLEAR GRAPHIC,Sachiko
                    Uehara / NEW POSITIONS patterned papers
                  </dd>
                </dl>
                <p className={css.ex__description}>
                  Hiroshi Matsumura is a professional printer. He has worked on
                  the works of many artists such as Ikko Tanaka since 1980. In
                  this event, he answers your questions of silkscreen print.
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>14:00 – JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.instagram.com/shortbangs`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>Live Drawing</span>@ Yuki MIKAMI
                    </a>
                  </dt>
                  <dd>Yuki MIKAMI</dd>
                </dl>
                <p className={css.ex__description}>
                  ライブドローイングをします。数分で終了予定です。お見逃しなく！
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>15:00 – 16:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.youtube.com/channel/UC4hrE5AjR_9rQvfl-A5lv1A/videos`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>Daily Report Extras</span>@ Daily Report
                    </a>
                  </dt>
                  <dd>Fleur, Zzuf, Texpack, and Karon n Roll</dd>
                </dl>
                <p className={css.ex__description}>
                  In collaboration with Studio Teras Belakang, Daily Report
                  present Daily Report Extras. This is a virtual show run
                  exclusively for the 2020 edition of Tokyo Art Book Fair. This
                  show is streamed and will be archived on Daily Report Extras
                  YouTube channel.
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>16:00 – 17:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://us02web.zoom.us/j/83391328872?pwd=YzNZeW5XYmlyNk85OHdTZDlFaWJnQT09`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>Small Talk with Prabda Yoon</span>@ SOI BOOKS
                    </a>
                  </dt>
                  <dd>Prabda Yoon, Sho Fukutomi（Interpreter）</dd>
                </dl>
                <p className={css.ex__description}>
                  A small talk with Thai writer, Prabda Yoon. Let's hear about
                  his latest short stories, a Netflix drama he produces, and
                  cultural trend in Thailand.
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>17:00 – 18:00 JST </p>
                <dl>
                  <dt>
                    <a
                      href={`https://meet.google.com/xfx-ihay-xdr`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>An Art Book Conversation #2</span>@ Art Byte
                      Critique
                    </a>
                  </dt>
                  <dd>Arthur Huang, Eva Li, Misako Oba, Nick West</dd>
                </dl>
                <p className={css.ex__description}>
                  These four Art Byte Critique art book creators will have a
                  roundtable discussion and answer questions regarding their art
                  book making processes and participation in the Tokyo Art Book
                  Fair.
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>17:30 - 18:30 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://us02web.zoom.us/j/83391328872?pwd=YzNZeW5XYmlyNk85OHdTZDlFaWJnQT09`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        Momoko's Thai Cooking in Misaki, Miura-shi, Kanagawa
                      </span>
                      @ SOI BOOKS
                    </a>
                  </dt>
                  <dd>
                    <a
                      href={`https://instagram.com/momokothaicookmortartokyo`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      Momoko（a Thai food lover in Japan）
                    </a>
                  </dd>
                </dl>
                <p className={css.ex__description}>
                  A Thai food lover Momoko will cook and eat Thai food
                  spontaneously with fresh ingredients that she will buy on the
                  day at a small fishing market town Misaki in Kanagawa.
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>19:00 – JST</p>
                <dl>
                  <dt>
                    <a
                      href={`http://www.emon-arc.com/`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        EMON Archives Online Artist Profile 2020 by EMON PHOTO
                        GALLERY 03.Kiiro
                      </span>
                    </a>
                    @ Kiiro
                  </dt>
                  <dd>Kiiro, EMON PHOTO GALLERY</dd>
                </dl>
                <p className={css.ex__description}>
                  オンラインを使った映像ドキュメンタリー『EMON
                  Archives』は、記録メディアを使って挑戦を続ける作家Kiiroにフォーカス。
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>20:30 – 21:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://us02web.zoom.us/j/83391328872?pwd=YzNZeW5XYmlyNk85OHdTZDlFaWJnQT09`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>Momoko Motion MIni live show</span>@ SOI BOOKS
                    </a>
                  </dt>
                  <dd>
                    <a
                      href={`https://instagram.com/momokothaicookmortartokyo`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      Momoko Motion（Singer-Songwriter）
                    </a>
                  </dd>
                </dl>
                <p className={css.ex__description}>
                  Momoko Motion will sing a few song from the Album "Punk in a
                  Coma" which she record and released in Thailand. She will talk
                  about the episode about the creation in Thailand, too.
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>22:00 – JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.instagram.com/sloweditions/`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>Aki Tsuyuko's Space 」</span>@ Slow Editions
                    </a>
                  </dt>
                  <dd>Aki Tsuyuko</dd>
                </dl>
                <p className={css.ex__description}>
                  Join us @sloweditions on Instagram for an intimate performance
                  by Aki Tsuyuko.
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>24:00 – JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.instagram.com/kaoruhironaka/`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>N I W 制作ムービー 発信</span>@ KaoruHIRONAKA
                    </a>
                  </dt>
                  <dd>collaboration books(designer Ushiyam X HNK ) Team</dd>
                </dl>
                <p className={css.ex__description}>
                  「 N I W」：デザイナーUshiyam と
                  HNKによるコラボレーション・チームです。Bricolage zine 制作
                  (working in progress) を ONAIR 公開。出来上がったzine は
                  、最終日11/23に本棚へ置きます。
                </p>
              </div>
              <div className={css.ex__unit}>
                <p className={css.ex__date}>Irregular </p>
                <dl>
                  <dt>
                    <a
                      href={`https://meet.google.com/fde-hnxy-zuz?pli=1&authuser=1`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        Artist talk of the photo book "SCULPTURE in the CITY"
                        and other works on display, sculpture demonstration
                      </span>
                      @ Masaki Yamaguchi / Photographic Works
                    </a>
                  </dt>
                  <dd>Masaki Yamaguchi </dd>
                </dl>
                <p className={css.ex__description}>
                  It will be delivered daily on Google Meet during the period.
                </p>
              </div>
            </article>

            <article className={css.ex__section}>
              <div className={`sub__head`}>
                <h2 className={`haeding__lev2`}>
                  November 23, 2020 (Monday / Holiday)
                </h2>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>All day</p>
                <dl>
                  <dt>
                    <a
                      href={`http://sloweditions.info`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>In the studio with Jesjit Gill</span>@ Slow Editions
                    </a>
                  </dt>
                  <dd>Jesjit Gill</dd>
                </dl>
                <p className={css.ex__description}>
                  Slow Editions invited Jesjit Gill to share a glimpse into his
                  studio in conjunction with the release of Gill's 'Dream Door'
                  edition. The feature will be on http://sloweditions.info from
                  Friday, November 16, 2020, to Monday, November 23, 2020.
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>12:00 – JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://instagram.com/pgi_gallery/`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>新刊写真集『糸をつむぐ』について</span>@ PGI
                    </a>
                  </dt>
                  <dd>Munemasa Takahashi x Akira Takahashi</dd>
                </dl>
                <p className={css.ex__description}>
                  11月1日に出版された新刊「糸をつむぐ」についてお話を伺います。出版レーベルVEROを立ち上げ、デザイナーと二人三脚で本を作る高橋宗正さん。本の内容についてはもとより、自費出版のこともお聞きします。
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>13:00 – JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://us02web.zoom.us/j/88994714604`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>イラストレーションと発注</span>@ KunikoNagasaki+ rn
                      press
                    </a>
                  </dt>
                  <dd>
                    若林恵（黒鳥社）、長崎訓子 / 司会：野口理恵（rn press）
                  </dd>
                </dl>
                <p className={css.ex__description}>
                  編集者である若林恵さんをゲストに雑誌や書籍のイラストレーションの発注事情についてお話しします。
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>13:00 – 14:30 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://us05web.zoom.us/j/81354106088?pwd=STRoRVVhSHRraVBobmhEbWEyazA0QT09`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        TALK EVENT: Fossa Magna- Their landscapes @VABF
                      </span>
                      @ assignments
                    </a>
                  </dt>
                  <dd>Ko Takenouchi / Yukiko Shikata / Atsuko Murano Abalos</dd>
                </dl>
                <p className={css.ex__description}>
                  2020年3月POST で開催されたアバロス村野敦子「Fossa
                  Magna-彼らの露頭と堆積」展示期間に予定、感染症流行の影響で延期されていたトークイベント。
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>13:00 – 16:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://zoom.us/j/3972530638?pwd=T1FxRXl3TjRJMzVvRUNmeFAzeEhWQT09`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>Silkscreen Clinic</span>@ NEW POSITIONS patterned
                      papers
                    </a>
                  </dt>
                  <dd>
                    Hiroshi Matsumura, Tomomi Tanaka / CLEAR GRAPHIC,Sachiko
                    Uehara / NEW POSITIONS patterned papers
                  </dd>
                </dl>
                <p className={css.ex__description}>
                  Hiroshi Matsumura is a professional printer. He has worked on
                  the works of many artists such as Ikko Tanaka since 1980. In
                  this event, he answers your questions of silkscreen print.
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>14:00 – 16:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://youtu.be/zyav1omwosw`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>Online presentation</span>@ 21th Century home works
                      :MeMe Design School
                    </a>
                  </dt>
                  <dd>
                    Akihisa Kodama, Yuta Tsukada, Saya Nakao, Ami Honda, Masako
                    Mitsuhashi, Makoto Miyoshi, Tetsuya Yokoo
                  </dd>
                </dl>
                <p className={css.ex__description}>
                  参加者によるプレゼンテーション。制作に至る契機、コンセプト、アプローチなど発表を行います。ミームデザイン学校代表
                  中垣信夫校長にも参加いただく予定です。
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>15:00 – 16:00 JST</p>
                <dl>
                  <dt>
                    <a
                      href={`https://www.instagram.com/remote_collage/?hl=ja`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>real time session</span>@ remote collage
                    </a>
                  </dt>
                  <dd>Rina Kimura / Ayari Nakamura / Momoko Negishi</dd>
                </dl>
                <p className={css.ex__description}>
                  We will live-stream session in real time on the official
                  remote collage Instagram.
                </p>
              </div>

              <div className={css.ex__unit}>
                <p className={css.ex__date}>Irregular</p>
                <dl>
                  <dt>
                    <a
                      href={`https://meet.google.com/fde-hnxy-zuz?pli=1&authuser=1`}
                      target={`_blank`}
                      className={`link__underline link__blank`}
                    >
                      <span>
                        Artist talk of the photo book "SCULPTURE in the CITY"
                        and other works on display, sculpture demonstration
                      </span>
                      @ Masaki Yamaguchi / Photographic Works
                    </a>
                  </dt>
                  <dd>Masaki Yamaguchi (the artist himself)</dd>
                </dl>
                <p className={css.ex__description}>
                  It will be delivered daily on Google Meet during the period.
                </p>
              </div>
            </article>
          </div>
          {/* /.content */}
        </section>
        {/* /.container */}
      </CommonDoc>
    </Layout>
  )
}

export default ExhivitorsEventPage
