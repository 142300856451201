import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.en.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Stages is a photographic survey of the places where art and crafts are produced, all over the world and at different times. By choosing a rigorous perspective strictly adhered to – photography from directly overhead – the multi-coloured aesthetics of these places is revealed and the specific cultural production processes shown take on a mysterious and fascinating, but also universal, aspect. Little wonder then that a book about the value of the physical production of culture and the materialisation process of culture and identity is itself also explicitly taken as the result of such a physical production process. For example, the greyboard used for the cover is visible, the linen on the spine is highlighted in a striking orange colour, the yarn used to sew the sections is coloured and the photography sections are on coated paper, distinguishing them from the documenting sections, which are on greyish recycled Recystar® Nature. The typography on the front and back covers consists of exemplary embossing in foil. The print and lithography deliver a marvellously open, crisp printed product. The picture editing is by the artists themselves, and Céline Hurka has achieved an intelligent materialisation of this. The result is an ode to and manifestation of the places where art and culture are produced, wherever in the world these may be.`}</p>
    <hr></hr>
    <p>{`Publisher: Onomatopee Projects, Eindhoven`}<br parentName="p"></br>{`
`}{`Designer: Céline Hurka, Den Haag`}</p>
    <p>{`Category: Art, photo and architecture books`}<br parentName="p"></br>{`
`}{`Artist: Katrin Korfmann, Jens Pfeifer`}<br parentName="p"></br>{`
`}{`Editor: Katrin Korfmann, Jens Pfeifer`}<br parentName="p"></br>{`
`}{`Language: English`}<br parentName="p"></br>{`
`}{`Translator: Nanne op ’t Ende, Josh Plough, Pernilla Ellens`}<br parentName="p"></br>{`
`}{`Photographer: Katrin Korfmann, Jens Pfeifer`}<br parentName="p"></br>{`
`}{`Image editor: Katrin Korfmann, Jens Pfeifer`}<br parentName="p"></br>{`
`}{`Editor-in-chief: Katrin Korfmann, Jens Pfeifer`}<br parentName="p"></br>{`
`}{`Lithographer: Unicum | Gianotten Printed Media (Gerrie van Beek), Tilburg`}<br parentName="p"></br>{`
`}{`Printer: Unicum | Gianotten Printed Media, Tilburg`}<br parentName="p"></br>{`
`}{`Binder: Boekbinderij Van Mierlo, Nijmegen`}<br parentName="p"></br>{`
`}{`Font: Junior (Selina Bernet)`}<br parentName="p"></br>{`
`}{`Size in mm: 225 x 285 x 10`}<br parentName="p"></br>{`
`}{`Number of pages: 124`}<br parentName="p"></br>{`
`}{`Print run: 700`}<br parentName="p"></br>{`
`}{`Price: € 27`}<br parentName="p"></br>{`
`}{`ISBN: 978 94 93148 06 2`}<br parentName="p"></br>{`
`}{`Paper for interior: 150gsm Condat Matt Périgord (Papier Union), 100gsm and Recystar® Nature (Papyrus)`}<br parentName="p"></br>{`
`}{`Endpapers: 140gsm Sirio Color Pietra (Fedrigoni)`}<br parentName="p"></br>{`
`}{`Binding: 120gsm Wibalin® Natural 500 White (Winter & Company) over 2.5 mm Eskaboard (Koninklijke Moorman Karton)`}<br parentName="p"></br>{`
`}{`Binding style: Sewn hardcover with endpapers glued to boards, spine glued with linen (Brillianta® 4196 - Van Heek Textiles)`}<br parentName="p"></br>{`
`}{`Special features: The binding has grey threads, the foil stamping in glossy black (Kurz Luxor 362) by Boekbinderij Van Mierlo and the black screen printing (spine) by Saenscreen.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      