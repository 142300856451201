import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.en.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The Kindness of One is an intuitive, personal exploration of how a Dutch consul and factory director helped thousands of Jewish refugees to escape to Japan from Lithuania in 1940. The story is understood as the impetus for a research project into the experiences of refugees – then and now – and the meaning of empathy and the small gesture. Although the book is printed only in black and silver, it gives the impression of many nuances of colour. An effect ingeniously achieved through the use of two different types of papers for the body: brown-grey Cairn and lighter Munken Lynx Rough. A choice also prompted by a more concrete motivation: the Lithuanian half of the story is told on Munken; the Japanese half on Cairn. The tactile difference between the two papers is utilised to the full to enrich the experience of reading and looking with the sense of touch. Combined with the soft blue-grey paper of the cover, this creates lovely, rich shades of cohesion throughout the project. Painstakingly printed and soundly bound as a Swiss brochure, which means it opens beautifully. A book can be a report on an experience, research or art project – or it can itself be both the experience and the artwork. As is the case here, and with sublime results.`}</p>
    <hr></hr>
    <p>{`Publisher: WeWest, West Graftdijk in association with Kaunas Photography Gallery, Kaunas (LT)`}<br parentName="p"></br>{`
`}{`Designer: Akiko Wakabayashi, Amsterdam`}</p>
    <p>{`Category: Art, photo and architecture books`}<br parentName="p"></br>{`
`}{`Artist: Margaret Lansink, Rene van Hulst`}<br parentName="p"></br>{`
`}{`Language: English`}<br parentName="p"></br>{`
`}{`Photographer: Margaret Lansink`}<br parentName="p"></br>{`
`}{`Image editor: Margaret Lansink, Akiko Wakabayashi`}<br parentName="p"></br>{`
`}{`Editor-in-chief: Katherine Oktober Matthews`}<br parentName="p"></br>{`
`}{`Lithographer: Marc Gijzen beeldbewerking & digitale lithografie, Voorburg`}<br parentName="p"></br>{`
`}{`Printer: Drukkerij Tielen, Boxtel`}<br parentName="p"></br>{`
`}{`Binder: Boekbinderij Patist, Den Dolder`}<br parentName="p"></br>{`
`}{`Font: Fortescue Pro (Colophon Foundry)`}<br parentName="p"></br>{`
`}{`Size in mm: 190 x 270 x 10`}<br parentName="p"></br>{`
`}{`Number of pages: 120`}<br parentName="p"></br>{`
`}{`Print run: 500`}<br parentName="p"></br>{`
`}{`Price: € 40`}<br parentName="p"></br>{`
`}{`ISBN: 978 609 80 99 33 1`}<br parentName="p"></br>{`
`}{`Paper for interior: 100gsm Cairn Eco white (Christiaan Janssen), 120gsm Munken Lynx Rough (Antalis)`}<br parentName="p"></br>{`
`}{`Cover: 250gsm Materica Acqua (Fedrigoni)`}<br parentName="p"></br>{`
`}{`Binding style: Swiss-style cut-flush binding, transparently glued-off spine, front cover with flap`}<br parentName="p"></br>{`
`}{`Special features: The book block is bound with black threads and is printed in Pantone 877, Process Black and Ridge Black highly pigmented RT 195.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      