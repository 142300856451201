import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/VabfEvents.en.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SocialShare = makeShortcode("SocialShare");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "980px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "72.6530612244898%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAdybRYg//8QAGhAAAgIDAAAAAAAAAAAAAAAAAAECERASIf/aAAgBAQABBQJncUjWIlR//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGRAAAgMBAAAAAAAAAAAAAAAAAAEQITEy/9oACAEBAAY/AqZ0owwo/8QAGhABAQEAAwEAAAAAAAAAAAAAAREAITFhQf/aAAgBAQABPyGuUZh7dnyYRKNN5N5cAghv/9oADAMBAAIAAwAAABDwz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABoQAQADAQEBAAAAAAAAAAAAAAEAETEhUaH/2gAIAQEAAT8QTQFMq6Y9tRhUTmbAQExG53v4RZFVM5KePAn/2Q==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/c181747bac6c24310594ff1e7670a0c1/1a3b7/img_1.webp 245w", "/static/c181747bac6c24310594ff1e7670a0c1/fb835/img_1.webp 490w", "/static/c181747bac6c24310594ff1e7670a0c1/096d3/img_1.webp 980w", "/static/c181747bac6c24310594ff1e7670a0c1/3dcc7/img_1.webp 1470w", "/static/c181747bac6c24310594ff1e7670a0c1/7a8fb/img_1.webp 1880w"],
          "sizes": "(max-width: 980px) 100vw, 980px",
          "type": "image/webp"
        }}></source>{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/c181747bac6c24310594ff1e7670a0c1/c168b/img_1.jpg 245w", "/static/c181747bac6c24310594ff1e7670a0c1/bb66a/img_1.jpg 490w", "/static/c181747bac6c24310594ff1e7670a0c1/2f919/img_1.jpg 980w", "/static/c181747bac6c24310594ff1e7670a0c1/a7235/img_1.jpg 1470w", "/static/c181747bac6c24310594ff1e7670a0c1/b9262/img_1.jpg 1880w"],
          "sizes": "(max-width: 980px) 100vw, 980px",
          "type": "image/jpeg"
        }}></source>{`
        `}<img parentName="picture" {...{
          "className": "gatsby-resp-image-image",
          "src": "/static/c181747bac6c24310594ff1e7670a0c1/2f919/img_1.jpg",
          "alt": "img 1",
          "title": "img 1",
          "loading": "lazy",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          }
        }}></img>{`
      `}</picture>{`
    `}</span>
    <div className="share__2col">
  <div className="share__2col__icon">
    <SocialShare title={props.pageContext.frontmatter.title} mdxType="SocialShare" />
  </div>
    </div>
    <p>{`TOKYO ART BOOK FAIR ではお馴染みの、人気長寿ラジオ風番組“Ken Kagami のオールナイトすっぽんぽん“が、今年はバーチャルアートブックフェアで生放送。放送 7 年目第 26 回目となるテーマは、聴いてからのおたのしみ。`}</p>
    <ul>
      <li parentName="ul">
        <span className="font__small">無料で視聴いただけます。</span>
      </li>
      <li parentName="ul">
        <span className="font__small">
  インターネット回線の状況やサーバー負荷、その他視聴者の PC
  環境により、映像が途切れる、または停止するなど正常に視聴できない事がございます。
        </span>
      </li>
      <li parentName="ul">
        <span className="font__small">
  配信終了後も TOKYO ART BOOK FAIR の youtube
  チャンネルで動画を視聴いただけます。
        </span>
      </li>
    </ul>
    <div {...{
      "className": "content__info"
    }}>{`
  `}<figure parentName="div" {...{
        "className": "content__info__img"
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQDBf/EABcBAAMBAAAAAAAAAAAAAAAAAAABAwL/2gAMAwEAAhADEAAAAcs8rAuc0nOCdYzX/8QAHBAAAwACAwEAAAAAAAAAAAAAAQIDBBESEyEi/9oACAEBAAEFAstAa4ut0qVYU+p9bNOj8aqFYjTKPP/EABgRAAIDAAAAAAAAAAAAAAAAAAADEBEx/9oACAEDAQE/AShmR//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/AWE//8QAGhAAAwEBAQEAAAAAAAAAAAAAAAECESEQYf/aAAgBAQAGPwJ0yTFOjq+mqc+HKFhzz//EABsQAAMAAwEBAAAAAAAAAAAAAAABESFBUTGB/9oACAEBAAE/IcZGEMvelUVlqL2OUyunh89IsZzgoE2I0ExT8H//2gAMAwEAAgADAAAAECP4/P/EABcRAQEBAQAAAAAAAAAAAAAAAAEAEHH/2gAIAQMBAT8QEu44/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAx/9oACAECAQE/EA5Gs//EAB4QAQACAgMAAwAAAAAAAAAAAAEAESExQVFhcbHw/9oACAEBAAE/EGIokZ3RxALV6r1eISXhax9SlK09hd/H4mFI3cuR5MWF2k6deQLoFe73D4KA0aL4jyxhoxXBP//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/8dfe2c9d070261605194596abdf51efa/1a3b7/img_2.webp 245w", "/static/8dfe2c9d070261605194596abdf51efa/fb835/img_2.webp 490w", "/static/8dfe2c9d070261605194596abdf51efa/e2a84/img_2.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/8dfe2c9d070261605194596abdf51efa/c168b/img_2.jpg 245w", "/static/8dfe2c9d070261605194596abdf51efa/bb66a/img_2.jpg 490w", "/static/8dfe2c9d070261605194596abdf51efa/d5c47/img_2.jpg 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/8dfe2c9d070261605194596abdf51efa/d5c47/img_2.jpg",
              "alt": "img 2",
              "title": "img 2",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
  `}</figure>{`
  `}<div parentName="div" {...{
        "className": "content__info__title"
      }}></div>{`
  `}<div parentName="div" {...{
        "className": "content__info__text"
      }}>{`
    `}<p parentName="div">{`
      パーソナリティーは現代美術のアーティストでSTRANGE STOREを運営する加賀美
      健。レギュラーメンバーにCOBRA（現代美術アーティスト・ XYZ
      Collective主宰）、題府基之（写真家）、SM
      TOKYO局アナのストレンジ子とADジェフリーからなる、結成7年目のラジオ風番組チーム。
      `}<br parentName="p"></br>{`
      `}<a parentName="p" {...{
            "href": "https://www.misakoandrosen.jp/entertainment/allnight/index.html",
            "target": "_blank"
          }}>{`
        https://www.misakoandrosen.jp/entertainment/allnight/index.html
      `}</a>{`
    `}</p>{`
  `}</div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      