import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import "./Header.scss"
import GlobalMenu from "./GlobalMenu"
import { useEvents } from "../hooks"
import { Area } from "../commonTypes"
import Helmet from "react-helmet"

type HeaderProps = Readonly<{
  siteTitle: string
  locale: "en" | "ja"
  langs: string[]
  invertColor: boolean
}>

const Header: React.FC<HeaderProps> = ({
  siteTitle = "",
  locale,
  langs,
  invertColor = false,
}) => {
  const events = useEvents()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isRafaelTitleVisible, setIsRafaelTitleVisible] = useState(false)

  const toggleModal = () => setIsModalOpen(!isModalOpen)
  const closeModal = () => setIsModalOpen(false)

  // ラファエルの展示エリアに入ったらタイトルを表示する
  useEffect(() => {
    const onAreaChange = (area: Area) => {
      setIsRafaelTitleVisible(area === "rafael")
    }

    events.on("areaChanged", onAreaChange)
    return () => {
      events.off("areaChanged", onAreaChange)
    }
  }, [events])

  const cssMenuTriggerColor = invertColor && !isModalOpen ? "invert" : ""

  return (
    <header
      className={isModalOpen ? "header isMenuOpen" : "header isMenuClosed"}
    >
      {isModalOpen && (
        <Helmet
          htmlAttributes={{
            class: "gnav--open",
          }}
        />
      )}

      <h1 className={`header__title`}>
        <Link to="/"> {siteTitle} </Link>
      </h1>

      <h2
        className={`header__rafael-title ${
          isRafaelTitleVisible ? "visible" : ""
        }`}
      >
        Rafaël Rozendaal Exhibition
      </h2>

      <div className={`header__menu-trigger ${cssMenuTriggerColor}`}>
        <button onClick={toggleModal}>{isModalOpen ? "CLOSE" : "MENU"}</button>
      </div>
      <div className={`header__global-menu`} data-motion="up-modal">
        <GlobalMenu locale={locale} onClose={closeModal} langs={langs} />
      </div>
    </header>
  )
}

export default Header
