import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.en.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Cycling@Shanghai is a survey of urban cycle infrastructure by Chinese architect Zhuo’er Wang. On the basis of an analysis of the situation in Shanghai and a comparison of seven cycle-friendly cities across the globe, Wang presents the potential of cycling as a means of transport for the future of cities. The large quantities of data, facts, tables and diagrams produced by the research could have resulted in a dull book. However, designers Haller and Brun have transformed this into a veritable feast for the eye. In four Pantone colours, including metallic and fluorescent, they have created a book that is wonderfully fresh and clear, crackling with smart solutions and new ideas that raise a smile. The jacket, folded in the American style, inwards on top and outwards on the bottom, creates a ‘bag’ into which the enclosed folding sheet showing a timeline of the development of the bicycle (another lovely little gift) is inserted. The problem of the dual language is resolved by placing the English text horizontally on the left-hand pages and the Chinese text vertically on the right-hand pages. The various main sections can be found intuitively as the thumb naturally sticks behind the thicker cardboard of the section title pages while leafing through – and as we do so, a little cyclist in the bottom right-hand corner actually cycles. The book is crammed with such experiences, without the sense of clarity and overview being at all diminished. Every choice made proves its worth: the cover sewn in with the book block and the open spine result in a book that opens effortlessly; the printing is crisp and fresh and the use of inks is effective in both exploiting aesthetic possibilities and creating clear, insightful maps, drawings and data visualisations. The jury was unanimous, and delighted.`}</p>
    <hr></hr>
    <p>{`Publisher: Tongji University Press, Shanghai (CN)`}<br parentName="p"></br>{`
`}{`Concept and design: Haller Brun (Sonja Haller, Pascal Brun, Long Wu), Amsterdam`}</p>
    <p>{`Category: Art, photo and architecture books`}<br parentName="p"></br>{`
`}{`Editor: Crisie Yuan, Zheng Li`}<br parentName="p"></br>{`
`}{`Language: English, Chinees`}<br parentName="p"></br>{`
`}{`Translator: Xu Jia, Marlon Zenden, Edward Tse`}<br parentName="p"></br>{`
`}{`Photographer: Xiangyi Tang, Zhuo’er Wang`}<br parentName="p"></br>{`
`}{`Illustrator: Long Wu, Ruoqi Yu, Sile Li`}<br parentName="p"></br>{`
`}{`Image editor: Zhuo’er Wang, Haller Brun`}<br parentName="p"></br>{`
`}{`Editor-in-chief: Crisie Yuan, Zheng Li`}<br parentName="p"></br>{`
`}{`Printer: Shanghai Yachang Art Printing Co., Ltd., Shanghai (CN)`}<br parentName="p"></br>{`
`}{`Binder: Shanghai Yachang Art Printing Co., Ltd., Shanghai (CN)`}<br parentName="p"></br>{`
`}{`Font: Neue Haas Unica Pro (Monotype), PingFang SC`}<br parentName="p"></br>{`
`}{`Size in mm: 120 x 160 x 22`}<br parentName="p"></br>{`
`}{`Number of pages: 314`}<br parentName="p"></br>{`
`}{`Print run: 2,500`}<br parentName="p"></br>{`
`}{`Price: € 25`}<br parentName="p"></br>{`
`}{`ISBN: 978 7 5608 8198 0`}<br parentName="p"></br>{`
`}{`Paper for interior: 120gsm Brisk Pure White (Antalis China), 180gsm Guyi BY180-03MK (Zhantuo), inlay: 65gsm Haozhi Dowling White (Zhantuo)`}<br parentName="p"></br>{`
`}{`Binding: 180gsm Guyi BY180-03MK (Zhantuo)`}<br parentName="p"></br>{`
`}{`Dust jacket: 120gsm Brisk Pure White (Antalis China)`}<br parentName="p"></br>{`
`}{`Binding style: Sewn hardcover with front and back cover of four pages sewn in, transparently glued-off spine, dust jacket folded in the American style`}<br parentName="p"></br>{`
`}{`Special features: Printing in Pantone 494, 646, 809 and 877. The interior contains extra title pages, a folded inlay and a flip book animation. The dust jacket is varnished on one side.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      