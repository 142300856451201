import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.en.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Letterewe is a forty-thousand hectare Scottish estate obtained by entrepreneur Paul Fentener van Vlissingen in 1977. Extensive surveys have been made of its unique flora and fauna – particularly the deer population – and this book is the third document to detail these. The book deals with the mosses and lichens of this wettest part of the British Isles. Irma Boom’s long-term relationship with the Van Vlissingen family – her legendary SHV book from 1996 being the best-known example – made the choice of designer an obvious one, whereupon Boom eagerly seized the opportunity to tackle the subject with all the ‘bookish’ resources at her disposal. One unmistakeable reference is the roughly abraded finish to the edges of the pages on one side of the book block, with the bled-off images in the illustration sections forming green stripes: the resulting velvety touch feels convincingly mossy. A sensation reinforced by the light weight of this sizeable book block.
The sturdy typography leaving little white has a wavy margin which imitates not only the cloudy moss beds but also the forms of the Scottish landscape. In the text, references to specific places or plants included in the pictorial sections are marked in green and with a page reference; not only does this enrich the typography, it also serves as a useful service to the reader, who is then helpfully referred back from the pictorial sections to the corresponding text passages. But the most remarkable process concerns the images themselves: to do justice to the woolly texture of the mosses and the ruggedness of conditions in Scotland, the initial idea was to create the images using the Risograph technique. When this turned out to be impractical on the paper chosen, a similar effect was achieved in offset using an extremely coarse screen. This gives the book a very specific visual quality and cohesion, with the colours in the images beautifully giving the suggestion of luminosity that mosses can have. The elegant, thin boards and minimal square are characteristic of binder Van Waarden and lend this book the familiar blocky look. The front and back covers are without typography. A mossy book in every sense except smell. And that’s a compliment!`}</p>
    <hr></hr>
    <p>{`Publisher: Letterewe Estate, Achnasheen (SCH)`}<br parentName="p"></br>{`
`}{`Designer: Irma Boom Office (Irma Boom, Eva van Bemmelen), Amsterdam`}</p>
    <p>{`Category: Informative nonfiction`}<br parentName="p"></br>{`
`}{`Editor: Oliver Moore`}<br parentName="p"></br>{`
`}{`Language: English`}<br parentName="p"></br>{`
`}{`Photographer/ fotograaf: Oliver Moore`}<br parentName="p"></br>{`
`}{`Image editor: Oliver Moore`}<br parentName="p"></br>{`
`}{`Lithographer: Zwaan Lenoir, Wormerveer`}<br parentName="p"></br>{`
`}{`Printer: Zwaan Lenoir, Wormerveer`}<br parentName="p"></br>{`
`}{`Binder: Boekbinderij Van Waarden, Zaandam`}<br parentName="p"></br>{`
`}{`Font: Plantin Pro (Monotype), Times (Linotype), Arial (Monotype)`}<br parentName="p"></br>{`
`}{`Size in mm: 170 x 220 x 32`}<br parentName="p"></br>{`
`}{`Number of pages: 344`}<br parentName="p"></br>{`
`}{`Print run: 300`}<br parentName="p"></br>{`
`}{`Price: £ 50 (Britse pond)`}<br parentName="p"></br>{`
`}{`ISBN: 978 90 824953 1 7`}<br parentName="p"></br>{`
`}{`Paper for interior: 100gsm EOS 2.0 (Igepa)`}<br parentName="p"></br>{`
`}{`Endpapers: 185gsm Sirio Black/Black (Fedrigoni)`}<br parentName="p"></br>{`
`}{`Binding: 120gsm Wibalin® Natural 500 White (Winter & Company) over 1.5 mm greyboard`}<br parentName="p"></br>{`
`}{`Binding style: Sewn hardback with flat spine, thin boards with minimal square`}<br parentName="p"></br>{`
`}{`Special features: The images are printed using a very coarse screen, 65 lpi (approximately screen 26). The typesetting on the spine consists of organic elements that refer to the capricious shapes of mosses. The fore-edge is trimmed in a special way that makes it feel as soft as moss. There are two different bindings.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      