import React from "react"
import Layout from "../../../layouts/ja"
import { graphql, Link } from "gatsby"
import BdbdMenu from "../../../components/BdbdMenu"
import Img from "gatsby-image"
import css from "./index.module.scss"

const IndexPage = props => {
  let posts = []
  for (let i = 0; i < props.data.allMdx.edges.length; i += 1) {
    let thePost = props.data.allMdx.edges[i].node

    posts.push(
      <li key={i} className={css.bdbd__items__item}>
        <Link
          to={`/${thePost.slug}`}
          state={{
            modal: true,
          }}
          className={css.bdbd__items__item__link}
        >
          <Img
            className={css.bdbd__items__item__img}
            fluid={thePost.frontmatter.thumbnail.childImageSharp.fluid}
          />
          <h2 className={css.bdbd__items__item__title}>
            {thePost.frontmatter.title}
          </h2>
          <p className={css.bdbd__items__item__name}>
            {thePost.frontmatter.author}
          </p>
        </Link>
      </li>
    )
  }

  return (
    <Layout location={props.location} title={`Best Dutch Book Exhibition`}>
      <section className={css.bdbd__index + ` container`}>
        <div className={css.bdbd__content + ` content`}>
          <div className={css.bdbd__head + ` content__head`}>
            <h1 className={`haeding__lev1`}>
              BEST DUTCH <br className={`only-sp`} />
              BOOK DESIGNS
            </h1>
          </div>

          <nav className={css.bdbd__localnavi}>
            <BdbdMenu />
          </nav>

          <div className={css.bdbd__read}>
            <p className={css.bdbd__read__main}>
              1926年に設立された「Best Dutch Book
              Designs」は、ヨーロッパで最も長い歴史を持つオランダのブックデザインアワードです。オランダ人もしくはオランダを拠点とするデザイナーによって作られたアートブック、児童書、料理本など幅広いジャンルを対象としています。本展では、ハンス・グレメンがデザインした『American
              Origami』、樋口歩が手がけた桑原真理子著『Burning
              Love』をはじめとする2019年の受賞作33点をご紹介します。
            </p>
            <p className={css.bdbd__read__sub}>
              協力：Stichting De Best Verzorgde Boeken
            </p>
          </div>

          <ul className={css.bdbd__items}>{posts}</ul>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage

export const posts = graphql`
  query {
    allMdx(
      filter: { slug: { regex: "/^exhibitions.+best-dutch-book/" } }
      sort: { fields: slug }
    ) {
      edges {
        node {
          id
          slug
          frontmatter {
            title
            author
            youtubeId
            description
            thumbnail {
              id
              childImageSharp {
                fluid(maxWidth: 1280) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            genre
          }
          excerpt
        }
      }
    }
  }
`
