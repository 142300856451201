import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.en.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In spite of some criticism of the typography (‘slapdash’), this book is an instant classic. Whoever would have thought that the grey-green waters of Zeeland could conceal such an enchanting world of colour and life that it makes us shake our heads in disbelief? Nevertheless, photographer Elspeth Diederix stresses in the book that ‘what you see is actually there’. From the blacker than black pages, with black edges, a black cover and black endpapers, underwater images loom up reminiscent of the magnificent seventeenth-century still lifes of Pieter Claesz. or Floris van Dyck – except that here, the oysters, shells, sponges and sea anemones are very much alive. ‘Nature vivante’ rather than ‘nature morte’. The choice for such deep black all round is crucial to the experience of the stunning colours and intoxicating atmosphere of the photos – this is what makes the book, along with the decision to use ‘enhanced CMYK to make the colours even more powerful. The result, as stated above, is an instant classic. A book that gets down to business straight away on its cover: an underwater still life the like of which you have never seen before, looming up out of the black, framed in black, without typography. It is followed by many more, and except for the brief essay and documentation at the back, the book needs nothing else. Leaf through and enjoy, marvel, wonder.`}</p>
    <hr></hr>
    <p>{`Publisher: Fw:Books, Amsterdam`}<br parentName="p"></br>{`
`}{`Designer: Hans Gremmen, Amsterdam`}</p>
    <p>{`Category: Art, photo and architecture books`}<br parentName="p"></br>{`
`}{`Language: English`}<br parentName="p"></br>{`
`}{`Photographer/ fotograaf: Elspeth Diederix`}<br parentName="p"></br>{`
`}{`Producer: Frank van der Stok, Amsterdam`}<br parentName="p"></br>{`
`}{`Lithographer: robstolk® (Alex Feenstra), Amsterdam`}<br parentName="p"></br>{`
`}{`Printer: robstolk®, Amsterdam`}<br parentName="p"></br>{`
`}{`Binder: Boekbinderij Patist, Den Dolder`}<br parentName="p"></br>{`
`}{`Font: F Grotesk (RP Digital Type Foundry)`}<br parentName="p"></br>{`
`}{`Size in mm: 230 x 335 x 7`}<br parentName="p"></br>{`
`}{`Number of pages: 88`}<br parentName="p"></br>{`
`}{`Print run: 1,000`}<br parentName="p"></br>{`
`}{`Price: € 35`}<br parentName="p"></br>{`
`}{`ISBN: 978 94 90119 77 5`}<br parentName="p"></br>{`
`}{`Paper for interior: 120gsm Kamiko Shira (Cordier Spezialpapier)`}<br parentName="p"></br>{`
`}{`Endpapers: 120gsm Kamiko Shira (Cordier Spezialpapier)`}<br parentName="p"></br>{`
`}{`Binding: 120gsm Kamiko Shira (Cordier Spezialpapier)`}<br parentName="p"></br>{`
`}{`Dust jacket: 120gsm Kamiko Shira (Cordier Spezialpapier)`}<br parentName="p"></br>{`
`}{`Binding style: Sewn hardback, spine glued with linen (Brillianta® 4000 - Van Heek Textiles), binding is laminated with 1.9 mm greyboard (Koninklijke Moorman Karton). The first and last pages are the endpapers. Book block contains 8 sections bound with black threads.`}<br parentName="p"></br>{`
`}{`Special features: The black pages and frames around the photographs are printed in black and Pantone 406. The fore-edge of the book block is printed in black using enhanced CMYK colours by Critis, Beernem (BE). The text and the two black beams are screen printed at the greyboard to prevent the dust jacket from opening; screen printing by Spant Zeefdruk, Utrecht.`}</p>
    <p>{`Honorary Appreciation Best Book Designs from all over the World, Leipzig 2020`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      