// react 上の改行に変換
import React from "react"

export function nl2br(text) {
  var regex = /(\n)/g
  return text.split(regex).map(function (line, index) {
    if (line.match(regex)) {
      return React.createElement("br", { key: index })
    } else {
      return line
    }
  })
}
