import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`70 年代という停滞の時代にアムステルダムの中心で設立された印刷所、ラットドライェルは、同地の活動家たちの出版シーンに私たちを誘う。彼らはアゲンダ・ヴォール・タウフ・ゾアルス・ウェイやアステリックス・エン・デ・ケルンセントラーレの印刷にも関わっていた。40 年が経ち、多くの変化があった。その変化はトエーデ・オーステルパルク通りに拠点を構える活動家によるこの印刷所についてヨランダ・エントィウスが書いた本書のテキストに記録されている。レムコ・ファン・ブラーデルとサンドラ・カッセナールによりデザインと写真編集がなされ、非常によくまとまった記録集となっている。中ページの印刷には 2 種類の黒が、後半の図版パートには CMYK が、そして書物全体には半透過の白が効果的に用いられ、歴史的価値の高い画像やロゴ、印刷機の技術的な図版などの資料も豊富だ。執拗な行末揃えと狭い余白に加え、起伏のあるタイポロジーはあえて粗野なまま保たれており、本書が活動家による印刷物であることを明示している。しかし、ファン・ブラーデルとケルンセントラーレの確かな手腕も全体を通して確認できる。後半には少ないながらも光沢のある紙に印刷されたフルカラーのセクションがあり、ラットドライェルの印刷物が実際に食器棚や本棚、印刷所の壁に掲げられた写真や、フォトショップ加工された画像も収められている。黒いボード紙（Sirio Ultra Black）に白いシルクスクリーンで刷られたカバーも、粗野でありながらマッチョさを回避した、中ページと同じような空気感を纏っている。非常に価値ある記念碑だろう。`}</p>
    <hr></hr>
    <p>{`出版社： drukkerij RaddraaierSSP, Amsterdam`}<br parentName="p"></br>{`
`}{`デザイン： Remco van Bladel, Amsterdam; Sandra Kassenaar, Amsterdam`}</p>
    <p>{`カテゴリー： 情報系ノンフィクション`}<br parentName="p"></br>{`
`}{`編集：Eric Brassem, Janny Oei, Machtelt van Thiel, Tom Kooiman`}<br parentName="p"></br>{`
`}{`言語：オランダ語`}<br parentName="p"></br>{`
`}{`写真：Johannes Schwartz`}<br parentName="p"></br>{`
`}{`Illustrator: Johannes van de Weert`}<br parentName="p"></br>{`
`}{`イメージ編集：Remco van Bladel, Sandra Kassenaar`}<br parentName="p"></br>{`
`}{`リトグラフ：drukkerij RaddraaierSSP, Amsterdam`}<br parentName="p"></br>{`
`}{`印刷：drukkerij RaddraaierSSP, Amsterdam`}<br parentName="p"></br>{`
`}{`製本：Boekbinderij Patist, Den Dolder`}<br parentName="p"></br>{`
`}{`フォント：Volta (Linotype)`}<br parentName="p"></br>{`
`}{`サイズ（ミリ）：150 x 210 x 11`}<br parentName="p"></br>{`
`}{`ページ数：160`}<br parentName="p"></br>{`
`}{`発行部数：750`}<br parentName="p"></br>{`
`}{`本文用紙：90gsm Munken Print White 15 (Antalis), 90gsm UPM Sol Gloss (Igepa)`}<br parentName="p"></br>{`
`}{`カバー：280gsm Sirio Ultra Black (Fedrigoni)`}<br parentName="p"></br>{`
`}{`製本方法：糸かがりホローバック製本、カバーフラップ付き`}<br parentName="p"></br>{`
`}{`特記事項：表紙はオペークホワイトで二度印刷。中身は Pantone 黒４、Pantone 黒６、オペークホワイトで印刷。最後のセクションは CMYK で印刷。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      