import React from "react"

import Layout from "../../layouts/ja" // 日本語のレイアウトを設定
import EventListItem from "../../components/EventListItem"
import { graphql, Link } from "gatsby"
import Image from "gatsby-image"
import css from "./index.module.scss"

const EventPage = props => {
  let posts = []
  let dateTitle // 日付見出し
  let dateNum // 日付ごとのclass

  const dateFormat = {
    _fmt: {
      w: function (date) {
        return [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ][date.getDay()]
      },
    },
    _priority: ["w"],
    format: function (date, format) {
      return this._priority.reduce(
        (res, fmt) => res.replace(fmt, this._fmt[fmt](date)),
        format
      )
    },
  }

  for (let i = 0; i < props.data.allMdx.edges.length; i += 1) {
    let thePost = props.data.allMdx.edges[i].node
    dateNum++
    let dateClass = ""

    // 日付見出し
    if (dateTitle !== thePost.frontmatter.date) {
      dateTitle = thePost.frontmatter.date
      dateNum = 0
      posts.push(
        <div className={css.event__date} key={`date_${i}`}>
          <h2 className={`haeding__lev2`}>
            {dateFormat.format(new Date(dateTitle), "w, ")}
            {dateTitle}
          </h2>
        </div>
      )
    }

    if (dateNum % 4 === 3) {
      dateClass = " mr0"
    }
    posts.push(
      <article
        key={i}
        className={css.event__list + dateClass}
        data-list={dateNum}
      >
        <Link to={`/${thePost.slug}`} className={css.event__link}>
          <Image
            fluid={thePost.frontmatter.img.childImageSharp.fluid}
            alt={thePost.frontmatter.title}
            className={css.event__img}
          />
          <EventListItem
            title={thePost.frontmatter.title}
            time={thePost.frontmatter.time}
            excerpt={thePost.excerpt}
          />
        </Link>
      </article>
    )
  }

  return (
    <Layout location={props.location} title={`Events`}>
      <section className={`container`}>
        <div className={css.event__content + ` content`}>
          <div className={css.event__head + ` content__head`}>
            <h1 className={`haeding__lev1`}>VABF EVENTS</h1>
          </div>
          {posts}
        </div>
        {/* /.content */}
      </section>
      {/* /.container */}
    </Layout>
  )
}

export default EventPage

export const posts = graphql`
  query {
    allMdx(
      filter: { slug: { regex: "/^events/" } }
      sort: { order: ASC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          slug
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            time
            img {
              childImageSharp {
                fluid(maxWidth: 1280) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt
        }
      }
    }
  }
`
