module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[],"modalProps":{"portalClassName":"vabfModal","overlayClassName":"vabfModal__Overlay","className":"vabfModal__Content","data":{"motion":"up-modal"},"bodyOpenClassName":"vabfModal__Body--open","htmlOpenClassName":"vabfModal__Html--open"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"vabf-default","short_name":"vabf","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"minimal-ui","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"b647ff4839efa049d47301cd6087df9f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-181918238-1","cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"facebookPixel":{"pixelId":"","cookieName":"gatsby-gdpr-facebook-pixel"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"bestDutchBookPost":"/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.js","bestDutchBookEnglishPost":"/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.en.js","dutchArtistBookPost":"/home/runner/work/vabf/vabf/vabf-site/src/templates/DutchArtistBook.js","dutchArtistBookEnglishPost":"/home/runner/work/vabf/vabf/vabf-site/src/templates/DutchArtistBook.en.js","vabfEventsPost":"/home/runner/work/vabf/vabf/vabf-site/src/templates/VabfEvents.js","vabfEventsEnglishPost":"/home/runner/work/vabf/vabf/vabf-site/src/templates/VabfEvents.en.js","default":"/home/runner/work/vabf/vabf/vabf-site/src/templates/DefaultPage.js"},"remarkPlugins":[null],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":980,"linkImagesToOriginal":false,"withWebp":true,"showCaptions":true,"quality":95}}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
