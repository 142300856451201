import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`『Doug's Cabin』は、カリアンヌ・ブエノが孤独に生きる存在 − 自ら選択したとしても、そうでなくても − を追った写真プロジェクト。本書の主人公であるダグは、そこにかつてあった軍基地ですらも冷戦後に撤退してしまったバンクーバー島で、訪れる人のいない詫びしいキャンプ場に暮らす。彼は、彼の家や所有物の周りを取り囲み、少しずつ確実に侵食しその色を奪い、腐敗させてゆく森との負け戦に臨んでいる。新たに撮影した写真と歴史的記録、そしてファウンドフォトを組み合わせたこのような写真プロジェクトは、出版社である The Eriskay Connection（エリスケイ・コネクション）の得意分野でもある。デザインを手がけたプット・ホーティンクはブエノの写真に漂う空気感を深く理解し、青と灰色の景色、緑に沈む山々の展望や静物が並ぶ書籍を生み出した。この環境についてのテキスト、古い記録の複製、過去の写真は 60g/㎡ の新聞用紙に印刷、書籍サイズよりも小さく折られて、ページによってはトリムされ、袋綴じになっている。ページ上にはまた、呼応した番号の写真 − 歴史的記録やブエノの風景 − についての覚書が記されている。メランコリーと腐敗の雰囲気と、森や、見る者の興味を惹く未知の、あるいは飼い慣らされていない風景の美しさが均衡している。ほの暗くもシャープかつ正確な印刷、紙の選択、そして写真の編集といったすべての要素がうまく融合し、豊かな表現、ニュアンス、意義を本書に与えることに成功している。背表紙が割れてしまうのはもったいないが、全体的に模範的と言える仕上がりになっている。`}</p>
    <hr></hr>
    <p>{`出版社： The Eriskay Connection, Breda`}<br parentName="p"></br>{`
`}{`デザイン： PutGootink, Amsterdam`}</p>
    <p>{`カテゴリー： アート、写真、建築`}<br parentName="p"></br>{`
`}{`アーティスト：Karianne Bueno`}<br parentName="p"></br>{`
`}{`編集：Rosie Heinrich`}<br parentName="p"></br>{`
`}{`言語：英語`}<br parentName="p"></br>{`
`}{`翻訳：Rosie Heinrich`}<br parentName="p"></br>{`
`}{`写真：Karianne Bueno`}<br parentName="p"></br>{`
`}{`イラスト：Medy Oberendorff`}<br parentName="p"></br>{`
`}{`イメージ編集：Ellen Sanders, Karianne Bueno`}<br parentName="p"></br>{`
`}{`編集：PutGootink`}<br parentName="p"></br>{`
`}{`用紙アドバイス：De Monsterkamer`}<br parentName="p"></br>{`
`}{`製作：Printing Matters (Daan van Tulder), Amsterdam`}<br parentName="p"></br>{`
`}{`リトグラフ：Marc Gijzen beeldbewerking & digitale lithografie, Voorburg`}<br parentName="p"></br>{`
`}{`印刷：Zwaan Lenoir, Wormerveer`}<br parentName="p"></br>{`
`}{`製本：Stronkhorst Boekbinders, Groningen`}<br parentName="p"></br>{`
`}{`フォント：Circular (Lineto), Caslon (Adobe)`}<br parentName="p"></br>{`
`}{`サイズ（ミリ）：190 x 300 x 12`}<br parentName="p"></br>{`
`}{`ページ数：192`}<br parentName="p"></br>{`
`}{`発行部数：700`}<br parentName="p"></br>{`
`}{`価格：€ 40`}<br parentName="p"></br>{`
`}{`ISBN：978 94 92051 43 1`}<br parentName="p"></br>{`
`}{`本文用紙：120gsm PERGRAPHICA® Natural Smooth (Igepa), 60gsm Druckfix 2000 (Papier Union)`}<br parentName="p"></br>{`
`}{`カバー：240gsm Pop’Set Urban Grey (Antalis)`}<br parentName="p"></br>{`
`}{`製本方法：糸かがりソフトカバーと複数のミシン目加工折り込み部分`}<br parentName="p"></br>{`
`}{`特記事項：オフセットフルカラーと Pantone 黒で印刷。全ての画像はスケルトンスクリーンに印刷、中身の一部はミシン目加工された折り込みページが縫い込まれている。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      