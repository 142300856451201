import * as React from "react"
import style from "./VabfLogo.module.scss"

export function VabfLogo() {
  return (
    <svg viewBox="0 0 394.44 480.06" className={style.wrapper}>
      <defs>
        <style>{".VabfLogo_inline_svg__cls-3{fill:#231f20}"}</style>
      </defs>
      <g
        style={{
          isolation: "isolate",
        }}
      >
        <g id="VabfLogo_inline_svg__Layer_2" data-name="Layer 2">
          <g
            style={{
              mixBlendMode: "multiply",
            }}
            id="VabfLogo_inline_svg__\u30EC\u30A4\u30E4\u30FC_1"
            data-name="\u30EC\u30A4\u30E4\u30FC 1"
          >
            <path d="M0 445.23h208.87v24.04H0zM0 198.81h183.67v24.04H0zM188.8 227.84h205.64v24.04H188.8z" />
            <path
              className={style.anim_v}
              d="M113.36 187.28h-37L6.12 0h35l55.15 157.49L152 0h31.86z"
            />
            <path
              className={style.anim_a}
              d="M246.11 168.75H333l17.89 46.67h36L308.35 27.93h-35.53l-76.5 187.49h32.16zM288.9 53.61l35 92.32h-69z"
            />
            <path
              className={style.anim_b}
              d="M165.68 291.16c0-37.61-31.11-47.2-62.49-47.2H32.13v187.49h70c33.71 0 70.54-10.89 70.54-52.38 0-26.71-17.12-41-41.75-45.38 21.02-5.97 34.76-19.45 34.76-42.53zm-25.93 86.09c0 27-23.08 30.6-45.38 30.6H63.51v-61.72h36.31c22.04 0 39.93 6.23 39.93 31.12zm-44.08-53.42H63.51v-56.27h34.75c18.41 0 35 6.74 35 27.23.01 22.56-17.63 29.04-37.59 29.04z"
            />
            <path
              className={style.anim_f}
              d="M236.98 480.06h32.45v-88.62h88.39v-25.59h-88.39v-59.86h93.09v-25.86H236.98v199.93z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
