import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`『Where the River Runs』は、リートフェルトやモンドリアンらのモダニズム、ウィンティ信仰（アフリカ系住民の伝統的な精霊信仰）、マルーン文化（アフリカから奴隷として連れて来られた人の一部が脱走し、現地でコミュニティを作る中でアフリカの伝統を継承して生み出した独自の文化）をテーマに制作をおこなうスリナム系オランダ人アーティスト、レーミ・ユンゲルマンの作品をまとめたものだ。本書はユンゲルマンが 2019 年のヴェネチア・ビエンナーレのために制作したモニュメンタルな作品で始まり、豊富な図版でリズミカルに彼の作品を紹介してゆく。本書の後半では、作家がよく使う白いカオリン粘土を参照する形で、赤いページに 4 層の白を重ねて印刷したページが続く。それがユンゲルマンの作品とのつながりを生むだけでなく、本書に明確なアイデンティティを与えている。このパートのページを埋めるどっしりとしたタイポグラフィの塊はモニュメンタルで彫刻的な存在感を放っており、写真パートと地続きでありながらも効果的な対比も生んでいる。後半ではビエンナーレでのインスタレーションに引き戻されるが、そこでも冒頭で使われたマスカットグレイの紙が再び使われている。イメージは同じくマスカットグレイのボード紙を使った表紙に流れこむ。カバーは本の裏面だけに縫い付けられ、綴じがあらわになった背表紙を回り込むように面面に折られている。ロジカルで効果的、美しく説得力もある。選考委員の意見はそのように一致した。`}</p>
    <hr></hr>
    <p>{`出版社： Jap Sam Books (Eleonoor Jap Sam), Prinsenbeek`}<br parentName="p"></br>{`
`}{`デザイン： Mainstudio (Edwin van Gelder, Florian Schimanski), Amsterdam`}</p>
    <p>{`カテゴリー： アート、写真、建築`}<br parentName="p"></br>{`
`}{`アーティスト：Remy Jungerman`}<br parentName="p"></br>{`
`}{`編集：Rob Perrée`}<br parentName="p"></br>{`
`}{`言語：英語`}<br parentName="p"></br>{`
`}{`翻訳：Jane Hall, Michael Meert`}<br parentName="p"></br>{`
`}{`写真：Aatjan Renders`}<br parentName="p"></br>{`
`}{`イメージ編集：Remy Jungerman, Edwin van Gelder`}<br parentName="p"></br>{`
`}{`編集：Eleonoor Jap Sam`}<br parentName="p"></br>{`
`}{`リトグラフ：robstolk® (Alex Feenstra), Amsterdam`}<br parentName="p"></br>{`
`}{`印刷：robstolk®, Amsterdam`}<br parentName="p"></br>{`
`}{`製本：Boekbinderij Patist, Den Dolder`}<br parentName="p"></br>{`
`}{`フォント：Funktional Grotesk (Typografische Systeme)`}<br parentName="p"></br>{`
`}{`サイズ（ミリ）：220 x 310 x 13`}<br parentName="p"></br>{`
`}{`ページ数：160`}<br parentName="p"></br>{`
`}{`発行部数：1,000`}<br parentName="p"></br>{`
`}{`価格：€ 29,50`}<br parentName="p"></br>{`
`}{`ISBN：978 94 92852 13 7`}<br parentName="p"></br>{`
`}{`本文用紙：100gsm Arcoprint Milk 1.5 (Fedrigoni), 140gsm Muskat Grey (Igepa), 120gsm Forever Red (Igepa)`}<br parentName="p"></br>{`
`}{`カバー：290gsm Muskat Grey (Igepa)`}<br parentName="p"></br>{`
`}{`製本方法：スイス装、背に透明糊付け、表紙は計６ページで裏に糊付けされており、表表紙として折り返される。`}<br parentName="p"></br>{`
`}{`特記事項：エッセイのテキストはオペークホワイトで 4 回印刷されている。カバーは CMYK とオペークホワイトで印刷。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      