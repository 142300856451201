import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.en.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The high-impact cover of this rugged cahier exudes the energy of Ide André’s work, which is focused around the action of painting – with rope, paint and canvas. In the same way his paintings are an impression of the action of painting, this book gives an impression of the atmosphere and circumstances in which this work is created, and of the artist’s attitude and nature. ‘Cahier’ may sound a little old-fashioned, but in actual fact this is a pile of spreads powerfully thrown together, in which the images at times cheekily run ‘round the back’ of the book block – for example the figure who, in an ecstatic pose with guitar, stretches over pages 8 and 105. At the same time, the execution of the book is extremely precise, with an exemplary Singer Sewn binding in black thread, first-class printing and lithography and a design that is rock ‘n’ roll without becoming messy. The intense fluorescent yellow of the text and cover pages lifts the entire book; the picture editing swings. Folding the pages to form a cahier, sewing using visible thread, the transition from yellow to white pages ruthlessly cutting across a picture spread: the action of making a book is made as manifest as the action of making the paintings in the artist’s creative process. Swinging, raw and precise.`}</p>
    <hr></hr>
    <p>{`Publisher: Jap Sam Books (Eleonoor Jap Sam), Prinsenbeek in association with Sieger White Stichting and Prins Bernhard Cultuurfonds`}<br parentName="p"></br>{`
`}{`Designer: Team Thursday, Rotterdam`}</p>
    <p>{`Category: Art, photo and architecture books`}<br parentName="p"></br>{`
`}{`Artist: Ide André`}<br parentName="p"></br>{`
`}{`Editor: Mirjam Westen`}<br parentName="p"></br>{`
`}{`Language: Dutch, English`}<br parentName="p"></br>{`
`}{`Translator: Gerard Forde`}<br parentName="p"></br>{`
`}{`Photographer: Ide André, Peter Cox, Oliver Schönemann, William van de Voort, Stefan Voelker, Floris de Vries, COSAR HMT`}<br parentName="p"></br>{`
`}{`Image editor: Team Thursday, Ide André`}<br parentName="p"></br>{`
`}{`Lithographer: BFC graphics and design (Bert van der Horst), Amersfoort`}<br parentName="p"></br>{`
`}{`Printer: Graphius Group, Gent (BE)`}<br parentName="p"></br>{`
`}{`Binder: Graphius Group, Gent (BE)`}<br parentName="p"></br>{`
`}{`Font: Neue Haas Grotesk Display (Linotype)`}<br parentName="p"></br>{`
`}{`Size in mm: 210 x 297 x 9`}<br parentName="p"></br>{`
`}{`Number of pages: 100`}<br parentName="p"></br>{`
`}{`Print run: 750`}<br parentName="p"></br>{`
`}{`Price: € 20`}<br parentName="p"></br>{`
`}{`ISBN: 978 94 92852 15 1`}<br parentName="p"></br>{`
`}{`Paper for interior: 100gsm Lessebo Design 1.3 White (Igepa)`}<br parentName="p"></br>{`
`}{`Cover: 200gsm Lessebo Design 1.3 White (Igepa)`}<br parentName="p"></br>{`
`}{`Binding style: Singer sewn binding`}<br parentName="p"></br>{`
`}{`Special features: Binding with black threads. The fore-edge is untrimmed, the book block contains 100 pages, the cover consists of 12 pages Printed in CMYK, Pantone 803 and 8003.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      