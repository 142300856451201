import React from "react"
import Layout from "../../../../layouts/en"
import BdbdMenu from "../../../../components/BdbdMenu.en"
import css from "../index.module.scss"

const IndexPage = props => {
  return (
    <Layout location={props.location} title={`Best Dutch Book Exhibition`}>
      <section className={css.bdbd__about + ` container`}>
        <div className={css.bdbd__content + ` content`}>
          <div className={css.bdbd__head + ` content__head`}>
            <h1 className={`haeding__lev1`}>
              BEST DUTCH <br className={`only-sp`} />
              BOOK DESIGNS
            </h1>
          </div>

          <nav className={css.bdbd__localnavi}>
            <BdbdMenu />
          </nav>

          <article className={`content__2col`}>
            <div className={`content__2col__side`}>
              <div className={`content__title`}>
                <h1 className={`haeding__lev2`}>Outline</h1>
              </div>
            </div>

            <div className={`editor__content content__2col__main`}>
              <section className={css.bdbd__section}>
                <h2>History</h2>
                <p>
                  With a history that stretches all the way back to 1926 the
                  annual selection of the Best Dutch Book Designs is Europe’s
                  oldest such event. That is not to say there have been no
                  interruptions. We distinguish three periods: 1925-32, 1948-71
                  and from 1986 up to and including the present. The first
                  keurcollectie (choice selection), as the collection of the
                  fifty best-produced books in the Netherlands was initially
                  known, was an initiative of the then Nederlandsch Verbond van
                  Boekenvrienden (NVB) (Netherlands Federation of Friends of the
                  Book), and in particular the designer S.H. de Roos. That
                  initial period was brought to an end by the slump and the
                  economic malaise of the thirties. Annual selections resumed in
                  1948 and continued under the auspices of the CPNB until 1971,
                  when it became impossible any longer to find the money for a
                  catalogue: the most important and tangible vehicle for the
                  contest. When Henk Kraima became director of Stichting CPNB in
                  1986 he embarked on a campaign for the return of the Best
                  Dutch Book Designs. An annual competition, and the impetus it
                  would give to demonstrating developments in the constantly
                  self-reinventing field of industrial design in printing and
                  publishing, now found itself with sufficient support in
                  publishing, the printing industry and related cultural
                  institutions such as the Stedelijk Museum. Thanks also to
                  financial support from the ministry of Welfare, Health &
                  Culture, after fifteen years’ absence it was again possible to
                  present a new selection of the Best Dutch Book Designs – once
                  again under the auspices of the CPNB. When government funding
                  ended, it was decided that in 1998 the contest should be
                  operated by a separate foundation, which would make it
                  possible to seek sponsoring. The new foundation was a
                  collaboration between three organizations: the Stichting CPNB
                  (Collectieve Propaganda van het Nederlandse Boek), the BNO
                  (Association of Dutch Designers) and the Grafische Cultuur
                  Stichting. Day-to-day management was in the hands of director
                  Just Enschedé, with financial support from Pictoright
                  (Amsterdam), paper manufacturer Antalis and L. van Heek
                  Textiles in Losser. In 2015 Enschedé retired and was succeeded
                  by Esther Scholten, who also works at the CPNB. Today the Best
                  Dutch Book Designs is still partly funded by Pictoright, but
                  Antalis has made way for paper supplier Igepa Nederland;
                  financial support is also still forthcoming from L. van Heek
                  Textiles.
                </p>
              </section>

              <section className={css.bdbd__section}>
                <h2>Mission</h2>
                <p>
                  The Foundation’s mission is in the widest sense to promote the
                  importance and to further the interests of qualitatively
                  excellent book design and production from the Dutch industry.
                </p>
              </section>
            </div>
          </article>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
