import React from "react"
import { EventProvider } from "../hooks"
import { GameCanvas } from "./GameCanvas"
import GlobalContextProvider from "../context/GlobalContextProvider"

type RootWrapperProps = Readonly<{
  element: React.ReactNode
}>

export const RootWrapper: React.FC<RootWrapperProps> = ({ element }) => {
  return (
    <GlobalContextProvider>
      <EventProvider>
        <GameCanvas />
        {element}
      </EventProvider>
    </GlobalContextProvider>
  )
}
