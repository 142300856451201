import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.en.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This book takes the form of a binder, like one of those suspension files used to keep pupils’ records in the school admin office. With recognisable ruler creases like those often seen on office stationery and a white sticker label bearing the title: American Origami. The unsettling content is the result of a six-year photographic investigation into American school shootings, neatly placed in chronological order from Columbine High School to Marjorie Stoneman Douglas High School in Parkland, Florida. Using a form of binding not, as far as we know, previously used, all the spreads in the book are stacked in landscape format and stapled on the right-hand side – including the cover. The entire body is then folded double, left to right, resulting in a very thick cahier. This allows us to leaf through this folded half, discovering one half of the book: disconcerting photos, without people, of the scenes of terrible events; portraits of those involved, cut-out school photos, interviews, Presidential speeches – each with its own distinct typography and colour or black-and-white printing. Each time, we can then lift the right-hand page, accessing the other half of the book block – a hidden section, containing photos of artefacts left behind by people at the scenes of the shootings: mementos, letters, but also social media threads from the alt-right scene. Rarely has such a highly charged topic been tackled in such an innovative way. The respect shown for the victims and the severity of the subject matter is also expressed by the incredibly painstaking way in which the technical idea has been made reality – for example, the thickness of the stack of spreads means that when the block is folded, the position of the fold moves up a little on each spread, meaning that each page has to be positioned by hand. The result is impressive; the form gives the project an additional layer and meaning. A file filled with the tragedy of a nation.`}</p>
    <hr></hr>
    <p>{`Publisher: Fw:Books, Amsterdam`}<br parentName="p"></br>{`
`}{`Designer: Hans Gremmen, Amsterdam`}</p>
    <p>{`Category: Art, photo and architecture books`}<br parentName="p"></br>{`
`}{`Artist: Andres Gonzalez`}<br parentName="p"></br>{`
`}{`Editor: Allison Dubinsky`}<br parentName="p"></br>{`
`}{`Language: English`}<br parentName="p"></br>{`
`}{`Image editor: Hans Gremmen, Andres Gonzalez`}<br parentName="p"></br>{`
`}{`Editor-in-chief: Hans Gremmen, Andres Gonzalez`}<br parentName="p"></br>{`
`}{`Producer: Jos Morree Fine Books, Weesp`}<br parentName="p"></br>{`
`}{`Lithographer: Colour & Books (Sebastiaan Hanekroot), Apeldoorn`}<br parentName="p"></br>{`
`}{`Printer: Grafiplaza, Mijdrecht`}<br parentName="p"></br>{`
`}{`Binder: Agia speciale grafische afwerking, Amsterdam`}<br parentName="p"></br>{`
`}{`Font: F Grotesk (RP Digital Type Foundry)`}<br parentName="p"></br>{`
`}{`Size in mm: 240 x 280 x 25`}<br parentName="p"></br>{`
`}{`Number of pages: 384`}<br parentName="p"></br>{`
`}{`Print run: 1,000`}<br parentName="p"></br>{`
`}{`Price: € 55`}<br parentName="p"></br>{`
`}{`ISBN: 978 94 90119 81 2`}<br parentName="p"></br>{`
`}{`Paper for interior: 85gsm Arcoprint Milk (Fedrigoni)`}<br parentName="p"></br>{`
`}{`Cover: 300gsm Parelgrijskarton (Koninklijke Moorman Karton)`}<br parentName="p"></br>{`
`}{`Binding style: The trimmed pages are stacked and stapled at the fore-edge to the back of the cover. Then the sheets are folded into a very thick cahier. To the best of our knowledge this binding style has not been used before.`}<br parentName="p"></br>{`
`}{`Special features: The finishing of this book was done in close association with the binder, designer and producer. The technical file had to have an asymmetric lay-out, which was designed on the basis of a predetermined model printed with a millimetre grid; in this way, the creep could be manually determined and adjusted for each spread. The cover is printed in seven colours: twice opaque white, CMYK and one colour mixed at the printing machine.`}</p>
    <p>{`Bronze medal Best Book Design from all over the World, Leipzig 2020`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      