import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/VabfEvents.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SocialShare = makeShortcode("SocialShare");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className="youtube__wrap">
  <iframe width="560" height="315" src="https://www.youtube.com/embed/9WNbjq4iHxA" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
    <div className="share__2col">
  <div className="share__2col__link">
    <a href="https://youtu.be/9WNbjq4iHxA" target="_blank">
      Youtube Live をみる
    </a>
  </div>
  <div className="share__2col__icon">
    <SocialShare title={props.pageContext.frontmatter.title} mdxType="SocialShare" />
  </div>
    </div>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "980px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "100%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAQDAQL/xAAXAQEAAwAAAAAAAAAAAAAAAAACAAED/9oADAMBAAIQAxAAAAHuu3qVQmIwUBeIWf8A/8QAHhAAAgEDBQAAAAAAAAAAAAAAAQIDERITAAQQFDL/2gAIAQEAAQUCeo1Nf1obsM1Q8nhJGszOdyXL8f/EABcRAAMBAAAAAAAAAAAAAAAAAAABEBL/2gAIAQMBAT8BNqM//8QAFhEAAwAAAAAAAAAAAAAAAAAAABAS/9oACAECAQE/ASX/AP/EAB8QAQACAQMFAAAAAAAAAAAAAAEAAhEQEiETMkFhgf/aAAgBAQAGPwIfcNjiV6ndCtKCTg+Q8RF4MzLp/8QAHBABAAMAAgMAAAAAAAAAAAAAAQARITFBYXGx/9oACAEBAAE/IRQNYnItVVIdq6bMzRbfyaHhRdRunpC5haEKt2upaNDP/9oADAMBAAIAAwAAABD3AAH/xAAXEQEBAQEAAAAAAAAAAAAAAAABEQAQ/9oACAEDAQE/EFmQZwF3/8QAGBEBAQADAAAAAAAAAAAAAAAAAQAQESH/2gAIAQIBAT8QhPcC6v/EAB0QAQACAwEAAwAAAAAAAAAAAAERIQAxQVFxkaH/2gAIAQEAAT8QISQW6TSJhCjqFFtgrDh1eZXyX4jEQvPW70vDSgSgiSVe5OAiJTxiSeYXiicFa1gP6iO3+YsIAYAz/9k=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/42739bb2d30aab41e2be01cedccca310/1a3b7/img_1.webp 245w", "/static/42739bb2d30aab41e2be01cedccca310/fb835/img_1.webp 490w", "/static/42739bb2d30aab41e2be01cedccca310/096d3/img_1.webp 980w", "/static/42739bb2d30aab41e2be01cedccca310/fefa6/img_1.webp 1024w"],
          "sizes": "(max-width: 980px) 100vw, 980px",
          "type": "image/webp"
        }}></source>{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/42739bb2d30aab41e2be01cedccca310/c168b/img_1.jpg 245w", "/static/42739bb2d30aab41e2be01cedccca310/bb66a/img_1.jpg 490w", "/static/42739bb2d30aab41e2be01cedccca310/2f919/img_1.jpg 980w", "/static/42739bb2d30aab41e2be01cedccca310/bef96/img_1.jpg 1024w"],
          "sizes": "(max-width: 980px) 100vw, 980px",
          "type": "image/jpeg"
        }}></source>{`
        `}<img parentName="picture" {...{
          "className": "gatsby-resp-image-image",
          "src": "/static/42739bb2d30aab41e2be01cedccca310/2f919/img_1.jpg",
          "alt": "img 1",
          "title": "img 1",
          "loading": "lazy",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          }
        }}></img>{`
      `}</picture>{`
    `}</span>
    <p>{`VABF ではウェブ開発費や運営資金を集めるために、TABF とゆかりのあるアーティストとともにさまざまなリターンアイテムを制作し、独自のファンディングプロジェクト「VABF KIOSK」を立ち上げました。富士ゼロックスのご協力のもと、"Iridesse™ Production Press"を使って、デザイナーと作家によるコラボレーション ZINE をリターンアイテムとして 3 冊制作しています。`}</p>
    <p>{`祖父江慎と川島小鳥（写真家）、田部井美奈と annco（イラストレーター）、田中義久と横田大輔（写真家）がタッグを組み、シルバー、ゴールドなどの特殊色を巧みに使いながら見たことのないような印刷物を生み出します。本トークでは後藤繁雄がモデレーターとなり、本づくりのプロセスや新技術がどのような新しい印刷表現をもたらしたのか、デザイナー 3 名に話を伺います。`}</p>
    <ul>
      <li parentName="ul">
        <span className="font__small">無料で視聴いただけます。</span>
      </li>
      <li parentName="ul">
        <span className="font__small">
  インターネット回線の状況やサーバー負荷、その他視聴者の PC
  環境により、映像が途切れる、または停止するなど正常に視聴できない事がございます。
        </span>
      </li>
      <li parentName="ul">
        <span className="font__small">
  配信終了後も TOKYO ART BOOK FAIR の youtube
  チャンネルで動画を視聴いただけます。
        </span>
      </li>
    </ul>
    <div {...{
      "className": "content__info"
    }}>{`
  `}<figure parentName="div" {...{
        "className": "content__info__img"
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAQEAAwAAAAAAAAAAAAAAAAQBAgMF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAwQC/9oADAMBAAIQAxAAAAH0JKo9F3NIWTA0cg03/8QAGxAAAgIDAQAAAAAAAAAAAAAAAAECEQMSMTL/2gAIAQEAAQUCoWRSdE5JFrezJ6fVz//EABgRAAIDAAAAAAAAAAAAAAAAAAEQAiEx/9oACAEDAQE/ARGkMX//xAAXEQEAAwAAAAAAAAAAAAAAAAAQAREh/9oACAECAQE/Ab0k/8QAGhAAAgIDAAAAAAAAAAAAAAAAARARMQASgf/aAAgBAQAGPwLIgiaexFLj/8QAHRABAAEDBQAAAAAAAAAAAAAAAQAQMWERIUFRgf/aAAgBAQABPyFBDvcDzTtF1j4AxDWUjszGx//aAAwDAQACAAMAAAAQZwDD/8QAGBEBAQADAAAAAAAAAAAAAAAAAQAQMYH/2gAIAQMBAT8QFa8xqm//xAAWEQEBAQAAAAAAAAAAAAAAAAABEDH/2gAIAQIBAT8QWAJqf//EAB0QAQADAAIDAQAAAAAAAAAAAAEAESExQYGx0fD/2gAIAQEAAT8QMVwNWZMhQlV9R1xNGUOL6gCDKK6smzcSd3b9S5Kqav7xKTZ//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/aca1050f121288cc675c1b50fa0b0c2b/1a3b7/img_2.webp 245w", "/static/aca1050f121288cc675c1b50fa0b0c2b/fb835/img_2.webp 490w", "/static/aca1050f121288cc675c1b50fa0b0c2b/e2a84/img_2.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/aca1050f121288cc675c1b50fa0b0c2b/c168b/img_2.jpg 245w", "/static/aca1050f121288cc675c1b50fa0b0c2b/bb66a/img_2.jpg 490w", "/static/aca1050f121288cc675c1b50fa0b0c2b/d5c47/img_2.jpg 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/aca1050f121288cc675c1b50fa0b0c2b/d5c47/img_2.jpg",
              "alt": "img 2",
              "title": "img 2",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
  `}</figure>{`
  `}<div parentName="div" {...{
        "className": "content__info__title"
      }}>{`
    `}<p parentName="div">{`祖父江慎`}</p>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "content__info__text"
      }}>{`
    `}<p parentName="div">{`
      1959年、愛知県生まれ。有限会社コズフィッシュ代表。並はずれた「うっとり力」をもって、日本のブックデザイン・展覧会の最前線で幅広く活躍。川島小鳥さんとの仕事は「未来ちゃん」（2011年／ナナロク社）、「川島小鳥写真展　未来ちゃん」（2014年／新潟市新津美術館）、「川島小鳥展　境界線で遊ぶ」（2017年／金津創作の森）などがある。過去の仕事をまとめた『祖父江慎＋コズフィッシュ』（パイ　インターナショナル刊）発売中。AGI、TDC会員。
    `}</p>{`
  `}</div>
    </div>
    <div {...{
      "className": "content__info"
    }}>{`
  `}<figure parentName="div" {...{
        "className": "content__info__img"
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAIDAAAAAAAAAAAAAAAAAAQFAQIG/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAABuaS4jE9gc5uEgH//xAAbEAEAAQUBAAAAAAAAAAAAAAACAwABBBITIv/aAAgBAQABBQKV84zlSXQWxk9GPW9RjQZjXbGdzH3kNf/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABsQAAMBAQADAAAAAAAAAAAAAAABEQISQVFh/9oACAEBAAY/Ata9FbYmNM5nwWUPPThqeCU//8QAGhAAAwEBAQEAAAAAAAAAAAAAAAERITFhUf/aAAgBAQABPyHBbFhAcviWHpoq4poocgjkEcoMyj0RXhsWRa9R/9oADAMBAAIAAwAAABCwCDz/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/EB//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/EB//xAAdEAEAAgIDAQEAAAAAAAAAAAABABEhQTFRYYGh/9oACAEBAAE/EBd492dSqqeBnrqERWGpzbYSXIpThVo/sT5Rq3cRSQQaBZ5HKysSufsJqAFJLwbn/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/36aae8de9836d268a71ee728e6e189dd/1a3b7/img_3.webp 245w", "/static/36aae8de9836d268a71ee728e6e189dd/fb835/img_3.webp 490w", "/static/36aae8de9836d268a71ee728e6e189dd/e2a84/img_3.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/36aae8de9836d268a71ee728e6e189dd/c168b/img_3.jpg 245w", "/static/36aae8de9836d268a71ee728e6e189dd/bb66a/img_3.jpg 490w", "/static/36aae8de9836d268a71ee728e6e189dd/d5c47/img_3.jpg 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/36aae8de9836d268a71ee728e6e189dd/d5c47/img_3.jpg",
              "alt": "img 3",
              "title": "img 3",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
  `}</figure>{`
  `}<div parentName="div" {...{
        "className": "content__info__title"
      }}>{`
    `}<p parentName="div">{`田部井美奈`}</p>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "content__info__text"
      }}>{`
    `}<p parentName="div">{`
      2003年より有限会社 服部一成に勤務。’14年に独立、田部井美奈デザインを設立。
      広告、CI、パッケージ、書籍、雑誌などの仕事を中心に活躍。2019 ADC賞受賞。
    `}</p>{`
  `}</div>
    </div>
    <div {...{
      "className": "content__info"
    }}>{`
  `}<figure parentName="div" {...{
        "className": "content__info__img"
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAUEAgb/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAGpDvRT0LoZMQVwf//EABsQAQACAgMAAAAAAAAAAAAAAAMAAgEEEjIz/9oACAEBAAEFAti+TI91cWjV5YIao0TpT0n/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAeEAABAwQDAAAAAAAAAAAAAAABABAhESIxQWFxsf/aAAgBAQAGPwIkZ0rjUN0pgesOUG//xAAaEAACAwEBAAAAAAAAAAAAAAAAAREhMRBB/9oACAEBAAE/IRfkPStNchXK1p4uXQhGQViok//aAAwDAQACAAMAAAAQMAgA/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAHBABAQADAQADAAAAAAAAAAAAAREAIUExUWGh/9oACAEBAAE/EIE6wO9/M7Mlc8EDPpMt2eYBBtItNMMC63S+ZDU/MScOy9wBDA8wcM//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/58899bc3f029033a612993557628d2ab/1a3b7/img_4.webp 245w", "/static/58899bc3f029033a612993557628d2ab/fb835/img_4.webp 490w", "/static/58899bc3f029033a612993557628d2ab/e2a84/img_4.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/58899bc3f029033a612993557628d2ab/c168b/img_4.jpg 245w", "/static/58899bc3f029033a612993557628d2ab/bb66a/img_4.jpg 490w", "/static/58899bc3f029033a612993557628d2ab/d5c47/img_4.jpg 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/58899bc3f029033a612993557628d2ab/d5c47/img_4.jpg",
              "alt": "img 4",
              "title": "img 4",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
  `}</figure>{`
  `}<div parentName="div" {...{
        "className": "content__info__title"
      }}>{`
    `}<p parentName="div">{`田中義久`}</p>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "content__info__text"
      }}>{`
    `}<p parentName="div">{`
      静岡県生まれ。永続性の高い文化的価値創造を理念にデザインを実践している。近年の仕事に東京都写真美術館のVI計画、「Takeo
      Paper
      Show」、「第58回ヴェネチア・ビエンナーレ国際美術展日本館」がある。また、飯田竜太（彫刻家）とのアーティストデュオNerholとしても活動。主な個展は「Index」（Foam
      Fotografiemuseum、アムステルダム）、「Promenade」（金沢21世紀美術館）など。
    `}</p>{`
  `}</div>
    </div>
    <div {...{
      "className": "content__info"
    }}>{`
  `}<figure parentName="div" {...{
        "className": "content__info__img"
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAIDBAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAABsz68pJ0ejAKwf//EABsQAAICAwEAAAAAAAAAAAAAAAECABEDEjEz/9oACAEBAAEFApsRlPajejdqoUBYqDP/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAcEAABAwUAAAAAAAAAAAAAAAARAAECEBIgMUH/2gAIAQEABj8CCtfVYnmP/8QAGhABAQEBAQEBAAAAAAAAAAAAAREAIRAxQf/aAAgBAQABPyFFQIX9d2qr55SoyppBHFb4BHNONkujKb//2gAMAwEAAgADAAAAEIDPPP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QH//EAB4QAQACAgIDAQAAAAAAAAAAAAEAESFBMVFxgaGx/9oACAEBAAE/EEJsBp9U8j56h+Ip2gIEgJo4Lvn3PwmKM0Ziu8tm7jFg1S90pP/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/441d6ed95c6ca40b520618a422a6bd96/1a3b7/img_5.webp 245w", "/static/441d6ed95c6ca40b520618a422a6bd96/fb835/img_5.webp 490w", "/static/441d6ed95c6ca40b520618a422a6bd96/e2a84/img_5.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/441d6ed95c6ca40b520618a422a6bd96/c168b/img_5.jpg 245w", "/static/441d6ed95c6ca40b520618a422a6bd96/bb66a/img_5.jpg 490w", "/static/441d6ed95c6ca40b520618a422a6bd96/d5c47/img_5.jpg 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/441d6ed95c6ca40b520618a422a6bd96/d5c47/img_5.jpg",
              "alt": "img 5",
              "title": "img 5",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
  `}</figure>{`
  `}<div parentName="div" {...{
        "className": "content__info__title"
      }}>{`
    `}<p parentName="div">{`後藤繁雄`}</p>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "content__info__text"
      }}>{`
    `}<p parentName="div">{`
      編集者、アートプロデューサー、京都芸術大学教授。若手アート作家の発掘・育成に取り組み、ギャラリー/出版社G/P＋abpを主宰。アートブック振興のためNEOTOKYOZINEのプロデュースも行う。また篠山紀信・蜷川実花らの大型美術館巡回展を成功させる。
    `}</p>{`
  `}</div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      