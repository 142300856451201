import type { Game } from "./Game"

const keyCss = `
border: 2px solid #999999;
border-radius: 10px;
color: #666666;
font-weight: bold;
width: 60px;
height: 40px;
background: white;
text-align: center;
vertical-align: middle;
line-height: 40px;
cursor: pointer;
user-select: none;
-webkit-touch-callout: none;
-webkit-user-select: none;
`

export class Input {
  private wrapper: HTMLElement
  private timer: NodeJS.Timeout | undefined
  private currentKey: string | undefined

  constructor(private game: Game) {
    const wrapper = document.createElement("div")
    wrapper.classList.add('controller')

    const lKey = document.createElement("button")
    lKey.id = "left"
    lKey.innerText = "←"
    lKey.classList.add('controller__left','btn__icon')
    wrapper.appendChild(lKey)

    const column = document.createElement("div")
    const uKey = document.createElement("button")
    uKey.id = "up"
    uKey.innerText = "↑"
    uKey.classList.add('controller__up','btn__icon')
    column.appendChild(uKey)

    const dKey = document.createElement("button")
    dKey.id = "down"
    dKey.innerText = "↓"
    dKey.classList.add('controller__down','btn__icon')
    column.appendChild(dKey)

    wrapper.appendChild(column)

    const rKey = document.createElement("button")
    rKey.id = "right"
    rKey.innerText = "→"
    rKey.classList.add('controller__right','btn__icon')
    wrapper.appendChild(rKey)

    document.body.appendChild(wrapper)

    wrapper.addEventListener("mousedown", this.onMouseDown)
    window.addEventListener("mouseup", this.onMouseUp)
    wrapper.addEventListener("touchstart", this.onMouseDown)
    window.addEventListener("touchend", this.onMouseUp)

    this.wrapper = wrapper
  }

  onMouseDown = (e: MouseEvent | TouchEvent) => {
    const id = (e.target as HTMLElement).id
    if (/(left|right|up|down)/.test(id)) {
      this.currentKey = id
      this.timer = setInterval(() => {
        this.game.setIsMoving(id as any, true)
      }, 66) as any
    }
  }

  onMouseUp = () => {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = undefined
    }
    if (this.currentKey) {
      this.game.setIsMoving(this.currentKey as any, false)
      this.currentKey = undefined
    }
  }

  dispose() {
    this.wrapper.removeEventListener("mousedown", this.onMouseDown)
    document.body.removeChild(this.wrapper)
  }
}
