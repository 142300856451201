import React from "react"
import Layout from "../layouts/en"
import CommonDoc from "../components/CommonDoc"

export default props => {
  const children = props.children
  const title = props.pageContext.frontmatter.title
  const description = props.pageContext.frontmatter.description

  return (
    <Layout location={props.location} title={title} description={description}>
      <section className={`container`}>
        <div className={`content`}>
          <div className={`content__head`}>
            <h1 className={`haeding__lev1`}>{title}</h1>
          </div>
          <div className={`editor__content`}>
            <CommonDoc>{children}</CommonDoc>
          </div>
          {/* /.editor__content */}
        </div>
        {/* /.content */}
      </section>
    </Layout>
  )
}
