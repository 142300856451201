import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`思慮が足りないというタイポロジーへの批判もあったが、本書はすでに古典の地位にある。ゼーランドの淀んだ緑の海に、このような色と生命の魅力的な世界が広がっていること、そしてこれは現実のものなのだろうかと私たちの頭を揺さぶることを予想できただろうか？　しかし、写真家エルズペス・ディーデリックスは本書で「ここにあるものは実際に存在する」と強調する。黒よりも暗いようなページ、黒い小口、黒いカバーに黒い見返しが組み合わさり、水中のイメージは 17 世紀のピーテル・クーラスやフロリス・ファン・ダイクによる静物のようなほの暗さを獲得しているが、ここに写るのは牡蠣、貝、海綿やイソギンチャクなど生きている存在、「ナチュール・モルト（フランス語で静物画を指す）」ではなく「ナチュール・ヴィヴァン（フランス語で歴史画や肖像画を指す）」だ。全体を通して深い黒を選択したことで、写真の魅力的な色や心酔させるような雰囲気を増強することに成功している。エンハンスド CMYK の採用も本書の鮮やかさを引き立てている。前述したように、本書はすでに古典なのだ。カバーからすでに物語は始まっている。タイポロジーが排除された一面の黒に、見たこともないような水中生物が浮かび上がっている。そこから、巻末の短いエッセイやドキュメンテーションを除きイメージが続くが、それ以外に何も必要ないのだ。ページをめくれば、うれしい驚きと発見が待っている。`}</p>
    <hr></hr>
    <p>{`出版社： Fw:Books, Amsterdam`}<br parentName="p"></br>{`
`}{`デザイン： Hans Gremmen, Amsterdam`}</p>
    <p>{`カテゴリー： アート、写真、建築`}<br parentName="p"></br>{`
`}{`言語：英語`}<br parentName="p"></br>{`
`}{`写真：Elspeth Diederix`}<br parentName="p"></br>{`
`}{`製作：Frank van der Stok, Amsterdam`}<br parentName="p"></br>{`
`}{`リトグラフ：robstolk® (Alex Feenstra), Amsterdam`}<br parentName="p"></br>{`
`}{`印刷：robstolk®, Amsterdam`}<br parentName="p"></br>{`
`}{`製本：Boekbinderij Patist, Den Dolder`}<br parentName="p"></br>{`
`}{`フォント：F Grotesk (RP Digital Type Foundry)`}<br parentName="p"></br>{`
`}{`サイズ（ミリ）：230 x 335 x 7`}<br parentName="p"></br>{`
`}{`ページ数：88`}<br parentName="p"></br>{`
`}{`発行部数：1,000`}<br parentName="p"></br>{`
`}{`価格：€ 35`}<br parentName="p"></br>{`
`}{`ISBN：978 94 90119 77 5`}<br parentName="p"></br>{`
`}{`本文用紙：120gsm Kamiko Shira (Cordier Spezialpapier)`}<br parentName="p"></br>{`
`}{`見返し：120gsm Kamiko Shira (Cordier Spezialpapier)`}<br parentName="p"></br>{`
`}{`装丁：120gsm Kamiko Shira (Cordier Spezialpapier)`}<br parentName="p"></br>{`
`}{`カバー：120gsm Kamiko Shira (Cordier Spezialpapier)`}<br parentName="p"></br>{`
`}{`製本方法：糸かがりハードカバー、背にリネン（テキスタイル Brillianta® 4000 - Van Heek Textiles）糊付け、装丁に 1.9mm グレーボード 紙（Koninklijke Moorman Karton 社）ラミネート加工。一番最初と最後のページに見返し加工。中身は黒糸で綴られた 8 つの折丁`}<br parentName="p"></br>{`
`}{`特記事項：黒いページと写真の枠は黒と Pantone406 で印刷。小口は Critis(ベールネム、ベルギー)による改良された CMYK インクで黒に印刷。Spant Zeefdruk(ユトレヒト、オランダ)によるスクリーン印刷。`}</p>
    <p>{`世界で最も美しい本コンクール 2020 栄誉賞受賞作（ライプツィヒ、ドイツ）。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      