const HTML_ENTITIES: Record<string, string> = {
  "&amp;": "&",
  "&apos;": "'",
  "&#x27;": "'",
  "&#x2F;": "/",
  "&#39;": "'",
  "&#47;": "/",
  "&lt;": "<",
  "&gt;": ">",
  "&nbsp;": " ",
  "&quot;": '"',
}

export function decodeHTMLEntities(text: string): string {
  return text.replace(/(&.*;)/g, t => HTML_ENTITIES[RegExp.$1] ?? RegExp.$1)
}
