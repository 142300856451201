import React, { useContext } from "react"
import { Link } from "gatsby"
import css from "./GlobalMenu.module.scss"
import { FormattedMessage } from "react-intl"
import { navigate } from "../../.cache/gatsby-browser-entry"
import { useEvents } from "../hooks"
import LanguageSwitcher from "./LanguageSwitcher"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider"

const GlobalMenu = props => {
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)

  const events = useEvents()

  const langDir = props.locale === `en` ? "/en" : ""

  let goto3dEntrance = () => {
    navigate(`${langDir}/`)
    events.emit("changeArea", "entrance")
    props.onClose()
  }

  let goto3dBooths = () => {
    navigate(`${langDir}/exhibitors/`)
    events.emit("changeArea", "booth")
    props.onClose()
  }

  let goto3dRafael = () => {
    navigate(`${langDir}/rafael/`)
    events.emit("changeArea", "rafael")
    props.onClose()
  }

  let toggleSound = () => {
    if (state.useSound) {
      events.emit("toggleAudio", false, true)
      dispatch({ type: "SOUND_OFF" })
    } else {
      events.emit("toggleAudio", true, true)
      dispatch({ type: "SOUND_ON" })
    }
    dispatch({ type: "INCREMENT_TEST_COUNT" })
  }

  const cssSoundState = state.useSound ? css.soundActive : css.soundDeactive

  return (
    <div className={css.menuModal}>
      <div className={css.menuModal__inner}>
        <LanguageSwitcher langs={props.langs} />

        <nav className={css.gnav}>
          <div className={css.gnav__inner}>
            <div className={css.gnav__sound} data-motion="gnav-sound">
              <button onClick={toggleSound} className={cssSoundState}>
                <span>Sound ON/OFF</span>
              </button>
            </div>

            <div data-motion="gnav-primary">
              <h2
                className={
                  css.gnav__hedding__lev1 + ` ` + css.gnav__hedding__main
                }
                data-motion="gnav-item-1"
              >
                {state.webGlLoaded ? (
                  <button onClick={goto3dBooths}>EXHIBITORS</button>
                ) : (
                  <a href={`${langDir}/exhibitors/`}>EXHIBITORS</a>
                )}
              </h2>

              <h2 className={css.gnav__hedding__lev1} data-motion="gnav-item-2">
                EXHIBITIONS
              </h2>
              <ul className={css.gnav__list__primary}>
                <li data-motion="gnav-item-3">
                  <Link to={`${langDir}/exhibitions/best-dutch-book/`}>
                    BEST DUTCH BOOK DESIGNS
                  </Link>
                </li>
                <li data-motion="gnav-item-4">
                  <Link to={`${langDir}/exhibitions/dutch-artist-book/`}>
                    DUTCH ARTISTS’ BOOK: <br className={`only-sp`} />
                    THEN AND NOW
                  </Link>
                </li>
                <li data-motion="gnav-item-5">
                  <span className={css.gnav__note}>FANFARE</span>
                  <Link to={`${langDir}/exhibitions/fanfare/`}>
                    UNLEARN, DISPLAY, CONNECT
                  </Link>
                </li>
                <li data-motion="gnav-item-6">
                  <span className={css.gnav__note}>Rafaël Rozendaal</span>
                  {state.webGlLoaded ? (
                    <button onClick={goto3dRafael}>
                      SHADOW OBJECTS <br className={`only-sp`} />
                      SCULPTURE PARK
                    </button>
                  ) : (
                    <a href={`${langDir}/rafael/`}>
                      SHADOW OBJECTS <br className={`only-sp`} />
                      SCULPTURE PARK
                    </a>
                  )}
                </li>
              </ul>

              <h2
                className={
                  css.gnav__hedding__lev1 + ` ` + css.gnav__hedding__main
                }
                data-motion="gnav-item-7"
              >
                <span className={css.gnav__note}>FUJI XEROX</span>
                <Link to={`${langDir}/printing-laboratory/`}>
                  ZINE LABORATORY
                </Link>
              </h2>

              <h2 className={css.gnav__hedding__lev1} data-motion="gnav-item-8">
                EVENTS
              </h2>
              <ul className={css.gnav__list__primary}>
                <li data-motion="gnav-item-9">
                  <Link
                    to={`https://tokyoartbookfair.com/events/`}
                    className={"link__blank"}
                    target={`_blank`}
                  >
                    VABF EVENTS
                  </Link>
                </li>
                <li data-motion="gnav-item-10">
                  <Link to={`${langDir}/events/exhibitors-events/`}>
                    EXHIBITORS' EVENTS
                  </Link>
                </li>
                <li data-motion="gnav-item-11">
                  <Link to={`${langDir}/events/virtual-book-signing/`}>
                    VIRTUAL BOOK SIGNINGS
                  </Link>
                </li>
              </ul>

              <h2
                className={
                  css.gnav__hedding__lev1 + ` ` + css.gnav__hedding__main
                }
                data-motion="gnav-item-12"
              >
                <a
                  className={"link__blank"}
                  href={`https://9204.teacup.com/vabf/bbs`}
                  target={`_blank`}
                >
                  LOUNGE
                </a>
                <span className={css.gnav__note}>
                  <FormattedMessage id={`bbsMessage`} />
                </span>
              </h2>

              <h2
                className={
                  css.gnav__hedding__lev1 + ` ` + css.gnav__hedding__last
                }
                data-motion="gnav-item-13"
              >
                <a
                  className={"link__blank"}
                  href={`https://vabf.shop/`}
                  target={`_blank`}
                >
                  VABF KIOSK
                </a>
                <span className={css.gnav__note}>
                  <FormattedMessage id={`kioskMessage`} />
                </span>
              </h2>
            </div>
          </div>
          {/* /.gnav__inner */}

          <div className={css.gnav__inner} data-motion="gnav-secondary">
            <h2 className={css.gnav__hedding__lev1} data-motion="gnav-item-1">
              REAL EVENTS
            </h2>
            <ul className={css.gnav__list__secondary}>
              <li data-motion="gnav-item-2">
                <a
                  className={"link__blank"}
                  href={`https://tokyoartbookfair.com/news/13022/`}
                  target={`_blank`}
                >
                  <FormattedMessage id={`museumTokyo`} />
                </a>
              </li>
              <li data-motion="gnav-item-3">
                <a
                  className={"link__blank"}
                  href={`https://tokyoartbookfair.com/news/13024/`}
                  target={`_blank`}
                >
                  MICRO FOOD &amp; IDEA MARKET
                </a>
              </li>
            </ul>

            <h2 className={css.gnav__hedding__lev1} data-motion="gnav-item-4">
              UTILITY
            </h2>
            <ul className={css.gnav__list__secondary}>
              <li data-motion="gnav-item-5">
                <Link to={`${langDir}/usefull-links/`}>
                  <FormattedMessage id={`usefullinks`} />
                </Link>
              </li>
            </ul>

            <h2 className={css.gnav__hedding__lev1} data-motion="gnav-item-6">
              INFORMATION
            </h2>
            <ul className={css.gnav__list__secondary}>
              <li data-motion="gnav-item-7">
                <a
                  className={"link__blank"}
                  href={`https://tokyoartbookfair.com/news/`}
                  target={`_blank`}
                >
                  NEWS
                </a>
              </li>
              <li data-motion="gnav-item-8">
                <a
                  className={"link__blank"}
                  href={`https://docs.google.com/document/d/1tMHEbAyebIQ_o0Uidk42zZ2Xi-gs7x95Pj2DKzAGbwk/edit`}
                  target={`_blank`}
                >
                  FAQ
                </a>
              </li>
              <li data-motion="gnav-item-9">
                <Link to={`${langDir}/information-desk/`}>ABOUT</Link>
              </li>
              <li data-motion="gnav-item-10">
                <a
                  className={"link__blank"}
                  href={`https://tokyoartbookfair.com/en/partners/`}
                  target={`_blank`}
                >
                  PARTNERS
                </a>
              </li>
              <li data-motion="gnav-item-11">
                <Link to={`${langDir}/credits/`}>STAFF</Link>
              </li>
              <li data-motion="gnav-item-12">
                <a
                  className={"link__blank"}
                  href={`https://tokyoartbookfair.com/archives/`}
                  target={`_blank`}
                >
                  TABF ARCHIVES
                </a>
              </li>
              <li className={css.gnav__sns} data-motion="gnav-item-13">
                <a href={`https://twitter.com/tabf_info`} target={`_blank`}>
                  <i aria-label="Twitter" className={"icon-twitter_circle"}></i>
                </a>
                <a
                  href={`https://www.facebook.com/tokyoartbookfair`}
                  target={`_blank`}
                >
                  <i aria-label="Facebook" className={"icon-facebook"}></i>
                </a>
                <a
                  href={`https://www.instagram.com/tokyoartbookfair/`}
                  target={`_blank`}
                >
                  <i aria-label="Facebook" className={"icon-instagram"}></i>
                </a>
              </li>
            </ul>

            <h2 className={css.gnav__hedding__lev1} data-motion="gnav-item-14">
              SATELLITE
            </h2>
            <ul className={css.gnav__list__secondary}>
              <li data-motion="gnav-item-15">
                <a
                  className={"link__blank"}
                  href={`https://www.honkawamachi.com/`}
                  target={`_blank`}
                >
                  <FormattedMessage id={`honkawamachi`} />
                </a>
                <span className={css.gnav__note}>
                  <FormattedMessage id={`honkawamachiMessage`} />
                </span>
              </li>
            </ul>
            <div className={css.gnav__home} data-motion="gnav-home">
              {state.webGlLoaded ? (
                <button onClick={goto3dEntrance}>RETURN TO HOME</button>
              ) : (
                <a href={`${langDir}/`}>RETURN TO HOME</a>
              )}
            </div>
            <div className={css.gnav__simple} data-motion="gnav-simple">
              <Link to={`${langDir}/exhibitors/list`}>
                <FormattedMessage id={`simpleHtmlVersion`} />
              </Link>
            </div>
          </div>

          <div className={css.gnav__footer}>
            <ul className={css.gnav__list__footer} data-motion="gnav-footer">
              <li>
                <Link to={`${langDir}/terms-of-use/`}>TERMS OF USE</Link>
              </li>
              <li>
                <Link to={`${langDir}/privacy-policy/`}>PRIVACY POLICY</Link>
              </li>
            </ul>
          </div>
          {/* /.gnav__inner */}
        </nav>
      </div>
    </div>
  )
}
export default GlobalMenu
