import React, { useEffect, useState } from "react"
import Layout from "../../../layouts/en"
import { loadExhibitors } from "../../../api"
import SearchExhibitorsList from "../../../components/SearchExhibitorsList"
import { navigate } from "gatsby"
import CommonDoc from "../../../components/CommonDoc"
import css from "./index.module.scss"

function IndexPage(props) {
  // JSONからロードした出店者リスト
  const [exhibitors, setExhibitors] = useState([])

  // コンポーネントのマウント時に出店者リストをロードする
  useEffect(() => {
    loadExhibitors().then(_exhibitors => {
      const exhibitors = JSON.parse(JSON.stringify(_exhibitors))
      setExhibitors(exhibitors)
    })
  }, [])

  return (
    <Layout
      location={props.location}
      title={`Exhibitors List`}
      show3dLayer={false}
    >
      <CommonDoc>
        <div className={`container`}>
          <div className={`content`}>
            <div className={`content__head`}>
              <h1 className={`haeding__lev1`}>
                Welcome to <br className={`only-sp`} />
                the VIRTUAL ART <br className={`only-sp`} />
                BOOK FAIR!
              </h1>
            </div>
            <div className={`content__lead`}>
              <p>
                At VABF, we offer an experience that allows the visitors to
                enjoy the fair while coming across exhibitor booths within the
                3D space.
                <br className={`only-pc`} />
                While we want to provide everyone with the experience of the
                fair in 3D space, we understand that smartphones and some other
                devices may not be compatible to fully experience the fair.
                <br className={`only-pc`} />
                In this page, we provide text links to exhibitor booths so that
                you can still enjoy the fair without the requirements for the
                full 3D experience.
              </p>
            </div>
          </div>
        </div>
      </CommonDoc>

      {/* フィルターされた出店者を描画 */}
      <div className={css.exhivitorslist}>
        <div>
          <SearchExhibitorsList
            exhibitors={exhibitors}
            sortKey={`name`}
            lang={`en`}
            doModalClose={() => {
              // console.log(`modal close`)
            }}
            doClickCallBack={id => {
              navigate(`/en/exhibitors/${id}`, {
                state: { modal: true, id: id },
              })
            }}
          />
        </div>
      </div>
      <CommonDoc>
        <div className={`container`}>
          <div className={`content`}>
            <div className={`editor__content`}>
              <section className={css.info}>
                <h2>Recommended environment for viewing this site</h2>
                <p>
                  The latest version of Google Chrome is recommended for the
                  best user experience. Please note that you may not be able to
                  use the site without the specified OS and browsers below.
                </p>
              </section>
              <section className={css.info}>
                <h2>Recommended OS</h2>
                <ul>
                  <li>Windows / Mac</li>
                  <li>
                    It works on iOS / Android as well, but some features might
                    not be displayed depending on the specifications.
                  </li>
                </ul>
              </section>
              <section className={css.info}>
                <h2>Recommended Browser</h2>
                <ul>
                  <li>Latest version of Google Chrome</li>
                  <li>
                    (The site may be unstable on Retina display and Safari.)
                  </li>
                </ul>
              </section>
            </div>
          </div>
        </div>
      </CommonDoc>
    </Layout>
  )
}

export default IndexPage
