import { EventEmitter } from "events"

export type MouseInfo = Readonly<{
  x: number
  y: number
  w: number
  h: number
  isRight: boolean
}>

function isTouchEvent(e: MouseEvent | TouchEvent): e is TouchEvent {
  return window.TouchEvent && e instanceof TouchEvent
}

export class MouseWatcher extends EventEmitter {
  static isEnabled = false

  private isPressed: boolean = false
  private isDrag: boolean = false
  private isRight: boolean = false

  constructor(private canvas: HTMLCanvasElement) {
    super()
    canvas.addEventListener("mousedown", this.onMouseDown, { passive: true })
    canvas.addEventListener("mousemove", this.onMouseMove, { passive: true })
    canvas.addEventListener("mouseup", this.onMouseUp, { passive: true })
    canvas.addEventListener("mouseleave", this.onMouseLeave, { passive: true })
    canvas.addEventListener("touchstart", this.onMouseDown, { passive: true })
    canvas.addEventListener("touchmove", this.onMouseMove, { passive: true })
    canvas.addEventListener("touchend", this.onMouseUp, { passive: true })
  }

  dispose(): void {
    this.canvas.removeEventListener("mousedown", this.onMouseDown)
    this.canvas.removeEventListener("mousemove", this.onMouseMove)
    this.canvas.removeEventListener("mouseup", this.onMouseUp)
    this.canvas.removeEventListener("mouseleave", this.onMouseLeave)
    this.canvas.removeEventListener("touchstart", this.onMouseDown)
    this.canvas.removeEventListener("touchmove", this.onMouseMove)
    this.canvas.removeEventListener("touchend", this.onMouseUp)
  }

  getMouseInfo(e: MouseEvent | TouchEvent): MouseInfo {
    const element = e.target as HTMLElement

    let x: number, y: number
    if (isTouchEvent(e)) {
      // canvas要素上のXY座標
      const t = e.changedTouches[0]
      x = t.clientX - element.offsetLeft
      y = t.clientY - element.offsetTop
    } else {
      // canvas要素上のXY座標
      x = e.clientX - element.offsetLeft
      y = e.clientY - element.offsetTop
    }

    // canvas要素の幅・高さ
    const w = element.offsetWidth
    const h = element.offsetHeight

    return { x, y, w, h, isRight: this.isRight }
  }

  onMouseDown = (e: MouseEvent | TouchEvent) => {
    // Ignore multi click
    if (this.isPressed) {
      return
    }

    this.isPressed = true
    this.isRight = e instanceof MouseEvent && e.button === 2

    if (MouseWatcher.isEnabled) {
      this.emit("mousedown", this.getMouseInfo(e))
    }
  }

  onMouseMove = (e: MouseEvent | TouchEvent) => {
    if (this.isPressed) {
      this.isDrag = true
    } else if (MouseWatcher.isEnabled) {
      this.emit("mousemove", this.getMouseInfo(e))
    }
  }

  onMouseUp = (e: MouseEvent | TouchEvent) => {
    if (this.isPressed && !this.isDrag && MouseWatcher.isEnabled) {
      this.emit("click", this.getMouseInfo(e))
    }

    this.isPressed = false
    this.isDrag = false
    this.isRight = false
  }

  onMouseLeave = (e: MouseEvent) => {
    this.isPressed = false
    this.isDrag = false
    this.isRight = false
  }
}
