import React from "react"
import css from "./SearchExhibitors.module.scss"
import { useEvents } from "../hooks"
import { Exhibitor, ExhibitorCategory } from "../api"
import {decodeHTMLEntities} from "../utils/decodeHTMLEntities"

type SearchExhibitorsListProps = {
  exhibitors: Exhibitor[]
  doModalClose: () => void
  sortKey: string
  lang: string
  doClickCallBack: () => void
}

function SearchExhibitorsList(prop: SearchExhibitorsListProps) {
  const events = useEvents()
  const lang = prop.lang ? prop.lang : "en"

  /**
   * 出店者リストをクローンし、ソートして返す。
   */
  const sortExhibitorsByKey = (
    exhibitors: Exhibitor[],
    key: string
  ): Exhibitor[] => {
    const newExhibitors = JSON.parse(JSON.stringify(exhibitors))
    if (key === "category") {
      return newExhibitors.sort((a: any, b: any) =>
        a.category.name_en < b.category.name_en ? -1 : 1
      )
    }

    return newExhibitors.sort((a: any, b: any) =>
      a[key].toUpperCase() < b[key].toUpperCase() ? -1 : 1
    )
  }

  /**
   * ベンダーページへ移動
   * @param id
   */
  const moveToVendor = (id: string) => () => {
    events.emit("moveToVendor", id)
    prop.doModalClose && prop.doModalClose()
    prop.doClickCallBack && prop.doClickCallBack(id)
  }

  const normalizeInitial = (src: string) => {
    src = src.toUpperCase()
    if (src.match(/[0-9]/)) {
      return "0-9"
    } else if (
      src.match(
        /[々〇〻\u3400-\u9FFF\uF900-\uFAFF]|[\uD840-\uD87F][\uDC00-\uDFFF]/
      )
    ) {
      return "漢"
    } else if (src.match(/[\u3041-\u3096]|[\u30A1-\u30FA]/)) {
      return "かな"
    }
    return src
  }

  const normalizeCountry = (src: string) => {
    src = src.toUpperCase()
    return src
  }

  /**
   * ソートし終えたグループを書き出し
   * @param groups
   * @param ignoreCountry
   */
  const exportGroups = (
    groups: {
      groupTitle: string
      contents: Exhibitor[]
    }[],
    ignoreCountry: boolean = false
  ) => {
    // console.log(groups)

    // 分類を出力
    let output = []
    let counter = 0

    for (const group of groups) {
      let list = []

      for (const exhibitor of group.contents) {
        list.push(
          <li
            key={counter + `-` + exhibitor.exhibitorId}
            className={css.listItem}
          >
            <a onClick={moveToVendor(exhibitor.exhibitorId)}>
              <div>
                {lang === `en` && exhibitor.nameEn ? (
                  <span>{decodeHTMLEntities(exhibitor.nameEn)}</span>
                ) : (
                  <span>{decodeHTMLEntities(exhibitor.name)}</span>
                )}
                {!ignoreCountry && exhibitor.country && (
                  <span className={css.country}>({exhibitor.country})</span>
                )}
              </div>
            </a>
          </li>
        )
        counter += 1
      }
      output.push(
        <div key={counter}>
          <h2 className={css.exhibitorListHead}>{group.groupTitle}</h2>
          <ul className={css.exhibitorList}>{list}</ul>
        </div>
      )
      counter += 1
    }

    return output
  }

  /**
   * アルファベティカル
   */
  const getNameSortedContent = () => {
    let sortedExhibitors = null

    if (lang === `ja`) {
      sortedExhibitors = sortExhibitorsByKey(prop.exhibitors, `name`)
    } else {
      sortedExhibitors = sortExhibitorsByKey(prop.exhibitors, `nameEn`)
    }

    let alphabetGroups = []
    let currentGroup: {
      groupTitle: string
      contents: Exhibitor[]
    } = {
      groupTitle: "",
      contents: [],
    }

    // アルファベッドで分類
    for (const exhibitor of sortedExhibitors) {
      let initial = null
      if (lang === `ja`) {
        initial = normalizeInitial(exhibitor.name.charAt(0))
      } else {
        initial = normalizeInitial(exhibitor.nameEn.charAt(0))
      }
      if (currentGroup.groupTitle !== initial) {
        currentGroup = {
          groupTitle: initial,
          contents: [],
        }
        alphabetGroups.push(currentGroup)
      }

      currentGroup.contents.push(exhibitor)
    }
    return exportGroups(alphabetGroups)
  }

  /**
   * 国別
   */
  const getCountrySortedContent = () => {
    const sortedExhibitors = sortExhibitorsByKey(prop.exhibitors, `country`)

    let groups = []
    let currentGroup: {
      groupTitle: string
      contents: Exhibitor[]
    } = {
      groupTitle: "",
      contents: [],
    }

    // アルファベッドで分類
    for (const exhibitor of sortedExhibitors) {
      let country = normalizeCountry(exhibitor.country)

      if (currentGroup.groupTitle !== country) {
        currentGroup = {
          groupTitle: country,
          contents: [],
        }
        groups.push(currentGroup)
      }
      currentGroup.contents.push(exhibitor)
    }

    return exportGroups(groups, true)
  }

  /**
   * カテゴリーソート
   */
  const getCategorySortedContent = () => {
    const sortedExhibitors = sortExhibitorsByKey(prop.exhibitors, `category`)

    let groups = []
    let currentGroup: {
      groupTitle: string
      contents: Exhibitor[]
    } = {
      groupTitle: "",
      contents: [],
    }

    for (const exhibitor of sortedExhibitors) {
      let category: ExhibitorCategory = exhibitor.category
      let title = lang === `ja` ? category.name_ja : category.name_en

      if (currentGroup.groupTitle !== title) {
        currentGroup = {
          groupTitle: title,
          contents: [],
        }
        groups.push(currentGroup)
      }
      currentGroup.contents.push(exhibitor)
    }

    return exportGroups(groups, true)
  }

  if (prop.sortKey === "name") {
    return getNameSortedContent()
  } else if (prop.sortKey === "category") {
    return getCategorySortedContent()
  } else {
    return getCountrySortedContent()
  }
}

export default SearchExhibitorsList
