/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useCallback } from "react"
import { useEvents } from "../hooks"
import { useState } from "react"
import css from "./MovePageConfirm.module.scss"

function MovePageConfirm() {
  // state を使う
  const [confirmParam, setConfirmParam] = useState(null)
  // event hook
  const events = useEvents()

  /**
   *
   * @param param MovePageConfirmParam オブジェクト
   */
  const doConfirm = useCallback(param => {
    let data = Object.assign({}, param) //オブジェクトを新しく作り直す必要がある
    setConfirmParam(data)
  }, [])

  useEffect(() => {
    events.on("requestMovePage", doConfirm)
    return () => {
      events.off("requestMovePage", doConfirm)
    }
  }, [events, doConfirm])

  const cancelHandler = e => {
    setConfirmParam(null)
  }

  const okHandler = useCallback(e => {
    if (typeof window === "undefined") {
      return;
    }

    if (confirmParam.event) {
      events.emit('movePageConfirmed', confirmParam.event)
      setConfirmParam(null)
    } else {
      window.open(confirmParam.url, "_blank")
    }
  }, [confirmParam, events])

  return (
    <div>
      {confirmParam && (
        <div className={css.base}>
          <div className={css.base__title}>{confirmParam.title}</div>
          <p className={css.base__description}>{confirmParam.description}</p>
          <button className={css.base__enter} onClick={okHandler}>
            {confirmParam.label}
          </button>
          <button className={css.base__cancel} onClick={cancelHandler}>
            <span className={`u-reader`}>Cancel</span>
          </button>
        </div>
      )}
    </div>
  )
}

export default MovePageConfirm
