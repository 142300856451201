import React from "react"
import Layout from "../../../layouts/en"
import { graphql, Link } from "gatsby"
import BdbdMenu from "../../../components/BdbdMenu.en"
import Img from "gatsby-image"
import css from "./index.module.scss"

const IndexPage = props => {
  let posts = []
  for (let i = 0; i < props.data.allMdx.edges.length; i += 1) {
    let thePost = props.data.allMdx.edges[i].node

    posts.push(
      <li key={i} className={css.bdbd__items__item}>
        <Link
          to={`/${thePost.slug}`}
          state={{
            modal: true,
          }}
          className={css.bdbd__items__item__link}
        >
          <Img
            className={css.bdbd__items__item__img}
            fluid={thePost.frontmatter.thumbnail.childImageSharp.fluid}
          />
          <h2 className={css.bdbd__items__item__title}>
            {thePost.frontmatter.title}
          </h2>
          <p className={css.bdbd__items__item__name}>
            {thePost.frontmatter.author}
          </p>
        </Link>
      </li>
    )
  }

  return (
    <Layout location={props.location} title={`Best Dutch Book Exhibition`}>
      <section className={css.bdbd__index + ` container`}>
        <div className={css.bdbd__content + ` content`}>
          <div className={css.bdbd__head + ` content__head`}>
            <h1 className={`haeding__lev1`}>
              BEST DUTCH <br className={`only-sp`} />
              BOOK DESIGNS
            </h1>
          </div>

          <nav className={css.bdbd__localnavi}>
            <BdbdMenu />
          </nav>

          <div className={css.bdbd__read}>
            <p className={css.bdbd__read__main}>
              Established in 1926, Best Dutch Book Designs (BDBD) are the oldest
              book design awards in Europe. The awards cover artbooks,
              children’s books, cookbooks, and much more made by Dutch or
              Netherlands-based designers. This year’s VABF will feature 33
              award-winning pieces from 2019, including Andres Gonzalez’s
              “American Origami” with design by Hans Gremen and Mariko
              Kuwabara’s “Burning Love” designed by Ayumi Higuchi.
            </p>
            <p className={css.bdbd__read__sub}>
              Support: Stichting De Best Verzorgde Boeken
            </p>
          </div>

          <ul className={css.bdbd__items}>{posts}</ul>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage

export const posts = graphql`
  query {
    allMdx(
      filter: { slug: { regex: "/^en/.+best-dutch-book/" } }
      sort: { fields: slug }
    ) {
      edges {
        node {
          id
          slug
          frontmatter {
            title
            author
            thumbnail {
              id
              childImageSharp {
                fluid(maxWidth: 1280) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            genre
          }
          excerpt
        }
      }
    }
  }
`
