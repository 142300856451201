import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.en.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The assignment was simply: the 24-hour economy. For Document Nederland – the prestigious series by Rijksmuseum Amsterdam which since 1975 has commissioned a Dutch photographer to depict a topic of contemporary social interest – Martijn van de Griendt photographed a society in which we are always ‘on’. A world of selfies and likes, Happinez festivals and Fashion weeks. Van de Griendt’s gritty street photography is shown on bled-off pages, which also given their large format draws us nicely ‘into the picture’: in your face, which is exactly what this photography wants to be. The semi-gloss coated paper is an excellent choice for this, contrasting pleasantly with the uncoated paper of the text sections, which are interspersed like layers of fat throughout the book. The captions are given a section of their own at the back, which always works well (‘`}{`[25]`}{` Father and son weekend at Scoutingclub St. Radboud in Santpoort’). Small texts are scattered across the dust jacket and the four grey cardboard pages of the stitched-in cover: quotes, blurb, title words, author, credits. The title’s ‘hier’ (‘here’) is placed on the spine, creating an effective play on being at the same time ‘here’ and ‘there’: being present at a festival but in fact mainly on Instagram; being on holiday but at the same time on Facebook. The photos are ‘not loaded’ on the dust jacket: grey rectangles with numbers corresponding to images in the book. Intelligently made, well thought-out and superbly executed.`}</p>
    <hr></hr>
    <p>{`Publisher: Martijn van de Griendt, Haarlem in association with Gloude Publishing, Amsterdam`}<br parentName="p"></br>{`
`}{`Designer: Inedition (Eva van der Schans), Haarlem`}</p>
    <p>{`Category: Art, photo and architecture books`}<br parentName="p"></br>{`
`}{`Editor: Wanda Gloude, Stef Verhoeven`}<br parentName="p"></br>{`
`}{`Language: Dutch`}<br parentName="p"></br>{`
`}{`Photographer: Martijn van de Griendt`}<br parentName="p"></br>{`
`}{`Image editor: Eva van der Schans, Martijn van de Griendt`}<br parentName="p"></br>{`
`}{`Editor-in-chief: Wanda Gloude, Stef Verhoeven`}<br parentName="p"></br>{`
`}{`Lithographer: Wilco Art Books (Mariska Bijl), Amersfoort`}<br parentName="p"></br>{`
`}{`Printer: Wilco Art Books, Amersfoort`}<br parentName="p"></br>{`
`}{`Binder: Boekbinderij Patist, Den Dolder`}<br parentName="p"></br>{`
`}{`Font: Agipo (RP Digital Type Foundry), Neue Helvetica (Linotype)`}<br parentName="p"></br>{`
`}{`Size in mm: 240 x 320 x 10`}<br parentName="p"></br>{`
`}{`Number of pages: 168`}<br parentName="p"></br>{`
`}{`Print run: 1,000`}<br parentName="p"></br>{`
`}{`Price: € 34.99`}<br parentName="p"></br>{`
`}{`ISBN: 978 94 93041 189`}<br parentName="p"></br>{`
`}{`Paper for interior: 130gsm Magno Satin (Sappi Europe), 90gsm Munken Pure Rough (Arctic Paper)`}<br parentName="p"></br>{`
`}{`Cover: 320gsm Parelgrijs Karton (Koninklijke Moorman Karton)`}<br parentName="p"></br>{`
`}{`Dust jacket: 120gsm Curious Metallics Cryogen White (Antalis)`}<br parentName="p"></br>{`
`}{`Binding style: Sewn softcover with front and back cover of four pages sewn in, the dust jacket is folded over the transparently glued-off spine.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      