import React, { Component } from "react"
import TWEEN from "@tweenjs/tween.js"

export default class Counter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      logData: null,
      count: 0,
    }

    this.tween = null
    this.timeoutId = 0
  }

  componentDidMount() {
    const url = `https://domg29rgj0qo4.cloudfront.net/log/requests.json`
    fetch(url, {
      mode: "cors",
    })
      .then(res => {
        return res.json()
      })
      .catch(err => {})
      .then(resJson => {
        this.setState(state => ({
          logData: resJson,
        }))

        // カウントアップ
        clearTimeout(this.timeoutId)
        this.timeoutId = setTimeout(() => {
          this.tween = new TWEEN.Tween({ count: 0 })
            .to({ count: resJson.total }, 1000)
            .easing(TWEEN.Easing.Exponential.InOut)
            .onUpdate(({ count }) => {
              this.setState(state => ({
                count: count,
              }))
            })
            .onComplete(({ count }) => {
              this.setState(state => ({
                count: count,
              }))
            })
            .start()
        }, 1000)

        return false
      })
  }

  componentWillUnmount() {
    if (this.tween) {
      this.tween.stop()
    }

    clearTimeout(this.timeoutId)
  }

  setDigit(num) {
    return ("00" + num).slice(-2)
  }

  render() {
    if (!this.state.logData) {
      return <></>
    }

    // Safari で NaN になってしまったのでハイフンをスラッシュに置き換え
    // yyyy-mm-dd を yyyy/mm/dd
    // https://qiita.com/pearmaster8293/items/b5b0df28147eb049f1ea
    const date = new Date(this.state.logData.update_time.replace(/-/g, "/"))
    const theYear = date.getFullYear()
    const theMonth = this.setDigit(date.getMonth() + 1)
    const theDate = this.setDigit(date.getDate())
    const theHour = this.setDigit(date.getHours())
    const theMinute = this.setDigit(date.getMinutes())

    if (this.props.lang === `ja`) {
      const update = `${theYear}年${theMonth}月${theDate}日${theHour}:${theMinute}`
      return (
        <>
          ご来場者数：推計{` `}
          {Math.floor(this.state.count).toLocaleString()} 人
          <br />
          <small>({update}時点)</small>
        </>
      )
    } else {
      return (
        <>
          Estimated number of visitors:{" "}
          {Math.floor(this.state.count).toLocaleString()}
          <br />
          <small>
            ({theYear}.{theMonth}.{theDate} {theHour}:{theMinute})
          </small>
        </>
      )
    }
  }
}
