import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`『Giacomo Joyce』は 1914 年にジェームス・ジョイスが執筆し、死後出版された短いテキストで、 ストィヒティング・デ・ロースによって復刊された。テキスト（翻訳はロベルト＝ヤン・ヘンケスとエーリク・ビンデルヴート、ビンデルヴートによる後書き、注釈、図解が加えられている）の後にはオリジナルの英語版の複製も掲載。合わせて追加されたジョイス自身の手書き原稿が、遊び心に満ちた「ドロステ効果」をもたらしている。本書は複製をできる限り原典に近づけることを目的としておらず、提示する原典と複製が相互作用を生んでいる。また、英語版をコピー機で複写することによって複製のプロセスを明らかにしている。そのパートは繊細な 60g/㎡ 紙に印刷されており、よりしっかりとした（それでいてさわり心地も良い）100g/㎡ の紙を用いた前半部分との魅力的なコントラストをなしている。表紙においては、作者名と恋する主人公の名を冠したタイトルに用いられたタイポグラフィが、グラフィカルな線の美しい相互作用を生み出している。カバーと仕立ての美しいスリップケースにおいて、異なる方向にハッチングされた 2 つの名前が重なる視覚効果は目を見張るものがあり、使われるインクが 1 色のみであることも効果的だ。スリップケースの効果については意見が分かれ、ある見開きはしかるべき完成度に達していないことも指摘しておく（P.44-46、愛する人の顔が綴じによって損なわれてしまっている）。形式と内容を知的かつ繊細に、説得力のある手法でまとめ上げたブックデザインの素晴らしさについては、満場一致で称賛された。`}</p>
    <hr></hr>
    <p>{`出版社： Stichting De Roos, Utrecht`}<br parentName="p"></br>{`
`}{`デザイン： Niels Schrader, Amsterdam`}</p>
    <p>{`カテゴリー：散文、詩、グラフィックノベル`}<br parentName="p"></br>{`
`}{`編集：Erik Bindervoet`}<br parentName="p"></br>{`
`}{`言語：オランダ語`}<br parentName="p"></br>{`
`}{`翻訳：Erik Bindervoet, Robbert-Jan Henkes`}<br parentName="p"></br>{`
`}{`イラスト：Erik Bindervoet`}<br parentName="p"></br>{`
`}{`リトグラフ：Zwaan Lenoir, Wormerveer`}<br parentName="p"></br>{`
`}{`印刷：Zwaan Lenoir, Wormerveer`}<br parentName="p"></br>{`
`}{`製本：Boekbinderij Van Waarden, Zaandam`}<br parentName="p"></br>{`
`}{`フォント：Founders Grotesk (Klim Type Foundry)`}<br parentName="p"></br>{`
`}{`サイズ（ミリ）：155 x 220 x 12`}<br parentName="p"></br>{`
`}{`ページ数：136`}<br parentName="p"></br>{`
`}{`発行部数：175`}<br parentName="p"></br>{`
`}{`価格：€ 90`}<br parentName="p"></br>{`
`}{`ISBN：N / a`}<br parentName="p"></br>{`
`}{`本文用紙：60gsm PlanoPak (Papyrus), 100gsm Lessebo Design 1.3 Ivory (Igepa)`}<br parentName="p"></br>{`
`}{`見返し：120gsm Pop’Set Ultra Red (Antalis)`}<br parentName="p"></br>{`
`}{`装丁：120gsm Pop’Set Ultra Red (Antalis) over 1.5 mm greyboard`}<br parentName="p"></br>{`
`}{`スリップケース：240gsm Pop’Set Ultra Red (Antalis)`}<br parentName="p"></br>{`
`}{`製本方法：糸かがり角背ハードカバー、薄い厚紙に最小限のチリ`}<br parentName="p"></br>{`
`}{`特記事項：黒テキスト一部はマゼンタでオーバプリント。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      