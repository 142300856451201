import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.en.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Another personal odyssey; another photographic research project; once more, dark, atmospheric images. This time, the melancholy and sombre feel are more intense. In Odsłonić, Maria Dabrowski travels to Ukraine with her mother in search of her family’s history. A story of displaced persons, war crimes and trauma, told in the first place through images – and even then, more as suggestion than documentation. The darkness infuses these images, often of people hardly visible in shadowy spaces, landscapes and interiors shot with little light. This continues in the choice of paper: slightly tinted, brown and almost black; the edges of the book block are likewise black. Those pictures in which hands, faces and interiors are barely distinguishable from the surrounding darkness in particular make the utmost demands on the lithographer and the printer; this utmost is also achieved, under the direction of the lithographer, the photographer and the designer. Herein lies much of the book’s strength. The Swiss cut-flush binding ensures that the book opens easily. The sparingly used typography is supportive and functional; for instance the map hidden behind the cover flap with references placing the photographs in geographical context. Misty, subtle, yet hugely expressive.`}</p>
    <hr></hr>
    <p>{`Publisher: Self-published`}<br parentName="p"></br>{`
`}{`Designer: Studio Another Day, Nijmegen`}</p>
    <p>{`Category: Art, photo and architecture books`}<br parentName="p"></br>{`
`}{`Editor: Maria Dabrowski`}<br parentName="p"></br>{`
`}{`Language: English`}<br parentName="p"></br>{`
`}{`Translator: Maja Ciumak`}<br parentName="p"></br>{`
`}{`Photographer: Maria Dabrowski`}<br parentName="p"></br>{`
`}{`Image editor: Maria Dabrowski, Yorick de Vries`}<br parentName="p"></br>{`
`}{`Paper advice: De Monsterkamer`}<br parentName="p"></br>{`
`}{`Producer: ArtLibro, Breda`}<br parentName="p"></br>{`
`}{`Lithographer: Cassochrome, Watergem (BE)`}<br parentName="p"></br>{`
`}{`Printer: Cassochrome, Watergem (BE)`}<br parentName="p"></br>{`
`}{`Binder: Boekbinderij Sepeli, Evergem (BE)`}<br parentName="p"></br>{`
`}{`Font: Euclid Circular B (Swiss Typefaces)`}<br parentName="p"></br>{`
`}{`Size in mm: 210 x 280 x 8`}<br parentName="p"></br>{`
`}{`Number of pages: 96`}<br parentName="p"></br>{`
`}{`Print run: 500`}<br parentName="p"></br>{`
`}{`Price: € 38`}<br parentName="p"></br>{`
`}{`ISBN: N / a`}<br parentName="p"></br>{`
`}{`Paper for interior: 90gsm EOS 2.0 (Igepa), 140gsm Woodstock Noce (Fedrigoni)`}<br parentName="p"></br>{`
`}{`Cover: 320gsm Pop’Set Hot Brown (Antalis)`}<br parentName="p"></br>{`
`}{`Binding style: Swiss-style cut-flush binding, front cover with flap, book block binding with light brown threads`}<br parentName="p"></br>{`
`}{`Special features: Printing in UV offset using a dense screen. The cover is printed in high density black and twice opaque white, the cover and spine have white foil stamping (Kurz Colorit 911) by Buromac, Brugge (BE). The fore-edge of the book block is printed in the same colour as the cover by Critis, Beernem (BE).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      