import React, { ChangeEvent, useEffect, useState } from "react"
import css from "./SearchExhibitors.module.scss"
import {
  loadExhibitors,
  Exhibitor,
  ExhibitorGenre,
  ExhibitorTag,
  ExhibitorCategory,
} from "../api"
import { FormattedMessage } from "react-intl"
import SearchExhibitorsList from "./SearchExhibitorsList"
import { useEvents } from "../hooks"

type SortKey = "name" | "country" | "category"

type SortType = {
  type: string
  name: string
  name_ja: string
  key: SortKey
}

type SearchExhibitorProps = {
  lang: string
}

const sortTypes: SortType[] = [
  {
    type: "alphabetical",
    name: "Alphabetical order",
    name_ja: "ブース名順",
    key: "name",
  },
  { type: "country", name: "Country", name_ja: "国別", key: "country" },
  {
    type: "category",
    name: "Category",
    name_ja: "カテゴリー順",
    key: "category",
  },
]

// オブジェクトをディープコピーする
function clone<T>(x: T): T {
  return JSON.parse(JSON.stringify(x))
}

function SearchExhibitors(prop: SearchExhibitorProps) {
  const lang: string = prop.lang ? prop.lang : "en"
  const [isModalOpen, setIsModalOpen] = useState(false)

  // JSONからロードした出店者リスト
  const [exhibitors, setExhibitors] = useState<Exhibitor[]>([])

  // フィルターされた出店者リスト
  const [visibleExhibitors, setVisibleExhibitors] = useState<Exhibitor[]>([])

  // 出店者リストから抽出したジャンルのリスト
  const [genres, setGenres] = useState<ExhibitorGenre[]>([])

  // 出店者リストから抽出したタグのリスト
  const [tags, setTags] = useState<ExhibitorTag[]>([])

  // 出店者リストから抽出したジャンルのリスト
  const [categories, setCategories] = useState<ExhibitorCategory[]>([])

  // フィルター条件
  const [filters, setFilters] = useState<string[]>([])

  // ソート方法
  const [sortKey, setSortKey] = useState<SortKey>("name")

  // お気に入りモードかどうか
  const [isFavoriteMode, setIsFavoriteMode] = useState(false)

  const events = useEvents()

  // ソート条件を変更する
  const onChangeSort = (e: any) => {
    const sortType = sortTypes.find(s => s.type === e.currentTarget.value)
    if (!sortType) {
      return
    }
    setSortKey(sortType.key)
  }

  // フィルター条件を変更する
  const onChangeFilters = (e: ChangeEvent<HTMLFormElement>) => {
    // チェックされたoptionのvalueのリストを作成
    const options = Array.from(e.currentTarget.getElementsByTagName(`input`))
    const result = options.filter(o => o.checked).map(o => o.value)
    setFilters(result)
  }

  // お気に入りモードのトグル
  const toggleFavoriteMode = () => {
    setIsFavoriteMode(!isFavoriteMode)
  }

  // モーダルを閉じる
  const cancelFilter = () => {
    setIsModalOpen(false)
  }

  // 結果を 3D レイヤにわたす
  const applyFilter = () => {
    events.emit(
      "filterBooths",
      visibleExhibitors.map(e => e.exhibitorId)
    )
    setIsModalOpen(false)
  }

  // コンポーネントのマウント時に出店者リストをロードする
  useEffect(() => {
    loadExhibitors().then(_exhibitors => {
      const exhibitors = clone(_exhibitors)
      setExhibitors(exhibitors)

      // ジャンルのリストを抽出する
      let extractedGenres = new Map<string, ExhibitorGenre>()
      for (const exhibitor of exhibitors) {
        for (const genre of exhibitor.genre) {
          extractedGenres.set(genre.id, genre)
        }
      }
      setGenres(Array.from(extractedGenres.values()))

      // タグのリストを抽出する
      const extractedTags = new Map<string, ExhibitorTag>()
      for (const exhibitor of exhibitors) {
        for (const tag of exhibitor.tag) {
          extractedTags.set(tag.id, tag)
        }
      }

      let tagResult = Array.from(extractedTags.values())
      tagResult = tagResult.sort((a, b) => {
        if (a.name > b.name) {
          return 1
        } else {
          return -1
        }
      })
      setTags(tagResult)

      // カテゴリーのリスト抽出
      const extractedCategories = new Map<string, ExhibitorCategory>()
      for (const exhibitor of exhibitors) {
        extractedCategories.set(exhibitor.category.id, exhibitor.category)
      }

      setCategories(Array.from(extractedCategories.values()))
    })
  }, [])

  // 出店者リスト、フィルター条件およびソート方法が変化したら、表示する出店者リストを更新する
  useEffect(() => {
    // お気に入りIDのハッシュを作成
    const isFavored: Record<string, boolean> = {}
    if (isFavoriteMode) {
      const favoriteStr = localStorage.getItem("favorite") // ローカルストレージ favorite取得
      let favorites = favoriteStr ? JSON.parse(favoriteStr) : []
      for (const fav of favorites) {
        isFavored[fav] = true
      }
    }

    // フィルターする
    if (filters.length === 0 && !isFavoriteMode) {
      setVisibleExhibitors(exhibitors)
      return
    }

    let result: Exhibitor[] = []
    for (const exhibitor of exhibitors) {
      if (isFavoriteMode) {
        if (!isFavored[exhibitor.exhibitorId]) {
          continue
        }
      }

      // 選択中のジャンルをすべて持っているブースのみ表示
      let hit = true
      for (const filter of filters) {
        let hasFilterItem = false
        for (const genre of exhibitor.genre) {
          if (genre.name_en === filter) {
            hasFilterItem = true
            break
          }
        }
        for (const tag of exhibitor.tag) {
          if (tag.name === filter) {
            hasFilterItem = true
            break
          }
        }
        if (!hasFilterItem) {
          hit = false
        }
      }
      if (hit) {
        result.push(exhibitor)
      }
    }

    setVisibleExhibitors(result)
  }, [exhibitors, filters, sortKey, isFavoriteMode])

  const classFavoriteMode = isFavoriteMode ? css.favoMode : ""

  // レンダリング
  return (
    <div>
      <button
        onClick={() => {
          setIsModalOpen(true)
          setFilters([])
          setSortKey(`name`)
        }}
        className={css.btn__search + ` btn__icon`}
      >
        <FormattedMessage id="searchBooths" />
      </button>
      {isModalOpen && (
        <div className={css.content} data-motion="up-modal">
          <div data-motion="up-inner-1">
            <button onClick={cancelFilter} className={css.content__close}>
              <FormattedMessage id="cancel" />
            </button>

            <header className={css.content__header}>
              <h2 className={css.content__header__title}>SEARCH BOOTHES</h2>
              <button
                className={classFavoriteMode + ` ` + css.content__header__fav}
                onClick={() => {
                  toggleFavoriteMode()
                }}
              >
                <FormattedMessage id="showFavoriteBooths" />
              </button>
            </header>

            <nav className={css.content__navi}>
              <div className={css.content__sort}>
                {/* ソート種別を選択するselect要素 */}
                {lang === `ja` ? (
                  <select name="sort" id="sort" onChange={onChangeSort}>
                    {sortTypes.map(sortType => (
                      <option key={sortType.type} value={sortType.type}>
                        {sortType.name_ja}
                      </option>
                    ))}
                  </select>
                ) : (
                  <select name="sort" id="sort" onChange={onChangeSort}>
                    {sortTypes.map(sortType => (
                      <option key={sortType.type} value={sortType.type}>
                        {sortType.name}
                      </option>
                    ))}
                  </select>
                )}
              </div>

              {/* フィルター用チェックボックスを描画 */}
              <div className={css.content__filter}>
                {lang === `ja` ? (
                  <label
                    className={css.content__filter__trigger}
                    htmlFor="filterTrigger"
                  >
                    絞り込み
                  </label>
                ) : (
                  <label
                    className={css.content__filter__trigger}
                    htmlFor="filterTrigger"
                  >
                    Filter
                  </label>
                )}
                <input
                  type="checkbox"
                  className={css.content__filter__check}
                  id="filterTrigger"
                />
                <div className={css.content__filter__body}>
                  <form onChange={onChangeFilters}>
                    {genres.map(genre => (
                      <label key={`g-${genre.id}`}>
                        <input
                          type="checkbox"
                          name="filter"
                          value={genre.name_en}
                        />
                        <span>{genre.name_en.toUpperCase()}</span>
                      </label>
                    ))}
                    {tags.map(tag => (
                      <label key={`t-${tag.id}`}>
                        <input type="checkbox" name="filter" value={tag.name} />
                        <span>{tag.name.toUpperCase()}</span>
                      </label>
                    ))}
                  </form>
                </div>
              </div>
            </nav>

            {/* フィルターされた出店者を描画 */}
            <div className={css.content__list}>
              <SearchExhibitorsList
                exhibitors={visibleExhibitors}
                sortKey={sortKey}
                lang={lang}
                doModalClose={() => {
                  setIsModalOpen(false)
                }}
              />
            </div>

            <nav className={css.content__btns}>
              <button
                onClick={applyFilter}
                className={css.content__btns__apply}
              >
                <FormattedMessage id="apply" />
              </button>
              <button
                onClick={cancelFilter}
                className={css.content__btns__cancel}
              >
                <FormattedMessage id="cancel" />
              </button>
            </nav>
          </div>
        </div>
      )}
    </div>
  )
}

export default SearchExhibitors
