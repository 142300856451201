import React from "react"
import Layout from "../../../../layouts/en"
import BdbdMenu from "../../../../components/BdbdMenu.en"
import css from "../index.module.scss"

const IndexPage = props => {
  return (
    <Layout location={props.location} title={`Best Dutch Book Exhibition`}>
      <section className={css.bdbd__talk + ` container`}>
        <div className={css.bdbd__content + ` content`}>
          <div className={css.bdbd__head + ` content__head`}>
            <h1 className={`haeding__lev1`}>
              BEST DUTCH <br className={`only-sp`} />
              BOOK DESIGNS
            </h1>
          </div>

          <nav className={css.bdbd__localnavi}>
            <BdbdMenu />
          </nav>

          <article className={`content__2col`}>
            <div className={`content__2col__side`}>
              <div className={`content__title`}>
                <h1 className={`haeding__lev2`}>Talk Event</h1>
              </div>
            </div>

            <div className={`editor__content content__2col__main`}>
              <section className={css.bdbd__section}>
                <h2>
                  The Best Dutch Book Designs – Thinking About Dutch Book Design
                </h2>
                <p>
                  Date/time: November 20, 16:30-18:00 JST
                  <br />
                  <br />
                  Guests at this talk session will be BDBD representative Esther
                  Scholten, selection committee chairperson Eelco van Welie
                  (director of NAI010 Publishers), and Thomas Castro (curator
                  for the Graphic Design section of Stedelijk Museum Amsterdam).
                  The panel will discuss topics including the history of book
                  design in the Netherlands and what aspects they pay the most
                  attention to when judging a book. The role of moderator will
                  be filled by Kiyonori Muroga, editor-in-chief to website “The
                  Graphic Design Review.”
                </p>
              </section>
            </div>
          </article>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
