import {
  TextureLoader,
  Mesh,
  RawShaderMaterial,
  Font,
  DoubleSide,
  Material,
  PlaneBufferGeometry,
  MeshBasicMaterial,
  Vector3,
  Texture,
} from "three"
import { createTextGeometry } from "./vendor/three-bmfont-text"
const loadFont = require("load-bmfont")
import { createMSDFShader } from "./vendor/three-bmfont-text/shaders/msdf"

type CustomTextGeometry = ReturnType<typeof createTextGeometry>

type MaterialId = "Booth" | "Foyer" | "BoothMouseOver" | "Counter"

type TextMeshMaterials = Readonly<{
  textMaterial: RawShaderMaterial
  underlineMaterial: MeshBasicMaterial
}>

type TextMeshOptions = Readonly<{
  color?: number
  width?: number
}>

export class TextMesh {
  static materials: Partial<Record<MaterialId, Promise<TextMeshMaterials>>> = {}
  static fontPngCache: Promise<Texture> | undefined
  static fontJsonCache: Promise<unknown> | undefined
  static isWebGL2: boolean = false

  private constructor(
    public mesh: Mesh,
    private underline: Mesh,
    private textGeometry: CustomTextGeometry
  ) {}

  static preload() {
    TextMesh.getOrLoadFont(`/fonts/UniversNext_Hiragino.json`)
    TextMesh.fontPngCache = new TextureLoader().loadAsync(
      "/fonts/UniversNext_Hiragino.png"
    )
  }

  static async init(
    text: string,
    materialId: MaterialId,
    opts?: TextMeshOptions
  ): Promise<TextMesh> {
    const color = opts?.color ?? 0x222222
    const width = opts?.width ?? 1280

    const font = await TextMesh.getOrLoadFont(
      `/fonts/UniversNext_Hiragino.json`
    )
    const geometry = createTextGeometry({
      width,
      align: "left",
      font: font,
    })
    geometry.update(text)

    const materials = await TextMesh.getOrCreateMaterial(materialId, color)

    const mesh = new Mesh(geometry as any, materials.textMaterial)

    const bbSize = new Vector3()
    geometry.computeBoundingBox()
    geometry.boundingBox!.getSize(bbSize)

    const underline = new Mesh(
      new PlaneBufferGeometry(1, 8, 2, 2),
      materials.underlineMaterial
    )
    underline.name = "Underline"
    underline.position.setX(bbSize.x / 2 + 5)
    underline.position.setY(16)
    underline.geometry.scale(bbSize.x, 1, 1)
    mesh.add(underline)

    return new TextMesh(mesh, underline, geometry)
  }

  static getOrLoadFont = (path: string): Promise<unknown> => {
    if (!TextMesh.fontJsonCache) {
      TextMesh.fontJsonCache = new Promise((resolve, reject) => {
        loadFont(path, function (err: Error, font: Font) {
          if (err) {
            return reject(err)
          }
          resolve(font)
        })
      })
    }

    return TextMesh.fontJsonCache
  }

  static async getOrCreateMaterial(
    materialId: MaterialId,
    color: number
  ): Promise<TextMeshMaterials> {
    let mat = TextMesh.materials[materialId]
    if (!mat) {
      mat = TextMesh.createMaterial(color)
      TextMesh.materials[materialId] = mat
    }
    return mat
  }

  private static async createMaterial(color: number) {
    if (!TextMesh.fontPngCache) {
      TextMesh.fontPngCache = new TextureLoader().loadAsync(
        "/fonts/UniversNext_Hiragino.png"
      )
    }
    const texture = await TextMesh.fontPngCache
    const textMaterial = new RawShaderMaterial(
      createMSDFShader(TextMesh.isWebGL2, {
        map: texture,
        transparent: true,
        color: color,
        side: DoubleSide,
        negate: false,
      })
    )

    const underlineMaterial = new MeshBasicMaterial({
      color: color,
      transparent: true,
      side: DoubleSide,
      fog: false,
    })

    return { textMaterial, underlineMaterial }
  }

  static async fade(materialId: MaterialId, show: boolean): Promise<void> {
    const mat = await TextMesh.materials[materialId]
    if (mat && !mat.textMaterial.uniformsNeedUpdate) {
      const { textMaterial, underlineMaterial } = mat
      const opacity =
        textMaterial.uniforms["opacity"].value + (show ? 0.03 : -0.03)

      textMaterial.uniforms["opacity"].value = Math.min(Math.max(opacity, 0), 1)
      textMaterial.uniformsNeedUpdate = true

      underlineMaterial.opacity = Math.min(Math.max(opacity, 0), 1)
      underlineMaterial.needsUpdate = true
    }
  }

  static setIsWebGL2(value: boolean): void {
    TextMesh.isWebGL2 = value
  }

  updateText(text: string): void {
    this.textGeometry.update(text)

    const bbSize = new Vector3()
    this.textGeometry.computeBoundingBox()
    this.textGeometry.boundingBox!.getSize(bbSize)

    this.underline.geometry.scale(bbSize.x, 1, 1)
    this.underline.position.setX(bbSize.x / 2 + 5)
    this.underline.position.setY(16)
  }

  async changeMaterial(materialId: MaterialId, color: number): Promise<void> {
    const mat = await TextMesh.getOrCreateMaterial(materialId, color)
    this.mesh.material = mat.textMaterial
    this.underline.material = mat.underlineMaterial
  }
}
