import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`巨大なフォントがレイアウトされた作品集。装丁はシンプルで、中ページで用いられたフェルトのように繊細な紙を表紙にも用いて、印刷はすべて黒のみ、図版は手書きの文字だけ、綴じも 2 カ所ホチキスで止められているだけ。それが、本書にほどよくアクティビストらしい印象を与えている。しかしこの本は、豊かさ、ニュアンスやエレガンス、そして説得力を最小の材料で生み出すことが可能であることを証明している。ホチキスの芯が黒であることも繊細に上品さを演出しており、斜めに印字されたタイポグラフィーが印字されたカバーは、裏側に同じく斜めに印刷されたテキストがうっすらと浮かび上がり、興味深いデザインの効果を生み出している。白い紙（オフホワイト）に黒で印刷する手法が、どこにでもありそうな初歩的手法ではなく、新たな新鮮さを生み出している。1 冊の中に 2 種類の言語を含むことについての解決法として、それぞれに本の半分が与えられ、呼応するようにページ冒頭からノドにかけて 180 度回転している両面表紙のデザインは決して目新しいものではないが、本書はとても自然にその手法を用い、カバーの交わるようなタイポグラフィを中ページでも展開させている。テキスト、グラフィックデザイン、そして手書きのタイポグラフィの三者がそれぞれに書籍全体に寄与していることは明確で、多層的な一体感を強め、このささやかな本を凡庸な一般書籍から頭一つ抜きんでた存在にしている。`}</p>
    <hr></hr>
    <p>{`出版社： Jan van Eyck Academie, Maastricht`}<br parentName="p"></br>{`
`}{`デザイン： Karoline Swiezynski, Amsterdam`}</p>
    <p>{`カテゴリー：散文、詩、グラフィックノベル`}<br parentName="p"></br>{`
`}{`編集：Jan van Eyck Academie, Maastricht`}<br parentName="p"></br>{`
`}{`言語：オランダ語、英語`}<br parentName="p"></br>{`
`}{`翻訳：David Colmer`}<br parentName="p"></br>{`
`}{`Illustrator: Sooji Lee`}<br parentName="p"></br>{`
`}{`編集：Jan van Eyck Academie, Maastricht`}<br parentName="p"></br>{`
`}{`印刷：Charles Nypels Lab, Jan van Eyck Academie, Maastricht`}<br parentName="p"></br>{`
`}{`製本：Charles Nypels Lab, Jan van Eyck Academie, Maastricht`}<br parentName="p"></br>{`
`}{`フォント：Bodoni (Sooji Lee), Times (Linotype)`}<br parentName="p"></br>{`
`}{`サイズ（ミリ）：190 x 270 x 3`}<br parentName="p"></br>{`
`}{`ページ数：36`}<br parentName="p"></br>{`
`}{`発行部数：150`}<br parentName="p"></br>{`
`}{`価格：€ 15`}<br parentName="p"></br>{`
`}{`ISBN：978 90 72076 97 7`}<br parentName="p"></br>{`
`}{`本文用紙：90gsm EOS 2.0 (Igepa)`}<br parentName="p"></br>{`
`}{`カバー：90gsm EOS 2.0 (Igepa)`}<br parentName="p"></br>{`
`}{`製本方法：黒針金中綴じ、小口未断裁`}<br parentName="p"></br>{`
`}{`特記事項：リソグラフ。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      