import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/DefaultPage.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`DEEP L`}</h3>
    <p>
  <a href="https://www.deepl.com/translator" target="_blank" className="link__blank">
    https://www.deepl.com/translator
  </a>
  <br />
  万能のAI翻訳サイト
    </p>
    <h3>{`Planet Express`}</h3>
    <p>
  <a href="https://planetexpress.com/" target="_blank" className="link__blank">
    https://planetexpress.com/
  </a>
  <br />
  個人輸入サービス、複数の発送元からの荷物をイギリス・アメリカの倉庫に集約して発送できるので、配送費を節約できます。
    </p>
    <h3>{`Tenso.com`}</h3>
    <p>
  <a href="https://www.tenso.com/jp" target="_blank" className="link__blank">
    https://www.tenso.com/jp
  </a>
  <br />
  日本から海外への転送サービス。国外発送不可能な荷物も海外へ転送してくれます。複数の発送元からの荷物もまとめて転送できます。
    </p>
    <h3>{`時差計算`}</h3>
    <p>
  <a href="https://www.jisakeisan.com/" target="_blank" className="link__blank">
    https://www.jisakeisan.com/
  </a>
    </p>
    <h3>{`Paypal`}</h3>
    <p>
  <a href="https://www.paypal.com/jp/home" target="_blank" className="link__blank">
    https://www.paypal.com/jp/home
  </a>
  <br />
  オンライン決済サービスです。
    </p>
    <h3>{`Transfer Wise`}</h3>
    <p>
  <a href="https://transferwise.com/jp" target="_blank" className="link__blank">
    https://transferwise.com/jp
  </a>
  <br />
  海外への銀行間送金サービスです。
  <br />
  　
    </p>
    <h1>{`国際配送お役立ちリンク`}</h1>
    <h3>{`郵便局`}</h3>
    <p>
  <a href="https://www.post.japanpost.jp/int/information/overview.html" target="_blank" className="link__blank">
    https://www.post.japanpost.jp/int/information/overview.html
  </a>
    </p>
    <h3>{`Fedex`}</h3>
    <p>
  <a href="https://www.fedex.com/ja-jp/home.html" target="_blank" className="link__blank">
    https://www.fedex.com/ja-jp/home.html
  </a>
  <br />
  <a href="https://www.fedex.com/ja-jp/coronavirus.html?INTCMP=BAL-1005905-5-5-962-1000000-APAC-JP-JA-FY20MAYCOVIDHBN" target="_blank" className="link__blank">
    https://www.fedex.com/ja-jp/coronavirus.html?INTCMP=BAL-1005905-5-5-962-1000000-APAC-JP-JA-FY20MAYCOVIDHBN
  </a>
  <br />
  <a href="https://www.fedex.com/content/dam/fedex/apac-asia-pacific/downloads/fedex-covid-service-update-ja-jp.pdf" target="_blank" className="link__blank">
    https://www.fedex.com/content/dam/fedex/apac-asia-pacific/downloads/fedex-covid-service-update-ja-jp.pdf
  </a>
    </p>
    <h3>{`DHL`}</h3>
    <p>
  <a href="https://www.dhl.co.jp/exp-ja/express.html" target="_blank" className="link__blank">
    https://www.dhl.co.jp/exp-ja/express.html
  </a>
  <br />
  <a href="https://www.dhl.co.jp/exp-ja/important_information/covid_19.html" target="_blank" className="link__blank">
    https://www.dhl.co.jp/exp-ja/important_information/covid_19.html
  </a>
  <br />
  ご指定の地域が配送可能かご確認されたい場合は、フリーダイヤルにてお問い合わせください:
  0120-392-580
    </p>
    <p>{`※各社状況により対応が逐一変更しておりますので、各社のアナウンスをご確認ください。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      