import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/VabfEvents.en.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SocialShare = makeShortcode("SocialShare");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className="youtube__wrap">
  <iframe width="560" height="315" src="https://www.youtube.com/embed/B8NkD36xq1Q?controls=0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
    <div className="share__2col">
  <div className="share__2col__link">
    <a href="https://youtu.be/B8NkD36xq1Q" target="_blank">
      Youtube Live をみる
    </a>
  </div>
  <div className="share__2col__icon">
    <SocialShare title={props.pageContext.frontmatter.title} mdxType="SocialShare" />
  </div>
    </div>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "980px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "78.36734693877551%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAQABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAgX/xAAVAQEBAAAAAAAAAAAAAAAAAAADAv/aAAwDAQACEAMQAAABrZOoE7JgWP/EABoQAQEBAAMBAAAAAAAAAAAAAAEDAgAREzL/2gAIAQEAAQUCo09JbTg9ltZKGhZ/H//EABgRAAMBAQAAAAAAAAAAAAAAAAABMQID/9oACAEDAQE/AVzxR0//xAAXEQADAQAAAAAAAAAAAAAAAAAAAQIx/9oACAECAQE/AXVCw//EABoQAQACAwEAAAAAAAAAAAAAAAEAEQIQIRL/2gAIAQEABj8Cazoj6V1kLONzs//EABkQAQEBAQEBAAAAAAAAAAAAAAERACExQf/aAAgBAQABPyGDb4GDDN5u0aIB0e9PhdYDl3//2gAMAwEAAgADAAAAEO8v/8QAGBEBAQADAAAAAAAAAAAAAAAAAQAhMXH/2gAIAQMBAT8Q3LnsQQX/xAAYEQEAAwEAAAAAAAAAAAAAAAABABExsf/aAAgBAgEBPxC+hyXQuz//xAAcEAEAAgIDAQAAAAAAAAAAAAABABEhMUFRYZH/2gAIAQEAAT8QzUUo0YJttIeCoZEg9y0/Jfwh1iygnyqgPHLY7n//2Q==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/1ab13f41077387e6c95cab1809a6500d/1a3b7/img_1.webp 245w", "/static/1ab13f41077387e6c95cab1809a6500d/fb835/img_1.webp 490w", "/static/1ab13f41077387e6c95cab1809a6500d/096d3/img_1.webp 980w", "/static/1ab13f41077387e6c95cab1809a6500d/3dcc7/img_1.webp 1470w", "/static/1ab13f41077387e6c95cab1809a6500d/061f9/img_1.webp 1960w", "/static/1ab13f41077387e6c95cab1809a6500d/498ae/img_1.webp 2889w"],
          "sizes": "(max-width: 980px) 100vw, 980px",
          "type": "image/webp"
        }}></source>{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/1ab13f41077387e6c95cab1809a6500d/c168b/img_1.jpg 245w", "/static/1ab13f41077387e6c95cab1809a6500d/bb66a/img_1.jpg 490w", "/static/1ab13f41077387e6c95cab1809a6500d/2f919/img_1.jpg 980w", "/static/1ab13f41077387e6c95cab1809a6500d/a7235/img_1.jpg 1470w", "/static/1ab13f41077387e6c95cab1809a6500d/331e6/img_1.jpg 1960w", "/static/1ab13f41077387e6c95cab1809a6500d/4dad0/img_1.jpg 2889w"],
          "sizes": "(max-width: 980px) 100vw, 980px",
          "type": "image/jpeg"
        }}></source>{`
        `}<img parentName="picture" {...{
          "className": "gatsby-resp-image-image",
          "src": "/static/1ab13f41077387e6c95cab1809a6500d/2f919/img_1.jpg",
          "alt": "img 1",
          "title": "img 1",
          "loading": "lazy",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          }
        }}></img>{`
      `}</picture>{`
    `}</span>
    <p>{`河内タカの新刊『芸術家たち ミッドセンチュリーの偉人 編』は、戦後アメリカの黄金期とも謳われる 1950 年代から 60 年代の偉人たちにフォーカスし、「家具・プロダクトデザイン」「建築」「アート・グラフィックデザイン」の 3 章にわたって総勢 27 人の建築家やデザイナーたちを親しみやすく解説した本です。シリーズ第一弾として出版された『芸術家たち 建築とデザインの巨匠 編』や「andpremium.jp」の連載でもコラボレーションをしている SANDER STUDIO とともに、新刊にまつわる軽快なトークを行います。`}</p>
    <figure {...{
      "className": "content__img__small"
    }}>{`
  `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "980px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "100%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQADAAAAAAAAAAAAAAAAAAMCBAX/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAbyx0cvQRDGogV//xAAeEAACAQMFAAAAAAAAAAAAAAABAgADBBESEyEiI//aAAgBAQABBQLdVA9ypmVlwFWjjU3oI/aq4iHj/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAIBAAAQQABwEAAAAAAAAAAAAAAQACEUEQISIxMlFxYf/aAAgBAQAGPwKa6QAvdcSV9pMBgB2pQ05eIzSb5h//xAAcEAEAAgMBAQEAAAAAAAAAAAABABEhQWExcfH/2gAIAQEAAT8hdJ6bizqp+Jdl6mXwrMhVFCyG4nq+rTG+MAgiviM56an/2gAMAwEAAgADAAAAENgYvP/EABYRAAMAAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPxAr/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAgEBPxAx3//EAB4QAQACAwACAwAAAAAAAAAAAAEAESExQWHRUXGB/9oACAEBAAE/EHgujawcB6gJN42o8+lyypVuozN2RLZtHP5GnACxxfRKIAGyV95HRRx/QX8QrmQA4HiUlXYHwT//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/17887ca5d632fa5019b22af5e37a895e/1a3b7/img_2.webp 245w", "/static/17887ca5d632fa5019b22af5e37a895e/fb835/img_2.webp 490w", "/static/17887ca5d632fa5019b22af5e37a895e/096d3/img_2.webp 980w", "/static/17887ca5d632fa5019b22af5e37a895e/3dcc7/img_2.webp 1470w", "/static/17887ca5d632fa5019b22af5e37a895e/061f9/img_2.webp 1960w", "/static/17887ca5d632fa5019b22af5e37a895e/40d94/img_2.webp 2860w"],
            "sizes": "(max-width: 980px) 100vw, 980px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/17887ca5d632fa5019b22af5e37a895e/c168b/img_2.jpg 245w", "/static/17887ca5d632fa5019b22af5e37a895e/bb66a/img_2.jpg 490w", "/static/17887ca5d632fa5019b22af5e37a895e/2f919/img_2.jpg 980w", "/static/17887ca5d632fa5019b22af5e37a895e/a7235/img_2.jpg 1470w", "/static/17887ca5d632fa5019b22af5e37a895e/331e6/img_2.jpg 1960w", "/static/17887ca5d632fa5019b22af5e37a895e/407db/img_2.jpg 2860w"],
            "sizes": "(max-width: 980px) 100vw, 980px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/17887ca5d632fa5019b22af5e37a895e/2f919/img_2.jpg",
            "alt": "img 2",
            "title": "img 2",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>
    </figure>
    <div className="content__noimginfo">
  <div className="content__noimginfo__title">
    <p>
      河内タカ <br className="only-sp" />
      <span className="font__xs">(Taka Kawachi)</span>
    </p>
  </div>
  <div className="content__noimginfo__text">
    <p>
      高校卒業後、サンフランシスコのアートカレッジへ留学。卒業後ニューヨークに拠点を移し、アートや写真に関する展覧会のキュレーションや写真集の編集を数多く手がけ2011
      年に帰国。2016年に自身の体験を通したアートや写真のことを綴った著書『アートの入り口
      アメリカ編』、『ヨーロッパ編』(ともに太田出版)を刊行。現在は京都に本社を置く便利堂の海外事業部を統括し、写真印刷技法であるコロタイプを国際的に広める活動を行いながら、ライターとして『&Premium』(マガジンハウス)などへ執筆を行っている。
    </p>
  </div>
    </div>
    <div className="content__noimginfo">
  <div className="content__noimginfo__title">
    <p>
      SANDER STUDIO <br className="only-sp" />
      <span className="font__xs">(サンダースタジオ)</span>
    </p>
  </div>
  <div className="content__noimginfo__text">
    <p>
      文化服装学院卒業。“クスッと笑える”
      イラストレーション、アニメーションを中心に、出版、広告、アパレル、雑貨など幅広い分野で活躍。
    </p>
  </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      