import {
  Scene,
  Mesh,
  Vector3,
  CircleBufferGeometry,
  MeshBasicMaterial,
  Camera,
  SphereBufferGeometry,
  Box3,
  Sphere,
  PlaneBufferGeometry,
  BufferAttribute,
  BufferGeometry,
  Texture,
} from "three"
import { GameState, SceneObject } from "../../types"
import { TextMesh } from "../../TextMesh"
import TWEEN from "@tweenjs/tween.js"
import { Exhibitor } from "../../../api"
import { UNIT } from "./constants"
import {
  NormalComposition,
  MultiComposition,
  Composition,
} from "./Compositions"
import { isDebugMultiBooth } from "../../../utils/isDebug"
import { decodeHTMLEntities } from "../../../utils/decodeHTMLEntities"

const CIRCLE_GEOMETRY = new CircleBufferGeometry(0.4, 16)
const SPHERE_GEOMETRY = new SphereBufferGeometry(1)
const PROFILE_CLICKER_GEOMETRY = new PlaneBufferGeometry(1, 1)

const INVISIBLE_MATERIAL = new MeshBasicMaterial()
INVISIBLE_MATERIAL.visible = false

function getBoundingSphereOfMesh(mesh: Mesh): Sphere {
  const box = new Box3()
  box.setFromObject(mesh)
  const s = new Sphere()
  box.getBoundingSphere(s)
  return s
}

function createQuad(
  geometry: BufferGeometry,
  size: number,
  layout: [number, number, number, number]
): void {
  const s = size
  const [x, y, w, h] = layout
  const vertices = [
    { pos: [-s, -s, s], norm: [0, 0, 1], uv: [x, y] },
    { pos: [s, -s, s], norm: [0, 0, 1], uv: [x + w, y] },
    { pos: [-s, s, s], norm: [0, 0, 1], uv: [x, y + h] },
    { pos: [s, s, s], norm: [0, 0, 1], uv: [x + w, y + h] },
  ]

  const numVertices = vertices.length
  const positions = new Float32Array(numVertices * 3)
  const normals = new Float32Array(numVertices * 3)
  const uvs = new Float32Array(numVertices * 2)

  let posNdx = 0
  let nrmNdx = 0
  let uvNdx = 0
  for (const vertex of vertices) {
    positions.set(vertex.pos, posNdx)
    normals.set(vertex.norm, nrmNdx)
    uvs.set(vertex.uv, uvNdx)
    posNdx += 3
    nrmNdx += 3
    uvNdx += 2
  }

  geometry.setAttribute("position", new BufferAttribute(positions, 3))
  geometry.setAttribute("normal", new BufferAttribute(normals, 3))
  geometry.setAttribute("uv", new BufferAttribute(uvs, 2))

  geometry.setIndex([0, 1, 2, 2, 1, 3])
}

export class Booth implements SceneObject {
  static repository: Record<string, Booth> = {}

  static findById(id: string): Booth | undefined {
    return Booth.repository[id]
  }

  private constructor(
    public readonly id: string,
    public readonly exhibitor: Exhibitor,
    public readonly mesh: Mesh,
    public readonly compo: Composition,
    private children: { [name: string]: Mesh },
    public readonly name: TextMesh
  ) {
    Booth.repository[id] = this
  }

  static async init(
    scene: Scene,
    exhibitor: Exhibitor,
    iconTexture: Texture,
    wallTextures: Texture[],
    tableTextures: Texture[],
    lang: "ja" | "en"
  ): Promise<Booth> {
    const root = new Mesh()
    const name = `Booth ${exhibitor.exhibitorId}`

    const compo =
      exhibitor.booth_type === "multi"
        ? await MultiComposition.init(
            name,
            tableTextures,
            wallTextures,
            exhibitor.exhibitorId
          )
        : await NormalComposition.init(
            name,
            tableTextures,
            wallTextures,
            exhibitor.composition || "A"
          )
    compo.mesh.traverse(c => {
      c.name = name
    })
    root.add(compo.mesh)

    // compo.meshの位置を計算
    const bbox = new Box3()
    bbox.setFromObject(compo.mesh)
    const baseX = bbox.max.x

    const profile = new Mesh()
    profile.name = "Profile"
    profile.position.x += baseX + 0.4
    profile.position.y += UNIT * 10 + 0.3
    profile.position.add(compo.profileOffset)

    // Icon
    const iconGeometry = CIRCLE_GEOMETRY
    const iconMaterial = new MeshBasicMaterial({
      color: 0xffffff,
      transparent: true,
      map: iconTexture,
    })
    const icon = new Mesh(iconGeometry, iconMaterial)
    icon.scale.set(0.8, 0.8, 0.8)
    icon.name = name
    profile.add(icon)

    // Name
    const boothName = decodeHTMLEntities(
      lang === "ja" ? exhibitor.name : exhibitor.nameEn
    )
    const nameText = isDebugMultiBooth()
      ? `${exhibitor.exhibitorId}: ${boothName}` // デバッグモードではIDを表示する
      : boothName
    const nameLabel = await TextMesh.init(nameText, "Booth", { width: 640 })
    nameLabel.mesh.name = name
    nameLabel.mesh.scale.set(0.005, 0.005, 0.005)
    nameLabel.mesh.position.add(new Vector3(0.5, -0.1, 0))
    nameLabel.mesh.rotateX(Math.PI)
    profile.add(nameLabel.mesh)

    // 名前クリック用オブジェクト
    const profileClicker = new Mesh(PROFILE_CLICKER_GEOMETRY)
    profileClicker.visible = false
    const profileBox = new Box3()
    profileBox.setFromObject(profile)

    const profileInfo = new Vector3()
    profileBox.getCenter(profileInfo)
    profileClicker.position.add(profileInfo).sub(profile.position)

    profileBox.getSize(profileInfo)
    profileClicker.scale.set(profileInfo.x, profileInfo.y, profileInfo.z)
    profileClicker.name = name
    profile.add(profileClicker)

    root.add(profile)

    // クリックしやすいように見えないオブジェクトを置いておく
    const s = getBoundingSphereOfMesh(compo.mesh)
    const dummyClicker = new Mesh(SPHERE_GEOMETRY)
    dummyClicker.name = name
    dummyClicker.visible = false
    dummyClicker.position.set(s.center.x, s.center.y, s.center.z)
    root.add(dummyClicker)

    return new Booth(
      exhibitor.exhibitorId,
      exhibitor,
      root,
      compo,
      {
        profile,
        icon,
      },
      nameLabel
    )
  }

  update(state: GameState, camera: Camera) {
    this.children["profile"].rotation.setFromRotationMatrix(camera.matrix)
  }

  dispose() {
    this.mesh.remove()
  }

  private animation: any | undefined

  scale(newScale: number, duration: number = 0): void {
    this.animation?.stop()

    if (duration === 0) {
      this.mesh.scale.set(newScale, newScale, newScale)
      return
    }

    const currentScale = this.mesh.scale
    this.animation = new TWEEN.Tween({
      x: currentScale.x,
      y: currentScale.y,
      z: currentScale.z,
    })
      .to({ x: newScale, y: newScale, z: newScale }, duration)
      .easing(TWEEN.Easing.Cubic.InOut)
      .onUpdate(({ x, y, z }) => {
        this.mesh.scale.set(x, y, z)
      })
      .start()
  }

  /**
   * コンポジション部分のBoundingSphereを得る。
   * プロフィール部分は無視されるので注意。
   */
  getBoundingSphereOfComposition(): Sphere {
    return getBoundingSphereOfMesh(this.compo.mesh)
  }
}
