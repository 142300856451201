import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.en.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A collection laid out in a large font. The theme here is simplicity. Which means: a self cover of the same fine felt-like paper as the interior, all printed only in black, with illustrations in the form of hand-drawn letters; a cahier simply held together by two staples. This gives the book a nice activist feel. However, this little book proves that it is also possible to achieve richness, nuance and elegance eloquence using minimal resources. The fact that the staples are black is just one such subtle refinement; the theme of the cover – typography placed at a slant – is made all the more interesting by the likewise slanted text inside the cover which shines through, and so becomes part of the cover design. Here, black print on white paper – in this case off-white – comes across as a fresh idea, rather than the worldwide default. The solution for including two languages in the collection – each given its own half of the book, each revolved through 180 degrees in relation to the other so that each language can be read from the cover to the centre of the book – is a frequently used one, but here feels extremely natural and continues the theme of criss-cross typography presented on the cover. The three compilers (of text, graphic design and written typography) explicitly present themselves as jointly responsible for the whole, which reinforces the sense of ‘unity in layering’ that elevates this modest little book above mass mediocrity.`}</p>
    <hr></hr>
    <p>{`Publisher: Jan van Eyck Academie, Maastricht`}<br parentName="p"></br>{`
`}{`Designer: Karoline Swiezynski, Amsterdam`}</p>
    <p>{`Category: Prose, poetry or graphic novels`}<br parentName="p"></br>{`
`}{`Editor: Jan van Eyck Academie, Maastricht`}<br parentName="p"></br>{`
`}{`Language: Dutch, English`}<br parentName="p"></br>{`
`}{`Translator: David Colmer`}<br parentName="p"></br>{`
`}{`Illustrator: Sooji Lee`}<br parentName="p"></br>{`
`}{`Editor-in-chief: Jan van Eyck Academie, Maastricht`}<br parentName="p"></br>{`
`}{`Printer: Charles Nypels Lab, Jan van Eyck Academie, Maastricht`}<br parentName="p"></br>{`
`}{`Binder: Charles Nypels Lab, Jan van Eyck Academie, Maastricht`}<br parentName="p"></br>{`
`}{`Font: Bodoni (Sooji Lee), Times (Linotype)`}<br parentName="p"></br>{`
`}{`Size in mm: 190 x 270 x 3`}<br parentName="p"></br>{`
`}{`Number of pages: 36`}<br parentName="p"></br>{`
`}{`Print run: 150`}<br parentName="p"></br>{`
`}{`Price: € 15`}<br parentName="p"></br>{`
`}{`ISBN: 978 90 72076 97 7`}<br parentName="p"></br>{`
`}{`Paper for interior: 90gsm EOS 2.0 (Igepa)`}<br parentName="p"></br>{`
`}{`Cover: 90gsm EOS 2.0 (Igepa)`}<br parentName="p"></br>{`
`}{`Binding style: Self cover, stapled using black staples, the fore-edge is untrimmed`}<br parentName="p"></br>{`
`}{`Special features: Risography.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      