import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`本書は建築家ヴィール・アレッツの近代的大都市の未来にまつわる万華鏡的なビジョンと、その環境に色と活力を与え、暮らしを変化させるために人間が無意識におこなう集団的選択をまとめたもの。ページ番号は「タイムスタンプ」に置き換えられ、72 分以内であればどんな旅や移動も楽しめるという考えのもと、それぞれの章が 72 分で終わるようになっている。折り返しがある neobond® 素材を用いたカバーが通常の表紙を覆い、素晴らしいしなやかさを獲得している。章は東京の都市風景を写した写真のパートで区切られている。ここでも、半透明の紙が用いられている。タイトルは折り返しの内側に刷られており、第 1 章の題は、タイトルページの次に挟まれた紙の向こうに透けて見える。写真パートそれぞれの最初のイメージも、ページの内側に印刷され、次のページの表面に再び見ることができる。それによって言葉とイメージはぼんやりと重なり、フォントやサイズの違いが生み出す強いコントラストによってテキストページ上の声と引用が区別される。タイポロジーにも前後の関係性が生まれ、うるさくならずに心地よい読書体験をもたらしている。もうひとつの賢い特徴は、非常に強くしなやかな neobond® によって割れることなく素晴らしい開き方をする背表紙だろう。ホットメルト接着剤を使用しているにもかかわらず、それがこの知的な書籍の可読性を高めている。何度も何度も考え抜かれたデザイン、というのも控えめな褒め言葉になってしまうだろう。ブラボー！`}</p>
    <hr></hr>
    <p>{`出版社： Actar, New York City (USA)`}<br parentName="p"></br>{`
`}{`デザイン： Mainstudio, Amsterdam`}</p>
    <p>{`カテゴリー： アート、写真、建築`}<br parentName="p"></br>{`
`}{`編集：John Bezold`}<br parentName="p"></br>{`
`}{`言語：英語`}<br parentName="p"></br>{`
`}{`写真：Wiel Arets`}<br parentName="p"></br>{`
`}{`編集：John Bezold`}<br parentName="p"></br>{`
`}{`リトグラフ：Zwaan Lenoir, Wormerveer`}<br parentName="p"></br>{`
`}{`印刷：Zwaan Lenoir, Wormerveer`}<br parentName="p"></br>{`
`}{`製本：Boekbinderij Patist, Den Dolder`}<br parentName="p"></br>{`
`}{`フォント：Stanley (Optimo) Monospace 821 (Bitstream)`}<br parentName="p"></br>{`
`}{`サイズ（ミリ）：165 x 235 x 21`}<br parentName="p"></br>{`
`}{`ページ数：278 (556)`}<br parentName="p"></br>{`
`}{`発行部数：2,000`}<br parentName="p"></br>{`
`}{`価格：€ 32`}<br parentName="p"></br>{`
`}{`ISBN：978 1 945150 65 4`}<br parentName="p"></br>{`
`}{`本文用紙：60gsm IBO One (Igepa)`}<br parentName="p"></br>{`
`}{`カバー：300gsm Munken Print White 15 (Antalis)`}<br parentName="p"></br>{`
`}{`ダストジャケット：200gsm neobond® green (Papyrus)`}<br parentName="p"></br>{`
`}{`製本方法：無線綴じソフトカバー、中身は袋綴じ、ダストジャケットは背に糊付け。`}<br parentName="p"></br>{`
`}{`特記事項：ダストジャケットはオフセット黒で二度印刷、背とカバーに Seanscreen による白スクリーン印刷。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      