import React, { useContext, useEffect, useRef } from "react"
import { navigate } from "gatsby"
import css from "./GameCanvas.module.scss"
import { Game } from "../webgl/Game"

import { useEvents } from "../hooks"
import { GlobalDispatchContext } from "../context/GlobalContextProvider"
import { getLang } from "../utils/getLang"
import { Area } from "../commonTypes"

/**
 * WebGL描画用のcanvasを持つコンポーネント。
 * Gameの初期化および破棄を行う。
 */
export const GameCanvas: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const events = useEvents()
  const dispatch = useContext(GlobalDispatchContext)

  useEffect(() => {
    // vendor詳細モーダルを開く
    const openVendor = (boothId: string, lang: string = `ja`) => {
      const langDir = lang === `ja` ? "" : "/en"
      navigate(`${langDir}/exhibitors/${boothId}`, {
        state: { modal: true, id: boothId },
      })
    }

    // ワールドを移動する
    const changeWorld = (worldId: number) => {
      // URLを変更
      const langDir = getLang() === "ja" ? "" : "/en"
      if (worldId === 0) {
        navigate(`${langDir}/`)
      } else if (worldId === 1) {
        navigate(`${langDir}/exhibitors/`)
      }

      events.emit("changeWorld", worldId)
    }

    const changeArea = (area: Area) => {
      // URLを変更
      const langDir = getLang() === "ja" ? "" : "/en"
      if (area === "entrance") {
        navigate(`${langDir}/`)
      } else if (area === "booth") {
        navigate(`${langDir}/exhibitors/`)
      } else if (area === "rafael") {
        navigate(`${langDir}/rafael/`)
      }

      events.emit("changeArea", area)
    }

    // 出展者のメニューを表示するためのイベント。
    // TODO: 不要になったら消す
    const talkToVendor = (vendorId: string) => {
      // console.log("talkToVendor", vendorId)
    }

    events.on("openVendor", openVendor)
    events.on("requestChangeWorld", changeWorld)
    events.on("requestChangeArea", changeArea)
    events.on("talkToVendor", talkToVendor)

    return () => {
      events.off("openVendor", openVendor)
      events.off("requestChangeWorld", changeWorld)
      events.off("requestChangeArea", changeArea)
      events.off("talkToVendor", talkToVendor)
    }
  }, [events])

  useEffect(() => {
    let game: Promise<Game>
    if (canvasRef.current !== null) {
      game = Game.init(events, canvasRef.current)
    }

    return () => {
      if (game) {
        game.then(g => g.dispose())
      }
    }
  }, [canvasRef])

  // 3D シーンのロード時に LOADED を DISPATCH すれば、 index.js 側でローディングレイヤーを削除します
  // https://github.com/hgw/vabf/issues/300
  // Game の load イベントと Action を変換
  useEffect(() => {
    const onLoad = (loaded: boolean) => {
      dispatch({ type: "WEBGL_LOADED", payload: loaded })
    }
    events.on("loaded", onLoad)
    return () => {
      events.off("loaded", onLoad)
    }
  }, [events])

  return <canvas className={css.venue} ref={canvasRef} />
}
