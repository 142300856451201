import React, { Component } from "react"
import css from "./EventListItem.module.scss"

class EventListItem extends Component {
  render() {
    return (
      <>
        <time className={css.item__time}>{this.props.time}</time>
        <div className={css.item__head}>
        <h3 className={css.item__title}>
          {this.props.title}</h3></div>
    <p className={css.item__text}>{this.props.excerpt}</p>
      </>
    )
  }
}

export default EventListItem
