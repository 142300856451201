import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.en.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Doug’s Cabin is a photographic research project by Karianne Bueno into solitary existence – whether by choice or otherwise. Protagonist Doug stayed on Vancouver Island, at his meagre campsite without campers, even when the military base there was dismantled after the Cold War. He is now fighting a losing battle against the ubiquitous forest that is slowly but surely causing his house and possessions to deteriorate, discolour and rot away. This type of photographic survey – using a combination of new photographs, historical documents and found photos – flourishes in the catalogue of publisher Eriskay Connection. Designers PutGootink show a deep understanding of the atmosphere of Bueno’s photography and have created a book full of landscapes in greens and greys, leafy vistas and still lifes. The texts, the reproductions of older documents and the historic images depicting the history of this environment have been printed on 60gsm, newspaper-style paper folded smaller than the book block, some pages untrimmed and with a perforated line so they can be torn open. These pages also contain notes pertaining to the numbered photos – both the historical photos and Bueno’s own. The feeling of melancholy and decay is balanced by the beauty of the landscape, the forest and the attraction of the unknown, the untamed. Dark but extremely sharp and precise printing, choice of paper and picture editing: all the elements work together to give this balance expressiveness, nuance and significance. It is a shame that the spine breaks when the book is opened, but this is otherwise an exemplary book.`}</p>
    <hr></hr>
    <p>{`Publisher: The Eriskay Connection, Breda`}<br parentName="p"></br>{`
`}{`Designer: PutGootink, Amsterdam`}</p>
    <p>{`Category: Art, photo and architecture books`}<br parentName="p"></br>{`
`}{`Artist: Karianne Bueno`}<br parentName="p"></br>{`
`}{`Editor: Rosie Heinrich`}<br parentName="p"></br>{`
`}{`Language: English`}<br parentName="p"></br>{`
`}{`Translator: Rosie Heinrich`}<br parentName="p"></br>{`
`}{`Photographer/ fotograaf: Karianne Bueno`}<br parentName="p"></br>{`
`}{`Illustrator/ illustrator: Medy Oberendorff`}<br parentName="p"></br>{`
`}{`Image editor: Ellen Sanders, Karianne Bueno`}<br parentName="p"></br>{`
`}{`Editor-in-chief: PutGootink`}<br parentName="p"></br>{`
`}{`Paper advice: De Monsterkamer`}<br parentName="p"></br>{`
`}{`Producer: Printing Matters (Daan van Tulder), Amsterdam`}<br parentName="p"></br>{`
`}{`Lithographer: Marc Gijzen beeldbewerking & digitale lithografie, Voorburg`}<br parentName="p"></br>{`
`}{`Printer: Zwaan Lenoir, Wormerveer`}<br parentName="p"></br>{`
`}{`Binder: Stronkhorst Boekbinders, Groningen`}<br parentName="p"></br>{`
`}{`Font: Circular (Lineto), Caslon (Adobe)`}<br parentName="p"></br>{`
`}{`Size in mm: 190 x 300 x 12`}<br parentName="p"></br>{`
`}{`Number of pages: 192`}<br parentName="p"></br>{`
`}{`Print run: 700`}<br parentName="p"></br>{`
`}{`Price: € 40`}<br parentName="p"></br>{`
`}{`ISBN: 978 94 92051 43 1`}<br parentName="p"></br>{`
`}{`Paper for interior: 120gsm PERGRAPHICA® Natural Smooth (Igepa), 60gsm Druckfix 2000 (Papier Union)`}<br parentName="p"></br>{`
`}{`Cover: 240gsm Pop’Set Urban Grey (Antalis)`}<br parentName="p"></br>{`
`}{`Binding style: Sewn softcover with multiple perforated foldouts`}<br parentName="p"></br>{`
`}{`Special features: Printing in full colour offset and Pantone black; all images are printed in skeleton screen, 72 pages are sewn in as 6 folded and perforated sheets.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      