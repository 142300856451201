import {
  DoubleSide,
  Mesh,
  MeshBasicMaterial,
  PlaneBufferGeometry,
  Texture,
  Vector3,
} from "three"
import { Loader } from "../../../Loader"
import {
  UNIT,
  CELL_UNIT_X,
  CELL_UNIT_Y,
  CELL_UNIT_Z,
  FURNITURE_MARGIN_WALL,
  CELL_MARGIN_X,
  FURNITURE_MARGIN_TABLE,
  CELL_MARGIN_Z,
  CELL_UNIT_Z_ROT,
  CELL_MARGIN_Y,
} from "../constants"
import {
  defaultMultiCompositionData,
  multiCompositionData,
} from "./multiCompositionData"
import { Composition } from "./types"
import { getRandomFurniture } from "./utils"

const PLANE_GEOMETRY = new PlaneBufferGeometry(1.2, 0.9)
const PLANE_GEOMETRY_ROT = new PlaneBufferGeometry(0.9, 1.2)

const randInt = (size: number) => Math.floor(Math.random() * size)

export class MultiComposition implements Composition {
  private constructor(
    public readonly mesh: Mesh,
    public readonly cells: [number, number][],
    public readonly profileOffset = new Vector3(0, 0, 0)
  ) {}

  static async init(
    name: string,
    tableTextures: Texture[],
    wallTextures: Texture[],
    exhibitorId: number
  ): Promise<MultiComposition> {
    const root = new Mesh()
    root.name = name

    // パラメータを初期化
    // データが無い場合はデフォルト構成にフォールバックする
    const data =
      multiCompositionData[exhibitorId] ?? defaultMultiCompositionData

    for (let i = 0; i < data.tables.length; i++) {
      const [x, z, dir] = data.tables[i]
      const xOffset = (data.xMargin ?? CELL_MARGIN_X) * x
      const zOffset = CELL_MARGIN_Z * z

      // Table
      const table = new Mesh(
        PLANE_GEOMETRY,
        new MeshBasicMaterial({
          map: tableTextures[i] ?? null,
        })
      )
      table.position.set(
        x * CELL_UNIT_X + xOffset,
        UNIT * 10,
        z * (dir === 0 ? CELL_UNIT_Z : CELL_UNIT_Z_ROT) + zOffset
      )
      if (dir === 1) {
        table.rotateY(-Math.PI / 2)
      }
      table.rotateX(-Math.PI / 2)
      root.add(table)

      // Table Furniture
      const tableFurniture = await getRandomFurniture()
      const tp = table.position
      tableFurniture.position.set(
        tp.x + (randInt(2) - 0.5) * 0.6,
        tp.y - (randInt(3) * 2 + 5) * UNIT - FURNITURE_MARGIN_TABLE,
        tp.z + (randInt(2) - 0.5) * 0.45
      )
      tableFurniture.rotateY((randInt(4) * Math.PI) / 2)
      root.add(tableFurniture)
    }

    for (let i = 0; i < data.walls.length; i++) {
      const [x, y, dir, tableDir] = data.walls[i]
      const xOffset = (data.xMargin ?? CELL_MARGIN_X) * x
      const yOffset = CELL_MARGIN_Y * y

      // Wall
      const wall = new Mesh(
        dir === 0 ? PLANE_GEOMETRY : PLANE_GEOMETRY_ROT,
        new MeshBasicMaterial({
          map: wallTextures[i] ?? null,
          side: DoubleSide,
        })
      )
      if (tableDir === 0) {
        wall.position.set(
          x * CELL_UNIT_X + xOffset,
          UNIT * 10 + (dir === 0 ? 0.45 : 0.6) + y * CELL_UNIT_Y + yOffset,
          -0.45 - UNIT
        )
      } else {
        wall.position.set(
          x * CELL_UNIT_X + xOffset,
          UNIT * 10 + (dir === 0 ? 0.45 : 0.6) + y * CELL_UNIT_Y + yOffset,
          -0.6 - UNIT
        )
      }
      root.add(wall)

      // Wall Furniture
      const wallFurniture = await getRandomFurniture()
      const wp = wall.position
      wallFurniture.position.set(
        wp.x + (randInt(3) - 1) * 0.4,
        wp.y - 0.45 - randInt(2) * UNIT * 4,
        wp.z - (randInt(1) * 3 + 4) * UNIT - FURNITURE_MARGIN_WALL
      )
      wallFurniture.rotateY((randInt(4) * Math.PI) / 2)
      root.add(wallFurniture)
    }

    return new MultiComposition(root, data.cells, data.profileOffset)
  }
}
