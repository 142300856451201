import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`ハールレムを拠点とする詩の研究所デ・ゼィンゲンデ・ザーグ（歌うのこぎり）は、設立 30 周年を祝福するため、このカラフルにそよぐ三角旗を作り出した。それは文字通り、フラットブックとアコーディオンブックを組み合わせた 12 メートルもの長さを持つ（作り手によれば世界最長の）本になっている。始まりから終わりまでページが糊付けされ、閉じられた立方体の状態から、三角旗のようにジグザグにつながったページが広がる見事なデザインは、閉じれば美しい立方体に収まり、過去 30 年、同研究所が出版してきた 42 冊の本をつなぎ合わせる。本書のために寄せられた詩やレビュー、その他テキストには、80 年代ポップシーンを席巻したドー・マールのアルバムカバーを彷彿とさせる派手でレトロな書体が用いられ、お祭り的かつ混沌としたデザインはのこぎりの歯をモチーフにしている。これ以上に遊び心たっぷりのデザインは存在しないだろう。`}</p>
    <hr></hr>
    <p>{`出版社： De Zingende Zaag Producties, Haarlem`}<br parentName="p"></br>{`
`}{`コンセプト・デザイン：Studio Hendriksen, Amsterdam; George Moormann, Haarlem`}</p>
    <p>{`カテゴリー：散文、詩、グラフィックノベル`}<br parentName="p"></br>{`
`}{`編集：George Moormann`}<br parentName="p"></br>{`
`}{`言語：オランダ語`}<br parentName="p"></br>{`
`}{`写真：Ton van der Heide`}<br parentName="p"></br>{`
`}{`イメージ編集：George Moormann`}<br parentName="p"></br>{`
`}{`デザイン： Studio Hendriksen, Amsterdam`}<br parentName="p"></br>{`
`}{`リトグラフ：Zwaan Lenoir, Wormerveer`}<br parentName="p"></br>{`
`}{`印刷：Zwaan Lenoir, Wormerveer`}<br parentName="p"></br>{`
`}{`製本：J. Voetelink & Zoon, Haarlem`}<br parentName="p"></br>{`
`}{`フォント：Maison Neue (Milieu Grotesque)`}<br parentName="p"></br>{`
`}{`サイズ（ミリ）：170 x 225 x 40 (leporello: 12.24 meter)`}<br parentName="p"></br>{`
`}{`ページ数：144`}<br parentName="p"></br>{`
`}{`発行部数：333`}<br parentName="p"></br>{`
`}{`価格：€ 125`}<br parentName="p"></br>{`
`}{`ISBN：979 90 74183 31 4`}<br parentName="p"></br>{`
`}{`本文用紙：250gsm Edixion Offset (Antalis)`}<br parentName="p"></br>{`
`}{`製本方法：合紙綴じと蛇腹綴じの組み合わせ`}<br parentName="p"></br>{`
`}{`特記事項：世界一長い文芸蛇腹本。J. Voetelink & Zoon はそのような本を製本できる機械を所有するヨーロッパ唯一の会社。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      