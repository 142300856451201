import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`アムステルダム市立美術館で開催されたレバノン系アメリカ人アーティスト、ワリッド・ラードの展覧会図録として制作された本書。博士論文の執筆にあたりおこなった長期的な資料調査に着想を得て、ラードはその後の制作で架空の書類を作り出し、それが本図録に 2 冊に分割して収められている。1 冊は冊子として、もう 1 冊は蛇腹本として、アーカイブ的なボード紙のカバーの左側と右側に収められ、カバーの背表紙には Roma Publications のロジャー・ヴィレムスがそのために製作したリネンの帯が付属する。冊子は日記の複製のようなもので、ラードの父親の日記の上に、ボールペンで彼の家の周りに落ちてきた爆弾を描いている。蛇腹本は植物日記の体裁を取るが、それぞれの植物の名前は中東の地方や地区、もしくは国際的な政治の舞台で活動する人物を象徴するコードとして使われている。特別に訓練された植物学者によって関連する人物の顔写真を貼り付けられた花や植物が、奇妙な植物図鑑を作り出した。本書にはそれらが完璧な形で収められており、カバーのタイポグラフィは先に述べたリネンの帯と全く同じ色で箔押しされている。両方の冊子に使われた光沢紙はそれを包む資料的なカードボードと対照的で、それらの「書類」に奇妙な威光を付与している。同じように、製本の精密さはそれが内包する書類の不条理さを強調し、国として正常な状態を数十年にわたり模索しながらも、戦争しか知ることができなかった国のひび割れた現実を明らかにする。`}</p>
    <hr></hr>
    <p>{`出版社： Roma Publications, Amsterdam`}<br parentName="p"></br>{`
`}{`コンセプト・デザイン：Hripsimé Visser, Walid Raad, Roger Willems`}</p>
    <p>{`カテゴリー： アート、写真、建築`}<br parentName="p"></br>{`
`}{`アーティスト：Walid Raad`}<br parentName="p"></br>{`
`}{`編集：Dutton Hauhart`}<br parentName="p"></br>{`
`}{`言語：英語`}<br parentName="p"></br>{`
`}{`イメージ編集：Walid Raad`}<br parentName="p"></br>{`
`}{`編集：Roger Willems`}<br parentName="p"></br>{`
`}{`デザイン： Roger Willems, Amsterdam; Walid Raad, Medusa (USA)`}<br parentName="p"></br>{`
`}{`リトグラフ：Zwaan Lenoir, Wormerveer`}<br parentName="p"></br>{`
`}{`印刷：Zwaan Lenoir, Wormerveer`}<br parentName="p"></br>{`
`}{`製本：J. Voetelink & Zoon, Haarlem`}<br parentName="p"></br>{`
`}{`フォント：Agipo (RP Digital Type Foundry)`}<br parentName="p"></br>{`
`}{`サイズ（ミリ）：210 x 280 x 5`}<br parentName="p"></br>{`
`}{`ページ数：16 pages leporello, 12 pages brochure`}<br parentName="p"></br>{`
`}{`発行部数：1,000`}<br parentName="p"></br>{`
`}{`価格：€ 27.50`}<br parentName="p"></br>{`
`}{`ISBN：978 94 92811 55 4`}<br parentName="p"></br>{`
`}{`本文用紙：Leporello: 250gsm tom&otto Gloss (Antalis), brochure: 150gsm tom&otto Gloss (Antalis)`}<br parentName="p"></br>{`
`}{`カバー：430gsm Cairn Eco Natural Kraft (Christiaan Janssen)`}<br parentName="p"></br>{`
`}{`製本方法：カバーフラップ付き、蛇腹部分は表フラップに糊付け、冊部分はは裏フラップに縫い付け。`}<br parentName="p"></br>{`
`}{`特記事項：１枚のカバーに異なる２部分が綴じられている。背にリネン糊付け（テキスタイル： Imperial 4810 Aluminium – Balacron International 社）、カバーに KuiperDonse によるグレーの箔押し(Kurz Colorit 932)。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      