export type Area = "entrance" | "booth" | "rafael" | "other"

export function getAreaFromUrl(): Area {
  if (typeof window === "undefined") {
    return "other"
  }

  const pathname = window.location.pathname
  if (/^\/(en\/)?$/.test(pathname)) {
    return "entrance"
  }
  if (/^\/(en\/)?exhibitors\/$/.test(pathname)) {
    return "booth"
  }
  if (/^\/(en\/)?rafael\/$/.test(pathname)) {
    return "rafael"
  }

  return "other"
}
