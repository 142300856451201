import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`シンプルながら力強い本であり、明確なアイディアをもとにした大判ながらも静けさを持ったペーパーバックだ。そのアイディアはアムステルダム国立美術館で開催された『All the Rembrandts』展開催中に生まれたもので、会期中に販売するために異例の速さで制作する必要があった。その成果物であるこのずっしりとしたペーパーバックのコンセプトは明確で、右側のページに作品を、左側のページに短い解説文を掲載するというもの。しかし、800 ページを通して全く退屈させない作りになっている。本書の一番の魅力は冒頭と後半のページ、そしてカバーに用いられたエッチングで、作家を特徴付ける神経質な筆致、その細部を美しく再現している。厚さ 6 センチの背表紙に用いられた力強いタイポグラフィとテキストのない表紙の組み合わせもうまく機能している。800 ページのペーパーバックにしてはページもきれいに開く。すべての点に明確な意図があり、大胆な制約のもと、素晴らしい成果を生み出している。`}</p>
    <hr></hr>
    <p>{`出版社： Rijksmuseum, Amsterdam`}<br parentName="p"></br>{`
`}{`デザイン： Irma Boom, Amsterdam`}</p>
    <p>{`カテゴリー： アート、写真、建築`}<br parentName="p"></br>{`
`}{`編集：Pauline Kintz, Jane Turner`}<br parentName="p"></br>{`
`}{`言語：オランダ語、英語`}<br parentName="p"></br>{`
`}{`翻訳：Kist & Kilian`}<br parentName="p"></br>{`
`}{`イメージ編集：Ellen Slob`}<br parentName="p"></br>{`
`}{`編集：Geri Klazema, Barbera van Kooij`}<br parentName="p"></br>{`
`}{`レイアウト：Irma Boom, Amsterdam`}<br parentName="p"></br>{`
`}{`リトグラフ：BFC graphics and design (Bert van der Horst), Amersfoort`}<br parentName="p"></br>{`
`}{`印刷：Wilco Art Books, Amersfoort`}<br parentName="p"></br>{`
`}{`製本：Wilco Art Books, Amersfoort`}<br parentName="p"></br>{`
`}{`フォント：Rijksmuseum`}<br parentName="p"></br>{`
`}{`サイズ（ミリ）：150 x 210 x 61`}<br parentName="p"></br>{`
`}{`ページ数：824`}<br parentName="p"></br>{`
`}{`発行部数：6,000`}<br parentName="p"></br>{`
`}{`価格：€ 40`}<br parentName="p"></br>{`
`}{`ISBN：978 94 6208 509 1`}<br parentName="p"></br>{`
`}{`本文用紙：100gsm Arcoprint Milk 1.5 (Fedrigoni)`}<br parentName="p"></br>{`
`}{`カバー：300gsm Munken Print White 1.5 (Antalis)`}<br parentName="p"></br>{`
`}{`製本方法：糸かがりソフトカバー`}<br parentName="p"></br>{`
`}{`特記事項：表紙と中身は Pantone072 で印刷。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      