import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/DefaultPage.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`TERMS OF SERVICE`}</h1>
    <p>{`TOKYO ART BOOK FAIR (hereinafter referred to as “TABF”) has adopted the following terms of service (hereinafter referred to as the “Terms”) in regards to the usage and provision of services relating to the website “VIRTUAL ART BOOK FAIR” (`}<a parentName="p" {...{
        "href": "https://virtualartbookfair.com/"
      }}>{`https://virtualartbookfair.com/`}</a>{`) (hereinafter referred to as the “Website”) operated by TABF.`}</p>
    <h2>{`1. General Provisions`}</h2>
    <h3>{`Article 1. (Scope of Terms)`}</h3>
    <ol>
      <li parentName="ol">{`These terms stipulate the agreement regarding the use and provision of the Service.`}</li>
      <li parentName="ol">{`These terms apply to all users (defined in the next article) regarding the usage and provision of this service.`}</li>
      <li parentName="ol">{`TABF may, without user consent, alter or completely change the details of the Terms upon sending users a notice or announcement through any means they deem appropriate, including posts on the Site as well as messages (this term includes any and all electronic contact including emails, application-based messages, and more. The term applies hereinafter).`}</li>
      <li parentName="ol">{`Should all or part of the Terms be changed, the new agreement shall replace this one and apply in its place to the usage and provision of the Service.`}</li>
    </ol>
    <h2>{`2. Definition`}</h2>
    <h3>{`Article 2. (Users)`}</h3>
    <p>{`In these Terms, “user” refers to individuals/parties (this includes “Exhibitors” defined in the next section) who search, browse, or use the images, texts, designs, logos, videos, programs, ideas, information, etc. (hereinafter referred to collectively as “content”) provided by TABF in the Service. Users shall be expected to have understood and accepted all contents of these Terms (including special contracts, subordinate rules, and guidelines, etc. mentioned in Article 4 Item 2 of the Terms).`}</p>
    <h3>{`Article 3. (Exhibitors)`}</h3>
    <p>{`In these Terms, “exhibitor” refers to any User who conducts sales activities on this site based on the Exhibitor Agreement (hereinafter referred to as “Exhibitor Agreement”) separately established by TABF.`}</p>
    <h2>{`3. Service Use`}</h2>
    <h3>{`Article 4. (Using this Service)`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Users shall use this Service in accordance with laws, regulations, notifications, and the Terms themselves, as well as the Privacy Policy, help resources, etc. separately established by TABF.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In addition to these Terms, TABF may establish special contracts for other services that users can use, other subordinate agreements to these Terms, or further guidelines, etc. Any subordinate agreements, guidelines, etc. to these Terms are applied to the use of the Service as part of these Terms.`}</p>
      </li>
    </ol>
    <h3>{`Article 5. (Users who are Minors)`}</h3>
    <ol>
      <li parentName="ol">{`Regarding the Service provided by TABF, the definition for whether a user is considered a minor is based on the laws of Japan.`}</li>
      <li parentName="ol">{`If it is found that an exhibitor or user is a minor and is exhibiting or purchasing without the consent of a parent or guardian, TABF may, without prior notification, suspend the performance of transactions such as product shipment by the corresponding exhibitor, or otherwise request suspension of the exhibitor from the Service.`}</li>
      <li parentName="ol">{`TABF may impose an age limit for this Service or other ancillary services. If it is discovered that a User has purchased an age-restricted product or is using this service or ancillary services even though he or she has not reached the age specified by TABF, we may take some or all of the measures specified in the previous paragraph.`}</li>
    </ol>
    <h3>{`Article 6. (Adding/Adjusting Ancillary Services)`}</h3>
    <p>{`TABF may add ancillary services to this Service (hereinafter referred to as “ancillary services”) and change the contents of the Service without prior notification to Users. When the user uses the Service, the Terms and guidelines newly applicable to the Service after addition or adjustment shall be followed.`}</p>
    <h3>{`Article 7. (Notifications)`}</h3>
    <ol>
      <li parentName="ol">{`Notifications from TABF to users regarding this Service will be made by posting on the website related to this Service or by other methods that we deem appropriate.`}</li>
      <li parentName="ol">{`When TABF sends a notification message, the notification will deemed as having arrived once 24 have passed since the email was sent to the registered email address by TABF or when the user views it, whichever comes first, and it will be assumed that the user agrees with the message.`}</li>
      <li parentName="ol">{`Regarding product purchases, TABF may send notifications on behalf of the exhibitor.`}</li>
    </ol>
    <h3>{`Article 8. (Contact / Publicity)`}</h3>
    <ol>
      <li parentName="ol">{`TABF may contact users by telephone, e-mail, or other methods in order to collect opinions on the Service, check transaction statuses, conduct hearings, etc.`}</li>
      <li parentName="ol">{`Our corporation may contact the user by telephone, e-mail, mail or other methods for campaign announcement, advertisement / promotion, etc. regarding this service.`}</li>
      <li parentName="ol">{`The exhibitor may contact users by telephone, e-mail, post, or other means to transmit data within the scope of a transaction, such as delivery information for purchased products, instruction manuals, aftercare information, etc.`}</li>
    </ol>
    <h3>{`Article 9. (Product Purchases)`}</h3>
    <p>{`When a user purchases a product or service (hereinafter simply referred to as “product”) during the Service, the provisions of Section 4 shall be followed.`}</p>
    <h3>{`Article 10. (Usage Restrictions)`}</h3>
    <ol>
      <li parentName="ol">{`If a user falls under or is likely to fall under any of the following items, we will immediately inform the user of all or of this service without prior notice or notification. We will stop offering some of them.`}
        <ol parentName="li">
          <li parentName="ol">{`When the following acts are performed:`}
            <ul parentName="li">
              <li parentName="ul">{`Violent demands on TABF or TABF employees`}</li>
              <li parentName="ul">{`Unreasonable demands beyond legal responsibility for TABF or TABF employees`}</li>
              <li parentName="ul">{`Acts of threatening behavior or using violence against TABF or TABF employees regarding transactions`}</li>
              <li parentName="ul">{`Acts that damage the credibility of TABF or interfere with the business through the dissemination of rumors, counterfeits, or intimidation`}</li>
              <li parentName="ul">{`Other acts that violate the law or correspond with the preceding items`}</li>
            </ul>
          </li>
          <li parentName="ol">{`When a user ceases to pay or becomes insolvent, or when a petition is filed to begin bankruptcy proceedings, civil rehabilitation proceedings, corporate reorganization proceedings, special liquidation proceedings, or other similar proceedings, or when a petition is filed by the user`}</li>
          <li parentName="ol">{`If TABF deems it inappropriate to provide the Service for any other reason`}</li>
        </ol>
      </li>
    </ol>
    <h2>{`4. Product Purchases`}</h2>
    <h3>{`Article 11. (Establishing a Sales Contract)`}</h3>
    <ol>
      <li parentName="ol">{`Users wishing to purchase products or use services shall place orders through the place of sale designated by the exhibitor. In this case, the user must abide by the terms and conditions of the service set by each place of sale.`}</li>
      <li parentName="ol">{`The order in the previous paragraph will be completed after meeting one of the following conditions:`}
        <ol parentName="li">
          <li parentName="ol">{`When the user confirms the delivery address, order details, and payment method entered which has been registered at the place of sale set up by the exhibitor`}</li>
          <li parentName="ol">{`When the user receives an email from the exhibitor confirming the order`}</li>
        </ol>
      </li>
      <li parentName="ol">{`The purchase agreement between the user and the exhibitor shall be concluded at the following time:
After the completion of the order, the user makes a payment by the method specified at the place of sale used by the exhibitor, and the payment is completed.`}</li>
      <li parentName="ol">{`The user may not assign their rights and obligations to a third party or provide security or otherwise dispose of them in accordance with the sales contract.`}</li>
      <li parentName="ol">{`If any fraudulent or inappropriate acts are discovered in connection with the use of this Service, TABF and the exhibitor may cancel or terminate the purchase agreement or take other appropriate measures.`}</li>
    </ol>
    <h3>{`Article 12. (Payment Method)`}</h3>
    <p>{`Payment and settlement for products purchased through the Service will be determined by the exhibitor (or the place of sale designated by the exhibitor), and users shall follow the relevant payment method.`}</p>
    <h3>{`Article 13. (Non-payments, Late Payments, etc.)`}</h3>
    <p>{`If a user does not make the required payment in accordance with these Terms or the terms and conditions of each place of sale designated by the exhibitor, or if the credit card or financial institution account entered into the Service is suspended, the exhibitor may suspend the use of the Service by the user without notice to the user in question.`}</p>
    <h3>{`Article 14. (Shipping/Delivery)`}</h3>
    <p>{`As a rule, exhibitors will conduct the shipping of ordered products.`}</p>
    <h3>{`Article 15. (Delivery Completion)`}</h3>
    <p>{`Products shall be delivered to the location specified by the user at the time of the order, and delivery of the products shall be considered complete upon delivery.`}</p>
    <h3>{`Article 16. (Withdrawing or Cancelling Orders)`}</h3>
    <p>{`Withdrawals and order cancellations are subject to the terms and conditions of the respective place of sale.`}</p>
    <h3>{`Article 17. (Returns, Exchanges, etc.)`}</h3>
    <ol>
      <li parentName="ol">{`Products may be returned or exchanged in accordance with the terms and conditions of the place of sales specified by the exhibitor.`}</li>
      <li parentName="ol">{`In the event that an item is returned or exchanged for a product as specified in the previous section, the user (customer) is responsible for returning the item. In the case of return of a product, the points acquired during the purchase of the product shall be returned to the issuer.`}</li>
      <li parentName="ol">{`Refunds will be made by the exhibitor only after the product returns are received in accordance with Clauses 1 and 2 of this Article.`}</li>
      <li parentName="ol">{`If a user returns an item to TABF or the exhibitor without a valid reason, TABF or the exhibitor will not be obligated to receive it.`}</li>
      <li parentName="ol">{`TABF shall store a product for a period of 30 days from the time of conclusion of the purchase agreement. If TABF is instructed by the user to receive the product during the storage period, we shall deliver the product to the user in an “as-is” state (including possible deterioration, deformation, wear and tear, damage or decay) and shall not be responsible for the condition of the product.`}</li>
      <li parentName="ol">{`After the storage period is over as stated in the preceding paragraph, TABF shall dispose of the relevant product(s) without notifying the user that the storage period has expired, and shall conduct this disposal by means determined by TABF. In this case, TABF shall not be liable to the user.`}</li>
    </ol>
    <h3>{`Article 18. (Product Ownership)`}</h3>
    <p>{`The ownership of products not sold directly by the Service remains with the exhibitor. The transfer of ownership of products shall be in comply with the terms and conditions of each service.`}</p>
    <h3>{`Article 19. (Selling Price)`}</h3>
    <ol>
      <li parentName="ol">{`The prices of products displayed on the Site reflect the prices as they were at the time the page was first loaded, and thus may have changed thereafter.`}</li>
      <li parentName="ol">{`In the event that the price displayed on the Site differs from the actual selling price due to human error or other reasons, the actual selling price shall take precedence over the price displayed on the Site. In such cases, any disputes that may arise between the user and the exhibitor shall be resolved between the user and the exhibitor. However, TABF may enter into discussions with the exhibitor at our discretion.`}</li>
    </ol>
    <h3>{`Article 20. (Product-related Trouble)`}</h3>
    <p>{`Any dispute between a user and an exhibitor regarding a product shall be resolved between the user and the exhibitor in question. However, TABF may enter into discussions with the exhibitor at our discretion.`}</p>
    <h3>{`Article 21. (Product Disclaimers)`}</h3>
    <ol>
      <li parentName="ol">{`TABF does not warrant, bear, or assume responsibility for the quality, materials, functions, performance, compatibility with other products, or any other defects in the products sold through the Service. TABF shall not be liable for any damage, loss, or disadvantage caused by such defects.`}</li>
      <li parentName="ol">{`In the event that a problem arises due to an unknown delivery address, the user and the exhibitor shall resolve the problem amongst themselves. However, TABF reserves the right to enter into discussions with any user or exhibitor at our discretion.`}</li>
      <li parentName="ol">{`The products posted on the Service may not express subtle nuances, textures, colors, etc. due to the nature of the online nature of the Service and other factors, and may appear different from the actual products. In such cases, TABF does not warrant or assume responsibility for the products.`}</li>
    </ol>
    <h2>{`5. User Responsibility`}</h2>
    <h3>{`Article 22. (Maintaining the Usage Environment)`}</h3>
    <ol>
      <li parentName="ol">{`The user shall be responsible for the preparation of all communication equipment, software, communication lines, etc. required to use the Service at his or her own risk and expense, and shall maintain and manage the entire environment necessary for the use of the Service.`}</li>
      <li parentName="ol">{`TABF shall not be responsible for any damage caused to the user due to a defect, failure, malfunction, or failure of the equipment, software, or communication network used by the user to use the Service.`}</li>
    </ol>
    <h3>{`Article 23. (Taking Responsibility for User IDs within an Exhibitor’s Sales Area)`}</h3>
    <p>{`Users shall manage their IDs and passwords at the place of sale set up by exhibitors in accordance with the terms of use of each service and in an appropriate manner.`}</p>
    <h3>{`Article 24. (Disclaimers)`}</h3>
    <ol>
      <li parentName="ol">{`If the Service provides a link to another website or resource, or if a third party website or resource provides a link to the Service, TABF agrees that the content, use, and results of such linked sites (including but not limited to their legality, validity, accuracy, certainty, security, recency, and completeness) may be subject to change without notice. Furthermore, if TABF reasonably determines that the content of a linked website or resource is illegal or inappropriate for the management and operation of the Service, we may remove the linked website or resource without prior notice to the user.`}</li>
      <li parentName="ol">{`In the event that a transaction is made with an advertiser who advertises or promotes the Service (including but not limited to sweepstakes advertisements or promotions), the user shall enter into this transaction at their own discretion and responsibility, and TABF shall not be held responsible. TABF does not guarantee the payment of goods, the terms and conditions of the contract, or the existence or non-existence of any warranty, security, or license, and shall not be liable for any damage or loss to the user resulting from a transaction conducted via an advertisement or promotion posted on the Service.`}</li>
      <li parentName="ol">{`TABF may temporarily halt, suspend, discontinue, or change the Service in the event of any of the following reasons, and shall not be liable for any direct or indirect damage, loss, or disadvantage to the user:`}
        <ol parentName="li">
          <li parentName="ol">{`In the event of a natural disaster such as a fire, earthquake, flood, lightning, heavy snowfall, etc.`}</li>
          <li parentName="ol">{`In the event of social unrest such as war, civil unrest, terrorism, riots, or disturbances`}</li>
          <li parentName="ol">{`In the event that TABF does not receive proper service from the telephone company, carrier, or provider with whom we have a contract`}</li>
          <li parentName="ol">{`In order to maintain, check, or update the computer system of the Service`}</li>
          <li parentName="ol">{`In the event that the corporation is technically incapable of handling the Service`}</li>
          <li parentName="ol">{`Any other case in which TABF deems it difficult to provide the service`}</li>
        </ol>
      </li>
      <li parentName="ol">{`TABF shall be discharged from its obligations to the user and exhibitor by processing business in accordance with these Terms.`}</li>
      <li parentName="ol">{`If a user or exhibitor causes damage to another user or third party by using this Service, the user or exhibitor shall be responsible for solving the problem and shall not cause any damage, loss, or disadvantage to TABF.`}</li>
    </ol>
    <h3>{`Article 25. (Prohibited Items)`}</h3>
    <p>{`The user shall not engage in any of the following acts. If the violation of these rules results in damage to the corporation or a third party, the user shall be responsible for compensating the corporation for all damages.`}</p>
    <ol>
      <li parentName="ol">{`Actions that cause or may cause nuisance, disadvantage, or damage to other users, exhibitors, other third parties, or to TABF`}</li>
      <li parentName="ol">{`Any action that infringes or may infringe on the intellectual property rights, portrait rights, moral rights, privacy rights, publicity rights, or other rights of other users, exhibitors, other third parties, or to TABF`}</li>
      <li parentName="ol">{`Use of the Service for commercial purposes (except for actions approved in advance by TABF)`}</li>
      <li parentName="ol">{`Any act that is or may be against public order and morals, or any other act that is or may be against the law`}</li>
      <li parentName="ol">{`Registering or posting information that contains false or misleading content`}</li>
      <li parentName="ol">{`The use of content obtained through this service for purposes other than personal use by users and exhibitors`}</li>
      <li parentName="ol">{`Copying, selling, publishing, distributing, publishing, or publicizing the contents obtained through the Service through other users, exhibitors, or other third parties`}</li>
      <li parentName="ol">{`Improperly collecting, accumulating, or storing the personal information of other users or exhibitors`}</li>
      <li parentName="ol">{`Uploading to the Service or sending via email or other means contents such as computer viruses, computer code, files, or programs designed to interfere with, destroy, or limit the functioning of computer software, hardware, or telecommunications equipment`}</li>
      <li parentName="ol">{`Any other actions that TABF reasonably deems inappropriate, such as damaging or damaging the reputation of TABF`}</li>
    </ol>
    <h3>{`Article 26. (Usage Restrictions)`}</h3>
    <ol>
      <li parentName="ol">{`TABF may, if any of the following items apply to the user, or if the user is suspected of falling under any of the following items, immediately terminate the provision of all or part of this Service to the user in question, and may cancel the exhibitor's registration without prior notice or warning.`}
        <ol parentName="li">
          <li parentName="ol">{`If any of the items in the preceding article (Prohibited Items) apply, or if there is a violation of any of the provisions of these Terms, or TABF’s instructions within these Terms are not followed`}</li>
          <li parentName="ol">{`When a user engages in any of the following behaviors:`}
            <ol parentName="li">
              <li parentName="ol">{`Violent demands of TABF or TABF employees`}</li>
              <li parentName="ol">{`Unreasonable demands of TABF, TABF employees, etc., in excess of our legal responsibilities`}</li>
              <li parentName="ol">{`Acts of using threatening language or behavior or violence against TABF or TABF employees in connection with transactions`}</li>
              <li parentName="ol">{`Damaging the reputation of TABF or interfering with our business by spreading rumors or using deception or force`}</li>
              <li parentName="ol">{`Any other actions that violate laws and regulations or that are similar to the above`}</li>
            </ol>
          </li>
          <li parentName="ol">{`If TABF deems it inappropriate to provide the Service for any other reason`}</li>
        </ol>
      </li>
      <li parentName="ol">{`TABF shall not be responsible for any damage or result caused to the user or any third party due to the suspension of the Service or the implementation of the measures stipulated in the previous article.`}</li>
      <li parentName="ol">{`The cancellation of the Service in accordance with this article shall not prevent TABF from claiming compensation for damages from the user.`}</li>
    </ol>
    <h2>{`6. Service Cancellation, etc.`}</h2>
    <h3>{`Article 27. (Cancellation of the Service)`}</h3>
    <ol>
      <li parentName="ol">{`TABF may temporarily suspend or cancel all or part of the Service without prior notice to users in order to maintain good operation of the Service, in any of the following cases:`}
        <ol parentName="li">
          <li parentName="ol">{`In the event of regular or emergency maintenance of the computer system (hereinafter referred to as the “system”) used to provide the Service`}</li>
          <li parentName="ol">{`In the event that the system becomes difficult to operate due to a natural disaster such as fire, earthquake, flood, lightning, or heavy snowfall, etc.`}</li>
          <li parentName="ol">{`In the event that social unrest such as war, civil unrest, terrorism, riots or disturbances make it difficult to operate the system`}</li>
          <li parentName="ol">{`In the event that system operation becomes difficult due to system malfunction, unauthorized access from a third party, computer virus infection, etc.`}</li>
          <li parentName="ol">{`In the event that a governmental or judicial body issues a reasonable request`}</li>
          <li parentName="ol">{`In the event that TABF deems it necessary to suspend or cancel the system for any other reason`}</li>
        </ol>
      </li>
      <li parentName="ol">{`TABF may, at any time and for any reason, suspend or cancel the Service, in whole or in part, without prior notice to the user.`}</li>
      <li parentName="ol">{`TABF shall not be liable for any loss or damage caused by the suspension, interruption, or discontinuation of the Service.`}</li>
    </ol>
    <h2>{`7. Handling of Data`}</h2>
    <h3>{`Article 28. (Personal Data Usage and Protection)`}</h3>
    <ol>
      <li parentName="ol">{`TABF acquires the following personal data when operating the Service:`}
        <ol parentName="li">
          <li parentName="ol">{`Information that is mechanically acquired when a user accesses the Site:`}
            <ul parentName="li">
              <li parentName="ul">{`IP address and domain name`}</li>
              <li parentName="ul">{`Browser type and version, operating system version, language, and location`}</li>
              <li parentName="ul">{`Device information (PC, mobile phone, smart phone, mobile carrier, model information, device identification information, etc.)`}</li>
            </ul>
          </li>
          <li parentName="ol">{`The name, address, telephone number, e-mail address and other information necessary for the implementation of campaigns or other transactions hosted or co-hosted by TABF, or applies for other transactions with TABF`}</li>
          <li parentName="ol">{`The content of the inquiry, name, e-mail address, and any other information necessary to respond to an opinion, request, or inquiry which TABF receives from a user`}</li>
          <li parentName="ol">{`Numbers, symbols and other codes that are assigned to individuals, such as designated IDs`}</li>
          <li parentName="ol">{`Other personal data legally acquired or held by TABF`}</li>
        </ol>
      </li>
      <li parentName="ol">{`TABF will handle the personal data specified in the preceding paragraph in accordance with our Privacy Policy.`}</li>
    </ol>
    <h3>{`Article 29. (Browsing Information, etc.)`}</h3>
    <p>{`TABF may view, store, or disclose to third parties any information related to the operation of the Service to the extent necessary in accordance with our Privacy Policy, which is established separately. TABF will not be liable for any damage caused by this.`}</p>
    <h3>{`Article 30. (Data Management, etc.)`}</h3>
    <ol>
      <li parentName="ol">{`TABF may, at our discretion, freely use, in whole or in part, any content sent by users in connection with the Service on our website or any other affiliated site, and we are not obligated to make any payment for the use of such content, regardless of the name of the user.`}</li>
      <li parentName="ol">{`TABF collects the following data in order to study user access history and usage conditions and to improve its services to users. Cookies issued by TABF will not be used for the collection of personal data or for any other purpose.`}
        <ol parentName="li">
          <li parentName="ol">{`Data regarding the IP address or mobile device ID number used by the user to access the server of the Service`}</li>
          <li parentName="ol">{`Data that TABF obtains through cookie technology (a technology that temporarily writes data on a user's computer through a web browser to record and store information such as the date and time of the user's last visit to a website and the number of visits to that website)`}</li>
          <li parentName="ol">{`If a user chooses to reject cookies in their settings, usage of this Service may be restricted.`}</li>
        </ol>
      </li>
    </ol>
    <h3>{`Article 31. (Aggregating Usage Records)`}</h3>
    <p>{`TABF shall aggregate and analyze the usage records of users, process and compile them in such a way that individuals cannot be identified or specified, and use and dispose of them for the purpose of developing other future services operated by TABF and other business activities.`}</p>
    <h2>{`8. Compensation`}</h2>
    <h3>{`Article 32. (Compensation)`}</h3>
    <ol>
      <li parentName="ol">{`TABF shall compensate the user for any direct and ordinary damage or loss attributable to TABF in connection with the use of the Service, unless the damage or loss is caused by intentional or gross negligence on the part of TABF.`}</li>
      <li parentName="ol">{`If TABF suffers any damage or loss as a result of a user's violation of these Terms, the user shall be liable to compensate TABF. If TABF incurs expenses (including attorney's fees, witness' fees, evidence collection fees, and other reasonable costs associated with the conduct of a lawsuit) due to a dispute between a user and a third party or for any other reason attributable to the user, TABF may hold the user liable for such expenses even before the actual burden of the costs has been incurred.`}</li>
    </ol>
    <h2>{`9. Miscellaneous`}</h2>
    <h3>{`Article 33. (Intellectual Property and other Copyrights)`}</h3>
    <ol>
      <li parentName="ol">{`The copyright of the programs, software, etc. of the Service belongs to TABF or the third party that owns the copyright. Unless otherwise stipulated in the Terms, users may not use the provided content without the consent of TABF or the third party who owns the copyright.`}</li>
      <li parentName="ol">{`In the event that a dispute with a third party arises due to a violation of this article, the user shall be responsible at their own expense for resolving the dispute and shall not cause any damage, loss, or disadvantage to TABF.`}</li>
    </ol>
    <h3>{`Article 34. (Prohibition of Transfers)`}</h3>
    <p>{`Users may not assign, transfer, or dispose of their position in these Terms, or their rights and obligations to TABF under these Terms, to any third party without the prior written consent from TABF.`}</p>
    <h3>{`Article 35. (Interpretations and Court of Jurisdiction, etc.)`}</h3>
    <ol>
      <li parentName="ol">{`If a question arises regarding the interpretation of these Terms, TABF may determine the interpretation within reason.`}</li>
      <li parentName="ol">{`Any legal relationship between TABF and the user based on these Terms shall be governed by the laws of Japan.`}</li>
      <li parentName="ol">{`The Tokyo Summary Court or the Tokyo District Court shall have exclusive first instance jurisdiction over any disputes arising between TABF and users/exhibitors based on the Terms, depending on the amount stipulated in the case.`}</li>
    </ol>
    <h3>{`Article 36. (Governing Law)`}</h3>
    <p>{`These Terms shall be governed by the laws of Japan.`}</p>
    <h3>{`Supplementary Provisions`}</h3>
    <p>{`These Terms shall apply to all users as of October 14, 2020.`}</p>
    <h3>{`Personal Data Protection`}</h3>
    <p>{`TOKYO ART BOOK FAIR / Director / Naoko Higashi`}</p>
    <h3>{`Where to Contact Regarding Personal Data:`}</h3>
    <p>{`TOKYO ART BOOK FAIR`}<br parentName="p"></br>{`
`}{`Personal Data Contact Window`}<br parentName="p"></br>{`
`}{`E-mail：`}<a parentName="p" {...{
        "href": "mailto:info@tokyoartbookfair.com"
      }}>{`info@tokyoartbookfair.com`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      