export const UNIT = 0.075
export const FURNITURE_SIZE = 0.1
export const CELL_UNIT_X = 1.2
export const CELL_UNIT_Y = 0.9
export const CELL_UNIT_Z = 0.9
export const CELL_UNIT_Z_ROT = 1.2
export const CELL_MARGIN_X = 0.1
export const CELL_MARGIN_Y = 0.1
export const CELL_MARGIN_Z = 0.1

export const furnituresPC = [
  { url: "Geo-01.glb", scale: 0.4 },
  { url: "Geo-02.glb", scale: 1 },
  { url: "Geo-03.glb", scale: 1 },
  { url: "Geo-04.glb", scale: 0.4 },
  { url: "Geo-05.glb", scale: 0.9 },
  { url: "Geo-06.glb", scale: 1 },
  { url: "Geo-07.glb", scale: 0.5 },
  { url: "Geo-08.glb", scale: 1 },
  { url: "Geo-09.glb", scale: 1 },
  { url: "Geo-10.glb", scale: 1 },
  { url: "Geo-11.glb", scale: 1 },
  { url: "Geo-12.glb", scale: 1 },
  { url: "Geo-13.glb", scale: 1 },
]

// Furnitureがテーブル/壁を邪魔しないよう追加したマージン
export const FURNITURE_MARGIN_WALL = 0.2
export const FURNITURE_MARGIN_TABLE = 0
