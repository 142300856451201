import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.en.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A real artists’ book, for which artist Vera Gulikers created the design. Illustrator, photographer, picture editor, ever binder: Gulikers’ name is all over this work. She took full advantage of the Risograph printer at the Jan van Eyck academy, using the fluorescent colours this printing process is known for and that characterise Gulikers’ work in egg tempera, for example. The book combines an overview of forgotten or under-represented female artists from all art historical periods, following the design of art history classic A World History of Art and supplemented by Gulikers’ own materials tests and colour experiments. These tumble over one another in all their brightly coloured magnificence, reinforced by the translucent 60gsm IBO paper, which allows the pages below to join in the fun. The partly erased texts about the female artists (taken from Wikipedia pages) are added to, mirrored, on the reverse sides of the pages, in both a commentary on the names erased from the history of art and as a positive deed: making these names visible once again. This book sewn in a soft cover has an open spine that reveals the waxed thread used for the binding, in the same Risograph colours as the book itself and beautifully hand-sewn. The fact that the cover does not give much in the way of information is entirely appropriate to this art project.`}</p>
    <hr></hr>
    <p>{`Publisher: Jan van Eyck Academie, Maastricht`}<br parentName="p"></br>{`
`}{`Designer: Vera Gulikers, Maastricht`}</p>
    <p>{`Category: Art, photo and architecture books`}<br parentName="p"></br>{`
`}{`Artist: Vera Gulikers`}<br parentName="p"></br>{`
`}{`Editor: Vera Gulikers`}<br parentName="p"></br>{`
`}{`Language: English`}<br parentName="p"></br>{`
`}{`Printer: Charles Nypels Lab, Jan van Eyck Academie, Maastricht`}<br parentName="p"></br>{`
`}{`Binder: Vera Gulikers in association with Charles Nypels Lab, Jan van Eyck Academie, Maastricht`}<br parentName="p"></br>{`
`}{`Font: Ariel (Monotype), Bodoni 72 (ITC)`}<br parentName="p"></br>{`
`}{`Size in mm: 202 x 287 x 7`}<br parentName="p"></br>{`
`}{`Number of pages: 140`}<br parentName="p"></br>{`
`}{`Print run: 100`}<br parentName="p"></br>{`
`}{`Price: € 75`}<br parentName="p"></br>{`
`}{`ISBN: N / a`}<br parentName="p"></br>{`
`}{`Paper for interior: 60gsm IBO One (Igepa)`}<br parentName="p"></br>{`
`}{`Cover: 300gsm Munken Pure Rough (Antalis)`}<br parentName="p"></br>{`
`}{`Binding style: Transparently glued-off spine with front and back cover of four pages sewn in. The book block has chain stitching using waxed threads in two different colours.`}<br parentName="p"></br>{`
`}{`Special features: Risography with different fluorescent inks, a circle is cut out of the cover that consists of 4 pages.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      