import dedent from 'dedent';
import { Texture, Color } from 'three';

export function createMSDFShader(isWebGL2, opt) {
  opt = opt || {};
  var opacity = typeof opt.opacity === 'number' ? opt.opacity : 1;
  var alphaTest = typeof opt.alphaTest === 'number' ? opt.alphaTest : 0.0001;
  var precision = opt.precision || 'highp';
  var color = opt.color;
  var map = opt.map;
  var negate = typeof opt.negate === 'boolean' ? opt.negate : true;

  // remove to satisfy r73
  delete opt.map;
  delete opt.color;
  delete opt.precision;
  delete opt.opacity;
  delete opt.negate;

  return {
    ...opt,
    uniforms: {
      opacity: { type: 'f', value: opacity },
      map: { type: 't', value: map || new Texture() },
      color: { type: 'c', value: new Color(color) }
    },
    vertexShader: isWebGL2 ? dedent`
    #version 300 es
    in vec2 uv;
    in vec4 position;
    uniform mat4 projectionMatrix;
    uniform mat4 modelViewMatrix;
    out vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * position;
    }
    ` : dedent`
    #version 100
    attribute vec2 uv;
    attribute vec4 position;
    uniform mat4 projectionMatrix;
    uniform mat4 modelViewMatrix;
    varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * position;
    }
    `,
    fragmentShader: isWebGL2 ? dedent`
    #version 300 es
    precision ${precision} float;
    uniform float opacity;
    uniform vec3 color;
    uniform sampler2D map;
    in vec2 vUv;
    out vec4 outColor;

    float median(float r, float g, float b) {
      return max(min(r, g), min(max(r, g), b));
    }

    void main() {
      vec3 smpl = ${(negate ? '1.0 - ' : '')} texture(map, vUv).rgb;
      float sigDist = median(smpl.r, smpl.g, smpl.b) - 0.5;
      float alpha = clamp(sigDist/fwidth(sigDist) + 0.5, 0.0, 1.0);
      outColor = vec4(color.xyz, alpha * opacity);
      ${alphaTest === 0 ?
        '' :
        'if (outColor.a < ' + alphaTest + ') discard;' }
    }
    ` : dedent`
    #version 100
    #ifdef GL_OES_standard_derivatives
    #extension GL_OES_standard_derivatives : enable
    #endif
    precision ${precision} float;
    uniform float opacity;
    uniform vec3 color;
    uniform sampler2D map;
    varying vec2 vUv;

    float median(float r, float g, float b) {
      return max(min(r, g), min(max(r, g), b));
    }

    void main() {
      vec3 sample = ${(negate ? '1.0 - ' : '')} texture2D(map, vUv).rgb;
      float sigDist = median(sample.r, sample.g, sample.b) - 0.5;
      float alpha = clamp(sigDist/fwidth(sigDist) + 0.5, 0.0, 1.0);
      gl_FragColor = vec4(color.xyz, alpha * opacity);
      ${alphaTest === 0 ?
        '' :
        'if (gl_FragColor.a < ' + alphaTest + ') discard;' }
    }
    `,
  };
};
