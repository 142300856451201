import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/VabfEvents.en.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SocialShare = makeShortcode("SocialShare");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "502px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "55.91836734693878%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAv/EABYBAQEBAAAAAAAAAAAAAAAAAAIBA//aAAwDAQACEAMQAAABi1ZT2ayJEX//xAAZEAACAwEAAAAAAAAAAAAAAAABAgMREiL/2gAIAQEAAQUCjNMH4MlFDTbOGPX/xAAXEQEAAwAAAAAAAAAAAAAAAAAAAhIx/9oACAEDAQE/AYau/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAMSMf/aAAgBAgEBPwGTFH//xAAaEAABBQEAAAAAAAAAAAAAAAAAAQIQMUGR/9oACAEBAAY/AsKbOcFP/8QAHBABAQEAAgMBAAAAAAAAAAAAAREAQYEhMVFx/9oACAEBAAE/IaHhfpm94cIZULY8uexOy47b4zEnf//aAAwDAQACAAMAAAAQ3x//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREx/9oACAEDAQE/ENBw4f/EABcRAQEBAQAAAAAAAAAAAAAAAAEAETH/2gAIAQIBAT8Q4QU2/8QAHRABAAICAgMAAAAAAAAAAAAAAQARITFRYaGx8P/aAAgBAQABPxCxZom4lFQSbMvY8Sx8ICt+IkykTB7Sp6fqocUFz//Z')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/9a87034a7e1e12fe04333a3eed3c6118/1a3b7/img_1.webp 245w", "/static/9a87034a7e1e12fe04333a3eed3c6118/fb835/img_1.webp 490w", "/static/9a87034a7e1e12fe04333a3eed3c6118/e6387/img_1.webp 502w"],
          "sizes": "(max-width: 502px) 100vw, 502px",
          "type": "image/webp"
        }}></source>{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/9a87034a7e1e12fe04333a3eed3c6118/c168b/img_1.jpg 245w", "/static/9a87034a7e1e12fe04333a3eed3c6118/bb66a/img_1.jpg 490w", "/static/9a87034a7e1e12fe04333a3eed3c6118/1c568/img_1.jpg 502w"],
          "sizes": "(max-width: 502px) 100vw, 502px",
          "type": "image/jpeg"
        }}></source>{`
        `}<img parentName="picture" {...{
          "className": "gatsby-resp-image-image",
          "src": "/static/9a87034a7e1e12fe04333a3eed3c6118/1c568/img_1.jpg",
          "alt": "img 1",
          "title": "img 1",
          "loading": "lazy",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          }
        }}></img>{`
      `}</picture>{`
    `}</span>
    <div className="share__2col">
  <div className="share__2col__icon">
    <SocialShare title={props.pageContext.frontmatter.title} mdxType="SocialShare" />
  </div>
    </div>
    <p>{`オランダ・アムステルダムを拠点に編集やデザインスタジオ、 キュレーター、アーティストユニットとしてなど、多角的に活動するインディペンデントパブリッシャー STUDIO THE FUTURE のクララとヴィンセントのいるスタジオから未明のアムステルダムよりライブ配信します。`}<br />{`
制作現場を拝見しながら、クリエイティビティの源となる RISO 機のこと、アムステルダムでの暮らしについてなど、オランダと日本に縁のある二人ならでは視点で、思いつくままにさまざまに語っていただきます。彼らのスタジオにお邪魔した気分でお楽しみください。`}</p>
    <ul>
      <li parentName="ul">
        <span className="font__small">無料で視聴いただけます。</span>
      </li>
      <li parentName="ul">
        <span className="font__small">
  インターネット回線の状況やサーバー負荷、その他視聴者の PC
  環境により、映像が途切れる、または停止するなど正常に視聴できない事がございます。
        </span>
      </li>
      <li parentName="ul">
        <span className="font__small">
  配信終了後も TOKYO ART BOOK FAIR の youtube
  チャンネルで動画を視聴いただけます。
        </span>
      </li>
    </ul>
    <div {...{
      "className": "content__info"
    }}>{`
  `}<figure parentName="div" {...{
        "className": "content__info__img"
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "280px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAQD/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAABypziKko2zCIH/8QAGxAAAgMAAwAAAAAAAAAAAAAAAQIAESEDEyL/2gAIAQEAAQUCFNLweoqiByjd7CA2/IMGz//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABsQAAIBBQAAAAAAAAAAAAAAAAABEBESITFx/9oACAEBAAY/Ami1mhxihyf/xAAcEAADAAMBAQEAAAAAAAAAAAAAAREhMVFBcYH/2gAIAQEAAT8hRcfRMtyYvTFY4IZRXguWppfhrtHwYyhCZ+iRtn//2gAMAwEAAgADAAAAEFDHAP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QH//EAB0QAAIDAAIDAAAAAAAAAAAAAAERACExUXFBkaH/2gAIAQEAAT8QOFwSB9xHwAN8oFoCJ0sgjBG2Bfb5ligUSyZ4hpUOOHapEPHcvYcM8OOCQIqp/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/d109d72ddf329ee4af56a13e55ebde91/1a3b7/img_2.webp 245w", "/static/d109d72ddf329ee4af56a13e55ebde91/2ae4e/img_2.webp 280w"],
              "sizes": "(max-width: 280px) 100vw, 280px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/d109d72ddf329ee4af56a13e55ebde91/c168b/img_2.jpg 245w", "/static/d109d72ddf329ee4af56a13e55ebde91/79710/img_2.jpg 280w"],
              "sizes": "(max-width: 280px) 100vw, 280px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/d109d72ddf329ee4af56a13e55ebde91/79710/img_2.jpg",
              "alt": "img 2",
              "title": "img 2",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
  `}</figure>{`
  `}<div parentName="div" {...{
        "className": "content__info__title"
      }}>{`
    `}<p parentName="div">{`
      Klara van Duijkeren＆Vincent Schipper（Studio The Future）
      `}<br parentName="p" {...{
            "className": "only-sp"
          }}></br>{`
      `}<span parentName="p" {...{
            "className": "font__xs"
          }}>{`
        (クララ・ヴァン・ダウクレン&ヴィンセント・スキッパー)
      `}</span>{`
    `}</p>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "content__info__text"
      }}>{`
    `}<p parentName="div">{`
      クララ・ヴァン・ダウクレンとヴィンセント・スキッパーは、アムステルダムを拠点とするアーティストユニット。2013年に出版社「スタジオ・ザ・フューチャー」を設立。インスタレーション制作やキュレーション、ワークショップなど、実験的な試みを行いながら出版物を手がける。主なプロジェクトに『A
      Table and A Chair』（2019）、『24H BOOK』（2019）など。
    `}</p>{`
    `}<p parentName="div">{`
      `}<a parentName="p" {...{
            "href": "http://printthefuture.nl",
            "target": "_blank"
          }}>{`
        http://printthefuture.nl
      `}</a>{`
    `}</p>{`
  `}</div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      