import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`『The Grand Projet』は、21 世期の情報デザインの模範例だ。640 ページにわたって世界各地の「グラン・プロジェ」、大規模都市開発を 8 件取り上げ、建築家・都市プランナーケース・クリスティアーンセが率いる多国籍のチームによって比較がなされている。複雑かつ大量のデータや考察は、情報の確かさ、明晰さ、そして比較を容易にするための知的な見せ方を必要とする。情報デザインを担当したヨースト・フローテンスは、それを模範を超える技量で成し遂げ、視覚的にも楽しく魅力的な本を作り出した。明確な意図のもと、プレゼンテーション媒体としての本であることをうまく利用しており、リサーチやデータ比較、図表や 200 近い地図や図面の組み合わせを、すべてデジタルの手法でまとめ上げている。インク、紙、レイアウトや装丁、そして色使いやタイポグラフィ、そして本書の多様な参照システムなどすべてが考え抜かれており、心地よいが、実は複雑な構造を持ったバッハの音楽のような作品を生み出している。印刷や造本も淀みない仕上がりだ。`}</p>
    <hr></hr>
    <p>{`出版社： nai010 publishers, Rotterdam`}<br parentName="p"></br>{`
`}{`デザイン： SJG (Joost Grootens, Carina Schwake, Julie da Silva, Megan Adé), Amsterdam`}</p>
    <p>{`カテゴリー： アート、写真、建築`}<br parentName="p"></br>{`
`}{`編集：Kees Christiaanse, Anna Gasco, Naomi C. Hanakata`}<br parentName="p"></br>{`
`}{`言語：英語`}<br parentName="p"></br>{`
`}{`イラスト：SJG (Joost Grootens, Carina Schwake, Julie da Silva, Megan Adé)`}<br parentName="p"></br>{`
`}{`印刷：Unicum | Gianotten printed media, Tilburg`}<br parentName="p"></br>{`
`}{`製本：Boekbinderij Abbringh, Groningen`}<br parentName="p"></br>{`
`}{`フォント：Lyon (Commercial Type), Neue Haas Unica Pro (Linotype), Ceremony (Optimo)`}<br parentName="p"></br>{`
`}{`サイズ（ミリ）：170 x 240 x 43`}<br parentName="p"></br>{`
`}{`ページ数：640`}<br parentName="p"></br>{`
`}{`発行部数：1,000`}<br parentName="p"></br>{`
`}{`価格：€ 59.95`}<br parentName="p"></br>{`
`}{`ISBN：978 94 6208 480 3`}<br parentName="p"></br>{`
`}{`本文用紙：115gsm Fly® Weiss 05 neu (Papier Union)`}<br parentName="p"></br>{`
`}{`見返し：170gsm Amber Graphic (Antalis)`}<br parentName="p"></br>{`
`}{`装丁：115gsm Wibalin® Buckram 546 Silver Grey (Winter & Company) over 2.5 mm greyboard`}<br parentName="p"></br>{`
`}{`製本方法：糸かがりソフトカバーフラップ付き、薄い厚紙に最小限のチリ`}<br parentName="p"></br>{`
`}{`特記事項：本書のためにスタジオ・ヨースト・フローテンスによってデザインされた 200 点の地図とインフォグラフィックを掲載。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      