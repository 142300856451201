import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/DefaultPage.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`TERMS OF SERVICE 利用規約`}</h1>
    <p>{`一般社団法人東京アートブックフェア（以下「当法人」といいます。）は、当法人が運営するウェブサイト「VIRTUAL ART BOOK FAIR」( `}<a parentName="p" {...{
        "href": "https://virtualartbookfair.com/"
      }}>{`https://virtualartbookfair.com/`}</a>{` )（以下「本サイト」といいます。）に関するサービス（以下「本サービス」といいいます。）の利用と提供に関し、以下の通り利用規約（以下「本規約」といいます。）を定めます。`}</p>
    <h2>{`第 1 章　総則`}</h2>
    <h3>{`第 1 条（本規約の範囲など）`}</h3>
    <ol>
      <li parentName="ol">{`本規約は、本サービスの利用と提供に関する規約を定めたものです。`}</li>
      <li parentName="ol">{`本規約は、本サービスの利用と提供に関し、利用者（次条で定義します）全てに適用されます。`}</li>
      <li parentName="ol">{`当法人は、利用者の事前の承諾を得ることなく、本サイト上における掲載またはメールなど（アプリを通じた連絡など、e-mail に限らず電磁的方法を用いて行われるあらゆる連絡方法を指します。以下同じ。）、当法人が適当と判断する方法で利用者に告知または通知することにより、本規約の全部または一部を変更できるものとします。`}</li>
      <li parentName="ol">{`本規約の全部または一部が変更された場合、本サービスの利用と提供に関しては、変更後の規約が適用されるものとします。`}</li>
    </ol>
    <h2>{`第 2 章　定義`}</h2>
    <h3>{`第 2 条（利用者）`}</h3>
    <p>{`本規約において「利用者」とは、当法人が本サービスで提供する画像、テキスト、デザイン、ロゴ、映像、プログラム、アイディア、情報など（以下、これらを本規約において「コンテンツ」と総称します。）を検索、閲覧または利用する者（次条に定める「出展者」を含みます。）を総称し、本サービスを検索、閲覧または利用した時点で、本規約（本規約第 4 条第 2 項の特約、下位規約、ガイドラインなどを含みます。）の内容をすべて了解・承認したものとみなされます。`}</p>
    <h3>{`第 3 条（出展者）`}</h3>
    <p>{`本規約において「出展者」とは、利用者のうち、当法人が別途定める「出展者に関する規約」（以下「出展者規約」といいます。）に基づき、本サイトで販売活動を行う者をいいます。`}</p>
    <h2>{`第 3 章　サービスの利用`}</h2>
    <h3>{`第 4 条（本サービスの利用）`}</h3>
    <ol>
      <li parentName="ol">{`利用者は、法令、規則、通達並びに本規約および当法人が別途定めるプライバシーポリシー、ヘルプなどに従い、本サービスを利用するものとします。`}</li>
      <li parentName="ol">{`当法人は、利用者による本サービスの利用に関し、本規約のほか、利用者が利用できる他のサービスの特約、その他本規約の下位規約、ガイドラインなどを定めることがあります。本規約の下位規約、ガイドラインなどは本規約の一部として利用者による本サービスの利用に適用されます。`}</li>
    </ol>
    <h3>{`第 5 条（未成年者による利用など）`}</h3>
    <ol>
      <li parentName="ol">{`本サービスにおいて、未成年か否かの判断は原則として日本国の法令に基づいて行います。`}</li>
      <li parentName="ol">{`出展者または利用者が未成年であり、親権者または未成年後見人の承諾を得ずに出展・購入をしていることが判明した場合、当法人は、当該出展者、利用者に事前に通知することなく、商品の発送など取引の履行を停止し、または本サービスに出展する出展者に停止を要請することができます。`}</li>
      <li parentName="ol">{`当法人は、利用可能な本サービスまたは付帯サービスの範囲に年齢制限をかける場合があります。当法人が指定する年齢に達していないにもかかわらず、利用者が年齢制限のある商品を購入し、または本サービスもしくは付帯サービスを利用していることが発覚した場合、当法人は、前項に定める措置の一部または全部を講じることができます。`}</li>
    </ol>
    <h3>{`第 6 条（付帯サービスの追加・変更など）`}</h3>
    <p>{`当法人は、利用者に事前の通知をすることなく、本サービスに付帯するサービス（以下「付帯サービス」といいます。）を追加し、またサービスの内容を変更することがあります。利用者が当該サービスを利用する場合、追加、変更後の当該サービスに適用される規約、ガイドラインなどに従うものとします。`}</p>
    <h3>{`第 7 条　（通知）`}</h3>
    <ol>
      <li parentName="ol">{`本サービスに関する当法人から利用者への通知は、本サービスにかかるウェブサイト上への掲示、またはその他当法人が適切と判断する方法によって行います。`}</li>
      <li parentName="ol">{`利用者は、当法人がメールの送信による通知を行った場合、登録したメールアドレス宛てに当法人がメールを送信し利用者が当該メールを閲覧可能になった時、または当法人がメールを送信してから 24 時間後のいずれか早い時点に当法人からの通知が到達したとみなされることに、予め同意するものとします。`}</li>
      <li parentName="ol">{`商品の購入に関し、当法人が出展者を代行して通知を行う場合があります。`}</li>
    </ol>
    <h3>{`第 8 条（連絡・宣伝）`}</h3>
    <ol>
      <li parentName="ol">{`当法人は、利用者に対し、本サービスに関する意見調査、取引の状況などを調査し、またヒアリングなどを行うため、電話、メールその他の方法により連絡をすることがあります。`}</li>
      <li parentName="ol">{`当法人は、利用者に対し、本サービスに関するキャンペーン告知、広告・宣伝などのために、電話、メール、郵便その他の方法により連絡することがあります。`}</li>
      <li parentName="ol">{`出展者は、利用者に対し、購入した商品の配送、取扱説明、アフターケアなど、当該取引に関する範囲に限り、電話、メール、郵便その他の方法により連絡することがあります。`}</li>
    </ol>
    <h3>{`第 9 条（商品の購入）`}</h3>
    <p>{`利用者が、本サービスにおいて商品やサービスなど（以下単に「商品」といいます。）を購入する場合、第 4 章の規定に従うものとします。`}</p>
    <h3>{`第 10 条（利用の制限）`}</h3>
    <ol>
      <li parentName="ol">{`当法人は、利用者が以下の各号の何れかに該当し、または該当するおそれがある場合、事前に通知、催告することなく、直ちに、当該利用者に対して本サービスの全部または一部の提供を停止します。`}
        <ol parentName="li">
          <li parentName="ol">{`以下に該当する行為を行ったとき`}
            <ul parentName="li">
              <li parentName="ul">{`当法人または当法人の従業員などに対する暴力的な要求`}</li>
              <li parentName="ul">{`当法人または当法人の従業員などに対する法的な責任を超えた不当な要求`}</li>
              <li parentName="ul">{`取引に関して、当法人または当法人の従業員に対する脅迫的な言動を行い、または暴力を用いる行為`}</li>
              <li parentName="ul">{`風説の流布、偽計もしくは威力を用いて当法人の信用を毀損し、または当法人の業務を妨害する行為`}</li>
              <li parentName="ul">{`その他、法令に違反する行為や前各項目に準じる行為`}</li>
            </ul>
          </li>
          <li parentName="ol">{`利用者が支払停止もしくは支払不能の状態に陥ったとき、または破産手続開始、民事再生手続開始、会社更生手続開始、特別清算手続開始その他これに準じる手続きの申立がなされ、もしくは自らかかる申立てを行ったとき`}</li>
          <li parentName="ol">{`その他本サービスを提供することが不適切であると当法人が判断したとき`}</li>
        </ol>
      </li>
      <li parentName="ol">{`当法人は、本条に基づき、本サービスの提供を停止、出展の取り消しその他前項に定める措置を実施したことにより利用者または第三者に生じた損害・結果について、責任を負わないものとします。`}</li>
      <li parentName="ol">{`本条による本サービスの提供の停止は、当法人の利用者に対する損害賠償請求を妨げないものとします。`}</li>
    </ol>
    <h2>{`第 4 章　商品の購入`}</h2>
    <h3>{`第 11 条（売買契約の成立）`}</h3>
    <ol>
      <li parentName="ol">{`利用者は、商品の購入や、サービスの利用などを希望する場合、出展者が設定する販売場所を通じて注文を行うものとします。この場合、利用者は各販売場所が定めるサービス利用規約に従う必要があります。`}</li>
      <li parentName="ol">{`前項の注文が完了するのは、次のいずれかの遅い時点とします。`}
        <ol parentName="li">
          <li parentName="ol">{`利用者が出展者の設定した販売場所において入力・登録した配達先・注文内容・決済方法などを確認した上で注文手続きを行った時点`}</li>
          <li parentName="ol">{`出展者から注文内容を確認する旨のメールなどの連絡が利用者に到達した時点`}</li>
        </ol>
      </li>
      <li parentName="ol">{`利用者と出展者の売買契約が成立するのは次の時点とします。`}
        <ul parentName="li">
          <li parentName="ul">{`注文完了後、利用者が出展者の利用する販売場所が定める決済方法により決済を行い、決済が完了したとき。`}</li>
        </ul>
      </li>
      <li parentName="ol">{`利用者は、売買契約に基づき発生した権利・義務を第三者に譲渡し、また担保提供その他の処分をすることができないものとします。`}</li>
      <li parentName="ol">{`本サービスの利用に関して不正行為または不適当な行為があった場合、当法人および出展者は、売買契約を取り消し、解除し、またはその他の適切な措置を取ることができるものとします。`}</li>
    </ol>
    <h3>{`第 12 条（決済方法）`}</h3>
    <p>{`本サービスを通じて購入した商品の支払いまたは決済に関しては、出展者（または出品者が指定した販売場所）が定め、利用者は当該支払い方法などに従うものとします。`}</p>
    <h3>{`第 13 条（不払・支払遅延など）`}</h3>
    <p>{`利用者が、本規約や出展者が指定した各販売場所の利用規約に従って必要な支払いを行わない場合、または当該サービスに入力したクレジットカードや金融機関の口座の利用が停止された場合は、出展者は当該利用者に通知することなく、当該利用者による本サービスの利用を停止することができます。`}</p>
    <h3>{`第 14 条（発送・引き渡し）`}</h3>
    <p>{`商品の発送は、原則として出展者が行うものとします。`}</p>
    <h3>{`第 15 条（引き渡しの完了）`}</h3>
    <p>{`商品は、利用者が注文時に指定した場所に納品するものとし、当該納品をもって商品の引渡しが完了したものとします。`}</p>
    <h3>{`第 16 条（注文の撤回および取り消し）`}</h3>
    <p>{`注文の撤回および取り消しは、各販売場所の利用規約に従います。`}</p>
    <h3>{`第 17 条（返品・交換など）`}</h3>
    <ol>
      <li parentName="ol">{`商品の返品および交換は、出展者が定めた販売場所における利用規約に従います。`}</li>
      <li parentName="ol">{`前項の規定に基づき、商品の返品および交換が受け付けられた場合には、利用者（購入者）の責任において商品の返送を行うものとします。なお、返品の場合、当該商品の購入に際して取得したポイントなどは、発行主に返還するものとします。`}</li>
      <li parentName="ol">{`代金の返金は、本条第 1 項、および第 2 項に基づく商品の返品を受け付けたのち、出展者が対応します。`}</li>
      <li parentName="ol">{`利用者が正当な理由なく商品を当法人または出展者に返送した場合、当法人または出展者はこれを受け取る義務はありません。`}</li>
      <li parentName="ol">{`当法人は、売買契約成立時から起算して 30 日間に限り商品を保管するものとします。なお、当法人は、当該保管期間内に利用者から商品の受け取りに関する指示を受けた場合、当該商品について現状有姿で引渡すものとし、当該商品の状態（変質、変形、消耗、毀損または腐敗などを含みますがこれらに限りません。）について一切責任を負わないものとします。`}</li>
      <li parentName="ol">{`当法人は、前項の保管期間経過後、当該商品の保管期間が終了した旨を利用者に対して通知することなく、廃棄その他当法人が定める処分方法により当該商品を処分するものとします。この場合、当法人は利用者に対して一切の責任を負わないものとします。`}</li>
    </ol>
    <h3>{`第 18 条（商品の所有権）`}</h3>
    <ol>
      <li parentName="ol">{`本サービスが直接販売する以外の商品の所有権は、出展者にあります。また、商品の所有権の移転時は、それぞれのサービスの利用規約に従います。`}</li>
    </ol>
    <h3>{`第 19 条（販売価格）`}</h3>
    <ol>
      <li parentName="ol">{`本サイト内における商品の価格表示は、その表示を行い始めた時点の販売価格であり、その後価格が変動することがあります。`}</li>
      <li parentName="ol">{`人為的なミスなどにより、本サイト内の表示価格が実際の販売価格と異なって表示された場合には、実際の販売価格が優先して適用されるものとします。この場合に紛争が生じた場合には、原則として利用者と出展者との間で解決するものとします。ただし、当法人の判断により、当法人も協議に入ることができるものとします。`}</li>
    </ol>
    <h3>{`第 20 条（商品に関するトラブル）`}</h3>
    <p>{`利用者と出展者の間で商品に関してトラブルが発生した場合には、当該利用者と出展者との間で解決するものとします。ただし、当法人の判断により、当法人が協議に入ることができるものとします。`}</p>
    <h3>{`第 21 条（商品に関する免責）`}</h3>
    <ol>
      <li parentName="ol">{`当法人は、本サービスおよび本サービスを通じて販売する商品につき、その品質、材質、機能、性能、他の商品との適合性その他の欠陥などについて、いかなる保証・負担・責任を負わないものとします。また、これらが原因となり生じた損害、損失、不利益などについても、一切の責任を負わないものとします。`}</li>
      <li parentName="ol">{`配送先の不明などによるトラブルに関しては、当該利用者・出展者間で解決するものとします。ただし、当法人の判断により、当法人が協議に入ることができるものとします。`}</li>
      <li parentName="ol">{`本サービスで掲載する商品はオンラインであることの特性などにより微妙なニュアンス・質感・色合いなどが表現できず、実際の商品と異なって見える場合があります。このような場合であっても、当法人は、いかなる保証を行わず、また責任を負わないものとします。`}</li>
    </ol>
    <h2>{`第 5 章 利用者の責務`}</h2>
    <h3>{`第 22 条（利用環境の整備）`}</h3>
    <ol>
      <li parentName="ol">{`利用者は、本サービスを利用するために必要な通信機器、ソフトウェア、通信回線などの一切を自己の責任と費用をもって準備し、本サービスを利用するために必要となる全ての環境の整備および維持管理を行うものとします。`}</li>
      <li parentName="ol">{`当法人は、利用者の使用する本サービスの利用に供する装置、ソフトウェアまたは通信網の瑕疵、障害、動作不良、もしくは不具合により、利用者に損害が生じたときであっても一切の責任を負いません。`}</li>
    </ol>
    <h3>{`第 23 条（出展者が設定する販売場所における、利用者 ID およびパスワードの管理責任）`}</h3>
    <p>{`出展者が設定する販売場所における利用者の ID およびパスワードの管理に関しては、それぞれのサービスの利用規約に従い、利用者が適切に行うものとします。`}</p>
    <h3>{`第 24 条（免責事項）`}</h3>
    <ol>
      <li parentName="ol">{`本サービスが他のウェブサイトもしくはリソースへのリンク、または第三者のウェブサイトもしくはリソースが本サービスへのリンクを提供している場合、当法人は、当該リンク先の内容、利用およびその結果（適法性、有効性、正確性、確実性、安全性、最新性および完全性を含みますが、これらに限られません。）について、いかなる責任も負わないものとします。なお、当法人は、リンク先のウェブサイトまたはリソースの内容が、違法または本サービスの管理・運営上不適切であると合理的に判断した場合には、利用者に何らの通知を要することなく、当該リンク先を削除することができるものとします。`}</li>
      <li parentName="ol">{`本サービス中に広告（懸賞広告を含みますが、これに限りません。）または宣伝を行っている広告主との取引（懸賞などのプロモーションへの参加を含みますが、これに限りません。）がある場合、利用者は、自らの判断と責任により、当該広告主との間で取引を行うものであり、これに関して当法人は一切責任を負わないものとします。商品の代金の支払、契約条件の決定、保証、担保責任、ライセンスの有無などの取引に関する内容・条件は、一切、当法人が保証するものではなく、当法人は、本サービス中に掲載されている広告または宣伝を経由して行われる取引に起因して利用者に生じた損害については一切責任を負わないものとします。`}</li>
      <li parentName="ol">{`当法人は、以下のいずれかの事由があると判断した場合、一時的に本サービスを停止、中断、中止または変更することができるものとし、利用者が直接的または間接的に被った一切の損害、損失、不利益などについて、いかなる責任も負わないものとします。`}
        <ol parentName="li">
          <li parentName="ol">{`火災、地震、洪水、落雷、大雪などの天災地変が生じた場合`}</li>
          <li parentName="ol">{`戦争、内乱、テロ、暴動、騒乱などの社会不安が生じた場合`}</li>
          <li parentName="ol">{`当法人が契約している電話会社、運送会社またはプロバイダから適切なサービスを受けられなかった場合`}</li>
          <li parentName="ol">{`本サービスにかかるコンピューターシステムの保守点検または更新を行う場合`}</li>
          <li parentName="ol">{`当法人が技術的に対応不可能な事由による場合`}</li>
          <li parentName="ol">{`その他、当法人がサービスの提供を困難と判断した場合`}</li>
        </ol>
      </li>
      <li parentName="ol">{`当法人は、本規約に従って事務を処理することにより、当法人の債務を履行し免責されるものとします。`}</li>
      <li parentName="ol">{`利用者や出展者が、本サービスを利用することにより、他の利用者または第三者に対して何らかの損害などを与えた場合には、当該利用者はその責任と費用においてこれを解決し、当法人には一切の損害、損失、不利益などを与えないものとします。`}</li>
    </ol>
    <h3>{`第 25 条（禁止事項）`}</h3>
    <p>{`利用者は、以下の行為を一切行ってはならないものとします。万一、これに違反して当法人または第三者に損害が生じた場合、当該利用者がその損害を全て賠償する責任を負うものとします。`}</p>
    <ol>
      <li parentName="ol">{`他の利用者や出展者、それ以外の第三者、もしくは当法人に迷惑、不利益もしくは損害を与える行為、またはそれらのおそれのある行為`}</li>
      <li parentName="ol">{`他の利用者や出展者、それ以外の第三者、もしくは当法人の著作権などの知的財産権、肖像権、人格権、プライバシー権、パブリシティ権その他の権利を侵害する行為またはそれらのおそれのある行為`}</li>
      <li parentName="ol">{`本サービスを商業目的で利用する行為（ただし、当法人が予め認めたものは除きます）。`}</li>
      <li parentName="ol">{`公序良俗に反する行為その他法令に違反する行為またはそれらのおそれのある行為`}</li>
      <li parentName="ol">{`虚偽または誤解を招くような内容を含む情報を登録、掲載する行為`}</li>
      <li parentName="ol">{`本サービスを通じて入手したコンテンツを利用者、出展者が私的使用の範囲外で使用する行為`}</li>
      <li parentName="ol">{`他の利用者や出展者、それ以外の第三者を介して、本サービスを通じて入手したコンテンツを複製、販売、出版、頒布、公開およびこれらに類似する行為`}</li>
      <li parentName="ol">{`他の利用者や出展者の個人情報を不当に収集、蓄積または保存する行為`}</li>
      <li parentName="ol">{`コンピューターのソフトウェア、ハードウェア、通信機器の機能を妨害、破壊、制限するように設計されたコンピューターウィルス、コンピューターコード、ファイル、プログラムなどのコンテンツを本サービスにアップロードしたり、メールなどの手段で送信したりする行為`}</li>
      <li parentName="ol">{`その他当法人の信用を毀損・失墜させるなど当法人が不適当であると合理的に判断する行為`}</li>
    </ol>
    <h3>{`第 26 条（利用の制限）`}</h3>
    <ol>
      <li parentName="ol">{`当法人は、利用者が以下の各号の何れかに該当し、または該当するおそれがある場合には、事前に通知、催告することなく、直ちに、当該利用者に対して本サービスの全部または一部の提供を停止し、出展者については出展登録を取り消すことができるものとします。`}
        <ol parentName="li">
          <li parentName="ol">{`前条（禁止事項）各号に該当し、もしくは本規約の何れかの条項に違反し、または当法人の本規約に基づく指示に従わないとき`}</li>
          <li parentName="ol">{`利用者が以下に該当する行為を行ったとき 1.当法人または当法人の従業員などに対する暴力的な要求 2.当法人または当法人の従業員などに対する法的な責任を超えた不当な要求 3.取引に関して、当法人または当法人の従業員に対する脅迫的な言動を行い、または暴力を用いる行為 4.風説の流布、偽計もしくは威力を用いて当法人の信用を毀損し、または当法人の業務を妨害する行為 5.その他、法令に違反する行為や前各項目に準じる行為`}</li>
          <li parentName="ol">{`その他本サービスを提供することが不適切であると当法人が判断したとき`}</li>
        </ol>
      </li>
      <li parentName="ol">{`当法人は、本条に基づいて本サービスの提供を停止し、また利用者について前項に定める措置を実施したことにより利用者および第三者に生じた損害・結果につき、何ら責任を負わないものとします。`}</li>
      <li parentName="ol">{`本条による本サービスの提供の停止は、当法人の利用者に対する損害賠償請求を妨げないものとします。`}</li>
    </ol>
    <h2>{`第 6 章 本サービスの中断・廃止など`}</h2>
    <h3>{`第 27 条（本サービスの中断）`}</h3>
    <ol>
      <li parentName="ol">{`当法人は、本サービスの安定的な運営に努めますが、本サービスの稼動状態を良好に保つため、以下各号の何れかに該当する場合には、 利用者に事前に通知を行うことなく、一時的に本サービスの提供の全部または一部を停止または中止することができるものとします。`}
        <ol parentName="li">
          <li parentName="ol">{`本サービス提供のためのコンピューターシステム（以下「システム」といいます。）の定期保守および緊急保守の場合`}</li>
          <li parentName="ol">{`火災、地震、洪水、落雷、大雪などの天災地変により、システムの運用が困難になった場合`}</li>
          <li parentName="ol">{`戦争、内乱、テロ、暴動、騒擾などの社会不安により、システムの運用が困難になった場合`}</li>
          <li parentName="ol">{`システムの不良および第三者からの不正アクセス、コンピューターウィルスの感染などにより、システムの運用が困難になった場合`}</li>
          <li parentName="ol">{`行政機関・司法機関から相当な根拠に基づき要請を受けた場合`}</li>
          <li parentName="ol">{`その他やむを得ずシステムの停止または中止が必要と当法人が判断した場合`}</li>
        </ol>
      </li>
      <li parentName="ol">{`当法人は、いつでも理由を問わず、利用者へと事前に通知することなく本サービスの全部または一部を停止・中断または廃止することができるものとします。`}</li>
      <li parentName="ol">{`当法人は、本サービスの停止・中断および廃止により利用者に生じた損害について、一切責任を負わないものとします。`}</li>
    </ol>
    <h2>{`第 7 章 情報などの取扱い`}</h2>
    <h3>{`第 28 条（個人情報の利用および保護 ）`}</h3>
    <ol>
      <li parentName="ol">{`当法人は、本サービスを運営するにあたり、以下の個人情報を取得します。`}
        <ol parentName="li">
          <li parentName="ol">{`利用者が本サイトへアクセスしたことを契機として機械的に取得する情報`}
            <ul parentName="li">
              <li parentName="ul">{`IP アドレス、ドメイン名`}</li>
              <li parentName="ul">{`ブラウザの種類・バージョン、オペレーティングシステム・バージョン、使用言語、場所`}</li>
              <li parentName="ul">{`端末情報（PC、携帯電話、スマートフォン、携帯のキャリア、機種情報、個体識別情報など）`}</li>
            </ul>
          </li>
          <li parentName="ol">{`利用者が、当法人が主催あるいは共催するキャンペーンに応募し、または当法人に対しその他のお取引を申し込んだ場合に、利用者の氏名、住所、電話番号、メールアドレスその他キャンペーンの実施またはその他のお取引に必要な情報`}</li>
          <li parentName="ol">{`当法人が利用者よりご意見、ご要望、お問合せなどを受けた場合に、当該お問合せなどの内容、氏名、メールアドレス、その他の対応に必要な情報`}</li>
          <li parentName="ol">{`指定 ID など、個人別に付与された番号・記号その他の符号`}</li>
          <li parentName="ol">{`その他当法人が適法に取得または保有する個人情報`}</li>
        </ol>
      </li>
      <li parentName="ol">{`当法人は、前項に定める個人情報を、当法人の「プライバシーポリシー」に従って取り扱うものとします。`}</li>
    </ol>
    <h3>{`第 29 条（情報の閲覧など）`}</h3>
    <p>{`当法人は、別途定めた「プライバシーポリシー」に基づき、必要な範囲において、本サービスの運営にかかる全ての情報の閲覧、保存、または第三者に対する開示を行うことがあります。当法人は、これによって生じたいかなる損害についても責任を負わないものとします。`}</p>
    <h3>{`第 30 条（情報の管理など）`}</h3>
    <ol>
      <li parentName="ol">{`当法人は、その裁量により、当法人および当法人と提携するサイトにおいて、本サービスに関連して利用者が発信したコンテンツの全部または一部を自由に利用できるものとします。当該コンテンツを利用するにあたり、当法人は投稿者に対し、その名目を問わず一切の支払いを必要としないものとします。`}</li>
      <li parentName="ol">{`当法人は利用者のアクセス履歴および利用状況の調査のため、また利用者へのサービス向上のため、利用者のアクセス履歴に関する以下の情報を収集します。なお、当法人が発行するクッキーは個人情報の収集やその他の目的には 一切使用致しません。`}
        <ol parentName="li">
          <li parentName="ol">{`利用者が本サービスのサーバーにアクセスする際の IP アドレスまたは携帯端末の機体識別番号に関する情報`}</li>
          <li parentName="ol">{`当法人が、クッキーの技術（Web ブラウザを通じてユーザーのコンピューターに一時的にデータを書き込んで利用者が最後にサイトを訪れた日時、そのサイトの訪問回数などを記録保存する技術をいいます。）を通じて取得する利用者のアクセス情報`}</li>
          <li parentName="ol">{`利用者がクッキーを拒否する旨の設定を行った場合、本サービスの利用が制限されることがあります。`}</li>
        </ol>
      </li>
    </ol>
    <h3>{`第 31 条（利用記録の集計など）`}</h3>
    <p>{`当法人は、利用者の利用記録の集計、分析を行い、個人が識別・特定できないように加工、集計し、当法人の運営する他のサービスの開発などの業務の遂行のために利用、処分できるものとします。`}</p>
    <h2>{`第 8 章 損害賠償`}</h2>
    <h3>{`第 32 条（損害賠償）`}</h3>
    <ol>
      <li parentName="ol">{`当法人は、本サービスの利用に関し、当法人の責めに帰すべき事由によって利用者に損害が発生した場合には、当法人の故意または重過失による場合を除き、利用者に現実に発生した直接かつ通常の損害を賠償するものとします。`}</li>
      <li parentName="ol">{`利用者が、本規約に定める事項に違反し、当法人が損害を被った場合には、当該利用者は、当法人に対して当該損害を賠償する責任を負うものとします。なお、当法人が、利用者と第三者との紛争、その他利用者の責めに帰すべき事由に起因して費用（弁護士費用、証人費用、証拠収集費用およびその他の訴訟遂行上の合理的費用を含みます。）を負担する場合には、当法人は、現実に負担が生じた前であっても、その費用を損害の一部として利用者に請求することができるものとします。`}</li>
    </ol>
    <h2>{`第 9 章 雑則`}</h2>
    <h3>{`第 33 条（著作権などの知的財産権）`}</h3>
    <ol>
      <li parentName="ol">{`本サービスのプログラム、ソフトウェアなどの著作権は当法人または著作権を所有する第三者に帰属するものとします。利用者は、本規約に別段定めがある場合を除き、提供コンテンツを当法人または著作権を所有する第三者の許諾を得ることなく使用することはできません。`}</li>
      <li parentName="ol">{`本条の規定に違反して第三者との間で何らかの紛争が生じた場合、利用者はその責任と費用において、かかる紛争を解決するとともに、当法人に何らの損害、損失または不利益などを与えないものとします。`}</li>
    </ol>
    <h3>{`第 34 条（譲渡などの禁止）`}</h3>
    <p>{`利用者は、当法人の書面による事前の承諾を得た場合を除き、本規約上の地位並びに本規約に基づく当法人に対する権利および義務を第三者に譲渡し、承継させ、または処分することはできないものとします。`}</p>
    <h3>{`第 35 条（解釈および管轄裁判所など）`}</h3>
    <ol>
      <li parentName="ol">{`本規約の解釈をめぐって疑義が生じた場合、当法人は合理的な範囲でその解釈を決定できるものとします。`}</li>
      <li parentName="ol">{`本規約に基づく当法人と利用者との間に生じる一切の法律関係については、全て日本国の法令が適用されるものとします。`}</li>
      <li parentName="ol">{`本規約に基づく当法人と利用者、出展者との間に生じる一切の紛争の解決については、その訴額に応じて東京簡易裁判所または東京地方裁判所を第一審の専属的合意管轄裁判所とします。`}</li>
    </ol>
    <h3>{`第 36 条（準拠法）`}</h3>
    <p>{`本規約に関する準拠法は、日本法とします。`}</p>
    <h3>{`附則`}</h3>
    <p>{`本規約は 2020 年 10 月 14 日から全ての利用者に適用されるものとします。`}</p>
    <h3>{`個人情報保護管理者`}</h3>
    <p>{`一般社団法人東京アートブックフェア　代表理事　東直子`}</p>
    <h3>{`個人情報についてのお問い合わせ先`}</h3>
    <p>{`一般社団法人東京アートブックフェア`}<br parentName="p"></br>{`
`}{`個人情報のお問い合わせ窓口`}<br parentName="p"></br>{`
`}{`E-mail：`}<a parentName="p" {...{
        "href": "mailto:info@tokyoartbookfair.com"
      }}>{`info@tokyoartbookfair.com`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      