import React from "react"
import css from "./SocialShare.module.scss"

//export default function SocialShare() {
export default function SocialShare(props) {
  const url =  typeof window !== "undefined" && window.location.href

  return (
    <div className={css.share}>
      <p className={css.share__title}>Share the link:</p>
      <ul className={css.share__list}>
        <li><a href={`https://twitter.com/share?url=${url}&text=${props.title}`} rel="nofollow noreferrer" target="_blank"><i aria-label="Twitter" className="icon-twitter"></i></a></li><li><a href={`https://www.facebook.com/share.php?u=${url}`} rel="nofollow noreferrer" target="_blank"><i aria-label="Facebook" className="icon-facebook"></i></a></li>
      </ul>
    </div>
  )
}
