import React, {
  useEffect,
  useState,
  useCallback,
  MouseEventHandler,
} from "react"
import { useEvents } from "../hooks"
import css from "./Helper.module.scss"
import { FormattedMessage } from "react-intl"
import langSwitchCss from "./LanguageSwitcher.module.scss"
import { Area } from "../commonTypes"
import Counter from "./Counter"

type HelperContentEntranceProps = Readonly<{
  goToFoyerSound: MouseEventHandler
}>

type HelperContentProps = Readonly<{
  goToWorld: MouseEventHandler
}>

const ContentEntranceEn: React.FC<HelperContentEntranceProps> = ({
  goToFoyerSound,
}) => (
  <div>
    <div className={langSwitchCss.base}>
      <a href={"/"}>JA</a>
    </div>
    <div className={css.exihibitor__head}>
      <h1 className={css.exihibitor__heading}>Welcome to VABF!</h1>
      <h2 className={css.exihibitor__counter}>
        <Counter lang={`en`} />
      </h2>
    </div>
    <div className={css.exihibitor__body}>
      <div className={css.closingText}>
        <p>
          Thank you for visiting our first ever VIRTUAL ART BOOK FAIR! We cannot
          thank enough our exhibitors, visitors, sponsors, supporters, artists,
          designers and curators who were involved in the programs, web
          programmers and space designers who created VABF website, all those
          who supported us through our crowdfunding project “VABF KIOSK” and our
          dear friends and family who are always on our sides to support us, for
          their generous contributions.
        </p>

        <p>
          Most of the contents on the website will remain as an archive and we
          hope that you will keep enjoying all of the exhibitor’s booths,
          exhibitions and events.
        </p>
      </div>

      <div className={css.exihibitor__inner}>
        <figure className={css.exihibitor__img}>
          <img
            src={`/images/helper/helper-entrance-a.gif`}
            width={`354`}
            height={`236`}
            alt=""
          />
        </figure>
        <p className={css.exihibitor__text}>
          You can move around the venue with the ↑↓ ← → or WASD keys on your PC.
          On a touch device, try tapping the arrow buttons.
        </p>
      </div>
      <div className={css.exihibitor__inner}>
        <figure className={css.exihibitor__img}>
          <img
            src={`/images/helper/helper-entrance-b.gif`}
            width={`354`}
            height={`236`}
            alt=""
          />
        </figure>
        <p className={css.exihibitor__text}>
          When you stumble upon a booth or an object that grabs your attention,
          touch it! You can also navigate through different pages via MENU
          located on the top right section of the screen.
        </p>
      </div>
      <ul className={`btn__wrap`}>
        <li>
          <button
            onClick={goToFoyerSound}
            data-sound={`true`}
            className={`btn`}
          >
            <FormattedMessage id="enter" />
          </button>
        </li>
        <li className={`btn__item__mt`}>
          <a
            href={`/`}
            onClick={goToFoyerSound}
            data-sound={`false`}
            className={`link__underline__revers`}
          >
            <FormattedMessage id="enterWithoutSound" />
          </a>
        </li>
      </ul>
      <div>
        <ul className={css.closingNote}>
          <li>
            The website will be updated at the end of November, 2020 for the
            last time and that version will become the archive.
          </li>
          <li>
            Please be aware that some of the books or items on the exhibitors’
            booths may be sold out.
          </li>
          <li>
            You will find the exhibitors’ website and social media on each of
            their pages. Please check those links for the latest information.
          </li>
        </ul>
      </div>
    </div>
  </div>
)

const ContentEntranceJa: React.FC<HelperContentEntranceProps> = ({
  goToFoyerSound,
}) => (
  <div>
    <div className={langSwitchCss.base}>
      <a href={"/en/"}>EN</a>
    </div>

    <div className={css.exihibitor__head}>
      <h1 className={css.exihibitor__heading}>VABFへようこそ！</h1>
      <h2 className={css.exihibitor__counter}>
        <Counter lang={`ja`} />
      </h2>
    </div>
    <div className={css.exihibitor__body}>
      <div className={css.closingText}>
        <p>
          VIRTUAL ART BOOK
          FAIRにお越しいただきまして、誠にありがとうございました。
          出展者および来場者のみなさま、協賛・協力企業や団体
          ご協力いただいたアーティストやデザイナー、キュレーター、サイトを作ってくださった開発チーム、
          ファンディングプロジェクト「VABF KIOSK」にてご支援いただいた方々
          そして支えてくれた家族や友人たちに心からの感謝を申し上げます。
        </p>
        <p>
          フェア終了後も、コンテンツ（一部を除く）をアーカイブとして残したいと思います。
          引き続き、出展者ブース、展覧会、イベントをご覧ください。
        </p>
      </div>

      <div className={css.exihibitor__inner}>
        <figure className={css.exihibitor__img}>
          <img
            src={`/images/helper/helper-entrance-a.gif`}
            width={`354`}
            height={`236`}
            alt=""
          />
        </figure>
        <p className={css.exihibitor__text}>
          PCの場合、キーボードの上下左右キーか、
          <br />
          WASDキーで会場を移動することができます。 <br />
          タッチデバイスの場合は、
          <br />
          画面上の矢印をタップしてみてください。
        </p>
      </div>
      <div className={css.exihibitor__inner}>
        <figure className={css.exihibitor__img}>
          <img
            src={`/images/helper/helper-entrance-b.gif`}
            width={`354`}
            height={`236`}
            alt=""
          />
        </figure>
        <p className={css.exihibitor__text}>
          気になるブースや物体を見つけたら、
          <br />
          タッチしてみましょう。
          <br />
          画面右上のMENUからもページ移動ができます。
        </p>
      </div>
      <ul className={`btn__wrap`}>
        <li>
          <button
            onClick={goToFoyerSound}
            data-sound={`true`}
            className={`btn`}
          >
            <FormattedMessage id="enter" />
          </button>
        </li>
        <li className={`btn__item__mt`}>
          <a
            href={`/`}
            onClick={goToFoyerSound}
            data-sound={`false`}
            className={`link__underline__revers`}
          >
            <FormattedMessage id="enterWithoutSound" />
          </a>
        </li>
      </ul>

      <div>
        <ul className={css.closingNote}>
          <li>2020年11月末時点の情報をまとめたアーカイブになります。</li>
          <li>
            出展者の方がオンライン上で展示するアートブックやグッズが売り切れている可能性もございます。ご了承ください。
          </li>
          <li>
            出展者の個別ページにて、それぞれのウェブサイトやSNSをご確認いただけます。最新情報は、そちらにてご確認ください。
          </li>
        </ul>
      </div>
    </div>
  </div>
)

const ContentGlobalViewJa: React.FC<HelperContentProps> = ({ goToWorld }) => (
  <div>
    <div className={css.exihibitor__head}>
      <h1 className={css.exihibitor__heading}>EXHIBITORS</h1>
    </div>
    <div className={css.exihibitor__body}>
      <div className={css.exihibitor__inner}>
        <figure className={css.exihibitor__img}>
          <img
            src={`/images/helper/helper-exhibitor-a.gif`}
            alt=""
            width={`354`}
            height={`236`}
          />
        </figure>
        <p className={css.exihibitor__text}>
          世界中から約230組の出展者の方がブースを展開しています。
          タッチして、話しかけてみましょう。
          右下の「虫眼鏡」から、絞り込みや検索もできます。
        </p>
      </div>
      <ul className={`btn__wrap`}>
        <li>
          <button onClick={goToWorld} data-world="1" className={`btn`}>
            入場する
          </button>
        </li>
        <li>
          <button onClick={goToWorld} data-world="0" className={`btn__back`}>
            戻る
          </button>
        </li>
      </ul>
    </div>
  </div>
)

const ContentGlobalViewEn: React.FC<HelperContentProps> = ({ goToWorld }) => (
  <div>
    <div className={css.exihibitor__head}>
      <h1 className={css.exihibitor__heading}>EXHIBITORS</h1>
    </div>
    <div className={css.exihibitor__body}>
      <div className={css.exihibitor__inner}>
        <figure className={css.exihibitor__img}>
          <img
            src={`/images/helper/helper-exhibitor-a.gif`}
            width={`354`}
            height={`236`}
            alt=""
          />
        </figure>
        <p className={css.exihibitor__text}>
          Approximately 230 exhibitors from all over the world present their
          booths. Touch and you can begin a conversation with them. You can also
          utilize the search icon on the bottom right to simply search or to
          refine your search results using various filters.
        </p>
      </div>
      <ul className={`btn__wrap`}>
        <li>
          <button onClick={goToWorld} data-world="1" className={`btn`}>
            Enter
          </button>
        </li>
        <li>
          <button onClick={goToWorld} data-world="0" className={`btn__back`}>
            Go back
          </button>
        </li>
      </ul>
    </div>
  </div>
)

const ContentRafaelJa: React.FC<HelperContentProps> = ({ goToWorld }) => (
  <div>
    <div className={css.rafael__head}>
      <h1 className={css.rafael__heading}>
        RAFAËL <br className={`only-sp`} />
        ROZENDAAL <br className={`only-sp`} />
        EXHIBITION <br />
        SHADOW OBJECTS <br className={`only-sp`} />
        SCULPTURE PARK
      </h1>
    </div>
    <div className={css.rafael__body}>
      <div className={css.rafael__inner}>
        <p>
          ネットアートのパイオニアとして
          <br />
          活躍するオランダ人アーティスト、
          <br />
          ラファエル・ローゼンダールによるデジタル彫刻
          <br />
          「Shadow Object」が並ぶ公園が広がります。
          <br />
          ブラウザ上の公園を自由に動き回って見てください。
        </p>
      </div>
      <ul className={`btn__wrap`}>
        <li>
          <button onClick={goToWorld} data-world="2" className={`btn`}>
            入場する
          </button>
        </li>
        <li>
          <button onClick={goToWorld} data-world="0" className={`btn__back`}>
            戻る
          </button>
        </li>
      </ul>
    </div>
  </div>
)

const ContentRafaelEn: React.FC<HelperContentProps> = ({ goToWorld }) => (
  <div>
    <div className={css.rafael__head}>
      <h1 className={css.rafael__heading}>
        RAFAËL <br className={`only-sp`} />
        ROZENDAAL <br className={`only-sp`} />
        EXHIBITION <br />
        SHADOW OBJECTS <br className={`only-sp`} />
        SCULPTURE PARK
      </h1>
    </div>
    <div className={css.rafael__body}>
      <div className={css.rafael__inner}>
        <p>
          The Dutch artist and pioneer of net art Rafaël Rozendaal presents a
          park filled with digital sculptures called “Shadow Objects.” Feel free
          to wander through the park at your leisure.
        </p>
      </div>
      <ul className={`btn__wrap`}>
        <li>
          <button onClick={goToWorld} data-world="2" className={`btn`}>
            Enter
          </button>
        </li>
        <li>
          <button onClick={goToWorld} data-world="0" className={`btn__back`}>
            Go back
          </button>
        </li>
      </ul>
    </div>
  </div>
)

type HelperProps = Readonly<{
  areaId: Area
  isBadge: boolean
  lang: string
  setBadge: (v: boolean) => void
}>

const Helper: React.FC<HelperProps> = ({ areaId, isBadge, lang, setBadge }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const events = useEvents()

  /**
   * インフォメーションモーダル表示
   */
  const openModal = useCallback(() => {
    setIsModalOpen(true)

    // バッジを消す
    try {
      localStorage.setItem("infoOpened" + areaId, "true")
    } catch {}
    setBadge(false) // インフォメーションのバッジ設定
  }, [])

  const closeModal = useCallback(() => setIsModalOpen(false), [])

  useEffect(() => {
    events.on("openHelpModal", openModal)
    events.on("closeHelpModal", closeModal)

    return () => {
      events.off("openHelpModal", openModal)
      events.off("closeHelpModal", closeModal)
    }
  }, [events])

  /**
   * サウンド切り替え
   * @param {boolean} flag - サウンドオンかオフか
   */
  const setSound = useCallback(
    flag => {
      sessionStorage.setItem("sound", flag) // セッションストレージ登録
      events.emit("toggleAudio", flag)
      events.emit("playBGM", "foyer_world/bgm_entrance")
    },
    [events]
  )

  /**
   * エントランス 入場するボタン（サウンド設定）
   * @param {object} e - イベントオブジェクト
   */
  const goToFoyerSound = useCallback(
    e => {
      const target = e.currentTarget
      events.emit("closeHelpModal") // Helperコンポーネント用イベント　モーダル閉じる
      setSound(target.dataset.sound === "true")
      e.preventDefault()
    },
    [events, setSound]
  )

  /**
   * 各入場するボタン
   * @param {object} e - イベントオブジェクト
   */
  const goToWorld = useCallback(
    e => {
      events.emit("closeHelpModal") // Helperコンポーネント用イベント　モーダル閉じる
      e.preventDefault()
    },
    [events]
  )

  return (
    <div>
      <button
        onClick={openModal}
        className={css.btn__info + ` btn__icon`}
        data-badge={isBadge}
      >
        Information
      </button>
      {isModalOpen && (
        <div className={`modal__full`} data-motion="up-modal">
          <div className={`close__wrap`} data-motion="up-inner-1">
            <button onClick={goToWorld} className={`btn__close`}>
              Close
            </button>
          </div>
          <div className={`modal__inner`} data-motion="up-inner-1">
            {/* 日本語 */}
            {lang === "ja" &&
              (areaId === "entrance" ? (
                <ContentEntranceJa goToFoyerSound={goToFoyerSound} />
              ) : areaId === "booth" ? (
                <ContentGlobalViewJa goToWorld={goToWorld} />
              ) : areaId === "rafael" ? (
                <ContentRafaelJa goToWorld={goToWorld} />
              ) : null)}

            {/* 英語 */}
            {lang === "en" &&
              (areaId === "entrance" ? (
                <ContentEntranceEn goToFoyerSound={goToFoyerSound} />
              ) : areaId === "booth" ? (
                <ContentGlobalViewEn goToWorld={goToWorld} />
              ) : areaId === "rafael" ? (
                <ContentRafaelEn goToWorld={goToWorld} />
              ) : null)}
          </div>
        </div>
      )}
    </div>
  )
}

export default Helper
