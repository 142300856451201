import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/DefaultPage.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`DEEP L`}</h3>
    <p>
  <a href="https://www.deepl.com/translator" target="_blank" className="link__blank">
    https://www.deepl.com/translator
  </a>
  <br />A useful AI translation tool
    </p>
    <h3>{`Planet Express`}</h3>
    <p>
  <a href="https://planetexpress.com/" target="_blank" className="link__blank">
    https://planetexpress.com/
  </a>
  <br />
  Import Service. This service may save you money on shipping costs as they
  consolidate packages from multiple sources in the UK and US and ship them
  using huge discount from their carriers.
    </p>
    <h3>{`Tenso.com`}</h3>
    <p>
  <a href="https://www.tenso.com/en" target="_blank" className="link__blank">
    https://www.tenso.com/en
  </a>
  <br />
  Japan-to-Overseas forwarding service. They deliver packages that cannot be
  shipped overseas from Japan using other services. You may deliver packages
  from multiple shipping sources together.
    </p>
    <h3>{`World Clock`}</h3>
    <p>
  <a href="https://www.timeanddate.com/worldclock/personal.html" target="_blank" className="link__blank">
    https://www.timeanddate.com/worldclock/personal.html
  </a>
    </p>
    <h3>{`Paypal`}</h3>
    <p>
  <a href="https://www.paypal.com/" target="_blank" className="link__blank">
    https://www.paypal.com/
  </a>
  <br />
  Online payment transfer service.
    </p>
    <h3>{`Transfer Wise`}</h3>
    <p>
  <a href="https://transferwise.com/" target="_blank" className="link__blank">
    https://transferwise.com/
  </a>
  <br />
  Online bank-to-bank money transfer service.
  <br />　
    </p>
    <h1>{`List of links of International Shipping from Japan to Overseas`}</h1>
    <h3>{`JAPAN POST`}</h3>
    <p>
  <a href="https://www.post.japanpost.jp/int/information/overview_en.html" target="_blank" className="link__blank">
    https://www.post.japanpost.jp/int/information/overview_en.html
  </a>
    </p>
    <h3>{`Fedex`}</h3>
    <p>
  <a href="https://www.fedex.com/en-us/home.html" target="_blank" className="link__blank">
    https://www.fedex.com/en-us/home.html
  </a>
  <br />
  <a href="https://www.fedex.com/en-us/coronavirus.html" target="_blank" className="link__blank">
    https://www.fedex.com/en-us/coronavirus.html
  </a>
    </p>
    <h3>{`DHL`}</h3>
    <p>
  <a href="https://www.dhl.com/en/dhl_worldwide.html" target="_blank" className="link__blank">
    https://www.dhl.com/en/dhl_worldwide.html
  </a>
  <br />
  <a href="https://www.dhl.com/en/important_information/covid_19.html" target="_blank" className="link__blank">
    https://www.dhl.com/en/important_information/covid_19.html
  </a>
  <br />
  Please ask to DHL customer support directly if you would like to know the
  information about the country whether you can ship or not by DHL. 0120-392-580
  (only from Japan)
    </p>
    <p>{`*`}{` Please refer to the announces be each courier to ship parcel(s) from Japan to overseas as they may change the shipping area and services due to COVID-19 and so on.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      