import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`De Bezige Bij（デ・ベーゼィヒ・ベイ）から出版された、優れた三部作の詩集シリーズ。パールコートが施されたカバーは私たちを貝の中に誘い込み、本を開ける前から主題に触れた気分にさせてくれる。タイトルの 2 文字（zoon ＝息子、zee ＝海）を大きくすることで、強く視覚に訴えるカバーデザインとなっている。本文には落ち着いたサンセリフのスイス・インテルナショナルとよりスタイリッシュなサングブレウ・ヴェルサイユという 2 つの異なるタイポグラフィが用いられているが、グラフィックデザイナーのマイケル・スニッカーはこの 2 つの異なるタイポグラフィを目次でも効果的に使っている。化粧扉から本扉が透けて見えるなど、半透明の紙も必要な場所で効果的に用いられている。ほかの箇所でも、ほとんどわからないほど薄く印刷された背景によって区分けがなされていて、その薄さはまるで、ページのインクが風にさらわれてしまったかのようだ。それが、シンプルかつ効果的なデザインがなされたこの詩集に上品さ（華やかさ、とも言える）を与えている。`}</p>
    <hr></hr>
    <p>{`出版社：De Bezige Bij, Amsterdam`}<br parentName="p"></br>{`
`}{`デザイン：Michaël Snitker, Amsterdam`}</p>
    <p>{`カテゴリー：散文、詩、グラフィックノベル`}<br parentName="p"></br>{`
`}{`編集：Merijn Hollestelle`}<br parentName="p"></br>{`
`}{`言語：オランダ語`}<br parentName="p"></br>{`
`}{`リトグラフ：DPS, Amsterdam`}<br parentName="p"></br>{`
`}{`印刷：Wilco bv | Boeken en Tijdschriften, Amersfoort`}<br parentName="p"></br>{`
`}{`製本：Wilco bv | Boeken en Tijdschriften, Amersfoort`}<br parentName="p"></br>{`
`}{`フォント：Suisse Int’l (Swiss Typefaces), SangBleu Versailles (Swiss Typefaces), Antique Gothic (Production Type)`}<br parentName="p"></br>{`
`}{`サイズ：160 x 240 x 8mm`}<br parentName="p"></br>{`
`}{`ページ数：84`}<br parentName="p"></br>{`
`}{`発行部数：1,000`}<br parentName="p"></br>{`
`}{`価格：€ 21.99`}<br parentName="p"></br>{`
`}{`ISBN：978 94 031 0850 6`}<br parentName="p"></br>{`
`}{`本文用紙：80gsm Munken Premium Cream 19.5 (Arctic Paper)`}<br parentName="p"></br>{`
`}{`カバー用紙：250gsm Curious Metallics Lustre (Antalis)`}<br parentName="p"></br>{`
`}{`製本方法：糸かがりソフトカバーフラップ付き`}<br parentName="p"></br>{`
`}{`特記事項：カバーに Wilco bv | Boeken en Tijdschriften による、銀の箔押し。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      