import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.en.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`De Raddraaier, founded in the heart of Amsterdam in the ‘critical ’70s’, takes us into the heart of Amsterdam’s activist printing scene. This was the printer of works such as Agenda voor Tuig zoals wij (1980) and Asterix en de kernsentrale. Much has changed in 40 years, and these changes are documented in this book written by Yolanda Entius on the activist printer on Tweede Oosterparkstraat. Design and picture editing are by Remco van Bladel and Sandra Kassenaar, resulting in a very well-organised book. The interior is printed in two different blacks, CMYK is used for the last picture section and opaque white creates a subtle effect throughout with an extra layer featuring vintage illustrations, logos and technical drawings of printing presses. The typography is rugged and has deliberately been kept rather coarse, with merciless justification and narrow white margins all round – after all, this is an activist printer. Nevertheless, Van Bladel and Kassenaar’s steady hand is clear throughout. At the back there is a small full-colour section printed on high-gloss paper featuring Raddraaier prints, photographed in situ – or Photoshopped – on the cupboards, racks and walls of the print shop. The cover, screen-printed in white over the top of white offset on black Sirio Ultra Black cardboard, exudes the same atmosphere: rugged, but not macho. A worthy monument.`}</p>
    <hr></hr>
    <p>{`Publisher: drukkerij RaddraaierSSP, Amsterdam`}<br parentName="p"></br>{`
`}{`Designer: Remco van Bladel, Amsterdam; Sandra Kassenaar, Amsterdam`}</p>
    <p>{`Category: Informative nonfiction`}<br parentName="p"></br>{`
`}{`Editor: Eric Brassem, Janny Oei, Machtelt van Thiel, Tom Kooiman`}<br parentName="p"></br>{`
`}{`Language: Dutch`}<br parentName="p"></br>{`
`}{`Photographer: Johannes Schwartz`}<br parentName="p"></br>{`
`}{`Illustrator: Johannes van de Weert`}<br parentName="p"></br>{`
`}{`Image editor: Remco van Bladel, Sandra Kassenaar`}<br parentName="p"></br>{`
`}{`Lithographer: drukkerij RaddraaierSSP, Amsterdam`}<br parentName="p"></br>{`
`}{`Printer: drukkerij RaddraaierSSP, Amsterdam`}<br parentName="p"></br>{`
`}{`Binder: Boekbinderij Patist, Den Dolder`}<br parentName="p"></br>{`
`}{`Font: Volta (Linotype)`}<br parentName="p"></br>{`
`}{`Size in mm: 150 x 210 x 11`}<br parentName="p"></br>{`
`}{`Number of pages: 160`}<br parentName="p"></br>{`
`}{`Print run: 750`}<br parentName="p"></br>{`
`}{`Paper for interior: 90gsm Munken Print White 15 (Antalis), 90gsm UPM Sol Gloss (Igepa)`}<br parentName="p"></br>{`
`}{`Cover: 280gsm Sirio Ultra Black (Fedrigoni)`}<br parentName="p"></br>{`
`}{`Binding style: Sewn Patabind, cover with flaps`}<br parentName="p"></br>{`
`}{`Special features: The cover is printed with two times opaque white. The interior is printed in Pantone Black 4, Pantone Black 6 and opaque white. The last section is printed in CMYK.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      