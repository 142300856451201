import React, { Component } from "react"
import { ModalRoutingContext } from "gatsby-plugin-modal-routing"
import css from "./ExhibitorTemplate.module.scss"
import { Link } from "gatsby"
import Layout from "../layouts/ja"
import ExhibitorDetail from "../components/ExhibitorDetail"
import { decodeHTMLEntities } from "../utils/decodeHTMLEntities"

const BackButton = closeTo => {
  let backLink = closeTo ? closeTo.closeTo : "/"
  return (
    <div className={`back__wrap`}>
      <Link to={backLink} className={`btn__back`}>
        ブースを離れる
      </Link>
    </div>
  )
}

const Content = ({ exhibitor }) => (
  <ExhibitorDetail
    id={exhibitor.exhibitorId}
    name={decodeHTMLEntities(exhibitor.name)}
    icon={exhibitor.icon}
    country={exhibitor.country}
    message={decodeHTMLEntities(exhibitor.profileText)}
    table={exhibitor.table[0]}
    lang="ja"
    labelReadMore={"もっと読む"}
  />
)

class ExhibitorTemplate extends Component {
  constructor(modal, closeTo) {
    super()
    this.state = {
      context: modal.pageContext,
    }
  }

  render() {
    const exhibitor = this.state.context

    return (
      <ModalRoutingContext.Consumer className={css.base}>
        {({ modal, closeTo }) => (
          <div>
            {modal ? (
              <div data-motion="up-inner-1">
                <BackButton closeTo={closeTo} />
                <Content exhibitor={exhibitor} />
              </div>
            ) : (
              <Layout
                location={this.props.location}
                title={`Exhibitor: ${decodeHTMLEntities(exhibitor.name)}`}
                description={decodeHTMLEntities(exhibitor.profileText)}
                ogImage={exhibitor.table[0]}
              >
                <Content exhibitor={exhibitor} />
              </Layout>
            )}
          </div>
        )}
      </ModalRoutingContext.Consumer>
    )
  }
}

export default ExhibitorTemplate
