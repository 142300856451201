import React from "react"
import Layout from "../../layouts/ja"
import css from "./index.module.scss"
import { Link } from "gatsby"

const IndexPage = props => (
  <Layout location={props.location} title={`Fuji Xerox Exhibition`}>
    <section className={css.xerox + ` container`}>
      <div className={css.xerox__content + ` content`}>
        <div className={css.xerox__head + ` content__head`}>
          <h1 className={`haeding__lev1`}>FUJI XEROX ZINE Laboratory</h1>
        </div>

        <article className={css.xerox__article + ` content__2col`}>
          <div className={`content__2col__side`}>
            <div className={`content__title`}>
              <h1 className={`haeding__lev2`}>
                CMYK＋2色が広げる新たなZINEの世界
              </h1>
            </div>
          </div>

          <div className={`editor__content content__2col__main`}>
            <figure>
              <img src="/images/xerox/photo1.jpg" alt="" />
            </figure>
            <p>
              「ZINE制作をされている方必見！」
              <br />
              オンデマンド印刷に最新デザイン技術が登場。
              <br />
              従来のCMYKにシルバー・ゴールド・ホワイト・クリア・ピンク・カスタムレッド（朱色）から2色を追加して印刷が可能。
              <br />
              シルバー・ゴールドはCMYKとの掛け合わせにより無数のメタリック表現も可能になりました。
              <br />
              プロセスカラーの為、色違いのメタリックグラデーションや1ページ内に何色ものメタリックカラーもデザインできます。
              <br />
              もちろん画像をメタリックにして輝かせることも可能。今年からVABFにこの最新技術の特殊色を使って作成された、ZINEが登場。ぜひ探してみてください。
            </p>

            <p>
              <Link to="#campaign" className={css.xerox__link__btn}>
                ZINE無料キャンペーン実施中
              </Link>
            </p>

            <p>
              <a
                href={`https://www.fujixerox.co.jp/product/publishing/promotion/iridesse?from=vabf`}
                target={`_blank`}
                className={`link__blank`}
              >
                富士ゼロックス特殊色専用サイト
              </a>
            </p>
          </div>
        </article>

        <article className={css.xerox__article + ` content__2col`}>
          <div className={`content__2col__side`}>
            <div className={`content__title`}>
              <h1 className={`haeding__lev2`}>
                特殊色ZINE制作
                <br />
                トークセッション動画
              </h1>
            </div>
          </div>

          <div className={`editor__content content__2col__main`}>
            <p>
              ファンディングチームが最新デジタル印刷機の特殊色（シルバー・ゴールド・ピンク）使用したZINE制作を実施。
              <br />
              今回、京都芸術大学教授、後藤繁雄氏ファシリテートのもと、ZINEを制作された祖父江氏、田中氏、田部井氏によるスペシャルトークセッションを動画で公開！
              <br />
              その魅力を語っていただきます。
            </p>

            <div className={css.xerox__movie__wrap}>
              <div className={`youtube__wrap`}>
                <iframe
                  title={`movie1`}
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/9WNbjq4iHxA"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <a
                href={`https://www.youtube.com/watch?v=9WNbjq4iHxA`}
                target={`_blank`}
              >
                Youtube をみる
              </a>
            </div>

            <div className={css.xerox__profile + ` content__info`}>
              <figure class="content__info__img">
                <img src="/images/xerox/photo5.jpg" alt="" />
              </figure>
              <div class="content__info__title">
                <p>祖父江 慎</p>
              </div>
              <div class="content__info__text">
                <p>
                  1959年、愛知県生まれ。有限会社コズフィッシュ代表。並はずれた「うっとり力」をもって、日本のブックデザイン・展覧会の最前線で幅広く活躍。川島小鳥さんとの仕事は「未来ちゃん」（2011年／ナナロク社）、「川島小鳥写真展　未来ちゃん」（2014年／新潟市新津美術館）、「川島小鳥展　境界線で遊ぶ」（2017年／金津創作の森）などがある。過去の仕事をまとめた『祖父江慎＋コズフィッシュ』（パイ　インターナショナル刊）発売中。AGI、TDC会員。
                </p>
              </div>
            </div>

            <div className={css.xerox__profile + ` content__info`}>
              <figure class="content__info__img">
                <img src="/images/xerox/photo6.jpg" alt="" />
              </figure>
              <div class="content__info__title">
                <p>田中 義久</p>
              </div>
              <div class="content__info__text">
                <p>
                  静岡県生まれ。永続性の高い文化的価値創造を理念にデザインを実践している。近年の仕事に東京都写真美術館のVI計画、「Takeo
                  Paper
                  Show」、「第58回ヴェネチア・ビエンナーレ国際美術展日本館」がある。また、飯田竜太（彫刻家）とのアーティストデュオNerholとしても活動。主な個展は「Index」（Foam
                  Fotografiemuseum、アムステルダム）、「Promenade」（金沢21世紀美術館）など。
                </p>
              </div>
            </div>

            <div className={css.xerox__profile + ` content__info`}>
              <figure class="content__info__img">
                <img src="/images/xerox/photo7.jpg" alt="" />
              </figure>
              <div class="content__info__title">
                <p>田部井 美奈</p>
              </div>
              <div class="content__info__text">
                <p>
                  2003年より有限会社
                  服部一成に勤務。’14年に独立、田部井美奈デザインを設立。
                  広告、CI、パッケージ、書籍、雑誌などの仕事を中心に活躍。2019
                  ADC賞受賞。
                </p>
              </div>
            </div>

            <div className={css.xerox__profile + ` content__info`}>
              <figure class="content__info__img">
                <img src="/images/xerox/photo8.jpg" alt="" />
              </figure>
              <div class="content__info__title">
                <p>
                  後藤繁雄 <br class="only-sp" />
                  <span class="font__xs">（トークファシリテーター）</span>
                </p>
              </div>
              <div class="content__info__text">
                <p>
                  編集者、アートプロデューサー、京都芸術大学教授。若手アート作家の発掘・育成に取り組み、ギャラリー/出版社G/P＋abpを主宰。アートブック振興のためNEOTOKYOZINEのプロデュースも行う。また篠山紀信・蜷川実花らの大型美術館巡回展を成功させる。
                </p>
              </div>
            </div>
          </div>
        </article>

        <article
          className={css.xerox__article + ` content__2col`}
          id="campaign"
        >
          <div className={`content__2col__side`}>
            <div className={`content__title`}>
              <h1 className={`haeding__lev2`}>
                特殊色を使ったZINE
                <br />
                無料制作
                <br />
                100名キャンペーン
              </h1>
            </div>
          </div>

          <div className={`editor__content content__2col__main`}>
            <p>
              あなたも特殊色を使った最新のZINEを制作してみませんか？
              <br />
              VABF内特別企画として、抽選で100名様に無料で特殊色を使ったZINE制作を体験いただけるキャンペーンをご用意しました。（5冊まで）
            </p>
            <h2>＜キャンペーン概要＞</h2>
            <p>
              応募受付期間： 2020年11月16日（月）～2020年11月23日（月）
              <br />
              無料ZINE制作有効期限： 2021年1月31日（日）まで
              <br />
              当選者数： 100名（抽選）
              <br />
              応募資格： ZINEのお届け先が日本国内の方に限らせていただきます。
              <br />
              応募方法：
              下記≪キャンペーン申し込み≫より必要情報をご入力の上、ご応募ください。
              <br />
              抽選・発表：
              応募締切後に厳正な抽選のうえ、当選者された方には11月30日（月）にメール配信をもってご連絡いたします。
              <br />
              抽選結果のお問い合せはお答えできませんので、予めご了承ください。
            </p>

            <h2>【制作条件】</h2>
            <p>
              ZINE仕上サイズ： A4サイズ縦（297mm×210mm）​
              <br />
              最大ページ数： 16ページ（8ページ、12ページでも可）
              <br />
              製本形態： 中綴じ製本（2か所ホチキス止め）​
              <br />
              使用用紙： OKトップコート127g/㎡（四六判110kg）コート紙​
              <br />
              デザイン仕様：
              シルバー（下刷り）＋CMYK＋ゴールド（上刷り）の6色印刷​
              <br />
              <span className={`font__small`}>
                ※シルバーの上にCMYKを載せればプロセスカラーのメタリック色が可能​です。
              </span>
              <br />
              特殊色が制作可能なアプリケーション：​Adobe
              Illustrator、Photoshop、InDesign​
            </p>

            <p className={`font__small`}>
              ※当選者の方のうち、希望者には実機を使った事前制作体験会もご用意しております。
              <br />
              (会場：東京/大阪/名古屋　詳細は申し込みサイトに記載）
            </p>

            <a
              href={`https://aws.doculife.jp/usrfxgcs/03/rd`}
              target={`_blank`}
              className={css.xerox__link__btn}
            >
              キャンペーン申し込みはこちら！
            </a>
          </div>
        </article>

        <article className={css.xerox__article + ` content__2col`}>
          <div className={`content__2col__side`}>
            <div className={`content__title`}>
              <h1 className={`haeding__lev2`}>
                特殊色を使ったZINE
                <br />
                事例
              </h1>
            </div>
          </div>

          <div className={`editor__content content__2col__main`}>
            <figure>
              <img src="/images/xerox/photo2.jpg" alt="" />
            </figure>
            <p className={`align__center`}>
              NIGO®×Virgil Abloh　『LV²』（ルイ・ヴィトン社コラボ）
            </p>

            <figure>
              <img src="/images/xerox/photo3.jpg" alt="" />
            </figure>
            <p className={`align__center`}>
              鬼頭健吾＋大庭大介＋髙畠依子　 『Painting Core』
            </p>
          </div>
        </article>

        <article className={css.xerox__article + ` content__2col`}>
          <div className={`content__2col__side`}>
            <div className={`content__title`}>
              <h1 className={`haeding__lev2`}>特殊色デザイン作り方動画</h1>
              <h2 className={`haeding__lev3 only-pc`}>
                右記Youtubeをご覧ください
              </h2>
              <h2 className={`haeding__lev3 only-sp`}>
                下記Youtubeをご覧ください
              </h2>
            </div>
          </div>

          <div className={`editor__content content__2col__main`}>
            <h2>＜機種紹介編＞</h2>
            <h3>特殊色×オンデマンドで広がる世界</h3>
            <div className={css.xerox__movie__wrap}>
              <div className={`youtube__wrap`}>
                <iframe
                  title={`movie1`}
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/px-NvQUiwBw"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <a
                href={`https://www.youtube.com/watch?v=px-NvQUiwBw`}
                target={`_blank`}
              >
                Youtube をみる
              </a>
            </div>

            <h2>＜制作基礎編＞</h2>
            <h3>特殊色の基本的な使い方（ゴールド・シルバー・メタリック）</h3>
            <div className={css.xerox__movie__wrap}>
              <div className={`youtube__wrap`}>
                <iframe
                  title={`movie1`}
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/6KvOMKNOY6w"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <a
                href={`https://www.youtube.com/watch?v=6KvOMKNOY6w`}
                target={`_blank`}
              >
                Youtube をみる
              </a>
            </div>

            <h2>＜制作応用編＞</h2>
            <h3>
              特殊色の効果的な利用方法（ゴールド・シルバー・メタリック・ホワイト・クリア）
            </h3>
            <div className={css.xerox__movie__wrap}>
              <div className={`youtube__wrap`}>
                <iframe
                  title={`movie1`}
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/TQWR-g8zzYk"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <a
                href={`https://www.youtube.com/watch?v=TQWR-g8zzYk`}
                target={`_blank`}
              >
                Youtube をみる
              </a>
            </div>

            <h2>メタリックカラーを使った画像編集を紹介する動画を公開</h2>
            <div className={css.xerox__movie__wrap}>
              <div className={`youtube__wrap`}>
                <iframe
                  title={`movie1`}
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/dk3v4ufZ5u8"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <a
                href={`https://www.youtube.com/watch?v=dk3v4ufZ5u8`}
                target={`_blank`}
              >
                Youtube をみる
              </a>
            </div>

            <a
              href={`https://aws.doculife.jp/usrfxgcs/03/rd`}
              target={`_blank`}
              className={css.xerox__link__btn}
            >
              キャンペーン申し込みはこちら！
            </a>
          </div>
        </article>
      </div>
    </section>
  </Layout>
)

export default IndexPage
