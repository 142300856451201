import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.en.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Pick up a blue ballpoint pen and doodle all over your exercise book. Who hasn’t done this as a child, preferably during lessons at school, in the classroom? Annemarie van Haeringen has created a whole book like this – about Mister Lóng and his love of dragons. The ballpoint pen theme was the starting point for designer Tessa van der Waals, who proves herself a master of this limiting form. The cover, endpapers, interior: everything is executed using a blue ballpoint pen. The typography of the interior corresponds nicely: letters the colour and thickness of ballpoint pen, but regular and sans serif, so text and images retain their own domains. On the cover, an idiosyncratic, ‘edgy’ choice of lettering in metallic green foil provides contrast, preventing the whole from becoming too proper and too blue. All in all a consistent book, brimming with character and unique atmosphere. A story about dragons that is also an invitation to scribble all over your exercise book. Go for it!`}</p>
    <hr></hr>
    <p>{`Publisher: Uitgeverij Leopold, Amsterdam`}<br parentName="p"></br>{`
`}{`Designer: Tessa van der Waals, Amsterdam`}</p>
    <p>{`Category: Books for children and young people`}<br parentName="p"></br>{`
`}{`Editor: Ria Turkenburg`}<br parentName="p"></br>{`
`}{`Language: Dutch`}<br parentName="p"></br>{`
`}{`Illustrator: Annemarie van Haeringen`}<br parentName="p"></br>{`
`}{`Image editor: Ria Turkenburg`}<br parentName="p"></br>{`
`}{`Lithographer: Crossmedia, Amsterdam`}<br parentName="p"></br>{`
`}{`Printer: Wilco Art Books, Amersfoort`}<br parentName="p"></br>{`
`}{`Binder: Wilco Art Books, Amersfoort`}<br parentName="p"></br>{`
`}{`Font: Telerysm mono 2 (Jack Usine Smeltery), Nobel (Dutch Type Library)`}<br parentName="p"></br>{`
`}{`Size in mm: 230 x 280 x 4`}<br parentName="p"></br>{`
`}{`Number of pages: 32`}<br parentName="p"></br>{`
`}{`Print run: 2,500`}<br parentName="p"></br>{`
`}{`Price: € 15.99`}<br parentName="p"></br>{`
`}{`ISBN: 978 90 258 7725 5`}<br parentName="p"></br>{`
`}{`Paper for interior: 170gsm Arcoprint Edizioni 1.3 Avorio (Fedrigoni)`}<br parentName="p"></br>{`
`}{`Endpapers: 170gsm Arcoprint Edizioni 1.3 Avorio (Fedrigoni)`}<br parentName="p"></br>{`
`}{`Binding: 135gsm Magno Gloss (Sappi Europe)`}<br parentName="p"></br>{`
`}{`Binding style: Sewn hardback with flat spine over 2.5 mm greyboard, the first and last pages are the endpapers`}<br parentName="p"></br>{`
`}{`Special features: The interior and the binding are printed in black, Pantone 072 and Pantone Purple. Pantone Yellow is added to the binding and the spine. The green foil stamping (Kurz Luxor 390) by Wilco Art Books.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      