import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.en.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The jury was unanimous in selecting this graphic novel that tells a fictional World War II love story between Dutch Liesbeth and African-American Allied liberator Franklin, based on true stories. The forgotten history of African-American soldiers and their relationships with the Dutch population in the aftermath of the war is told in strong graphic language using two mixed inks: though these do refer to skin colour, their use is not stereotypical. Historical documents linking the fictional story to real history are presented on the insides of pages in Japanese binding. The front matter and end matter are printed in blue and black while the cover is in white and red, creating a reference to the flag of the Netherlands. The fine, felt-like paper used for the interior and the cover gives this book the feel of a historical document, although the painstaking design firmly marks it out as a contemporary book. Thanks in part to the suppleness of the paper, this simple paperback opens with ease.`}</p>
    <hr></hr>
    <p>{`Publisher: ROSE stories / Stichting LovingDay.NL, Amsterdam`}<br parentName="p"></br>{`
`}{`Designer: Lyanne Tonk, Amsterdam`}</p>
    <p>{`Category: Prose, poetry or graphic novels`}<br parentName="p"></br>{`
`}{`Editor: Dorrit Emmer, Kees Ribbens, Leonie Soet`}<br parentName="p"></br>{`
`}{`Language: Dutch, English`}<br parentName="p"></br>{`
`}{`Translator: Rikkert Stuve`}<br parentName="p"></br>{`
`}{`Illustrator: Brian Elstak (colouring by Hedy Tjin and Julian Mostert)`}<br parentName="p"></br>{`
`}{`Editor-in-chief: Maarten van Hinte, Marga Altena`}<br parentName="p"></br>{`
`}{`Producer: Tim Beijer Producties, Utrecht`}<br parentName="p"></br>{`
`}{`Printer:Wilco bv | Boeken en Tijdschriften, Amersfoort`}<br parentName="p"></br>{`
`}{`Binder: Wilco bv | Boeken en Tijdschriften, Amersfoort`}<br parentName="p"></br>{`
`}{`Font: Plantin (Monotype), LL Unica77 (Lineto)`}<br parentName="p"></br>{`
`}{`Size in mm: 155 x 215 x 14`}<br parentName="p"></br>{`
`}{`Number of pages: 160`}<br parentName="p"></br>{`
`}{`Print run: 3,000`}<br parentName="p"></br>{`
`}{`Price: € 16.95`}<br parentName="p"></br>{`
`}{`ISBN: 978 90 83002 85 9`}<br parentName="p"></br>{`
`}{`Paper for interior: 120gsm Soporset Premium Offset (Igepa), 90gsm Munken Premium Cream 19.5 (Arctic Paper)`}<br parentName="p"></br>{`
`}{`Cover: 250gsm Crush Corn (Igepa)`}<br parentName="p"></br>{`
`}{`Binding style: Sewn softcover with flaps`}<br parentName="p"></br>{`
`}{`Special features: The main part of the interior is printed in Pantone 4645, 7520 and black. The first and last section are printed in Pantone 2727 (blue) and black. The flap (cover) is printed in Pantone 032 (red). These red, white and blue colours represent the Dutch flag. There are a number of non-trimmed pages between the normal pages, so that alternately open and closed (Japanese style) pages are created.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      