import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`この本は、ヘンク・ヴィルスフートが難民や移民について扱ったものでは 3 作目となる、切迫した作品集だ。本書が見せるのは、一時的に劣悪な環境に暮らす難民たちが作り出した小さな庭を通して彼らが得る希望、慰め、そして尊厳だ。ヴィルスフートとデザイナーのロビン・ウレマンによって、写真、デザイン、装丁のすべてが完璧に組み合わせられている。難民キャンプの荒れた環境、小さなプランターの中で守られる土壌や植物のイメージは、不確実な世界でしがみつくことができるものへの渇望、そこで育まれる家庭生活を反映したものだ。さまざまな植物、場所、そして状況の関係性は、高さが異なるページが交互に続くことでうまく表現されている。それは次のページのイメージが手前の見開きにも影響することを意味し、とても自然かつ説得力のある効果を生み出している。同じような効果が、異なる方法でカバーにも応用されている。アメリカ式に上部が裏面に、下部が表面に折り込まれることで、それぞれの面の写真が関係性を生んでいる。このカバーに覆われた、本に縫い合わされたマスカットブラウンと黒を用いた表紙は美しいコントラストを生み、格子棚のようなざらついた仕切りとしても機能している。的確な抑制のもと、デザイン、コンセプト、制作を通じて注意深さが貫かれている。選考委員も満場一致でその点を評価した。`}</p>
    <hr></hr>
    <p>{`出版社： Henk Wildschut, Amsterdam`}<br parentName="p"></br>{`
`}{`デザイン： Robin Uleman, Amsterdam`}</p>
    <p>{`カテゴリー： アート、写真、建築`}<br parentName="p"></br>{`
`}{`編集：Vic Joseph, Joost van Beek`}<br parentName="p"></br>{`
`}{`言語：英語`}<br parentName="p"></br>{`
`}{`翻訳：Vic Joseph`}<br parentName="p"></br>{`
`}{`写真：Henk Wildschut`}<br parentName="p"></br>{`
`}{`イメージ編集：Robin Uleman, Henk Wildschut`}<br parentName="p"></br>{`
`}{`編集：Robin Uleman, Vic Joseph`}<br parentName="p"></br>{`
`}{`リトグラフ：Marc Gijzen beeldbewerking & digitale lithografie, Voorburg`}<br parentName="p"></br>{`
`}{`印刷：NPN Drukkers, Breda`}<br parentName="p"></br>{`
`}{`製本：Boekbinderij Patist, Den Dolder`}<br parentName="p"></br>{`
`}{`フォント：New Century Schoolbook STD (Adobe)`}<br parentName="p"></br>{`
`}{`サイズ（ミリ）：190 x 255 x 12`}<br parentName="p"></br>{`
`}{`ページ数：152`}<br parentName="p"></br>{`
`}{`発行部数：750`}<br parentName="p"></br>{`
`}{`価格：€ 39`}<br parentName="p"></br>{`
`}{`ISBN：978 90 825885 1 4`}<br parentName="p"></br>{`
`}{`本文用紙：120gsm Munken Lynx Rough (Antalis)`}<br parentName="p"></br>{`
`}{`カバー：290gsm Muskat Brown (Igepa)`}<br parentName="p"></br>{`
`}{`ダストジャケット：120gsm Wibalin® Buckram 500 White (Winter & Company)`}<br parentName="p"></br>{`
`}{`製本方法：糸かがりソフトカバー、表紙計 4 ページ縫い込み、透明に糊付けされた背の上にダストジャケット`}<br parentName="p"></br>{`
`}{`特記事項：ページによっては地から 16mm 短くなっている。中身は 2 種類のセクション（各 8 ページ）で構成され、各セクションが交互に異なるパターンで折られる仕組みは、通常のページと短いページがもたらす本書のリズムに寄与する。切り付け表紙。CMYK、Pantone 緑で印刷。ダストジャケットは天は内側、地は外側に折られている（アメリカ式折）。カバーは黒で印刷後、オペークホワイトで二度印刷。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      