import React from "react"
import Layout from "../../../../layouts/ja"
import BdbdMenu from "../../../../components/BdbdMenu"
import css from "../index.module.scss"
import ImageSlider from "../../../../components/ImageSlider"

const IndexPage = props => {
  return (
    <Layout location={props.location} title={`Best Dutch Book Exhibition`}>
      <section className={css.bdbd__report + ` container`}>
        <div className={css.bdbd__content + ` content`}>
          <div className={css.bdbd__head + ` content__head`}>
            <h1 className={`haeding__lev1`}>
              BEST DUTCH <br className={`only-sp`} />
              BOOK DESIGNS
            </h1>
          </div>

          <nav className={css.bdbd__localnavi}>
            <BdbdMenu />
          </nav>

          <article className={css.bdbd__section + ` content__2col`}>
            <div className={`content__2col__side`}>
              <div className={`content__title`}>
                <h1 className={`haeding__lev2`}>
                  The judging for The Best Dutch Book Designs 2019
                </h1>
              </div>
            </div>

            <div className={`editor__content content__2col__main`}>
              <p>
                2019年度の「Best Dutch Book
                Designs」の審査は、オランダをはじめ世界の歴史上においても類を見ない状況となる前夜に行われた。2019年に発行された審査対象の278冊のどれにも「コロナウイルス」の文字はない。しかしこの講評が書かれているのは、オランダ全体が「インテリジェントロックダウン」に直面しているときだ。14週目にはアルベール・カミュの『異邦人』がベストセラー60の3位にまで上昇した。#Ikleesthuis（#ireadathome＝家で読む）キャンペーンが大きな話題を呼んだが、3月15日にライターのウィルマ・デ・レクがオランダの新聞『de
                Volkskrant』に寄稿し、「国内の書店のうち3分の1が倒産の危機に瀕していることを明らかにした。これを書いている今、まだ誰も「ニューノーマル」や「1.5メートルの距離を保つ社会」がどのようになるのか、それが本にどのような影響を与えるのかもわかっていない。こんなときに、綴じや紙の選択、蛍光塗料の使用などについて議論することは些細なこと、あるいは不謹慎とすら思われるかもしれない。しかし、しっかりと目を向けてみる価値はあるはずだ。
              </p>
              <ImageSlider>
                <img src="/images/bdbd/jury2.jpg" alt="" />
                <img src="/images/bdbd/selectie3.jpg" alt="" />
              </ImageSlider>

              <p>
                『U bevindt zich
                hier（あなたはここにいる）』は、今回の受賞書籍のひとつで、人々が常に「ON」の状態であり続ける消費者社会、24時間回り続ける経済を記録した一冊。まず目につくのは、誰もソーシャルディスタンスを保っていないということだ。まだ撮影されてから1年も経たないのに、今やそれはまるで別時代の記録のようでもある。ここに記録されたローランズ・フェスティバルも、アムステルダム・ファッション・ウィークも、日用品のフェア、ハウスハウトベールスも、デ・スプーディスの集会も、今年は開催不可能となった。この本のインクが乾かないうちに「ありのままのオランダ」が「もう見ることのできないオランダ」になってしまったのだ。審査が実施されたのは新型コロナウイルスの感染が拡大する前の1月だったが、今はこの視点からしか作品を見ることができなくなってしまった。しかし、見る者に安心感を与えてくれる本もあった。『When
                Red
                Disappear』の、17世紀の名画家をも驚かせるような、ゼーランド地方の水中で撮影された静物写真は、ウイルスの存在しない水中世界へと私たちを引き込む。その時が止まったような色彩世界は、まるでウイルスの及ばない、新型コロナが無効化された崇高な世界のようだ。移民を主題とするヘンク・ヴィルスフートの『Rooted』をはじめとしたその他の作品集も、グローバル化と移動という、ウイルスの拡散に影響を与える重要な問題を扱っている。
              </p>

              <ImageSlider>
                <img src="/images/bdbd/U bevindt zich hier_cover.jpg" alt="" />
                <img src="/images/bdbd/U bevindt zich hier_01.jpg" alt="" />
                <img src="/images/bdbd/U bevindt zich hier_02.jpg" alt="" />
              </ImageSlider>

              <ImageSlider>
                <img src="/images/bdbd/When Red Disappears_cover.jpg" alt="" />
                <img src="/images/bdbd/When Red Disappears_01.jpg" alt="" />
                <img src="/images/bdbd/When Red Disappears_02.jpg" alt="" />
              </ImageSlider>

              <ImageSlider>
                <img src="/images/bdbd/Rooted_cover.jpg" alt="" />
                <img src="/images/bdbd/Rooted_01.jpg" alt="" />
                <img src="/images/bdbd/Rooted_02.jpg" alt="" />
              </ImageSlider>

              <p>
                しかし、選考基準にポストコロナの視点を持ち込むのはフェアではないだろう。先に述べた通り、審査対象の本は2019年に作られたもので、選考も今年1月におこなわれている。本の中で見られる視点や傾向は2019年のものだ。いくつか例を挙げてみよう。まずニュートラルな考察をすると、テキストのページとイメージを掲載するセクションを分けた構成の「レイヤーケーキ」、背表紙がない「コデックス装」の衰えない流行、断裁面を色付けした「小口塗り」、表紙に文字もしくはイメージがない写真集など。一方で、不要かつ扱いづらいスリップケース、インクや素材の無意味な多用、これみよがしな「ペーパーポルノ」など、選考委員は過度な様式美に対しては批判的だった。ポジティブな面で言えば、（半）透明紙を用いた冒険や遊び、数ページ越しにうっすらと透けて見えるタイポロジー、対となるページに鏡像を配置することでイメージを共鳴させる手法などには多くの選考委員が感銘を受けていた。また、例えばベルベットのような質感を断裁面に与えるneobond®など、素材や加工の特色を知的に用いたものも評価されていたのが記憶に残っている。多くの本が、人間と植物、人類と自然の関係性を扱っていた。多様性もテーマの傾向のひとつだったが、そこには自分以外の「他者」の視点や複数の視座が存在していた。（個人的または集団的）アイデンティティに焦点を当てたリサーチは、アーカイブ型手法をとる作品に顕著だった。移住とグローバリゼーションも重要なテーマとして見られた。
              </p>

              <ImageSlider>
                <img src="/images/bdbd/American Origami_cover01.jpg" alt="" />
                <img src="/images/bdbd/American Origami_01.jpg" alt="" />
                <img src="/images/bdbd/American Origami_cover02.jpg" alt="" />
              </ImageSlider>

              <ImageSlider>
                <img
                  src="/images/bdbd/Bryophetes and Lichens of Letterewe_cover.jpg"
                  alt=""
                />
                <img
                  src="/images/bdbd/Bryophetes and Lichens of Letterewe_01.jpg"
                  alt=""
                />
              </ImageSlider>

              <ImageSlider>
                <img src="/images/bdbd/Odstonic_02.jpg" alt="" />
                <img src="/images/bdbd/Odstonic_01.jpg" alt="" />
              </ImageSlider>

              <p>
                今回の受賞作は、デザインや形式、表現や手法を、知的かつ先進的あるいは非常に魅力的な方法でこれらのトピックをつなぎ合わせている。賞賛と興奮と共に、これらの本はある種の癒しも与えてくれる。それは、私たちが今、何よりも必要としているものだろう。
              </p>

              <p>
                2020年4月
                <br />
                エールコ・ファン・ウェーリ（審査委員代表）
              </p>

              <h3>2019年度「Best Dutch Book Designs 」2019審査委員</h3>
              <p>
                ジェレミー・ヤンセン（デザイナー）、マイク・フォンダージェム（デザイナー）、エールコ・ファン・ウェーリ（NAI010
                publishersマネージングディレクター）、マーティン・キッケン（アムステルダム市立美術館アシスタントディレクター）
              </p>

              <p className={`font__small`}>
                ＊応募作品の制作に審査が関わっている場合は、過去の審査員で構成された「シャドーパネル（影の審査員）」によって審査がおこなわれる。今年は18作品がシャドーパネルによって審査され、うち7作品が最終選考に進み（その際も、審査対象となる作品集に関わる審査員は選考から除外された）、その中から最終的に受賞したのは『The
                Grand Project』、『The Kindness of One』の2冊であった。
              </p>

              <h3>2019年度「Best Dutch Book Designs 」シャドーパネル</h3>
              <p>
                ブレジッタ・クレーマー（印刷コンサルタント）、ロン・ファン・ルーン（デザイナー）、ミカエル・スニッカー（デザイナー）、アストリッド・フォルスターマンズ（出版社Valiz代表）
              </p>
            </div>
          </article>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
