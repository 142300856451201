import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.en.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The glossy black ball on the cover of this small volume tells a curious, poignant story. It is a piece of coal, such as those cut and polished in their free time by miners from the city of Yubari on the Japanese island of Hokkaido. A symbol of the heyday of the mines there, which have now closed down. Japanese Dutch artist and designer Mariko Kuwahara stumbled upon this remarkable tradition during a visit to Yubari and became fascinated by the phenomenon, which also gave her a way of coming into contact with the ageing population of this former colliery town.
The book – in English and Japanese – is a report on her investigations. Conversations about the practice and the miners’ pasts are combined with sparingly used documentary photographs. The texts are in black on grey pages, apart from direct quotes from her conversation partners, which are printed in white. The book reads from the right (the Japanese half) and from the left (the English half) to the centre – the heart of the book, which contains a double pull-out showing a number of the coal spheres in all their mysterious glory. White, grey and black are the only colours used, creating an effect of great beauty and force. This is reinforced by the care with which these elements have been assembled. The black edges of the block give even more impact to the simple white of the cover, on which another of these speechless balls gleams, enhanced by yet another great idea: the ball is covered in a Spot UV lacquer, perfectly evoking the greasy feel of the surface of coal. The whole book exudes an aura of wonder, tranquillity and melancholy.`}</p>
    <hr></hr>
    <p>{`Publisher: Torch press, Tokyo (JP)`}<br parentName="p"></br>{`
`}{`Designer: Ayumi Higuchi, Amsterdam`}</p>
    <p>{`Category: Art, photo and architecture books`}<br parentName="p"></br>{`
`}{`Language: English, Japans`}<br parentName="p"></br>{`
`}{`Translator/ vertaler: Ella Rutledge`}<br parentName="p"></br>{`
`}{`Photographer/ fotograaf: Ayako Nishibori, Mariko Kuwahara`}<br parentName="p"></br>{`
`}{`Producer: Jos Morree Fine Books, Weesp`}<br parentName="p"></br>{`
`}{`Lithographer: Wilco Art Books (Mariska Bijl), Amersfoort`}<br parentName="p"></br>{`
`}{`Printer: Wilco Art Books, Amersfoort`}<br parentName="p"></br>{`
`}{`Binder: Wilco Art Books, Amersfoort`}<br parentName="p"></br>{`
`}{`Font: F Grotesk (RP Digital Type Foundry), Yu Gothic (Jiyu Kobo), Yu Gothic Shogo Kana (Jiyu Kobo)`}<br parentName="p"></br>{`
`}{`Size in mm: 115 x 175 x 10`}<br parentName="p"></br>{`
`}{`Number of pages: 104`}<br parentName="p"></br>{`
`}{`Print run: 500`}<br parentName="p"></br>{`
`}{`Price: € 18`}<br parentName="p"></br>{`
`}{`ISBN: 978 4 907562 18 2`}<br parentName="p"></br>{`
`}{`Paper for interior: 100gsm Fluweel 1.5 (Igepa)`}<br parentName="p"></br>{`
`}{`Cover: 300gsm Lessebo Design 1.3 White (Igepa)`}<br parentName="p"></br>{`
`}{`Binding style: Unsewn softcover`}<br parentName="p"></br>{`
`}{`Special features: Spot varnishing is applied to the cover. The fore-edge of the book block is printed in black by Critis, Beernem (BE). The interior is printed in black and Pantone Warm Grey 7.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      