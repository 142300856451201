import React, { useContext } from "react"
import css from "./RecommendBrowser.module.scss"
import { FormattedMessage } from "react-intl"
import { detect } from "detect-browser"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider"
import isMobile from "is-mobile"

function RecommendBrowser() {
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)
  const browser = detect()

  const isRecommendedBrowser =
    browser.name === "crios" ||
    browser.name === "ios" ||
    browser.name === "android" ||
    browser.name === "ios-webview" ||
    browser.name === "chrome" ||
    browser.name === "fxios" ||
    browser.name === "chromium-webview" ||
    browser.name === "facebook" ||
    browser.name === "instagram" ||
    browser.name === "firefox"

  if (!browser || !state || !dispatch) {
    return <></>
  }

  return (
    <>
      {!state.browserAccept && (!isRecommendedBrowser || isMobile()) && (
        <div className={css.base}>
          <p className={css.textBox}>
            {!isRecommendedBrowser && (
              <FormattedMessage id={`recommendChrome`} />
            )}
            {isMobile() && <FormattedMessage id={`recommendPc`} />}
          </p>
          <p className={css.buttonBox}>
            <button
              className={css.buttonOk}
              onClick={() => {
                dispatch({ type: "CHROME_RECOMMENDED_ACCEPT" })
              }}
            >
              <FormattedMessage id={`OK`} />
            </button>
          </p>
        </div>
      )}
    </>
  )
}

export default RecommendBrowser
