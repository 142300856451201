import {
  DirectionalLight,
  Mesh,
  MeshStandardMaterial,
  PerspectiveCamera,
  PlaneBufferGeometry,
  Scene,
  Vector2,
  Vector3,
  WebGLRenderTarget,
  CubeTextureLoader,
  CatmullRomCurve3,
  LineCurve3,
  Fog,
  RGBADepthPacking,
  MeshDepthMaterial,
  DoubleSide,
  Texture,
  Euler,
  AmbientLight,
  Raycaster,
  Box3,
  Object3D,
  Color,
  LinearEncoding,
  MeshBasicMaterial,
  BoxBufferGeometry,
  Sphere,
  Quaternion,
  CubicBezierCurve3,
  PlaneGeometry,
  VideoTexture,
} from "three"
import CameraControls from "../vendor/camera-controls"
import { World } from "./World"
import { SceneObject } from "../types"
import { Loader } from "../Loader"
import type { MoveDir } from "../Game"
import type { EventEmitter } from "events"
import { TextMesh } from "../TextMesh"
import TWEEN from "@tweenjs/tween.js"
import { Ground } from "./Foyer/Ground"
import MovePageConfirmParam from "../../utils/MovePageConfirmParam"
import { Area, getAreaFromUrl } from "../../commonTypes"
import { indexOf } from "lodash"
import { getLang } from "../../utils/getLang"
import { movePageParams } from "./Foyer/movePageParams"
import { MouseWatcher } from "../MouseWatcher"
import isMobile from "is-mobile"
import { loadViewCount } from "../../api"
import { isThisTypeNode } from "typescript"

const START_POS = new Vector3(0, 2.2, 10)
const MODAL_POS = new Vector3(0, 2.2, 22)
const CAMERA_Y_MAX = 3
const CAMERA_Y_MIN = -1.5
const CAMERA_DIST_FROM_FLOOR = 3.7
const DEFAULT_CAMERA_HEIGHT = -0.8

const potatoPos = new Vector3(0, 4.5, -51)

const MOT_CENTER_X = -35
const MOT_CENTER_Z = -62.5
const MOT_WIDTH = 105
const MOT_HEIGHT = 260
const MOT_LEFT = MOT_CENTER_X - MOT_WIDTH / 2
const MOT_RIGHT = MOT_CENTER_X + MOT_WIDTH / 2
const MOT_FRONT = MOT_CENTER_Z + MOT_HEIGHT / 2
const MOT_BACK = MOT_CENTER_Z - MOT_HEIGHT / 2

const RAFAEL_CENTER_X = 150
const RAFAEL_CENTER_Z = 0
const RAFAEL_CELL = 40
const RAFAEL_WIDTH = RAFAEL_CELL * 5
const RAFAEL_HEIGHT = RAFAEL_CELL * 4
const RAFAEL_LEFT = RAFAEL_CENTER_X - RAFAEL_WIDTH / 2
const RAFAEL_RIGHT = RAFAEL_CENTER_X + RAFAEL_WIDTH / 2
const RAFAEL_FRONT = RAFAEL_CENTER_Z + RAFAEL_HEIGHT / 2
const RAFAEL_BACK = RAFAEL_CENTER_Z - RAFAEL_HEIGHT / 2

type MiscOptions = Readonly<{
  url: string
  clickable?: boolean
  offset?: Vector3
  rotation?: Euler
  labelText?: string
  labelOffset?: Vector3
  labelRotation?: Euler
  fontSize?: number
  position?: Vector3
  color?: Color
  colorFactor?: number
  emissive?: Color
  doubleSide?: boolean
  alphaTest?: number
  cameraPos?: Vector3
  pcOnly?: boolean
}>

const miscInfo: Record<string, MiscOptions> = {
  MOT: {
    url: "mot/00_MOT_only.glb",
    clickable: true,
  },
  Lake: {
    url: "mot/00_MOT_lake.glb",
    offset: new Vector3(0, 0.6, 0),
    color: new Color(0xffffff),
  },
  Potato: {
    url: "mot/01_Potato.glb",
    clickable: true,
    labelText: "EXHIBITORS",
    labelOffset: new Vector3(1.2, 1.7, 6),
    fontSize: 1.5,
  },
  PotatoM: {
    url: "mot/02_VABF_Event.glb",
    clickable: true,
    labelText: "VABF EVENT",
    labelOffset: new Vector3(-0.7, -0.3, 3),
  },
  PotatoS: {
    url: "mot/03_Exhibitor_Event.glb",
    clickable: true,
    labelText: "EXHIBITORS' EVENT",
    labelOffset: new Vector3(-1.7, -0.5, 2),
    cameraPos: new Vector3(-2, 0, 10),
  },
  FriedPotato: {
    url: "mot/04_Fries.glb",
    clickable: true,
    labelText: "LOUNGE",
    labelOffset: new Vector3(-1, 0.6, 0),
  },
  SweetPotato: {
    url: "mot/05_SweetPotato.glb",
    clickable: true,
    labelText: "INFORMATION",
    labelOffset: new Vector3(-1.2, 1.1, 0.8),
  },
  PotatoChips: {
    url: "mot/06_PotatoChips.glb",
    clickable: true,
    labelText: "BOOK SIGNING",
    labelOffset: new Vector3(-1, 0.8, 0),
    colorFactor: 0.8,
  },
  Fanfare: {
    url: "mot/07_fanfare.glb",
    clickable: true,
    labelText: "FANFARE",
    labelOffset: new Vector3(0, 0.6, -1.2),
    labelRotation: new Euler(0, Math.PI / 2, 0),
    doubleSide: true, // 布部分のため
  },
  Iridesse: {
    url: "mot/08_iridesse_v2.glb",
    clickable: true,
    colorFactor: 0.75,
    labelText: "FUJI XEROX ZINE LABORATORY",
    labelOffset: new Vector3(-1, 0.8, 0),
  },
  DutchArtistBooks_0: {
    url: "mot/09_DucthArtistbook_0.glb",
    clickable: true,
    labelText: "DUTCH ARTISTS' BOOK",
    labelOffset: new Vector3(-1.8, 1, 1),
    colorFactor: 1,
  },
  DutchArtistBooks_1: {
    url: "mot/09_DucthArtistbook_1.glb",
    clickable: true,
    colorFactor: 1,
    pcOnly: true,
  },
  DutchArtistBooks_2: {
    url: "mot/09_DucthArtistbook_2.glb",
    clickable: true,
    colorFactor: 1,
    pcOnly: true,
  },
  Ship: {
    url: "mot/10_LocalContents.glb",
    clickable: true,
    cameraPos: new Vector3(-40, 0, 30),
  },
  Credits: {
    url: "mot/11_About_lightEdit.glb",
    clickable: true,
    pcOnly: true,
  },
  Kiosk: {
    url: "mot/12_Kiosk.glb",
    clickable: true,
    colorFactor: 0.7,
    emissive: new Color(0x222222),
  },
  Donation: {
    url: "mot/13_Donation.glb",
    clickable: true,
    colorFactor: 1,
    doubleSide: true,
  },
  Human: {
    url: "mot/14_mot_human.glb",
    clickable: true,
  },
  Prev_1: {
    url: "mot/15_Prev1.glb",
    clickable: true,
    pcOnly: true,
  },
  Prev_2: {
    url: "mot/16_Prev2.glb",
    clickable: true,
    colorFactor: 1,
    emissive: new Color(0x222222),
    pcOnly: true,
  },
  Prev_3: {
    url: "mot/17_Prev3.glb",
    clickable: true,
    pcOnly: true,
  },
  Prev_4: {
    url: "mot/18_Prev4.glb",
    clickable: true,
    colorFactor: 1,
    emissive: new Color(0x222222),
  },
  Prev_5: {
    url: "mot/19_Prev5.glb",
    clickable: true,
    pcOnly: true,
  },
  Taro: {
    url: "mot/20_Taro.glb",
  },
  Curtain: {
    url: "mot/21_Curtains.glb",
    doubleSide: true,
  },
  PotatoPackage: {
    url: "mot/22_PotatoPackage.glb",
  },
  TheBestDutchBookDesigns: {
    url: "mot/theBestDutchBookDesign.glb",
    clickable: true,
    labelText: "BEST DUTCH BOOK DESIGN",
    labelOffset: new Vector3(2.5, 1, 2),
  },
  Dolphin: {
    url: "mot/dolphin.glb",
    clickable: true,
    offset: new Vector3(-61, 1.2, -34),
    rotation: new Euler(0, (Math.PI * 2) / 3, 0),
    labelText: "Ryota Miyake",
    labelOffset: new Vector3(1, 1, -1),
    labelRotation: new Euler(0, (Math.PI * 2) / 3, 0),
    pcOnly: true,
  },
  Illust: {
    url: "illust.glb",
    pcOnly: true,
    doubleSide: true,
  },
}

const MAT_OPTS: Record<string, Partial<MiscOptions>> = {
  MOT_METAL: {
    colorFactor: 1.5,
    emissive: new Color(0x222222),
  },
  MOT_METAL_MESH: {
    colorFactor: 0.9,
    emissive: new Color(0x111111),
    doubleSide: true,
    alphaTest: 0.5,
  },
}

function shouldMoveCamera(name: string): boolean {
  return name !== "PotatoTrigger" && name !== "RafaelTrigger"
}

const sounds: Record<string, string> = {
  TheBestDutchBookDesigns: "se/1-2",
  DutchArtistBooks_0: "se/1-3",
  DutchArtistBooks_1: "se/1-3",
  DutchArtistBooks_2: "se/1-3",
  Fanfare: "se/1-4",
  Iridesse: "se/1-6",
  FriedPotato: "se/1-7",
  SweetPotato: "se/1-8",
  PotatoChips: "se/1-9",
  Human: "se/1-10",
  Credits: "se/1-11",
  Ship: "se/1-12",
  Prev_1: "se/1-13",
  Prev_2: "se/1-13",
  Prev_3: "se/1-13",
  Prev_4: "se/1-13",
  Prev_5: "se/1-13",
  PotatoM: "se/1-14",
  PotatoS: "se/1-15",
}

/**
 * 画面が縦長かどうか
 */
function isVerticalScreen(aspectRatioThreshold: number = 1): boolean {
  if (typeof window === "undefined") {
    return false
  }

  const aspectRatio = window.innerHeight / window.innerWidth
  return aspectRatio > aspectRatioThreshold
}

const isSafari =
  typeof navigator !== "undefined" &&
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

const RAFAEL_MATERIAL = new MeshBasicMaterial()

export class FoyerWorld extends World {
  /** 初回表示かどうか。ロゴの出し分けに使用する */
  static isFirstVisit = true

  public readonly renderTarget: WebGLRenderTarget
  public readonly camera: PerspectiveCamera
  protected cameraControls: CameraControls
  private gravityCaster = new Raycaster()
  private currentArea: Area = "entrance"
  private isBirdView = false
  private rafaelFocusTimer: NodeJS.Timeout | undefined
  private focusedShadowSculpture: Object3D | undefined
  private areaTrigger: Partial<Record<Area, Mesh>> = {}
  private areaForTrigger: Record<string, Area> = {}
  private videoScreen: Mesh | undefined

  private isMoving: Record<MoveDir, boolean> = {
    left: false,
    right: false,
    up: false,
    down: false,
  }
  private moveVelocity: Record<MoveDir, number> = {
    left: 0,
    right: 0,
    up: 0,
    down: 0,
  }

  private constructor(
    id: number,
    private events: EventEmitter,
    canvas: HTMLCanvasElement,
    scene: Scene,
    objects: SceneObject[]
  ) {
    super(id, canvas, scene, objects)

    this.renderTarget = new WebGLRenderTarget(
      canvas.offsetWidth,
      canvas.offsetHeight
    )

    this.camera = this.initCamera()
    this.cameraControls = this.initCameraControls()
    this.cameraControls.addEventListener(
      "controlstart",
      this.blurShadowSculpture
    )

    // エントランスからの開始ではない時はロゴを消す
    const area = getAreaFromUrl()
    if (area !== "entrance") {
      this.removeLogo()
    }

    // エリア全体のクリック用オブジェクト
    const entranceTrigger = new Mesh(
      new BoxBufferGeometry(MOT_WIDTH + 10, 30, MOT_HEIGHT + 10),
      new MeshBasicMaterial({ color: 0xff0000 })
    )
    entranceTrigger.name = "EntranceTrigger"
    entranceTrigger.visible = false
    entranceTrigger.position.set(MOT_CENTER_X, 0, MOT_CENTER_Z)
    this.areaForTrigger[entranceTrigger.id] = "entrance"
    this.areaTrigger["entrance"] = entranceTrigger
    scene.add(entranceTrigger)

    const rafaelTrigger = new Mesh(
      new BoxBufferGeometry(RAFAEL_CELL * 4.5, 30, RAFAEL_CELL * 3.5)
    )
    rafaelTrigger.name = "RafaelTrigger"
    rafaelTrigger.visible = false
    rafaelTrigger.position.set(RAFAEL_CENTER_X, 10, RAFAEL_CENTER_Z)
    this.areaForTrigger[rafaelTrigger.id] = "rafael"
    this.areaTrigger["rafael"] = rafaelTrigger
    scene.add(rafaelTrigger)

    // 現在のエリアのトリガーは隠しておく
    this.areaTrigger[area]?.position.setY(-9999)

    // 屋外上映スクリーンを取得
    this.videoScreen = scene.children.find(
      s => s.name === "VideoScreen"
    ) as Mesh

    this.events.on("changeArea", this.changeArea)
    this.events.on("movePageConfirmed", this.movePage)

    this.reset()
  }

  static async init(
    id: number,
    events: EventEmitter,
    canvas: HTMLCanvasElement
  ): Promise<FoyerWorld> {
    const scene = new Scene()
    scene.fog = new Fog(0xdddddd, 15, 30)

    // Lighting
    const ambientLight = new AmbientLight(0xffffff)
    scene.add(ambientLight)

    const dirLight = new DirectionalLight(0xffffee, 0.3)
    dirLight.position.set(-40, 50, 100)
    dirLight.castShadow = true
    dirLight.shadow.radius = 1
    dirLight.shadow.mapSize.width = 4096 // default
    dirLight.shadow.mapSize.height = 4096 // default
    dirLight.shadow.camera.near = 0.1 // default
    dirLight.shadow.camera.far = 1000 // default
    dirLight.shadow.camera.left = -50
    dirLight.shadow.camera.right = 250
    dirLight.shadow.camera.bottom = -150
    dirLight.shadow.camera.top = 150
    scene.add(dirLight)

    // Cubemap
    const cubemapLoader = new CubeTextureLoader()
    scene.background = cubemapLoader
      .setPath("/images/cubemap/")
      .load(["px.jpg", "nx.jpg", "py.jpg", "ny.jpg", "pz.jpg", "nz.jpg"])

    const objects: SceneObject[] = []

    // カメラの動きを制限するためのPlane
    const fakeGround = new Mesh(
      new PlaneBufferGeometry(10000, 10000),
      new MeshBasicMaterial({ color: 0xff0000, fog: false })
    )
    fakeGround.name = "FakeGround"
    fakeGround.position.setY(-2)
    fakeGround.rotateX(-Math.PI / 2)
    fakeGround.visible = false
    scene.add(fakeGround)

    // Ground
    const ground = Ground.init()
    ground.mesh.position.y += -4
    scene.add(ground.mesh)

    await Promise.all([
      FoyerWorld.initLogo(scene, events),
      FoyerWorld.initMisc(scene, events),
      FoyerWorld.initRafael(scene, events),
      FoyerWorld.initCounter(scene, events),
      FoyerWorld.initVideo(scene, events),
    ])

    return new FoyerWorld(id, events, canvas, scene, objects)
  }

  static async initLogo(scene: Scene, events: EventEmitter): Promise<void> {
    // ロゴ 初回のみ表示する
    if (FoyerWorld.isFirstVisit) {
      const logo = await Loader.loadGLTF("vabf_logo.glb")
      logo.name = "Logo"
      logo.position.add(MODAL_POS)
      logo.traverse(l => {
        if ((l as Mesh).isMesh) {
          const mat = (l as Mesh).material as MeshStandardMaterial
          mat.fog = false
        }
      })
      scene.add(logo)
      FoyerWorld.isFirstVisit = false
    }
  }

  static async initMisc(scene: Scene, events: EventEmitter): Promise<void> {
    const isPC = !isMobile()

    await Promise.all(
      Object.keys(miscInfo).map(async key => {
        const opts = miscInfo[key]
        if (opts.pcOnly && !isPC) {
          return
        }

        try {
          const obj = await Loader.loadGLTF(opts.url)
          obj.name = key
          obj.position.set(0, -1.5, 25)
          if (opts.offset) {
            obj.position.add(opts.offset)
          }
          if (opts.rotation) {
            obj.setRotationFromEuler(opts.rotation)
          }
          scene.add(obj)

          obj.traverse(_m => {
            const m = _m as Mesh
            m.name = key
            if (m.isMesh) {
              const mat = m.material as MeshStandardMaterial
              const matOpts = MAT_OPTS[mat.name]

              if (mat.map) {
                mat.map.encoding = LinearEncoding
              }
              mat.fog = false
              mat.roughness = 1

              // DoubleSide
              if (opts?.doubleSide || matOpts?.doubleSide) {
                mat.side = DoubleSide
              }

              // Color
              const colorFactor = opts?.colorFactor ?? matOpts?.colorFactor
              if (colorFactor) {
                mat.color.multiplyScalar(colorFactor)
              } else if (opts?.color) {
                mat.color = opts.color
                mat.metalness = 0.4
              } else {
                mat.color = new Color(0xdddddd)
              }

              // Emissive
              const emissive = opts?.emissive ?? matOpts?.emissive
              if (emissive) {
                mat.emissive = emissive
              }

              if (matOpts?.alphaTest) {
                mat.alphaTest = matOpts.alphaTest
                // mat.premultipliedAlpha = true
              }
            }
          })

          if (opts?.position) {
            const op = opts.position
            obj.position.set(op.x, op.y, op.z)
          }

          if (opts?.clickable) {
            // 子オブジェクトをクリックできるようにする
            const triggerName = `${obj.name}Trigger`
            obj.traverse(c => {
              if ((c as Mesh).isMesh) {
                ;(c as Mesh).name = triggerName
              }
            })
          }

          // Label for object
          if (opts?.labelText) {
            // オブジェクトの中心座標を得る
            const bbox = new Box3().setFromObject(obj)
            const labelPos = new Vector3()
            bbox.getCenter(labelPos)
            if (opts.labelOffset) {
              labelPos.add(opts.labelOffset)
            }
            // labelPos.add(new Vector3(0, -1.5, 25))

            // ラベルを作成
            const objLabel = await TextMesh.init(opts.labelText, "Foyer")
            objLabel.mesh.scale.setScalar(0.004 * (opts?.fontSize ?? 1))
            objLabel.mesh.position.add(labelPos)
            if (opts?.labelRotation) {
              objLabel.mesh.setRotationFromEuler(opts.labelRotation)
            }
            objLabel.mesh.rotateX(Math.PI)
            scene.add(objLabel.mesh)
          }
        } catch (e) {
          // console.log(">>> failed to load ", opts.url)
        }
      })
    )
  }

  static async initRafael(scene: Scene, events: EventEmitter): Promise<Mesh> {
    // Shadow Sculptures
    const shadowSculptures = new Mesh()
    shadowSculptures.name = "ShadowSculptureWrapper"

    const randInt = (size: number) => Math.random() * size - size / 2

    const model = await Loader.loadGLTF("rafael.glb")
    const sculptures: Mesh[] = []
    model.traverse(c => {
      const m = c as Mesh
      if (m.isMesh && m.name.includes("ss")) {
        if (isSafari) {
          m.material = RAFAEL_MATERIAL
        } else {
          const mat = m.material as MeshStandardMaterial
          mat.color = new Color(0xcccccc)
          mat.emissive = new Color(0x333333)
          mat.metalness = 0.2
        }
        sculptures.push(m)
      }
    })

    for (let i = 0; i < 18; i++) {
      const mesh = sculptures[i]
      mesh.scale.set(6, 6, 6)

      const x = ((i % 5) - 2) * RAFAEL_CELL
      const y = (Math.floor(i / 5) - 2) * RAFAEL_CELL

      mesh.position.set(
        x + (Math.random() - 0.5) * RAFAEL_CELL * 0.7,
        3,
        y + (Math.random() - 0.5) * RAFAEL_CELL * 0.7
      )
      mesh.rotateY(randInt((Math.PI * 2) / 3))
      mesh.castShadow = true

      // クリック用当たり判定オブジェクト
      const triggerName = `ShadowSculpture${i}Trigger`
      const trigger = new Mesh(new BoxBufferGeometry(0.7, 1, 0.02))
      trigger.name = triggerName
      trigger.visible = false
      mesh.add(trigger)

      mesh.name = triggerName
      shadowSculptures.add(mesh)
    }

    scene.add(shadowSculptures)
    shadowSculptures.position.x += RAFAEL_CENTER_X
    shadowSculptures.position.y -= 4

    return shadowSculptures
  }

  static async initCounter(scene: Scene, events: EventEmitter): Promise<void> {
    const count = await loadViewCount()
    const countText = `Visitors: ${count}`

    // ラベルを作成
    const label = await TextMesh.init(countText, "Counter", {
      width: 1280,
      color: 0xffee55,
    })
    label.mesh.scale.setScalar(0.2)
    label.mesh.position.set(-110, 0, 60)
    label.mesh.rotateX(Math.PI)
    label.mesh.rotateY(-Math.PI / 2)

    scene.add(label.mesh)
  }

  static async initVideo(scene: Scene, events: EventEmitter): Promise<void> {
    const mat = new MeshBasicMaterial({
      side: DoubleSide,
      fog: false,
      map: Loader.loadTexture("/images/vabf_video_thumb.jpg"),
    })
    const mesh = new Mesh(new PlaneGeometry(1, 1, 2, 2), mat)
    mesh.name = "VideoScreen"
    mesh.scale.set(24, 13.5, 0)
    mesh.position.set(-86, 8, -100)
    mesh.rotateY(-Math.PI / 2)
    scene.add(mesh)
  }

  initCamera(): PerspectiveCamera {
    const camera = new PerspectiveCamera(
      isMobile() ? 60 : 45,
      this.canvas.offsetWidth / this.canvas.offsetHeight,
      0.01,
      2000
    )
    camera.position.set(0, 0, 0.0001)

    return camera
  }

  initCameraControls(): CameraControls {
    const cameraControls = new CameraControls(this.camera, this.canvas)
    cameraControls.mouseButtons = {
      left: CameraControls.ACTION.ROTATE,
      right: CameraControls.ACTION.TRUCK,
      middle: CameraControls.ACTION.TRUCK,
      wheel: CameraControls.ACTION.ZOOM,
    }
    cameraControls.touches = {
      one: CameraControls.ACTION.TOUCH_ROTATE,
      two: CameraControls.ACTION.TOUCH_DOLLY,
      three: CameraControls.ACTION.TOUCH_DOLLY_TRUCK,
    }

    // カメラの動きを制限する
    cameraControls.azimuthRotateSpeed = 0.3 // negative value to invert rotation direction
    cameraControls.polarRotateSpeed = 0.3 // negative value to invert rotation direction
    cameraControls.dampingFactor = 0.07
    cameraControls.minZoom = 0.7
    cameraControls.maxZoom = 1

    // const mot = this.scene.children.find(c => c.name === "MOT") as Mesh
    // cameraControls.colliderMeshes.push(mot.children[0])

    const fakeGround = this.scene.children.find(
      c => c.name === "FakeGround"
    ) as Mesh
    cameraControls.colliderMeshes.push(fakeGround)

    // cameraControls.moveTo(0, 10, 0, true);
    return cameraControls
  }

  dispose() {
    this.clearVideo()

    for (const o of this.objects) {
      o.dispose()
    }
    this.blurShadowSculpture()
    this.events.off("changeArea", this.changeArea)
    this.events.off("movePageConfirmed", this.movePage)
  }

  /**
   * ページ表示時のアニメーション。
   */
  async start(): Promise<void> {
    const area = getAreaFromUrl()

    if (area === "rafael") {
      this.enableBirdView(false)
      MouseWatcher.isEnabled = true
      return
    }

    const curve = new CubicBezierCurve3(
      new Vector3(500, 120, 250),
      new Vector3(-10, 5, 220),
      MODAL_POS,
      MODAL_POS
    )

    const p = new Vector3()
    await new Promise(resolve => {
      new TWEEN.Tween({ t: 0 })
        .to({ t: 1 }, 5000)
        .easing(TWEEN.Easing.Cubic.InOut)
        .onStart(() => {
          // disable user control while the animation
          this.cameraControls.enabled = false
          this.cameraControls.colliderMeshes = []
        })
        .onUpdate(({ t }) => {
          curve.getPoint(t, p)
          this.cameraControls.setLookAt(
            p.x,
            p.y,
            p.z,
            START_POS.x,
            START_POS.y,
            START_POS.z - 20,
            false
          )
        })
        .onComplete(resolve)
        .start()
    })

    this.events.emit("openHelpModal")

    this.events.once("closeHelpModal", async () => {
      // ロゴを消す
      this.removeLogo()

      // スタート位置に移動
      const curve2 = new CatmullRomCurve3([MODAL_POS, START_POS])
      const p = new Vector3()
      await new Promise(resolve => {
        new TWEEN.Tween({ t: 0 })
          .to({ t: 1 }, 1000)
          .easing(TWEEN.Easing.Cubic.Out)
          .onUpdate(({ t }) => {
            curve2.getPoint(t, p)
            this.cameraControls.setLookAt(
              p.x,
              p.y,
              p.z,
              START_POS.x,
              START_POS.y,
              START_POS.z - 20,
              false
            )
          })
          .onComplete(() => {
            this.cameraControls.enabled = true
            this.cameraControls.setTarget(p.x, p.y, p.z - 0.01)
            MouseWatcher.isEnabled = true
            resolve()
          })
          .start()
      })
    })
  }

  reset(animate: boolean = false) {
    this.resetMoving()

    // カメラの初期位置を設定
    if (this.currentArea === "entrance") {
      this.disableBirdView()
      this.cameraControls.setLookAt(
        MODAL_POS.x,
        MODAL_POS.y,
        MODAL_POS.z,
        START_POS.x,
        START_POS.y,
        START_POS.z - 0.001,
        false
      )
      this.cameraControls.setLookAt(
        START_POS.x,
        START_POS.y,
        START_POS.z,
        START_POS.x,
        START_POS.y,
        START_POS.z - 0.001,
        animate
      )
    } else if (this.currentArea === "rafael") {
      this.enableBirdView(animate)
    }
  }

  resize(width: number, height: number) {
    const aspect = width / height
    this.camera.aspect = aspect
    this.camera.updateProjectionMatrix()
    const dpr = window.devicePixelRatio
    this.renderTarget.setSize(width * dpr, height * dpr)
  }

  loop(): void {
    if (!this.enabled) {
      return
    }

    // Move objects
    for (const o of this.objects) {
      // o.update(this.state, this.camera);
    }

    // Move camera
    const delta = this.clock.getDelta()
    const hasControlsUpdated = this.cameraControls.update(delta)

    if (this.cameraControls.enabled) {
      if (!this.isBirdView && !this.focusedShadowSculpture) {
        const camHeight = this.getNewCameraHeight(this.camera.position)
        if (camHeight !== undefined) {
          const diff = camHeight - this.camera.position.y
          this.cameraControls.truck(0, -diff * 0.1, false)
        }
      }

      const area = this.isInRafael() ? "rafael" : "entrance"
      if (area !== this.currentArea) {
        this.events.emit("areaChanged", area)
        this.currentArea = area
        this.updateAreaTriggers()
      }

      this.playBGMForArea(area)
    }

    // VideoScreenに近づいたら再生を開始
    if (this.isNearby("VideoScreen", 50) && this.camera.position.x < -86) {
      this.startVideo()
    }
  }

  getNewCameraHeight(cameraPos: Vector3): number | undefined {
    // 現在のカメラ位置の地面に応じてカメラの高さを移動する
    this.gravityCaster.set(cameraPos, new Vector3(0, -1, 0))
    const intersects = this.gravityCaster.intersectObjects(
      this.scene.children,
      true
    )
    const intersect = intersects.find(i => i.object.name === "MOTTrigger")

    // カメラの理想的な高さを決定
    let newCameraY
    if (intersect) {
      const floorY = cameraPos.y - intersect.distance
      newCameraY = floorY + CAMERA_DIST_FROM_FLOOR
    } else {
      newCameraY = DEFAULT_CAMERA_HEIGHT
    }

    // newCameraYが正常範囲ならカメラの高さを近づける
    if (CAMERA_Y_MIN <= newCameraY && newCameraY <= CAMERA_Y_MAX) {
      return newCameraY
    }
  }

  getObjectUnderMouse(x: number, y: number): Mesh | undefined {
    this.raycaster.setFromCamera(new Vector2(x, y), this.camera)

    const intersects = this.raycaster.intersectObjects(
      this.scene.children,
      true
    )

    return intersects.find(i => i.object.name.includes("Trigger"))
      ?.object as Mesh
  }

  onMouseMove(x: number, y: number): void {
    const object = this.getObjectUnderMouse(x, y)
    if (object && object.name !== "MOTTrigger") {
      // マウスカーソルを更新する
      document.body.style.cursor = "pointer"
    } else {
      // マウスカーソルを更新する
      document.body.style.cursor = ""
    }
  }

  onClick(x: number, y: number): void {
    const object = this.getObjectUnderMouse(x, y)
    if (!object) {
      return
    }

    // MOTの何も無い所をクリックしたらSEを鳴らす
    if (object.name === "MOTTrigger") {
      this.events.emit("playSE", "se/9")
      return
    }

    if (this.areaForTrigger[object.id]) {
      this.disableBirdView()
      this.events.emit("requestChangeArea", this.areaForTrigger[object.id])
    } else if (object.name.includes("ShadowSculpture")) {
      this.disableBirdView()
      this.focusShadowSculpture(object)
    } else {
      this.disableBirdView()

      const triggerName = object.name.split("Trigger")[0]
      const lang = getLang()
      const params = movePageParams[lang][triggerName]
      if (params) {
        this.focusObject(object)
        this.events.emit("requestMovePage", params)
      }
      const se = sounds[triggerName]
      if (se) {
        this.events.emit("playSE", se)
      }
    }
  }

  focusObject(object: Object3D) {
    // オブジェクトの中心を取得
    const bbox = new Box3()
    bbox.setFromObject(object)
    const objectPos = new Vector3()
    bbox.getCenter(objectPos)

    // フォーカス時のカメラ位置を計算
    const cameraPos = objectPos.clone()
    const name = object.name.split("Trigger")[0]

    if (shouldMoveCamera(object.name)) {
      const info = miscInfo[name]
      if (info && info.cameraPos) {
        // カメラ位置をデータから取得
        cameraPos.add(info.cameraPos)
        const cameraHeight = this.getNewCameraHeight(cameraPos)
        if (cameraHeight) {
          cameraPos.y = cameraHeight
        }
      } else {
        // データがなければ、現在のカメラ位置から計算
        const cameraToObject = objectPos.clone().sub(this.camera.position)
        cameraToObject.y = 0
        cameraPos.sub(cameraToObject.setLength(isMobile() ? 10 : 15))
        cameraPos.y = this.camera.position.y
      }
    } else {
      // 現在のカメラ位置をそのまま使う
      const cp = this.camera.position
      cameraPos.set(cp.x, cp.y, cp.z)
    }

    // モバイルではモーダルと重なるのを避けるため、カメラを少し下にむける
    if (isMobile()) {
      objectPos.y -= 1.5
    }

    const targetPos = new Vector3().lerpVectors(cameraPos, objectPos, 0.001)

    this.cameraControls.setLookAt(
      cameraPos.x,
      cameraPos.y,
      cameraPos.z,
      targetPos.x,
      targetPos.y,
      targetPos.z,
      true
    )
  }

  resetCameraCenter(): void {}

  escape(): void {
    if (this.isInRafael() && !this.isBirdView) {
      this.enableBirdView()
    }
  }

  setMoving(dir: MoveDir, isMoving: boolean) {
    this.isMoving[dir] = isMoving
  }

  resetMoving() {
    this.isMoving["left"] = false
    this.isMoving["right"] = false
    this.isMoving["up"] = false
    this.isMoving["down"] = false
    this.moveVelocity["left"] = 0
    this.moveVelocity["right"] = 0
    this.moveVelocity["up"] = 0
    this.moveVelocity["down"] = 0
  }

  move(deltaTime: number) {
    for (const key of Object.keys(this.moveVelocity) as MoveDir[]) {
      let velocity = this.moveVelocity[key]
      velocity = Math.min(
        Math.max(velocity + (this.isMoving[key] ? 0.05 : -0.03), 0),
        1
      )
      this.moveVelocity[key] = velocity
      this.moveByDir(key, velocity * deltaTime)
    }
  }

  private moveByDir(dir: MoveDir, speed: number) {
    // 移動距離を計算
    const dist = 12 * speed

    if (dir === "left") {
      this.cameraControls.truck(-dist, 0, true)
    } else if (dir === "right") {
      this.cameraControls.truck(dist, 0, true)
    } else if (dir === "up") {
      this.cameraControls.forward(dist, true)
    } else if (dir === "down") {
      this.cameraControls.forward(-dist, true)
    }
  }

  async enterWorld(prevWorldId: number) {
    // this.cameraControls.setLookAt(
    //   START_POS.x,
    //   START_POS.y,
    //   START_POS.z,
    //   START_POS.x,
    //   START_POS.y,
    //   START_POS.z - 0.01,
    //   false
    // )

    if (this.isInEntrance()) {
      this.events.emit("playBGM", "foyer_world/bgm_entrance")
      this.events.emit("areaChanged", "entrance")
    } else if (this.isInRafael()) {
      this.events.emit("playBGM", "foyer_world/bgm_rafael")
      this.events.emit("areaChanged", "rafael")
    }
  }

  async leaveWorld(nextWorldId: number) {
    this.blurShadowSculpture()
    this.clearVideo()

    if (nextWorldId === 1) {
      this.cameraControls.enabled = false
      this.cameraControls.colliderMeshes = []

      const p1 = this.camera.position.clone()
      const p2 = p1.clone().lerp(new Vector3(0, -1.5, 25).add(potatoPos), 0.9)
      const curve = new LineCurve3(p1, p2)

      await new Promise(resolve => {
        const p = new Vector3()
        new TWEEN.Tween({ t: 0 })
          .to({ t: 1 }, 1500)
          .easing(TWEEN.Easing.Cubic.InOut)
          .onUpdate(({ t }) => {
            curve.getPoint(t, p)
            this.cameraControls.setLookAt(
              p.x,
              p.y,
              p.z,
              potatoPos.x,
              potatoPos.y,
              potatoPos.z,
              true
            )
          })
          .onComplete(resolve)
          .start()
      })
      this.cameraControls.enabled = true
    }
  }

  /**
   * Shadow Sculptureにフォーカスする
   */
  focusShadowSculpture(object: Object3D): void {
    const objectPos = new Vector3()
    object.getWorldPosition(objectPos)
    const objectRot = new Quaternion()
    object.getWorldQuaternion(objectRot)

    const p1 = this.camera.position.clone()

    // 現在のカメラとobjectの距離を元に、中間点を算出
    const dist = this.camera.position.distanceTo(objectPos)
    const p12dist = Math.min(dist, 20)
    const p12 = new Vector3(0, 0, p12dist)
      .applyQuaternion(objectRot)
      .add(objectPos)

    // カメラ位置を計算
    const p2dist = this.getCameraDistForObject(object)
    const p2dir = new Vector3(0, -0.03, 1).applyQuaternion(objectRot) // 少し見上げる
    const p2distScale = isVerticalScreen() ? 2.4 : 1.5 // 画面が縦長のときは少しカメラを離す
    const p2 = objectPos.clone().add(p2dir.setLength(p2dist * p2distScale))

    const oldTargetPos = new Vector3()
    this.cameraControls.getTarget(oldTargetPos)

    const newTargetPos = new Vector3().lerpVectors(p2, objectPos, 0.001)

    const targetPos = new Vector3()

    const curve = new CatmullRomCurve3([p1, p12, p2])
    const p = new Vector3()
    new TWEEN.Tween({ t: 0 })
      .to({ t: 1 }, 2000)
      .easing(TWEEN.Easing.Cubic.Out)
      .onStart(() => {
        this.cameraControls.enabled = false
      })
      .onUpdate(({ t }) => {
        curve.getPoint(t, p)
        targetPos.lerpVectors(oldTargetPos, objectPos, Math.min(t * 5, 1))
        this.cameraControls.setLookAt(
          p.x,
          p.y,
          p.z,
          targetPos.x,
          targetPos.y,
          targetPos.z,
          false
        )
      })
      .onComplete(() => {
        this.cameraControls.setTarget(
          newTargetPos.x,
          newTargetPos.y,
          newTargetPos.z,
          false
        )
        this.disableBirdView()
      })
      .start()

    this.focusedShadowSculpture = object

    // 一定時間後に別のオブジェクトに自動でフォーカス
    this.rafaelFocusTimer = setTimeout(() => {
      const sculptures = this.scene.children.find(
        c => c.name === "ShadowSculptureWrapper"
      )?.children
      if (sculptures) {
        let randomSculpture
        do {
          randomSculpture =
            sculptures[Math.floor(Math.random() * sculptures.length)]
        } while (randomSculpture === object)
        this.focusShadowSculpture(randomSculpture)
      }
    }, 30 * 1000)
  }

  blurShadowSculpture = () => {
    this.focusedShadowSculpture = undefined

    // 自動フォーカスを停止
    if (this.rafaelFocusTimer) {
      clearTimeout(this.rafaelFocusTimer)
    }
  }

  enableBirdView(animate: boolean = true) {
    this.isBirdView = true

    // const shadowSculptures = this.scene.children.find(
    //   c => c.name === "ShadowSculptureWrapper"
    // )!

    // // BoundingBoxを計算
    // const bbox = new Box3()
    // bbox.setFromObject(shadowSculptures)
    // const bboxSize = new Vector3()
    // bbox.getSize(bboxSize)

    // // 奥行きからBoundingSphereを計算
    // // モバイルでの見た目を考慮し、奥行きをベースに計算している (横はハミ出しても良い)
    // const center = new Vector3()
    // bbox.getCenter(center)
    // const radius = bboxSize.z

    const center = new Vector3(RAFAEL_CENTER_X, 0, RAFAEL_CENTER_Z)
    const radius = RAFAEL_CELL * 3

    const dir = new Vector3(0, 1, 1).normalize()
    const theta = (this.camera.getEffectiveFOV() / 2 / 180) * Math.PI
    const dist = (radius / Math.tan(theta)) * 0.7 // ちょっと近づける

    const cameraPos = center.clone().add(dir.setLength(dist))

    this.cameraControls.setLookAt(
      cameraPos.x,
      cameraPos.y,
      cameraPos.z,
      center.x,
      center.y,
      center.z,
      animate
    )

    this.cameraControls.maxPolarAngle = (Math.PI / 2) * 0.98
  }

  disableBirdView() {
    this.isBirdView = false
    this.cameraControls.enabled = true
    this.cameraControls.maxPolarAngle = Math.PI
  }

  private bgmType: string | undefined
  private bgmTimer: NodeJS.Timeout | undefined

  playBGMForArea(area: string): void {
    let bgmType: string

    // エリアからBGMの種類を判定
    if (this.isNearby("Dolphin", 15)) {
      bgmType = "dolphin"
    } else if (this.isNearby("Ship", 25)) {
      bgmType = `lake`
    } else if (area === "rafael") {
      bgmType = "rafael"
    } else if (area === "entrance") {
      bgmType = "entrance"
    } else {
      bgmType = "entrance" // あとで追加があるかも
    }

    // BGMタイプが変わっていたら処理を行う
    if (bgmType !== this.bgmType) {
      this.bgmType = bgmType

      if (this.bgmTimer) {
        clearInterval(this.bgmTimer)
      }

      if (bgmType == "dolphin") {
        // イルカの周りのときだけシャッフル再生
        let i = Math.ceil(Math.random() * 3)

        this.events.emit("playBGM", `foyer_world/bgm_dolphin_${i}`)
        this.bgmTimer = setInterval(() => {
          // 新しいIDを計算
          const oldI = i
          do {
            i = Math.ceil(Math.random() * 3)
          } while (i === oldI)

          this.events.emit("playBGM", `foyer_world/bgm_dolphin_${i}`)
        }, 90 * 1000)
      } else {
        // それ他の場合は単純にBGMを再生する
        this.events.emit("playBGM", `foyer_world/bgm_${bgmType}`)
      }
    }
  }

  isInEntrance(): boolean {
    const p = this.camera.position
    return (
      MOT_LEFT <= p.x && p.x <= MOT_RIGHT && MOT_BACK <= p.z && p.z <= MOT_FRONT
    )
  }

  isInRafael(): boolean {
    const p = this.camera.position

    return (
      this.isBirdView ||
      (RAFAEL_LEFT <= p.x &&
        p.x <= RAFAEL_RIGHT &&
        RAFAEL_BACK <= p.z &&
        p.z <= RAFAEL_FRONT)
    )
  }

  private miscBox: Record<string, Box3> = {}

  isNearby(name: keyof typeof miscInfo, distance: number): boolean {
    let box = this.miscBox[name]

    if (box === undefined) {
      const obj = this.scene.children.find(c => c.name === name)
      if (!obj) {
        return false
      }

      box = new Box3()
      box.setFromObject(obj)

      this.miscBox[name] = box
    }

    return box.distanceToPoint(this.camera.position) < distance
  }

  getCameraDistForObject(object: Object3D): number {
    // BoundingBoxを計算
    const bbox = new Box3()
    bbox.setFromObject(object)

    // BoundingSphereを計算
    const s = new Sphere()
    bbox.getBoundingSphere(s)
    const center = s.center
    const radius = s.radius

    const dir = new Vector3(0, 0, 1).applyEuler(object.rotation)
    const theta = (this.camera.getEffectiveFOV() / 2 / 180) * Math.PI
    const dist = radius / Math.tan(theta)

    return dist
  }

  changeArea = (area: Area) => {
    if (area === "booth") {
      return
    }
    this.currentArea = area
    this.updateAreaTriggers()
    this.events.emit("areaChanged", area)
    this.reset(true)
  }

  removeLogo() {
    const logo = this.scene.children.find(c => c.name === "Logo")
    if (logo) {
      this.scene.remove(logo)
    }
  }

  updateAreaTriggers() {
    for (const [area, trigger] of Object.entries(this.areaTrigger)) {
      trigger?.position.setY(area === this.currentArea ? -9999 : 0)
    }
  }

  movePage = (id: string) => {
    if (id === "exhibitors") {
      this.events.emit("playSE", `se/1-1_${Math.ceil(Math.random() * 3)}`)
      this.events.emit("requestChangeWorld", 1)
    } else if (id === "rafael") {
      this.changeArea("rafael")
    }
  }

  private videoElement: HTMLVideoElement | undefined
  private videoTimer: NodeJS.Timeout | undefined

  startVideo() {
    if (this.videoElement || !this.videoScreen) {
      return
    }
    this.videoElement = document.createElement("video")
    this.videoElement.src = "/videos/vabf_video1.mp4"
    this.videoElement.muted = true
    this.videoElement.style.position = "fixed"
    this.videoElement.style.top = "9999px"
    this.videoElement.style.visibility = "hidden"
    document.body.appendChild(this.videoElement)

    const videoMat = this.videoScreen.material as MeshBasicMaterial
    videoMat.opacity = 1
    videoMat.map = new VideoTexture(this.videoElement)
    videoMat.needsUpdate = true
    this.videoElement.play()

    let i = 1
    this.videoTimer = setInterval(() => {
      if (this.videoElement) {
        this.videoElement.src = `/videos/vabf_video${(i++ % 2) + 1}.mp4`
        this.videoElement.play()
      }
    }, 120 * 1000)
  }

  clearVideo() {
    // 自動再生を停止
    if (this.videoTimer) {
      clearInterval(this.videoTimer)
    }

    // video要素を削除
    this.videoElement?.remove()
  }
}
