import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/VabfEvents.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SocialShare = makeShortcode("SocialShare");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className="youtube__wrap">
  <iframe width="560" height="315" src="https://www.youtube.com/embed/ae_TEPyP9-U?controls=0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
    <div className="share__2col">
  <div className="share__2col__link">
    <a href="https://youtu.be/ae_TEPyP9-U" target="_blank">
      Youtube Live をみる
    </a>
  </div>
  <div className="share__2col__icon">
    <SocialShare title={props.pageContext.frontmatter.title} mdxType="SocialShare" />
  </div>
    </div>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "980px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "75.10204081632654%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEBf/EABYBAQEBAAAAAAAAAAAAAAAAAAEAAv/aAAwDAQACEAMQAAABgfRbLGagX//EABoQAAMAAwEAAAAAAAAAAAAAAAABAgMEESL/2gAIAQEAAQUCqctE61tLU6U/UcQrP//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AUf/xAAWEQEBAQAAAAAAAAAAAAAAAAAAERL/2gAIAQIBAT8BrT//xAAbEAACAwADAAAAAAAAAAAAAAAAAQIRITEykf/aAAgBAQAGPwLbLtHePpQ3LVwZE//EABsQAAICAwEAAAAAAAAAAAAAAAABESExQXFR/9oACAEBAAE/IWNG6SIi9siSC7v4MtBTLDZTTHT/2gAMAwEAAgADAAAAEA8//8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8Qin//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPxAh/8QAHhABAAMAAQUBAAAAAAAAAAAAAQARITFBUXGB8LH/2gAIAQEAAT8QuOxRfPRCofFCfszzU0/CckF6A6eZmyA5Gm4femfCf//Z')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/ada2c611b4eff4a202c5c59f55ddbd56/1a3b7/img_1.webp 245w", "/static/ada2c611b4eff4a202c5c59f55ddbd56/fb835/img_1.webp 490w", "/static/ada2c611b4eff4a202c5c59f55ddbd56/096d3/img_1.webp 980w", "/static/ada2c611b4eff4a202c5c59f55ddbd56/3dcc7/img_1.webp 1470w", "/static/ada2c611b4eff4a202c5c59f55ddbd56/061f9/img_1.webp 1960w", "/static/ada2c611b4eff4a202c5c59f55ddbd56/eebb9/img_1.webp 4032w"],
          "sizes": "(max-width: 980px) 100vw, 980px",
          "type": "image/webp"
        }}></source>{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/ada2c611b4eff4a202c5c59f55ddbd56/c168b/img_1.jpg 245w", "/static/ada2c611b4eff4a202c5c59f55ddbd56/bb66a/img_1.jpg 490w", "/static/ada2c611b4eff4a202c5c59f55ddbd56/2f919/img_1.jpg 980w", "/static/ada2c611b4eff4a202c5c59f55ddbd56/a7235/img_1.jpg 1470w", "/static/ada2c611b4eff4a202c5c59f55ddbd56/331e6/img_1.jpg 1960w", "/static/ada2c611b4eff4a202c5c59f55ddbd56/bf374/img_1.jpg 4032w"],
          "sizes": "(max-width: 980px) 100vw, 980px",
          "type": "image/jpeg"
        }}></source>{`
        `}<img parentName="picture" {...{
          "className": "gatsby-resp-image-image",
          "src": "/static/ada2c611b4eff4a202c5c59f55ddbd56/2f919/img_1.jpg",
          "alt": "img 1",
          "title": "img 1",
          "loading": "lazy",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          }
        }}></img>{`
      `}</picture>{`
    `}</span>
    <p>{`『花椿』が注目する写真家、細倉真弓さんは、被写体の性別や社会的属性、または生物か静物であるかにもとらわれない、ニュートラルな視点で世界を見つめる。そして細倉さん自身も展示やイベントを開催している代田橋にある本屋さん flotsam books。今回は、本だけでなくアーティストたちの実験場としても機能している flotsam books の小林孝行さんをお招きして、オルタナティブスペースとしての本屋さんと写真家の可能性について語ります。ご来場の方には『花椿』特製エコバッグをプレゼント！`}</p>
    <ul>
      <li parentName="ul">
        <span className="font__small">無料で視聴いただけます。</span>
      </li>
      <li parentName="ul">
        <span className="font__small">
  インターネット回線の状況やサーバー負荷、その他視聴者の PC
  環境により、映像が途切れる、または停止するなど正常に視聴できない事がございます。
        </span>
      </li>
      <li parentName="ul">
        <span className="font__small">
  配信終了後も TOKYO ART BOOK FAIR の youtube
  チャンネルで動画を視聴いただけます。
        </span>
      </li>
    </ul>
    <div {...{
      "className": "content__info"
    }}>{`
  `}<figure parentName="div" {...{
        "className": "content__info__img"
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIDBQT/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAdGq6nLB0IzIk6AT/8QAHBAAAwACAwEAAAAAAAAAAAAAAAECEjIDBBEh/9oACAEBAAEFAq3eqH9KmlOB2H4cVmLZ/8QAFREBAQAAAAAAAAAAAAAAAAAAASD/2gAIAQMBAT8BI//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EAB0QAAICAQUAAAAAAAAAAAAAAAAxAYEQAjJBcfD/2gAIAQEABj8CoWGPFc9ij0m/VFn/xAAbEAEBAQEAAwEAAAAAAAAAAAABESEAMUFhgf/aAAgBAQABPyEGvrssM2X84ZodDwDEi8VoZ5nvhzeXZHTi8oSWeqcNB8Rz/9oADAMBAAIAAwAAABA3MIP/xAAXEQEBAQEAAAAAAAAAAAAAAAABABAx/9oACAEDAQE/EAOPIv/EABcRAAMBAAAAAAAAAAAAAAAAAAABERD/2gAIAQIBAT8QdIxZ/8QAHRABAAMAAgMBAAAAAAAAAAAAAQARIUFhUXGBwf/aAAgBAQABPxBxRzQKAXINVlv8mW09wjaPgbbKW1Lc3ag4+VGlVKaeICXBmNUOYr6RL5nR6gtHMMee7n//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/d8771d5b40c0d02fc649e3d7c0fe3650/1a3b7/img_2.webp 245w", "/static/d8771d5b40c0d02fc649e3d7c0fe3650/fb835/img_2.webp 490w", "/static/d8771d5b40c0d02fc649e3d7c0fe3650/e2a84/img_2.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/d8771d5b40c0d02fc649e3d7c0fe3650/c168b/img_2.jpg 245w", "/static/d8771d5b40c0d02fc649e3d7c0fe3650/bb66a/img_2.jpg 490w", "/static/d8771d5b40c0d02fc649e3d7c0fe3650/d5c47/img_2.jpg 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/d8771d5b40c0d02fc649e3d7c0fe3650/d5c47/img_2.jpg",
              "alt": "img 2",
              "title": "img 2",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
  `}</figure>{`
  `}<div parentName="div" {...{
        "className": "content__info__title"
      }}>{`
    `}<p parentName="div">{`
      細倉真弓 `}<br parentName="p" {...{
            "className": "only-sp"
          }}></br>{`
      `}<span parentName="p" {...{
            "className": "font__xs"
          }}>{`(写真家)`}</span>{`
    `}</p>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "content__info__text"
      }}>{`
    `}<p parentName="div">{`
      ユースとセクシュアリティを扱う写真及び映像作品を制作。写真集に『KAZAN』（アートビートパブリッシャーズ）、『Transparency
      is the new mystery』（MACK）、『クリスタル ラブ スターライト』（TYCOON
      BOOKS）、『floaters』（waterfall）など。最新作は今年発表した『NEW
      SKIN』（MACK）。
    `}</p>{`
    `}<p parentName="div">{`
      `}<a parentName="p" {...{
            "href": "http://hosokuramayumi.com/",
            "target": "_blank"
          }}>{`
        http://hosokuramayumi.com/
      `}</a>{`
    `}</p>{`
  `}</div>
    </div>
    <div {...{
      "className": "content__info"
    }}>{`
  `}<figure parentName="div" {...{
        "className": "content__info__img"
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAMEBQL/xAAVAQEBAAAAAAAAAAAAAAAAAAABAP/aAAwDAQACEAMQAAABoy91400yMGoLsiP/xAAbEAACAgMBAAAAAAAAAAAAAAABAgMSAAQRE//aAAgBAQABBQKzelGJEQUTTUxdmr27mwey4qgp/8QAFREBAQAAAAAAAAAAAAAAAAAAASD/2gAIAQMBAT8BI//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EACAQAAIBAwQDAAAAAAAAAAAAAAABAhESMQMQIUFhcYH/2gAIAQEABj8Cl1TtGndKTcyhbFcvIpWY8iZ82j6P/8QAHBABAAIDAAMAAAAAAAAAAAAAAQARIUFRMWGR/9oACAEBAAE/IU1FQX0RAawXxC1IZA0XaYE0Kqz8iB0LjZtAjGB4n//aAAwDAQACAAMAAAAQp/AA/8QAFhEAAwAAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/EIhQX//EABcRAAMBAAAAAAAAAAAAAAAAAAABERD/2gAIAQIBAT8Qad2H/8QAHRABAAMBAAIDAAAAAAAAAAAAAREhMQAQUUFhsf/aAAgBAQABPxBbtgIcH5HAghVV4mNuuCkptW+lTmhguTDZvhH1MwKmTmZ3pBiX4TjEAhI9X4G2q7P13//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/10510d096182ab4e23aa17311903d0bd/1a3b7/img_3.webp 245w", "/static/10510d096182ab4e23aa17311903d0bd/fb835/img_3.webp 490w", "/static/10510d096182ab4e23aa17311903d0bd/e2a84/img_3.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/10510d096182ab4e23aa17311903d0bd/c168b/img_3.jpg 245w", "/static/10510d096182ab4e23aa17311903d0bd/bb66a/img_3.jpg 490w", "/static/10510d096182ab4e23aa17311903d0bd/d5c47/img_3.jpg 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/10510d096182ab4e23aa17311903d0bd/d5c47/img_3.jpg",
              "alt": "img 3",
              "title": "img 3",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
  `}</figure>{`
  `}<div parentName="div" {...{
        "className": "content__info__title"
      }}>{`
    `}<p parentName="div">{`
      小林孝行 `}<br parentName="p" {...{
            "className": "only-sp"
          }}></br>{`
      `}<span parentName="p" {...{
            "className": "font__xs"
          }}>{`(flotsam books代表)`}</span>{`
    `}</p>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "content__info__text"
      }}>{`
    `}<p parentName="div">{`
      2010年4月オンライン書店としてflotsam
      booksを開業する。国内外、新刊または古書を問わず、店主の"偏見"が選書の指針。書籍販売のほか、ブックディレクションや各種イベントなども行う。今年、実店舗を東京・代田橋にオープン。
    `}</p>{`
    `}<p parentName="div">{`
      `}<a parentName="p" {...{
            "href": "https://www.flotsambooks.com/",
            "target": "_blank"
          }}>{`
        https://www.flotsambooks.com/
      `}</a>{`
    `}</p>{`
  `}</div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      