import React, { useEffect } from "react"
import PropTypes from "prop-types"
import Header from "../components/Header"
import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n"
import { StaticQuery, graphql } from "gatsby"
import { IntlProvider } from "react-intl"
import "intl"
import SEO from "../components/seo"
import css from "./index.module.scss"
import OptinConsent from "../components/OptinConsent"
import RecommendBrowser from "../components/RecommendBrowser"
import { useEvents } from "../hooks"

/**
 * アンマウント時にイベントを発行する
 */
const CheckMuteBgm = () => {
  const events = useEvents()
  useEffect(() => {
    const url = window.location.pathname
    const webGLPages = [`/`, `/rafael/`, `/exhibitors/`]
    let isWebGLPage = false
    for (let i = 0; i < webGLPages.length; i += 1) {
      if (url === webGLPages[i] || url === `/en` + webGLPages[i]) {
        isWebGLPage = true
        break
      }
    }
    if (!isWebGLPage) {
      events.emit("stopBGM")
    }
  }, [events])

  return <></>
}

const Layout = ({
  children,
  location,
  i18nMessages,
  title,
  description,
  ogImage,
  show3dLayer = false,
  invertColor = false,
}) => {
  const classCover3DLayer = show3dLayer ? "" : css.cover

  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              title
              languages {
                defaultLangKey
                langs
              }
            }
          }
        }
      `}
      render={data => {
        const url = location.pathname
        const { langs, defaultLangKey } = data.site.siteMetadata.languages
        const langKey = getCurrentLangKey(langs, defaultLangKey, url)
        const homeLink = `/${langKey}/`.replace(`/${defaultLangKey}/`, "/")
        const langsMenu = getLangs(
          langs,
          langKey,
          getUrlForLang(homeLink, url)
        ).map(item => ({
          ...item,
          link: item.link.replace(`/${defaultLangKey}/`, "/"),
        }))

        title = title || ""
        description = description || ""

        return (
          <IntlProvider locale={langKey} messages={i18nMessages}>
            <div className={classCover3DLayer + ` layout-cover`}>
              <SEO
                title={title}
                lang={langKey}
                description={description}
                ogImage={ogImage}
              />
              <CheckMuteBgm />
              <RecommendBrowser />
              <Header
                siteTitle={data.site.siteMetadata.title}
                locale={langKey}
                langs={langsMenu}
                invertColor={invertColor}
              />
              <main> {children} </main>
              <OptinConsent />
            </div>
          </IntlProvider>
        )
      }}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
}

export default Layout
