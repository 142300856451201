import React from "react"
import Layout from "../../layouts/ja"
import { FormattedMessage } from "react-intl"

function IndexPage(props) {
  return (
    <Layout location={props.location}>
      <h1>多言語表示のサンプル</h1>
      <p>
        デフォルトでは日本語が指定されます。このページは /translation-example/
        に出力されます。
      </p>
      <p>かならず Layout で囲うようにしてください。</p>
      <p>共通の UI で言語を分けたい場合は、 FormattedMessage を利用します。</p>
      <button>
        <FormattedMessage id="dummyMessage1" />
      </button>
    </Layout>
  )
}

export default IndexPage
