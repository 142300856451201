import React, { useEffect, useState } from "react"
import css from "./OptinConsent.module.scss"
import { FormattedMessage } from "react-intl"
import Cookies from "js-cookie"

function OptinConsent() {
  const [showConfirm, setShowConfirm] = useState(false)

  // OPTOUT されるが初回は pushstate で残ってしまう

  const gaOptout = () => {
    Cookies.set("gatsby-gdpr-google-analytics", false)
    // console.log(`OPT OUT GA`)
  }

  const gaOptin = () => {
    Cookies.set("gatsby-gdpr-google-analytics", true, {
      expires: 9999,
      path: "/",
    })
    // console.log(`OPT IN GA`)
  }

  const optInActioned = () => {
    localStorage.setItem("OPTIN-ACTIONED", true)
    setShowConfirm(false)
  }

  // 読み込み時に回答済みかどうかをチェック
  useEffect(() => {
    window.ga && window.ga("set", "anonymizeIp", 1)

    if (localStorage.getItem("OPTIN-ACTIONED")) {
    } else {
      gaOptout()
      setShowConfirm(true)
    }
    if (Cookies.get("gatsby-gdpr-google-analytics") === true) {
      gaOptin()
      setShowConfirm(false)
    }
  }, [])

  // アクセス解析を許可した
  const consent = () => {
    gaOptin()
    optInActioned()
  }

  // アクセス解析を拒絶した
  const reject = () => {
    gaOptout()
    optInActioned()
  }

  // 閲覧してリアクションをとったユーザには表示しない
  if (!showConfirm) {
    return <></>
  }

  return (
    <>
      <div className={css.base}>
        <p className={css.textBox}>
          <FormattedMessage id={`useCookie`} />
        </p>
        <p className={css.buttonBox}>
          <button
            className={css.buttonOk}
            onClick={() => {
              consent()
            }}
          >
            <FormattedMessage id={`accept`} />
          </button>
          <button
            className={css.buttonReject}
            onClick={() => {
              reject()
            }}
          >
            <FormattedMessage id={`reject`} />
          </button>
        </p>
      </div>
    </>
  )
}

export default OptinConsent
