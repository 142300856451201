import isMobile from "is-mobile"
import { shuffle } from "lodash"
import {
  LinearEncoding,
  Mesh,
  MeshStandardMaterial,
  Object3D,
  Scene,
} from "three"
import { Loader } from "../../../Loader"
import { furnituresPC, FURNITURE_SIZE } from "../constants"

export function fixFurnitureMaterial(c: Object3D) {
  const m = c as Mesh
  if (m.isMesh) {
    const mat = m.material as MeshStandardMaterial
    if (mat.map) {
      mat.map.encoding = LinearEncoding
    }
  }
}

export function randInt(size: number): number {
  return Math.floor(Math.random() * size)
}

const isPC = !isMobile()
let furnituresMobile: typeof furnituresPC

export function getFurnitures() {
  if (!isPC && furnituresMobile === undefined) {
    furnituresMobile = shuffle(furnituresPC).slice(0, 6)
  }
  return isPC ? furnituresPC : furnituresMobile
}

/**
 * ランダムなFurnitureを取得する
 */
export async function getRandomFurniture(): Promise<Scene> {
  const furnitures = getFurnitures()
  const data = furnitures[randInt(furnitures.length)]
  const furniture = await Loader.loadGLTF(data.url, fixFurnitureMaterial)
  furniture.scale.setScalar(FURNITURE_SIZE * data.scale)
  return furniture
}
