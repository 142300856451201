import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/vabf/vabf/vabf-site/src/templates/BestDutchBook.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`肌、身体、エロティシズム、エキゾティシズム、批評とアイデンティティ。 ハダッサ・エッメリヒのカラフルな近作は、親密さと距離、抽象と視認性、対象化と見る者の立ち位置などにまつわる実験だ。アーティストと共に、デザイナーエステル・ルロイは作品の肌の中 − あるいは下 − に潜り込み、形式とその内容両方において、作品を内側から明らかにする本をデザインした。表紙に使用された素材 neobond® は、エッメリヒが作品で使用する樹脂を用いた製本技術であり、彼女の絵画の表面を思わせると同時に、それらが描く抽象化された人体の肌も想起させる。サイズの大きさもまた、エッメリヒの作品を参照している。本を開いても、異なる紙の使用、作品をうまく見せるグリッド状のレイアウト、連続する形状のグラフィカルな存在感など、知覚に訴える体験が続く。メタリックな身体的形状を覆う、激しくも繊細なニュアンスを持つ玉虫色が、白すぎない紙の上に細心の注意を払って印刷され、銀の箔押しがされたカバーのタイポグラフィは、作品のメタリックな質感を文字通り反映したものだろう。後付にはハダッサ・エッメリヒ自身が「デザイナーの協力のもと」編集したとクレジットされ、エステル・ルロイは「アーティストとの会話を通して」コンセプト＆デザインを担当したとクレジットされている。デザイナーとアーティストをつなぐメビウスの輪は視覚的にも質感的にも明らかで、それが本書に官能的かつ批評的な価値を与えている。`}</p>
    <hr></hr>
    <p>{`出版社： Onomatopee Projects, Eindhoven`}<br parentName="p"></br>{`
`}{`デザイン： Esther Le Roy, Brussel (BE)`}</p>
    <p>{`カテゴリー： アート、写真、建築`}<br parentName="p"></br>{`
`}{`アーティスト：Hadassah Emmerich`}<br parentName="p"></br>{`
`}{`編集：Koen Leemans`}<br parentName="p"></br>{`
`}{`言語：英語、オランダ語、フランス語`}<br parentName="p"></br>{`
`}{`翻訳：Gerard Forde, Isabelle Grynberg`}<br parentName="p"></br>{`
`}{`写真：Peter Cox, Carel de Groot, Hugard & Vanoverschelde, Jeroen Nieuwenhuize, Teri Romkey`}<br parentName="p"></br>{`
`}{`イメージ編集：Hadassah Emmerich, Esther Le Roy`}<br parentName="p"></br>{`
`}{`編集：Hadassah Emmerich, Esther Le Roy`}<br parentName="p"></br>{`
`}{`リトグラフ：Die Keure, Brugge (BE)`}<br parentName="p"></br>{`
`}{`印刷：Die Keure, Brugge (BE)`}<br parentName="p"></br>{`
`}{`製本：Boekbinderij Van Mierlo, Nijmegen`}<br parentName="p"></br>{`
`}{`フォント：Favorit (Dinamo)`}<br parentName="p"></br>{`
`}{`サイズ（ミリ）：255 x 345 x 6`}<br parentName="p"></br>{`
`}{`ページ数：96`}<br parentName="p"></br>{`
`}{`発行部数：1,000`}<br parentName="p"></br>{`
`}{`価格：€ 30`}<br parentName="p"></br>{`
`}{`ISBN：978 94 93148 09 3`}<br parentName="p"></br>{`
`}{`本文用紙：110gsm Woodstock Cipria (Fedrigoni), 150gsm Symbol Matt Plus Premium White (Fedrigoni)`}<br parentName="p"></br>{`
`}{`見返し：170gsm Sirio Color Perla (Fedrigoni)`}<br parentName="p"></br>{`
`}{`装丁：150gsm neobond® white (Papyrus)`}<br parentName="p"></br>{`
`}{`製本方法：糸かがり角背ハードカバー、1.5mm グレーボード紙`}<br parentName="p"></br>{`
`}{`特記事項：Boekbinderij Van Mierlo（オランダ）による銀(Kurz Alufin Mat)の箔押し。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      